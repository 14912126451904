import { CLEAR_TAG_FILTER, GET_TAG_FILTER } from "../../ReduxKeys";

const tagFilterInitialState = {
  title: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store tage filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const tagFilterReducer = (state = tagFilterInitialState, action) => {
  switch (action.type) {
    case GET_TAG_FILTER:
      return {
        ...state,
        title: action.payload.title,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_TAG_FILTER:
      return tagFilterInitialState;
    default:
      return state;
  }
};
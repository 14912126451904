import {
  CLEAR_STOCK_HISTORY_DATA,
  CLEAR_TOTAL_STOCK_HISTORY,
  GET_STOCK_HISTORY_DATA,
  GET_TOTAL_STOCK_HISTORY,
} from "../../ReduxKeys";

/**
 * Setting Stock history list data into reducer
 * @param {*} stockHistory
 * @returns {*}
 */
export const getStockHistoryData = (stockHistory) => ({
  type: GET_STOCK_HISTORY_DATA,
  payload: stockHistory,
});

/**
 * clearing stock history list data
 * @returns {*}
 */
export const clearStockHistoryData = () => ({
  type: CLEAR_STOCK_HISTORY_DATA,
});

/**
 * Setting total stock history list data into reducer (No pagnation)
 * @param {*} stockHistory
 * @returns {*}
 */
export const getTotalStockHistoryData = (stockHistory) => ({
  type: GET_TOTAL_STOCK_HISTORY,
  payload: stockHistory,
});

/**
 * Clearing total stock history list data (No pagination)
 * @returns {*}
 */
export const clearTotalStockHistoryData = () => ({
  type: CLEAR_TOTAL_STOCK_HISTORY,
});
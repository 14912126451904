export const buildVendorRequestBody = (item, payment, status) => {
  
  if (!item || !item.ingredients) {
    console.error("Invalid item data");
    return null;
  }
  
  const ingredients = JSON.parse(item.ingredients);
  const transformedIngredients = ingredients.map((ingredient) => ({
    ingredient_id: ingredient.ingredient_id,
    ingredient_instruction: ingredient.ingredient_instruction,
    value: ingredient.value,
    unit: ingredient.unit,
    status: ingredient.status,
    ingredient_price: ingredient.ingredient_price,
  }));
  
  return {
    p_id: item.id,
    p_vendor_id:item.vendor_user_id,
    p_instruction: item.instructions,
    p_product_data: transformedIngredients,
    p_payment:(payment) ? payment : item.payment,
    p_status: (status) ? status : item.orderStatus,
    p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

import { Dispatch } from "redux";
import {
  AddUpdateProductRequest,
  getAllProductRequest,
  getAllProductResponse,
  getProductByIdResponse,
  UpdateProductPrice,
} from "./ProductModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearProductData,
  clearTotalProductData,
  getProductData,
  getTotalProductData,
} from "../../../redux/Actions/productAction/ProductAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import {
  clearProductPageData,
  getProductPageData,
} from "../../../redux/Actions/productAction/ProductPageAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import {
  clearProductPricingData,
  clearTotalProductPricingData,
  getProductPricingData,
  getTotalProductPricingData,
} from "../../../redux/Actions/ProductPricingAction/ProductPricingAction";
import {
  clearProductPricingPageData,
  getProductPricingPageData,
} from "../../../redux/Actions/ProductPricingAction/ProductPricingPageAction";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function ProductServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get product list
   */
  const getAllProductService = (
    productListRequest: getAllProductRequest,
    token: string,
    dispatch: Dispatch,
    total?: any,
    loaderData?: any
  ): Promise<getAllProductResponse> => {
    return new Promise<getAllProductResponse>(async (resolve, reject) => {
      try {
        if (!loaderData) {
          dispatch(setLoader());
        }
        const queryParams = Object.entries(productListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.PRODUCT +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalProductData(response));
            dispatch(getTotalProductPricingData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getProductData(response));
            dispatch(getProductPricingData(response));
          } else {
            dispatch(getProductData(response));
            dispatch(getProductPricingData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating product
   */
  const addUpdateProductService = (
    addUpdateProductRequest: AddUpdateProductRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.PRODUCT,
            token,
            addUpdateProductRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.PRODUCT,
            token,
            addUpdateProductRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success") &&
          response.message !== "Product SKU code should be Unique"
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          dispatch(clearProductData());
          dispatch(clearTotalProductData());
          dispatch(clearProductPricingData());
          dispatch(clearTotalProductPricingData());
          if (type === "add") {
            dispatch(clearProductPageData());
            dispatch(clearProductPricingPageData());
            toast.success(
              response?.message
                ? response.message
                : "Product added successfully!"
            );
            navigate("/product");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Product undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getProductPageData(pageNumber + 1));
                } else {
                  dispatch(clearProductPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Product updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/product");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Product status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getProductPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual product
   */
  const getProductById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getProductByIdResponse> => {
    return new Promise<getProductByIdResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.PRODUCT + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for deleting product
   */
  const deleteProductService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.PRODUCT + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          dispatch(clearProductData());
          dispatch(clearTotalProductData());
          toast.success(
            response?.message
              ? response.message
              : "Product deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getProductPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for uploading excel
   */
  const uploadProductExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append("excel", excelRequest);

      try {
        dispatch(setLoader());
        const response = await fetch(
          API_URL.BASE_URL + API_URL.PRODUCT_UPLOAD,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Language": localisation ? localisation : "en",
              "accept-language": localisation ? localisation : "en",
            },
            body: formData,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          if (data?.message && data.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          return data;
          // throw new Error('Network response was not ok');
        } else {
          const data = await response.json();
          toast.success(
            data?.message ? data.message : "Product imported successfully"
          );
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for adding and updating product
   */
  const updateProductPriceService = (
    updateProductPrice: UpdateProductPrice,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        // if (type === "add") {
        //   response = await postApiService(
        //     API_URL.BASE_URL + API_URL.PRODUCT,
        //     token,
        //     updateProductPrice
        //   );
        // }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.PRODUCT + API_URL.PRICE,
            token,
            updateProductPrice
          );
        }
        const val = response.message;
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success") &&
          val !== "Product SKU code should be Unique"
        ) {
          dispatch(clearProductData());
          dispatch(clearTotalProductData());
          dispatch(clearProductPricingData());
          dispatch(clearTotalProductPricingData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          // if (type === "add") {
          //   dispatch(clearProductPageData());
          //   toast.success("Product added successfully!");
          //   navigate("/product-pricing");
          // }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Product undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getProductPricingPageData(pageNumber + 1));
                } else {
                  dispatch(clearProductPricingPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Product updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/product-pricing");
                });
                if (val.includes("Product SKU code should be Unique")) {
                  toast.error(
                    response?.message
                      ? response.message
                      : "Product SKU code should be Unique"
                  );
                }
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Product status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getProductPricingPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getAllProductService,
    addUpdateProductService,
    getProductById,
    deleteProductService,
    uploadProductExcelService,
    updateProductPriceService,
  };
}

import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import CustomTable from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import Button from "../../components/Button/Button";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomerData } from "../../redux/Actions/CustomerAction/CustomerAction";
import {
  clearCustomerPageData,
  getCustomerPageData,
} from "../../redux/Actions/CustomerAction/CustomerPageAction";
import CustomerService from "./CustomerService/CustomerService";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { formatUTCDate } from "../../services/Regular-services/DateService";
import CustomPagination from "../../components/Pagination/Pagination";
import { GrMoreVertical } from "react-icons/gr";
import { clearCustomerFilterData } from "../../redux/Actions/CustomerAction/CustomerFilterAction";
import { useTranslation } from "react-i18next";

export default function Customer() {
  const { getCustomerListService } = CustomerService();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customerFilter = useSelector((state) => state.customerFilter);
  const customerPage = useSelector((state) => state.customerPage?.items);
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const token = UtilsData();
  const [customerListData, setCustomerListData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [customerActionData, setcustomerActionData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);

  const columns = [
    {
      name: t("columns.name"),
      selector: (row) => row.name,
    },
    {
      name: t("columns.phone_number"),
      selector: (row) => row.number,
    },
    {
      name: t("columns.total_orders"),
      selector: (row) => row.orders,
    },
    {
      name: t("columns.actions"),
      selector: (row) => row.actions,
    },
  ];

  //Show toggle which is to be displayed in table
  const showToggle = {
    status: false,
    delete: false,
  };

  //Show actions which is to be displayed in table
  const showActions = {
    showView: true,
    showEdit: true,
    showDelete: false,
    navigate: "/crm",
  };

  //Show keys which is to be displayed in table
  const showKey = {
    name: true,
    // email: true,
    number: true,
    orders: true,
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setcustomerActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setCurrentPage(customerPage);
  }, [customerPage]);

  /**
   * Fetch user data
   */
  const fetchUsers = () => {
    const requestData = {
      skip: 0,
      take: 10,
    };
    if (customerFilter.email !== "" && customerFilter.email !== null) {
      requestData.search_email = customerFilter.email;
    }
    if (customerFilter.phone !== "" && customerFilter.phone !== null) {
      requestData.search_phone_number = customerFilter.phone;
    }
    if (customerPage > 1) {
      requestData.skip = (customerPage - 2) * 10;
      setCurrentPage(customerPage - 1); // Update the current page state
      dispatch(getCustomerPageData(customerPage - 1));
    } else {
      requestData.skip = (customerPage - 1) * 10;
      setCurrentPage(customerPage); // Update the current page state
      dispatch(getCustomerPageData(customerPage));
    }
    getCustomerListService(requestData, token, dispatch);
  };

  useEffect(() => {
    if (customer && customer.items && customer.items.length === 0) {
      fetchUsers();
    } else {
      if (customerFilter.phone !== "") {
        // handleShow();
      }
    }

    if (customer.items[0]?.data && customer.items[0].data.length > 0) {
      const transformedAdminData = customer.items[0].data
        .filter(
          (item) =>
            !["8155006366", 8155006366, "null", null].includes(
              item?.phoneNumber
            )
        )
        .map((item) => ({
          name: `${item.firstName} ${item.lastName}`,
          email: item.email,
          number: item.phoneNumber,
          orders: item.order_count,
          userId: item.userId,
          username: item.username,
          roleName: item.role_name,
          createdAt: formatUTCDate(item.createdAt),
          lastLoginAt: formatUTCDate(item.lastLoginAt),
          isActive: item.isActive,
          isDeleted: item.isDeleted,
          firstName: item.firstName,
          lastName: item.lastName,
          rolePermissionsId: item.rolePermissionsId,
          typeId: item.typeId,
          createdBy: item.createdBy,
          total_order_amount: item.total_order_amount,
          aniversery_date: item.aniversery_date,
          dob: item.dob,
          discount_ids: item.discount_ids,
        }));
      setCustomerListData(transformedAdminData);
    } else {
      setCustomerListData([]);
    }
    // eslint-disable-next-line
  }, [customer, customerFilter]);

  const styleData = {
    number: { type: { textAlign: "end" } },
    orders: { type: { textAlign: "end" } },
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    const requestData = {
      skip: (pageNumber - 1) * 10,
      take: 10,
    };
    if (customerFilter.email !== "" && customerFilter.email !== null) {
      requestData.search_email = customerFilter.email;
    }
    if (customerFilter.phone !== "" && customerFilter.phone !== null) {
      requestData.search_phone_number = customerFilter.phone;
    }
    if (pageNumber !== currentPage) {
      getCustomerListService(requestData, token, dispatch);
    }
    setCurrentPage(pageNumber); // Update the current page state
    dispatch(getCustomerPageData(pageNumber));
  };

  const clearFilters = () => {
    dispatch(clearCustomerFilterData());
    handleClose();
    dispatch(clearCustomerPageData());
    dispatch(clearCustomerData());
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {customerActionData && customerActionData.length > 0
                ? getRouteNameFromSidebar(
                    customerActionData[0].name,
                    localisation
                  )
                : t("CRM")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {customerActionData && customerActionData.length > 0
                ? getRouteNameFromSidebar(
                    customerActionData[0].name,
                    localisation
                  )
                : t("CRM")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups">
          {customerFilter.phone !== "" && customerFilter.phone !== null && (
            <Dropdown className="new-header-dropdown action-btn">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                data-bs-toggle="tooltip"
                title="Action"
              >
                <GrMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={clearFilters}>
                  Clear Filters
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={handleShow}
            data-bs-toggle="tooltip"
            title="Filter"
            // onClick={handleButtonClick}
            className="btn btn-fillter-main"
            name={
              <div className="filter">
                <FaFilter className="filter-icon" />
              </div>
            }
          ></Button>

          {customer.items[0]?.data && customer.items[0].data.length > 0 && (
            <ExcelData
              excelData={customer.items[0]?.data}
              fileName={"customer"}
            ></ExcelData>
          )}
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filter page="customer" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>

      {customer && customer.items[0]?.data && customer.items[0].data && (
        <>
          <CustomTable
            page="customer"
            showKey={showKey}
            showActions={showActions}
            checkBool={false}
            showTooltip={true}
            data={customerListData}
            heading={columns}
            styleData={styleData}
            showToggle={showToggle}
          />

          {customer.items[0]?.total_records > 0 &&
            customer.items[0].total_records > customer.items[0].data.length &&
            customer.items[0].total_records > 10 && (
              <CustomPagination
                totalRecords={customer.items[0].total_records}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
        </>
      )}
    </div>
  );
}

import React, { useState } from "react";
import "../NewAddOrder/NewAddOrder.scss";
import Button from "../../../components/Button/Button";
import { MdDateRange } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import CloseRegister from "../../../assets/images/Close-Register.png";
import ViewRegister from "../../../assets/images/ViewRegister.png";
import Baked from "../../../assets/images/Baked.png";
import Food from "../../../assets/images/cheesemaskabunwithfruitjam.jpg";
import SearchBar from "../../../components/SearchBar/SearchBar";

function NewAddOrder() {
  const [inputData, setInputData] = useState("");
  return (
    <div className="body-content">
      <div className="table-body-content new-table-body-content">
        <div className="roles">
          <div className="order-number-show">
            <div>
              <div className="title">
                <h4>Add Orders</h4>
              </div>
            </div>
          </div>
          <div className="btn-groups">
            <Button
              className="btn btn-fillter-main"
              name={<MdDateRange style={{ fontSize: 25 }} />}
            />
            <Button
              className="btn btn-fillter-main icon-img"
              name={<img src={ViewRegister} alt="Close Register" />}
            />
            <Button
              className="btn btn-fillter-main icon-img"
              name={<img src={CloseRegister} alt="Close Register" />}
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-4 col-sm-12 col-12"
            style={{
              flex: "1",
              minWidth: "250px",
              marginBottom: "10px",
            }}
          >
            <div className="name-type">
              <div className="search-bar">
                <SearchBar inputData={inputData} setInputData={setInputData} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <div className="new-cetegory-box-list">
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
              <div className="new-cetegory-box">
                <img src={Baked} alt="Baked" />
                <span>Bakery</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="new-product-card-2">
                  <div className="new-product-card-iamge">
                    <img src={Food} alt="Food" />
                  </div>
                  <div className="new-main-product-detail">
                    <div className="new-product-detail">
                      <h4>
                        Burger Lorem ipsum, dolor sit amet Burger Lorem ipsum,
                        dolor sit amet Burger Lorem ipsum, dolor sit amet
                      </h4>
                      <span>₹200</span>
                    </div>
                    <div>
                      <Button
                        className="btn btn-fillter-main"
                        name={<FaPlus style={{ fontSize: 25 }} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="new-box-details-all"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAddOrder;

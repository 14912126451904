import {
  CLEAR_ATTRIBUTE_DATA,
  CLEAR_TOTAL_ATTRIBUTE_DATA,
  GET_ATTRIBUTE_DATA,
  GET_TOTAL_ATTRIBUTE_DATA,
} from "../../ReduxKeys";

const attributeInitialState = {
  items: [],
};

/**
 * Store attribute list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const attributeReducer = (state = attributeInitialState, action) => {
  switch (action.type) {
    case GET_ATTRIBUTE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_ATTRIBUTE_DATA:
      return attributeInitialState;
    default:
      return state;
  }
};

const totalattributeInitialState = {
  items: [],
};

/**
 * Store total attribute list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalAttributeReducer = (
  state = totalattributeInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_ATTRIBUTE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_ATTRIBUTE_DATA:
      return totalattributeInitialState;
    default:
      return state;
  }
};
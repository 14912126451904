import { format } from "date-fns";

export const DiscountToggleRequest = ({
  discountDetail,
  userId,
  isActive,
  isDelete,
}) => {
  const requestBody = {
    p_id: discountDetail.data.id,
    p_title: discountDetail.data.title,
    p_code: discountDetail.data.code,
    p_discount_type: discountDetail.data.discount_type,
    p_discount_amount: discountDetail.data.discount_amount,
    p_max_discount_amount: discountDetail.data.max_discount_ammount,
    p_min_cart_value: discountDetail.data.min_cart_value,
    p_is_first_order_discount: discountDetail.data.is_first_order_discount,
    p_discount_type_on: discountDetail.data.discount_type_on,
    p_discount_apply_on: discountDetail.data.discount_apply_on,
    p_specific_item: discountDetail.data.spacific_item,
    p_start_date: format(
      new Date(discountDetail.data.start_date),
      "yyyy-MM-dd"
    ),
    p_end_date: format(new Date(discountDetail.data.end_date), "yyyy-MM-dd"),
    p_modified_by: userId,
    p_is_active: isActive
      ? discountDetail.data.is_active === "1"
        ? "0"
        : "1"
      : discountDetail.data.is_active,
    p_IsDeleted: isDelete ? "0" : discountDetail.data.IsDeleted,
    p_discount_data: [],
  };
  if (discountDetail.data.spacific_item === "1") {
    if (discountDetail.data.discount_apply_on === "3") {
      const brandIdsArray = discountDetail.data.brand_ids
        ? discountDetail.data.brand_ids.split(",").map((id) => id.trim())
        : null;

      if (brandIdsArray) {
        requestBody.p_discount_data = brandIdsArray.map((id) => ({
          brand_id: id,
        }));
      }
    } else if (discountDetail.data.discount_apply_on === "0") {
      const categoryIdsArray = discountDetail.data.category_ids
        ? discountDetail.data.category_ids.split(",").map((id) => id.trim())
        : null;

      if (categoryIdsArray) {
        requestBody.p_discount_data = categoryIdsArray.map((id) => ({
          category_id: id,
        }));
      }
    } else if (discountDetail.data.discount_apply_on === "1") {
      const subCategoryIdsArray = discountDetail.data.sub_category_ids
        ? discountDetail.data.sub_category_ids.split(",").map((id) => id.trim())
        : null;

      if (subCategoryIdsArray) {
        requestBody.p_discount_data = subCategoryIdsArray.map((id) => ({
          sub_category_id: id,
        }));
      }
    } else if (discountDetail.data.discount_apply_on === "2") {
      const leafCategoryIdsArray = discountDetail.data.leaf_level_category_ids
        ? discountDetail.data.leaf_level_category_ids
            .split(",")
            .map((id) => id.trim())
        : null;

      if (leafCategoryIdsArray) {
        requestBody.p_discount_data = leafCategoryIdsArray.map((id) => ({
          leaf_level_category_id: id,
        }));
      }
    } else if (discountDetail.data.discount_apply_on === "4") {
      const productIdsArray = discountDetail.data.product_ids
        ? discountDetail.data.product_ids.split(",").map((id) => id.trim())
        : null;

      if (productIdsArray) {
        requestBody.p_discount_data = productIdsArray.map((id) => ({
          product_id: id,
        }));
      }
    }
  }
  return requestBody;
};
import {
  CLEAR_ROLE_DATA,
  CLEAR_TOTAL_ROLE_DATA,
  GET_ROLE_DATA,
  GET_TOTAL_ROLE_DATA,
} from "../../ReduxKeys";

const rolesInitialState = {
  items: [],
};

/**
 * Store role list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const RolesReducer = (state = rolesInitialState, action) => {
  switch (action.type) {
    case GET_ROLE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_ROLE_DATA:
      return rolesInitialState;
    default:
      return state;
  }
};

const totalRolesInitialState = {
  items: [],
};

/**
 * Store total role list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalRolesReducer = (state = totalRolesInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_ROLE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_ROLE_DATA:
      return totalRolesInitialState;
    default:
      return state;
  }
};
import { orderDate } from "../../../services/Regular-services/DateService";
import {
  CLEAR_PURCHASE_REQUISITION_FILTER,
  GET_PURCHASE_REQUISITION_FILTER,
} from "../../ReduxKeys";

const purchaseRequisitionFilterInitialState = {
  purchaseRequisitionNo: "",
  orderStartDate: orderDate(),
  orderEndDate: "",
  vendor: "",
  status: "",
};

/**
 * Store PURCHASE REQUISITION filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PurchaseRequisitionFilterReducer = (
  state = purchaseRequisitionFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_REQUISITION_FILTER:
      return {
        ...state,
        purchaseRequisitionNo: action.payload.purchaseRequisitionNo,
        orderStartDate: action.payload.orderStartDate,
        orderEndDate: action.payload.orderEndDate,
        vendor: action.payload.vendor,
        status: action.payload.status,
      };
    case CLEAR_PURCHASE_REQUISITION_FILTER:
      return purchaseRequisitionFilterInitialState;
    default:
      return state;
  }
};
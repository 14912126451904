import {
  CLEAR_LEAF_DATA,
  CLEAR_TOTAL_LEAF_DATA,
  GET_LEAF_DATA,
  GET_TOTAL_LEAF_DATA,
} from "../../ReduxKeys";

const leafCategoryInitialState = {
  items: [],
};

/**
 * Store leaf level category list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const LeafCategoryReducer = (
  state = leafCategoryInitialState,
  action
) => {
  switch (action.type) {
    case GET_LEAF_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_LEAF_DATA:
      return leafCategoryInitialState;
    default:
      return state;
  }
};

const totalLeafInitialState = {
  items: [],
};

/**
 * Store total leaf level category list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalLeafReducer = (state = totalLeafInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_LEAF_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_LEAF_DATA:
      return totalLeafInitialState;
    default:
      return state;
  }
};
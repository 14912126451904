export const BranchToggleRequest = ({toggleData, userId, isActive, isDelete}) => {
    const requestBody = {
        p_id: toggleData.branchId,
        p_name: toggleData.name,
        p_address: toggleData.address,
        p_pincode: toggleData.pincode,
        p_isActive: isActive ? toggleData.isActive === "1" ? "0" : "1" : toggleData.isActive,
        p_IsDeleted: isDelete ? '0' : toggleData.isDeleted,
        p_created_by: toggleData.createdBy,
        p_modified_by: userId,
        p_tax: toggleData.tax_type,
        p_gst_number: toggleData.gst_number,
        p_vat_number: toggleData.vat_number,
        p_Working_day: JSON.parse(toggleData.schedule),
        p_distance_data:
          toggleData.distance_data && toggleData.distance_data.length > 0
            ? toggleData.distance_data
            : null,
        p_payment_url:
          toggleData?.payment_url && toggleData.payment_url !== ""
            ? toggleData.payment_url
            : null,
        p_public_avilable: toggleData.is_public_avilable,
        p_delivery_available: toggleData.is_delivery_available,
        p_wp_sms_allow: toggleData.is_wp_sms_allow,
        p_brand_ids:
          toggleData?.brand_id && JSON.parse(toggleData.brand_id).length > 0
            ? JSON.parse(toggleData.brand_id).map((item) => ({
                id: Number(item?.id),
              }))
            : null
      };
      return requestBody;
}
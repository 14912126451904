import { CLEAR_PACKAGER_FILTER, GET_PACKAGER_FILTER } from "../../ReduxKeys";

const packagerFilterInitialState = {
  name: "",
  type: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store packager filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const packagerFilterReducer = (
  state = packagerFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_PACKAGER_FILTER:
      return {
        ...state,
        name: action.payload.name,
        type: action.payload.type,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_PACKAGER_FILTER:
      return packagerFilterInitialState;
    default:
      return state;
  }
};
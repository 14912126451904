export const OrderRequestBodyOnToggle = ({
  item,
  status,
  payment,
  zomatoSwiggyPrice,
}) => {
  const productData = item.productData.map((product) => ({
    map_table_id: product?.map_table_id ? product.map_table_id : 0,
    product_id: Number(product.productId),
    product_size: product.product_size,
    quantity: Number(product.quantity),
    product_price: Number(product.price),
    purchase_price: product?.purchase_price ? product.purchase_price : 0,
    product_instruction:
      product?.product_instruction && product.product_instruction !== ""
        ? product.product_instruction
        : null,
    food_type_one:
      product?.food_type_1 && product.food_type_1 !== ""
        ? product.food_type_1
        : null,
    food_type_two:
      product?.food_type_2 && product.food_type_2 !== ""
        ? product.food_type_2
        : null,
    attribute_data: product.attribute_data ? product.attribute_data : null,
    status: product.productStatus,
    product_discount_id: product.product_discount_id,
    product_discount_code: product.product_discount_code,
    product_discount_amount: product.product_discount_amount,
  }));

  const requestBody = {
    p_id: item.id,
    p_mobile_no:
      item.phoneNumber && item.phoneNumber !== "" && item.phoneNumber !== "N/A"
        ? Number(item.phoneNumber)
        : 8155006366,
    p_first_name:
      item.firstName && item.firstName !== "" && item.firstName !== "N/A"
        ? item.firstName
        : "Bespeakk",
    p_last_name: item.lastName,
    p_table_no: item.type === "0" ? item.table_no : "",
    p_instruction: item.instructions,
    p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    p_address_id: item && item.address_id ? item.address_id : null,
    p_delivery_charges: item?.total_delivery_charge
      ? Number(item.total_delivery_charge)
      : 0,
    p_discount_id:
      item?.discount_id && item.discount_id ? item?.discount_id : 0,
    p_discount_code:
      item?.Discount_Code && item.Discount_Code !== ""
        ? item.Discount_Code
        : "",
    p_discount_amount:
      item?.total_discount_amount &&
      item.total_discount_amount !== "" &&
      item.total_discount_amount > 0
        ? Number(item.total_discount_amount)
        : 0,
    p_total_amount: Number(item.total_amount_with_tax),
    p_total_tax_amount: Number(item.total_tax_amount),
    p_product_data: productData,
    p_status: status ? status : item.orderStatus,
    p_type: item.type,
    p_payment: payment ? payment : item.payment,
    p_zomato_swiggy_other_amount:
      payment !== "5" && payment !== "6"
        ? "0.00"
        : zomatoSwiggyPrice
        ? zomatoSwiggyPrice
        : item.zomatoSwiggyPrice,
  };

  return requestBody;
};

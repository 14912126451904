import { CLEAR_ATTRIBUTE_PAGE, GET_ATTRIBUTE_PAGE } from "../../ReduxKeys";

const attributePageInitialState = {
  items: 1,
};

/**
 * Store attribute page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const attributePageReducer = (
  state = attributePageInitialState,
  action
) => {
  switch (action.type) {
    case GET_ATTRIBUTE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_ATTRIBUTE_PAGE:
      return attributePageInitialState;
    default:
      return state;
  }
};
import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { toast } from "react-toastify";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  AddUpdateCategoryRequest,
  CategoryListResponse,
  getAllCategoryRequest,
  getCategoryByIdResponse,
} from "./CategoryModal";
import {
  clearCategoryData,
  clearTotalCategory,
  getCategoryData,
  getTotalCategoryData,
} from "../../../redux/Actions/categoryAction/CategoryAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearCategoryPageData,
  getCategoryPageData,
} from "../../../redux/Actions/categoryAction/CategoryPageAction";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function CategoryService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get category list
   */
  const getAllCategoryService = (
    categoryListRequest: getAllCategoryRequest,
    token: string,
    dispatch: Dispatch,
    total?: any,
    loaderData?: any
  ): Promise<CategoryListResponse> => {
    return new Promise<CategoryListResponse>(async (resolve, reject) => {
      try {
        if (!loaderData) {
          dispatch(setLoader());
        }
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          categoryListRequest.p_skip !== undefined &&
          categoryListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${categoryListRequest.p_skip}&`;
        }
        if (
          categoryListRequest.p_take !== undefined &&
          categoryListRequest.p_take !== null
        ) {
          queryParams += `p_take=${categoryListRequest.p_take}&`;
        }
        if (
          categoryListRequest.p_name !== undefined &&
          categoryListRequest.p_name !== null
        ) {
          queryParams += `p_name=${categoryListRequest.p_name}&`;
        }
        if (
          categoryListRequest.p_category_code !== undefined &&
          categoryListRequest.p_category_code !== null
        ) {
          queryParams += `p_category_code=${categoryListRequest.p_category_code}&`;
        }
        if (
          categoryListRequest.p_is_active !== undefined &&
          categoryListRequest.p_is_active !== null
        ) {
          queryParams += `p_is_active=${categoryListRequest.p_is_active}&`;
        }
        if (
          categoryListRequest.p_IsDeleted !== undefined &&
          categoryListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${categoryListRequest.p_IsDeleted}&`;
        }
        if (
          categoryListRequest.p_type !== undefined &&
          categoryListRequest.p_type !== null
        ) {
          queryParams += `p_type=${categoryListRequest.p_type}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.CATEGORY +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalCategoryData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getCategoryData(response));
          } else {
            dispatch(getCategoryData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating category
   */
  const addUpdateCategoryService = (
    addUpdateCategoryRequest: AddUpdateCategoryRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.CATEGORY,
            token,
            addUpdateCategoryRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.CATEGORY,
            token,
            addUpdateCategoryRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearCategoryData());
          dispatch(clearTotalCategory());
          if (type === "add") {
            dispatch(clearCategoryPageData());
            toast.success(
              response?.message
                ? response.message
                : "Category added successfully!"
            );
            navigate("/category");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Category undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getCategoryPageData(pageNumber + 1));
                } else {
                  dispatch(clearCategoryPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Category updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/category");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Category status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getCategoryPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting category
   */
  const deleteCategoryService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.CATEGORY + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearCategoryData());
          dispatch(clearTotalCategory());
          toast.success(
            response?.message
              ? response.message
              : "Category deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getCategoryPageData(pageNumber));
        }
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual category
   */
  const getCategoryById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getCategoryByIdResponse> => {
    return new Promise<getCategoryByIdResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.CATEGORY + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for uploading excel
   */
  const uploadCategoryExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append("excel", excelRequest);

      try {
        dispatch(setLoader());
        const response = await fetch(
          API_URL.BASE_URL + API_URL.CATEGORY_UPLOAD,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Language": localisation ? localisation : "en",
              "accept-language": localisation ? localisation : "en",
            },
            body: formData,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          if (data?.message && data.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          return data;
          // throw new Error('Network response was not ok');
        } else {
          const data = await response.json();
          toast.success(
            data?.message ? data.message : "Category imported successfully"
          );
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllCategoryService,
    addUpdateCategoryService,
    deleteCategoryService,
    getCategoryById,
    uploadCategoryExcelService,
  };
}
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import CategoryService from "../../Category/Category-service/CategoryService";
import BrandService from "../../BrandList/BrandService/BrandService";
import { toast } from "react-toastify";
import { parseImage } from "../../../services/Regular-services/ImageService";
import Toggle from "../../../components/Toggle/Toggle";
import AttributeService from "../../Attributes/AttributeService/AttributeService";
import BranchService from "../../Branches/BranchService/BranchService";
import ServiceServices from "../ServiceService/ServiceService";
import ServiceProviderServices from "../../ServiceProvider/ServiceProviderService/ServiceProviderService";
import SubCategoryServices from "../../SubCategory/SubCategoryService/SubCategoryService";
import TagServices from "../../Tag/TagService/TagService";
import { useTranslation } from "react-i18next";

export default function AddService() {
  const { getAllAttributeService } = AttributeService();
  const { getAllBranchService } = BranchService();
  const { getAllBrandService } = BrandService();
  const { getAllCategoryService } = CategoryService();
  const { addUpdateServiceService, getServiceById } = ServiceServices();
  const { getAllServiceProviderService } = ServiceProviderServices();
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const { getAllSubCategoryService } = SubCategoryServices();
  const { getAllTagsService } = TagServices();

  //Validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    serviceImage: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE),
    category: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
    subCategory: Yup.array().min(
      1,
      ValidationMessage.MULTI_SUB_CATEGORY_SELECT
    ),
    brand: Yup.string().required(ValidationMessage.SINGLE_BRAND_SELECT),
    branch: Yup.array().min(1, ValidationMessage.FIELD_REQUIRED),
    tag: Yup.array().min(1, ValidationMessage.MULTI_TAG_SELECT),
    description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
  });

  const [initialValues, setInitialValues] = useState({
    serviceImage: [],
    name: "",
    brand: "",
    branch: [],
    category: [],
    subCategory: [],
    serviceProviderName: "",
    serviceProviderPrice: "",
    attributes: "",
    attributeCategory: "",
    attributeValues: "",
    attributeImage: [],
    tag: [],
    description: "",
    status: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  /**
   * Getting data from service table
   */
  const location = useLocation();
  const { Data } = location.state || {};

  const [fields, setFields] = useState([]);
  const [fieldArrays, setFieldArrays] = useState([]);
  const [attributeErrorImages, setAttributeErrorImages] = useState([]);
  const [serviceErrorImages, setServiceErrorImages] = useState([]);
  const [attributeShowImage, setAttributeShowImage] = useState(false);
  const [serviceShowImage, setServiceShowImage] = useState(false);

  /**
   * Fetch service data by id
   */
  const fetchServiceData = async (id) => {
    try {
      // Call the getServiceById function
      const serviceData = await getServiceById(id, tokenData, dispatch);
      // Execute the returned async function to fetch the data

      if (serviceData && serviceData.status === "success") {
        const categoryOption = serviceData.data.category_ids
          .split(",")
          .map(Number);
        const subCategoryOption = serviceData.data.sub_category_ids
          .split(",")
          .map(Number);
        setCategoryOptionValue(categoryOption);
        setSubCategoryOptionValue(subCategoryOption);

        const serviceImage = serviceData.data.image_data
          ? parseImage(JSON.stringify(serviceData.data.image_data))
          : [];
        const serviceImageData = serviceImage.map((item) => ({
          imageId: parseInt(item.id),
          url: item.url,
          imageType: parseInt(item.type),
        }));

        setServiceShowImage(true);

        const serviceProvider = serviceData.data.serviceProvider_data
          ? parseImage(serviceData.data.serviceProvider_data)
          : null;
        const serviceProviderData = serviceProvider
          ? serviceProvider.map((item, index) => ({
              id: parseInt(index + 1),
              serviceProviderName: parseInt(item.id),
              serviceProviderPrice: item.price,
            }))
          : [];

        setFields(serviceProviderData);

        const attributeData = serviceData.data.attribute_data
          ? parseImage(serviceData.data.attribute_data)
          : [];

        const attributeImageData = serviceData.data.attribute_image_data
          ? parseImage(JSON.stringify(serviceData.data.attribute_image_data))
          : [];

        const attributeImageMap = new Map(
          attributeImageData.map((item) => [item.id, item])
        );

        // Create a new array with merged data
        const mergedData = attributeData.map((data) => ({
          id: data.id,
          value: data.value,
          attributeImage: attributeImageMap.get(data.attribute_image) || null,
          category_id: parseInt(data.attribute_category),
        }));

        const updatedData = mergedData.map((item, index) => {
          return {
            id: index + 1,
            attributes: parseInt(item.id),
            attributeValues: item.value,
            attributeImage: [
              {
                imageId: parseInt(item.attributeImage.id),
                url: item.attributeImage.url,
                imageType: parseInt(item.attributeImage.type),
              },
            ],
            attributeCategory: item.category_id,
          };
        });
        setAttributeShowImage(true);

        setFieldArrays(updatedData);

        setInitialValues({
          serviceImage: serviceImageData,
          name: serviceData.data.name,
          brand: serviceData.data.brand_id,
          branch: serviceData.data.branch_ids
            ? serviceData.data.branch_ids
                .split(",")
                .map((branchId) => parseInt(branchId.trim()))
            : [],
          category: serviceData.data.category_ids
            ? serviceData.data.category_ids
                .split(",")
                .map((categoryId) => parseInt(categoryId.trim()))
            : [],
          subCategory: serviceData.data.sub_category_ids
            ? serviceData.data.sub_category_ids
                .split(",")
                .map((subCategoryId) => parseInt(subCategoryId.trim()))
            : [],
          serviceProviderName: "",
          serviceProviderPrice: "",
          attributes: "",
          attributeCategory: "",
          attributeValues: "",
          attributeImage: [],
          tag: serviceData.data.tag_ids
            ? serviceData.data.tag_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [],
          description: serviceData.data.description,
          status: serviceData.data.is_active === "1" ? true : false,
        });
      }
      return serviceData;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchServiceData(Data.data.serviceId);
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Setting attribute options data
   */
  const totalAttribute = useSelector((state) => state.totalAttribute);
  const [AttributesOptions, setAttributesOptions] = useState([]);

  /**
   * Fetch attribute data
   */
  const fetchAttribute = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllAttributeService(requestBody, tokenData, dispatch, true);
  };
  useEffect(() => {
    if (totalAttribute && totalAttribute.items.length === 0) {
      fetchAttribute();
    }

    if (
      totalAttribute.items[0]?.data &&
      totalAttribute.items[0].data.length > 0
    ) {
      const transformedArray = totalAttribute.items[0].data.map((item) => ({
        value: item.attributeId,
        label: item.title,
        is_active: item.isActive,
      }));
      setAttributesOptions(transformedArray);
    }
    // eslint-disable-next-line
  }, [totalAttribute]);

  /**
   * Setting category options
   */
  const categoryListData = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const [subCategoryOptionValue, setSubCategoryOptionValue] = useState([]);
  const [attributeCategoryListOptions, setAttributeCategoryListOptions] =
    useState([]);
  const servicePage = useSelector((state) => state.servicePage?.items);

  /**
   * fetch category data
   */
  const fetchCategory = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllCategoryService(categoryRequestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categoryListData?.items?.length) {
      if (
        categoryListData.items[0]?.data &&
        categoryListData.items[0].data.length > 0
      ) {
        const filterCategoryByType = categoryListData.items[0]?.data.filter(
          (item) => item.type === "0" || item.type === 0
        );
        const categoryOptions = filterCategoryByType.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
        }));
        setCategoryListOptions(categoryOptions);
        if (categoryOptionValue && categoryOptionValue.length > 0) {
          const subCategoryOptions = categoryOptions.filter((item2) =>
            categoryOptionValue.some((item1) => item1 === item2.value)
          );
          getFilterOptionData(subCategoryOptions, "category");
        }

        const filterAttributeCategoryByType =
          categoryListData.items[0]?.data.filter(
            (item) => item.type === "2" || item.type === 2
          );
        const attributeCategoryOptions = filterAttributeCategoryByType.map(
          (item) => ({
            value: item.categoryId,
            label: item.name,
            is_active: item.is_active,
          })
        );
        setAttributeCategoryListOptions(attributeCategoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [categoryListData, categoryOptionValue]);

  /**
   * Setting sub category options
   */
  const subCategoryListData = useSelector((state) => state.totalSubCategory);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  const fetchSubCategoryData = () => {
    const roleRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllSubCategoryService(roleRequestBody, tokenData, dispatch, true);
  };
  useEffect(() => {
    if (
      subCategoryListData &&
      subCategoryListData.items &&
      subCategoryListData.items.length === 0
    ) {
      fetchSubCategoryData();
    } else {
      if (
        subCategoryListData.items &&
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        if (subCategoryOptionValue && subCategoryOptionValue.length > 0) {
          const filterSubCategoryByType =
            subCategoryListData.items[0].data.filter(
              (item) => item.type === "0" || item.type === 0
            );
          const subCategoryOptions = filterSubCategoryByType.map((item) => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active,
          }));

          const filteredSubCategoryOptions = subCategoryOptions.filter(
            (item2) =>
              subCategoryOptionValue.some((item1) => item1 === item2.value)
          );
          getFilterOptionData(filteredSubCategoryOptions, "subCategory");
        }
      }
    }
    // eslint-disable-next-line
  }, [subCategoryListData, subCategoryOptionValue]);

  /**
   * Setting sub-category options on selecting category value
   * @param {*} option
   * @param {*} data
   * @param {*} setFieldValue
   */
  const getFilterOptionData = (option, data, setFieldValue) => {
    if (data === "category") {
      if (
        subCategoryListData.items &&
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        const filterSubCategoryByType =
          subCategoryListData.items[0].data.filter(
            (item) => item.type === "0" || item.type === 0
          );
        if (filterSubCategoryByType && filterSubCategoryByType.length > 0) {
          const filteredArr2 = filterSubCategoryByType.filter((item2) =>
            option.some((item1) => item1.value === item2.category_id)
          );
          const subCategoryOptionData = filteredArr2.map((item) => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active,
          }));
          setSubCategoryListOptions(subCategoryOptionData);
        }
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue("subCategory", []);
        }
      }
    }
  };

  /**
   * Setting Brand Options
   */
  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    if (totalBrand && totalBrand.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_limit: 10000000,
      };
      getAllBrandService(requestBody, tokenData, dispatch, true);
    }

    if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
      const brandOptions = totalBrand.items[0].data.map((item) => ({
        value: item.brandId,
        label: item.name,
        is_active: item.isActive,
      }));
      setBrandOptions(brandOptions);
    }
    // eslint-disable-next-line
  }, [totalBrand]);

  /**
   * Setting branch options
   */
  const totalBranch = useSelector((state) => state.totalBranch);
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    if (totalBranch && totalBranch.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_limit: 10000000,
      };
      getAllBranchService(requestBody, tokenData, dispatch, true);
    }

    if (totalBranch.items[0]?.data && totalBranch.items[0].data.length > 0) {
      const branchOptions = totalBranch.items[0].data.map((item) => ({
        value: item.branchId,
        label: item.name,
        is_active: item.isActive,
      }));
      setBranchOptions(branchOptions);
    }
    // eslint-disable-next-line
  }, [totalBranch]);

  /**
   * Setting tags options
   */
  const totalTag = useSelector((state) => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  useEffect(() => {
    if (totalTag && totalTag.items && totalTag.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_limit: 5000000,
      };
      getAllTagsService(requestBody, tokenData, dispatch, true);
    } else {
      if (totalTag.items[0]?.data && totalTag.items[0].data.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
          is_active: item.isActive,
        }));
        setTagListOptions(tagOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalTag]);

  /**
   * Setting service provider options
   */
  const totalServiceProvider = useSelector(
    (state) => state.totalServiceProvider
  );
  const [serviceProviderListOptions, setServiceProviderListOptions] = useState(
    []
  );

  useEffect(() => {
    if (
      totalServiceProvider &&
      totalServiceProvider.items &&
      totalServiceProvider.items.length === 0
    ) {
      const requestBody = {
        p_skip: 0,
        p_limit: 5000000,
      };
      getAllServiceProviderService(requestBody, tokenData, dispatch, true);
    } else {
      if (
        totalServiceProvider.items[0]?.data &&
        totalServiceProvider.items[0].data.length > 0
      ) {
        const serviceProviderOptions = totalServiceProvider.items[0].data.map(
          (item) => ({
            value: item.serviceProviderId,
            label: item.name,
            is_active: item.isActive,
          })
        );
        setServiceProviderListOptions(serviceProviderOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalServiceProvider]);

  /**
   * function for adding service provider
   */
  const handleAddField = (values, setFieldValue) => {
    if (
      values.serviceProviderName === "" ||
      values.serviceProviderPrice === ""
    ) {
      toast.warn("Please add service provider name and price!");
      return;
    } else {
      const conflicts =
        fields && fields.length > 0
          ? fields.some(
              (field) =>
                field.serviceProviderName === values.serviceProviderName
              // && field.serviceProviderPrice === values.serviceProviderPrice
            )
          : false;

      if (conflicts) {
        toast.error("Please add unique service provider!");
        return;
      }

      let newId;
      if (fields.length === 0) {
        newId = fields.length + 1;
      } else {
        const lastKey = fields[fields.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        serviceProviderName: values.serviceProviderName,
        serviceProviderPrice: values.serviceProviderPrice,
      };
      setFieldValue("serviceProviderName", "");
      setFieldValue("serviceProviderPrice", "");
      setFields([...fields, newField]);
    }
  };

  /**
   * Function for adding attribute data
   */
  const handleAddFieldArray = (values, setFieldValue) => {
    if (
      values.attributes === "" ||
      values.attributeValues === "" ||
      values.attributeCategory === ""
    ) {
      toast.warn(
        "Please select attribute, attribute category and its value before adding!"
      );
      return;
    }
    let newId;
    if (fieldArrays.length === 0) {
      newId = fieldArrays.length + 1;
    } else {
      const lastKey = fieldArrays[fieldArrays.length - 1].id;
      newId = lastKey + 1;
    }
    const newField = {
      id: newId,
      attributes: values.attributes,
      attributeValues: values.attributeValues,
      attributeImage: values.attributeImage,
      attributeCategory: values.attributeCategory,
    };
    setFieldValue("attributes", "");
    setFieldValue("attributeValues", "");
    setFieldValue("attributeImage", "");
    setFieldValue("attributeCategory", "");
    setFieldArrays([...fieldArrays, newField]);
  };

  /**
   * Updating service provider and attributes values in reverse order
   */
  const changeInputData = (index, value, setFieldValue, Regex) => {
    let valueData;
    if (Regex) {
      const newValue = value.replace(Regex, "");
      valueData = newValue;
    } else {
      valueData = value;
    }
    if (setFieldValue === "serviceProviderPrice") {
      let listData = [...fields];
      listData[fields.length - index - 1]["serviceProviderPrice"] = valueData;
      setFields(listData);
    } else {
      let listData = [...fieldArrays];
      listData[fieldArrays.length - index - 1]["attributeValues"] = valueData;
      setFieldArrays(listData);
    }
  };

  /**
   * Removing service provider data
   */
  const handleRemoveField = (idToRemove) => {
    const list = [...fields];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFields(filterField);
  };

  /**
   * Removing attribute data
   */
  const handleRemoveArrayField = (idToRemove) => {
    const list = [...fieldArrays];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFieldArrays(filterField);
  };

  /**
   * Updating attribute images in reverse order
   */
  const changeImageData = (index, value) => {
    let listData = [...fieldArrays];
    listData[fieldArrays.length - index - 1]["attributeImage"] = value;
    setFieldArrays(listData);
  };

  /**
   * Updating service provider name and attributes in reverse order
   */
  const onChangeData = (index, value, category) => {
    if (category && category === "serviceProviderName") {
      let listData = [...fields];
      const conflicts =
        listData && listData.length > 0
          ? fields.some(
              (field) => field.serviceProviderName === value
              // && field.serviceProviderPrice === parseInt(values.serviceProviderPrice)
            )
          : false;

      if (conflicts) {
        toast.error("Please add unique service provider!");
        return;
      }
      listData[fields.length - index - 1][category] = value;
      setFields(listData);
    } else {
      let listData = [...fieldArrays];
      listData[fieldArrays.length - index - 1][category] = value;
      setFieldArrays(listData);
    }
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    //Setting attribute data
    let attributeData =
      fieldArrays.length > 0
        ? fieldArrays.map((item) => ({
            id: item.attributes,
            value: item.attributeValues,
            imageId:
              item.attributeImage &&
              item.attributeImage[0] &&
              item.attributeImage[0].imageId
                ? item.attributeImage[0].imageId
                : null,
            type_id:
              item.attributeImage &&
              item.attributeImage[0] &&
              item.attributeImage[0].imageType
                ? item.attributeImage[0].imageType
                : null,
            category_id: item.attributeCategory,
          }))
        : [];

    if (attributeData.length < 1) {
      if (
        values.attributes !== "" &&
        values.attributeValues !== "" &&
        values.attributeCategory !== ""
      ) {
        attributeData.push({});
        attributeData[0].id = values.attributes;
        attributeData[0].value = values.attributeValues;
        attributeData[0].imageId =
          values.attributeImage &&
          values.attributeImage[0] &&
          values.attributeImage[0].imageId
            ? values.attributeImage[0].imageId
            : null;
        attributeData[0].type_id =
          values.attributeImage &&
          values.attributeImage[0] &&
          values.attributeImage[0].imageType
            ? values.attributeImage[0].imageType
            : null;
        attributeData[0].category_id = values.attributeCategory;
      } else {
        toast.error("Please select attribute name, category and it's value!");
        return;
      }
    } else {
      if (
        values.attributes !== "" &&
        values.attributeValues !== "" &&
        values.attributeCategory !== ""
      ) {
        attributeData.push({
          id: values.attributes,
          value: values.attributeValues,
          imageId:
            values.attributeImage &&
            values.attributeImage[0] &&
            values.attributeImage[0].imageId
              ? values.attributeImage[0].imageId
              : null,
          type_id:
            values.attributeImage &&
            values.attributeImage[0] &&
            values.attributeImage[0].imageType
              ? values.attributeImage[0].imageType
              : null,
          category_id: values.attributeCategory,
        });
      }
    }

    //Setting service provider data
    let serviceProviderData =
      fields.length > 0
        ? fields.map((item) => ({
            provider_id: item.serviceProviderName,
            price: parseInt(item.serviceProviderPrice),
          }))
        : [];

    if (serviceProviderData.length < 1) {
      if (
        values.serviceProviderName !== "" &&
        values.serviceProviderPrice !== ""
      ) {
        const conflicts =
          fields && fields.length > 0
            ? fields.some(
                (field) =>
                  field.serviceProviderName === values.serviceProviderName
              )
            : false;

        if (conflicts) {
          toast.error("Please add unique service provider!");
          return;
        } else {
          serviceProviderData.push({});
          serviceProviderData[0].provider_id = values.serviceProviderName;
          serviceProviderData[0].price = parseInt(values.serviceProviderPrice);
        }
      } else {
        toast.error("Please select attribute name, category and it's value!");
        return;
      }
    } else {
      if (
        values.serviceProviderName !== "" &&
        values.serviceProviderPrice !== ""
      ) {
        const conflicts =
          fields && fields.length > 0
            ? fields.some(
                (field) =>
                  field.serviceProviderName === values.serviceProviderName
              )
            : false;

        if (conflicts) {
          toast.error("Please add unique service provider!");
          return;
        } else {
          serviceProviderData.push({
            provider_id: values.serviceProviderName,
            price: parseInt(values.serviceProviderPrice),
          });
        }
      }
    }

    const imageData =
      values.serviceImage && values.serviceImage.length > 0
        ? values.serviceImage.map((item) => ({
            id: item.imageId,
            type_id: item.imageType,
          }))
        : [];

    const categoryData =
      values.category && values.category.length > 0
        ? values.category.map((item) => ({
            id: item,
          }))
        : [];

    const subCategoryData =
      values.subCategory && values.subCategory.length > 0
        ? values.subCategory.map((item) => ({
            id: item,
          }))
        : [];

    const branchData =
      values.branch && values.branch.length > 0
        ? values.branch.map((item) => ({
            id: item,
          }))
        : [];

    const tagData =
      values.tag && values.tag.length > 0
        ? values.tag.map((item) => ({
            id: item,
          }))
        : [];

    if (!Data) {
      const requestBody = {
        p_brand_id: parseInt(values.brand),
        p_name: values.name,
        p_description: values.description,
        p_Tags: tagData,
        p_imageIds: imageData,
        p_category_ids: categoryData,
        p_sub_category_ids: subCategoryData,
        p_attributeData: attributeData,
        p_branch_ids: branchData,
        p_serviceProviderData: serviceProviderData,
        p_is_active:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_created_by: user.data.userId,
      };
      dispatch(
        addUpdateServiceService(requestBody, tokenData, "add", navigate)
      );
    } else {
      const requestBody = {
        p_id: Data.data.serviceId,
        p_brand_id: parseInt(values.brand),
        p_name: values.name,
        p_description: values.description,
        p_Tags: tagData,
        p_imageIds: imageData,
        p_category_ids: categoryData,
        p_sub_category_ids: subCategoryData,
        p_attributeData: attributeData,
        p_branch_ids: branchData,
        p_serviceProviderData: serviceProviderData,
        p_is_active:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_IsDeleted: Data.data.isDeleted,
        p_created_by: Data.data.createdBy,
        p_modified_by: user.data.userId,
      };
      if (Data?.length && servicePage > 1) {
        if (
          values.status === "0" ||
          values.status === 0 ||
          values.status === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateServiceService(
                requestBody,
                tokenData,
                "update",
                navigate,
                servicePage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateServiceService(
                requestBody,
                tokenData,
                "update",
                navigate,
                servicePage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateServiceService(
              requestBody,
              tokenData,
              "update",
              navigate,
              servicePage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateServiceService(requestBody, tokenData, "update", navigate),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className=" add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  Service
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/service")}>
                  Service
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  Service
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? "Edit "
                : Data && Data.isEdit === false
                ? "View "
                : "Add "}{" "}
              Service
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="upload-image">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Upload Service Image
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <UploadImage
                        name="serviceImage"
                        onChange={setFieldValue}
                        value={values.serviceImage}
                        filterByType={13}
                        setErrorImages={setServiceErrorImages}
                        disabled={Data && Data.isEdit === false}
                        setShowImage={setServiceShowImage}
                      />
                      <ShowImage
                        value={values.serviceImage}
                        errorImages={serviceErrorImages}
                        showImage={serviceShowImage}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Name<span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Enter Name"
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        value={values.name}
                        maxLength={20}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Brand<span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="brand"
                        options={brandOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={false}
                        value={values.brand}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Branch selection
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="branch"
                        options={branchOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={true}
                        value={values.branch}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Category<span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="category"
                        options={categoryListOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={true}
                        value={values.category}
                        onchangedata={(option) =>
                          getFilterOptionData(option, "category", setFieldValue)
                        }
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Sub Category<span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="subCategory"
                        options={subCategoryListOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={true}
                        value={values.subCategory}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Service provider :-</h4>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Name
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Price
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-1">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Add</h4>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <Field
                          className="select-menu mb-2"
                          name="serviceProviderName"
                          options={serviceProviderListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={
                            values.serviceProviderName === "" ||
                            values.serviceProviderName === null
                              ? false
                              : values.serviceProviderName
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                      <div className="col-md-5">
                        <div className="name-type">
                          <Input
                            type="text"
                            name="serviceProviderPrice"
                            label="price"
                            placeholder="Enter Price"
                            className="add-roles"
                            Regex={/[^0-9]/g}
                            maxLength={20}
                            value={values.serviceProviderPrice}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-1">
                          <Button
                            className="btn btn-fillter-main"
                            type="button"
                            onClick={() =>
                              handleAddField(values, setFieldValue)
                            }
                            name={
                              <div className="Plus">
                                <FaPlus />
                              </div>
                            }
                          ></Button>
                        </div>
                      )}
                    </div>
                    <div>
                      {fields &&
                        fields.length > 0 &&
                        fields
                          .slice()
                          .reverse()
                          .map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-5">
                                <Field
                                  className="select-menu mb-2"
                                  name={`serviceProviderName${index}`}
                                  options={serviceProviderListOptions}
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  value={field.serviceProviderName}
                                  changeData={(value) =>
                                    onChangeData(
                                      index,
                                      value,
                                      "serviceProviderName"
                                    )
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                              <div className="col-md-5">
                                <div className="name-type">
                                  <Input
                                    type="text"
                                    name={`serviceProviderPrice${index}`}
                                    label="Price"
                                    placeholder="Enter Price"
                                    className="add-roles"
                                    value={field.serviceProviderPrice}
                                    Regex={/[^0-9]/g}
                                    changeInputData={(event, Regex) =>
                                      changeInputData(
                                        index,
                                        event.target.value,
                                        "serviceProviderPrice",
                                        Regex
                                      )
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              {((Data && Data.isEdit === true) || !Data) && (
                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() => handleRemoveField(field.id)}
                                    name={
                                      <div className="Plus">
                                        <FaMinus />
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Attributes :-</h4>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Attributes
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Attribute Category
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Values
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Attribute Image</h4>
                        </div>
                      </div>
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Add</h4>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Field
                          className="select-menu mb-2"
                          name="attributes"
                          options={AttributesOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={
                            values.attributes === "" ||
                            values.attributes === null
                              ? false
                              : values.attributes
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          className="select-menu mb-2"
                          name="attributeCategory"
                          options={attributeCategoryListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={
                            values.attributeCategory === "" ||
                            values.attributeCategory === null
                              ? false
                              : values.attributeCategory
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="name-type">
                          <Input
                            type="text"
                            name="attributeValues"
                            label="values"
                            placeholder="Enter Values"
                            className="add-roles"
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <UploadImage
                          name="attributeImage"
                          onChange={setFieldValue}
                          value={values.attributeImage}
                          restrictOne={true}
                          filterByType={12}
                          disabled={Data && Data.isEdit === false}
                          setErrorImages={setAttributeErrorImages}
                        />

                        <ShowImage
                          value={values.attributeImage}
                          errorImages={attributeErrorImages}
                        />
                      </div>
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-1">
                          <Button
                            className="btn btn-fillter-main"
                            type="button"
                            onClick={() =>
                              handleAddFieldArray(values, setFieldValue)
                            }
                            name={
                              <div className="Plus">
                                <FaPlus />
                              </div>
                            }
                          ></Button>
                        </div>
                      )}
                    </div>
                    <div>
                      {fieldArrays &&
                        fieldArrays.length > 0 &&
                        fieldArrays
                          .slice()
                          .reverse()
                          .map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-3">
                                <Field
                                  className="select-menu mb-2"
                                  name={`attributes${index}`}
                                  options={AttributesOptions}
                                  changeData={(value) =>
                                    onChangeData(index, value, "attributes")
                                  }
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  value={field.attributes}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                              <div className="col-md-3">
                                <Field
                                  className="select-menu mb-2"
                                  name={`attributeCategory${index}`}
                                  options={attributeCategoryListOptions}
                                  changeData={(value) =>
                                    onChangeData(
                                      index,
                                      value,
                                      "attributeCategory"
                                    )
                                  }
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  value={field.attributeCategory}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                              <div className="col-md-2">
                                <div className="name-type">
                                  <Input
                                    type="text"
                                    name={`attributeValues${index}`}
                                    label="values"
                                    placeholder="Enter Values"
                                    className="add-roles"
                                    value={field.attributeValues}
                                    changeInputData={(event) =>
                                      changeInputData(
                                        index,
                                        event.target.value,
                                        "attributeValues"
                                      )
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <UploadImage
                                  name={`attributeImage${index}`}
                                  onChange={setFieldValue}
                                  changeImageData={(value) =>
                                    changeImageData(index, value)
                                  }
                                  value={
                                    values[`attributeImage${index}`]
                                      ? values[`attributeImage${index}`]
                                      : field.attributeImage
                                  }
                                  restrictOne={true}
                                  filterByType={12}
                                  setErrorImages={setAttributeErrorImages}
                                  setShowImage={setAttributeShowImage}
                                  disabled={Data && Data.isEdit === false}
                                />
                                <ShowImage
                                  value={
                                    values[`attributeImage${index}`]
                                      ? values[`attributeImage${index}`]
                                      : field.attributeImage
                                  }
                                  errorImages={attributeErrorImages}
                                  showImage={attributeShowImage}
                                />
                              </div>
                              {((Data && Data.isEdit === true) || !Data) && (
                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() =>
                                      handleRemoveArrayField(field.id)
                                    }
                                    name={
                                      <div className="Plus">
                                        <FaMinus />
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Tag<span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="tag"
                        options={tagListOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={true}
                        value={values.tag}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Description<span className="required-label"></span>
                      </h4>
                      <Field
                        name="description"
                        component={CKEditorCpmponent}
                        maxLength={255}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Status</h4>
                      <Field
                        name="status"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  {((Data && Data.isEdit === true) || !Data) && (
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          <Button
                            type="submit"
                            name="Save"
                            className="btn login-btn"
                          />
                          <Button
                            type="button"
                            name="Cancel"
                            className="btn clear-btn login-btn"
                            onClick={() =>
                              handleCancleClick("/service", navigate)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

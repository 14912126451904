import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  getSalesDataRequest,
  getSalesDataResponse,
  getSalesTableDataRequest,
} from "./AnalyticsModal";
import { toast } from "react-toastify";
import { getSalesGraphData } from "../../../redux/Actions/AnalyticsActions/SalesGraphAction";
import { getSalesTableData } from "../../../redux/Actions/AnalyticsActions/SalesTableAction";
import { formatDateToUTC } from "../../../services/Regular-services/DateService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function AnalyticsService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Get branch list service
   */
  const getSalesChartDataService = (
    salesDataRequest: getSalesDataRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getSalesDataResponse> => {
    return new Promise<getSalesDataResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(salesDataRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ANALYTICS +
            API_URL.SALESANALYTICS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            //   dispatch(getTotalBranchData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getSalesGraphData(response));
          } else {
            dispatch(getSalesGraphData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Get branch list service
   */
  const getSalesTableDataService = (
    salesTableDataRequest: getSalesTableDataRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getSalesDataResponse> => {
    return new Promise<getSalesDataResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(salesTableDataRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ANALYTICS +
            API_URL.SALESTABLE +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            // dispatch(getTotalCustomerOrderData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(
              getSalesTableData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: null,
              })
            );
          } else {
            if (response.data && response.data.length > 0) {
              const pendingCount = response.data.filter(
                (item: any) => item.status === "0"
              ).length;
              dispatch(
                getSalesTableData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: pendingCount,
                })
              );
            } else {
              dispatch(
                getSalesTableData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: 0,
                })
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  return { getSalesChartDataService, getSalesTableDataService };
}
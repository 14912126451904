import {
  CLEAR_ALL_PREFERENCE_FILTER,
  GET_ALL_PREFERENCE_FILTER,
} from "../../ReduxKeys";

const allPreferencesFilterInitialState = {
  p_user_id: "",
  p_product_name: "",
};

/**
 * Store preferences filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const AllPreferencesFilterReducer = (
  state = allPreferencesFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_PREFERENCE_FILTER:
      return {
        ...state,
        p_user_id: action.payload.p_user_id,
        p_product_name: action.payload.p_product_name,
      };
    case CLEAR_ALL_PREFERENCE_FILTER:
      return allPreferencesFilterInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_TOTAL_UNIT_DROPDOWN_DATA,
  CLEAR_UNIT_DROPDOWN_DATA,
  GET_TOTAL_UNIT_DROPDOWN_DATA,
  GET_UNIT_DROPDOWN_DATA,
} from "../../ReduxKeys";

const unitsDropdownInitialState = {
  items: [],
};

/**
 * Store All Units list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const UnitsDropdownReducer = (
  state = unitsDropdownInitialState,
  action
) => {
  switch (action.type) {
    case GET_UNIT_DROPDOWN_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_UNIT_DROPDOWN_DATA:
      return unitsDropdownInitialState;
    default:
      return state;
  }
};

const totalUnitsDropdownInitialState = {
  items: [],
};

/**
 * Store total units dropdown list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalUnitsDropdownReducer = (
  state = totalUnitsDropdownInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_UNIT_DROPDOWN_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_UNIT_DROPDOWN_DATA:
      return totalUnitsDropdownInitialState;
    default:
      return state;
  }
};
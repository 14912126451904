import {
  CLEAR_TAG_DATA,
  CLEAR_TOTAL_TAG_DATA,
  GET_TAG_DATA,
  GET_TOTAL_TAG_DATA,
} from "../../ReduxKeys";

/**
 * Set tag list data into reducer
 * @param {*} data
 * @returns {*}
 */
export const getTagData = (data) => ({
  type: GET_TAG_DATA,
  payload: data,
});

/**
 * Clear tag list data
 * @returns {*}
 */
export const clearTagData = () => ({
  type: CLEAR_TAG_DATA,
});

/**
 * Set total tag list data into reducer (No pagination)
 * @param {*} tag
 * @returns {*}
 */
export const getTotalTagData = (tag) => ({
  type: GET_TOTAL_TAG_DATA,
  payload: tag,
});

/**
 * Clear total tag list data (No pagination)
 * @returns {*}
 */
export const clearTotalTagData = () => ({
  type: CLEAR_TOTAL_TAG_DATA,
});
import {
  CLEAR_CUSTOMER_NOTES,
  CLEAR_TOTAL_CUSTOMER_NOTES,
  GET_CUSTOMER_NOTES,
  GET_TOTAL_CUSTOMER_NOTES,
} from "../../ReduxKeys";

const customerNoteInitialState = {
  items: [],
};

/**
 * Store customer note list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const customerNoteReducer = (
  state = customerNoteInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_NOTES:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_CUSTOMER_NOTES:
      return customerNoteInitialState;
    default:
      return state;
  }
};

const totalCustomerNoteInitialState = {
  items: [],
};

/**
 * Store total PREFERENCESlist data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalCustomerNoteReducer = (
  state = totalCustomerNoteInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_CUSTOMER_NOTES:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_CUSTOMER_NOTES:
      return totalCustomerNoteInitialState;
    default:
      return state;
  }
};
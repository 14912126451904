import {
  CLEAR_PAYMENT_DETAIL_DATA,
  GET_PAYMENT_DETAIL_DATA,
} from "../../ReduxKeys";

const paymentDetailInitialState = {
  items: [],
};

/**
 * Store Payment Detail list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PaymentDetailReducer = (
  state = paymentDetailInitialState,
  action
) => {
  switch (action.type) {
    case GET_PAYMENT_DETAIL_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_PAYMENT_DETAIL_DATA:
      return paymentDetailInitialState;
    default:
      return state;
  }
};
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  addUpdateExpenseRequest,
  getAllExpenseRequest,
  getAllExpenseResponse,
} from "./ExpenseModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  clearExpenseData,
  getExpenseData,
  getTotalExpenseData,
} from "../../../redux/Actions/ExpenseAction/ExpenseAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";
import {
  clearExpensePageData,
  getExpensePageData,
} from "../../../redux/Actions/ExpenseAction/ExpensePageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearExpenseFilterData } from "../../../redux/Actions/ExpenseAction/ExpenseFilterAction";
import { clearExpenseGraphData } from "../../../redux/Actions/ExpenseAction/ExpenseGraphAction";
import { clearExpenseGraphFilterData } from "../../../redux/Actions/ExpenseAction/ExpenseGraphFilterAction";

let errorShow = false;
export default function ExpenseService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get inventory item list
   */
  const getAllExpenseService = (
    expenseRequest: getAllExpenseRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllExpenseResponse> => {
    return new Promise<getAllExpenseResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          expenseRequest.p_skip !== undefined &&
          expenseRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${expenseRequest.p_skip}&`;
        }
        if (
          expenseRequest.p_take !== undefined &&
          expenseRequest.p_take !== null
        ) {
          queryParams += `p_take=${expenseRequest.p_take}&`;
        }
        if (
          expenseRequest.p_name !== undefined &&
          expenseRequest.p_name !== null
        ) {
          queryParams += `p_name=${expenseRequest.p_name}&`;
        }
        if (
          expenseRequest.p_amount !== undefined &&
          expenseRequest.p_amount !== null
        ) {
          queryParams += `p_amount=${expenseRequest.p_take}&`;
        }
        if (
          expenseRequest.p_start_date !== undefined &&
          expenseRequest.p_start_date !== null
        ) {
          queryParams += `p_start_date=${expenseRequest.p_start_date}&`;
        }
        if (
          expenseRequest.p_end_date !== undefined &&
          expenseRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${expenseRequest.p_end_date}&`;
        }
        if (
          expenseRequest.p_category_id !== undefined &&
          expenseRequest.p_category_id !== null
        ) {
          queryParams += `p_category_id=${expenseRequest.p_category_id}&`;
        }
        if (
          expenseRequest.p_time_zone !== undefined &&
          expenseRequest.p_time_zone !== null
        ) {
          queryParams += `p_time_zone=${expenseRequest.p_time_zone}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.EXPENSE +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalExpenseData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getExpenseData(response));
          } else {
            dispatch(getExpenseData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating Inventory Item
   */
  const addUpdateExpenseService = (
    addUpdateExpenseRequest: addUpdateExpenseRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.EXPENSE,
            token,
            addUpdateExpenseRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.EXPENSE,
            token,
            addUpdateExpenseRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogFilterData());
          dispatch(clearExpenseData());
          dispatch(clearExpensePageData());
          dispatch(clearExpenseGraphData());
          dispatch(clearExpenseGraphFilterData());
          if (type === "add") {
            toast.success(
              response?.message
                ? response.message
                : "Expense added successfully!"
            );
            navigate("/expense");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Expense undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getExpensePageData(pageNumber + 1));
                } else {
                  dispatch(clearExpensePageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Expense updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/expense");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Expense status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getExpensePageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting expense item
   */
  const deleteExpenseService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.EXPENSE + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearExpenseData());
          dispatch(clearExpenseFilterData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          toast.success(
            response?.message
              ? response.message
              : "Expense deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          dispatch(getExpensePageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllExpenseService,
    addUpdateExpenseService,
    deleteExpenseService,
  };
}

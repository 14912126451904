import {
  CLEAR_DISCOUNT_CATALOGUE_DATA,
  CLEAR_TOTAL_CATALOGUE_DISCOUNT,
  GET_DISCOUNT_CATALOGUE_DATA,
  GET_TOTAL_CATALOGUE_DISCOUNT,
} from "../../ReduxKeys";

const DiscountCatalogueInitialState = {
  items: [],
};

/**
 * Store list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const DiscountCatalogueReducer = (
  state = DiscountCatalogueInitialState,
  action
) => {
  switch (action.type) {
    case GET_DISCOUNT_CATALOGUE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_DISCOUNT_CATALOGUE_DATA:
      return DiscountCatalogueInitialState;
    default:
      return state;
  }
};

const totalDiscountCatalogueInitialState = {
  items: [],
};

/**
 * Store total list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalDiscountCatalogueReducer = (
  state = totalDiscountCatalogueInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_CATALOGUE_DISCOUNT:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_CATALOGUE_DISCOUNT:
      return totalDiscountCatalogueInitialState;
    default:
      return state;
  }
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.btn-new {
  width: 40%;
  color: #f1f1f1;
  background-color: #1D3B42;
  padding: 7px 10px;
  border-radius: 5px;
  border: unset;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-detail-heading {
  margin-bottom: 10px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PurchaseRequisition/AddPurchaseRequisition/AddPurchaseRequisition.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,cAAA;AACJ;;AAEA;EACI,UAAA;EACA,cAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".btn-container{\n    display: flex;\n    justify-content: space-between;\n    margin: 10px 0;\n}\n\n.btn-new{\n    width: 40%;\n    color: #f1f1f1;\n    background-color: #1D3B42;\n    padding: 7px 10px;\n    border-radius: 5px;\n    border: unset;\n    text-align: center;\n    cursor: pointer;\n    font-size: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.order-detail-heading{\n    margin-bottom: 10px;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { CLEAR_GRN_PAGE, GET_GRN_PAGE } from "../../ReduxKeys";

/**
 * Set GRN page data
 * @param {*} page
 * @returns {*}
 */
export const getGRNPageData = (page) => ({
  type: GET_GRN_PAGE,
  payload: page,
});

/**
 * Clear GRN page data
 * @returns {*}
 */
export const clearGRNPageData = () => ({
  type: CLEAR_GRN_PAGE,
});
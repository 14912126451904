import {
  CLEAR_STOCK_HISTORY_DATA,
  CLEAR_TOTAL_STOCK_HISTORY,
  GET_STOCK_HISTORY_DATA,
  GET_TOTAL_STOCK_HISTORY,
} from "../../ReduxKeys";

const stockHistoryInitialState = {
  items: [],
};

/**
 * Store stock hsitory list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const StockHistoryReducer = (
  state = stockHistoryInitialState,
  action
) => {
  switch (action.type) {
    case GET_STOCK_HISTORY_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_STOCK_HISTORY_DATA:
      return stockHistoryInitialState;
    default:
      return state;
  }
};

const totalStockHistoryInitialState = {
  items: [],
};

/**
 * Store total stock history list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalStockHistoryReducer = (
  state = totalStockHistoryInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_STOCK_HISTORY:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_STOCK_HISTORY:
      return totalStockHistoryInitialState;
    default:
      return state;
  }
};
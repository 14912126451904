import { LOGIN, LOGIN_FAILURE, LOGOUT, UPDATE_AUTH } from "../../ReduxKeys";

/**
 * Setting user data into reducer after login
 * @param {*} user
 * @param {*} rememberMe
 * @param {*} loginTime
 * @returns {*}
 */
export const login = (user, loginTime) => ({
  type: LOGIN,
  payload: { user, loginTime },
});

export const updateAuthData = ({ user, reload }) => ({
  type: UPDATE_AUTH,
  payload: { user, reload },
});

/**
 * action for logout user
 * @returns {*}
 */
export const logout = () => ({
  type: LOGOUT,
});

/**
 * Action for login fail, if failed to login!
 * @returns {*}
 */
export const loginFail = () => ({
  type: LOGIN_FAILURE,
});
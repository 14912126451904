// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.save-all svg {
  font-size: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductPricing/ProductPricing.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;;AACA;EACI,0BAAA;AAEJ","sourcesContent":[".pricing-input{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto;\n}\n.save-all svg {\n    font-size: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { orderDate } from "../../../services/Regular-services/DateService";
import {
  CLEAR_LOCAL_ORDER_FILTER,
  CLEAR_ORDER_FILTER,
  GET_ORDER_FILTER,
} from "../../ReduxKeys";

const orderFilterInitialState = {
  orderDate: orderDate(),
  status: "",
  orderType: "",
};

/**
 * Store order filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const OrderFilterReducer = (state = orderFilterInitialState, action) => {
  switch (action.type) {
    case GET_ORDER_FILTER:
      return {
        ...state,
        orderDate: action.payload.orderDate,
        status: action.payload.status,
        orderType: action.payload.orderType,
      };
    case CLEAR_ORDER_FILTER:
      return orderFilterInitialState;
    default:
      return state;
  }
};

// CLEAT_LOCAL_ORDER_FILTER

const clearLocalOrderFilterInitialState = {
  type: false,
};

/**
 * Store order filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ClearLocalOrderFilterReducer = (
  state = clearLocalOrderFilterInitialState,
  action
) => {
  switch (action.type) {
    case CLEAR_LOCAL_ORDER_FILTER:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
};
import {
  CLEAR_ACCOUNT_DETAIL_DATA,
  GET_ACCOUNT_DETAIL_DATA,
} from "../../ReduxKeys";

const accountDetailsInitialState = {
  items: [],
};

/**
 * Store Payment Detail list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const AccountDetailReducer = (
  state = accountDetailsInitialState,
  action
) => {
  switch (action.type) {
    case GET_ACCOUNT_DETAIL_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_ACCOUNT_DETAIL_DATA:
      return accountDetailsInitialState;
    default:
      return state;
  }
};
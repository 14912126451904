export const WarehouseToggleRequest = ({
  toggleData,
  userId,
  isActive,
  isDelete,
}) => {
  const requestBody = {
    p_id: toggleData.warehouseId,
    p_name: toggleData.name,
    p_address: toggleData.address,
    p_pincode: toggleData.pincode,
    p_isActive: isActive
      ? toggleData.is_active === "1"
        ? "0"
        : "1"
      : toggleData.is_active,
    p_IsDeleted: isDelete ? "0" : toggleData.IsDeleted,
    p_modified_by: userId,
  };
  return requestBody;
};
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import CategoryService from "../../Category/Category-service/CategoryService";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { modifyString } from "../../../services/Regular-services/StringService";
import SubCategoryServices from "../SubCategoryService/SubCategoryService";

export default function AddSubCategory() {
  const { getAllCategoryService } = CategoryService();
  const { addUpdateSubCategoryService, getSubCategoryById } =
    SubCategoryServices();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    subCategoryName: "",
    subCategoryCode: "",
    subCategoryImage: [],
    categorySelect: "",
    subCategorytype: "",
    description: "",
    status: true,
  });
  const { handleCancleClick, handlesaveClick } = AlertMessage();

  const [errorImages, setErrorImages] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryListData = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [categoryNewOptionsData, setCategoryNewOptionsData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [subCategoryActionData, setsubCategoryActionData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);

  const token = UtilsData();
  const userData = useSelector((state) => state.auth.user);
  const [showImage, setShowImage] = useState(false);
  const SubCategoryPage = useSelector((state) => state.SubCategoryPage?.items);

  //Validation schema
  const validationSchema = Yup.object({
    subCategoryName: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
    subCategoryCode: Yup.string().required(t(ValidationMessage.CODE_REQUIRED)),
    categorySelect: Yup.string().required(
      t(ValidationMessage.SINGLE_CATEGORY_SELECT)
    ),
    subCategorytype: Yup.string().required(t(ValidationMessage.TYPE_SELECT)),
    description: Yup.string().required(
      t(ValidationMessage.DESCRIPTION_REQUIRED)
    ),
    subCategoryImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
  });

  useEffect(() => {
    if (Array.isArray(sideBarData)) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "sub-category"
      );
      setsubCategoryActionData(actionData);
    } else {
      toast.error(t(t(ValidationMessage.SOMETHING_WENT_WRONG)));
    }
    // eslint-disable-next-line
  }, [sideBarData, localisation]);
  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  /**
   * Fetching sub category detail data
   * @returns {*}
   */
  const fetchSubCategory = async () => {
    try {
      // Call the getRoleById function
      const subCategoryData = await getSubCategoryById(
        Data.data.id,
        token,
        dispatch
      );

      const subCategoryImage = subCategoryData.data.image_data
        ? parseImage(JSON.stringify(subCategoryData.data.image_data))
        : null;
      const subCategoryImageData = subCategoryImage
        ? subCategoryImage.map((item) => ({
            imageId: parseInt(item.id),
            url: item.url,
            imageType: parseInt(item.type),
          }))
        : [];
      setShowImage(true);

      setInitialValues({
        subCategoryName: Data.data.name,
        subCategoryCode: Data.data.code,
        subCategoryImage: subCategoryImageData,
        categorySelect: Data.data.categoryId,
        subCategorytype: Data.data.typeId,
        description: Data.data.description,
        status:
          Data.data.isActive === "1" ||
          Data.data.isActive === true ||
          Data.data.isActive === 1
            ? true
            : false,
      });
      return subCategoryData;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  //Reinitializing initial values
  useEffect(() => {
    if (Data && Data.data) {
      fetchSubCategory();
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Fetch category data
   */
  const fetchCategoryData = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllCategoryService(categoryRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    fetchCategoryData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categoryListData?.items?.length) {
      if (
        categoryListData.items[0]?.data &&
        categoryListData.items[0].data.length > 0
      ) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
          type: item.type,
        }));
        if (Data && Data.data) {
          handleSubCategoryTypeChange(Data.data.typeId, "", categoryOptions);
        }
        setCategoryListOptions(categoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [categoryListData, Data]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    const imageId = values.subCategoryImage.map((item) => ({
      id: item.imageId,
      type_id: item.imageType,
    }));
    const requestBody = {
      ...(Data && Data?.data && { p_id: Data.data.id }),
      p_categoryid: values.categorySelect,
      p_name: values.subCategoryName,
      p_description: values.description,
      p_subcategorycode: values.subCategoryCode,
      p_isActive:
        values.status === true || values.status === 1 || values.status === "1"
          ? "1"
          : "0",
      p_type: values.subCategorytype,
      ...(Data && Data?.data && { p_deleted: Data.data.isDeleted }),
      p_createdBy:
        Data && Data?.data ? Data.data.createdBy : userData.data.userId,
      ...(Data && Data?.data && { p_modifiedBy: userData.data.userId }),
      p_imageIds: imageId,
    };
    if (!Data) {
      dispatch(
        addUpdateSubCategoryService(requestBody, token, "add", navigate)
      );
    } else {
      if (Data?.length && SubCategoryPage > 1) {
        if (
          values.status === "0" ||
          values.status === 0 ||
          values.status === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateSubCategoryService(
                requestBody,
                token,
                "update",
                navigate,
                SubCategoryPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateSubCategoryService(
                requestBody,
                token,
                "update",
                navigate,
                SubCategoryPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateSubCategoryService(
              requestBody,
              token,
              "update",
              navigate,
              SubCategoryPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateSubCategoryService(requestBody, token, "update", navigate),
          "update"
        );
      }
    }
  };

  const subCategorytypeOptions = [
    { value: "0", label: "Product" },
    { value: "1", label: "Blog" },
    { value: "2", label: "Service" },
  ];

  const handleSubCategoryTypeChange = (option, setFieldValue, catData) => {
    if (catData) {
      if (option) {
        const categoryOption = catData.filter((item) => item.type === option);
        setCategoryNewOptionsData(categoryOption);
      }
    } else {
      if (option && categoryListOptions && categoryListOptions.length > 0) {
        const categoryOption = categoryListOptions.filter(
          (item) => item.type === option.value
        );
        setCategoryNewOptionsData(categoryOption);
        setFieldValue("categorySelect", "");
      }
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {" "}
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {subCategoryActionData && subCategoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          subCategoryActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("lable_sub_categories"))}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/sub-category")}>
                  {subCategoryActionData && subCategoryActionData.length > 0
                    ? getRouteNameFromSidebar(
                        subCategoryActionData[0].name,
                        localisation
                      )
                    : t("lable_sub_categories")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {subCategoryActionData && subCategoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          subCategoryActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("lable_sub_categories"))}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {t("Sub Category")}
            </h4>
          </div>
          <div className="category-section add-roles-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("Sub Category")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="subCategoryName"
                          label="Category Name"
                          placeholder={t("lable_sub_category_name")}
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.subCategoryName}
                          disabled={Data && Data.isEdit === false}
                          maxLength={20}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("lable_sub_category_code")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="subCategoryCode"
                          label="Category Code"
                          placeholder={t("lable_sub_category_code")}
                          className="add-roles"
                          value={values.subCategoryCode}
                          disabled={Data && Data.isEdit === false}
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_select_type")}
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="subCategorytype"
                          options={subCategorytypeOptions}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          value={values.subCategorytype}
                          onchangedata={(option) =>
                            handleSubCategoryTypeChange(option, setFieldValue)
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_select_category")}
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="categorySelect"
                          options={categoryNewOptionsData}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          value={values.categorySelect}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_sub_category_image")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <UploadImage
                        name="subCategoryImage"
                        onChange={setFieldValue}
                        value={values.subCategoryImage}
                        disabled={Data && Data.isEdit === false}
                        filterByType={6}
                        setErrorImages={setErrorImages}
                        setShowImage={setShowImage}
                      ></UploadImage>
                      <ShowImage
                        value={values.subCategoryImage}
                        errorImages={errorImages}
                        showImage={showImage}
                      ></ShowImage>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_description")}
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          name="description"
                          component={CKEditorCpmponent}
                          disabled={Data && Data.isEdit === false}
                          maxLength={255}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">{t("status")}</h4>
                        <Field
                          name="status"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/sub-category", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

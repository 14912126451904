import { Dispatch } from "redux";
import {
  AddUpdateAdminUserRequest,
  GetUserDetailResponse,
  UserTypeResponse,
  getAdminUserListRequest,
  getAdminUserResponse,
  verifyUserRequest,
} from "./UserModalResponse";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  getToken,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearAdminData,
  getAdminData,
  getUserType,
} from "../../../redux/Actions/adminAction/AdminUserAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearAdminPageData,
  getAdminPageData,
} from "../../../redux/Actions/adminAction/AdminPageAction";
import { updateAuthData } from "../../../redux/Actions/authAction/AuthAction";
import { getAdminUserPRData } from "../../../redux/Actions/adminAction/AdminUserPRAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";
import { useSelector } from "react-redux";
import { getApiService } from "../../../services/API-services/GetService";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function UserService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /**
   * Get user list service
   * @param adminRequest
   * @param token
   * @param dispatch
   * @param total
   * @returns
   */
  const getUserListService = (
    adminRequest: getAdminUserListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ) => {
    return new Promise<getAdminUserResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(adminRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.USERS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getAdminData(response));
          } else {
            dispatch(getAdminData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get individual user
   */
  const getUserById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<GetUserDetailResponse> => {
    return new Promise<GetUserDetailResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.USERS + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /**
   * Add and update admin user
   * @param addUpdateUserRequest
   * @param token
   * @param type
   * @param navigate
   * @returns
   */
  const addUpdateUserService = (
    addUpdateUserRequest: AddUpdateAdminUserRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number,
    user?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.USERS,
            token,
            addUpdateUserRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.USERS,
            token,
            addUpdateUserRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearAdminData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          if (type === "add") {
            dispatch(clearAdminPageData());
            toast.success(
              response?.message ? response.message : "User added successfully!"
            );
            navigate("/adminusers");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              dispatch(clearAdminPageData());
              toast.success(
                response?.message
                  ? response.message
                  : "User undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getAdminPageData(pageNumber + 1));
                } else {
                  dispatch(clearAdminPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "User updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/adminusers");
                });
              } else {
                if (!user) {
                  toast.success(
                    response?.message
                      ? response.message
                      : "User status updated successfully!"
                  );
                }
              }
            }

            if (user) {
              let userData = user;
              userData.data.beep_option = addUpdateUserRequest?.p_beep_option;
              if (addUpdateUserRequest?.p_beep_option === "1") {
                toast.success(
                  response?.message
                    ? response.message
                    : "Multiple beep enabled successfully!"
                );
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Multiple beep disabled successfully!"
                );
              }
              dispatch(updateAuthData({ user: userData, reload: false }));
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getAdminPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /**
   * verify user API calling for unique user
   */
  const verifyUniqueUser = (verifyUserRequest: verifyUserRequest) => {
    return new Promise<getAdminUserResponse>(async (resolve, reject) => {
      try {
        const token = getToken();

        const response = await postApiService(
          API_URL.BASE_URL + API_URL.VERIFY_EMAIL,
          token,
          verifyUserRequest,
          localisation
        );
        resolve(response);
      } catch (error) {
        if (errorShow === false) {
          toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
        }
      } finally {
        errorShow = false;
      }
    });
  };

  /*
   * Service to delete role
   */
  const deleteUserService = (id: string, token: string, pageNumber: number) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.USERS + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          dispatch(clearAdminData());
          toast.success("User deleted successfully!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getAdminPageData(pageNumber));
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /**
   * Get user type list service
   * @param dispatch
   * @returns
   */
  const getUserTypeList = (dispatch: Dispatch) => {
    return new Promise<UserTypeResponse>(async (resolve, reject) => {
      try {
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.USER_TYPE,
          null,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(getUserType(response.data));
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /**
   * Get PR user list service
   * @param adminRequest
   * @param token
   * @param dispatch
   * @param total
   * @returns
   */
  const getPRUserListService = (
    adminRequest: getAdminUserListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ) => {
    return new Promise<getAdminUserResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(adminRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.USERS +
            API_URL.PRUSERS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getAdminUserPRData(response));
          } else {
            dispatch(getAdminUserPRData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message === "Permission does not exists."
                  ? "User permission does not exists."
                  : response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  return {
    getUserListService,
    getUserById,
    addUpdateUserService,
    verifyUniqueUser,
    deleteUserService,
    getUserTypeList,
    getPRUserListService,
  };
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import {
  clearProductInventoryPageData,
  getProductInventoryPageData,
} from "../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { clearProductInventoryFilterData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryFilterAction";
import { clearProductInventoryData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryAction";
import ExcelData from "../../services/Regular-services/ExcelExport";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import ProductInventoryServices from "./ProductInventoryService/ProductInventoryService";

const ProductInventory = () => {
  const { getAllProductInventoryService } = ProductInventoryServices();
  const { t } = useTranslation();
  const productInventoryData = useSelector((state) => state.productInventory);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const productInventoryPage = useSelector(
    (state) => state.productInventoryPage?.items
  );
  const productInventoryFilter = useSelector(
    (state) => state.productInventoryFilter
  );
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [productInventoryActionData, setProductInventoryActionData] = useState(
    []
  );
  // const user = useSelector((state) => state.auth?.user);

  const showKey = {
    name: true,
    // quantity: true,
    // unit: true,
  };
  // const showToggle = {
  //   status: true,
  // };

  const columns = [
    {
      name: t("columns.name"),
      selector: (row) => row.name,
    },
    {
      name: t("columns.actions"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ];
  const showActions = {
    showEdit: true,
    showDelete: true,
    showView: true,
    // showUndo: true,
    navigate: "/add-product-inventory",
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setProductInventoryActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setCurrentPage(productInventoryPage);
  }, [productInventoryPage]);

  /**
   * Fetch inventory item data
   */
  const fetchProductInventory = () => {
    const requestBody = {
      p_skip:
        productInventoryPage > 1
          ? (productInventoryPage - 2) * 10
          : (productInventoryPage - 1) * 10,
      p_take: 10,
    };
    if (
      productInventoryFilter.productId !== "" &&
      productInventoryFilter.productId !== null
    ) {
      requestBody.p_product_id = productInventoryFilter.productId;
    }
    // if (inventoryItemFilter.isActive !== "" && inventoryItemFilter.isActive !== null) {
    //   requestBody.p_isActive = inventoryItemFilter.isActive;
    // } else {
    //   requestBody.p_isActive = "1";
    // }
    // if (inventoryItemFilter.isDelete !== "" && inventoryItemFilter.isDelete !== null) {
    //   requestBody.p_IsDeleted = inventoryItemFilter.isDelete;
    // }
    // return
    getAllProductInventoryService(requestBody, tokenData, dispatch);
    if (productInventoryPage > 1) {
      setCurrentPage(productInventoryPage - 1); // Update the current page state
      dispatch(getProductInventoryPageData(productInventoryPage - 1));
    }
  };

  useEffect(() => {
    if (
      productInventoryData &&
      productInventoryData.items &&
      productInventoryData.items.length === 0
    ) {
      fetchProductInventory();
    } else {
      if (
        productInventoryFilter?.productId !== ""
        // inventoryItemFilter?.isActive !== "" ||
        // inventoryItemFilter?.isDelete !== ""
      ) {
        // handleShow();
      }
    }

    if (
      productInventoryData.items[0]?.data &&
      productInventoryData.items[0].data.length > 0
    ) {
      const transformedProductInventoryData =
        productInventoryData.items[0].data.map((item) => {
          return {
            // id: item.productId,
            name: item.productName,
            productId: item.productId,
            ingredients: item.ingredients,
            companyId: item.companyId,
            // status: item.isActive
          };
        });

      setTableData(transformedProductInventoryData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [productInventoryData, productInventoryFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (
        productInventoryFilter.productId !== "" &&
        productInventoryFilter.productId !== null
      ) {
        requestBody.p_product_id = productInventoryFilter.productId;
      }
      getAllProductInventoryService(requestBody, tokenData, dispatch);
      dispatch(getProductInventoryPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearProductInventoryFilterData());
    dispatch(clearProductInventoryPageData());
    dispatch(clearProductInventoryData());
    handleClose();
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <>
        <div>
          {/* <div className="sidebar-content">
            <Sidebar />
          </div>
          <div className="body-content">
            <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {productInventoryActionData &&
                    productInventoryActionData.length > 0
                      ? getRouteNameFromSidebar(
                          productInventoryActionData[0].name,
                          localisation
                        )
                      : t("Recipes")}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    {t("Home")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {productInventoryActionData &&
                    productInventoryActionData.length > 0
                      ? getRouteNameFromSidebar(
                          productInventoryActionData[0].name,
                          localisation
                        )
                      : t("Recipes")}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {productInventoryFilter.productId !== "" ? (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        {t("clear_filters")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                <Button
                  onClick={handleShow}
                  data-bs-toggle="tooltip"
                  title="Filter"
                  // onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>

                <Offcanvas show={show} onHide={handleClose} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title> {t("filter_options")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Filter
                      page="product-inventory"
                      handleClose={handleClose}
                    ></Filter>
                  </Offcanvas.Body>
                </Offcanvas>

                {productInventoryData &&
                  productInventoryData.items[0]?.data &&
                  productInventoryData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={productInventoryData.items[0].data}
                      fileName={"product-inventory"}
                    ></ExcelData>
                  )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Inventory Item"
                  onClick={() => navigate("/add-product-inventory")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {/* {isToggled && <Filter page="product-inventory"></Filter>} */}

            {productInventoryData &&
              productInventoryData.items[0]?.data &&
              productInventoryData.items[0].data && (
                <>
                  <CustomTable
                    page="product-inventory"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    disabled={
                      productInventoryActionData &&
                      productInventoryActionData[0]?.edit &&
                      productInventoryActionData[0].edit === "0"
                    }
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                  />

                  {productInventoryData.items[0].total_records >
                    productInventoryData.items[0].data.length &&
                    productInventoryData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={
                          productInventoryData.items[0].total_records
                        }
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          {/* <Footer />
          </div> */}
        </div>
      </>
    </div>
  );
};

export default ProductInventory;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stocksHistory .dropdown-menu.show {
  inset: 0 -90px auto auto !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Stocks/StockHistoryPage.js/StockHistory.scss"],"names":[],"mappings":"AACI;EACI,mCAAA;AAAR","sourcesContent":[".stocksHistory{\n    .dropdown-menu.show{\n        inset:0 -90px auto auto !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

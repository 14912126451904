export const postServicePayment = async (
  Url,
  token,
  bodyData,
  localisation
) => {
  try {
    const response = await fetch(Url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Language": localisation ? localisation : 'en',
        "accept-language": localisation ? localisation : 'en',
      },
      ...(bodyData && { body: bodyData }),
    });
    if (!response.ok) {
      const data = await response.json();
      return data;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('api error: ', error);
  }
};

import { CLEAR_PACKAGER_FILTER, GET_PACKAGER_FILTER } from "../../ReduxKeys";

/**
 * Set packager filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getPackagerFilterData = ({ name, type, isActive, isDelete }) => ({
  type: GET_PACKAGER_FILTER,
  payload: { name, type, isActive, isDelete },
});

/**
 * Clear packager filtered data
 * @returns {*}
 */
export const clearPackagerFilterData = () => ({
  type: CLEAR_PACKAGER_FILTER,
});
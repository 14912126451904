import { CLEAR_EVENT_LOG_FILTER, GET_EVENT_LOG_FILTER } from "../../ReduxKeys";

/**
 * set event log filter data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getEventLogFilterData = ({
  module,
  Action,
  startDate,
  endDate,
  user,
}) => ({
  type: GET_EVENT_LOG_FILTER,
  payload: { module, Action, startDate, endDate, user },
});

/**
 * clear order filtered data
 * @returns {*}
 */
export const clearEventLogFilterData = () => ({
  type: CLEAR_EVENT_LOG_FILTER,
});
import { CLEAR_STOCK_PAGE, GET_STOCK_PAGE } from "../../ReduxKeys";

const stockPageInitialState = {
  items: 1,
};

/**
 * Store stock page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const StockPageReducer = (state = stockPageInitialState, action) => {
  switch (action.type) {
    case GET_STOCK_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_STOCK_PAGE:
      return stockPageInitialState;
    default:
      return state;
  }
};
import { getRoundedValue } from "../../Utils/Utils";

export default function generateInvoiceHtml(
  orderData,
  authuserData,
  type = ""
) {
  const {
    // companyLogo,
    // companyName,
    // companyOwnerName,
    companyPhneNumber,
    branchGstNumber,
    branchFSSAI_licence_number,
    firstName,
    lastName,
  } = authuserData.data;
  const cleanProductData = orderData.productData;
  const orderDate = new Date(orderData?.createdAt).toLocaleDateString();
  const grandTotalwithoutRoundOff = (
    Number(orderData?.orderSubTotal) -
    Number(orderData?.total_discount_amount) +
    Number(orderData?.total_tax_amount)
  ).toFixed(2);
  const roundOffTotal = (
    (grandTotalwithoutRoundOff % 1 < 0.5
      ? Math.floor(grandTotalwithoutRoundOff)
      : Math.ceil(grandTotalwithoutRoundOff)) - grandTotalwithoutRoundOff
  ).toFixed(2);

  const finalTotal =
    Math.round(grandTotalwithoutRoundOff % 1) < 0.5
      ? Math.floor(grandTotalwithoutRoundOff)
      : Math.ceil(grandTotalwithoutRoundOff);

  // Helper function to generate tax info

  const aggregatedTaxes = calculateAggregatedTaxes(cleanProductData);
  function calculateAggregatedTaxes(productData) {
    const taxSummary = {};

    productData.forEach((product) => {
      const { cgst, sgst, price, quantity } = product;
      const cgstRate = Number(cgst);
      const sgstRate = Number(sgst);
      const key = `${cgstRate}-${sgstRate}`;
      const totalPrice =
        price * quantity - Number(product.product_discount_amount);

      if (!taxSummary[key]) {
        taxSummary[key] = {
          cgstRate,
          sgstRate,
          cgstValue: 0,
          sgstValue: 0,
        };
      }

      taxSummary[key].cgstValue += (totalPrice * cgstRate) / 100;
      taxSummary[key].sgstValue += (totalPrice * sgstRate) / 100;
    });
    return Object.values(taxSummary);
  }

  const taxDetailsHtml = aggregatedTaxes
    .map((tax) => {
      return type === "wp"
        ? `
      ${
        tax.cgstRate !== 0
          ? `    <div class="tax-row"> <span class ="summary">CGST (${
              tax.cgstRate
            }%):</span>
        <span class ="summary">${tax.cgstValue.toFixed(2)}</span></div>`
          : ""
      }
      ${
        tax.cgstRate !== 0
          ? `     <div class="tax-row">
        <span class ="summary">SGST (${tax.sgstRate}%):</span>
        <span class ="summary">${tax.sgstValue.toFixed(2)}</span>
      </div>`
          : ""
      }
    `
        : `
      ${
        tax.cgstRate !== 0
          ? `    <div class="row"> <span class ="summary">CGST (${
              tax.cgstRate
            }%):</span>
        <span class ="summary">${tax.cgstValue.toFixed(2)}</span></div>`
          : ""
      }
      ${
        tax.cgstRate !== 0
          ? `     <div class="row">
        <span class ="summary">SGST (${tax.sgstRate}%):</span>
        <span class ="summary">${tax.sgstValue.toFixed(2)}</span>
      </div>`
          : ""
      }
    `;
    })
    .join("");

  // Helper function for calculating total quantity
  const totalQty = (item) => item.reduce((acc, item) => acc + item.quantity, 0);

  // Generate HTML
  if (type === "wp") {
    return `
    <html>
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Invoice</title>
    <style>
      body {
        font-family: Arial, sans-serif;
      font-size: 10px;
      margin: 0;
      padding: 20px;
      background-color: #f4f4f4;
      }
      .invoice {
        width: 80mm;
      background-color: #ffffff;
      padding: 10px;
      margin: 0 auto;
      }
      .header, .footer {
        text-align: center;
        margin-bottom: 10px;
      }
      .header strong, .footer {
        font-size: 14px;
      }
      .header p, .footer p {
        margin: 2px 0;
      }
      .header {
        border-bottom: 2px solid #000;
        padding-bottom: 10px;
      }
      .section {
        margin: 10px 0;
        padding-bottom: 10px;
        border-bottom: 2px solid #000;
      }
      .section p {
        margin: 2px 0;
      }
      .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
      }
      .table, .table th, .table td {
        border-bottom: 2px solid #000;
        padding: 5px;
        text-align: left;
        font-size: 14px
      }
      .total-section {
        margin-top: 10px;
        width:100%;
      }
       .tax-row {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        width:100%;
        flex-direction:row;
      }
      
      .thank-you {
        text-align: center;
        margin-top: 15px;
      }
      .thank-you p {
        margin: 0;
      }
      .footer{
        border-top: 2px solid #000;
        margin-top: 10px;
      }
    </style>
    </head>
    <body>
    
    <div class="invoice">
      <div class="header">
        <p><strong>${orderData?.companyName}</strong></p>
        <p>${orderData?.companyAddress}</p>
        <p>Mob - ${companyPhneNumber}</p>
        ${
          branchGstNumber !== "N/A" &&
          branchGstNumber !== "" &&
          branchGstNumber !== null &&
          branchGstNumber !== "null"
            ? ` <p>GST NO - ${branchGstNumber}</p>`
            : ""
        }
      </div>
    
      ${
        orderData.phoneNumber !== "N/A" &&
        orderData.phoneNumber !== "8155006366" &&
        orderData.phoneNumber !== 8155006366
          ? ` <div class="section">
        <p><strong>Name:</strong> ${orderData?.customerName} ${
              orderData.phoneNumber !== "N/A" &&
              orderData.phoneNumber !== "8155006366" &&
              orderData.phoneNumber !== 8155006366 &&
              ` (M: ${orderData?.phoneNumber})`
            }</p>
      </div>`
          : ""
      }
       <div class="section">
      <p><strong>Date:</strong> ${orderDate}</p>
     
       ${
         orderData.table_no &&
         orderData.table_no !== "null" &&
         orderData.table_no !== "" &&
         orderData.table_no !== "—"
           ? `<p> <strong>Dine In:</strong> ${orderData.table_no}</p>`
           : ""
       }
<p><strong>Cashier:</strong> ${firstName} ${lastName}</p>
    <p><strong>Bill No.:</strong> ${orderData?.orderNumber}</p>
    </div>
       <table class="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Tax</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            ${cleanProductData
              .map(
                (item) => `
              <tr>
                <td>${item.productName}</td>
                <td>${
                  Number(orderData?.total_tax_amount) > 0
                    ? Number(item.cgst) + Number(item.sgst)
                    : 0
                }%</td>
                <td>${item.price}</td>
                <td>${item.quantity}</td>
                <td>${Number(item.product_discount_amount)}</td>
                <td>${getRoundedValue(Number(item?.totalTablePrice) || 0)}</td>
              </tr>`
              )
              .join("")}
            </tbody>
          </table>
    
      <div class="section-new">
        <div class = "tax-row">
          <span class ="summary">Total Qty:</span>
          <span class ="summary">${totalQty(orderData.productData)}</span>
        </div>
        <div class = "tax-row">
          <span class ="summary">Sub Total:</span>
          <span class ="summary">${orderData?.currencySymbol || "₹"}${
      orderData.orderSubTotal
    }</span>
        </div>
        ${
          orderData?.total_discount_amount &&
          orderData?.total_discount_amount !== "null" &&
          orderData?.total_discount_amount !== null &&
          orderData?.total_discount_amount !== "N/A"
            ? ` <div class="tax-row"><span class ="summary">Total Discount:</span><span class ="summary">${
                orderData?.currencySymbol || "₹"
              }${orderData?.total_discount_amount}</span></div>`
            : ` <div class="tax-row"><span class ="summary">Total Discount:</span><span class ="summary">₹0</span></div>`
        }
        ${
          orderData.type === "2"
            ? orderData?.total_delivery_charge &&
              orderData?.total_delivery_charge !== "0" &&
              orderData?.total_delivery_charge !== 0 &&
              orderData?.total_delivery_charge !== "N/A"
              ? `<div class="tax-row"><span class="summary">Delivery Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }${orderData?.total_delivery_charge}</span></div>`
              : `<div class="tax-row"><span class="summary">Delivery Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }0</span></div>`
            : ``
        }
        ${
          orderData.type === "2"
            ? orderData?.service_charge &&
              orderData?.service_charge !== "0" &&
              orderData?.service_charge !== 0 &&
              orderData?.service_charge !== "N/A"
              ? `<div class="tax-row"><span class="summary">Service Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }${orderData?.service_charge}</span></div>`
              : `<div class="tax-row"><span class="summary">Service Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }0</span></div>`
            : ``
        }
        
        ${
          Number(orderData?.total_tax_amount) > 0
            ? `
          ${taxDetailsHtml}
            <div class = "tax-row">
     <span class ="summary">Total Tax:</span>
     <span class ="summary">${orderData?.currencySymbol || "₹"}${
                orderData?.total_tax_amount
              }</span>
   </div>
           `
            : ""
        }
       
        
         <div class="tax-row"><span class ="summary">Total:</span><span class ="summary">${
           orderData?.currencySymbol || "₹"
         }${grandTotalwithoutRoundOff}</span></div>
        <div class="tax-row"><span class ="summary">Total Round Off:</span><span class ="summary">${
          orderData?.currencySymbol || "₹"
        }${roundOffTotal}</span></div>
        <div class = "tax-row">
          <span class ="summary">Grand Total:</span>
          <span class ="summary">${
            orderData?.currencySymbol || "₹"
          }${finalTotal}</span>
        </div>
      </div>
    
      <div class="footer">
      ${
        branchFSSAI_licence_number &&
        branchFSSAI_licence_number !== "null" &&
        branchFSSAI_licence_number !== "" &&
        branchFSSAI_licence_number !== null &&
        branchFSSAI_licence_number !== "N/A"
          ? `<p>FSSAI Lic No. ${branchFSSAI_licence_number}</p>`
          : ""
      }
        <p>Thanks For Ordering!</p>
      </div>
    </div>
    
    </body>
    </html>
    `;
  } else {
    // Return the other version of the invoice HTML if type is not "wp"
    return `
      <html>
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Invoice</title>
        <style>
          body { font-family: Arial, sans-serif; font-size: 10px; margin: 0; padding: 20px; background-color: #f4f4f4; }
          .invoice { width: 80mm; background-color: #ffffff; padding: 10px; margin: 0 auto; }
          .header, .footer { text-align: center; margin-bottom: 10px; }
          .header strong, .footer { font-size: 14px; }
          .header p, .footer p { margin: 2px 0; }
          .header { border-bottom: 2px solid #000; padding-bottom: 10px; }
          .section { margin: 10px 0; padding-bottom: 10px; border-bottom: 2px solid #000; }
          .section p { margin: 2px 0; }
          .table { width: 100%; border-collapse: collapse; margin-top: 10px; }
          .table, .table th, .table td { border-bottom: 2px solid #000; padding: 5px; text-align: left; font-size: 14px }
          .total-section { margin-top: 10px; }
          .total-section .row { display: flex; justify-content: space-between; font-weight: bold; width:100%; flex-direction:row; }
          .thank-you { text-align: center; margin-top: 15px; }
          .thank-you p { margin: 0; }
          .footer{ border-top: 2px solid #000; margin-top: 10px; }
          .summary{ font-size: 12px}
        </style>
      </head>
      <body>
        <div class="invoice">
          <div class="header">
            <p><strong>${orderData?.companyName}</strong></p>
            <p>${orderData?.companyAddress}</p>
            <p>Mob - ${companyPhneNumber}</p>
            ${
              branchGstNumber !== "N/A" &&
              branchGstNumber !== "" &&
              branchGstNumber !== null &&
              branchGstNumber !== "null"
                ? ` <p>GST NO - ${branchGstNumber}</p>`
                : ""
            }
          </div>
          ${
            orderData.phoneNumber !== "N/A" &&
            orderData.phoneNumber !== "8155006366"
              ? `
            <div class="section">
              <p><strong>Name:</strong> ${orderData?.customerName} (M: ${orderData?.phoneNumber})</p>
            </div>`
              : ""
          }
          <div class="section">
            <p><strong>Date:</strong> ${orderDate}</p>
            ${
              orderData.table_no &&
              orderData.table_no !== "null" &&
              orderData.table_no !== "—"
                ? `<p><strong>Dine In:</strong> ${orderData.table_no}</p>`
                : ""
            }
            <p><strong>Cashier:</strong> ${firstName} ${lastName}</p>
            <p><strong>Bill No.:</strong> ${orderData?.orderNumber}</p>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Tax</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${cleanProductData
                .map(
                  (item) => `
                <tr>
                  <td>${item.productName}</td>
                  <td>${
                    Number(orderData?.total_tax_amount) > 0
                      ? Number(item.cgst) + Number(item.sgst)
                      : 0
                  }%</td>
                  <td>${item.price}</td>
                  <td>${item.quantity}</td>
                  <td>${Number(item.product_discount_amount)}</td>
                  <td>${getRoundedValue(
                    Number(item?.totalTablePrice) || 0
                  )}</td>
                </tr>`
                )
                .join("")}
            </tbody>
          </table>
          <div class="total-section">
            <div class="row"><span class ="summary">Total Qty:</span><span class ="summary">${totalQty(
              orderData.productData
            )}</span></div>
             <div class = "row">
          <span class ="summary">Sub Total:</span>
          <span class ="summary">${orderData?.currencySymbol || "₹"}${
      orderData.orderSubTotal
    }</span>
        </div>
        ${
          orderData?.total_discount_amount &&
          orderData?.total_discount_amount !== "null" &&
          orderData?.total_discount_amount !== null &&
          orderData?.total_discount_amount !== "N/A"
            ? ` <div class="row"><span class ="summary">Total Discount:</span><span class ="summary">${
                orderData?.currencySymbol || "₹"
              }${orderData?.total_discount_amount}</span></div>`
            : ` <div class="row"><span class ="summary">Total Discount:</span><span class ="summary">₹0</span></div>`
        }
        ${
          orderData.type === "2"
            ? orderData?.total_delivery_charge &&
              orderData?.total_delivery_charge !== "0" &&
              orderData?.total_delivery_charge !== 0 &&
              orderData?.total_delivery_charge !== "N/A"
              ? `<div class="row"><span class="summary">Delivery Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }${orderData?.total_delivery_charge}</span></div>`
              : `<div class="row"><span class="summary">Delivery Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }0</span></div>`
            : ``
        }
        ${
          orderData.type === "2"
            ? orderData?.service_charge &&
              orderData?.service_charge !== "0" &&
              orderData?.service_charge !== 0 &&
              orderData?.service_charge !== "N/A"
              ? `<div class="row"><span class="summary">Service Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }${orderData?.service_charge}</span></div>`
              : `<div class="row"><span class="summary">Service Charge:</span><span class="summary">${
                  orderData?.currencySymbol || "₹"
                }0</span></div>`
            : ``
        }
             ${
               Number(orderData?.total_tax_amount) > 0
                 ? `
                  ${taxDetailsHtml}
                 <div class = "row">
          <span class ="summary">Total Tax:</span>
          <span class ="summary">${orderData?.currencySymbol || "₹"}${
                     orderData?.total_tax_amount
                   }</span>
        </div>
                `
                 : ""
             }
             
             <div class="row"><span class ="summary">Total:</span><span class ="summary">${
               orderData?.currencySymbol || "₹"
             }${grandTotalwithoutRoundOff}</span></div>
             <div class="row"><span class ="summary">Total Round Off:</span><span class ="summary">${
               orderData?.currencySymbol || "₹"
             }${roundOffTotal}</span></div>
            <div class="row" ><span class ="summary">Grand Total:</span><span class ="summary">${
              orderData?.currencySymbol || "₹"
            }${finalTotal}</span></div>
          </div>
          <div class="footer">
            ${
              branchFSSAI_licence_number &&
              branchFSSAI_licence_number !== "N/A" &&
              branchFSSAI_licence_number !== "" &&
              branchFSSAI_licence_number !== null &&
              branchFSSAI_licence_number !== "null"
                ? `<p>FSSAI Lic No. ${branchFSSAI_licence_number}</p>`
                : ""
            }
            <p>Thanks For Ordering!</p>
          </div>
        </div>
      </body>
      </html>`;
  }
}

import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import { FaFilter } from 'react-icons/fa';
import Filter from '../../components/Filter/Filter';
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from 'react-bootstrap';
import ExcelData from '../../services/Regular-services/ExcelExport';

const columns = [
    {
        name: 'Appointment ID',
        selector: row => row.id,
      },
      {
        name: 'Bramcha',
        selector: row => row.Bramcha,
      },
    {
      name: 'Customer Name',
      selector: row => row.Name,
    },
    {
      name: 'Service Provider Name',
      selector: row => row.ProviderName,
    },
    {
        name: 'Services',
        selector: row => row.Type,
      },
    {
        name: 'Date',
        selector: row => row.Date,
    },
    {
      name: 'Time Slot',
      selector: row => row.slot,
  },
    {
        name: 'Total Amount',
        selector: row => row.total,
    },
    {
      name: 'Action',
      selector: row => row.action,
    }
  ];
  
  const data = [
    {
        id: 1,
        Bramcha:"2",
        name: 'Product',
        ProviderName:"provider Name",
        serviceType: 'Delivery',
        Date: '20-02-2024',
        slot:'09:00 to 11:00',
        total: "₹450",
        
    },
    {
        id: 2,
        Bramcha:"2",
        name: 'Product',
        ProviderName:"provider Name",
        serviceType: 'Delivery',
        Date: '20-02-2024',
        slot:'09:00 to 11:00',
        total: "₹450",
        
        
    },
    {
        id: 3,
        Bramcha:"2",
        name: 'Product',
        ProviderName:"provider Name",
        serviceType: 'Delivery',
        Date: '20-02-2024',
        slot:'09:00 to 11:00',
        total: "₹450",
        
    },
    
  
  ]
  
  //Show actions which is to be displayed in table
  const showActions = {
    showView: true,
    showEdit: false,
    showDelete: true,
    navigate: '/service-appointment-process'
  }
  
  //Show keys which is to be displayed in table
  const showKey = {
    id: true,
    Bramcha:true,
    name: true,
    Date: true,
    total: true,
    slot:true,
    serviceType:true,
    ProviderName:true,
  }

export default function ServiceAppointments() {
    const tooltipRef = useRef(null);
    useEffect(() => {
      if (tooltipRef.current) {
        const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
        return () => {
          tooltip.dispose();
        };
      }
    }, []);
  
    const navigate = useNavigate();
    const [isToggled, setIsToggled] = useState(false);
  
    const handleButtonClick = () => {
      setIsToggled((prevIsToggled) => !prevIsToggled);
    };

  return (
    <>
        <div>
        {/* <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header /> */}
          <div className='table-body-content'>
            <div className='roles'>
              <div>
                <div className='title'>
                  <h4>Service Appointments</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Service Appointments</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='btn-groups'>

                <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >Delete</Dropdown.Item>
                    <Dropdown.Item >Active</Dropdown.Item>
                    <Dropdown.Item >Inactive</Dropdown.Item>
                    <Dropdown.Item >Undo</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

                <Button data-bs-toggle='tooltip' title='Filter' onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                  <FaFilter className="filter-icon" />
                </div>}></Button>
                {data && data.length > 0 && (
                  <ExcelData excelData={data} fileName={'image'}></ExcelData>
                )}
              </div>
            </div>
            {isToggled &&
              <Filter page='tags'></Filter>}
            <CustomTable showId={true} checkBool={false} showKey={showKey} showActions={showActions} showTooltip={true} data={data} heading={columns} />
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  )
}

import { Dispatch } from "redux";
import {
  AddUpdateDiscountCatalogueRequest,
  getAllDiscountCatalogueRequest,
  getAllDiscountCatalogueResponse,
  getDiscountCatalogueById,
} from "./DiscountCatalogueModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearDiscountCatalogueData,
  clearTotalDiscountCatalogueData,
  getDiscountCatalogueData,
  getTotalDiscountCatalogueData,
} from "../../../redux/Actions/DiscountCatalogueAction/DiscountCatalogueAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import {
  clearDiscountCataloguePageData,
  getDiscountCataloguePageData,
} from "../../../redux/Actions/DiscountCatalogueAction/DiscountCataloguePageAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function DiscountCatalogueService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get leaf level category list
   */
  const getAllDiscountCatalogueService = (
    dicountCatalogueListRequest: getAllDiscountCatalogueRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllDiscountCatalogueResponse> => {
    return new Promise<getAllDiscountCatalogueResponse>(
      async (resolve, reject) => {
        try {
          dispatch(setLoader());
          let queryParams = "";
          // Adding query parameters if they are provided
          if (
            dicountCatalogueListRequest.p_skip !== undefined &&
            dicountCatalogueListRequest.p_skip !== null
          ) {
            queryParams += `p_skip=${dicountCatalogueListRequest.p_skip}&`;
          }
          if (
            dicountCatalogueListRequest.p_limit !== undefined &&
            dicountCatalogueListRequest.p_limit !== null
          ) {
            queryParams += `p_limit=${dicountCatalogueListRequest.p_limit}&`;
          }
          if (
            dicountCatalogueListRequest.p_title !== undefined &&
            dicountCatalogueListRequest.p_title !== null
          ) {
            queryParams += `p_title=${dicountCatalogueListRequest.p_title}&`;
          }
          if (
            dicountCatalogueListRequest.p_start_date !== undefined &&
            dicountCatalogueListRequest.p_start_date !== null
          ) {
            queryParams += `p_start_date=${dicountCatalogueListRequest.p_start_date}&`;
          }
          if (
            dicountCatalogueListRequest.p_end_date !== undefined &&
            dicountCatalogueListRequest.p_end_date !== null
          ) {
            queryParams += `p_end_date=${dicountCatalogueListRequest.p_end_date}&`;
          }
          if (
            dicountCatalogueListRequest.p_discount_type !== undefined &&
            dicountCatalogueListRequest.p_discount_type !== null
          ) {
            queryParams += `p_discount_type=${dicountCatalogueListRequest.p_discount_type}&`;
          }

          if (
            dicountCatalogueListRequest.p_discount_type_on !== undefined &&
            dicountCatalogueListRequest.p_discount_type_on !== null
          ) {
            queryParams += `p_discount_type_on=${dicountCatalogueListRequest.p_discount_type_on}&`;
          }

          if (
            dicountCatalogueListRequest.p_discount_catlogue_for !== undefined &&
            dicountCatalogueListRequest.p_discount_catlogue_for !== null
          ) {
            queryParams += `p_discount_catlogue_for=${dicountCatalogueListRequest.p_discount_catlogue_for}&`;
          }

          if (
            dicountCatalogueListRequest.p_IsActive !== undefined &&
            dicountCatalogueListRequest.p_IsActive !== null
          ) {
            queryParams += `p_IsActive=${dicountCatalogueListRequest.p_IsActive}&`;
          }
          if (
            dicountCatalogueListRequest.p_isDeleted !== undefined &&
            dicountCatalogueListRequest.p_isDeleted !== null
          ) {
            queryParams += `p_isDeleted=${dicountCatalogueListRequest.p_isDeleted}&`;
          }
          const response = await getApiService(
            API_URL.BASE_URL +
              API_URL.DISCOUNT_CATALOGUE +
              (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
            token,
            null,
            localisation
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === "200" ||
              response.status === "success")
          ) {
            if (total && total === true) {
              dispatch(getTotalDiscountCatalogueData(response));
            } else if (total && total === "filter") {
              toast.success(t(ValidationMessage.FILTER_SUCCESS));
              dispatch(getDiscountCatalogueData(response));
            } else {
              dispatch(getDiscountCatalogueData(response));
            }
          } else {
            if (!document.querySelector(toastClass)) {
              toast.error(
                response?.message
                  ? response.message === "Permission does not exists."
                    ? "Discount catalogue permission does not exists."
                    : response.message
                  : t(ValidationMessage.SOMETHING_WENT_WRONG)
              );
            }
            errorShow = true;
            if (
              response?.message &&
              response.message === UnauthorizeAccessMessage
            ) {
              removePersistStorage(dispatch);
              // dispatch(logout());
            }
          }
          resolve(response);
        } catch (error: any) {
          if (error?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          if (errorShow === false && !document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        } finally {
          errorShow = false;
          dispatch(clearLoader());
        }
      }
    );
  };

  /*
   * Service for adding and updating discount catalogue
   */
  const addUpdateDiscountCatalogueService = (
    addUpdateDiscountCatalogueRequest: AddUpdateDiscountCatalogueRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.DISCOUNT_CATALOGUE,
            token,
            addUpdateDiscountCatalogueRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.DISCOUNT_CATALOGUE,
            token,
            addUpdateDiscountCatalogueRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDiscountCatalogueData());
          dispatch(clearTotalDiscountCatalogueData());
          if (type === "add") {
            dispatch(clearDiscountCataloguePageData());
            toast.success(
              response?.message
                ? response.message
                : "Discount catalogue added successfully!"
            );
            navigate("/discount-catalogue");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Discount catalogue undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getDiscountCataloguePageData(pageNumber + 1));
                } else {
                  dispatch(clearDiscountCataloguePageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Discount catalogue updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/discount-catalogue");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Discount catalogue status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          if (pageNumber) {
            dispatch(getDiscountCataloguePageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual discount catalogue
   */
  const DiscountCatalogueById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getDiscountCatalogueById> => {
    return new Promise<getDiscountCatalogueById>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.DISCOUNT_CATALOGUE + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for deleting discount catalogue
   */
  const deleteDiscountCatalogueService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.DISCOUNT_CATALOGUE + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDiscountCatalogueData());
          dispatch(clearTotalDiscountCatalogueData());
          toast.success("Discount catalogue deleted successfully!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getDiscountCataloguePageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getAllDiscountCatalogueService,
    addUpdateDiscountCatalogueService,
    DiscountCatalogueById,
    deleteDiscountCatalogueService,
  };
}

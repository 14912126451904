import {
  CLEAR_CUSTOMER_NOTES,
  CLEAR_TOTAL_CUSTOMER_NOTES,
  GET_CUSTOMER_NOTES,
  GET_TOTAL_CUSTOMER_NOTES,
} from "../../ReduxKeys";

/**
 * Setting Customer Note list data into reducer
 * @param {*} customerNote
 * @returns {*}
 */
export const getCustomerNoteData = (customerNote) => ({
  type: GET_CUSTOMER_NOTES,
  payload: customerNote,
});

/**
 * clearing Customer Note list data
 * @returns {*}
 */
export const clearCustomerNoteData = () => ({
  type: CLEAR_CUSTOMER_NOTES,
});

/**
 * Setting total Customer Note list data into reducer (No pagnation)
 * @param {*} customerNote
 * @returns {*}
 */
export const getTotalCustomerNoteData = (customerNote) => ({
  type: GET_TOTAL_CUSTOMER_NOTES,
  payload: customerNote,
});

/**
 * Clearing total Customer Note list data (No pagination)
 * @returns {*}
 */
export const clearTotalCustomerNoteData = () => ({
  type: CLEAR_TOTAL_CUSTOMER_NOTES,
});
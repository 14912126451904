import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  SpotResponse,
  AddUpdateSpotRequest,
  getAllSpotRequest,
} from "./SpotModal";
import {
  clearSpotData,
  clearTotalSpotData,
  getSpotData,
  getTotalSpotData,
} from "../../../redux/Actions/spotAction/SpotAction";
import {
  clearSpotPageData,
  getSpotPageData,
} from "../../../redux/Actions/spotAction/SpotPageAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function SpotServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get leaf level category list of spots
   */
  const getAllSpotService = (
    spotsListRequest: getAllSpotRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<SpotResponse> => {
    return new Promise<SpotResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          spotsListRequest.p_skip !== undefined &&
          spotsListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${spotsListRequest.p_skip}&`;
        }
        if (
          spotsListRequest.p_take !== undefined &&
          spotsListRequest.p_take !== null
        ) {
          queryParams += `p_take=${spotsListRequest.p_take}&`;
        }
        if (
          spotsListRequest.p_name !== undefined &&
          spotsListRequest.p_name !== null
        ) {
          queryParams += `p_name=${spotsListRequest.p_name}&`;
        }
        if (
          spotsListRequest.p_branch_id !== undefined &&
          spotsListRequest.p_branch_id !== null
        ) {
          queryParams += `p_branch_id=${spotsListRequest.p_branch_id}&`;
        }
        if (
          spotsListRequest.p_is_available !== undefined &&
          spotsListRequest.p_is_available !== null
        ) {
          queryParams += `p_is_available=${spotsListRequest.p_is_available}&`;
        }
        if (
          spotsListRequest.p_is_active !== undefined &&
          spotsListRequest.p_is_active !== null
        ) {
          queryParams += `p_is_active=${spotsListRequest.p_is_active}&`;
        }
        if (
          spotsListRequest.p_is_deleted !== undefined &&
          spotsListRequest.p_is_deleted !== null
        ) {
          queryParams += `p_is_deleted=${spotsListRequest.p_is_deleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.SPOTS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalSpotData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getSpotData(response));
          } else {
            dispatch(getSpotData(response));
          }
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          resolve(response);
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating spots
   */
  const addUpdateSpotService = (
    addUpdateSpotRequest: AddUpdateSpotRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await postApiService(
          API_URL.BASE_URL + API_URL.SPOTS,
          token,
          addUpdateSpotRequest,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearSpotData());
          dispatch(clearTotalSpotData());
          if (type === "add") {
            dispatch(clearSpotPageData());
            toast.success(
              response?.message ? response.message : "Spot added successfully!"
            );
            navigate("/spot");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Spot undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getSpotPageData(pageNumber + 1));
                } else {
                  dispatch(clearSpotPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Spot updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/spot");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Spot status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getSpotPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting spots
   */
  const deleteSpotService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.SPOTS + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearSpotData());
          dispatch(clearTotalSpotData());
          toast.success(
            response?.message ? response.message : "Spot deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getSpotPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return { getAllSpotService, addUpdateSpotService, deleteSpotService };
}

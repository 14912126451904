import { CLEAR_NEW_VENDOR_PAGE, GET_NEW_VENDOR_PAGE } from "../../ReduxKeys";

const newVendorPageInitialState = {
  items: 1,
};

/**
 * Store new vendor page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const NewVendorPageReducer = (
  state = newVendorPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_NEW_VENDOR_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_NEW_VENDOR_PAGE:
      return newVendorPageInitialState;
    default:
      return state;
  }
};
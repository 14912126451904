import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearExpenseData } from "../../redux/Actions/ExpenseAction/ExpenseAction";
import { clearExpenseFilterData } from "../../redux/Actions/ExpenseAction/ExpenseFilterAction";
import {
  clearExpensePageData,
  getExpensePageData,
} from "../../redux/Actions/ExpenseAction/ExpensePageAction";
import Toggle from "../../components/Toggle/Toggle";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { FaFilter, FaPlus } from "react-icons/fa";
import Button from "../../components/Button/Button";
import Filter from "../../components/Filter/Filter";
import CustomPagination from "../../components/Pagination/Pagination";
import CustomTable from "../../components/Table/Table";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import ExpenseService from "./ExpenseService/ExpenseSerive";

const Expense = () => {
  const { t } = useTranslation();
  const tokenData = UtilsData();
  const { getAllExpenseService } = ExpenseService();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [expenseActionData, setExpenseActionData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const expenseData = useSelector((state) => state.expense);
  const expensePage = useSelector((state) => state.expensePage?.items);
  const expenseFilter = useSelector((state) => state.expenseFilter);
  const showActions = {
    showEdit: true,
    showDelete: true,
    showView: true,
    navigate: "/add-expense",
  };

  const showKey = {
    name: true,
    expenseDate: true,
    amount: true,
    categoryNames: true,
    instruction: true,
  };

  const [columns, setColumns] = useState([
    {
      name: t("columns.name"),
      selector: (row) => row.name,
    },
    {
      name: t("columns.expense_date"),
      selector: (row) => row.expenseDate,
    },
    {
      name: t("columns.amount"),
      selector: (row) => row.amount,
    },
    {
      name: t("columns.category"),
      selector: (row) => row.categoryNames,
    },
    {
      name: t("columns.message"),
      selector: (row) => row.instruction,
    },
    {
      name: t("columns.actions"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);

  const [show, setShow] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setExpenseActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setColumns([
      {
        name: t("columns.name"),
        selector: (row) => row.name,
      },
      {
        name: t("columns.expense_date"),
        selector: (row) => row.expenseDate,
      },
      {
        name: t("columns.amount"),
        selector: (row) => row.amount,
      },
      {
        name: t("columns.category"),
        selector: (row) => row.categoryNames,
      },
      {
        name: t("columns.message"),
        selector: (row) => row.instruction,
      },
      {
        name: t("columns.actions"),
        cell: (row) => <Toggle checked={row.toggle} />,
      },
    ]);
    // eslint-disable-next-line
  }, [localisation]);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (expenseData && expenseData.items && expenseData.items.length === 0) {
      fetchExpense();
    } else {
      if (expenseData.items[0]?.data && expenseData.items[0].data.length > 0) {
        const transformedExpenseData = expenseData.items[0].data.map((item) => {
          const formattedDate = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date(item.expense_date));

          return {
            name: item.name,
            expenseDate: formattedDate,
            amount: item.amount,
            categoryNames: item?.category_ids
              ? JSON.parse(item?.category_names)
              : [],
            instruction: item.details,
            category: item?.category_ids ? JSON.parse(item?.category_ids) : [],
            id: item.id,
            Date: item.expense_date,
          };
        });

        setTableData(transformedExpenseData);
      } else {
        setTableData([]);
      }
    }
    // eslint-disable-next-line
  }, [expenseData, expenseFilter]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchExpense = () => {
    if (expensePage > 1) {
      const requestBody = {
        p_skip: (expensePage - 2) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(expenseFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(expenseFilter.endDate),
      };
      if (expenseFilter.title !== "" && expenseFilter.title !== null) {
        requestBody.p_name = expenseFilter.title;
      }
      if (expenseFilter.amount !== "" && expenseFilter.amount !== null) {
        requestBody.p_amount = expenseFilter.amount;
      }
      if (expenseFilter.category !== "" && expenseFilter.category !== null) {
        requestBody.p_category_id = expenseFilter.category;
      }
      setCurrentPage(expensePage - 1);
      dispatch(getExpensePageData(expensePage - 1));
      getAllExpenseService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (expensePage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(expenseFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(expenseFilter.endDate),
      };
      if (expenseFilter.title !== "" && expenseFilter.title !== null) {
        requestBody.p_name = expenseFilter.title;
      }
      if (expenseFilter.amount !== "" && expenseFilter.amount !== null) {
        requestBody.p_amount = expenseFilter.amount;
      }
      if (expenseFilter.category !== "" && expenseFilter.category !== null) {
        requestBody.p_category_id = expenseFilter.category;
      }
      getAllExpenseService(requestBody, tokenData, dispatch);
    }
  };

  const onPageChange = (pageNumber) => {
    const requestBody = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_start_date: formatDateToYYYYMMDD(expenseFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(expenseFilter.endDate),
    };
    if (expenseFilter.title !== "" && expenseFilter.title !== null) {
      requestBody.p_name = expenseFilter.title;
    }
    if (expenseFilter.amount !== "" && expenseFilter.amount !== null) {
      requestBody.p_amount = expenseFilter.amount;
    }
    if (expenseFilter.category !== "" && expenseFilter.category !== null) {
      requestBody.p_category_id = expenseFilter.category;
    }
    if (pageNumber !== currentPageNumber) {
      getAllExpenseService(requestBody, tokenData, dispatch);
    }
    setCurrentPage(pageNumber);
    dispatch(getExpensePageData(pageNumber));
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearExpenseData());
    dispatch(clearExpenseFilterData());
    dispatch(clearExpensePageData());
  };

  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {expenseActionData && expenseActionData.length > 0
                    ? getRouteNameFromSidebar(
                        expenseActionData[0].name,
                        localisation
                      )
                    : t("Expense")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {expenseActionData && expenseActionData.length > 0
                    ? getRouteNameFromSidebar(
                        expenseActionData[0].name,
                        localisation
                      )
                    : t("Expense")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {((expenseFilter.startDate !== "" &&
                expenseFilter.startDate !== null &&
                new Date(
                  formatDateToYYYYMMDD(expenseFilter.startDate)
                ).toDateString() !==
                  new Date(
                    new Date().setMonth(new Date().getMonth() - 1)
                  ).toDateString()) ||
                (expenseFilter.endDate !== "" &&
                  expenseFilter.endDate !== null &&
                  new Date(
                    formatDateToYYYYMMDD(expenseFilter.endDate)
                  ).toDateString() !== new Date().toDateString()) ||
                (expenseFilter.title !== "" && expenseFilter.title !== null) ||
                (expenseFilter.category !== "" &&
                  expenseFilter.category !== null) ||
                (expenseFilter.amount !== "" &&
                  expenseFilter.amount !== null)) && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="event-clear-filter">
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="expense" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              <Button
                data-bs-toggle="tooltip"
                title="Add Expense"
                onClick={() => navigate("/add-expense")}
                className="btn btn-fillter-main"
                name={
                  <div className="Plus">
                    <FaPlus />
                  </div>
                }
              ></Button>
            </div>
          </div>

          {expenseData &&
            expenseData.items[0]?.data &&
            expenseData.items[0].data && (
              <>
                <CustomTable
                  page="expense"
                  checkBool={false}
                  showKey={showKey}
                  showActions={showActions}
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  disabled={
                    expenseActionData &&
                    expenseActionData[0]?.edit &&
                    expenseActionData[0].edit === "0"
                  }
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                  pageData={expensePage}
                />

                {expenseData.items[0].total_records >
                  expenseData.items[0].data.length &&
                  expenseData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={expenseData.items[0].total_records}
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default Expense;

import {
  CLEAR_PRODUCT_PRICING_DATA,
  CLEAR_TOTAL_PRODUCT_PRICING_DATA,
  SET_PRODUCT_PRICING_DATA,
  SET_TOTAL_PRODUCT_PRICING_DATA,
} from "../../ReduxKeys";

const productPricingInitialState = {
  items: [],
};

/**
 * Store product Pricing list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ProductPricingReducer = (
  state = productPricingInitialState,
  action
) => {
  switch (action.type) {
    case SET_PRODUCT_PRICING_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_PRODUCT_PRICING_DATA:
      return productPricingInitialState;
    default:
      return state;
  }
};

const totalProductPricingInitialState = {
  items: [],
};

/**
 * Store total product pricing list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalProductPricingReducer = (
  state = totalProductPricingInitialState,
  action
) => {
  switch (action.type) {
    case SET_TOTAL_PRODUCT_PRICING_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_PRODUCT_PRICING_DATA:
      return totalProductPricingInitialState;
    default:
      return state;
  }
};
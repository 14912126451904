// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ingredientName {
  font-weight: 500;
  font-size: 18px;
}

.grn-data {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/GoodsReceiveNote/AddGoodsReceiveNote/AddGoodsReceiveNote.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AAEF","sourcesContent":[".ingredientName {\n  font-weight: 500;\n  font-size: 18px;\n}\n.grn-data {\n  height: 400px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 10px;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { parseImage } from "../../../services/Regular-services/ImageService";

export const ProductToggleRequestBody = ({
  productDetail,
  isActive,
  isDelete,
  userId,
  toggleData,
}) => {
  const tagData = productDetail.data.tag_ids
    ? productDetail.data.tag_ids.split(",").map(Number)
    : [];
  const tagId = tagData.map((item) => ({
    id: item,
  }));

  const productImage = productDetail.data.image_data
    ? parseImage(JSON.stringify(productDetail.data.image_data))
    : null;
  const productImageData = productImage
    ? productImage.map((item) => ({
        id: parseInt(item.id),
        type_id: parseInt(item.type),
      }))
    : [];

   const productCategoryData = [{id: Number(productDetail.data?.category_id)}]
  // const productCategory = productDetail.data.category_id
  //   ? productDetail.data.category_id.split(",").map(Number)
  //   : [];
  // const productCategoryData = productCategory.map((item) => ({
  //   id: item,
  // }));

  // const productSubCategory = productDetail.data.sub_category_ids
  //   ? productDetail.data.sub_category_ids.split(",").map(Number)
  //   : [];
  // const productSubCategoryData = productSubCategory.map((item) => ({
  //   id: item,
  // }));

  // const productLeaf = productDetail.data.leaf_level_category_ids
  //   ? productDetail.data.leaf_level_category_ids.split(",").map(Number)
  //   : [];
  // const productLeafData = productLeaf.map((item) => ({
  //   id: item,
  // }));

  const attributeData = productDetail.data.attribute_data
    ? parseImage(productDetail.data.attribute_data)
    : [];

  const attributeImageData = productDetail.data.attribute_image_data
    ? parseImage(JSON.stringify(productDetail.data.attribute_image_data))
    : [];

  const attributeImageMap = new Map(
    attributeImageData.map((item) => [item.id, item])
  );
  const mergedAttributeData = attributeData.map((data) => ({
    id: data.id,
    value: data.value,
    ...(parseInt(attributeImageMap.get(data?.attribute_image)?.id) && {
      imageId: parseInt(attributeImageMap.get(data.attribute_image).id) || null,
    }),
    ...(parseInt(attributeImageMap.get(data?.attribute_image)?.type) && {
      type_id:
        parseInt(attributeImageMap.get(data.attribute_image).type) || null,
    }),
    category_id: parseInt(data.attribute_category),
  }));

  const requestBody = {
    id: toggleData.productId,
    warehouse_id: productDetail?.data?.warehouse_id || null,
    brand_id: productDetail.data.brand_id,
    manufacturer_id: productDetail?.data?.manufacturer_id || null,
    packer_id: productDetail?.data?.packer_id || null,
    importer_id: productDetail?.data?.importer_id || null,
    name: productDetail.data?.name,
    description: productDetail?.data?.description || "",
    additional_description: productDetail?.data?.additional_description || "",
    product_code: productDetail?.data?.product_code || null,
    hsn: productDetail?.data?.hsn,
    price: productDetail?.data?.price,
    p_purchase_price: productDetail?.data?.purchase_price
      ? productDetail.data.purchase_price
      : 0,
    unit: productDetail?.data?.unit || null,
    sku: productDetail?.data?.sku || null,
    size: productDetail?.data?.size || null,
    color_code: productDetail?.data?.color_code || null,
    country_of_origin: productDetail?.data?.country_of_origin || null,
    color_name: productDetail?.data?.color_name || null,
    Tags: tagId,
    SKID: productDetail?.data?.SKID || null,
    EAN_CODES: productDetail?.data?.EAN_CODES || null,
    imageIds: productImageData,
    category_ids: productCategoryData,
    // sub_category_ids: productSubCategoryData,
    // leaf_level_ids: productLeafData,
    attributeData: mergedAttributeData,
    Unit_Fill_Pack_Size: productDetail?.data?.Unit_Fill_Pack_Size || null,
    Unit_Pack_Size: productDetail?.data?.Unit_Pack_Size || null,
    Length_of_outer_package: productDetail?.data?.Length_of_outer_package || null,
    Breadth_of_outer_package: productDetail?.data?.Breadth_of_outer_package || null,
    Height_of_outer_package: productDetail?.data?.Height_of_outer_package || null,
    Weight_including_packaging: productDetail.data.Weight_including_packaging,
    modified_by: userId,
    is_active: isActive
      ? toggleData.isActive === "1"
        ? "0"
        : "1"
      : productDetail.data.is_active,
    IsDeleted: isDelete
      ? toggleData.isDeleted === "1"
        ? "0"
        : "1"
      : productDetail.data.IsDeleted,
    p_veg_nonveg: toggleData?.veg_nonveg,
    p_jain_regular: toggleData?.jain_regular,
    p_exclude_filter: toggleData?.filter,
    p_is_vegan: toggleData?.vegan_or_not,
    p_is_jain: toggleData?.jain_or_not,
    p_sgst:
      productDetail.data?.sgst && productDetail.data.sgst !== ""
        ? productDetail.data.sgst
        : null,
    p_cgst:
      productDetail.data?.cgst && productDetail.data.cgst !== ""
        ? productDetail.data.cgst
        : null,
    p_igst:
      productDetail.data?.igst && productDetail.data.igst !== ""
        ? productDetail.data.igst
        : null,
    p_vat:
      productDetail.data?.vat && productDetail.data.vat !== ""
        ? productDetail.data.vat
        : null,
  };
  return requestBody;
};
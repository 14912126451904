import React, { useEffect, useRef } from "react";
import "../Industry/Industry.scss";

const Industry = () => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  return (
    <>
      <div className="main-section"></div>
    </>
  );
};

export default Industry;
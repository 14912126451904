import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { modifyString } from "../../../services/Regular-services/StringService";
import CustomTable from "../../../components/Table/Table";
import {
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import Input from "../../../components/input/Input";
import { useTranslation } from "react-i18next";
import { getRouteNameFromSidebar } from "../../../Utils/Utils";

const AddPurchaseOrder = () => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    vendor: "",
    user: "",
    status: "0",
    message: "",
  });
  const [inventoryData, setInventoryData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const validationSchema = Yup.object({
    vendor: Yup.string().required("Vendor is required"),
    user: Yup.string().required("Field is required!"),
  });
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [purchaseOrderActionData, setPurchaseOrderActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === 'purchase-order'
      );
      setPurchaseOrderActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      const ingredientVals = JSON.parse(Data.data.ingredients);
      const addedIngredientVals = ingredientVals.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.value);
        let unit = item.unit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }

        return {
          ingredientName: item?.ingredientTitle,
          quantity: quantityInOriginalUnit,
          unit: unit,
          instruction:
            item.pr_ingredient_instruction !== "null"
              ? item.pr_ingredient_instruction
              : "N/A",
          category_name: item?.category_name,
          IngredientId: item?.ingredient_id,
          rejection_reason: item.rejection_reason,
          status: item.status,
          category_id: item?.category_id,
        };
      });
      setInventoryData(addedIngredientVals);
      setInitialValues({
        id: Data.data.id,
        vendor: Data.data.name,
        poNum: Data.data.poNum,
        prNum: Data.data.prNum,
        user: Data.data.assign_user_id,
        status: Data.data.orderStatus,
        message: Data.data.instructions,
      });
    }
  }, [Data]);

  const tooltipRef = useRef(null);
  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const showKey = {
    ingredientName: true,
    quantity: true,
    unit: true,
    instruction: true,
  };

  const columns = [
    {
      name: "Ingredient Name",
      selector: (row) => row.ingredientName,
    },
    {
      name: "Quanity",
      selector: (row) => row.quantity,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
    },
    {
      name: "Instruction",
      selector: (row) => row.instruction,
    },
  ];

  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  const handleSubmit = () => {};

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {purchaseOrderActionData && purchaseOrderActionData.length > 0
                    ? modifyString(getRouteNameFromSidebar(purchaseOrderActionData[0].name, localisation))
                    : t("Purchase Order")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/purchase-order")}>
                  {purchaseOrderActionData && purchaseOrderActionData.length > 0
                    ? getRouteNameFromSidebar(purchaseOrderActionData[0].name, localisation)
                    : t("Purchase Order")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {purchaseOrderActionData && purchaseOrderActionData.length > 0
                    ? modifyString(getRouteNameFromSidebar(purchaseOrderActionData[0].name, localisation))
                    : t("Purchase Order")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {purchaseOrderActionData && purchaseOrderActionData.length > 0
                ? modifyString(getRouteNameFromSidebar(purchaseOrderActionData[0].name, localisation))
                : t("Purchase Order")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="add-roles-form row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_vendor")}
                            <span className="required-label"></span>
                          </h4>
                          <Input
                            type="text"
                            name="vendor"
                            label="Vendor"
                            placeholder={t("placeholder_product_code")}
                            className="add-roles"
                            maxLength={70}
                            disabled={true}
                            hidelength={"true"}
                            value={values.vendor}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_purchase_order_number")}
                            <span className="required-label"></span>
                          </h4>
                          <Input
                            type="text"
                            name="poNum"
                            label="Purchase Order Number"
                            placeholder={t("placeholder_product_code")}
                            className="add-roles"
                            maxLength={70}
                            disabled={true}
                            hidelength={"true"}
                            value={values.poNum}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_purchase_requisition_number")}
                            <span className="required-label"></span>
                          </h4>
                          <Input
                            type="text"
                            name="prNum"
                            label={t("lable_purchase_requisition_number")}
                            placeholder="Enter Product Code"
                            className="add-roles"
                            maxLength={70}
                            disabled={true}
                            hidelength={"true"}
                            value={values.prNum}
                          />
                        </div>
                      </div>
                      <CustomTable
                        page="purchase-order"
                        showToggle={false}
                        checkBool={false}
                        showKey={showKey}
                        showActions={false}
                        showTooltip={false}
                        data={inventoryData}
                        heading={columns}
                        disabled={true}
                        currentPage={0}
                        styleData={styleData}
                      />
                      {/* <div className="col-12">
                          <div className="paragraph-editor">
                            <div className="btn-submit mt-3">
                              {((Data && Data.isEdit === true) || !Data) && (
                                <>
                                  <Button
                                    type="submit"
                                    name="Save"
                                    className="btn login-btn"
                                  />
                                  <Button
                                    type="button"
                                    name="Cancel"
                                    className="btn clear-btn login-btn"
                                    onClick={() =>
                                      handleCancleClick(
                                        "/purchase-order",
                                        navigate
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddPurchaseOrder;

import { Dispatch } from "redux";
import {
  ImageAddRequest,
  ImageUpdateRequest,
  getAllImagesRequest,
  getAllImagesResponse,
} from "./ImageModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearImageData,
  clearTotalImageData,
  getImageData,
  getTotalImageData,
} from "../../../redux/Actions/imageGalleryAction/ImageGallaryAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearImagePageData,
  getImagePageData,
} from "../../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function ImageManagerService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get image list
   */
  const getAllImageService = (
    imageListRequest: getAllImagesRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllImagesResponse> => {
    return new Promise<getAllImagesResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          imageListRequest.p_skip !== undefined &&
          imageListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${imageListRequest.p_skip}&`;
        }
        if (
          imageListRequest.p_take !== undefined &&
          imageListRequest.p_take !== null
        ) {
          queryParams += `p_take=${imageListRequest.p_take}&`;
        }
        if (
          imageListRequest.p_type_id !== undefined &&
          imageListRequest.p_type_id !== null
        ) {
          queryParams += `p_type_id=${imageListRequest.p_type_id}&`;
        }
        if (
          imageListRequest.p_IsDeleted !== undefined &&
          imageListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${imageListRequest.p_IsDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.IMAGEMANAGER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalImageData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getImageData(response));
          } else {
            dispatch(getImageData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message === "Permission does not exists."
                  ? "Image permission does not exists."
                  : response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding images
   */
  const addImageService = (
    addUpdateImageRequest: ImageAddRequest,
    token: string,
    toggle: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      addUpdateImageRequest.images.forEach((image, index) => {
        formData.append("images", image);
      });
      // Append other fields to the FormData object
      formData.append("type_id", addUpdateImageRequest.type_id.toString());
      formData.append("created_by", addUpdateImageRequest.created_by);
      try {
        dispatch(setLoader());
        const response = await fetch(API_URL.BASE_URL + API_URL.IMAGEMANAGER, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Language": localisation ? localisation : "en",
            "accept-language": localisation ? localisation : "en",
          },
          body: formData,
        });
        dispatch(clearImageData());
        dispatch(clearTotalImageData());
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          return data;
          // throw new Error('Network response was not ok');
        } else {
          toast.success("Images added successfully");
          const data = await response.json();
          dispatch(clearImagePageData());
          toggle();
          return data;
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for updating image
   */
  const updateImageService = (
    updateImageRequest: ImageUpdateRequest,
    token: string,
    toggle: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      const images = Array.isArray(updateImageRequest.images)
        ? updateImageRequest.images
        : [updateImageRequest.images];

      images.forEach((image) => {
        formData.append("images", image);
      });

      updateImageRequest.image_ids.forEach((item) => {
        formData.append("image_ids", JSON.stringify(item));
      });

      // Append other fields to the FormData object
      formData.append("type_id", updateImageRequest.type_id.toString());
      formData.append("created_by", updateImageRequest.created_by);
      formData.append("modified_by", updateImageRequest.modified_by);
      formData.append("IsDeleted", updateImageRequest.IsDeleted);
      try {
        dispatch(setLoader());
        const response = await fetch(API_URL.BASE_URL + API_URL.IMAGEMANAGER, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Language": localisation ? localisation : "en",
            "accept-language": localisation ? localisation : "en",
          },
          body: formData,
        });
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          return data;
        } else {
          const data = await response.json();
          if (toggle && toggle === "undo") {
            toast.success(
              data?.message ? data.message : "Image undeleted successfully"
            );
            dispatch(clearImageData());
            dispatch(clearTotalImageData());
          } else {
            toast.success(
              data?.message ? data.message : "Image updated successfully"
            );
            dispatch(clearImageData());
            dispatch(clearTotalImageData());
            if (toggle) {
              if (pageNumber) {
                dispatch(getImagePageData(pageNumber + 1));
              } else {
                dispatch(clearImagePageData());
              }
              toggle();
            }
          }
          return data;
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting image
   */
  const deleteImageService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.IMAGEMANAGER + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearImageData());
          dispatch(clearTotalImageData());
          toast.success("Image deleted successfully!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getImagePageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getAllImageService,
    addImageService,
    updateImageService,
    deleteImageService,
  };
}
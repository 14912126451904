import { CLEAR_DISCOUNT_FILTER, GET_DISCOUNT_FILTER } from "../../ReduxKeys";

const discountFilterInitialState = {
  name: "",
  type: "",
  start_date: "",
  end_date: "",
  code: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store discount filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const discountFilterReducer = (
  state = discountFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_DISCOUNT_FILTER:
      return {
        ...state,
        name: action.payload.name,
        type: action.payload.type,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        code: action.payload.code,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_DISCOUNT_FILTER:
      return discountFilterInitialState;
    default:
      return state;
  }
};
export const spotToggleRequest = ({ toggleData, isActive, isDelete }) => {
  const requestBody = {
    p_id: toggleData.id,
    p_name: toggleData.name,
    p_size: toggleData.size,
    p_price: toggleData.price,
    p_is_available: toggleData.is_available,
    p_is_active: isActive
      ? toggleData.is_active === "1"
        ? "0"
        : "1"
      : toggleData.is_active,
    p_is_deleted: isDelete ? "0" : toggleData.is_delete,
  };
  return requestBody;
};
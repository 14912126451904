import { UPLOAD_IMAGE_DATA } from "../../ReduxKeys";

/**
 * Upload image action
 * @param {*} image
 * @returns {*}
 */
export const uploadImage = (image) => ({
  type: UPLOAD_IMAGE_DATA,
  payload: image,
});
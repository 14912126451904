import {
  CLEAR_STAKEHOLDER_DETAIL_DATA,
  GET_STAKEHOLDER_DETAIL_DATA,
} from "../../ReduxKeys";

/**
 * Setting StakeHolder Detail list data into reducer
 * @param {*} paymentDetail
 * @returns {*}
 */
export const getStakeHolderDetailData = (StakeHolderDetail) => ({
  type: GET_STAKEHOLDER_DETAIL_DATA,
  payload: StakeHolderDetail,
});

/**
 * clearing StakeHolder Detail list data
 * @returns {*}
 */
export const clearStakeHolderDetailData = () => ({
  type: CLEAR_STAKEHOLDER_DETAIL_DATA,
});
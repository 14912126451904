import { Dispatch } from "redux";
import {
  addUpdateUnitsRequest,
  getAllUnitsRequest,
  getAllUnitsResponse,
  getUnitsDropdownRequest,
} from "./UnitsModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  clearTotalUnitsData,
  clearUnitsData,
  getTotalUnitsData,
  getUnitsData,
} from "../../../redux/Actions/UnitsAction/UnitsAction";
import {
  clearUnitsPageData,
  getUnitsPageData,
} from "../../../redux/Actions/UnitsAction/UnitsPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import {
  getTotalUnitsDropdownData,
  getUnitsDropdownData,
} from "../../../redux/Actions/UnitsAction/UnitsDropdownAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { putApiService } from "../../../services/API-services/PutService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function UnitServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get brand list
   */
  const getAllUnitsService = (
    unitsRequest: getAllUnitsRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllUnitsResponse> => {
    return new Promise<getAllUnitsResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (unitsRequest.p_skip !== undefined && unitsRequest.p_skip !== null) {
          queryParams += `p_skip=${unitsRequest.p_skip}&`;
        }
        if (
          unitsRequest.p_limit !== undefined &&
          unitsRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${unitsRequest.p_limit}&`;
        }
        if (
          unitsRequest.p_unit_id !== undefined &&
          unitsRequest.p_unit_id !== null
        ) {
          queryParams += `p_unit_id=${unitsRequest.p_unit_id}&`;
        }
        if (
          unitsRequest.p_isActive !== undefined &&
          unitsRequest.p_isActive !== null
        ) {
          queryParams += `p_isActive=${unitsRequest.p_isActive}&`;
        }
        if (
          unitsRequest.p_IsDeleted !== undefined &&
          unitsRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${unitsRequest.p_IsDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.UNITS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalUnitsData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getUnitsData(response));
          } else {
            dispatch(getUnitsData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating Product Inventory
   */
  const addUpdateUnitsService = (
    addUpdateUnitsRequest: addUpdateUnitsRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.UNITS,
            token,
            addUpdateUnitsRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.UNITS,
            token,
            addUpdateUnitsRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearUnitsData());
          dispatch(clearTotalUnitsData());
          if (type === "add") {
            dispatch(clearUnitsPageData());
            toast.success(
              response?.message ? response.message : "Units added successfully!"
            );
            navigate("/units");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Unit undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getUnitsPageData(pageNumber + 1));
                } else {
                  dispatch(clearUnitsPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Units updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/units");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Brand status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getUnitsPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting units
   */
  const deleteUnitService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.INVENTORYITEM + "/" + deleteId,
          token
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearUnitsData());
          dispatch(clearTotalUnitsData());
          toast.success(
            response?.message ? response.message : "Units deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getUnitsPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get brand list
   */
  const getUnitsDropdownService = (
    unitsDropdownRequest: getUnitsDropdownRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllUnitsResponse> => {
    return new Promise<getAllUnitsResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          unitsDropdownRequest.p_skip !== undefined &&
          unitsDropdownRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${unitsDropdownRequest.p_skip}&`;
        }
        if (
          unitsDropdownRequest.p_limit !== undefined &&
          unitsDropdownRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${unitsDropdownRequest.p_limit}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.UNITS +
            API_URL.ALL_UNITS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalUnitsDropdownData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getUnitsDropdownData(response));
          } else {
            dispatch(getUnitsDropdownData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };
  return {
    getAllUnitsService,
    addUpdateUnitsService,
    deleteUnitService,
    getUnitsDropdownService,
  };
}
import {
  CLEAR_ALL_PREFERENCE_DATA,
  GET_ALL_PREFERENCE_DATA,
} from "../../ReduxKeys";

const allPreferencesInitialState = {
  items: [],
};

/**
 * Store all preferences list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const AllPreferencesReducer = (
  state = allPreferencesInitialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_PREFERENCE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_ALL_PREFERENCE_DATA:
      return allPreferencesInitialState;
    default:
      return state;
  }
};
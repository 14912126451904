import {
  CLEAR_INVENTORY_ITEM_PAGE,
  GET_INVENTORY_ITEM_PAGE,
} from "../../ReduxKeys";

/**
 * Set inventory item page data
 * @param {*} page
 * @returns {*}
 */
export const getInventoryItemPageData = (page) => ({
  type: GET_INVENTORY_ITEM_PAGE,
  payload: page,
});

/**
 * Clear inventory item page data
 * @returns {*}
 */
export const clearInventoryItemPageData = () => ({
  type: CLEAR_INVENTORY_ITEM_PAGE,
});
import { toast } from "react-toastify";
import { API_URL, toastClass, ValidationMessage } from "../../../Utils/Utils";

import { CalculateCurrentTime } from "../../../services/Regular-services/DateService";
import { postApiService } from "../../../services/API-services/PostService";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { Dispatch } from "redux";
import { LoginResponse } from "./LoginModalAPIResponse";
import { login, loginFail } from "../../../redux/Actions/authAction/AuthAction";
import UnitServices from "../../Units/UnitsService/UnitsService";
import SidebarServices from "../../../components/Sidebar/SidebarService/SidebarService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function LoginService() {
  const { getUnitsDropdownService } = UnitServices();
  const { getSideBarByRoleId } = SidebarServices();
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /**
   * Service for login API Integration
   * @param email
   * @param password
   * @param rememberMe
   * @param token
   * @param dispatch
   * @returns
   */
  const loginAuth = (
    phone: string,
    otp: string,
    token: string,
    dispatch: Dispatch
  ): Promise<LoginResponse> => {
    return new Promise<LoginResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await postApiService(
          API_URL.BASE_URL +
            API_URL.LOGIN +
            `?phone_number=${phone}&otp=${otp}`,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          const currentLoginTime = CalculateCurrentTime();
          toast.success(
            response?.message ? response.message : "login success!"
          );
          dispatch(login(response, currentLoginTime));
          getSideBarByRoleId(
            response.data.rolePermissionsId,
            response.token,
            dispatch
          );
          const unitRequestBody = {
            p_skip: 0,
            p_limit: 1000000,
          };
          getUnitsDropdownService(unitRequestBody, response.token, dispatch);
          resolve(response);
        } else {
          if (response === "unauthenticated") {
            toast.error("login failed, please try again!");
          } else {
            toast.error(
              response?.message
                ? response.message
                : "login failed, please try again!"
            );
          }
          dispatch(loginFail());
          // reject(response);
        }
      } catch (error: any) {
        toast.error(
          error?.message
            ? error.message
            : t(ValidationMessage.SOMETHING_WENT_WRONG)
        );
        dispatch(loginFail());
      } finally {
        dispatch(clearLoader());
      }
    });
  };

  /**
   * Service for Request OTP
   * @param email
   * @param password
   * @param rememberMe
   * @param token
   * @param dispatch
   * @returns
   */
  const requestOTP = (
    phone: string,
    token: string,
    setIsOtpRequested: any,
    dispatch: Dispatch
  ): Promise<LoginResponse> => {
    return new Promise<LoginResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());

        const response = await postApiService(
          API_URL.BASE_URL + `auth/send-otp?phone_number=${phone}`,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          toast.success(
            response?.message ? response.message : "OTP sent successfully!"
          );
          setIsOtpRequested(true);
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          dispatch(loginFail());
          // reject(response);
        }
      } catch (error: any) {
        if (!document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        dispatch(loginFail());
      } finally {
        dispatch(clearLoader());
      }
    });
  };
  return { loginAuth, requestOTP };
}

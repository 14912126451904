import {
  CLEAR_CUSTOMER_RUSH_DATA,
  GET_CUSTOMER_RUSH_DATA,
} from "../../ReduxKeys";

/**
 * Setting customer rush list data into reducer
 * @param {*} customerRush
 * @returns {*}
 */
export const getCustomerRushData = (customerRush) => ({
  type: GET_CUSTOMER_RUSH_DATA,
  payload: customerRush,
});

/**
 * clearing event list data
 * @returns {*}
 */
export const clearCustomerRushData = () => ({
  type: CLEAR_CUSTOMER_RUSH_DATA,
});
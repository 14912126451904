// Layout.js
import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";


const Layout = ({ children }) => {
  return (
    <div className="main-section">
      {/* Sidebar */}
      <div className="sidebar-content">
        <Sidebar />  
      </div>

      {/* Main Body */}
      <div className="body-content">
        {/* Header */}
        <Header />
        
        {/* Page Content */}
        <div className="page-content">
          {children}
        </div>

        {/* Footer */}
      </div>
        <Footer />
    </div>
  );
};

export default Layout;

import {
  CLEAR_BRAND_DATA,
  CLEAR_TOTAL_BRAND,
  GET_BRAND_DATA,
  GET_TOTAL_BRAND,
} from "../../ReduxKeys";

/**
 * Setting brand list data into reducer
 * @param {*} brand
 * @returns {*}
 */
export const getBrandData = (brand) => ({
  type: GET_BRAND_DATA,
  payload: brand,
});

/**
 * clearing brand list data
 * @returns {*}
 */
export const clearBrandData = () => ({
  type: CLEAR_BRAND_DATA,
});

/**
 * Setting total brand list data into reducer (No pagnation)
 * @param {*} brand
 * @returns {*}
 */
export const getTotalBrandData = (brand) => ({
  type: GET_TOTAL_BRAND,
  payload: brand,
});

/**
 * Clearing total brand llist data (No pagination)
 * @returns {*}
 */
export const clearTotalBrandData = () => ({
  type: CLEAR_TOTAL_BRAND,
});
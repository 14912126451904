import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import "../Wallet/Wallet.scss";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { MdOutlineCancel } from "react-icons/md";
import Input from "../../components/input/Input";
import UtilsData, {
  getRouteNameFromSidebar,
  razorUrl,
  ValidationMessage,
} from "../../Utils/Utils";
import {
  clearWalletPageData,
  getWalletPageData,
} from "../../redux/Actions/WalletAction/WalletPageAction";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import { clearWalletFilterData } from "../../redux/Actions/WalletAction/WalletFilterAction";
import { clearWalletData } from "../../redux/Actions/WalletAction/WalletAction";
import CustomPagination from "../../components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import WalletServices from "./WalletService/WalletService";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";

const Wallet = () => {
  const {
    addOrderIdService,
    addUpdateWalletService,
    getAllWalletService,
    getTotalAmtWalletService,
    verifyTransactionService,
  } = WalletServices();
  const { t } = useTranslation();
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const walletData = useSelector((state) => state.wallet);
  const walletTotalAmt = useSelector((state) => state.walletTotalAmt?.items);
  const walletPage = useSelector((state) => state.walletPage?.items);
  const walletFilter = useSelector((state) => state.walletFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [walletActionData, setWalletActionData] = useState([]);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [razorModal, setRazorModal] = useState(false);
  const userData = useSelector((state) => state.auth?.user.data);
  const [totalAmt, setTotalAmt] = useState(0);
  const localisation = useSelector((state) => state.localization?.items);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues = {
    mobile: userData && userData?.phoneNumber ? userData.phoneNumber : "",
    amount: "5",
    type: "0",
  };
  const validationSchema = Yup.object({
    amount: Yup.string().required(t("Amount_is_required")),
  });

  const showKey = {
    name: true,
    phNum: true,
    // transactionID: true,
    transactionType: true,
    transactionAmount: true,
    taxAmount: true,
    totalAmount: true,
    transaction_purpuse: true,
  };

  const [columns, setColumns] = useState([
    {
      name: t("columns.name"),
      selector: (row) => row.name,
    },
    {
      name: t("columns.mobile"),
      selector: (row) => row.phNum,
    },
    // {
    //   name: "Transaction Number",
    //   selector: (row) => row.transactionID,
    // },

    {
      name: t("columns.amount"),
      selector: (row) => row.transactionAmount,
    },
    {
      name: t("columns.tax_amount"),
      selector: (row) => row.taxAmount,
    },
    {
      name: t("columns.total_amount"),
      selector: (row) => row.totalAmount,
    },
    {
      name: t("columns.type"),
      selector: (row) => row.transactionType,
    },
    {
      name: t("columns.purpose"),
      selector: (row) => row.transaction_purpuse,
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        name: t("columns.name"),
        selector: (row) => row.name,
      },
      {
        name: t("columns.mobile"),
        selector: (row) => row.phNum,
      },
      // {
      //   name: "Transaction Number",
      //   selector: (row) => row.transactionID,
      // },

      {
        name: t("columns.amount"),
        selector: (row) => row.transactionAmount,
      },
      {
        name: t("columns.tax_amount"),
        selector: (row) => row.taxAmount,
      },
      {
        name: t("columns.total_amount"),
        selector: (row) => row.totalAmount,
      },
      {
        name: t("columns.type"),
        selector: (row) => row.transactionType,
      },
      {
        name: t("columns.purpose"),
        selector: (row) => row.transaction_purpuse,
      },
    ]);
    // eslint-disable-next-line
  }, [localisation]);

  const styleData = {
    mobile: { type: { textAlign: "end" } },
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setWalletActionData(actionData);
    }
  }, [sideBarData]);

  const fetchTotalAmt = () => {
    try {
      if (
        walletTotalAmt &&
        walletTotalAmt.length > 0 &&
        walletTotalAmt[0] &&
        walletTotalAmt[0]?.data &&
        walletTotalAmt[0].data?.amount
      ) {
        getTotalAmtWalletService({}, tokenData, dispatch);
        const totalAmt = Number(walletTotalAmt[0]?.data?.amount) / 100;
        setTotalAmt(totalAmt);
      } else {
        getTotalAmtWalletService({}, tokenData, dispatch);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchTotalAmt();
    // eslint-disable-next-line
  }, [walletData]);

  /**
   * Fetch purchase requisition item data
   */
  const fetchWalletData = () => {
    const requestBody = {
      p_skip: walletPage > 1 ? (walletPage - 2) * 10 : (walletPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_created_date: formatDateToYYYYMMDD(walletFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(walletFilter.endDate),
    };

    if (walletFilter.status !== "" && walletFilter.status !== null) {
      requestBody.p_status = walletFilter.status;
    }
    if (walletFilter.type !== "" && walletFilter.type !== null) {
      requestBody.p_type = walletFilter.type;
    }
    getAllWalletService(requestBody, tokenData, dispatch);
    if (walletPage > 1) {
      setCurrentPage(walletPage - 1);
      dispatch(getWalletPageData(walletPage - 1));
    }
  };

  useEffect(() => {
    setCurrentPage(walletPage);
  }, [walletPage]);

  useEffect(() => {
    if (
      (walletData && walletData.items && walletData.items.length === 0) ||
      !walletData
    ) {
      fetchWalletData();
    } else {
      if (walletData.items[0]?.data && walletData.items[0].data.length > 0) {
        const transformedWalletData = walletData.items[0].data.map((item) => {
          const taxamt =
            item.taxAmount && item.taxAmount !== "0.00" && item.taxAmount !== 0
              ? (Number(item.taxAmount) / 100).toFixed(2)
              : 0;
          const totalAmt =
            item.taxAmount && item.taxAmount !== "0.00" && item.taxAmount !== 0
              ? (
                  Number(item?.amount) / 100 +
                  Number(item.taxAmount) / 100
                ).toFixed(2)
              : Number(item.amount) / 100;
          return {
            id: item.transactionId,
            name: item.first_name + " " + item.last_name,
            phNum: item.phone_number,
            // transactionID: item.transactionId,
            transactionAmount: "₹" + Number(item.amount) / 100,
            taxAmount: "₹" + taxamt,
            totalAmount: "₹" + Number(totalAmt),
            transactionType:
              item.transaction_type === "0" ? "Credited" : "Debited",
            transaction_purpuse: item.transaction_purpuse
              ? item.transaction_purpuse
              : "-",
            paymentStatus: item.status,
            paymentId: item.payment_id,
          };
        });
        setTableData(transformedWalletData);
      } else {
        setTableData([]);
      }
    }

    // eslint-disable-next-line
  }, [walletData, walletFilter]);

  // razorpay whole flow
  useEffect(() => {
    const script = document.createElement("script");
    script.src = razorUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleRazorpayPayment = (orederIdResponse, amount, mobile, type) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: orederIdResponse.amount,
      name: "Bespeakk",
      currency: orederIdResponse.currency,
      order_id: orederIdResponse.orderId,
      // description: "Test Transaction",
      handler: async (response) => {
        toast.success("Payment successful!");
        try {
          const requestBody = {
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          };
          const verifiedResponse = await dispatch(
            verifyTransactionService(requestBody, tokenData, "add", navigate)
          );
          if (
            verifiedResponse &&
            verifiedResponse?.message === "Payment verified successfully"
          ) {
            // const amt = orederIdResponse.amount;
            const transactionRequestBody = {
              p_amount: Number(amount) * 100,
              p_transaction_type: type,
              p_payment_id: response.razorpay_payment_id,
              p_tax_amount: Number(amount) * 0.18 * 100,
            };
            dispatch(
              addUpdateWalletService(
                transactionRequestBody,
                tokenData,
                "add",
                navigate
              )
            );
          }
        } catch (error) {
          toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
        }
      },
      modal: {
        ondismiss: () => {
          toast.warn("Payment was cancelled.");
        },
      },
      prefill: {
        name: "Enter Card Holder's Name",
        contact: mobile,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const razorpay = new window.Razorpay(options);
    razorpay.on("payment.failed", (response) => {
      // toast.error("Payment failed! Please try again.");
    });
    razorpay.open();
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_created_date: formatDateToYYYYMMDD(walletFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(walletFilter.endDate),
      };

      if (walletFilter.status !== "" && walletFilter.status !== null) {
        requestBody.p_status = walletFilter.status;
      }
      if (walletFilter.type !== "" && walletFilter.type !== null) {
        requestBody.p_type = walletFilter.type;
      }

      getAllWalletService(requestBody, tokenData, dispatch);
      dispatch(getWalletPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearWalletFilterData());
    dispatch(clearWalletPageData());
    dispatch(clearWalletData());
    handleClose();
  };

  const handleSubmit = async (values) => {
    const requestBody = {
      amount: Number(values.amount) + Number(values.amount) * 0.18,
      currency: userData.currency_code,
    };
    const response = await dispatch(
      addOrderIdService(requestBody, tokenData, "add", navigate)
    );
    if (response.orderId && response.orderId !== "") {
      setRazorModal(!razorModal);
      handleRazorpayPayment(
        response,
        values.amount,
        values.mobile,
        values.type
      );
    } else {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {walletActionData && walletActionData.length > 0
                    ? getRouteNameFromSidebar(
                        walletActionData[0].name,
                        localisation
                      )
                    : t("Wallet")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {walletActionData && walletActionData.length > 0
                    ? getRouteNameFromSidebar(
                        walletActionData[0].name,
                        localisation
                      )
                    : t("Wallet")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(walletFilter.startDate !== "" &&
                walletFilter.startDate !== null &&
                new Date(
                  formatDateToYYYYMMDD(walletFilter.startDate)
                ).toDateString() !==
                  new Date(
                    new Date().setMonth(new Date().getMonth() - 1)
                  ).toDateString()) ||
              (walletFilter.endDate !== "" &&
                walletFilter.endDate !== null &&
                new Date(
                  formatDateToYYYYMMDD(walletFilter.endDate)
                ).toDateString() !== new Date().toDateString()) ? (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main filter-btn"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="wallet" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              <Button
                data-bs-toggle="tooltip"
                title="Add Balance In Wallet
                  "
                onClick={() => setRazorModal(!razorModal)}
                className="btn btn-fillter-main razor-btn"
                name={
                  <div className="Plus ">
                    <GiWallet />
                    {t("add_balance")}
                  </div>
                }
              ></Button>
            </div>
          </div>
          {/* {!isToggled && <Filter page="wallet"></Filter>} */}
          <div
            className="wallet-amt"
            data-bs-toggle="tooltip"
            title="Total Balance"
          >
            <p>
              {t("total_balance")} : ₹{Number(totalAmt)}
            </p>
          </div>
          {walletData &&
            walletData.items[0]?.data &&
            walletData.items[0].data && (
              <>
                <CustomTable
                  page="wallet"
                  showToggle={false}
                  checkBool={false}
                  showKey={showKey}
                  // showActions={showActions}
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  styleData={styleData}
                  disabled={
                    walletActionData &&
                    walletActionData[0]?.edit &&
                    walletActionData[0].edit === "0"
                  }
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />

                {walletData.items[0].total_records >
                  walletData.items[0].data.length &&
                  walletData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={walletData.items[0].total_records}
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
        {razorModal && (
          <div className="modal-overlay">
            <div className="modal-content-order">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="add-roles-form row">
                      <div>
                        <div>
                          <h4>{t("add_balance_title")}</h4>
                          <button
                            data-bs-toggle="tooltip"
                            title="Close"
                            className="modal-close"
                            onClick={() => setRazorModal(!razorModal)}
                          >
                            <MdOutlineCancel />
                          </button>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    {t("lable_amount")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="amount"
                                  // label="Mobile No"
                                  placeholder={t("placeholder_enter_Amount")}
                                  className="add-roles"
                                  value={values.amount}
                                  Regex={/[^0-9\s]/g}
                                  // disabled={Data}
                                  maxLength={10}
                                  hidelength={"true"}
                                />
                                <span className="amt-note">
                                  {t("add_wallet_tax_massage")}
                                </span>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="paragraph-editor">
                                <div className="btn-submit-1 mt-3">
                                  <Button
                                    type="submit"
                                    data-bs-toggle="tooltip"
                                    title="Add Balance"
                                    name={t("button_add_balance")}
                                    className="btn razor-btn "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wallet;

import { CLEAR_PURCHASE_ORDER_DATA, CLEAR_TOTAL_PURCHASE_ORDER_DATA, GET_PURCHASE_ORDER_DATA, GET_TOTAL_PURCHASE_ORDER_DATA } from "../../ReduxKeys";

/**
 * Setting Purchase Order list data into reducer
 * @param {*} purchaseOrder
 * @returns {*}
 */
export const getPurchaseOrderData = (purchaseOrder) => ({
    type: GET_PURCHASE_ORDER_DATA,
    payload: purchaseOrder,
  });
  
  /**
   * clearing Purchase Order list data
   * @returns {*}
   */
  export const clearPurchaseOrderData = () => ({
    type: CLEAR_PURCHASE_ORDER_DATA,
  });
  
  /**
   * Setting total Purchase Order list data into reducer (No pagnation)
   * @param {*} purchaseOrder
   * @returns {*}
   */
  export const getTotalPurchaseOrderData = (purchaseOrder) => ({
    type: GET_TOTAL_PURCHASE_ORDER_DATA,
    payload: purchaseOrder,
  });
  
  /**
   * Clearing total Purchase Order list data (No pagination)
   * @returns {*}
   */
  export const clearTotalPurchaseOrderData = () => ({
    type: CLEAR_TOTAL_PURCHASE_ORDER_DATA,
  });
  
import {
  CLEAR_NEW_VENDOR_DATA,
  CLEAR_TOTAL_NEW_VENDOR_DATA,
  GET_NEW_VENDOR_DATA,
  GET_TOTAL_NEW_VENDOR_DATA,
} from "../../ReduxKeys";

/**
 * Setting new vendor list data into reducer
 * @param {*} newVendor
 * @returns {*}
 */
export const getNewVendorData = (newVendor) => ({
  type: GET_NEW_VENDOR_DATA,
  payload: newVendor,
});

/**
 * clearing new vendor list data
 * @returns {*}
 */
export const clearNewVendorData = () => ({
  type: CLEAR_NEW_VENDOR_DATA,
});

/**
 * Setting total new vendor list data into reducer (No pagnation)
 * @param {*} newVendor
 * @returns {*}
 */
export const getTotalNewVendorData = (newVendor) => ({
  type: GET_TOTAL_NEW_VENDOR_DATA,
  payload: newVendor,
});

/**
 * Clearing total New Vendor list data (No pagination)
 * @returns {*}
 */
export const clearTotalNewVendorData = () => ({
  type: CLEAR_TOTAL_NEW_VENDOR_DATA,
});
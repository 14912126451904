import { orderDate } from "../../../services/Regular-services/DateService";
import {
  CLEAR_STOCK_HISTORY_FILTER,
  GET_STOCK_HISTORY_FILTER,
} from "../../ReduxKeys";

const StockHistoryFilterInitialState = {
  ingredientId: "",
  orderDate: orderDate(),
  // isActive: '',
  // isDelete: '',
};

/**
 * Store stock history filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const StockHistoryFilterReducer = (
  state = StockHistoryFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_STOCK_HISTORY_FILTER:
      return {
        ...state,
        ingredientId: action.payload.ingredientId,
        orderDate: action.payload.orderDate,
        // isActive: action.payload.isActive,
        // isDelete: action.payload.isDelete,
      };
    case CLEAR_STOCK_HISTORY_FILTER:
      return StockHistoryFilterInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_GRN_DATA,
  CLEAR_TOTAL_GRN,
  GET_GRN_DATA,
  GET_TOTAL_GRN,
} from "../../ReduxKeys";

/**
 * Setting GRN list data into reducer
 * @param {*} GRN
 * @returns {*}
 */
export const getGRNData = (GRN) => ({
  type: GET_GRN_DATA,
  payload: GRN,
});

/**
 * clearing GRN list data
 * @returns {*}
 */
export const clearGRNData = () => ({
  type: CLEAR_GRN_DATA,
});

/**
 * Setting total GRN list data into reducer (No pagnation)
 * @param {*} GRN
 * @returns {*}
 */
export const getTotalGRNData = (GRN) => ({
  type: GET_TOTAL_GRN,
  payload: GRN,
});

/**
 * Clearing total GRN list data (No pagination)
 * @returns {*}
 */
export const clearTotalGRNData = () => ({
  type: CLEAR_TOTAL_GRN,
});
import {
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_SETTLEMENT_REPORT_FILTER,
  GET_SETTLEMENT_REPORT_FILTER,
} from "../../ReduxKeys";

const settlementGraphFilterInitialState = {
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
};

/**
 * Store settlement graph filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SettlementGraphFilterReducer = (
  state = settlementGraphFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_SETTLEMENT_REPORT_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case CLEAR_SETTLEMENT_REPORT_FILTER:
      return settlementGraphFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EventLog.scss";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import {
  clearEventLogPageData,
  getEventLogPageData,
} from "../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogFilterData } from "../../redux/Actions/EventLogAction/EventLogFilterAction";
import { clearEventLogData } from "../../redux/Actions/EventLogAction/EventLogAction";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Button from "../../components/Button/Button";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import EventLogService from "./EventLogService/EventLogService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EventLog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getEventLogDataService } = EventLogService();
  const eventLog = useSelector((state) => state.eventLog);
  const eventLogFilter = useSelector((state) => state.eventLogFilter);
  const eventLogPage = useSelector((state) => state.eventLogPage.items);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentEventLogPage, setCurrentEventPage] = useState(1);
  const [showEventLog, setEventLog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const [eventLogActionData, setEventLogData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);

  const showKey = {
    module: true,
    action: true,
    date: true,
    logMsg: true,
  };
  const [columns, setColumns] = useState([
    {
      name: t("columns.module"),
      selector: (row) => row.module,
    },
    {
      name: t("columns.actions"),
      selector: (row) => row.action,
    },
    {
      name: t("lable_date"),
      selector: (row) => row.date,
    },
    {
      name: t("columns.message"),
      selector: (row) => row.logMsg,
    },
  ]);

  const [styleData, setStyleData] = useState({
    module: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },
    action: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },
    date: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },
    logMsg: {
      // type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
    },
    [t("columns.module")]: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },

    [t("columns.actions")]: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },
    [t("lable_date")]: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
      },
    },

    [t("columns.message")]: {
      // type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
    },
  });

  useEffect(() => {
    setColumns([
      {
        name: t("columns.module"),
        selector: (row) => row.module,
      },
      {
        name: t("columns.actions"),
        selector: (row) => row.action,
      },
      {
        name: t("lable_date"),
        selector: (row) => row.date,
      },
      {
        name: t("columns.message"),
        selector: (row) => row.logMsg,
      },
    ]);
    setStyleData({
      module: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },
      action: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },
      date: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },
      logMsg: {
        // type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
      },
      [t("columns.module")]: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },

      [t("columns.actions")]: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },
      [t("lable_date")]: {
        type: {
          minWidth: "100px",
          width: "100px",
          maxWidth: "100px",
        },
      },

      [t("columns.message")]: {
        // type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
      },
    });
    // eslint-disable-next-line
  }, [localisation]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "event-logs"
      );
      setEventLogData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (eventLog && eventLog.items && eventLog.items.length === 0) {
      fetchEventLog();
      setTableData([]);
    } else {
      if (
        eventLog &&
        eventLog.items[0] &&
        eventLog.items[0]?.data &&
        eventLog.items[0].data.length > 0
      ) {

        const data = eventLog.items[0].data.map((item) => {
          // Format the date
          const formattedDate = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date(item.created_at));

          return {
            module: item.module,
            action: item.action,
            date: formattedDate,
            logMsg: item.message,
          };
        });

        setTableData(data);
      } else {
        setTableData([]);
      }
    }
    // eslint-disable-next-line
  }, [eventLog, eventLogFilter]);

  const fetchEventLog = () => {
    if (eventLogPage > 1) {
      const requestBody = {
        p_skip: (eventLogPage - 2) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(eventLogFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(eventLogFilter.endDate),
      };
      if (eventLogFilter.module !== "" && eventLogFilter.module !== null) {
        requestBody.p_module = eventLogFilter.module;
      }
      if (eventLogFilter.Action !== "" && eventLogFilter.Action !== null) {
        requestBody.p_action = eventLogFilter.Action;
      }
      if (eventLogFilter.user !== "" && eventLogFilter.user !== null) {
        requestBody.p_user_id = eventLogFilter.user;
      }
      setCurrentEventPage(eventLogPage - 1);
      dispatch(getEventLogPageData(eventLogPage - 1));
      getEventLogDataService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (eventLogPage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(eventLogFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(eventLogFilter.endDate),
      };
      if (eventLogFilter.module !== "" && eventLogFilter.module !== null) {
        requestBody.p_module = eventLogFilter.module;
      }
      if (eventLogFilter.Action !== "" && eventLogFilter.Action !== null) {
        requestBody.p_action = eventLogFilter.Action;
      }
      if (eventLogFilter.user !== "" && eventLogFilter.user !== null) {
        requestBody.p_user_id = eventLogFilter.user;
      }
      getEventLogDataService(requestBody, tokenData, dispatch);
    }
  };

  const handleEventLogClose = () => setEventLog(false);
  const handleEventLogShow = () => setEventLog(true);

  const onPageChange = (pageNumber) => {
    const requestData = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_start_date: formatDateToYYYYMMDD(eventLogFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(eventLogFilter.endDate),
    };
    if (eventLogFilter.module !== "" && eventLogFilter.module !== null) {
      requestData.p_module = eventLogFilter.module;
    }
    if (eventLogFilter.Action !== "" && eventLogFilter.Action !== null) {
      requestData.p_action = eventLogFilter.Action;
    }
    if (eventLogFilter.user !== "" && eventLogFilter.user !== null) {
      requestData.p_user_id = eventLogFilter.user;
    }
    if (pageNumber !== currentEventLogPage) {
      getEventLogDataService(requestData, tokenData, dispatch);
    }
    setCurrentEventPage(pageNumber);
    dispatch(getEventLogPageData(pageNumber));
  };

  const clearFilters = () => {
    dispatch(clearEventLogFilterData());
    // setIsToggled(false);
    dispatch(clearEventLogData());
    dispatch(clearEventLogPageData());
  };

  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {eventLogActionData && eventLogActionData.length > 0
                ? getRouteNameFromSidebar(
                    eventLogActionData[0].name,
                    localisation
                  )
                : t("Event Log")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {eventLogActionData && eventLogActionData.length > 0
                ? getRouteNameFromSidebar(
                    eventLogActionData[0].name,
                    localisation
                  )
                : t("Event Log")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups">
          {((eventLogFilter.startDate !== "" &&
            eventLogFilter.startDate !== null &&
            new Date(
              formatDateToYYYYMMDD(eventLogFilter.startDate)
            ).toDateString() !==
              new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toDateString()) ||
            (eventLogFilter.endDate !== "" &&
              eventLogFilter.endDate !== null &&
              new Date(
                formatDateToYYYYMMDD(eventLogFilter.endDate)
              ).toDateString() !== new Date().toDateString()) ||
            (eventLogFilter.module !== "" && eventLogFilter.module !== null) ||
            (eventLogFilter.Action !== "" && eventLogFilter.Action !== null) ||
            (eventLogFilter.user !== "" && eventLogFilter.user !== null)) && (
            <Dropdown className="new-header-dropdown action-btn">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                data-bs-toggle="tooltip"
                title="Action"
              >
                <GrMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu className="event-clear-filter">
                <Dropdown.Item onClick={clearFilters}>
                  {t("clear_filters")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={handleEventLogShow}
            data-bs-toggle="tooltip"
            title="Filter"
            // onClick={handleButtonClick}
            className="btn btn-fillter-main crm-filter"
            name={
              <div className="filter">
                <FaFilter className="filter-icon" />
              </div>
            }
          ></Button>
        </div>

        <Offcanvas
          show={showEventLog}
          onHide={handleEventLogClose}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter page="eventLog" handleClose={handleEventLogClose}></Filter>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {eventLog && eventLog.items[0]?.data && eventLog.items[0].data && (
        <>
          <CustomTable
            page="eventLog"
            showKey={showKey}
            showActions={false}
            showTooltip={true}
            data={tableData}
            heading={columns}
            checkBool={false}
            styleData={styleData}
          />
          {eventLog.items[0]?.total_records > 0 &&
            eventLog.items[0].total_records > eventLog.items[0].data.length &&
            eventLog.items[0].total_records > 10 && (
              <CustomPagination
                totalRecords={eventLog.items[0].total_records}
                currentPage={currentEventLogPage}
                onPageChange={onPageChange}
              />
            )}
        </>
      )}
    </div>
  );
};

export default EventLog;

import { Dispatch } from "redux";
import { addUpdateRemainigStockRequest } from "./AddRemainingStockModal";
import {
  clearLoader,
  setLoader,
} from "../../../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearStockData,
  clearTotalStockData,
} from "../../../../../redux/Actions/StocksAction/StockAction";
import {
  clearStockPageData,
  getStockPageData,
} from "../../../../../redux/Actions/StocksAction/StockPageAction";
import {
  clearStockHistoryData,
  clearTotalStockHistoryData,
} from "../../../../../redux/Actions/StockHistoryAction/StockHistoryAction";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

let errorShow = false;

export default function AddRemainingStockServices() {
  const { t } = useTranslation() as any;
  const localisation = useSelector((state: any) => state.localization?.items);
  /*
   * Service for adding and updating Vendor
   */
  const addUpdateRemainingStockService = (
    addUpdateRemainigStockRequest: addUpdateRemainigStockRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.STOCKS,
            token,
            addUpdateRemainigStockRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearStockData());
          dispatch(clearTotalStockData());
          dispatch(clearStockHistoryData());
          dispatch(clearTotalStockHistoryData());
          if (type === "add") {
            dispatch(clearStockPageData());
            toast.success(
              response?.message ? response.message : "Stock added successfully!"
            );
            navigate("/stock");
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getStockPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return { addUpdateRemainingStockService };
}

import { CLEAR_UNIT_FILTER, GET_UNIT_FILTER } from "../../ReduxKeys";

/**
 * Setting units filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getUnitsFilterData = ({ unitId, isActive }) => ({
  type: GET_UNIT_FILTER,
  payload: { unitId, isActive },
});

/**
 * Clearing units filtered data
 * @returns {*}
 */
export const clearUnitsFilterData = () => ({
  type: CLEAR_UNIT_FILTER,
});
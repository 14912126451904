import React, { useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import Filter from '../../components/Filter/Filter';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { GrMoreVertical } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';

const columns = [
    {
        name: 'Title',
        selector: row => row.Title,
    },
    {
        name: 'URL',
        selector: row => row.URL,
    },
    {
        name: 'Description',
        selector: row => row.Description,
    },
    {
        name: 'Category',
        selector: row => row.Category,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    }
];

const data = [
    {
        Title: 'Title',
        URL: 'lorem.com',
        Description: 'Lorem Ipsum',
        Category: "ABC"
    },
]

//Show actions which is to be displayed in table
const showActions = {
    showEdit: true,
    showDelete: true,
    navigate: '/add-tv'
}

//Show keys which is to be displayed in table
const showKey = {
    Title: true,
    URL: true,
    Description: true,
    Category: true
}

export default function Tv() {
    const navigate = useNavigate();

    const [isToggled, setIsToggled] = useState(false);

    const handleButtonClick = () => {
        setIsToggled((prevIsToggled) => !prevIsToggled);
    };

    return (
        <>
            <div >
                {/* <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='body-content'>
                    <Header /> */}
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Tv</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Tv</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className='btn-groups'>
                                <Dropdown className='header-dropdown action-btn' >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                                        <GrMoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >Delete</Dropdown.Item>
                                        <Dropdown.Item >Active</Dropdown.Item>
                                        <Dropdown.Item >Inactive</Dropdown.Item>
                                        <Dropdown.Item >Undo</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                                    <FaFilter className="filter-icon" />
                                </div>}></Button>
                                <Button data-bs-toggle='tooltip' title='Export' className="btn btn-fillter-main" name={<div className="filter">
                                    <SiMicrosoftexcel className="filter-icon" />
                                </div>}></Button>
                                <Button onClick={() => navigate('/add-tv')} className="btn btn-fillter-main" name={<div className="Plus">
                                    <FaPlus />
                                </div>}></Button>
                            </div>
                        </div>
                        {isToggled &&
                            <Filter></Filter>}
                        <CustomTable showKey={showKey} showActions={showActions} showTooltip={true} showId={true} data={data} heading={columns} />
                    </div>
                    {/* <Footer />
                </div> */}
            </div> <div >
                {/* <div className='sidebar-content'>
                    <Sidebar />
                </div> */}
                <div className='body-content'>
                    <Header />
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Tv</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Tv</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className='btn-groups'>
                                <Dropdown className='header-dropdown action-btn' >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                                        <GrMoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >Delete</Dropdown.Item>
                                        <Dropdown.Item >Active</Dropdown.Item>
                                        <Dropdown.Item >Inactive</Dropdown.Item>
                                        <Dropdown.Item >Undo</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                                    <FaFilter className="filter-icon" />
                                </div>}></Button>
                                <Button data-bs-toggle='tooltip' title='Export' className="btn btn-fillter-main" name={<div className="filter">
                                    <SiMicrosoftexcel className="filter-icon" />
                                </div>}></Button>
                                <Button onClick={() => navigate('/add-tv')} className="btn btn-fillter-main" name={<div className="Plus">
                                    <FaPlus />
                                </div>}></Button>
                            </div>
                        </div>
                        {isToggled &&
                            <Filter></Filter>}
                        <CustomTable showKey={showKey} showActions={showActions} showTooltip={true} showId={true} data={data} heading={columns} />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

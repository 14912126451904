import { CLEAR_BRAND_PAGE, GET_BRAND_PAGE } from "../../ReduxKeys";

const brandPageInitialState = {
  items: 1,
};

/**
 * Store brand page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const brandPageReducer = (state = brandPageInitialState, action) => {
  switch (action.type) {
    case GET_BRAND_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_BRAND_PAGE:
      return brandPageInitialState;
    default:
      return state;
  }
};
import { CLEAR_LOCALIZATION, SET_LOCALIZATION } from "../../ReduxKeys";

const localizationInitialState = {
  items: "en-US",
};

/**
 * Store localization data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const LocalizationReducer = (
  state = localizationInitialState,
  action
) => {
  switch (action.type) {
    case SET_LOCALIZATION:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_LOCALIZATION:
      return localizationInitialState;
    default:
      return state;
  }
};
import { CLEAR_NEW_VENDOR_PAGE, GET_NEW_VENDOR_PAGE } from "../../ReduxKeys";

/**
 * Set new vendor page data
 * @param {*} page
 * @returns {*}
 */
export const getNewVendorPageData = (page) => ({
  type: GET_NEW_VENDOR_PAGE,
  payload: page,
});

/**
 * Clear new vendor page data
 * @returns {*}
 */
export const clearNewVendorPageData = () => ({
  type: CLEAR_NEW_VENDOR_PAGE,
});
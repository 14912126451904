import { CLEAR_SERVICE_FILTER, GET_SERVICE_FILTER } from "../../ReduxKeys";

/**
 * Set service filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getServiceFilterData = ({
  name,
  category,
  subCategory,
  tag,
  branch,
  brand,
  isActive,
  isDelete,
}) => ({
  type: GET_SERVICE_FILTER,
  payload: {
    name,
    category,
    subCategory,
    tag,
    branch,
    brand,
    isActive,
    isDelete,
  },
});

/**
 * Clear service filtered data
 * @returns {*}
 */
export const clearServiceFilterData = () => ({
  type: CLEAR_SERVICE_FILTER,
});
import { Dispatch } from "redux";
import {
  addUpdateSubCategoryRequest,
  getAllSubCategoryRequest,
  getAllSubCategoryResponse,
  getSubCategoryByIdResponse,
} from "./SubCategoryModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearSubCategoryData,
  clearTotalSubCategoryData,
  getSubCategoryData,
  getTotalSubCategoryData,
} from "../../../redux/Actions/subCategoryAction/SubCategoryAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearSubCategoryPageData,
  getSubCategoryPageData,
} from "../../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function SubCategoryServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get sub category list
   */
  const getAllSubCategoryService = (
    subCategoryListRequest: getAllSubCategoryRequest,
    token: string,
    dispatch: Dispatch,
    total?: any,
    loaderData?: any
  ): Promise<getAllSubCategoryResponse> => {
    return new Promise<getAllSubCategoryResponse>(async (resolve, reject) => {
      try {
        if (!loaderData) {
          dispatch(setLoader());
        }
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          subCategoryListRequest.p_skip !== undefined &&
          subCategoryListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${subCategoryListRequest.p_skip}&`;
        }
        if (
          subCategoryListRequest.p_take !== undefined &&
          subCategoryListRequest.p_take !== null
        ) {
          queryParams += `p_take=${subCategoryListRequest.p_take}&`;
        }
        if (
          subCategoryListRequest.p_name !== undefined &&
          subCategoryListRequest.p_name !== null
        ) {
          queryParams += `p_name=${subCategoryListRequest.p_name}&`;
        }
        if (
          subCategoryListRequest.p_categoryIds !== undefined &&
          subCategoryListRequest.p_categoryIds !== null
        ) {
          queryParams += `p_categoryIds=${subCategoryListRequest.p_categoryIds}&`;
        }
        if (
          subCategoryListRequest.p_category_code !== undefined &&
          subCategoryListRequest.p_category_code !== null
        ) {
          queryParams += `p_category_code=${subCategoryListRequest.p_category_code}&`;
        }
        if (
          subCategoryListRequest.p_type !== undefined &&
          subCategoryListRequest.p_type !== null
        ) {
          queryParams += `p_type=${subCategoryListRequest.p_type}&`;
        }
        if (
          subCategoryListRequest.p_is_active !== undefined &&
          subCategoryListRequest.p_is_active !== null
        ) {
          queryParams += `p_is_active=${subCategoryListRequest.p_is_active}&`;
        }
        if (
          subCategoryListRequest.p_isDeleted !== undefined &&
          subCategoryListRequest.p_isDeleted !== null
        ) {
          queryParams += `p_isDeleted=${subCategoryListRequest.p_isDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.SUB_CATEGORY +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalSubCategoryData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getSubCategoryData(response));
          } else {
            dispatch(getSubCategoryData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating sub category
   */
  const addUpdateSubCategoryService = (
    addUpdateSubCategoryRequest: addUpdateSubCategoryRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.SUB_CATEGORY,
            token,
            addUpdateSubCategoryRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.SUB_CATEGORY,
            token,
            addUpdateSubCategoryRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearSubCategoryData());
          dispatch(clearTotalSubCategoryData());
          if (type === "add") {
            dispatch(clearSubCategoryPageData());
            toast.success(
              response?.message
                ? response.message
                : "Sub category added successfully!"
            );
            navigate("/sub-category");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Sub category undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getSubCategoryPageData(pageNumber + 1));
                } else {
                  dispatch(clearSubCategoryPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Sub category updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/sub-category");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Sub category status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getSubCategoryPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting sub category
   */
  const deleteSubCategoryService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.SUB_CATEGORY + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearSubCategoryData());
          dispatch(clearTotalSubCategoryData());
          toast.success(
            response?.message
              ? response.message
              : "Sub Category deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getSubCategoryPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual sub category
   */
  const getSubCategoryById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getSubCategoryByIdResponse> => {
    return new Promise<getSubCategoryByIdResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.SUB_CATEGORY + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for uploading excel
   */
  const uploadSubCategoryExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append("excel", excelRequest);

      try {
        dispatch(setLoader());
        const response = await fetch(
          API_URL.BASE_URL + API_URL.SUB_CATEGORY_UPLOAD,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Language": localisation ? localisation : "en",
              "accept-language": localisation ? localisation : "en",
            },
            body: formData,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          if (data?.message && data.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          return data;
        } else {
          const data = await response.json();
          toast.success(
            data?.message ? data.message : "Sub category imported successfully"
          );
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllSubCategoryService,
    addUpdateSubCategoryService,
    deleteSubCategoryService,
    getSubCategoryById,
    uploadSubCategoryExcelService,
  };
}
import { FloatingLabel, Form } from "react-bootstrap";
import "./Input.scss";
import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import ErrorComp from "../Error/ErrorComp";
import { BiShow, BiSolidHide } from "react-icons/bi";
import { toast } from "react-toastify";

export default function Input(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);

  let {
    type,
    name,
    label,
    placeholder,
    isRequired,
    isPassword,
    id,
    maxLength,
    lengthHide,
    Regex,
    changeInputData,
    inputRef,
    note,
    ...rest
  } = props;

  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (props.value && props.value.toString().length > 0) {
      setCharacterCount(props.value.toString().length);
    }
  }, [props.value]);

  /**
   * Toggle input type
   */
  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  /**
   * Handle input change
   * @param {*} event
   * @param {*} field
   */
  const handleInputChange = (event, field) => {
    const inputValue = event.target.value;
    if (maxLength) {
      if (inputValue.length <= maxLength) {
        if (Regex) {
          if (props.handletimechange === "true") {
            const newValue = /^(?:0?|[1-9]|1[0-9]|2[0-4])$/.test(
              event.target.value
            );
            if (newValue) {
              field.onChange({
                target: { name: field.name, value: event.target.value },
              });
            } else {
              if (!document.querySelector(".Toastify__toast-body")) {
                toast.warn("Please enter an integer value between 0 and 24");
              }
            }
          } else {
            const newValue = event.target.value.replace(Regex, "");
            const trimmedValue = newValue.slice(0, maxLength);
            setCharacterCount(trimmedValue.length);
            field.onChange({
              target: { name: field.name, value: trimmedValue },
            });
          }
        } else {
          setCharacterCount(inputValue.length);
          field.onChange(event);
        }
      } else {
        // Truncate the input value to the specified limit
        const truncatedValue = inputValue.substring(0, maxLength);
        setCharacterCount(maxLength);
        field.onChange({ target: { name: field.name, value: truncatedValue } });
      }
    } else {
      if (Regex) {
        if (props.handletimechange === "true") {
          const newValue = /^(?:0?|[1-9]|1[0-9]|2[0-4])$/.test(
            event.target.value
          );
          if (newValue) {
            field.onChange({
              target: { name: field.name, value: event.target.value },
            });
          } else {
            if (!document.querySelector(".Toastify__toast-body")) {
              toast.warn("Please enter an integer value between 0 and 24");
            }
          }
        } else {
          const newValue = event.target.value.replace(Regex, "");
          setCharacterCount(newValue.length);
          field.onChange({ target: { name: field.name, value: newValue } });
        }
      } else {
        setCharacterCount(inputValue.length);
        field.onChange(event);
      }
    }
  };

  return (
    <>
      <FloatingLabel
        label={
          <span className={isRequired ? "required-label" : ""}>{label}</span>
        }
        className="mb-2 show-and-hide-btn"
      >
        <Field name={name}>
          {({ field }) => (
            <>
              <div className="input-container">
                {isPassword && (
                  <span className="hide-btn" onClick={togglePasswordVisibility}>
                    {showPassword ? <BiShow /> : <BiSolidHide />}
                  </span>
                )}
                <Form.Control
                  {...field}
                  ref={inputRef}
                  type={type ? inputType : "text"}
                  placeholder={placeholder}
                  {...rest}
                  id={name}
                  onChange={(e) => {
                    handleInputChange(e, field);
                    // Call changeInputData if it's provided
                    if (props.changeInputData) {
                      if (!Regex) {
                        props.changeInputData(e);
                      } else {
                        props.changeInputData(e, Regex);
                      }
                    }
                  }}
                />
                {maxLength && !props.hidelength && (
                  <div className="character-count">
                    {characterCount} / {maxLength}
                  </div>
                )}
              </div>
            </>
          )}
        </Field>
      </FloatingLabel>
      <ErrorMessage name={name} component={ErrorComp}></ErrorMessage>
      {note ? <p className="note">{note}</p> : <></>}
    </>
  );
}
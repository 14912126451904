import {
  CLEAR_CUSTOMER_ANALYTICS,
  GET_CUSTOMER_ANALYTICS,
} from "../../ReduxKeys";

const customerAnalyticsInitialState = {
  items: [],
};

/**
 * Store customer analytics data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerAnalyticsReducer = (
  state = customerAnalyticsInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ANALYTICS:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_CUSTOMER_ANALYTICS:
      return customerAnalyticsInitialState;
    default:
      return state;
  }
};
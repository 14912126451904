import {
  CLEAR_CUSTOMER_ANALYTICS_FILTER,
  GET_CUSTOMER_ANALYTICS_FILTER,
} from "../../ReduxKeys";

/**
 * set customer analytics filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getCustomerAnalyticsFilterData = ({
  StartDate,
  EndDate,
  branchId,
}) => ({
  type: GET_CUSTOMER_ANALYTICS_FILTER,
  payload: {
    StartDate,
    EndDate,
    branchId,
  },
});

/**
 * clear customer analytics filtered data
 * @returns {*}
 */
export const clearCustomerAnalyticsFilterData = () => ({
  type: CLEAR_CUSTOMER_ANALYTICS_FILTER,
});
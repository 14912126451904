import {
  CLEAR_DISCOUNT_CATALOGUE_FILTER,
  GET_DISCOUNT_CATALOGUE_FILTER,
} from "../../ReduxKeys";

const discountCatalogueFilterInitialState = {
  name: "",
  start_date: "",
  end_date: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const discountCatalogueFilterReducer = (
  state = discountCatalogueFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_DISCOUNT_CATALOGUE_FILTER:
      return {
        ...state,
        name: action.payload.name,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_DISCOUNT_CATALOGUE_FILTER:
      return discountCatalogueFilterInitialState;
    default:
      return state;
  }
};
export const RoleToggleRequest = ({
  roleDetail,
  userId,
  isActive,
  isDelete,
}) => {
  const rolePermisionData = roleDetail.data.permissions.reduce(
    (acc, permission) => {
      // Extract id and value for each permission type
      const permissionTypes = ["allow", "add", "view", "edit", "delete"];
      const permissionData = permissionTypes.reduce((data, type) => {
        if (
          permission[type] === 1 ||
          permission[type] === true ||
          permission[type] === "1"
        ) {
          const id = parseInt(permission[type + "_id"]);
          data.push({
            id: id,
            value:
              permission[type] === true ||
              permission[type] === 1 ||
              permission[type] === "1"
                ? "1"
                : "1",
          });
        }
        return data;
      }, []);
      acc.push(...permissionData);
      return acc;
    },
    []
  );
  const requestBody = {
    id: roleDetail.data.roleDetails[0].id,
    title: roleDetail.data.roleDetails[0].title,
    description: roleDetail.data.roleDetails[0].description,
    p_isActive: isActive
      ? roleDetail.data.roleDetails[0].is_active === "1"
        ? "0"
        : "1"
      : roleDetail.data.roleDetails[0].is_active,
    p_IsDeleted: isDelete ? "0" : roleDetail.data.roleDetails[0].IsDeleted,
    createdBy: roleDetail.data.roleDetails[0].created_by,
    modifiedBy: userId,
    permissionData: rolePermisionData,
  };
  return requestBody;
};
import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa6";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown, Offcanvas } from "react-bootstrap";
import "../Spot/Spots.scss";
import Toggle from "../../components/Toggle/Toggle";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import {
  clearSpotPageData,
  getSpotPageData,
} from "../../redux/Actions/spotAction/SpotPageAction";
import { clearSpotFilterData } from "../../redux/Actions/spotAction/SpotFilterAction";
import { clearSpotData } from "../../redux/Actions/spotAction/SpotAction";
import CustomPagination from "../../components/Pagination/Pagination";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import QRCodeComponent from "../../pages/QRCode/QRCodeComponent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SpotServices from "./SpotService/SpotService";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  price: true,
  size: true,
  // branch_id:true
};

const showToggle = {
  status: true,
};

const Spots = () => {
  const { getAllSpotService } = SpotServices();
  const { t } = useTranslation();
  const spotData = useSelector((state) => state.spot);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const spotPage = useSelector((state) => state.spotPage?.items);
  const spotFilter = useSelector((state) => state.spotFilter);
  const localization = useSelector((state) => state.localization?.items);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [spotActionData, setSpotActionData] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setSpotActionData(actionData);
    }
  }, [sideBarData]);

  const user = useSelector((state) => state.auth?.user);
  const [brandData, setBrandData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const navigate = useNavigate();
  const [showActionBool, setShowActionBool] = useState(false);
  const localisation = useSelector((state) => state.localization?.items);

  const [columns, setColumns] = useState([
    {
      name: t("columns.name"),
      selector: (row) => row.p_name,
    },
    {
      name: t("columns.price"),
      selector: (row) => row.p_price,
    },
    {
      name: t("columns.size"),
      selector: (row) => row.p_size,
    },
    {
      name: t("columns.status"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: t("columns.actions"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);

  useEffect(() => {
    if (
      spotActionData[0]?.view &&
      spotActionData[0].view === "0" &&
      spotActionData[0]?.edit &&
      spotActionData[0].edit === "0" &&
      spotActionData[0]?.add &&
      spotActionData[0].add === "0" &&
      spotActionData[0]?.delete &&
      spotActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.p_name,
        },
        {
          name: t("columns.price"),
          selector: (row) => row.p_price,
        },
        {
          name: t("columns.size"),
          selector: (row) => row.p_size,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.p_name,
        },
        {
          name: t("columns.price"),
          selector: (row) => row.p_price,
        },
        {
          name: t("columns.size"),
          selector: (row) => row.p_size,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: t("columns.actions"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [spotActionData, localization]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (spotActionData[0]?.view && spotActionData[0].view === "1") ||
      !spotActionData
        ? true
        : false,
    showEdit:
      (spotActionData[0]?.edit && spotActionData[0].edit === "1") ||
      !spotActionData
        ? true
        : false,
    showDelete:
      (spotActionData[0]?.delete && spotActionData[0].delete === "1") ||
      !spotActionData
        ? true
        : false,
    showUndo:
      (spotActionData[0]?.edit && spotActionData[0].edit === "1") ||
      !spotActionData
        ? true
        : false,
    navigate:
      (spotActionData[0]?.edit && spotActionData[0].edit === "1") ||
      !spotActionData
        ? "/addspots"
        : false,
    showPrint: true,
  };

  useEffect(() => {
    setCurrentPage(spotPage);
  }, [spotPage]);

  /**
   * Fetch spot data
   */
  const fetchSpot = () => {
    const requestBody = {
      p_skip: spotPage > 1 ? (spotPage - 2) * 10 : (spotPage - 1) * 10,
      p_take: 10,
    };
    if (spotFilter.p_name !== "" && spotFilter.p_name !== null) {
      requestBody.p_name = spotFilter.p_name;
    }
    if (
      spotFilter.p_is_available !== "" &&
      spotFilter.p_is_available !== null
    ) {
      requestBody.p_is_available = spotFilter.p_is_available;
    }
    if (spotFilter.p_is_active !== "" && spotFilter.p_is_active !== null) {
      requestBody.p_is_active = spotFilter.p_is_active;
    } else {
      requestBody.p_is_active = "1";
    }
    if (spotFilter.p_is_deleted !== "" && spotFilter.p_is_deleted !== null) {
      requestBody.p_is_deleted = spotFilter.p_is_deleted;
    }

    getAllSpotService(requestBody, tokenData, dispatch);
    if (spotPage > 1) {
      setCurrentPage(spotPage - 1); // Update the current page state
      dispatch(getSpotPageData(spotPage - 1));
    }
  };

  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (
        spotFilter?.p_name !== "" ||
        spotFilter?.p_is_available !== "" ||
        spotFilter?.p_is_active !== "" ||
        spotFilter?.p_is_deleted !== ""
      ) {
        // handleShow();
      }
    }

    if (spotData.items[0]?.data && spotData.items[0].data.length > 0) {
      const transformedSpotData = spotData.items[0].data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          price: item.price,
          size: item.size,
          is_active: item.is_active,
          is_available: item.is_available,
          is_delete: item.is_deleted,
          branch_id: item.branch_id,
        };
      });

      setTableData(transformedSpotData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [spotData, spotFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (spotFilter.p_name !== "" && spotFilter.p_name !== null) {
        requestBody.name = spotFilter.p_name;
      }
      if (spotFilter.p_is_active !== "" && spotFilter.p_is_active !== null) {
        requestBody.p_is_active = spotFilter.p_is_active;
      } else {
        requestBody.p_is_active = "1";
      }
      if (spotFilter.p_is_deleted !== "" && spotFilter.p_is_deleted !== null) {
        requestBody.p_is_deleted = spotFilter.p_is_deleted;
      }
      if (
        spotFilter.p_is_available !== "" &&
        spotFilter.p_is_available !== null
      ) {
        requestBody.p_is_available = spotFilter.p_is_available;
      }
      getAllSpotService(requestBody, tokenData, dispatch);
      dispatch(getSpotPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearSpotFilterData());
    dispatch(clearSpotPageData());
    dispatch(clearSpotData());
    handleClose();
  };

  // Fetch brand data on user data change
  useEffect(() => {
    if (user.data?.brandData) {
      const parsedBrandData = JSON.parse(user.data.brandData);
      setBrandData(parsedBrandData);
    }
    // eslint-disable-next-line
  }, [user]);

  // Set the default selected brand when brandData changes
  useEffect(() => {
    if (brandData.length > 0 && Object.keys(selectedBrand).length === 0) {
      setSelectedBrand(brandData[0]);
    }
    // eslint-disable-next-line
  }, [brandData]);

  const QRCodeDetail = (item) => {
    if (item) {
      try {
        const container = document.createElement("div");
        container.style.position = "absolute";
        container.style.left = "-9999px";
        container.style.top = "-9999px";
        document.body.appendChild(container);

        ReactDOM.render(
          <QRCodeComponent
            companyCode={user.data.companyCode}
            companyLogo={user.data.companyLogo}
            companyPincode={user.data.branchPincode}
            companyName={user.data.companyName}
            tableNo={item.id}
            tableName={item.name}
            // tableNo={item.name}
            // brandLogo={url}
          />,
          container
        );
        const scaleFactor = 2; // Increase this to improve quality (2x, 3x, etc.)
        html2canvas(container, {
          useCORS: true,
          scale: scaleFactor, // Scale the canvas for higher resolution
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", [
            canvas.width / 4,
            canvas.height / 4,
          ]);

          // Adjust width and height to match the canvas scale
          const imgWidth = canvas.width / 4; // Adjust this to your needs
          const imgHeight = canvas.height / 4; // Adjust this to your needs

          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST"); // Use 'FAST' for better quality

          pdf.save("QRCode.pdf");
          document.body.removeChild(container);
        });

        // Ensure the image is loaded before capturing it
        // setTimeout(() => {
        //   const scaleFactor = 2; // Increase this to improve quality (2x, 3x, etc.)
        //   html2canvas(container, {
        //     useCORS: true,
        //     scale: scaleFactor, // Scale the canvas for higher resolution
        //   }).then((canvas) => {
        //     const imgData = canvas.toDataURL("image/png");
        //     const pdf = new jsPDF("p", "mm", [
        //       canvas.width / 4,
        //       canvas.height / 4,
        //     ]);

        //     // Adjust width and height to match the canvas scale
        //     const imgWidth = canvas.width / 4; // Adjust this to your needs
        //     const imgHeight = canvas.height / 4; // Adjust this to your needs

        //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST"); // Use 'FAST' for better quality

        //     pdf.save("QRCode.pdf");
        //     document.body.removeChild(container);
        //   });
        // }, 1000); // Delay to ensure the image has time to load
      } catch (error) {
        toast.error("Error generating QR code");
      }
    } else {
      try {
        const container = document.createElement("div");
        container.style.position = "absolute";
        container.style.left = "-9999px";
        container.style.top = "-9999px";
        document.body.appendChild(container);

        ReactDOM.render(
          <QRCodeComponent
            companyCode={user.data.companyCode}
            companyLogo={user.data.companyLogo}
            companyPincode={user.data.branchPincode}
            companyName={user.data.companyName}
            // tableNo={item.name}
            // brandLogo={url}
          />,
          container
        );
        const scaleFactor = 2; // Increase this to improve quality (2x, 3x, etc.)
        html2canvas(container, {
          useCORS: true,
          scale: scaleFactor, // Scale the canvas for higher resolution
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", [
            canvas.width / 4,
            canvas.height / 4,
          ]);

          // Adjust width and height to match the canvas scale
          const imgWidth = canvas.width / 4; // Adjust this to your needs
          const imgHeight = canvas.height / 4; // Adjust this to your needs

          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST"); // Use 'FAST' for better quality

          pdf.save("QRCode.pdf");
          document.body.removeChild(container);
        });
      } catch (error) {
        toast.error("Error generating QR code");
      }
    }
  };

  const getBrand = (item) => {
    if (item) {
      // setQrSpotData(item);
    }

    if (brandData.length > 1) {
      // setBranchModal(true);
    } else {
      setSelectedBrand(brandData[0]);
      QRCodeDetail(item, brandData[0]);
    }
  };
  const styleData = {
    name: {type: {textAlign: "end"} },
    price: { type: { textAlign: "end" } },
    size: { type: { textAlign: "end" } },
  };

  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {spotActionData && spotActionData.length > 0
                    ? getRouteNameFromSidebar(
                        spotActionData[0].name,
                        localisation
                      )
                    : t("Tables")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {spotActionData && spotActionData.length > 0
                    ? getRouteNameFromSidebar(
                        spotActionData[0].name,
                        localisation
                      )
                    : t("Tables")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(spotFilter?.p_name !== "" ||
                spotFilter?.p_is_active !== "" ||
                spotFilter?.p_is_deleted !== "" ||
                spotFilter?.p_is_available !== "") && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="spots" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              {spotData &&
                spotData.items[0]?.data &&
                spotData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={spotData.items[0].data}
                    fileName={"image"}
                  ></ExcelData>
                )}
              {((spotActionData &&
                spotActionData[0]?.add &&
                spotActionData[0].add === "1") ||
                !spotActionData) && (
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Spot"
                  onClick={() => navigate("/addspots")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>
          {spotData && spotData.items[0]?.data && spotData.items[0].data && (
            <>
              <button
                className="qr-btn"
                onClick={() => QRCodeDetail(tableData)}
              >
                {" "}
                <FaQrcode />
              </button>
              <CustomTable
                page="spots"
                showToggle={showToggle}
                checkBool={false}
                showKey={showKey}
                showActions={showActionBool ? showActions : false}
                showTooltip={true}
                data={tableData}
                heading={columns}
                disabled={
                  spotActionData &&
                  spotActionData[0]?.edit &&
                  spotActionData[0].edit === "0"
                }
                currentPage={currentPageNumber}
                onPageChange={onPageChange}
                getBrand={getBrand}
                QRCodeDetail={QRCodeDetail}
                styleData={styleData}
                pageData={spotPage}
              />

              {/* pagination */}
              {spotData.items[0].total_records >
                spotData.items[0].data.length &&
                spotData.items[0].total_records > 10 && (
                  <CustomPagination
                    totalRecords={spotData.items[0].total_records}
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                  />
                )}
            </>
          )}
        </div>
        {/* <Footer />
        </div> */}
        {/* {branchModal && (
          <div className="modal-overlay">
            <div className="modal-content-order">
              <button
                className="modal-close"
                onClick={() => setBranchModal(!branchModal)}
              >
                <MdOutlineCancel />
              </button>
              <h6>Select your brand for QR-code</h6>
              <div>
                {brandData.length > 0 &&
                  brandData.map((data, id) => (
                    <div key={data.id} className="attr-row">
                      <label htmlFor={data.name}>{data.name}</label>
                      <input
                        type="radio"
                        id={data.name}
                        value={data.name}
                        checked={selectedBrand?.id === data.id}
                        onChange={() =>
                          handleRadioChange(data.id, data.name, data.image_data)
                        }
                      />
                    </div>
                  ))}
              </div>
              <button
                className="qr-btn"
                onClick={() => QRCodeDetail(qrSpotData, selectedBrand)}
              >
                <FaQrcode />
              </button>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Spots;

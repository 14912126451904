import {
  CLEAR_EXPENSE_DATA,
  CLEAR_TOTAL_EXPENSE,
  GET_EXPENSE_DATA,
  GET_TOTAL_EXPENSE,
} from "../../ReduxKeys";

/**
 * Setting expense list data into reducer
 * @param {*} expense
 * @returns {*}
 */
export const getExpenseData = (expense) => ({
  type: GET_EXPENSE_DATA,
  payload: expense,
});

/**
 * clearing expense list data
 * @returns {*}
 */
export const clearExpenseData = () => ({
  type: CLEAR_EXPENSE_DATA,
});

/**
 * Setting total expense list data into reducer (No pagnation)
 * @param {*} expense
 * @returns {*}
 */
export const getTotalExpenseData = (inventoryItem) => ({
  type: GET_TOTAL_EXPENSE,
  payload: inventoryItem,
});

/**
 * Clearing total expense llist data (No pagination)
 * @returns {*}
 */
export const clearTotalExpenseData = () => ({
  type: CLEAR_TOTAL_EXPENSE,
});
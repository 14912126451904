import {
  CLEAR_SETTLEMENT_REPORT_FILTER,
  GET_SETTLEMENT_REPORT_FILTER,
} from "../../ReduxKeys";

/**
 * set event log filter data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getSettlementGraphFilterData = ({ startDate, endDate }) => ({
  type: GET_SETTLEMENT_REPORT_FILTER,
  payload: { startDate, endDate },
});

/**
 * clear order filtered data
 * @returns {*}
 */
export const clearSettlementGraphFilterData = () => ({
  type: CLEAR_SETTLEMENT_REPORT_FILTER,
});
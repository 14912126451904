// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pie-chart-settlement {
  width: 370px;
  height: 370px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.settlement-icon-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.settlement-icon-container .settlement-icon {
  margin-right: 5px;
  font-size: 22px;
}

.modal-content-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SettlementGraph/SettlementGraph.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AADI;EACI,iBAAA;EACA,eAAA;AAGR;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAGJ","sourcesContent":[".pie-chart-settlement{\n    width: 370px;\n    height: 370px;\n    display: flex;\n    margin: 0 auto;\n    align-items: center;\n    justify-content: center;\n}\n.settlement-icon-container{\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n    .settlement-icon{\n        margin-right: 5px;\n        font-size: 22px;\n    }\n}\n.modal-content-icons{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { CLEAR_CUSTOMER_PAGE, GET_CUSTOMER_PAGE } from "../../ReduxKeys";

const CustomerPageInitialState = {
  items: 1,
};

/**
 * Store CUSTOMER page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const CustomerPageReducer = (
  state = CustomerPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_CUSTOMER_PAGE:
      return CustomerPageInitialState;
    default:
      return state;
  }
};
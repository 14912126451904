import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import "../Imagemanager/Imagemanager.scss";
import Toggle from "../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { formatUTCDate } from "../../services/Regular-services/DateService";
import { clearLeafFilterData } from "../../redux/Actions/leafLevelCategoryAction/LeafFilterAction";
import { clearLeafLevelData } from "../../redux/Actions/leafLevelCategoryAction/LeafLevelCategoryAction";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearLeafPageData,
  getLeafPageData,
} from "../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
// import { TfiImport } from "react-icons/tfi";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import { useTranslation } from "react-i18next";
import LeafLevelCategoryService from "./LeafLevelService/LeafLevelService";
import SubCategoryServices from "../SubCategory/SubCategoryService/SubCategoryService";

//Show keys which is to be displayed in table
const showKey = {
  Title: true,
  Description: true,
  SubCategoryName: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

//Handle toggle change
const handleToggleChange = (id) => {};
export default function LeafLevelCategory() {
  const { getAllLeafLevelCategoryService, uploadLeafExcelService } =
    LeafLevelCategoryService();
  const { getAllSubCategoryService } = SubCategoryServices();
  const { t } = useTranslation();
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const localization = useSelector((state) => state.localization?.items);
  const leafFilter = useSelector((state) => state.leafFilter);
  const leafPage = useSelector((state) => state.leafPage?.items);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [leafActionData, setleafActionData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setleafActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      leafActionData[0]?.view &&
      leafActionData[0].view === "0" &&
      leafActionData[0]?.edit &&
      leafActionData[0].edit === "0" &&
      leafActionData[0]?.add &&
      leafActionData[0].add === "0" &&
      leafActionData[0]?.delete &&
      leafActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.title"),
          selector: (row) => row.title,
        },
        {
          name: t("columns.description"),
          selector: (row) => row.description,
        },
        {
          name: t("columns.sub_category"),
          selector: (row) => row.parentcategory,
        },
        {
          name: t("columns.status"),
          selector: (row) => row.active,
          cell: (row) => (
            <Toggle
              checked={row.toggle}
              onChange={() => handleToggleChange(row.id)}
            />
          ),
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.title"),
          selector: (row) => row.title,
        },
        {
          name: t("columns.description"),
          selector: (row) => row.description,
        },
        {
          name: t("columns.sub_category"),
          selector: (row) => row.parentcategory,
        },
        {
          name: t("columns.status"),
          selector: (row) => row.active,
          cell: (row) => (
            <Toggle
              checked={row.toggle}
              onChange={() => handleToggleChange(row.id)}
            />
          ),
        },
        {
          name: t("columns.actions"),
          selector: (row) => row.action,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [leafActionData, localization]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (leafActionData[0]?.view && leafActionData[0].view === "1") ||
      !leafActionData
        ? true
        : false,
    showEdit:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? true
        : false,
    showDelete:
      (leafActionData[0]?.delete && leafActionData[0].delete === "1") ||
      !leafActionData
        ? true
        : false,
    showUndo:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? true
        : false,
    navigate:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? "/edit-leaf-level-category"
        : false,
  };

  useEffect(() => {
    setCurrentPage(leafPage);
  }, [leafPage]);

  const [tableData, setTableData] = useState([]);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const subCategoryListData = useSelector((state) => state.totalSubCategory);

  /**
   * Fetching sub-category data
   */
  const fetchSubCategory = () => {
    const subCategoryRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllSubCategoryService(subCategoryRequestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    if (
      subCategoryListData &&
      subCategoryListData.items &&
      subCategoryListData.items.length === 0
    ) {
      fetchSubCategory();
    }
    // eslint-disable-next-line
  }, [subCategoryListData]);

  //Get data from reducer
  const leafCategoryData = useSelector((state) => state.leafCategory);

  /**
   * Fetching leaf level category data
   */
  const fetchLeafCategory = () => {
    if (leafPage > 1) {
      const requestBody = {
        p_skip: (leafPage - 2) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      dispatch(getLeafPageData(leafPage - 1));
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (leafPage - 1) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (
      leafCategoryData &&
      leafCategoryData.items &&
      leafCategoryData.items.length === 0
    ) {
      fetchLeafCategory();
    } else {
      if (
        leafFilter.name !== "" ||
        leafFilter.subCategory !== "" ||
        leafFilter.isActive !== "" ||
        leafFilter.isDelete !== ""
      ) {
        // handleShow();
      }
    }

    if (
      leafCategoryData.items[0]?.data &&
      leafCategoryData.items[0].data.length > 0
    ) {
      const transformedLeafData = leafCategoryData.items[0].data.map(
        (item) => ({
          id: item.id,
          Title: item.Title,
          Description: item.Description,
          SubcategoryId: item.SubcategoryId,
          SubCategoryName: "",
          createdAt: formatUTCDate(item.created_at),
          sequence: item.id,
          isActive: item.is_active,
          isDeleted: item.IsDeleted,
          createdBy: item.created_by,
          modifiedBy: item.modified_by,
          modifiedAt: item.modified_at,
        })
      );
      if (
        subCategoryListData &&
        subCategoryListData.items &&
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        for (let i = 0; i < leafCategoryData.items[0].data.length; i++) {
          const subcategoryId = leafCategoryData.items[0].data[i].SubcategoryId;
          const matchingObject = subCategoryListData.items[0].data.find(
            (obj) => obj.id === subcategoryId
          );
          if (matchingObject) {
            transformedLeafData[i].SubCategoryName = matchingObject.name;
          }
        }
      }
      setTableData(transformedLeafData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [leafCategoryData]);

  const navigate = useNavigate();

  /**
   * Clear filtered data
   */
  const clearFilters = () => {
    dispatch(clearLeafFilterData());
    dispatch(clearLeafLevelData());
    dispatch(clearLeafPageData());
    handleClose();
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    }
    setCurrentPage(pageNumber);
    dispatch(getLeafPageData(pageNumber));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleImportClick = () => {
  //   setIsModalOpen(true);
  // };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadLeafExcelService(file, tokenData, setIsModalOpen));
  };

  return (
    <>
      {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
      <div className="table-body-content">
        <div className="roles">
          <div>
            <div className="title">
              <h4>
                {leafActionData && leafActionData.length > 0
                  ? getRouteNameFromSidebar(
                      leafActionData[0].name,
                      localisation
                    )
                  : t("Leaf Level Categories")}
              </h4>
            </div>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate("/home")}>
                {t("Home")}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {leafActionData && leafActionData.length > 0
                  ? getRouteNameFromSidebar(
                      leafActionData[0].name,
                      localisation
                    )
                  : t("Leaf Level Categories")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="btn-groups">
            {leafFilter &&
              (leafFilter.name !== "" ||
                leafFilter.subCategory !== "" ||
                leafFilter.isActive !== "" ||
                leafFilter.isDelete !== "") && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

            {/* {((leafActionData[0]?.add && leafActionData[0].add === "1") ||
                  !leafActionData) && (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <TfiImport />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleImportClick}>
                        Import
                      </Dropdown.Item>
                      <DownloadExcel
                        excelFile={sampleLeafExcelFile}
                        fileName="leaf-level-category.xlsx"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}

            <FileUploadModal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              onFileUpload={handleFileUpload}
              isClose={isModalOpen}
            />

            <Button
              onClick={handleShow}
              data-bs-toggle="tooltip"
              title="Filter"
              // onClick={handleButtonClick}
              className="btn btn-fillter-main"
              name={
                <div className="filter">
                  <FaFilter className="filter-icon" />
                </div>
              }
            ></Button>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Filter page="leafCategory" handleClose={handleClose}></Filter>
              </Offcanvas.Body>
            </Offcanvas>
            {leafCategoryData &&
              leafCategoryData.items &&
              leafCategoryData.items[0]?.data &&
              leafCategoryData.items[0].data.length > 0 && (
                <ExcelData
                  excelData={leafCategoryData.items[0].data}
                  fileName={"leaf-level-category"}
                ></ExcelData>
              )}
            {((leafActionData[0]?.add && leafActionData[0].add === "1") ||
              !leafActionData) && (
              <Button
                data-bs-toggle="tooltip"
                title="Add leaf-level-category"
                onClick={() => navigate("/add-leaf-level-category")}
                className="btn btn-fillter-main"
                name={
                  <div className="Plus">
                    <FaPlus />
                  </div>
                }
              ></Button>
            )}
          </div>
        </div>
        {/* {isToggled && (
              <Filter
                page="leafCategory"
                handleButtonClick={handleButtonClick}
              ></Filter>
            )} */}
        {leafCategoryData &&
          leafCategoryData.items[0]?.data &&
          leafCategoryData.items[0].data && (
            <>
              <CustomTable
                totalRecords={
                  leafCategoryData.items[0].total_records
                    ? leafCategoryData.items[0].total_records
                    : 0
                }
                showDescription={true}
                showToggle={showToggle}
                checkBool={false}
                showKey={showKey}
                showActions={showActionBool ? showActions : false}
                showTooltip={true}
                data={tableData}
                heading={columns}
                page="leafCategory"
                disabled={
                  leafActionData[0]?.edit && leafActionData[0].edit === "0"
                }
                pageData={leafPage}
              />

              {leafCategoryData.items[0].total_records >
                leafCategoryData.items[0].data.length &&
                leafCategoryData.items[0].total_records > 10 && (
                  <div className="custom-pagination">
                    <CustomPagination
                      totalRecords={leafCategoryData.items[0].total_records}
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  </div>
                )}
            </>
          )}
      </div>
      {/* <Footer />
        </div> */}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../../components/Toggle/Toggle";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddDiscount.scss";
import ErrorComp from "../../../components/Error/ErrorComp";
import Modal from "react-bootstrap/Modal";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DiscountService from "../DiscountService/DiscountService";
import CategoryService from "../../Category/Category-service/CategoryService";
import { format } from "date-fns";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { toast } from "react-toastify";
import { modifyString } from "../../../services/Regular-services/StringService";
import { useTranslation } from "react-i18next";
import ProductServices from "../../Product/ProductService/ProductService";
import ConfirmationModal from "../../../components/ConfirmAndCloseModal/ConfirmCloseModal";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

export default function AddDiscount() {
  const { getAllCategoryService } = CategoryService();
  const { getAllProductService } = ProductServices();
  const {
    addUpdateDiscountService,
    getAllDiscountBrandService,
    getDiscountById,
  } = DiscountService();
  const {confirmSaveClick} = ConfirmationModal();

  const { t } = useTranslation();
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const discountPage = useSelector((state) => state.discountPage?.items);
  const discountBrand = useSelector((state) => state.discountBrand);
  const productData = useSelector((state) => state.totalProduct);
  const categoryListData = useSelector((state) => state.totalCategory);
  const totalLeaf = useSelector((state) => state.totalLeaf);
  const subCategoryListData = useSelector((state) => state.totalSubCategory);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [discountActionData, setdiscountActionData] = useState([]);
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const localisation = useSelector((state) => state.localization?.items);
  const token = UtilsData();
  const dispatch = useDispatch();
  const [lgShow, setLgShow] = useState(false);
  const [CategoriesShow, setCategoriesShow] = useState(false);
  const [itproductShow, productShow] = useState(false);
  const [discountDetail, setDiscountDetail] = useState(null);
  const [showBrand, setShowBrand] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [showBrandData, setShowBrandData] = useState([]);
  const [showBrandName, setShowBrandName] = useState([]);
  const [showCategoryData, setShowCategoryData] = useState([]);
  const [showCategoryName, setShowCategoryName] = useState([]);
  const [showProductData, setShowProductData] = useState([]);
  const [showProductName, setShowProductName] = useState([]);
  const authuserData = useSelector((state) => state.auth?.user);

  const validationSchema = Yup.object({
    discountTitle: Yup.string().required().max(20),
    discountCode: Yup.string().required(),
    discountType: Yup.string().required(),
    discountValue: Yup.number().required(t(ValidationMessage.FIELD_REQUIRED)),
    maxDiscountAmount: Yup.string()
      .nullable()
      .when("discountType", {
        is: (val) => val === "1" || val === "Yes",
        then: (schema) => schema.required(t(ValidationMessage.FIELD_REQUIRED)),
      }),
    minimumCartValue: Yup.number().required(
      t(ValidationMessage.FIELD_REQUIRED)
    ),
    startDate: Yup.date().required(t(ValidationMessage.FIELD_REQUIRED)),
    endDate: Yup.date().required(t(ValidationMessage.FIELD_REQUIRED)),
    specificItems: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
    discountApplyOn: Yup.string()
      .nullable()
      .when("specificItems", {
        is: (val) => val === "1" || val === "Yes",
        then: (schema) => schema.required(t(ValidationMessage.FIELD_REQUIRED)),
      }),
    cartDiscount: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
    status: Yup.boolean().required(),
  });

  const [initialValues, setInitialValues] = useState({
    discountTitle: "",
    discountCode: "",
    discountType: "",
    discountValue: "",
    maxDiscountAmount: "",
    minimumCartValue: "",
    firstOrderDiscount: "",
    cartDiscount: "",
    specificItems: "",
    startDate: "",
    endDate: "",
    discountApplyOn: "",
    status: true,
  });

  const [brandInitialValue, setBrandInitialValues] = useState({
    brandSearchBar: "",
  });

  const [categoryInitialValue, setCategoryInitialValue] = useState({
    categorySearchBar: "",
  });

  const [productInitialValue, setProductInitialValue] = useState({
    productSearchBar: "",
  });

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "discount"
      );
      setdiscountActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
      p_type: "0",
    };
    getAllCategoryService(categoryRequestBody, token, dispatch, true);
    // eslint-disable-next-line
  }, []);

  /**
   * Setting category options
   */
  useEffect(() => {
    if (categoryListData?.items && categoryListData?.items?.length) {
      const initialValues = categoryListData.items[0]?.data.reduce(
        (acc, category) => {
          acc[`category${category.categoryId}`] = false;
          return acc;
        },
        {}
      );
      showCategoryData &&
        showCategoryData.length > 0 &&
        showCategoryData.forEach((item) => {
          if (initialValues[`category${item.category_id}`] !== undefined) {
            initialValues[`category${item.category_id}`] = true;
          }
        });
      setCategoryInitialValue({
        categorySearchBar: "",
        ...initialValues,
      });
    }
    // eslint-disable-next-line
  }, [categoryListData, showCategoryData]);

  /**
   * Call get discount by Id fuction and set values in form
   */
  const fetchDiscount = async () => {
    try {
      const discountData = await getDiscountById(
        Data.data.discountId,
        token,
        dispatch
      );
      setDiscountDetail(discountData);
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchDiscount();
    }
    // eslint-disable-next-line
  }, [Data]);

  useEffect(() => {
    if (discountDetail?.data) {
      const data = discountDetail.data;
      setInitialValues({
        discountTitle: data.title,
        discountCode: data.code,
        discountType: data.discount_type,
        discountValue: data.discount_amount,
        maxDiscountAmount: data.max_discount_ammount,
        minimumCartValue: data.min_cart_value,
        firstOrderDiscount: data.is_first_order_discount,
        cartDiscount: data.discount_type_on,
        specificItems: data.spacific_item,
        startDate: data.start_date,
        endDate: data.end_date,
        discountApplyOn: data.discount_apply_on,
        status: data.is_active === "1",
      });

      const processInitialValues = (items, keyPrefix, ids, handler) => {
        const initialValues = items[0]?.data.reduce((acc, item) => {
          acc[
            `${keyPrefix}${item.id || item.categoryId || item.productId}`
          ] = false;
          return acc;
        }, {});

        ids
          ?.split(",")
          .map((id) => id.trim())
          .forEach((id) => {
            const key = `${keyPrefix}${id}`;
            if (initialValues?.[key] !== undefined) {
              initialValues[key] = true;
            }
          });

        if (ids) handler(initialValues);
      };

      switch (data.discount_apply_on) {
        case "0":
          setShowCategories(true);
          processInitialValues(
            categoryListData.items,
            "category",
            data.category_ids,
            handleCategorySubmit
          );
          break;
        case "3":
          setShowBrand(true);
          processInitialValues(
            discountBrand.items,
            "brand",
            data.brand_ids,
            handleBrandSubmit
          );
          break;
        case "4":
          setShowProduct(true);
          processInitialValues(
            productData.items,
            "product",
            data.product_ids,
            handleProductSubmit
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [
    discountDetail,
    discountBrand,
    productData,
    subCategoryListData,
    categoryListData,
    totalLeaf,
  ]);

  /**
   * Discount apply on product initial values
   */
  useEffect(() => {
    if (
      (productData?.items && productData.items.length === 0) ||
      !productData?.items
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 1000000,
        p_is_active: "1",
      };
      getAllProductService(requestBody, token, dispatch, true);
    } else {
      const initialValues = productData.items[0]?.data.reduce(
        (acc, product) => {
          acc[`product${product.productId}`] = false; // Set all brands initially unchecked
          return acc;
        },
        {}
      );
      showProductData &&
        showProductData.length > 0 &&
        showProductData.forEach((item) => {
          if (initialValues[`product${item.product_id}`] !== undefined) {
            initialValues[`product${item.product_id}`] = true;
          }
        });
      setProductInitialValue({
        subCategorySearchBar: "",
        ...initialValues,
      });
    }
    // eslint-disable-next-line
  }, [productData, showProductData]);

  /**
   * Discount apply on brand initial values
   */
  useEffect(() => {
    if (
      discountBrand &&
      discountBrand.items &&
      discountBrand.items.length === 0
    ) {
      getAllDiscountBrandService(token, dispatch);
    } else {
      const initialValues = discountBrand.items[0]?.data.reduce(
        (acc, brand) => {
          acc[`brand${brand.id}`] = false; // Set all brands initially unchecked
          return acc;
        },
        {}
      );
      showBrandData &&
        showBrandData.length > 0 &&
        showBrandData.forEach((item) => {
          const { brand_id } = item;
          if (initialValues[`brand${brand_id}`] !== undefined) {
            initialValues[`brand${brand_id}`] = true;
          }
        });

      setBrandInitialValues({
        brandSearchBar: "",
        ...initialValues,
      });
    }
    // eslint-disable-next-line
  }, [discountBrand, showBrandData]);

  /**
   * Check all data on checking the checkbox
   */
  const changeFields = (event, setFieldValue, field) => {
    const { checked } = event.target;
    if (field === "brand") {
      discountBrand.items[0].data.forEach((brand) => {
        setFieldValue(`brand${brand.id}`, checked);
      });
    } else if (field === "category") {
      categoryListData.items[0].data.forEach((category) => {
        setFieldValue(`category${category.categoryId}`, checked);
      });
    } else if (field === "product") {
      productData.items[0].data.forEach((product) => {
        setFieldValue(`product${product.productId}`, checked);
      });
    }
  };

  const typeOptions = [
    {
      value: "0",
      label: authuserData?.data?.symbol ? authuserData.data.symbol : "₹",
    },
    { value: "1", label: "%" },
  ];

  const specificOptions = [
    { value: "1", label: t("lable_yes") },
    { value: "0", label: t("lable_no") },
  ];
  const DiscountOptions = [
    { value: "0", label: t("lable_categories") },
    { value: "3", label: t("Department") },
    { value: "4", label: t("lable_product") },
  ];
  const AmounttypeOptions = [
    { value: "1", label: t("lable_yes") },
    { value: "0", label: t("lable_no") },
  ];
  const DiscounttypeOptions = [
    { value: "0", label: t("lable_cart_discount") },
    { value: "1", label: t("lable_slashed_discount") },
  ];

  /**
   * Show option
   */
  const handleOptionSelect = (selectedOptions) => {
    const selectedValues = [selectedOptions].map((option) => option.value);
    setShowBrand(selectedValues && selectedValues.includes("3"));
    setShowCategories(selectedValues && selectedValues.includes("0"));
    setShowProduct(selectedValues && selectedValues.includes("4"));
  };

  /**
   * Handle brand submit
   */
  const handleBrandSubmit = (values) => {
    // Filter out the checked brands
    const checkedBrands =
      discountBrand &&
      discountBrand?.items &&
      discountBrand.items.length > 0 &&
      discountBrand.items[0]?.data &&
      discountBrand.items[0].data.length > 0
        ? discountBrand.items[0].data.filter(
            (brand) => values[`brand${brand.id}`]
          )
        : [];

    const checkedBrandDetails = checkedBrands
      .map((brand) => {
        if (values[`brand${brand.id}`]) {
          return {
            brand_id: brand.id,
          };
        }
        return null; // Return null for unchecked brands
      })
      .filter(Boolean); // Filter out any null values
    setShowBrandData(checkedBrandDetails);

    const checkedBrandName = checkedBrands
      .map((brand) => {
        if (values[`brand${brand.id}`]) {
          return {
            id: brand.id,
            name: brand.name,
            // Add other properties of the brand if needed
          };
        }
        return null; // Return null for unchecked brands
      })
      .filter(Boolean); // Filter out any null values
    setShowBrandName(checkedBrandName);
    setLgShow(false);
  };

  /**
   * Handle category submit
   */
  const handleCategorySubmit = (values, categoryList) => {
    const newCategoryList = categoryList?.items ? categoryList : categoryListData;
    const checkedCategory =
      newCategoryList?.items?.length && newCategoryList.items[0]?.data?.length
        ? newCategoryList.items[0].data.filter(
            (category) => values[`category${category.categoryId}`]
          )
        : [];

    const checkedCategoryDetails = checkedCategory
      .map((category) => {
        if (values[`category${category.categoryId}`]) {
          return {
            category_id: parseInt(category.categoryId),
          };
        }
        return null;
      })
      .filter(Boolean);
    setShowCategoryData(checkedCategoryDetails);

    const checkedCategoryName = checkedCategory
      .map((category) => {
        if (values[`category${category.categoryId}`]) {
          return {
            id: category.categoryId,
            name: category.name,
          };
        }
        return null;
      })
      .filter(Boolean);
    setShowCategoryName(checkedCategoryName);
    setCategoriesShow(false);
  };

  /**
   * Handle product submit
   */
  const handleProductSubmit = (values) => {
    const checkedProduct =
      productData &&
      productData?.items &&
      productData.items.length > 0 &&
      productData.items[0]?.data &&
      productData.items[0].data.length > 0
        ? productData.items[0].data.filter(
            (product) => values[`product${product.productId}`]
          )
        : [];

    const checkedProductDetails = checkedProduct
      .map((product) => {
        if (values[`product${product.productId}`]) {
          return {
            product_id: parseInt(product.productId),
          };
        }
        return null;
      })
      .filter(Boolean);
    setShowProductData(checkedProductDetails);

    const checkedProductyName = checkedProduct
      .map((product) => {
        if (values[`product${product.productId}`]) {
          return {
            id: product.productId,
            name: product.name,
          };
        }
        return null;
      })
      .filter(Boolean);
    setShowProductName(checkedProductyName);
    productShow(false);
  };

  const prepareRequestBody = (values) => {
    const requestBody = {
      p_title: values.discountTitle,
      p_code: values.discountCode,
      p_discount_type: values.discountType,
      p_discount_amount: Number(values.discountValue),
      p_max_discount_amount:
        values.maxDiscountAmount && values.maxDiscountAmount !== ""
          ? Number(values.maxDiscountAmount)
          : null,
      p_min_cart_value: Number(values.minimumCartValue),
      p_is_first_order_discount: values.firstOrderDiscount,
      p_discount_type_on: values.cartDiscount,
      p_discount_apply_on:
        values.specificItems === "1" ? values.discountApplyOn : null,
      p_specific_item: values.specificItems,
      p_start_date: format(new Date(values.startDate), "yyyy-MM-dd"),
      p_end_date: format(new Date(values.endDate), "yyyy-MM-dd"),
      p_is_active:
        values.status === true || values.status === 1 || values.status === "1"
          ? "1"
          : "0",
      // ...additionalFields
    };
    return requestBody;
  };

  const handleDiscountDataCheck = (discountApplyOn, showData, errorMessage) => {
    if (showData.length === 0) {
      toast.error(errorMessage);
      return false;
    }
    return showData;
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    try {
      const userId = user.data.userId;
      let requestBody = prepareRequestBody(values);

      if (!Data) {
        requestBody.p_created_by = userId;
      } else {
        requestBody.p_id = Data.data.discountId;
        requestBody.p_modified_by = userId;
        requestBody.p_IsDeleted = Data.data.IsDeleted;
      }

      if (values.specificItems === "1") {
        const dataMapping = {
          3: {
            showData: showBrandData,
            errorMessage: "Please select brand discount",
          },
          0: {
            showData: showCategoryData,
            errorMessage: "Please select category discount",
          },
          4: {
            showData: showProductData,
            errorMessage: "Please select product discount",
          },
        };

        const selectedData = handleDiscountDataCheck(
          values.discountApplyOn,
          dataMapping[values.discountApplyOn].showData,
          dataMapping[values.discountApplyOn].errorMessage
        );

        if (!selectedData) return;

        requestBody.p_discount_data = selectedData;
      }

      const action = Data ? "update" : "add";
      const page =
        discountPage > 1 &&
        (values.status === "0" ||
          values.status === 0 ||
          values.status === false)
          ? Data.length > 1
            ? discountPage
            : discountPage - 1
          : discountPage;
      handlesaveClick(
        dispatch,
        addUpdateDiscountService(requestBody, token, action, navigate, page),
        action
      );
    } catch (error) {
      console.log("err: ", error);
    }
  };

  const onChangeSpecificItems = (value, setFieldValue) => {
    if (value === "0") {
      setFieldValue("discountApplyOn", "");
      setShowBrand(false);
      setShowCategories(false);
      setShowProduct(false);
    }
  };

  const onChangeDiscountType = (value, setFieldValue) => {
    if (value === "0") {
      setFieldValue("maxDiscountAmount", "");
    }
  };

  const handleCatagorySelect = (category, checked, setFieldValue) => {
    setFieldValue(`category${category.categoryId}`, checked);
    if (checked) {
      selectDescendants(
        category.categoryId,
        checked,
        categoryListData,
        setFieldValue
      );
    }
  };

  const selectDescendants = (
    parentId,
    checked,
    categoryListData,
    setFieldValue
  ) => {
    const childCategories = categoryListData.items[0].data.filter(
      (category) => category.parentCategoryID === parentId
    );

    childCategories.forEach((category) => {
      setFieldValue(`category${category.categoryId}`, checked);
      selectDescendants(
        category.categoryId,
        checked,
        categoryListData,
        setFieldValue
      );
    });
  };

  const renderCategoryTree = (
    categories,
    parentId = 0,
    values,
    setFieldValue,
    Data
  ) => {
    const childCategories = categories.filter(
      (category) => category.parentCategoryID === parentId
    );

    if (!childCategories.length) return null;

    return (
      <ul
        className={
          parentId ? "category-discount-ul" : "category-discount-no-parent"
        }
      >
        {childCategories.map((category) => (
          <li key={category.categoryId} className="category-discount-li">
            <div className="d-flex">
              <Checkbox
                checked={values[`category${category.categoryId}`]}
                name={`category${category.categoryId}`}
                id={`category${category.categoryId}`}
                onChange={(event) =>
                  handleCatagorySelect(
                    category,
                    event.target.checked,
                    setFieldValue
                  )
                }
                disabled={Data && Data.isEdit === false}
              />
              {category.name}
            </div>
            {renderCategoryTree(
              categories,
              category.categoryId,
              values,
              setFieldValue,
              Data
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {discountActionData && discountActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          discountActionData[0].name,
                          localisation
                        )
                      )
                    : t("Discounts")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/discount")}>
                  {discountActionData && discountActionData.length > 0
                    ? getRouteNameFromSidebar(
                        discountActionData[0].name,
                        localisation
                      )
                    : t("Discounts")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {discountActionData && discountActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          discountActionData[0].name,
                          localisation
                        )
                      )
                    : t("Discounts")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {discountActionData && discountActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      discountActionData[0].name,
                      localisation
                    )
                  )
                : t("Discounts")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("discount_title")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="discountTitle"
                        label="Discount Title"
                        placeholder={t("placeholder_discount_title")}
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        maxLength={20}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_discount_code")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="discountCode"
                        label="Discount Code"
                        placeholder={t("placeholder_discount_code")}
                        className="add-roles"
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_discount_type")}{" "}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="basic-single"
                        name="discountType"
                        options={typeOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.discountType}
                        changeData={(value) =>
                          onChangeDiscountType(value, setFieldValue)
                        }
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_discount_value")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="discountValue"
                        label="Discount Value"
                        placeholder={t("placeholder_discount_value")}
                        className="add-roles"
                        Regex={/[^0-9]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>

                  {values.discountType === "1" && (
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("lable_max_discount_amount")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="maxDiscountAmount"
                          label="Max Discount Amount"
                          placeholder={t("placeholder_max_discount_amount")}
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_minimum_cart_value")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="minimumCartValue"
                        label="Minimum Cart Value"
                        placeholder={t("placeholder_minimum_cart_value")}
                        className="add-roles"
                        Regex={/[^0-9]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_is_first_order_discount")}{" "}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="basic-single"
                        name="firstOrderDiscount"
                        options={AmounttypeOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.firstOrderDiscount}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_discount_type_on")}{" "}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="basic-single"
                        name="cartDiscount"
                        options={DiscounttypeOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.cartDiscount}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_specific_items")}{" "}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="basic-single"
                        name="specificItems"
                        options={specificOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        changeData={(value) =>
                          onChangeSpecificItems(value, setFieldValue)
                        }
                        value={values.specificItems}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <div className="date-picker">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("lable_date")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-5 datepicker-width">
                            <DatePicker
                              selected={values.startDate}
                              onChange={(date) => {
                                if (date > values.endDate) {
                                  setFieldValue("endDate", null);
                                }
                                setFieldValue("startDate", date);
                              }}
                              selectsStart
                              startDate={values.startDate}
                              endDate={values.endDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText={t("placeholder_start_date")}
                              showIcon
                              minDate={new Date()}
                              disabled={Data && Data.isEdit === false}
                            />
                            <ErrorMessage
                              name="startDate"
                              component={ErrorComp}
                            ></ErrorMessage>
                          </div>
                          <div className="col-md-2">
                            <label className="text-center w-100">
                              {t("lable_to")}
                            </label>
                          </div>
                          <div className="col-md-5 datepicker-width">
                            <DatePicker
                              selected={values.endDate}
                              onChange={(date) =>
                                setFieldValue("endDate", date)
                              }
                              selectsEnd
                              startDate={values.startDate}
                              endDate={values.endDate}
                              placeholderText={t("placeholder_end_date")}
                              showIcon
                              dateFormat="dd/MM/yyyy"
                              minDate={values.startDate}
                              disabled={Data && Data.isEdit === false}
                            />
                            <ErrorMessage
                              name="endDate"
                              component={ErrorComp}
                            ></ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(values.specificItems === "1" ||
                    values.specificItems === "Yes") && (
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_discount_apply_on")}{" "}
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="basic-single"
                          name="discountApplyOn"
                          options={DiscountOptions}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          onSelectChange={handleOptionSelect}
                          value={values.discountApplyOn}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                  )}
                  {(values.specificItems === "1" ||
                    values.specificItems === "Yes") &&
                    showBrand && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("Department")}{" "}{t("Discount")}
                              <span className="required-label"></span>
                            </h4>
                            <div
                              className="select-discount"
                              onClick={() => setLgShow(true)}
                            >
                              <span className="black-color">
                                {showBrandName && showBrandName.length > 1
                                  ? showBrandName[0].name +
                                    " and " +
                                    (showBrandName.length - 1) +
                                    " more"
                                  : showBrandName && showBrandName.length === 1
                                  ? showBrandName[0].name
                                  : t("select_brand_discount")}
                              </span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {(values.specificItems === "1" ||
                    values.specificItems === "Yes") &&
                    showCategories && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_categories_discount")}{" "}
                              <span className="required-label"></span>
                            </h4>
                            <div
                              className="select-discount"
                              onClick={() => setCategoriesShow(true)}
                            >
                              <span className="black-color">
                                {showCategoryName && showCategoryName.length > 1
                                  ? showCategoryName[0].name +
                                    " and " +
                                    (showCategoryName.length - 1) +
                                    " more"
                                  : showCategoryName &&
                                    showCategoryName.length === 1
                                  ? showCategoryName[0].name
                                  : t("select_category_discount")}
                              </span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {(values.specificItems === "1" ||
                    values.specificItems === "Yes") &&
                    showProduct && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_product_discount")}{" "}
                              <span className="required-label"></span>
                            </h4>
                            <div
                              className="select-discount"
                              onClick={() => productShow(true)}
                            >
                              <span className="black-color">
                                {showProductName && showProductName.length > 1
                                  ? showProductName[0].name +
                                    " and " +
                                    (showProductName.length - 1) +
                                    " more"
                                  : showProductName &&
                                    showProductName.length === 1
                                  ? showProductName[0].name
                                  : t("select_product_discount")}
                              </span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">{t("status")}</h4>
                    </div>
                    <Field
                      name="status"
                      setFieldValue={setFieldValue}
                      component={Toggle}
                      disabled={Data && Data.isEdit === false}
                    ></Field>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/discount", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* Brand modal open start*/}
      <Modal
        size="lg"
        show={lgShow}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Formik
          initialValues={brandInitialValue}
          onSubmit={handleBrandSubmit}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="new-close-btn-2">
                <div className="btn-submit mt-3 d-flex justify-content-end align-items-center">
                  {((Data && Data.isEdit === true) || !Data) && (
                    <Button
                      type="submit"
                      name={t("button_save")}
                      className="login-btn btn me-2"
                    />
                  )}
                </div>
                <Button
                  type="button"
                  className="btn-close btn"
                  onClick={() => confirmSaveClick(setLgShow)}
                >
                  <span>X</span>
                </Button>
              </div>
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {t("select_brand_discount")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="serchbar-detail"></div>
                <div className="list-of-brand">
                  {discountBrand &&
                  discountBrand.items &&
                  discountBrand.items[0]?.data &&
                  discountBrand.items[0].data.length > 0 ? (
                    <>
                      <span>
                        <Checkbox
                          value={
                            discountBrand &&
                            discountBrand.items &&
                            discountBrand.items[0]?.data &&
                            discountBrand.items[0].data.every(
                              (brand) => values[`brand${brand.id}`]
                            )
                          }
                          id="brand0"
                          onChange={(event) =>
                            changeFields(event, setFieldValue, "brand")
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                        {t("Department")}
                      </span>
                      <ul>
                        {discountBrand &&
                          discountBrand.items &&
                          discountBrand.items[0]?.data &&
                          discountBrand.items[0].data.map((brand) => (
                            <li key={brand.id}>
                              <Checkbox
                                value={values[`brand${brand.id}`]}
                                name={`brand${brand.id}`}
                                id={`brand${brand.id}`}
                                onChange={(event) =>
                                  setFieldValue(
                                    `brand${brand.id}`,
                                    event.target.checked
                                  )
                                }
                                disabled={Data && Data.isEdit === false}
                              />
                              {brand.name}
                            </li>
                          ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span>{t("no_brand_records")}</span>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Brand modal open End*/}

      {/* Categories modal open start*/}
      <Modal
        size="lg"
        show={CategoriesShow}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
        backdrop="static"
      >
        <Formik
          initialValues={categoryInitialValue}
          enableReinitialize={true}
          onSubmit={handleCategorySubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="new-close-btn-2">
                <div className="btn-submit mt-3 d-flex justify-content-end align-items-center">
                  {((Data && Data.isEdit === true) || !Data) && (
                    <Button
                      type="submit"
                      name={t("button_save")}
                      className="login-btn btn me-2"
                    />
                  )}
                </div>
                <Button
                  type="button"
                  className="btn-close btn"
                  onClick={() => confirmSaveClick(setCategoriesShow)}
                >
                  <span>X</span>
                </Button>
              </div>
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {t("categories_discount_title")}
                </Modal.Title>

                {/* Close Button */}
              </Modal.Header>
              <Modal.Body>
                <div className="serchbar-detail"></div>
                <div className="list-of-category">
                  {categoryListData?.items?.length &&
                  categoryListData.items[0]?.data?.length ? (
                    <>
                      <span>
                        <Checkbox
                          value={categoryListData.items[0].data.every(
                            (category) =>
                              values[`category${category.categoryId}`]
                          )}
                          id="category0"
                          onChange={(event) =>
                            changeFields(event, setFieldValue, "category")
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                        {t("categories")}
                      </span>
                      {renderCategoryTree(
                        categoryListData.items[0].data,
                        0,
                        values,
                        setFieldValue,
                        Data
                      )}
                    </>
                  ) : (
                    <span>{t("no_category_records")}</span>
                  )}
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Categories modal open End*/}

      {/* Product modal open start*/}
      <Modal
        size="lg"
        show={itproductShow}
        onHide={() => productShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Formik
          initialValues={productInitialValue}
          enableReinitialize={true}
          onSubmit={handleProductSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
               <div className="new-close-btn-2">
                <div className="btn-submit mt-3 d-flex justify-content-end align-items-center">
                  {((Data && Data.isEdit === true) || !Data) && (
                    <Button
                      type="submit"
                      name={t("button_save")}
                      className="login-btn btn me-2"
                    />
                  )}
                </div>
                <Button
                  type="button"
                  className="btn-close btn"
                  onClick={() => confirmSaveClick(productShow)}
                >
                  <span>X</span>
                </Button>
              </div>
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {t("select_product_discount")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="serchbar-detail"></div>
                <div className="list-of-brand">
                  {productData?.items?.length &&
                  productData.items[0]?.data?.length > 0 ? (
                    <>
                      <span>
                        <Checkbox
                          value={
                            productData.items &&
                            productData.items[0]?.data &&
                            productData.items[0].data.every(
                              (product) => values[`product${product.productId}`]
                            )
                          }
                          id="product0"
                          onChange={(event) =>
                            changeFields(event, setFieldValue, "product")
                          }
                          disabled={Data && Data.isEdit === false}
                        />{" "}
                        {t("lable_product")}
                      </span>
                      <ul>
                        {productData.items?.length &&
                          productData?.items[0]?.data?.length &&
                          productData.items[0].data.map((product) => (
                            <li key={product.productId}>
                              <Checkbox
                                value={values[`product${product.productId}`]}
                                name={`product${product.productId}`}
                                id={`product${product.productId}`}
                                onChange={(event) =>
                                  setFieldValue(
                                    `product${product.productId}`,
                                    event.target.checked
                                  )
                                }
                                disabled={Data && Data.isEdit === false}
                              />
                              {product.name}
                            </li>
                          ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span>{t("no_product_records")}</span>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Product modal open End*/}
    </>
  );
}
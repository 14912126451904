import {
  CLEAR_PRODUCT_PRICING_PAGE,
  SET_PRODUCT_PRICING_PAGE,
} from "../../ReduxKeys";

/**
 * Set product pricing page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getProductPricingPageData = (page) => ({
  type: SET_PRODUCT_PRICING_PAGE,
  payload: page,
});

/**
 * Clear product page data
 * @returns {*}
 */
export const clearProductPricingPageData = () => ({
  type: CLEAR_PRODUCT_PRICING_PAGE,
});
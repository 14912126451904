import { CLEAR_SETTLEMENT_REPORT, GET_SETTLEMENT_REPORT } from "../../ReduxKeys";

/**
 * Setting settlement report list data into reducer
 * @param {*} settlementReport
 * @returns {*}
 */
export const getSettlementGraphData = (settlementReport) => ({
  type: GET_SETTLEMENT_REPORT,
  payload: settlementReport,
});

/**
 * clearing event list data
 * @returns {*}
 */
export const clearSettlementGraphData = () => ({
  type: CLEAR_SETTLEMENT_REPORT,
});

import { Dispatch } from "redux";
import {
  addInventoryRequest,
  getInventoryListRequest,
  getInventoryListResponse,
} from "./InventoryModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  clearInventoryData,
  clearTotalInventoryData,
  getInventoryData,
  getTotalInventoryData,
} from "../../../redux/Actions/InventoryAction/InventoryAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import {
  clearInventoryPageData,
  getInventoryPageData,
} from "../../../redux/Actions/InventoryAction/InventoryPageAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function InventoryService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get product list
   */
  const getAllInventoryService = (
    inventoryListRequest: getInventoryListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getInventoryListResponse> => {
    return new Promise<getInventoryListResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          inventoryListRequest.p_skip !== undefined &&
          inventoryListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${inventoryListRequest.p_skip}&`;
        }
        if (
          inventoryListRequest.p_limit !== undefined &&
          inventoryListRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${inventoryListRequest.p_limit}&`;
        }
        if (
          inventoryListRequest.p_productCode !== undefined &&
          inventoryListRequest.p_productCode !== null
        ) {
          queryParams += `p_productCode=${inventoryListRequest.p_productCode}&`;
        }
        if (
          inventoryListRequest.p_sku_code !== undefined &&
          inventoryListRequest.p_sku_code !== null
        ) {
          queryParams += `p_sku_code=${inventoryListRequest.p_sku_code}&`;
        }

        if (
          inventoryListRequest.p_warehouse_id !== undefined &&
          inventoryListRequest.p_warehouse_id !== null
        ) {
          queryParams += `p_warehouse_id=${inventoryListRequest.p_warehouse_id}&`;
        }
        if (
          inventoryListRequest.p_name !== undefined &&
          inventoryListRequest.p_name !== null
        ) {
          queryParams += `p_name=${inventoryListRequest.p_name}&`;
        }
        if (
          inventoryListRequest.p_is_active !== undefined &&
          inventoryListRequest.p_is_active !== null
        ) {
          queryParams += `p_is_active=${inventoryListRequest.p_is_active}&`;
        }
        if (
          inventoryListRequest.p_isDeleted !== undefined &&
          inventoryListRequest.p_isDeleted !== null
        ) {
          queryParams += `p_isDeleted=${inventoryListRequest.p_isDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.INVENTORY +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalInventoryData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getInventoryData(response));
          } else {
            dispatch(getInventoryData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message === "Permission does not exists."
                  ? "Inventory permission does not exists."
                  : response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating leaf level category
   */
  const addInventoryService = (
    addInventoryRequest: addInventoryRequest,
    token: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await postApiService(
          API_URL.BASE_URL + API_URL.INVENTORY,
          token,
          addInventoryRequest,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearInventoryData());
          dispatch(clearTotalInventoryData());
          if (pageNumber) {
            dispatch(getInventoryPageData(pageNumber + 1));
          } else {
            dispatch(clearInventoryPageData());
          }
          toast.success("Inventory added successfully!");
          navigate("/inventory");
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          if (pageNumber) {
            dispatch(getInventoryPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return { getAllInventoryService, addInventoryService };
}

import {
  CLEAR_SETTLEMENT_REPORT,
  GET_SETTLEMENT_REPORT,
} from "../../ReduxKeys";

const settlementGraphInitialState = {
  items: [],
};

/**
 * Store settlement graph list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SettlementGraphReducer = (
  state = settlementGraphInitialState,
  action
) => {
  switch (action.type) {
    case GET_SETTLEMENT_REPORT:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SETTLEMENT_REPORT:
      return settlementGraphInitialState;
    default:
      return state;
  }
};
import { CLEAR_VENDOR_FILTER, GET_VENDOR_FILTER } from "../../ReduxKeys";

/**
 * set vendor filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getVendorFilterData = ({
  orderNo,
  orderStartDate,
  orderEndDate,
  status,
  payment,
  vendor,
}) => ({
  type: GET_VENDOR_FILTER,
  payload: { orderNo, orderStartDate, orderEndDate, status, payment, vendor },
});

/**
 * clear vendor filtered data
 * @returns {*}
 */
export const clearVendorFilterData = () => ({
  type: CLEAR_VENDOR_FILTER,
});
import {
  CLEAR_SUB_CATEGORY_DATA,
  CLEAR_TOTAL_SUB_CATEGORY_DATA,
  GET_SUB_CATEGORY_DATA,
  GET_TOTAL_SUB_CATEGORY_DATA,
} from "../../ReduxKeys";

/**
 * Set sub-category list data into reducer
 * @param {*} subCategory
 * @returns {*}
 */
export const getSubCategoryData = (subCategory) => ({
  type: GET_SUB_CATEGORY_DATA,
  payload: subCategory,
});

/**
 * Clear sub-category list data
 * @returns {*}
 */
export const clearSubCategoryData = () => ({
  type: CLEAR_SUB_CATEGORY_DATA,
});

/**
 * Set total sub-category list data into reducer (No pagination)
 * @param {*} subCategory
 * @returns {*}
 */
export const getTotalSubCategoryData = (subCategory) => ({
  type: GET_TOTAL_SUB_CATEGORY_DATA,
  payload: subCategory,
});

/**
 * Clear total sub-category list data (No pagination)
 * @returns {*}
 */
export const clearTotalSubCategoryData = () => ({
  type: CLEAR_TOTAL_SUB_CATEGORY_DATA,
});
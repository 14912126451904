export const PurchaseRequisitionReqBody = (item, status) => {
  
    if (!item || !item.ingredients) {
      console.error("Invalid item data");
      return null;
    }
    
    const ingredients = JSON.parse(item.ingredients);
    const transformedIngredients = ingredients.map((ingredient) => ({
      ingredient_id: ingredient.ingredient_id,
      ingredient_rejection_reason: ingredient.rejection_reason,
      value: ingredient.value,
      unit: ingredient.unit,
      ingredient_status: ingredient.status,
      ingredient_instruction:ingredient.ingredientinstruction
    }));
    
    return {
      p_id: item.id,
      p_vendor_id:item.vendor_user_id,
      p_instruction: item.instructions,
      p_product_data: transformedIngredients,
      p_status: (status) ? status : item.orderStatus,
      p_assign_user_id:item.assign_user_id,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_assign_phone_number:item.assign_user_phone_number
    };
  };
  
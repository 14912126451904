import React from "react";
import "../WalletWarning/WalletWarning.scss";
import { useTranslation } from "react-i18next";

const WalletWarning = ({ props }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="warning">
      <span>
        {t("wallet_balance")}
      </span>
    </div>
  );
};

export default WalletWarning;

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const ConfirmationModal = () => {
  const { t } = useTranslation();

    const confirmSaveClick = (closeMainModal) => {
      Swal.fire({
        title: t("discard_changes"),
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: t("lable_yes"),
        cancelButtonText: t("lable_no"),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          closeMainModal(false);
        } 
      });
    };

  return {confirmSaveClick};
};

export default ConfirmationModal;
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { modifyString } from "../../../services/Regular-services/StringService";
import CategoryService from "../../Category/Category-service/CategoryService";
import { clearNewVendorPageData } from "../../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { useTranslation } from "react-i18next";
import NewVendorPersonService from "../NewVendorPersonService/NewVendorPersonService";

const AddNewVendorPerson = () => {
  const { getAllCategoryService } = CategoryService();
  const { addUpdateNewVendorPersonService } = NewVendorPersonService();
  const { t } = useTranslation();
  
  const validationSchema = Yup.object({
    shopName: Yup.string().required(t(ValidationMessage.SHOP_NAME_REQUIRED)),
    firstName: Yup.string().required(t(ValidationMessage.FIRST_NAME_REQUIRED)),
    lastName: Yup.string().required(t(ValidationMessage.LAST_NAME_REQUIRED)),
    mobile: Yup.string()
      .required(t(ValidationMessage.MOBILE_REQUIRED))
      .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
    category: Yup.array().min(1, t(ValidationMessage.MULTI_CATEGORY_SELECT)),
  });
  const [initialValues, setInitialValues] = useState({
    shopName: "",
    firstName: "",
    lastName: "",
    mobile: "",
    category: [],
    status: 1,
    smsAllow: 0,
  });
  const location = useLocation();
  const { Data } = location.state || {};
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [newVendorActionData, setNewVendorActionData] = useState([]);
  const categoryListData = useSelector((state) => state.totalCategory);
  // const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const { handlesaveClick } = AlertMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const localisation = useSelector((state) => state.localization?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "vendor-person"
      );
      setNewVendorActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        id: Data.data.id,
        firstName: Data.data.first_name,
        lastName: Data.data.last_name,
        shopName: Data.data.shopName,
        mobile: Data.data.mobile,
        status:
          Data.data.status === true ||
          Data.data.status === 1 ||
          Data.data.status === "1"
            ? 1
            : 0,
        smsAllow:
          Data.data.wp_sms_allow === true ||
          Data.data.wp_sms_allow === "1" ||
          Data.data.wp_sms_allow === 1
            ? 1
            : 0,
        is_deleted: Data.data.isDeleted,
        category: Data.data.categoryData
          ? JSON.parse(Data.data.categoryData).map((item) => item.category_id)
          : [],
      });
    }
  }, [Data]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Fetching category data
   */
  const fetchCategoryData = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllCategoryService(categoryRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    fetchCategoryData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (categoryListData?.items?.length
    ) {
      if (
        categoryListData.items[0]?.data &&
        categoryListData.items[0].data.length > 0
      ) {
        const filteredData = categoryListData.items[0].data.filter(
          (item) => item.type === "4" || item.type === 4
        );
        const categoryOptions = filteredData.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
        }));
        setCategoryOptionValue(categoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [categoryListData]);

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    const categoryData =
      values.category && values.category.length > 0
        ? values.category.map((item) => ({
            category_id: item,
          }))
        : [];
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_first_name: values.firstName,
      p_last_name: values.lastName,
      p_shop_name: values.shopName,
      p_mobile_no: values.mobile,
      p_category_ids: categoryData,
      p_status:
        values.status === true ||
        values.status === 1 ||
        values.status === "1" ||
        values.status === 1 ||
        values.status === "true"
          ? "1"
          : "0",
      p_is_wp_allow:
        values.smsAllow === true ||
        values.smsAllow === "1" ||
        values.smsAllow === 1 ||
        values.smsAllow === "true"
          ? "1"
          : "0",
      ...(Data && Data.data && { p_deleted: values.is_deleted }),
    };
    dispatch(clearNewVendorPageData());
    if (!Data) {
      dispatch(
        addUpdateNewVendorPersonService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdateNewVendorPersonService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {newVendorActionData && newVendorActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          newVendorActionData[0].name,
                          localisation
                        )
                      )
                    : t("Vendor")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/vendor-person")}>
                  {newVendorActionData && newVendorActionData.length > 0
                    ? getRouteNameFromSidebar(
                        newVendorActionData[0].name,
                        localisation
                      )
                    : t("Vendors")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {newVendorActionData && newVendorActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          newVendorActionData[0].name,
                          localisation
                        )
                      )
                    : t("Vendor")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {newVendorActionData && newVendorActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      newVendorActionData[0].name,
                      localisation
                    )
                  )
                : t("Vendor")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("first_name")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        placeholder={t("Placeholder_vendor_first_name")}
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("last_name")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        placeholder={t("Placeholder_vendor_last_name")}
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_mobile_no")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="mobile"
                        label="Mobile No"
                        placeholder={t("Placeholder_mobile_no")}
                        className="add-roles"
                        value={values.mobile}
                        Regex={/[^0-9\s]/g}
                        disabled={Data}
                        maxLength={10}
                        hidelength={"true"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_shop_name")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="shopName"
                        label="Shop Name"
                        placeholder={t("Placeholder_owner_name")}
                        className="add-roles"
                        Regex={/[^a-zA-Z0-9\s]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_category_name")}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="category"
                        options={categoryOptionValue}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={true}
                        disabled={Data && Data.isEdit === false}
                        value={values.category}
                        Regex={/[^a-zA-Z\s]/g}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">{t("status")}</h4>
                      <Field
                        name="status"
                        className="toggle"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("whatsApp_message")}
                      </h4>
                    </div>
                    <Field
                      name="smsAllow"
                      setFieldValue={setFieldValue}
                      component={Toggle}
                      disabled={Data && Data.isEdit === false}
                    ></Field>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() => navigate("/vendor-person")}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default AddNewVendorPerson;

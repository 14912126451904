import { CLEAR_EVENT_LOG_DATA, GET_EVENT_LOG_DATA } from "../../ReduxKeys";

const eventLogInitialState = {
  items: [],
};

/**
 * Store event list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const EventLogReducer = (state = eventLogInitialState, action) => {
  switch (action.type) {
    case GET_EVENT_LOG_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_EVENT_LOG_DATA:
      return eventLogInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import "../../Inventory/AddInventory/AddInventory.scss";
import { useDispatch, useSelector } from "react-redux";
import PackagerServices from "../PackagerService/PackagerService";

export default function AddPackager() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addUpdatePackagerService } = PackagerServices();

  //Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  type: Yup.string().required(ValidationMessage.TYPE_SELECT),
  address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
  pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
});

  const user = useSelector((state) => state.auth.user);
  const packagerPage = useSelector((state) => state.packagerPage?.items);
  const tokenData = UtilsData();
  const { handleCancleClick, handlesaveClick } = AlertMessage();

  //Setting initial values
  const [initialValues, setInititialValues] = useState({
    type: "",
    name: "",
    address: "",
    pincode: "",
    status: true,
  });

  //Getting data from packager-list page
  const location = useLocation();
  const { Data } = location.state || {};

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [packagerActionData, setpackagerActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "packager-and-manufacturer"
      );
      setpackagerActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      setInititialValues({
        type: Data.data.type,
        name: Data.data.name,
        address: Data.data.address,
        pincode: Data.data.pincode,
        status:
          Data.data.is_active === "1" ||
          Data.data.is_active === 1 ||
          Data.data.is_active === true
            ? true
            : false,
      });
    }
  }, [Data]);

  const typeOptions = [
    { value: "0", label: "Manufacturer" },
    { value: "1", label: "Packager" },
    { value: "2", label: "Importer" },
  ];

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    if (!Data) {
      const requestBody = {
        p_name: values.name,
        p_address: values.address,
        p_type: values.type,
        p_pincode: values.pincode,
        p_isActive: values.status === true ? "1" : "0",
        p_created_by: user.data.userId,
      };
      dispatch(
        addUpdatePackagerService(requestBody, tokenData, "add", navigate)
      );
    } else {
      const requestBody = {
        p_id: Data.data.productPackagerId,
        p_name: values.name,
        p_address: values.address,
        p_type: values.type,
        p_pincode: values.pincode,
        p_isActive: values.status === true ? "1" : "0",
        p_IsDeleted: Data.data.IsDeleted,
        p_modified_by: user.data.userId,
      };
      if (Data?.length && packagerPage > 1) {
        if (
          values.status === "0" ||
          values.status === 0 ||
          values.status === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdatePackagerService(
                requestBody,
                tokenData,
                "update",
                navigate,
                packagerPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdatePackagerService(
                requestBody,
                tokenData,
                "update",
                navigate,
                packagerPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdatePackagerService(
              requestBody,
              tokenData,
              "update",
              navigate,
              packagerPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdatePackagerService(requestBody, tokenData, "update", navigate),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className=" add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {packagerActionData && packagerActionData.length > 0
                    ? packagerActionData[0].name
                    : "Packager and Manufacturer"}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate("/packager-and-manufacturer")}
                >
                  {packagerActionData && packagerActionData.length > 0
                    ? packagerActionData[0].name
                    : "Packager and Manufacturer"}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {packagerActionData && packagerActionData.length > 0
                    ? packagerActionData[0].name
                    : "Packager and Manufacturer"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? "Edit "
                : Data && Data.isEdit === false
                ? "View "
                : "Add "}{" "}
              {packagerActionData && packagerActionData.length > 0
                ? packagerActionData[0].name
                : "Packager and Manufacturer"}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        Type<span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="type"
                        options={typeOptions}
                        component={CustomSelect}
                        placeholder="Select..."
                        isMulti={false}
                        value={values.type}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Name <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Enter Name"
                        className="add-roles"
                        maxLength={20}
                        Regex={/[^a-zA-Z\s]/g}
                        value={values.name}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Address <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="address"
                        label="Address"
                        placeholder="Enter Address"
                        className="add-roles"
                        maxLength={70}
                        Regex={/[^a-zA-Z0-9/\-//\s]/g}
                        value={values.address}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Pincode <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="pincode"
                        label="Pincode"
                        placeholder="Enter Pincode"
                        className="add-roles"
                        maxLength={6}
                        Regex={/[^0-9]/g}
                        value={values.pincode}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Status</h4>
                      <Field
                        name="status"
                        className="toggle"
                        component={Toggle}
                        setFieldValue={setFieldValue}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name="Save"
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name="Cancel"
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick(
                                  "/packager-and-manufacturer",
                                  navigate
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

import {
  CLEAR_BRANCH_DATA,
  CLEAR_TOTAL_BRANCH,
  GET_BRANCH_DATA,
  GET_TOTAL_BRANCH,
} from "../../ReduxKeys";

const branchInitialState = {
  items: [],
};

/**
 * Store branch list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const BranchReducer = (state = branchInitialState, action) => {
  switch (action.type) {
    case GET_BRANCH_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_BRANCH_DATA:
      return branchInitialState;
    default:
      return state;
  }
};

const totalBranchInitialState = {
  items: [],
};

/**
 * Store total branch list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalBranchReducer = (state = totalBranchInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_BRANCH:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_BRANCH:
      return totalBranchInitialState;
    default:
      return state;
  }
};
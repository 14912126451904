import { CLEAR_EXPENSE_FILTER, GET_EXPENSE_FILTER } from "../../ReduxKeys";

/**
 * Setting expense filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getExpenseFilterData = ({
  title,
  amount,
  category,
  startDate,
  endDate,
}) => ({
  type: GET_EXPENSE_FILTER,
  payload: { title, amount, category, startDate, endDate },
});

/**
 * Clearing expense filtered data
 * @returns {*}
 */
export const clearExpenseFilterData = () => ({
  type: CLEAR_EXPENSE_FILTER,
});
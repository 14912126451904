import { orderDate } from "../../../services/Regular-services/DateService";
import {
  CLEAR_DASHBOARD_ORDER_FILTER,
  GET_DASHBOARD_ORDER_FILTER,
} from "../../ReduxKeys";

const orderFilterInitialState = {
  orderDate: orderDate(),
  status: "",
  orderType: "",
};

/**
 * Store order filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const DashboardOrderFilterReducer = (
  state = orderFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_ORDER_FILTER:
      return {
        ...state,
        orderDate: action.payload.orderDate,
        status: action.payload.status,
        orderType: action.payload.orderType,
      };
    case CLEAR_DASHBOARD_ORDER_FILTER:
      return orderFilterInitialState;
    default:
      return state;
  }
};
import React from "react";
import "./ForgotPassword.scss";
import Loginbanner from "../../components/Loginbanner/Loginbanner";
import Button from "../../components/Button/Button";
import Input from "../../components/input/Input";
import { FormGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Regex, ValidationMessage } from "../../Utils/Utils";
import { useDispatch } from "react-redux";
import NewLoginService from "../../services/API-services/generateToken";

const emailRegex = Regex.EmailRegex; //Email Regex

//Initial values
const initialValues = {
  email: "",
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchToken } = NewLoginService();

  //Validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .required(ValidationMessage.EMAIL_REQUIRED)
      .max(64, ValidationMessage.MAX_EMAIL_LIMIT)
      .matches(emailRegex, ValidationMessage.INVALID_EMAIL),
  });

  //Handling form submission => Generate Token and Redirect to request Otp Page
  const onSubmit = (values) => {
    dispatch(fetchToken(values, "requestOtp", navigate));
  };
  return (
    <>
      <section className="login-form">
        <div className="row w-100 m-0">
          <div className="col-lg-6 p-0 d-lg-flex d-none">
            {/* left side image section start  */}
            <Loginbanner></Loginbanner>
            {/* left side image section end  */}
          </div>
          <div className="col-lg-6 col-12 p-0">
            {/* Right side login form section end  */}

            <div className="right-side-form">
              <div className="logo d-lg-none d-flex">Ecommerce</div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form className="wrapper label-p-0 bg-layer">
                  <h2>Forgot Password</h2>
                  <span className="forgotpassword">
                    Enter the email address associated with your account and
                    we'll send OTP to reset your password.
                  </span>

                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      isRequired={true}
                      maxLength={65}
                      hidelength={"true"}
                    />
                    <div className="back">
                      <Button
                        type="submit"
                        name="Get OTP"
                        className=" btn login-btn"
                      />
                      <div className="forget-password">
                        <Link to="/Login">Back to login</Link>
                      </div>
                    </div>
                  </FormGroup>
                </Form>
              </Formik>
            </div>
            {/* Right side login form section end  */}
          </div>
        </div>
      </section>
    </>
  );
}

import {
  CLEAR_PRODUCT_INVENTORY_PAGE,
  GET_PRODUCT_INVENTORY_PAGE,
} from "../../ReduxKeys";

/**
 * Setting product inventory page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getProductInventoryPageData = (page) => ({
  type: GET_PRODUCT_INVENTORY_PAGE,
  payload: page,
});

/**
 * clearing product inventory page data
 * @returns {*}
 */
export const clearProductInventoryPageData = () => ({
  type: CLEAR_PRODUCT_INVENTORY_PAGE,
});
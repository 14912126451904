import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UtilsData, { getRouteNameFromSidebar } from "../../../Utils/Utils";
import { Breadcrumb } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Button from "../../../components/Button/Button";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import "../AddPurchaseRequisition/AddPurchaseRequisition.scss";
import { toast } from "react-toastify";
import IngredientCard from "../../../components/IngredientCard/IngredientCard";
import { modifyString } from "../../../services/Regular-services/StringService";
import UserService from "../../AdminUsers/User-service/UserService";
import { clearPurchaseRequisitionPageData } from "../../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionPageAction";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import IngredientService from "../../InventoryItems/InventoryItemService/InventoryItemService";
import NewVendorPersonService from "../../NewVendorPerson/NewVendorPersonService/NewVendorPersonService";
import PurchaseRequisitionServices from "../PurchaseRequisitionService/PurchaseRequisitionService";

const AddPurchaseRequisition = () => {
  const { getAllInventoryItemService } = IngredientService();
  const { getPRUserListService } = UserService();
  const { getAllNewVendorPersonService } = NewVendorPersonService();
  const { addUpdatePurchaseRequisitionService } = PurchaseRequisitionServices();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    vendor: "",
    user: "",
    user_phoneNumber: "",
    status: "0",
    message: "",
  });
  const validationSchema = Yup.object({
    vendor: Yup.string().required("Vendor is required"),
    user: Yup.string().required("Field is required!"),
  });
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [purchaseRequisitionActionData, setPurchaseRequisitionActionData] =
    useState([]);
  const [vendorPersonOptions, setVendorPersonOption] = useState([]);
  const totalVendorPerson = useSelector((state) => state.totalNewVendor);
  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [totalInventory, setTotalInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [addedInventory, setAddedInventory] = useState([]);
  const adminUserList = useSelector((state) => state.adminPR);
  const [userOptions, setUserOptions] = useState([]);
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const localisation = useSelector((state) => state.localization?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "purchase-requisition"
      );
      setPurchaseRequisitionActionData(actionData);
    }
  }, [sideBarData]);

  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };
  useEffect(() => {
    if (Data && Data.data) {
      const ingredientVals = JSON.parse(Data.data.ingredients);
      const addedIngredientVals = ingredientVals.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.value);
        let unit = item.unit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }
        return {
          ingredientName: item?.ingredientTitle,
          IngredientId: item?.ingredient_id,
          quantity: quantityInOriginalUnit,
          unit: unit,
          instruction: item.rejection_reason,
          generalInstruction: item.pr_ingredient_instruction,
          status: item.status,
          category_id: item?.category_id,
          category_name: item?.category_name,
        };
      });
      if (vendorPersonOptions && vendorPersonOptions.length > 0) {
        const vendor = vendorPersonOptions.find(
          (item) => item.value === Data.data.vendor_user_id
        );
        if (vendor) {
          const categoryIds = vendor.categoryData.map((cat) => cat.category_id);
          const ingredients = totalInventory;
          const newIngredients = ingredients.filter((item) =>
            categoryIds.includes(item.category_id)
          );
          const filteredNewIngredients = newIngredients.filter(
            (newIng) =>
              !addedIngredientVals.some(
                (addedIng) => addedIng.IngredientId === newIng.IngredientId
              )
          );
          setFilteredInventory(filteredNewIngredients);
          setAddedInventory(addedIngredientVals);

          setInitialValues({
            id: Data.data.id,
            vendor: Data.data.vendor_user_id,
            user: Data.data.assign_user_id,
            status: Data.data.orderStatus,
            message: Data.data.instructions,
            user_phoneNumber: Data.data.assign_user_phone_number,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [Data, vendorPersonOptions]);

  useEffect(() => {
    if (
      totalInventoryItem &&
      totalInventoryItem.items &&
      totalInventoryItem.items.length > 0
    ) {
      const newInventoryItem = totalInventoryItem.items[0].data.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.thresoldValue);
        let unit = item.thresoldUnit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }
        return {
          ingredientName: item?.title,
          IngredientId: item?.IngredientId,
          quantity: "0",
          unit: unit,
          instruction: "",
          generalInstruction: item.instruction,
          status: "0",
          //   ingredient_price: "0",
          category_id: item?.category_id,
          category_name: item?.category_name,
        };
      });
      setTotalInventory(newInventoryItem);
    } else {
      fetchInventoryItem();
    }
    // eslint-disable-next-line
  }, [totalInventoryItem]);

  /**
   * Fetching vendor data
   */
  const fetchVendorPerson = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
      p_isActive: "1",
    };
    getAllNewVendorPersonService(categoryRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalVendorPerson &&
      totalVendorPerson.items &&
      totalVendorPerson.items.length === 0
    ) {
      fetchVendorPerson();
    } else {
      if (
        totalVendorPerson.items[0]?.data &&
        totalVendorPerson.items[0].data.length > 0
      ) {
        const newVendorOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.first_name + " " + item.last_name,
            categoryData: JSON.parse(item.categoryData),
          })
        );
        setVendorPersonOption(newVendorOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalVendorPerson]);

  /**
   * Fetch user data
   */
  const fetchUsers = () => {
    const requestData = {
      skip: 0,
      take: 100000,
    };
    getPRUserListService(requestData, token, dispatch, true);
  };

  useEffect(() => {
    if (
      adminUserList &&
      adminUserList.items &&
      adminUserList.items.length === 0
    ) {
      fetchUsers();
    } else if (
      adminUserList.items[0]?.data &&
      adminUserList.items[0].data.length > 0
    ) {
      const transformedAdminData = adminUserList.items[0].data.map((item) => ({
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
        phoneNumber: item.phoneNumber,
      }));
      setUserOptions(transformedAdminData);
    } else {
      setUserOptions([]);
    }
    // eslint-disable-next-line
  }, [adminUserList]);

  const tooltipRef = useRef(null);
  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const fetchCategoryIngredients = (option, setFieldValue) => {
    const categoryIds = option.categoryData.map((cat) => cat.category_id);
    const ingredients = totalInventory;
    const newIngredients = ingredients.filter((item) =>
      categoryIds.includes(item.category_id)
    );
    setFilteredInventory(newIngredients);
    setFieldValue("vendor", option.value);
    setAddedInventory([]);
  };
  const handleAddIngredient = (ingredient) => {
    setAddedInventory((prev) => [...prev, ingredient]);
    const newFilteredInventory = filteredInventory.filter(
      (item) => item.IngredientId !== ingredient.IngredientId
    );
    setFilteredInventory(newFilteredInventory);
    toast.success("Ingredient is added");
  };
  const handleRemoveIngrediant = (ingredient) => {
    const updatedInventory = addedInventory.filter(
      (item) => item.IngredientId !== ingredient.IngredientId
    );
    setAddedInventory(updatedInventory);
    const newIngredient = totalInventory.find(
      (item) => ingredient.IngredientId === item.IngredientId
    );
    if (newIngredient) {
      setFilteredInventory((prev) => [...prev, newIngredient]);
    }
    toast.success("Ingredient is removed");
  };
  const handleUpdateIngredient = (updatedIngredient) => {
    setAddedInventory((prevData) =>
      prevData.map((item) => {
        return item.IngredientId === updatedIngredient.IngredientId
          ? updatedIngredient
          : item;
      })
    );
  };
  const handleApproveAll = () => {
    const isApprovedAll = addedInventory.every((item) => item.status === "1");
    if (!isApprovedAll) {
      const updatedIngredients = addedInventory.map((item) => {
        return {
          ...item,
          instruction: "",
          status: "1",
        };
      });
      setAddedInventory(updatedIngredients);
      toast.success("all Items are approved!");
    } else {
    }
  };
  const handleRejectAll = () => {
    const isRejectedAll = addedInventory.every((item) => item.status === "2");
    if (!isRejectedAll) {
      const updatedIngredients = addedInventory.map((item) => {
        return {
          ...item,
          status: "2",
        };
      });
      setAddedInventory(updatedIngredients);
      toast.success("all Items are Rejected!");
    } else {
      toast.error("all Items are Rejected!");
    }
  };

  const handleUser = (e, setFieldValue) => {
    setFieldValue("user", e);
    const user =
      adminUserList &&
      adminUserList?.items[0] &&
      adminUserList.items[0]?.data &&
      adminUserList.items[0].data.find((item) => item.userId === e);
    setFieldValue("user_phoneNumber", user.phoneNumber);
  };

  const handleSubmit = (values) => {
    const ingredientsWithZeroQuantity = addedInventory.filter((ingredient) => {
      return (
        ingredient.quantity === 0 ||
        ingredient.quantity === "0" ||
        ingredient.quantity === ""
      );
    });

    if (ingredientsWithZeroQuantity.length > 0) {
      const ingredientNames = ingredientsWithZeroQuantity
        .map((ingredient) => ingredient.ingredientName)
        .join(", ");
      toast.error(
        `Please add quantity for the following ingredients: ${ingredientNames}`
      );
      return;
    }

    const hasApproved = addedInventory.some((item) => item.status === "1");
    const hasRejected = addedInventory.some((item) => item.status === "2");
    const hasPending = addedInventory.some((item) => item.status === "0");
    const hasAllPending = addedInventory.every((item) => item.status === "0");
    const partiallyApproved = addedInventory.some(
      (item) => item.status === "1" || item.status === "2"
    );
    const hasAllApproved = addedInventory.every((item) => item.status === "1");
    const hasAllRejected = addedInventory.every((item) => item.status === "2");
    if (
      hasPending &&
      (hasApproved || hasRejected) &&
      !(hasApproved && hasRejected)
    ) {
      toast.error("Please ensure all items' status are approved or rejected");
      return;
    }
    const emptyRejectMsg = addedInventory.some(
      (item) => item.status === "2" && item.instruction === ""
    );
    if (emptyRejectMsg) {
      toast.error("Please ensure all rejected items have a reject msg");
      return;
    }

    // changing the status value
    if (hasAllApproved) {
      values.status = "1";
    } else if (hasAllRejected) {
      values.status = "3";
    } else if (hasAllPending) {
      values.status = "0";
    } else if (partiallyApproved) {
      values.status = "2";
    }

    const ingredientData = addedInventory.map((item) => {
      let { quantityInBaseUnit, unit } = convertToBaseUnit(
        item.quantity,
        item.unit
      );
      return {
        ingredient_id: item.IngredientId,
        ingredient_rejection_reason: item.instruction ? item.instruction : "",
        value: String(quantityInBaseUnit),
        unit: unit,
        ingredient_status: item.status,
        ingredient_instruction: item.generalInstruction,
      };
    });
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_vendor_id: values.vendor,
      p_assign_user_id: values.user,
      p_instruction: values.message,
      p_product_data: ingredientData,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_assign_phone_number: values.user_phoneNumber,
      p_status: values.status,
    };

    dispatch(clearPurchaseRequisitionPageData());
    if (!Data) {
      dispatch(
        addUpdatePurchaseRequisitionService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdatePurchaseRequisitionService(
          requestBody,
          token,
          "update",
          navigate
        ),
        "update"
      );
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {purchaseRequisitionActionData &&
                  purchaseRequisitionActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          purchaseRequisitionActionData[0].name,
                          localisation
                        )
                      )
                    : t("Purchase Requisition")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate("/purchase-requisition")}
                >
                  {purchaseRequisitionActionData &&
                  purchaseRequisitionActionData.length > 0
                    ? getRouteNameFromSidebar(
                        purchaseRequisitionActionData[0].name,
                        localisation
                      )
                    : t("Purchase Requisition")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {purchaseRequisitionActionData &&
                  purchaseRequisitionActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          purchaseRequisitionActionData[0].name,
                          localisation
                        )
                      )
                    : t("Purchase Requisition")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {purchaseRequisitionActionData &&
              purchaseRequisitionActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      purchaseRequisitionActionData[0].name,
                      localisation
                    )
                  )
                : t("Purchase Requisition")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="add-roles-form row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_vendor")}
                            <span className="required-label"></span>
                          </h4>
                          <Field
                            className="select-menu mb-2"
                            name="vendor"
                            options={vendorPersonOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_Select")}
                            isMulti={false}
                            disabled={Data}
                            value={values.vendor}
                            Regex={/[^a-zA-Z\s]/g}
                            styles={customStyles}
                            onchangedata={(option) =>
                              fetchCategoryIngredients(option, setFieldValue)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              {t("lable_user")}
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Field
                            className="select-menu mb-2"
                            name="user"
                            options={userOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_Select")}
                            isMulti={false}
                            value={values.user}
                            disabled={Data}
                            changeData={(e) => handleUser(e, setFieldValue)}
                          />
                        </div>
                      </div>
                      <div className="pb-1">
                        <div className="message-input">
                          <textarea
                            value={values.message}
                            placeholder={t(
                              "Placeholder_generalized_instruction"
                            )}
                            onChange={(event) =>
                              setFieldValue("message", event.target.value)
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>

                      <div className="row">
                        {filteredInventory && filteredInventory.length > 0 && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ingredient-order">
                              <div className="row">
                                {filteredInventory.map((item, index) => (
                                  <div
                                    key={index}
                                    className="col-lg-6 col-12"
                                    onClick={() => {
                                      if (Data && Data.isEdit === true) {
                                        handleAddIngredient(item);
                                      } else if (!Data) {
                                        handleAddIngredient(item);
                                      }
                                    }}
                                  >
                                    <IngredientCard
                                      ingredientData={item}
                                      addedInventory={addedInventory}
                                      handleAddIngredient={handleAddIngredient}
                                      filteredInventory={filteredInventory}
                                      showAddBtn={true}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {addedInventory && addedInventory.length > 0 ? (
                          <div
                            className={`${
                              filteredInventory.length !== 0
                                ? "col-lg-6 col-md-6 col-sm-12 col-12"
                                : "col-12"
                            }`}
                          >
                            <div className="new-ingrediant-detail">
                              <h6 className="order-detail-heading">
                                {t("addOrders.order_details")}
                              </h6>
                              {Data && Data.isEdit === true && (
                                <div>
                                  <div className="btn-container">
                                    {addedInventory.some(
                                      (item) =>
                                        item.status === "0" ||
                                        item.status === "2"
                                    ) && (
                                      <button
                                        data-bs-toggle="tooltip"
                                        title="Approve All"
                                        className="btn-new"
                                        type="button"
                                        onClick={handleApproveAll}
                                      >
                                        {t("button_approveall")}
                                      </button>
                                    )}

                                    {addedInventory.some(
                                      (item) =>
                                        item.status === "0" ||
                                        item.status === "1"
                                    ) && (
                                      <button
                                        data-bs-toggle="tooltip"
                                        title="Reject All"
                                        className="btn-new"
                                        type="button"
                                        onClick={handleRejectAll}
                                      >
                                        {t("button_rejectall")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div
                                  className={`${
                                    filteredInventory.length !== 0
                                      ? "col-12"
                                      : "col-6"
                                  }`}
                                >
                                  {addedInventory.map((item, index) => (
                                    <div
                                      key={index}
                                      className={`col-12 ${
                                        filteredInventory.length === 0
                                          ? "col-6"
                                          : "col-12"
                                      }`}
                                    >
                                      <IngredientCard
                                        addedIngredientData={item}
                                        handleRemoveIngrediant={
                                          handleRemoveIngrediant
                                        }
                                        handleUpdateIngredient={
                                          handleUpdateIngredient
                                        }
                                        addedInventory={addedInventory}
                                        filteredInventory={filteredInventory}
                                        Data={Data}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {filteredInventory.length > 0 && (
                              <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12"
                                style={{
                                  backgroundColor: "rgb(194, 191, 191)",
                                  // border: '1px solid', // Specify solid or other style here
                                  borderRadius: "10px",
                                }}
                              >
                                <div className="no-data-card">
                                  <h6 className="no-data-title">
                                    {t("no_products_added")}
                                  </h6>
                                  <p className="no-data-description">
                                    {t("no_products_added_massage")}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {addedInventory.length > 0 && (
                        <div className="col-12">
                          <div className="paragraph-editor">
                            <div className="btn-submit mt-3">
                              {((Data && Data.isEdit === true) || !Data) && (
                                <>
                                  <Button
                                    type="submit"
                                    name="Save"
                                    className="btn login-btn"
                                  />
                                  <Button
                                    type="button"
                                    name="Cancel"
                                    className="btn clear-btn login-btn"
                                    onClick={() =>
                                      handleCancleClick(
                                        "/purchase-requisition",
                                        navigate
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default AddPurchaseRequisition;

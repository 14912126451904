import { CLEAR_PRODUCT_PAGE, GET_PRODUCT_PAGE } from "../../ReduxKeys";

/**
 * Set product page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getProductPageData = (page) => ({
  type: GET_PRODUCT_PAGE,
  payload: page,
});

/**
 * Clear product page data
 * @returns {*}
 */
export const clearProductPageData = () => ({
  type: CLEAR_PRODUCT_PAGE,
});
import { CLEAR_COMPANY_FILTER, GET_COMPANY_FILTER } from "../../ReduxKeys";

/**
 * Set company filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getCompanyFilterData = ({
  p_company_name,
  p_address,
  p_pincode,
  p_IsDeleted,
  p_is_active,
}) => ({
  type: GET_COMPANY_FILTER,
  payload: { p_pincode, p_address, p_company_name, p_is_active, p_IsDeleted },
});

/**
 * Clear company filtered data
 * @returns {*}
 */
export const clearCompanyFilterData = () => ({
  type: CLEAR_COMPANY_FILTER,
});
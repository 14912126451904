import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "../../../components/Toggle/Toggle";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { modifyString } from "../../../services/Regular-services/StringService";
import { useTranslation } from "react-i18next";
import LeafLevelCategoryService from "../LeafLevelService/LeafLevelService";
import SubCategoryServices from "../../SubCategory/SubCategoryService/SubCategoryService";

export default function AddLeafLevelCategory() {
  const { addUpdateLeafLevelService, getLeafCategoryById } =
    LeafLevelCategoryService();
  const { getAllSubCategoryService } = SubCategoryServices();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    leafName: "",
    parentCategory: "",
    upload: [],
    description: "",
    status: true,
  });

  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState(false);
  const leafPage = useSelector((state) => state.leafPage?.items);
  const user = useSelector((state) => state.auth.user);
  const tokenData = UtilsData();
  const [errorImages, setErrorImages] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [leafActionData, setleafActionData] = useState([]);
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const localisation = useSelector((state) => state.localization?.items);

  //Validation schema
  const validationSchema = Yup.object({
    leafName: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
    parentCategory: Yup.string().required(
      t(ValidationMessage.SINGLE_CATEGORY_SELECT)
    ),
    upload: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
    description: Yup.string().required(
      t(ValidationMessage.DESCRIPTION_REQUIRED)
    ),
  });
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "leaf-level-category"
      );
      setleafActionData(actionData);
    }
  }, [sideBarData]);

  //Getting data from reducer
  const navigate = useNavigate();

  //Getting data from leaf-level-category-list page
  const location = useLocation();
  const { Data } = location.state || {};

  const token = UtilsData();
  const subCategoryListData = useSelector((state) => state.totalSubCategory);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  /**
   * Fetch Sub category data
   */
  const fetchSubCategoryData = () => {
    const subCategoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllSubCategoryService(subCategoryRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      subCategoryListData &&
      subCategoryListData.items &&
      subCategoryListData.items.length === 0
    ) {
      fetchSubCategoryData();
    } else {
      if (
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active,
          })
        );
        setSubCategoryListOptions(subCategoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [subCategoryListData]);

  /**
   * Custom styles for React Select
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Fetching leaf level category detail data
   */
  const fetchLeafCategoryDetail = async () => {
    const leafDetail = await getLeafCategoryById(Data.data.id, token, dispatch);

    const leafImage = leafDetail.data.image_data
      ? parseImage(JSON.stringify(leafDetail.data.image_data))
      : [];
    const leafImageData = leafImage.map((item) => ({
      imageId: parseInt(item.id),
      url: item.url,
      imageType: parseInt(item.type),
    }));
    setShowImage(true);
    setInitialValues({
      leafName: leafDetail.data.Title,
      parentCategory: leafDetail.data.SubcategoryId,
      upload: leafImageData,
      description: leafDetail.data.Description,
      status:
        leafDetail.data.is_active === "1" ||
        leafDetail.data.is_active === true ||
        leafDetail.data.is_active === 1
          ? true
          : false,
    });
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchLeafCategoryDetail();
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    const imageIds = values.upload.map((item) => ({
      id: item.imageId,
      type_id: item.imageType,
    }));
    if (!Data) {
      const requestBody = {
        p_name: values.leafName,
        p_description: values.description,
        p_subcategoryId: values.parentCategory,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_createdBy: user.data.userId,
        p_imageIds: imageIds,
      };
      dispatch(
        addUpdateLeafLevelService(requestBody, tokenData, "add", navigate)
      );
    } else {
      const requestBody = {
        p_id: Data.data.id,
        p_name: values.leafName,
        p_description: values.description,
        p_subcategoryId: values.parentCategory,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_deleted: Data.data.isDeleted,
        p_createdBy: Data.data.createdBy,
        p_modifiedBy: user.data.userId,
        p_imageIds: imageIds,
      };
      if (Data?.length && leafPage > 1) {
        if (
          values.active === "0" ||
          values.active === 0 ||
          values.active === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateLeafLevelService(
                requestBody,
                tokenData,
                "update",
                navigate,
                leafPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateLeafLevelService(
                requestBody,
                tokenData,
                "update",
                navigate,
                leafPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateLeafLevelService(
              requestBody,
              tokenData,
              "update",
              navigate,
              leafPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateLeafLevelService(requestBody, tokenData, "update", navigate),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {leafActionData && leafActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          leafActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("Leaf Level Category"))}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate("/leaf-level-category")}
                >
                  {leafActionData && leafActionData.length > 0
                    ? getRouteNameFromSidebar(
                        leafActionData[0].name,
                        localisation
                      )
                    : t("Leaf Level Categories")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {leafActionData && leafActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          leafActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("Leaf Level Category"))}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {leafActionData && leafActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      leafActionData[0].name,
                      localisation
                    )
                  )
                : modifyString(t("Leaf Level Category"))}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Name")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="leafName"
                        label="Title"
                        placeholder={t("placeholder_enter_name")}
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        maxLength={20}
                        value={values.leafName}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_sub_category")}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="select-menu mb-2"
                        name="parentCategory"
                        options={subCategoryListOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.parentCategory}
                        disabled={Data && Data.isEdit === false}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="upload-image">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_leaf_level_category_image")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <UploadImage
                        name="upload"
                        onChange={setFieldValue}
                        value={values.upload}
                        disabled={Data && Data.isEdit === false}
                        filterByType={7}
                        setErrorImages={setErrorImages}
                        setShowImage={setShowImage}
                      />

                      <ShowImage
                        value={values.upload}
                        errorImages={errorImages}
                        showImage={showImage}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_description")}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        name="description"
                        component={CKEditorCpmponent}
                        maxLength={255}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">{t("status")}</h4>
                      <Field
                        name="status"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick(
                                  "/leaf-level-category",
                                  navigate
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

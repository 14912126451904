import {
  CLEAR_PURCHASE_REQUISITION_DATA,
  CLEAR_TOTAL_PURCHASE_REQUISITION_DATA,
  GET_PURCHASE_REQUISITION_DATA,
  GET_TOTAL_PURCHASE_REQUISITION_DATA,
} from "../../ReduxKeys";

const purchaseRequisitionInitialState = {
  items: [],
};

/**
 * Store PURCHASE REQUISITION list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PurchaseRequisitionReducer = (
  state = purchaseRequisitionInitialState,
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_REQUISITION_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_PURCHASE_REQUISITION_DATA:
      return purchaseRequisitionInitialState;
    default:
      return state;
  }
};

const totalPurchaseRequisitionInitialState = {
  items: [],
};

/**
 * Store total PURCHASE REQUISITION list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalPurchaseRequisitionReducer = (
  state = totalPurchaseRequisitionInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_PURCHASE_REQUISITION_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_PURCHASE_REQUISITION_DATA:
      return totalPurchaseRequisitionInitialState;
    default:
      return state;
  }
};
import { CLEAR_PACKAGER_PAGE, GET_PACKAGER_PAGE } from "../../ReduxKeys";

/**
 * set packager page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getPackagerPageData = (page) => ({
  type: GET_PACKAGER_PAGE,
  payload: page,
});

/**
 * clear packager page data
 * @returns {*}
 */
export const clearPackagerPageData = () => ({
  type: CLEAR_PACKAGER_PAGE,
});
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UtilsData, { ValidationMessage, toastClass } from "../../../Utils/Utils";
import { FaMinus, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import Toggle from "../../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import BranchService from "../../Branches/BranchService/BranchService";
import ServiceProviderServices from "../ServiceProviderService/ServiceProviderService";
import { useTranslation } from "react-i18next";

const timeOptions = [
  { value: 0, label: "12 AM (00:00)" },
  { value: 1, label: "01 AM (01:00)" },
  { value: 2, label: "02 AM (02:00)" },
  { value: 3, label: "03 AM (03:00)" },
  { value: 4, label: "04 AM (04:00)" },
  { value: 5, label: "05 AM (05:00)" },
  { value: 6, label: "06 AM (06:00)" },
  { value: 7, label: "07 AM (07:00)" },
  { value: 8, label: "08 AM (08:00)" },
  { value: 9, label: "09 AM (09:00)" },
  { value: 10, label: "10 AM (10:00)" },
  { value: 11, label: "11 AM (11:00)" },
  { value: 12, label: "12 PM (12:00)" },
  { value: 13, label: "01 PM (13:00)" },
  { value: 14, label: "02 PM (14:00)" },
  { value: 15, label: "03 PM (15:00)" },
  { value: 16, label: "04 PM (16:00)" },
  { value: 17, label: "05 PM (17:00)" },
  { value: 18, label: "06 PM (18:00)" },
  { value: 19, label: "07 PM (19:00)" },
  { value: 20, label: "08 PM (20:00)" },
  { value: 21, label: "09 PM (21:00)" },
  { value: 22, label: "10 PM (22:00)" },
  { value: 23, label: "11 PM (23:00)" },
];

export default function AddServiceProvider() {
  const { getAllBranchService } = BranchService();
  const { addUpdateServiceProviderService, getServiceProviderById } =
    ServiceProviderServices();

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Name must contain only letters and spaces"),
    granularity: Yup.string().required("Granularity is required"),
  });

  /**
   * Setting initial values
   */
  const [initialValues, setInitialValues] = useState({
    name: "",
    granularity: "",
    branch: "",
    day: "",
    start_time: "",
    end_time: "",
    status: true,
  });

  const navigate = useNavigate();
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const [branchtypeOptions, setBranchtypeOptions] = useState([]);
  const totalBranch = useSelector((state) => state.totalBranch);
  const serviceProviderPage = useSelector(
    (state) => state.ServiceProviderPage?.items
  );
  const [fieldArrays, setFieldArrays] = useState([]);

  /**
   * Getting data from service provider table
   */
  const location = useLocation();
  const { Data } = location.state || {};

  /**
   * Fetch service provider data by id
   */
  const fetchServiceProvider = async () => {
    try {
      // Call the getRoleById function
      const serviceProvider = await getServiceProviderById(
        Data.data.serviceProviderId,
        tokenData,
        dispatch
      );
      // Execute the returned async function to fetch the data

      if (serviceProvider && serviceProvider.status === "success") {
        setInitialValues({
          name: serviceProvider.data.name,
          granularity: serviceProvider.data.granularity,
          status:
            serviceProvider.data.isActive === true ||
            serviceProvider.data.isActive === 1 ||
            serviceProvider.data.isActive === "1"
              ? true
              : false,
        });
        const scheduleData = parseImage(serviceProvider.data.scheduleData);
        const newScheduleData = scheduleData
          ? scheduleData.map((item, index) => ({
              id: parseInt(index + 1),
              day: parseInt(item.day_id),
              start_time: parseInt(item.start_time),
              end_time: parseInt(item.end_time),
              branch: parseInt(item.branch_id),
            }))
          : [];
        setFieldArrays(newScheduleData);
      }
      return serviceProvider;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchServiceProvider();
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Fetch branch data
   */
  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBranchService(requestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    if (totalBranch && totalBranch.items.length === 0) {
      fetchBranch();
    } else {
      if (totalBranch.items[0]?.data && totalBranch.items[0].data.length > 0) {
        const branchOptions = totalBranch.items[0].data.map((item) => ({
          value: item.branchId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBranchtypeOptions(branchOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalBranch]);

  /**
   * Custom styles for React Select
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "red" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const daytypeOptions = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  /**
   * Appending start time, end time, branch, and day
   */
  const handleAddFieldArray = (values, setFieldValue) => {
    if (
      values.day === "" ||
      values.start_time === "" ||
      values.end_time === "" ||
      values.branch === ""
    ) {
      toast.error(
        "Please enter day, start time and end time, and branch name!"
      );
      return;
    } else {
      if (values.start_time > values.end_time) {
        toast.error("Start time should not be greater than end time!");
        return;
      }
      // Check if the new field conflicts with existing ones
      const conflicts =
        fieldArrays && fieldArrays.length > 0
          ? fieldArrays.some(
              (field) =>
                field.day === values.day &&
                field.start_time === values.start_time &&
                field.end_time === values.end_time &&
                field.branch === values.branch
            )
          : false;

      if (conflicts) {
        toast.error("The schedule should be unique!");
        return;
      }
      let newId;
      if (fieldArrays.length === 0) {
        newId = fieldArrays.length + 1;
      } else {
        const lastKey = fieldArrays[fieldArrays.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        day: values.day,
        end_time: values.end_time,
        start_time: values.start_time,
        branch: values.branch,
      };
      setFieldValue("day", "");
      setFieldValue("end_time", "");
      setFieldValue("start_time", "");
      setFieldValue("branch", "");
      setFieldArrays([...fieldArrays, newField]);
    }
  };

  /**
   * Removing already appended day, start time, end time and branch by id
   */
  const handleRemoveArrayField = (idToRemove) => {
    const list = [...fieldArrays];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFieldArrays(filterField);
  };

  /**
   * Updating days, start_time and end_time in reverse order
   */
  const onChangeData = (index, value, dataType, fieldValue) => {
    let listData = [...fieldArrays];

    if (dataType === "start_time") {
      if (
        value !== "" &&
        value > listData[fieldArrays.length - index - 1]["end_time"] &&
        listData[fieldArrays.length - index - 1]["end_time"] > 0 &&
        listData[fieldArrays.length - index - 1]["start_time"] > 0
      ) {
        if (!document.querySelector(toastClass)) {
          toast.error("Start time should not be greater than end time");
        }
        return;
      } else {
        if (fieldValue) {
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === fieldValue.day &&
                    field.start_time === value &&
                    field.end_time === fieldValue.end_time &&
                    field.branch === fieldValue.branch
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
        }
        listData[fieldArrays.length - index - 1][dataType] = value;
        setFieldArrays(listData);
      }
    } else if (dataType === "end_time") {
      if (
        value !== "" &&
        value < listData[fieldArrays.length - index - 1]["start_time"] &&
        listData[fieldArrays.length - index - 1]["end_time"] > 0 &&
        listData[fieldArrays.length - index - 1]["start_time"] > 0
      ) {
        if (!document.querySelector(toastClass)) {
          toast.error("End time should not be lesser than start time!");
        }
        return;
      } else {
        if (fieldValue) {
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === fieldValue.day &&
                    field.start_time === fieldValue.start_time &&
                    field.end_time === value &&
                    field.branch === fieldValue.branch
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
        }
        listData[fieldArrays.length - index - 1][dataType] = value;
        setFieldArrays(listData);
      }
    } else if (dataType === "day") {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.day === value &&
                  field.start_time === fieldValue.start_time &&
                  field.end_time === fieldValue.end_time &&
                  field.branch === fieldValue.branch
              )
            : false;

        if (conflicts) {
          toast.error("The schedule should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    } else if (dataType === "branch") {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.day === fieldValue.day &&
                  field.start_time === fieldValue.start_time &&
                  field.end_time === fieldValue.end_time &&
                  field.branch === value
              )
            : false;

        if (conflicts) {
          toast.error("The schedule should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    } else {
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    }
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    let scheduleData =
      fieldArrays.length > 0
        ? fieldArrays.map((item) => ({
            day_id: item.day,
            start_time: parseInt(item.start_time),
            end_time: parseInt(item.end_time),
            branch_id: item.branch,
          }))
        : [];

    if (scheduleData.length < 1) {
      if (
        values.day !== "" &&
        values.start_time !== "" &&
        values.end_time !== "" &&
        values.branch !== ""
      ) {
        if (values.start_time < values.end_time) {
          scheduleData.push({});
          scheduleData[0].day_id = values.day;
          scheduleData[0].start_time = parseInt(values.start_time);
          scheduleData[0].end_time = parseInt(values.end_time);
          scheduleData[0].branch_id = values.branch;
        } else {
          toast.error("Start time should be less than end time!");
          return;
        }
      } else {
        toast.error(
          "Please enter day, start time and end time, and branch name!"
        );
      }
    } else {
      if (
        values.day !== "" &&
        values.start_time !== "" &&
        values.end_time !== "" &&
        values.branch !== ""
      ) {
        if (values.start_time <= values.end_time) {
          // Check if the new field conflicts with existing ones
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === values.day &&
                    field.start_time === values.start_time &&
                    field.end_time === values.end_time &&
                    field.branch === values.branch
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
          scheduleData.push({
            day_id: values.day,
            start_time: parseInt(values.start_time),
            end_time: parseInt(values.end_time),
            branch_id: values.branch,
          });
        } else {
          toast.error("Start time should be less than end time!");
          return;
        }
      }
    }

    if (!Data) {
      const requestBody = {
        p_name: values.name,
        p_granularity: values.granularity,
        p_schedule: scheduleData,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_created_by: user.data.userId,
      };
      dispatch(
        addUpdateServiceProviderService(requestBody, tokenData, "add", navigate)
      );
    } else {
      const requestBody = {
        p_id: Data.data.serviceProviderId,
        p_name: values.name,
        p_granularity: values.granularity,
        p_schedule: scheduleData,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_IsDeleted: Data.data.isDeleted,
        p_created_by: Data.data.createdBy,
        p_modified_by: user.data.userId,
      };
      if (Data?.length && serviceProviderPage > 1) {
        if (
          values.status === "0" ||
          values.status === 0 ||
          values.status === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateServiceProviderService(
                requestBody,
                tokenData,
                "update",
                navigate,
                serviceProviderPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateServiceProviderService(
                requestBody,
                tokenData,
                "update",
                navigate,
                serviceProviderPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateServiceProviderService(
              requestBody,
              tokenData,
              "update",
              navigate,
              serviceProviderPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateServiceProviderService(
            requestBody,
            tokenData,
            "update",
            navigate
          ),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  Service Provider
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/service-provider")}>
                  Service Provider
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  Service Provider
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? "Edit "
                : Data && Data.isEdit === false
                ? "View "
                : "Add "}{" "}
              Service Provider
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Name<span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Enter Name"
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        value={values.name}
                        maxLength={20}
                        hidelength={"true"}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          Granularity <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="granularity"
                        label="Granularity"
                        placeholder="Enter Granularity (number in minutes)"
                        className="add-roles"
                        Regex={/[^0-9]/g}
                        maxLength={50}
                        hidelength={"true"}
                        value={values.granularity}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Day
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      {(Data && Data.isEdit === true) || !Data ? (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              Start time
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              Start time
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      )}
                      {(Data && Data.isEdit === true) || !Data ? (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              End time <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              End time <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Branch <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Add</h4>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-3">
                          <Field
                            className="select-menu mb-2"
                            name="day"
                            options={daytypeOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            value={
                              values.day === "" || values.day === null
                                ? false
                                : values.day
                            }
                          />
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="name-type">
                            <Field
                              className="select-menu mb-2"
                              name="start_time"
                              options={timeOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              value={
                                values.start_time === "" ||
                                values.start_time === null
                                  ? false
                                  : values.start_time
                              }
                            />
                          </div>
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="name-type">
                            <Field
                              className="select-menu mb-2"
                              name="end_time"
                              options={timeOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              value={
                                values.end_time === "" ||
                                values.end_time === null
                                  ? false
                                  : values.end_time
                              }
                            />
                          </div>
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-3">
                          <div className="name-type">
                            <Field
                              className="select-menu mb-2"
                              name="branch"
                              options={branchtypeOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              styles={customStyles}
                              value={values.branch}
                            />
                          </div>
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-1">
                          <Button
                            className="btn btn-fillter-main"
                            type="button"
                            onClick={() =>
                              handleAddFieldArray(values, setFieldValue)
                            }
                            name={
                              <div className="Plus">
                                <FaPlus />
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {fieldArrays &&
                        fieldArrays.length > 0 &&
                        fieldArrays
                          .slice()
                          .reverse()
                          .map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-3">
                                <Field
                                  className="select-menu mb-2"
                                  name={`day${index}`}
                                  options={daytypeOptions}
                                  changeData={(value) =>
                                    onChangeData(index, value, "day", field)
                                  }
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  value={field.day}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                              {(Data && Data.isEdit === true) || !Data ? (
                                <div className="col-md-2">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`start_time${index}`}
                                      options={timeOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "start_time",
                                          field
                                        )
                                      }
                                      value={field.start_time}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-3">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`start_time${index}`}
                                      options={timeOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "start_time",
                                          field
                                        )
                                      }
                                      value={field.start_time}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              )}
                              {(Data && Data.isEdit === true) || !Data ? (
                                <div className="col-md-2">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`end_time${index}`}
                                      options={timeOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "end_time",
                                          field
                                        )
                                      }
                                      value={field.end_time}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-3">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`end_time${index}`}
                                      options={timeOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "end_time",
                                          field
                                        )
                                      }
                                      value={field.end_time}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-md-3">
                                <div className="name-type">
                                  <Field
                                    className="select-menu mb-2"
                                    name={`branch${index}`}
                                    options={branchtypeOptions}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    styles={customStyles}
                                    changeData={(value) =>
                                      onChangeData(
                                        index,
                                        value,
                                        "branch",
                                        field
                                      )
                                    }
                                    value={field.branch}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              {((Data && Data.isEdit === true) || !Data) && (
                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() =>
                                      handleRemoveArrayField(field.id)
                                    }
                                    name={
                                      <div className="Plus">
                                        <FaMinus />
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Status</h4>
                      <Field
                        name="status"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name="Save"
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name="Cancel"
                              className="btn clear-btn login-btn"
                              onClick={() => handleCancleClick("/tv", navigate)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

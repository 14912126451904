import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import CustomTable from "../../components/Table/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../components/Toggle/Toggle";
import { useNavigate } from "react-router-dom";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import DiscountCatalogueService from "./DiscountCatalogueService/DiscountCatalogueService";
import { format } from "date-fns";
import CustomPagination from "../../components/Pagination/Pagination";
import { clearDiscountCatalogueData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCatalogueAction";
import { clearDiscountCatalogueFilterData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCatalogueFilterAction";
import {
  clearDiscountCataloguePageData,
  getDiscountCataloguePageData,
} from "../../redux/Actions/DiscountCatalogueAction/DiscountCataloguePageAction";

//Show keys which is to be displayed in table
const showKey = {
  title: true,
  discount_type: true,
  discount_amount: true,
  start_date: true,
  end_date: true,
};

export default function DiscountCatalogue() {
  const { getAllDiscountCatalogueService } = DiscountCatalogueService();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const tokenData = UtilsData();

  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const discountCatalogueData = useSelector((state) => state.discountCatalogue);
  const discountCatalogueFilter = useSelector(
    (state) => state.discountCatalogueFilter
  );
  const discountCataloguePage = useSelector(
    (state) => state.discountCataloguePage?.items
  );

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [discountActionData, setdiscountActionData] = useState([]);
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setdiscountActionData(actionData);
    }
  }, [sideBarData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (discountActionData[0]?.view && discountActionData[0].view === "1") ||
      !discountActionData
        ? true
        : false,
    showEdit:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? true
        : false,
    showDelete:
      (discountActionData[0]?.delete && discountActionData[0].delete === "1") ||
      !discountActionData
        ? true
        : false,
    showUndo:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? true
        : false,
    navigate:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? "/edit-discount-catalogue"
        : false,
  };

  const [showActionBool, setShowActionBool] = useState(false);
  //Headings
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (
      discountActionData[0]?.view &&
      discountActionData[0].view === "0" &&
      discountActionData[0]?.edit &&
      discountActionData[0].edit === "0" &&
      discountActionData[0]?.add &&
      discountActionData[0].add === "0" &&
      discountActionData[0]?.delete &&
      discountActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Discount Name",
          selector: (row) => row.Name,
          sortable: true,
        },
        {
          name: "Discount Type",
          selector: (row) => row.Type,
          sortable: true,
        },
        {
          name: "Discount Amount",
          selector: (row) => row.value,
          sortable: true,
        },
        {
          name: "Start Date",
          selector: (row) => row.date,
          sortable: true,
        },
        {
          name: "End Date",
          selector: (row) => row.enddate,
          sortable: true,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Discount Name",
          selector: (row) => row.Name,
          sortable: true,
        },
        {
          name: "Discount Type",
          selector: (row) => row.Type,
          sortable: true,
        },
        {
          name: "Discount Amount",
          selector: (row) => row.value,
          sortable: true,
        },
        {
          name: "Start Date",
          selector: (row) => row.date,
          sortable: true,
        },
        {
          name: "End Date",
          selector: (row) => row.enddate,
          sortable: true,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [discountActionData]);

  /**
   * Setting data for discount catalogue
   */
  useEffect(() => {
    setCurrentPage(discountCataloguePage);
  }, [discountCataloguePage]);

  /**
   * Fetch discount catalogue data
   */
  const fetchDiscountCatalogue = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (discountCataloguePage > 1) {
      requestBody.p_skip = (discountCataloguePage - 2) * 10;
      dispatch(getDiscountCataloguePageData(discountCataloguePage - 1));
    } else {
      requestBody.p_skip = (discountCataloguePage - 1) * 10;
      dispatch(getDiscountCataloguePageData(discountCataloguePage));
    }
    if (
      discountCatalogueFilter.name !== "" &&
      discountCatalogueFilter.name !== null
    ) {
      requestBody.p_title = discountCatalogueFilter.name;
    }
    if (
      discountCatalogueFilter.start_date !== "" &&
      discountCatalogueFilter.start_date !== null
    ) {
      requestBody.p_start_date = format(
        new Date(discountCatalogueFilter.start_date),
        "yyyy-MM-dd"
      );
    }
    if (
      discountCatalogueFilter.end_date !== "" &&
      discountCatalogueFilter.end_date !== null
    ) {
      requestBody.p_end_date = format(
        new Date(discountCatalogueFilter.end_date),
        "yyyy-MM-dd"
      );
    }
    if (
      discountCatalogueFilter.isActive !== "" &&
      discountCatalogueFilter.isActive !== null
    ) {
      requestBody.p_IsActive = discountCatalogueFilter.isActive;
    } else {
      requestBody.p_IsActive = "1";
    }
    if (
      discountCatalogueFilter.isDelete !== "" &&
      discountCatalogueFilter.isDelete !== null
    ) {
      requestBody.p_isDeleted = discountCatalogueFilter.isDelete;
    }
    getAllDiscountCatalogueService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (
      discountCatalogueData &&
      discountCatalogueData.items &&
      discountCatalogueData.items.length === 0
    ) {
      fetchDiscountCatalogue();
    } else {
      if (
        (discountCatalogueFilter.name !== "" &&
          discountCatalogueFilter.name !== null) ||
        (discountCatalogueFilter.start_date !== "" &&
          discountCatalogueFilter.start_date !== null) ||
        (discountCatalogueFilter.end_date !== "" &&
          discountCatalogueFilter.end_date !== null) ||
        (discountCatalogueFilter.isActive !== "" &&
          discountCatalogueFilter.isActive !== null) ||
        (discountCatalogueFilter.isDelete !== "" &&
          discountCatalogueFilter.isDelete !== null)
      ) {
        setIsToggled(true);
      }

      if (
        discountCatalogueData.items &&
        discountCatalogueData.items[0]?.data &&
        discountCatalogueData.items[0].data.length > 0
      ) {
        const transformedData = discountCatalogueData.items[0].data.map(
          (item) => ({
            discountId: item.discountId,
            title: item.title,
            discount_type: item.discount_type === "0" ? "Fix" : "Upto",
            discount_amount: item.discount_amount,
            start_date: format(new Date(item.start_date), "yyyy-MM-dd"),
            end_date: format(new Date(item.end_date), "yyyy-MM-dd"),
            discount_type_on: item.discount_type_on,
            discount_catlogue_for: item.discount_catlogue_for,
            is_active: item.is_active,
            created_by: item.created_by,
            modified_by: item.modified_by,
            created_at: item.created_at,
            modified_at: item.modified_at,
            IsDeleted: item.IsDeleted,
          })
        );
        setTableData(transformedData);
      } else {
        setTableData([]);
      }
    }
    // eslint-disable-next-line
  }, [discountCatalogueData]);

  const styleData = {
    discount_amount: { type: { textAlign: "end" } },
    start_date: { type: { textAlign: "center" } },
    end_date: { type: { textAlign: "center" } },
  };

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };
  const showToggle = {
    status: true,
    delete: false,
  };

  /**
   * Function for page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (
        discountCatalogueFilter.name !== "" &&
        discountCatalogueFilter.name !== null
      ) {
        requestBody.p_title = discountCatalogueFilter.name;
      }
      if (
        discountCatalogueFilter.start_date !== "" &&
        discountCatalogueFilter.start_date !== null
      ) {
        requestBody.p_start_date = format(
          new Date(discountCatalogueFilter.start_date),
          "yyyy-MM-dd"
        );
      }
      if (
        discountCatalogueFilter.end_date !== "" &&
        discountCatalogueFilter.end_date !== null
      ) {
        requestBody.p_end_date = format(
          new Date(discountCatalogueFilter.end_date),
          "yyyy-MM-dd"
        );
      }
      if (
        discountCatalogueFilter.isActive !== "" &&
        discountCatalogueFilter.isActive !== null
      ) {
        requestBody.p_IsActive = discountCatalogueFilter.status;
      } else {
        requestBody.p_IsActive = "1";
      }
      if (
        discountCatalogueFilter.isDelete !== "" &&
        discountCatalogueFilter.isDelete !== null
      ) {
        requestBody.p_isDeleted = discountCatalogueFilter.is_delete;
      }
      getAllDiscountCatalogueService(requestBody, tokenData, dispatch);
      setCurrentPage(pageNumber);
      dispatch(getDiscountCataloguePageData(pageNumber));
    }
  };

  const clearFilters = () => {
    dispatch(clearDiscountCatalogueData());
    dispatch(clearDiscountCatalogueFilterData());
    dispatch(clearDiscountCataloguePageData());
    setIsToggled(false);
  };

  return (
    <>
      <div>
        {/* <div className="sidebar-content">
          <Sidebar />
        </div> */}
        {/* <div className="body-content"> */}
        {/* <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {discountActionData && discountActionData.length > 0
                    ? discountActionData[0].name
                    : "Discount Catalogue"}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {discountActionData && discountActionData.length > 0
                    ? discountActionData[0].name
                    : "Discount Catalogue"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(discountCatalogueFilter.name !== "" &&
                discountCatalogueFilter.name !== null) ||
              (discountCatalogueFilter.start_date !== "" &&
                discountCatalogueFilter.start_date !== null) ||
              (discountCatalogueFilter.end_date !== "" &&
                discountCatalogueFilter.end_date !== null) ||
              (discountCatalogueFilter.isActive !== "" &&
                discountCatalogueFilter.isActive !== null) ||
              (discountCatalogueFilter.isDelete !== "" &&
                discountCatalogueFilter.isDelete !== null) ? (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      Clear Filters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
              <Button
                data-bs-toggle="tooltip"
                title="Filter"
                onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>
              {discountCatalogueData &&
                discountCatalogueData.items[0]?.data &&
                discountCatalogueData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={discountCatalogueData.items[0].data}
                    fileName={"discount-catalogue"}
                  ></ExcelData>
                )}
              {((discountActionData[0]?.add &&
                discountActionData[0].add === "1") ||
                !discountActionData) && (
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Discount Catalogue"
                  onClick={() => navigate("/add-discount-catalogue")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>
          {isToggled && <Filter page="discountCatalogue"></Filter>}

          {discountCatalogueData &&
            discountCatalogueData.items[0]?.data &&
            discountCatalogueData.items[0].data && (
              <>
                <CustomTable
                  showDescription={true}
                  showToggle={showToggle}
                  checkBool={false}
                  showKey={showKey}
                  showActions={showActionBool ? showActions : false}
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  styleData={styleData}
                  page="discountCatalogue"
                  disabled={
                    discountActionData[0]?.edit &&
                    discountActionData[0].edit === "0"
                  }
                />
                {discountCatalogueData.items[0].total_records >
                  discountCatalogueData.items[0].data.length && (
                  <div className="custom-pagination">
                    <CustomPagination
                      totalRecords={
                        discountCatalogueData.items[0].total_records
                      }
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  </div>
                )}
              </>
            )}
        </div>
        {/* <Footer /> */}
      </div>
      {/* </div> */}
    </>
  );
}

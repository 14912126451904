import { CLEAR_EVENT_LOG_PAGE, GET_EVENT_LOG_PAGE } from "../../ReduxKeys";

const eventLogPageInitialState = {
  items: 1,
};

/**
 * Store Event log page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const EventLogPageReducer = (
  state = eventLogPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_EVENT_LOG_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_EVENT_LOG_PAGE:
      return eventLogPageInitialState;
    default:
      return state;
  }
};
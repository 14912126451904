import {
  CLEAR_INDUSTRY_DATA,
  CLEAR_TOTAL_INDUSTRY_DATA,
  GET_INDUSTRY_DATA,
  GET_TOTAL_INDUSTRY_DATA,
} from "../../ReduxKeys";

const industryInitialState = {
  items: [],
};

/**
 * Store industry list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */

export const industryReducer = (state = industryInitialState, action) => {
  switch (action.type) {
    case GET_INDUSTRY_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_INDUSTRY_DATA:
      return industryInitialState;
    default:
      return state;
  }
};

const totalIndustryInitialState = {
  items: [],
};

/**
 * Store total industry list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalIndustryReducer = (
  state = totalIndustryInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_INDUSTRY_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_INDUSTRY_DATA:
      return totalIndustryInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_BRAND_DATA,
  CLEAR_TOTAL_BRAND,
  GET_BRAND_DATA,
  GET_TOTAL_BRAND,
} from "../../ReduxKeys";

const brandInitialState = {
  items: [],
};

/**
 * Store brand list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const BrandReducer = (state = brandInitialState, action) => {
  switch (action.type) {
    case GET_BRAND_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_BRAND_DATA:
      return brandInitialState;
    default:
      return state;
  }
};

const totalBrandInitialState = {
  items: [],
};

/**
 * Store total brand list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalBrandReducer = (state = totalBrandInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_BRAND:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_BRAND:
      return totalBrandInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import "./Category.scss";
import Button from "../../components/Button/Button";
import CustomTable from "../../components/Table/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../components/Toggle/Toggle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import CategoryService from "./Category-service/CategoryService.tsx";
import { clearCategoryFilterData } from "../../redux/Actions/categoryAction/CategoryFilterAction";
import { clearCategoryData } from "../../redux/Actions/categoryAction/CategoryAction";
import CustomPagination from "../../components/Pagination/Pagination";
import { getCategoryPageData } from "../../redux/Actions/categoryAction/CategoryPageAction";
// import { TfiImport } from "react-icons/tfi";
// import DownloadExcel from "../../services/Regular-services/DownloadExcelService";
// import sampleCategoryExcelFile from "../../assets/files/Category-Import.xlsx";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import { useTranslation } from "react-i18next";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  type: true,
  code: true,
  patrentCategoryName: true,
  description: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

export default function Category() {
  const { getAllCategoryService, uploadCategoryExcelService } =
    CategoryService();
  const { t } = useTranslation();
  const categoryPageData = useSelector((state) => state.categoryPage?.items);
  const [columns, setColumns] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [categoryListData, setCategoryListData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [categoryActionData, setcategoryActionData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setcategoryActionData(actionData);
    }
  }, [sideBarData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (categoryActionData[0]?.view && categoryActionData[0].view === "1") ||
      !categoryActionData
        ? true
        : false,
    showEdit:
      (categoryActionData[0]?.edit && categoryActionData[0].edit === "1") ||
      !categoryActionData
        ? true
        : false,
    showDelete:
      (categoryActionData[0]?.delete && categoryActionData[0].delete === "1") ||
      !categoryActionData
        ? true
        : false,
    showUndo:
      (categoryActionData[0]?.edit && categoryActionData[0].edit === "1") ||
      !categoryActionData
        ? true
        : false,
    navigate:
      (categoryActionData[0]?.edit && categoryActionData[0].edit === "1") ||
      !categoryActionData
        ? "/edit-category"
        : false,
  };

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    const hasNoPermissions = categoryActionData[0]?.view === "0" &&
      categoryActionData[0]?.edit === "0" &&
      categoryActionData[0]?.add === "0" &&
      categoryActionData[0]?.delete === "0";
  
    const commonColumns = [
      { name: t("columns.name"), selector: (row) => row.Name, sortable: true },
      { name: t("columns.type"), selector: (row) => row.Type, sortable: true },
      { name: t("columns.code"), selector: (row) => row.Code, sortable: true },
      { name: t("parentCategory"), selector: (row) => row.parentCategory, sortable: true },
      { name: t("columns.description"), selector: (row) => row.Description, sortable: true },
      { name: t("columns.status"), cell: (row) => <Toggle checked={row.toggle} /> },
    ];
  
    setColumns(hasNoPermissions ? commonColumns : [...commonColumns, {
      name: t("columns.actions"),
      selector: (row) => row.actions,
    }]);
    setShowActionBool(!hasNoPermissions);
    // eslint-disable-next-line
  }, [categoryActionData, localisation]);

  useEffect(() => {
    setCurrentPage(categoryPageData);
  }, [categoryPageData]);

  const tokenData = UtilsData(); //Getting token
  const dispatch = useDispatch();

  const categoryFilter = useSelector((state) => state.categoryFilter);

  //Getting data from reducers
  const categoryData = useSelector((state) => state.category);

  /**
   * Fetch category data
   */
  const fetchCategory = (page) => {
    const pageNumber = page
      ? (page - 1) * 10
      : categoryPageData > 1
      ? (categoryPageData - 2) * 10
      : (categoryPageData - 1) * 10;

    const requestBody = {
      p_skip: pageNumber,
      p_take: 10,
    };
    if (categoryFilter.name !== "" && categoryFilter.name !== null) {
      requestBody.p_name = categoryFilter.name;
    }
    if (categoryFilter.code !== "" && categoryFilter.code !== null) {
      requestBody.p_category_code = categoryFilter.code;
    }

    if (categoryFilter.isActive !== "" && categoryFilter.isActive !== null) {
      requestBody.p_is_active = categoryFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }

    if (categoryFilter.isDelete !== "" && categoryFilter.isDelete !== null) {
      requestBody.p_IsDeleted = categoryFilter.isDelete;
    }
    getAllCategoryService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (categoryData && categoryData.items && categoryData.items.length === 0) {
      fetchCategory();
    } else {
      if (
        categoryFilter.name !== "" ||
        categoryFilter.code !== "" ||
        categoryFilter.isActive !== "" ||
        categoryFilter.isDelete !== ""
      ) {
        // handleShow();
      }
    }
    if (categoryData.items[0]?.data && categoryData.items[0].data.length > 0) {
      const transformedAdminData = categoryData.items[0].data.map((item) => ({
        id: item.categoryId,
        name: item.name,
        type:
          item.type === "1"
            ? "Blog"
            : item.type === "0"
            ? "Product"
            : item.type === "2"
            ? "Attribute"
            : item.type === "4"
            ? "Inventory"
            : "Expense",
        code: item.category_code,
        sequence: item.Sequence,
        parentCategoryID:
          item?.parentCategoryID &&
          !["", "null", "NULL"].includes(item.parentCategoryID)
            ? Number(item.parentCategoryID)
            : 0,
        patrentCategoryName:
          item?.patrentCategoryName &&
          !["", "null", "NULL"].includes(item.patrentCategoryName)
            ? item.patrentCategoryName
            : "—",
        description: item.description,
        isActive: item.is_active,
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
      }));
      setCategoryListData(transformedAdminData);
    } else {
      setCategoryListData([]);
    }
    // eslint-disable-next-line
  }, [categoryData]);

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Import modal open
   */
  // const handleImportClick = () => {
  //   setIsModalOpen(true);
  // };

  /**
   * Import modal close
   */
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Handle file upload
   * @param {*} file
   */
  const handleFileUpload = (file) => {
    dispatch(uploadCategoryExcelService(file, tokenData, setIsModalOpen));
  };

  /**
   * Clear filter data
   */
  const clearcategoryFilters = () => {
    dispatch(clearCategoryFilterData());
    dispatch(clearCategoryData());
    handleClose();
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      fetchCategory(pageNumber);
    }
    setCurrentPage(pageNumber);
    dispatch(getCategoryPageData(pageNumber));
  };

  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {categoryActionData && categoryActionData.length > 0
                    ? getRouteNameFromSidebar(
                        categoryActionData[0].name,
                        localisation
                      )
                    : t("Categories")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {categoryActionData && categoryActionData.length > 0
                    ? getRouteNameFromSidebar(
                        categoryActionData[0].name,
                        localisation
                      )
                    : t("Categories")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(categoryFilter.name !== "" ||
                categoryFilter.code !== "" ||
                categoryFilter.isActive !== "" ||
                categoryFilter.isDelete !== "") && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearcategoryFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <FileUploadModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onFileUpload={handleFileUpload}
                isClose={isModalOpen}
              />

              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="category" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              {categoryData &&
                categoryData.items[0]?.data &&
                categoryData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={categoryData.items[0].data}
                    fileName={"category"}
                  />
                )}

              {((categoryActionData[0]?.add &&
                categoryActionData[0].add === "1") ||
                !categoryActionData) && (
                <Button
                  onClick={() => navigate("/addcategory")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>
          {categoryData &&
            categoryData.items[0]?.data &&
            categoryData.items[0].data && (
              <>
                <CustomTable
                  totalRecords={
                    categoryData.items[0].maxSequence
                      ? categoryData.items[0].maxSequence
                      : categoryData.items[0].total_records
                      ? categoryData.items[0].total_records
                      : 0
                  }
                  showDescription={true}
                  page="category"
                  showToggle={showToggle}
                  showKey={showKey}
                  showTooltip={true}
                  showActions={showActionBool ? showActions : false}
                  data={categoryListData}
                  heading={columns}
                  checkBool={false}
                  disabled={
                    categoryActionData[0]?.edit &&
                    categoryActionData[0].edit === "0"
                  }
                  pageData={categoryPageData}
                />

                {categoryData.items[0].total_records >
                  categoryData.items[0].data.length &&
                  categoryData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={categoryData.items[0].total_records}
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
      </div>
    </>
  );
}
import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../Filter/Filter.scss";
import Input from "../input/Input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomSelect from "../CustomSelect/CustomSelect";
import ErrorComp from "../Error/ErrorComp";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, {
  getRouteNameFromSidebar,
  imageTypeOptions,
} from "../../Utils/Utils";
import { clearRolePageData } from "../../redux/Actions/rolesAction/RolesPageAction.js";
import { clearAdminPageData } from "../../redux/Actions/adminAction/AdminPageAction.js";
import UserService from "../../pages/AdminUsers/User-service/UserService.tsx";
import { getRoleFilterData } from "../../redux/Actions/rolesAction/RolesFilterAction";
import { getAdminFilterData } from "../../redux/Actions/adminAction/AdminFilterAction";
import { getCategoryFilterData } from "../../redux/Actions/categoryAction/CategoryFilterAction";
import CategoryService from "../../pages/Category/Category-service/CategoryService.tsx";
import { getSubCategoryFilterData } from "../../redux/Actions/subCategoryAction/SubCategoryFilterAction";
import { getLeafFilterData } from "../../redux/Actions/leafLevelCategoryAction/LeafFilterAction";
import { clearCategoryPageData } from "../../redux/Actions/categoryAction/CategoryPageAction";
import { clearSubCategoryPageData } from "../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearLeafPageData } from "../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
import { getTagFilterData } from "../../redux/Actions/tagAction/TagFilterAction";
import { clearTagPageData } from "../../redux/Actions/tagAction/TagPageAction";
import { clearImagePageData } from "../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";
import { getImageFilterData } from "../../redux/Actions/imageGalleryAction/ImageFilterAction";
import { getBrandFilterData } from "../../redux/Actions/brandAction/BrandFilterAction";
import BrandService from "../../pages/BrandList/BrandService/BrandService";
import { clearBrandPageData } from "../../redux/Actions/brandAction/BrandPageAction";
import { getProductFilterData } from "../../redux/Actions/productAction/ProductFilterAction";
import { getAttributeFilterData } from "../../redux/Actions/AttributeAction/AttributeFilterAction";
import { clearAttributePageData } from "../../redux/Actions/AttributeAction/AttributePageAction";
import { getWarehouseFilterData } from "../../redux/Actions/WarehouseAction/WarehouseFilterAction";
import { clearWarehousePageData } from "../../redux/Actions/WarehouseAction/WarehousePageAction";
import { toast } from "react-toastify";
import { clearPackagerPageData } from "../../redux/Actions/PackagerAction/PackagerPageAction";
import { getPackagerFilterData } from "../../redux/Actions/PackagerAction/PackagerFilterAction";
import { format } from "date-fns";
import { getDiscountFilterData } from "../../redux/Actions/DiscountAction/DiscountFilterAction";
import { clearDiscountPageData } from "../../redux/Actions/DiscountAction/DiscountPageAction";
import { clearProductPageData } from "../../redux/Actions/productAction/ProductPageAction";
import { clearDiscountCataloguePageData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCataloguePageAction";
import { getDiscountCatalogueFilterData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCatalogueFilterAction";
import { clearInventoryPageData } from "../../redux/Actions/InventoryAction/InventoryPageAction";
import { getInventoryFilterData } from "../../redux/Actions/InventoryAction/InventoryFilterAction";
import { clearBranchPageData } from "../../redux/Actions/BranchAction/BranchPageAction";
import { getBranchFilterData } from "../../redux/Actions/BranchAction/BranchFilterReducer";
import BranchService from "../../pages/Branches/BranchService/BranchService";
import { getServiceProviderFilterData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderFilterAction";
import { clearServiceProviderPageData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderPageAction";
import { clearServicePageData } from "../../redux/Actions/ServiceAction/ServicePageAction";
import { getServiceFilterData } from "../../redux/Actions/ServiceAction/ServiceFilterAction";
import { getOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";
import { clearOrderPageData } from "../../redux/Actions/orderAction/OrderPAgeAction";
import { clearCustomerPageData } from "../../redux/Actions/CustomerAction/CustomerPageAction";
import { getCustomerFilterData } from "../../redux/Actions/CustomerAction/CustomerFilterAction";
import CustomerService from "../../pages/Customer/CustomerService/CustomerService";
import CompanyService from "../../pages/Company/Company-service/companyService";
import { clearCompanyData } from "../../redux/Actions/companyAction/CompanyAction";
import { getCompanyFilterData } from "../../redux/Actions/companyAction/CompanyFilterAction";
import { clearCompanyPageData } from "../../redux/Actions/companyAction/CompanyPageAction";
import { clearSpotPageData } from "../../redux/Actions/spotAction/SpotPageAction";
import { getSpotFilterData } from "../../redux/Actions/spotAction/SpotFilterAction";
import { getInventoryItemFilterData } from "../../redux/Actions/InventoryItemAction/InventoryItemFilterAction";
import { clearInventoryItemPageData } from "../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import { clearProductInventoryPageData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { getProductInventoryFilterData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryFilterAction";
import { clearVendorPageData } from "../../redux/Actions/VendorAction/VendorPageAction";
import { getVendorFilterData } from "../../redux/Actions/VendorAction/VendorFilterAction";
import { clearStockPageData } from "../../redux/Actions/StocksAction/StockPageAction";
import { getStockFilterData } from "../../redux/Actions/StocksAction/StockFilterAction";
import { clearStockHistoryPageData } from "../../redux/Actions/StockHistoryAction/StockHistoryPageAction";
import { getStockHistoryFilterData } from "../../redux/Actions/StockHistoryAction/StockHistoryFilterAction";
import { clearNewVendorPageData } from "../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { getNewVendorFilterData } from "../../redux/Actions/NewVendorAction/NewVendorFilterAction";
import { clearUnitsPageData } from "../../redux/Actions/UnitsAction/UnitsPageAction";
import { getUnitsFilterData } from "../../redux/Actions/UnitsAction/UnitsFilterAction";
import { clearPurchaseRequisitionPageData } from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionPageAction";
import { getPurchaseRequisitionFilterData } from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionFilterAction";
import { clearPurchaseOrderPageData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import { getPurchaseOrderFilterData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderFilterAction";
import { clearGRNPageData } from "../../redux/Actions/GoodReceiveNoteAction/GRNPageAction";
import { getGRNFilterData } from "../../redux/Actions/GoodReceiveNoteAction/GRNFilterAction";
import { clearWalletPageData } from "../../redux/Actions/WalletAction/WalletPageAction";
import { getWalletFilterData } from "../../redux/Actions/WalletAction/WalletFilterAction";
import { clearProductPricingPageData } from "../../redux/Actions/ProductPricingAction/ProductPricingPageAction";
import { getProductPricingFilterData } from "../../redux/Actions/ProductPricingAction/ProductPricingFilterAction";
import { clearPreferencesPageData } from "../../redux/Actions/Prefrences/PrefrencesPageAction";
import { getPreferencesFilterData } from "../../redux/Actions/Prefrences/PrefrencesFilterAction";
import { getCustomerAnalyticsFilterData } from "../../redux/Actions/CustomerAnalyticsAction/CustomerAnalyticsFilterAction";
import { clearCustomerOrderPageData } from "../../redux/Actions/CustomerAction/CustomerOrderPageAction";
import { getCustomerOrderFilterData } from "../../redux/Actions/CustomerAction/CustomerOrderFilterAction";
import { getSalesGraphFilter } from "../../redux/Actions/AnalyticsActions/SalesGraphFilterAction";
import {
  formatDateToDDMMYYYY,
  parseDateFromDDMMYYYY,
} from "../../services/Regular-services/DateService";
import { getSalesTableFilterData } from "../../redux/Actions/AnalyticsActions/SalesTableFilterAction";
import { clearSalesTablePageData } from "../../redux/Actions/AnalyticsActions/SalesTablePageAction";
import { clearEventLogPageData } from "../../redux/Actions/EventLogAction/EventLogPageAction";
import { getEventLogFilterData } from "../../redux/Actions/EventLogAction/EventLogFilterAction";
import { getCustomerRushFilterData } from "../../redux/Actions/CustomerRushAction/CustomerRushFilterAction";
import CustomerRushService from "../../pages/CustomerRushReport/CustomerRushService/CustomerRushSerivice";
import { clearCustomerRushData } from "../../redux/Actions/CustomerRushAction/CustomerRushAction";
import AttributeService from "../../pages/Attributes/AttributeService/AttributeService";
import AnalyticsService from "../../pages/Analytics/AnalyticsService/AnalyticsService";
import DiscountService from "../../pages/Discount/DiscountService/DiscountService";
import DiscountCatalogueService from "../../pages/DiscountCatalogue/DiscountCatalogueService/DiscountCatalogueService";
import EventLogService from "../../pages/EventLogs/EventLogService/EventLogService";
import GoodReceiveNoteService from "../../pages/GoodsReceiveNote/GoodsReceiveNoteResponse/GoodsReceiveNoteService";
import ImageManagerService from "../../pages/Imagemanager/ImageService/ImageService";
import InventoryService from "../../pages/Inventory/InventoryService/InventoryService";
import IngredientService from "../../pages/InventoryItems/InventoryItemService/InventoryItemService";
import LeafLevelCategoryService from "../../pages/LeafLevelCategory/LeafLevelService/LeafLevelService";
import NewVendorPersonService from "../../pages/NewVendorPerson/NewVendorPersonService/NewVendorPersonService";
import OrderServices from "../../pages/Order/OrderService/OrderService";
import PackagerServices from "../../pages/Packager/PackagerService/PackagerService";
import ProductServices from "../../pages/Product/ProductService/ProductService";
import ProductInventoryServices from "../../pages/ProductInventory/ProductInventoryService/ProductInventoryService";
import PurchaseOrderServices from "../../pages/PurchaseOrder/PurchaseOrderService/PurchaseOrderService";
import PurchaseRequisitionServices from "../../pages/PurchaseRequisition/PurchaseRequisitionService/PurchaseRequisitionService";
import RoleServices from "../../pages/Roles/Role-Service/roleService";
import ServiceServices from "../../pages/Service/ServiceService/ServiceService";
import ServiceProviderServices from "../../pages/ServiceProvider/ServiceProviderService/ServiceProviderService";
import SpotServices from "../../pages/Spot/SpotService/SpotService";
import StockServices from "../../pages/Stocks/StocksService/StocksService";
import StockHistoryServices from "../../pages/Stocks/StockHistoryPage.js/StockHistoryServices/StockHistoryService";
import SubCategoryServices from "../../pages/SubCategory/SubCategoryService/SubCategoryService";
import TagServices from "../../pages/Tag/TagService/TagService";
import VendorServices from "../../pages/Vendors/VendorsService.js/VendorService";
import WalletServices from "../../pages/Wallet/WalletService/WalletService";
import WarehouseServices from "../../pages/Warehouse/WarehouseService/WarehouseService";
import { useTranslation } from "react-i18next";
import { clearAllPreferencesPageData } from "../../redux/Actions/Prefrences/AllPreferencesPageAction";
import { getAllPreferencesFilterData } from "../../redux/Actions/Prefrences/AllPreferencesFilterAction";
import { clearExpensePageData } from "../../redux/Actions/ExpenseAction/ExpensePageAction";
import ExpenseService from "../../pages/Expense/ExpenseService/ExpenseSerive";
import { getExpenseFilterData } from "../../redux/Actions/ExpenseAction/ExpenseFilterAction";
import { clearExpenseGraphData } from "../../redux/Actions/ExpenseAction/ExpenseGraphAction";
import { getExpenseGraphFilterData } from "../../redux/Actions/ExpenseAction/ExpenseGraphFilterAction";
import ExpenseGraphService from "../../pages/ExpenseGraph/ExpenseGraphService/ExpenseGraphService";
import { clearSettlementGraphData } from "../../redux/Actions/SettlementGraphAction/SettlementGraphAction";
import { getSettlementGraphFilterData } from "../../redux/Actions/SettlementGraphAction/SettlementGraphFilterAction";
import SettlementGraphService from "../../pages/SettlementGraph/SettlementGraphService/SettlementGraphService";
import { clearTotalCategory } from "../../redux/Actions/categoryAction/CategoryAction";

export default function Filter(props) {
  const { getAllAttributeService } = AttributeService();
  const { getSalesChartDataService, getSalesTableDataService } =
    AnalyticsService();
  const { getAllProductInventoryService } = ProductInventoryServices();
  const { getAllBranchService } = BranchService();
  const { getUserListService } = UserService();
  const { getAllBrandService } = BrandService();
  const { getAllCategoryService } = CategoryService();
  const { getCompanyService } = CompanyService();
  const {
    getAllPreferencesService,
    getCustomerAnalyticsService,
    getCustomerListService,
    getCustomersOrderService,
  } = CustomerService();
  const { getCustomerRushService } = CustomerRushService();
  const { getExpenseGraphService } = ExpenseGraphService();
  const { getSettlementGraphService } = SettlementGraphService();
  const { getAllDiscountService } = DiscountService();
  const { getAllDiscountCatalogueService } = DiscountCatalogueService();
  const { getEventLogDataService } = EventLogService();
  const { getAllExpenseService } = ExpenseService();
  const { getAllGoodReceiveNoteService } = GoodReceiveNoteService();
  const { getAllImageService } = ImageManagerService();
  const { getAllInventoryService } = InventoryService();
  const { getAllInventoryItemService } = IngredientService();
  const { getAllLeafLevelCategoryService } = LeafLevelCategoryService();
  const { getAllNewVendorPersonService } = NewVendorPersonService();
  const { getAllOrderService } = OrderServices();
  const { getAllPackagerService } = PackagerServices();
  const { getAllProductService } = ProductServices();
  const { getAllPurchaseOrderService } = PurchaseOrderServices();
  const { getAllPurchaseRequisitionService } = PurchaseRequisitionServices();
  const { getRolesService } = RoleServices();
  const { getAllService } = ServiceServices();
  const { getAllServiceProviderService } = ServiceProviderServices();
  const { getAllSpotService } = SpotServices();
  const { getAllStocksService } = StockServices();
  const { getAllStockHistoryService } = StockHistoryServices();
  const { getAllSubCategoryService } = SubCategoryServices();
  const { getAllTagsService } = TagServices();
  const { getAllVendorService } = VendorServices();
  const { getAllWalletService } = WalletServices();
  const { getAllWarehouseService } = WarehouseServices();

  const { t } = useTranslation();
  const userData = useSelector((state) => state.auth?.user);
  const categoryOptions = [
    { value: "Product", label: "Product" },
    { value: "Beauty", label: "Beauty" },
  ];

  const packagerTypeOptions = [
    { value: "0", label: "Manufacturer" },
    { value: "1", label: "Packager" },
    { value: "2", label: "Importer" },
  ];

  // const discountTypeOptions = [
  //   { value: '0', label: '₹' },
  //   { value: '1', label: '%' }
  // ]

  const discountTypeOptions = [
    { value: "0", label: userData?.data?.symbol ? userData.data.symbol : "₹" },
    { value: "1", label: "%" },
  ];

  const activeDeleteOption = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const taxTypeOption = [
    {
      value: "no tax",
      label: "No tax",
    },
    {
      value: "GST",
      label: "GST",
    },
    {
      value: "VAT",
      label: "VAT",
    },
  ];
  const paymentTypeOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "UPI" },
    { value: "2", label: "Card" },
    { value: "3", label: "Cash" },
    { value: "4", label: "Other" },
  ];

  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [sidebarOptions, setSidebarOptions] = useState([]);

  useEffect(() => {
    const filteredsidebarData = sideBarData.filter(
      (data) => !(data.allow === "0" || data.parent_id === 0)
    );

    const formattedSidebarOptions = filteredsidebarData.map((data) => {
      const routeName = getRouteNameFromSidebar(data.name, localisation); // Get the localized name
      return {
        value: routeName, // Set the localized name as the value
        label: routeName, // Set the localized name as the label
      };
    });

    setSidebarOptions(formattedSidebarOptions);
  }, [sideBarData, localisation]);

  const monthOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const actionOption = [
    { value: "Add", label: "Add" },
    { value: "Update", label: "Update" },
    { value: "Delete", label: "Delete" },
  ];
  const currentYear = new Date().getFullYear();

  // Generate year options dynamically
  const yearOptions = Array.from({ length: 11 }, (_, index) => {
    const year = currentYear - index;
    return { value: year, label: `${year}` };
  });

  const [initialValues, setInitialValues] = useState({});
  const roleFilter = useSelector((state) => state.roleFilter);
  const adminFilter = useSelector((state) => state.adminFilter);
  const categoryFilter = useSelector((state) => state.categoryFilter);
  const SubCategoryFilter = useSelector((state) => state.SubCategoryFilter);
  const leafFilter = useSelector((state) => state.leafFilter);
  const tagFilter = useSelector((state) => state.tagFilter);
  const spotFilter = useSelector((state) => state.spotFilter);
  const imageFilter = useSelector((state) => state.imageFilter);
  const brandFilter = useSelector((state) => state.brandFilter);
  const productFilter = useSelector((state) => state.productFilter);
  const attributeFilter = useSelector((state) => state.attributeFilter);
  const warehouseFilter = useSelector((state) => state.warehouseFilter);
  const packagerFilter = useSelector((state) => state.packagerFilter);
  const discountFilter = useSelector((state) => state.discountFilter);
  const inventoryItemFilter = useSelector((state) => state.inventoryItemFilter);
  const discountCatalogueFilter = useSelector(
    (state) => state.discountCatalogueFilter
  );
  const inventoryFilter = useSelector((state) => state.inventoryFilter);
  const productInventoryFilter = useSelector(
    (state) => state.productInventoryFilter
  );
  const vendorFilter = useSelector((state) => state.vendorFilter);
  const purchaseRequisitionFilter = useSelector(
    (state) => state.purchaseRequisitionFilter
  );
  const customerAnalyticsFilter = useSelector(
    (state) => state.customerAnalyticsFilter
  );
  const purchaseOrderFilter = useSelector((state) => state.purchaseOrderFilter);
  const walletFilter = useSelector((state) => state.walletFilter);
  const GRNFilter = useSelector((state) => state.GRNFilter);
  const stockHistoryFilter = useSelector((state) => state.stockHistoryFilter);
  const stockFilter = useSelector((state) => state.stockFilter);
  const newVendorFilter = useSelector((state) => state.newVendorFilter);
  const unitsFilter = useSelector((state) => state.unitsFilter);
  const branchFilter = useSelector((state) => state.branchFilter);
  const ServiceProviderFilter = useSelector(
    (state) => state.ServiceProviderFilter
  );
  const serviceFilter = useSelector((state) => state.serviceFilter);
  const orderFilter = useSelector((state) => state.OrderFilter);
  const customerOrdersFilter = useSelector(
    (state) => state.customerOrdersFilter
  );
  const customerFilter = useSelector((state) => state.customerFilter);
  const preferencesFilter = useSelector((state) => state.preferencesFilter);
  const allPreferencesFilter = useSelector((state) => state.preferencesFilter);
  const salesGraphFilter = useSelector((state) => state.salesGraphFilter);
  const salesTableFilter = useSelector((state) => state.salesTableFilter);
  const eventLogFilter = useSelector((state) => state.eventLogFilter);
  const customerRushFilter = useSelector((state) => state.customerRushFilter);
  const expenseGraphFilter = useSelector((state) => state.expenseGraphFilter);
  const settlementGraphFilter = useSelector(
    (state) => state.settlementGraphFilter
  );
  const expenseFilter = useSelector((state) => state.expenseFilter);
  const companyFilter = useSelector((state) => state.companyFilter);

  const dispatch = useDispatch();
  const token = UtilsData();

  /**
   * Getting category data
   */
  const categoryListData = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [categoryNewOptionsData, setCategoryNewOptionsData] = useState([]);

  /**
   * Fetching category data
   */
  const fetchCategory = (type) => {
    const roleRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    if (type) {
      roleRequestBody.p_type = type;
    }
    getAllCategoryService(roleRequestBody, token, dispatch, true, true);
  };

  useEffect(() => {
    dispatch(clearTotalCategory());
    // eslint-disable-next-line
  }, []);

  // const subCategoryListData = useSelector((state) => state.totalSubCategory);
  // const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  /**
   * Fetting sub category data
   */
  // const fetchSubCategory = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 10000000,
  //   };
  //   getAllSubCategoryService(requestBody, token, dispatch, true);
  // };

  // const totalLeaf = useSelector((state) => state.totalLeaf);
  // const [leafCategoryListOptions, setLeafCategoryListOptions] = useState([]);

  /**
   * Fetting leaf level category data
   */
  // const fetchLeaf = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 5000000,
  //   };
  //   getAllLeafLevelCategoryService(requestBody, token, dispatch, true);
  // };

  const totalTag = useSelector((state) => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  /**
   * Fetting tags data
   */
  const fetchTag = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllTagsService(requestBody, token, dispatch, true);
  };

  const users = useSelector((state) => state.adminUser);
  const [usersOptions, setUserOptions] = useState([]);

  /**
   * Fetting users data
   */
  const fetchUsers = () => {
    const requestBody = {
      skip: 0,
      take: 10000000,
    };
    getUserListService(requestBody, token, dispatch, true);
  };

  const totalVendorPerson = useSelector((state) => state.totalNewVendor);
  const [vendorPersonListOptions, setVendorPersonListOptions] = useState([]);
  const fetchVendorPerson = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllNewVendorPersonService(requestBody, token, dispatch, true);
  };

  const typeOptions = [
    { value: "0", label: "Product" },
    { value: "1", label: "Blog" },
    { value: "2", label: "Service" },
  ];

  const orderTypeOptions = [
    { value: "0", label: "Dine In" },
    { value: "1", label: "Take Out" },
    { value: "2", label: "Delivery" },
  ];

  const orderStatusOptions = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Confirmed" },
    { value: "2", label: "In Progress" },
    { value: "3", label: "Completed" },
    { value: "4", label: "Cancelled" },
  ];

  const vendorOrderStatusOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Completed" },
    { value: "2", label: "Cancelled" },
  ];

  const purchaseRequisitionStatusOption = [
    {
      value: "0",
      label: "Pending",
    },
    {
      value: "1",
      label: "Approved",
    },
    {
      value: "2",
      label: "Partially Approved",
    },
    {
      value: "3",
      label: "Rejected",
    },
    {
      value: "4",
      label: "PO Generated",
    },
  ];

  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandListOptions, setBrandListOptions] = useState([]);

  /**
   * Fetting brand data
   */
  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBrandService(requestBody, token, dispatch, true);
  };

  const totalWarehouse = useSelector((state) => state.totalWarehouse);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  /**
   * Fetting warehouse data
   */
  const fetchWarehouse = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllWarehouseService(requestBody, token, dispatch, true);
  };

  const totalBranch = useSelector((state) => state.totalBranch);
  const [branchOptions, setBranchOptions] = useState([]);

  /**
   * Fetting branch data
   */
  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBranchService(requestBody, token, dispatch, true);
  };

  const totalProduct = useSelector((state) => state.totalProduct);
  const [productOptions, setProductOptions] = useState([]);

  /**
   * Fetching product data from API
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
    };
    getAllProductService(requestBody, token, dispatch, true);
  };

  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [inventoryItemOptions, setInventoryItemOptions] = useState([]);
  /**
   * Fetching product data from API
   */
  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  //Reinitializing initial values
  useEffect(() => {
    // Customer page
    if (props.page === "customer") {
      setInitialValues({
        phone: customerFilter.phone,
      });
    }

    // Customer page
    if (props.page === "preferences") {
      setInitialValues({
        productName: preferencesFilter.p_product_name,
      });
    }
    if (props.page === "allPreferences") {
      setInitialValues({
        productName: allPreferencesFilter.p_product_name,
      });
    }
    if (props.page === "salesGraph") {
      setInitialValues({
        year: salesGraphFilter.year,
        month: salesGraphFilter.month,
      });
    }
    if (props.page === "salesData") {
      setInitialValues({
        year: salesTableFilter.year,
        month: salesTableFilter.month,
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(salesTableFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(salesTableFilter?.endDate)
        ),
      });
    }
    if (props.page === "eventLog") {
      setInitialValues({
        action: eventLogFilter.Action,
        module: eventLogFilter.module,
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(eventLogFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(eventLogFilter?.endDate)
        ),
        user: eventLogFilter.user,
      });

      if (users?.items?.length === 0) {
        fetchUsers();
      } else if (users?.items[0]?.data?.length > 0) {
        const userOptions = users.items[0].data.map((item) => ({
          value: item.userId,
          label: `${item.firstName} ${item.lastName}`,
        }));
        setUserOptions(userOptions);
      }
    }

    // Expense Page
    if (props.page === "expense") {
      setInitialValues({
        name: expenseFilter.title,
        amount: expenseFilter.amount,
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(expenseFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(expenseFilter?.endDate)
        ),
        category:
          expenseFilter.category !== ""
            ? Number(expenseFilter.category)
            : expenseFilter.category,
      });

      if (categoryListData?.items?.length === 0) {
        fetchCategory("5");
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data
          .filter((item) => item.type === "5" || item.type === 5) // Filter items with type 5
          .map((item) => ({
            value: item.categoryId,
            label: item.name,
            type: item.type,
          }));
        setCategoryListOptions(categoryOptions);
      }
    }
    if (props.page === "wallet") {
      setInitialValues({
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(walletFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(walletFilter?.endDate)
        ),
      });
    }
    if (props.page === "customerRush") {
      setInitialValues({
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerRushFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerRushFilter?.endDate)
        ),
      });
    }
    if (props.page === "expenseGraph") {
      setInitialValues({
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(expenseGraphFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(expenseGraphFilter?.endDate)
        ),
        category:
          expenseGraphFilter.category !== ""
            ? Number(expenseGraphFilter.category)
            : expenseGraphFilter.category,
      });

      if (categoryListData?.items?.length === 0) {
        fetchCategory("5");
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data
          .filter((item) => item.type === "5" || item.type === 5) // Filter items with type 5
          .map((item) => ({
            value: item.categoryId,
            label: item.name,
            type: item.type,
          }));
        setCategoryListOptions(categoryOptions);
      }
    }
    if (props.page === "settlementGraph") {
      setInitialValues({
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(settlementGraphFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(settlementGraphFilter?.endDate)
        ),
      });
    }

    // Brand Page
    if (props.page === "brand") {
      setInitialValues({
        name: brandFilter.name,
        category: brandFilter.category,
        // subCategory: brandFilter.subCategory,
        // leafCategory: brandFilter.leafCategory,
        tags: brandFilter.tags,
        status: brandFilter.isActive,
        is_delete: brandFilter.isDelete,
        type: branchFilter.type,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
          type: item.type,
        }));
        setCategoryListOptions(categoryOptions);
      }

      // if (subCategoryListData?.items?.length === 0) {
      //   fetchSubCategory();
      // }

      // if (totalLeaf?.items?.length === 0) {
      //   fetchLeaf();
      // }
    }

    // Category page
    if (props.page === "category") {
      setInitialValues({
        name: categoryFilter.name,
        code: categoryFilter.code,
        status: categoryFilter.isActive,
        is_delete: categoryFilter.isDelete,
      });
    }

    // Sub Category Page
    if (props.page === "subCategory") {
      setInitialValues({
        name: SubCategoryFilter.name,
        code: SubCategoryFilter.code,
        category: SubCategoryFilter.category,
        type: SubCategoryFilter.type,
        status: SubCategoryFilter.isActive,
        is_delete: SubCategoryFilter.isDelete,
      });

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
          type: item.type,
        }));
        setCategoryListOptions(categoryOptions);
      }
    }

    // Product Page
    if (props.page === "product") {
      setInitialValues({
        name: productFilter.name,
        category: productFilter.category,
        subCategory: productFilter.subCategory,
        leafCategory: productFilter.leafCategory,
        tags: productFilter.tags,
        sku: productFilter.sku,
        brand: productFilter.brand,
        status: productFilter.isActive,
        is_delete: productFilter.isDelete,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory("0");
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        setCategoryListOptions(categoryOptions);
      }

      // if (subCategoryListData?.items?.length === 0) {
      //   fetchSubCategory();
      // } else if (subCategoryListData?.items[0]?.data?.length > 0) {
      //   const subCategoryOptions = subCategoryListData.items[0].data.map(
      //     (item) => ({
      //       value: item.id,
      //       label: item.name,
      //     })
      //   );
      //   setSubCategoryListOptions(subCategoryOptions);
      // }

      // if (totalLeaf?.items?.length === 0) {
      //   fetchLeaf();
      // } else if (totalLeaf?.items[0]?.data?.length > 0) {
      //   const leafCategoryOptions = totalLeaf.items[0].data.map((item) => ({
      //     value: item.id,
      //     label: item.Title,
      //   }));
      //   setLeafCategoryListOptions(leafCategoryOptions);
      // }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }
    }

    // Product Pricing Page
    if (props.page === "product-pricing") {
      setInitialValues({
        name: productFilter.name,
        sku: productFilter.sku,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      // if (subCategoryListData?.items?.length === 0) {
      //   fetchSubCategory();
      // } else if (subCategoryListData?.items[0]?.data?.length > 0) {
      //   const subCategoryOptions = subCategoryListData.items[0].data.map(
      //     (item) => ({
      //       value: item.id,
      //       label: item.name,
      //     })
      //   );
      //   setSubCategoryListOptions(subCategoryOptions);
      // }

      // if (totalLeaf?.items?.length === 0) {
      //   fetchLeaf();
      // } else if (totalLeaf?.items[0]?.data?.length > 0) {
      //   const leafCategoryOptions = totalLeaf.items[0].data.map((item) => ({
      //     value: item.id,
      //     label: item.Title,
      //   }));
      //   setLeafCategoryListOptions(leafCategoryOptions);
      // }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }
    }

    // Image Gallery Page
    if (props.page === "imageManager") {
      setInitialValues({
        type: imageFilter.type,
        is_delete: imageFilter.isDelete,
      });
    }

    // Leaf level category page
    if (props.page === "leafCategory") {
      setInitialValues({
        name: leafFilter.name,
        subCategory: leafFilter.subCategory,
        status: leafFilter.isActive,
        is_delete: leafFilter.isDelete,
      });

      // if (subCategoryListData?.items?.length === 0) {
      //   fetchSubCategory();
      // } else if (subCategoryListData?.items[0]?.data?.length > 0) {
      //   const subCategoryOptions = subCategoryListData.items[0].data.map(
      //     (item) => ({
      //       value: item.id,
      //       label: item.name,
      //     })
      //   );
      //   setSubCategoryListOptions(subCategoryOptions);
      // }
    }

    // Tags Page
    if (props.page === "tags") {
      setInitialValues({
        name: tagFilter?.title,
        status: tagFilter?.isActive,
        is_delete: tagFilter?.isDelete,
      });
    }

    // Spots Page
    if (props.page === "spots") {
      setInitialValues({
        name: spotFilter?.p_name,
        is_available: spotFilter?.p_is_available,
        is_active: spotFilter?.p_is_active,
        is_deleted: spotFilter?.p_is_deleted,
      });
    }

    // Inventory Item Page
    if (props.page === "inventory-item") {
      setInitialValues({
        name: inventoryItemFilter?.title,
        is_available: inventoryItemFilter?.isActive,
        is_active: inventoryItemFilter?.isDelete,
      });
    }

    // Product Inventory Item Page
    if (props.page === "product-inventory") {
      setInitialValues({
        productId: productInventoryFilter?.productId,
      });

      if (totalProduct?.items?.length === 0) {
        fetchProduct();
      } else if (totalProduct?.items[0]?.data?.length > 0) {
        const productOptions = totalProduct.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.productId,
        }));
        setProductOptions(productOptions);
      }
    }

    // Stocks Page
    if (props.page === "stocks" || props.page === "stock") {
      setInitialValues({
        ingredientId: stockFilter?.ingredientId,
      });

      if (totalInventoryItem?.items?.length === 0) {
        fetchInventoryItem();
      } else if (totalInventoryItem?.items[0]?.data?.length > 0) {
        const totalInventoryOptions = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
          })
        );
        setInventoryItemOptions(totalInventoryOptions);
      }
    }
    // Vendor Page
    if (props.page === "vendors") {
      setInitialValues({
        orderNo: vendorFilter?.orderNo,
        orderStartDate: vendorFilter.orderStartDate,
        orderEndDate: vendorFilter.orderEndDate,
        status: vendorFilter.status,
        payment: vendorFilter.orderType,
        vendor: vendorFilter.vendor,
      });
      if (
        (totalVendorPerson?.items && totalVendorPerson?.items.length === 0) ||
        !totalVendorPerson
      ) {
        fetchVendorPerson();
      } else if (totalVendorPerson?.items[0]?.data?.length > 0) {
        const totalVendorPersonOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          })
        );
        setVendorPersonListOptions(totalVendorPersonOptions);
      }
    }
    if (props.page === "purchase-requisition") {
      setInitialValues({
        purchaseRequisitionNo: purchaseRequisitionFilter?.purchaseRequisitionNo,
        orderStartDate: purchaseRequisitionFilter.orderStartDate,
        orderEndDate: purchaseRequisitionFilter.orderEndDate,
        status: purchaseRequisitionFilter.status,
        vendor: purchaseRequisitionFilter.vendor,
      });
      if (
        (totalVendorPerson?.items && totalVendorPerson?.items.length === 0) ||
        !totalVendorPerson
      ) {
        fetchVendorPerson();
      } else if (totalVendorPerson?.items[0]?.data?.length > 0) {
        const totalVendorPersonOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          })
        );
        setVendorPersonListOptions(totalVendorPersonOptions);
      }
    }
    if (props.page === "purchase-order") {
      setInitialValues({
        purchaseOrderNo: purchaseOrderFilter?.purchaseOrderNo,
        orderStartDate: purchaseOrderFilter.orderStartDate,
        orderEndDate: purchaseOrderFilter.orderEndDate,
      });
    }

    // Example usage for initial values
    if (props.page === "analytics") {
      setInitialValues({
        StartDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerAnalyticsFilter?.StartDate)
        ),
        EndDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerAnalyticsFilter?.EndDate)
        ),
        branchId: customerAnalyticsFilter.branchId,
      });
    }

    if (props.page === "grn") {
      setInitialValues({
        GRNNo: GRNFilter?.GRNNo,
        orderStartDate: GRNFilter.orderStartDate,
        orderEndDate: GRNFilter.orderEndDate,
      });
    }

    if (props.page === "new-vendor-person") {
      setInitialValues({
        firstName: newVendorFilter?.firstName,
        lastName: newVendorFilter.lastName,
        phNum: newVendorFilter.phNum,
        isActive: newVendorFilter.isActive,
        isDelete: newVendorFilter.isDelete,
      });
    }

    if (props.page === "units") {
      setInitialValues({
        unit: unitsFilter.unitId,
        isActive: unitsFilter.isActive,
        isDelete: unitsFilter.isDelete,
      });
    }

    // Stock History page
    if (props.page === "StockHistory") {
      setInitialValues({
        ingredientId: stockHistoryFilter?.ingredientId,
        orderDate: stockHistoryFilter.orderDate,
      });

      if (totalInventoryItem?.items?.length === 0) {
        fetchInventoryItem();
      } else if (totalInventoryItem?.items[0]?.data?.length > 0) {
        const totalInventoryOptions = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
          })
        );
        setInventoryItemOptions(totalInventoryOptions);
      }
    }

    // Attributes Page
    if (props.page === "attributes") {
      setInitialValues({
        name: attributeFilter.name,
        status: attributeFilter.isActive,
        is_delete: attributeFilter.isDelete,
      });
    }

    // Warehouse Page
    if (props.page === "warehouse") {
      setInitialValues({
        name: warehouseFilter.name,
        status: warehouseFilter.isActive,
        is_delete: warehouseFilter.isDelete,
      });
    }

    // Admin User Page
    if (props.page === "users") {
      setInitialValues({
        phone: adminFilter.phone,
        status: adminFilter.isActive,
        is_delete: adminFilter.isDelete,
      });
    }

    // Policy Page
    if (props.page === "policy") {
      setInitialValues({
        name: "",
        type: "",
        status: "",
        is_delete: "",
      });
    }

    // Order Page
    if (props.page === "order") {
      setInitialValues({
        orderDate: orderFilter.orderDate,
        status: orderFilter.status,
        type: orderFilter.orderType,
      });
    }
    if (props.page === "customerOrders") {
      setInitialValues({
        startDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerOrdersFilter?.startDate)
        ),
        endDate: parseDateFromDDMMYYYY(
          formatDateToDDMMYYYY(customerOrdersFilter?.endDate)
        ),
        status: customerOrdersFilter.status,
        payment: customerOrdersFilter.payment,
        type: customerOrdersFilter.orderType,
      });
    }

    // Role Page
    if (props.page === "roles") {
      setInitialValues({
        name: roleFilter.name,
        status: roleFilter.isActive,
        is_delete: roleFilter.isDelete,
      });
    }

    // Company Page
    if (props.page === "company") {
      setInitialValues({
        pincode: companyFilter.p_pincode,
        company_address: companyFilter.p_address,
        company_name: companyFilter.p_company_name,
      });
    }

    // Packager Page
    if (props.page === "packager") {
      setInitialValues({
        name: packagerFilter.name,
        type: packagerFilter.type,
        status: packagerFilter.isActive,
        is_delete: packagerFilter.isDelete,
      });
    }

    // Discount Page
    if (props.page === "discount") {
      setInitialValues({
        name: discountFilter.name,
        type: discountFilter.type,
        start_date: discountFilter.start_date,
        end_date: discountFilter.end_date,
        code: discountFilter.code,
        status: discountFilter.isActive,
        is_delete: discountFilter.isDelete,
      });
    }

    // Discount Catalogue Page
    if (props.page === "discountCatalogue") {
      setInitialValues({
        name: discountCatalogueFilter.name,
        start_date: discountCatalogueFilter.start_date,
        end_date: discountCatalogueFilter.end_date,
        status: discountCatalogueFilter.isActive,
        is_delete: discountCatalogueFilter.isDelete,
      });
    }

    // Inventory Page
    if (props.page === "inventory") {
      setInitialValues({
        code: inventoryFilter.productCode,
        name: inventoryFilter.name,
        sku: inventoryFilter.sku,
        warehouse: inventoryFilter.warehouse,
        status: inventoryFilter.isActive,
        is_delete: inventoryFilter.isDelete,
      });

      if (totalWarehouse?.items?.length === 0) {
        fetchWarehouse();
      } else if (totalWarehouse?.items[0]?.data?.length > 0) {
        const warehouse = totalWarehouse.items[0].data.map((item) => ({
          value: item.warehouseId,
          label: item.name,
          is_active: item.is_active,
        }));
        setWarehouseOptions(warehouse);
      }
    }

    // Branch Page
    if (props.page === "branch") {
      setInitialValues({
        name: branchFilter.name,
        pincode: branchFilter.pincode,
        status: branchFilter.isActive,
        is_delete: branchFilter.isDelete,
        is_public_avilable: branchFilter.isPublicAvailable,
        is_wp_sms_allow: branchFilter.isWpSmsAllow,
        is_delivery_available: branchFilter.isDeliveryAvailable,
        tax_type: branchFilter.taxType,
      });
    }

    // Service Provider Page
    if (props.page === "serviceProvider") {
      setInitialValues({
        name: ServiceProviderFilter.name,
        status: ServiceProviderFilter.isActive,
        is_delete: ServiceProviderFilter.isDelete,
      });
    }

    // Service Page
    if (props.page === "service") {
      setInitialValues({
        name: serviceFilter.name,
        tags:
          serviceFilter.tag === ""
            ? []
            : serviceFilter.tag.split(",").map(Number),
        brand: serviceFilter.brand,
        branch:
          serviceFilter.branch === ""
            ? []
            : serviceFilter.branchs.split(",").map(Number),
        category: serviceFilter.category,
        subcategory:
          serviceFilter.subCategory === ""
            ? []
            : serviceFilter.subCategory.split(",").map(Number),
        status: serviceFilter.isActive,
        is_delete: serviceFilter.isDelete,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory("3");
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        setCategoryListOptions(categoryOptions);
      }

      // if (subCategoryListData?.items?.length === 0) {
      //   fetchSubCategory();
      // } else if (subCategoryListData?.items[0]?.data?.length > 0) {
      //   const subCategoryOptions = subCategoryListData.items[0].data.map(
      //     (item) => ({
      //       value: item.id,
      //       label: item.name,
      //     })
      //   );
      //   setSubCategoryListOptions(subCategoryOptions);
      // }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }

      if (totalBranch?.items?.length === 0) {
        fetchBranch();
      } else if (totalBranch?.items[0]?.data?.length > 0) {
        const branchOptions = totalBranch.items[0].data.map((item) => ({
          value: item.branchId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBranchOptions(branchOptions);
      }
    }

    // eslint-disable-next-line
  }, [
    props.page,
    totalTag,
    // subCategoryListData,
    // totalLeaf,
    categoryListData,
    totalBrand,
    totalBranch,
    totalWarehouse,
    totalProduct,
    productInventoryFilter,
    stockFilter,
    vendorFilter,
    attributeFilter,
    warehouseFilter,
    adminFilter,
    orderFilter,
    roleFilter,
    companyFilter,
    packagerFilter,
    discountFilter,
    discountCatalogueFilter,
    inventoryFilter,
    ServiceProviderFilter,
    serviceFilter,
    totalInventoryItem,
    totalVendorPerson,
    users,
    expenseFilter,
  ]);

  /**
   * Handle submit for filter
   */
  const handleSubmit = (values) => {
    //Role page
    if (props.page === "roles") {
      dispatch(clearRolePageData());
      const roleRequestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        roleRequestBody.p_search_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        roleRequestBody.p_is_active = values.status;
      } else {
        roleRequestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        roleRequestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getRoleFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getRolesService(roleRequestBody, token, dispatch, "filter");
        dispatch(clearRolePageData());
      }
    }
    // Company page
    if (props.page === "company") {
      dispatch(clearCompanyPageData());
      const companyRequestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.pincode !== "" && values.pincode !== null) {
        companyRequestBody.p_pincode = values.pincode;
      }
      if (values.company_address !== "" && values.company_address !== null) {
        companyRequestBody.p_address = values.company_address;
      }
      if (values.company_name !== "" && values.company_name !== null) {
        companyRequestBody.p_company_name = values.company_name;
      }

      // else {
      //   companyRequestBody.p_is_active = '1';
      // }
      if (values.is_delete !== "" && values.is_delete !== null) {
        companyRequestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.pincode === "" &&
        values.company_address === "" &&
        values.company_name === ""
      ) {
        toast.error("Please add filters!");
      } else {
        // dispatch(getCompanyFilterData({ country_name: values.country_name, isActive: values.status, isDelete: values.is_delete }));
        dispatch(
          getCompanyFilterData({
            p_company_name: values.company_name,
            p_pincode: values.pincode,
            p_address: values.company_address,
          })
        );
        getCompanyService(companyRequestBody, token, dispatch, "filter");
        dispatch(clearCompanyData());
      }
    }

    //Admin user page
    else if (props.page === "users") {
      dispatch(clearAdminPageData());
      const requestData = {
        skip: 0,
        take: 10,
      };
      if (values.phone !== "" && values.phone !== null) {
        requestData.search_phone_number = values.phone;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.search_is_active = values.status;
      } else {
        requestData.search_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_IsDeleted = values.is_delete;
      }
      if (
        values.phone === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getAdminFilterData({
            phone: values.phone,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getUserListService(requestData, token, dispatch, "filter");
        dispatch(clearAdminPageData());
      }
    }
    //Customer list page
    else if (props.page === "customer") {
      dispatch(clearCustomerPageData());
      const requestData = {
        skip: 0,
        take: 10,
      };
      if (values.phone !== "" && values.phone !== null) {
        requestData.search_phone_number = values.phone;
      }
      if (values.phone === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(getCustomerFilterData({ phone: values.phone }));
        getCustomerListService(requestData, token, dispatch, "filter");
        dispatch(clearCustomerPageData());
      }
    }
    //Customer list page
    else if (props.page === "preferences") {
      dispatch(clearPreferencesPageData());
      const requestData = {
        p_skip: 0,
        p_take: 10,
        p_user_id: props.userId,
        p_branch_id: userData.data.branchId,
      };
      if (values.productName !== "" && values.productName !== null) {
        requestData.p_product_name = values.productName;
      }
      if (values.productName === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getPreferencesFilterData({ p_product_name: values.productName })
        );
        getAllPreferencesService(requestData, token, dispatch, "filter");
        dispatch(clearPreferencesPageData());
      }
    }

    //Customer list page
    else if (props.page === "allPreferences") {
      dispatch(clearAllPreferencesPageData());
      const requestData = {
        p_skip: 0,
        p_take: 10,
        p_user_id: props.userId,
      };
      if (values.productName !== "" && values.productName !== null) {
        requestData.p_product_name = values.productName;
      }
      if (values.productName === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getAllPreferencesFilterData({ p_product_name: values.productName })
        );
        getAllPreferencesService(requestData, token, dispatch, "filter");
        dispatch(clearAllPreferencesPageData());
      }
    }

    //Customer list page
    else if (props.page === "salesGraph") {
      const requestData = {};
      if (values.year !== "" && values.year !== null) {
        requestData.p_year = Number(values.year);
      }
      if (values.month !== "" && values.month !== null) {
        requestData.p_month = Number(values.month);
      }

      if (values.year === "" && values.month === "") {
        toast.error("Please add filter!");
      } else if (values.year === "") {
        toast.error("Please add Year!");
      } else {
        dispatch(
          getSalesGraphFilter({
            year: Number(values.year),
            month: values.month,
          })
        );
        getSalesChartDataService(requestData, token, dispatch, "filter");
      }
    } else if (props.page === "salesData") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      if (values.year !== "" && values.year !== null) {
        requestBody.p_year = Number(values.year);
      }
      if (values.month !== "" && values.month !== null) {
        requestBody.p_month = Number(values.month);
      }

      // Check if all fields are empty strings
      if (
        values.endDate === "" &&
        values.startDate === "" &&
        values.month === "" &&
        values.year === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error(
            "Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearSalesTablePageData());

          dispatch(
            getSalesTableFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
              year: Number(values.year),
              month: values.month,
            })
          );

          return getSalesTableDataService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearSalesTablePageData());
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        dispatch(
          getSalesTableFilterData({
            startDate: format(startDateObj, "dd-MM-yyyy"),
            endDate: format(endDateObj, "dd-MM-yyyy"),
            year: Number(values.year),
            month: values.month,
          })
        );

        return getSalesTableDataService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "eventLog") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      if (values.action !== "" && values.action !== null) {
        requestBody.p_action = values.action;
      }
      if (values.module !== "" && values.module !== null) {
        requestBody.p_module = values.module;
      }
      if (values.user !== "" && values.user !== null) {
        requestBody.p_user_id = values.user;
      }

      // Check if all fields are empty strings
      if (
        values.endDate === "" &&
        values.startDate === "" &&
        values.action === "" &&
        values.module === "" &&
        values.user === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error("End Date cannot be earlier than Start Date!");
        } else {
          dispatch(clearEventLogPageData());
          dispatch(
            getEventLogFilterData({
              module: values.module,
              Action: values.action,
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
              user: values.user,
            })
          );
          return getEventLogDataService(requestBody, token, dispatch, "filter");
        }
      } else {
        dispatch(clearEventLogPageData());
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        getEventLogFilterData({
          module: values.module,
          Action: values.action,
          startDate: format(startDateObj, "dd-MM-yyyy"),
          endDate: format(endDateObj, "dd-MM-yyyy"),
          user: values.user,
        });
        return getEventLogDataService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "expense") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.amount !== "" && values.amount !== null) {
        requestBody.p_amount = values.amount;
      }
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category;
      }

      // Check if all fields are empty strings
      if (
        values.endDate === "" &&
        values.startDate === "" &&
        values.name === "" &&
        values.amount === "" &&
        values.category === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error("End Date cannot be earlier than Start Date!");
        } else {
          dispatch(clearExpensePageData());
          dispatch(
            getExpenseFilterData({
              title: values.name,
              amount: values.amount,
              category: String(values.category),
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
            })
          );

          return getAllExpenseService(requestBody, token, dispatch, "filter");
        }
      } else {
        dispatch(clearExpensePageData());
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        getExpenseFilterData({
          title: values.name,
          amount: values.amount,
          category: String(values.category),
          startDate: format(startDateObj, "dd-MM-yyyy"),
          endDate: format(endDateObj, "dd-MM-yyyy"),
        });

        return getAllExpenseService(requestBody, token, dispatch, "filter");
      }
    } // wallet page
    else if (props.page === "wallet") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (values.startDate === "" && values.endDate === "") {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error(
            "Wallet's End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearWalletPageData());

          dispatch(
            getWalletFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
            })
          );
          return getAllWalletService(requestBody, token, dispatch, "filter");
        }
      } else {
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        dispatch(clearWalletPageData());

        dispatch(
          getWalletFilterData({
            startDate: format(startDateObj, "dd-MM-yyyy"),
            endDate: format(endDateObj, "dd-MM-yyyy"),
          })
        );
        return getAllWalletService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "customerRush") {
      const requestBody = {
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (values.endDate === "" && values.startDate === "") {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error(
            "Event Log's End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearCustomerRushData());
          dispatch(
            getCustomerRushFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
            })
          );
          return getCustomerRushService(requestBody, token, dispatch, "filter");
        }
      } else {
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        dispatch(clearCustomerRushData());
        getCustomerRushFilterData({
          startDate: format(startDateObj, "dd-MM-yyyy"),
          endDate: format(endDateObj, "dd-MM-yyyy"),
        });
        return getCustomerRushService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "expenseGraph") {
      const requestBody = {
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category;
      }

      // Check if all fields are empty strings
      if (
        values.endDate === "" &&
        values.startDate === "" &&
        values.category === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error("End Date cannot be earlier than Start Date!");
        } else {
          dispatch(clearExpenseGraphData());
          dispatch(
            getExpenseGraphFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
              category: String(values.category),
            })
          );
          return getExpenseGraphService(requestBody, token, dispatch, "filter");
        }
      } else {
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        dispatch(clearExpenseGraphData());
        getExpenseGraphFilterData({
          startDate: format(startDateObj, "dd-MM-yyyy"),
          endDate: format(endDateObj, "dd-MM-yyyy"),
          category: String(values.category),
        });
        return getExpenseGraphService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "settlementGraph") {
      const requestBody = {
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (values.endDate === "" && values.startDate === "") {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error("End Date cannot be earlier than Start Date!");
        } else {
          dispatch(clearSettlementGraphData());
          dispatch(
            getSettlementGraphFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
            })
          );
          return getSettlementGraphService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);
        dispatch(clearSettlementGraphData());
        dispatch(
          getSettlementGraphFilterData({
            startDate: format(startDateObj, "dd-MM-yyyy"),
            endDate: format(endDateObj, "dd-MM-yyyy"),
          })
        );
        return getSettlementGraphService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }
    //category page
    else if (props.page === "category") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.code !== "" && values.code !== null) {
        requestData.p_category_code = values.code;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.code === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getCategoryFilterData({
            name: values.name,
            code: values.code,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearCategoryPageData());
      }
    }
    //sub category page
    else if (props.page === "subCategory") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.category !== "" && values.category !== null) {
        requestData.p_categoryIds = values.category;
      }
      if (values.code !== "" && values.code !== null) {
        requestData.p_category_code = values.code;
      }
      if (values.type !== "" && values.type !== null) {
        requestData.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.category === "" &&
        values.code === "" &&
        values.type === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getSubCategoryFilterData({
            name: values.name,
            category: values.category,
            code: values.code,
            type: values.type,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllSubCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearSubCategoryPageData());
      }
    }
    //leaf level category page
    else if (props.page === "leafCategory") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.subCategory !== "" && values.subCategory !== null) {
        requestData.p_sub_categoryIds = values.subCategory;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.subCategory === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getLeafFilterData({
            name: values.name,
            subCategory: values.subCategory,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllLeafLevelCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearLeafPageData());
      }
    }
    //tags page
    else if (props.page === "tags" || props.page === "tag") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_isActive = values.status;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearTagPageData());
        dispatch(
          getTagFilterData({
            title: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllTagsService(requestBody, token, dispatch, "filter");
      }
    }

    //spots page
    else if (props.page === "spots" || props.page === "spot") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.is_active !== "" && values.is_active !== null) {
        requestBody.p_is_active = values.is_active;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_deleted !== "" && values.is_deleted !== null) {
        requestBody.p_is_deleted = values.is_deleted;
      }
      if (values.is_available !== "" && values.is_available !== null) {
        requestBody.p_is_available = values.is_available;
      }

      // Check if all fields are empty strings
      if (
        values.name === "" &&
        values.is_available === "" &&
        values.is_deleted === "" &&
        values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearSpotPageData());

        dispatch(
          getSpotFilterData({
            name: values.name,
            p_is_active: values.is_active,
            p_is_deleted: values.is_deleted,
            p_is_available: values.is_available,
          })
        );

        return getAllSpotService(requestBody, token, dispatch, "filter");
      }
    }

    // inventory item page
    else if (
      props.page === "inventory-items" ||
      props.page === "inventory-item"
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.is_active !== "" && values.is_active !== null) {
        requestBody.p_isActive = values.is_active;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_deleted !== "" && values.is_deleted !== null) {
        requestBody.p_IsDeleted = values.is_deleted;
      }

      // Check if all fields are empty strings
      if (
        values.name === "" &&
        values.is_deleted === "" &&
        values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearInventoryItemPageData());

        dispatch(
          getInventoryItemFilterData({
            title: values.name,
            isActive: values.is_active,
            isDelete: values.is_deleted,
          })
        );

        return getAllInventoryItemService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    } else if (props.page === "product-inventory") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.productId !== "" && values.productId !== null) {
        requestBody.p_product_id = values.productId;
      }
      // if (values.is_active !== "" && values.is_active !== null) {
      //   requestBody.p_isActive = values.is_active;
      // } else {
      //   requestBody.p_isActive = "1";
      // }
      // if (values.is_deleted !== "" && values.is_deleted !== null) {
      //   requestBody.p_IsDeleted = values.is_deleted;
      // }

      // Check if all fields are empty strings
      if (
        values.productId === ""
        // values.productId === "" &&
        // values.is_deleted === "" &&
        // values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearProductInventoryPageData());

        dispatch(
          getProductInventoryFilterData({
            productId: values.productId,
            // isActive: values.is_active,
            // isDelete: values.is_deleted,
          })
        );

        return getAllProductInventoryService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    } else if (props.page === "stocks") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.ingredientId !== "" && values.ingredientId !== null) {
        requestBody.p_ingredient_id = values.ingredientId;
      }
      // if (values.is_active !== "" && values.is_active !== null) {
      //   requestBody.p_isActive = values.is_active;
      // } else {
      //   requestBody.p_isActive = "1";
      // }
      // if (values.is_deleted !== "" && values.is_deleted !== null) {
      //   requestBody.p_IsDeleted = values.is_deleted;
      // }

      // Check if all fields are empty strings
      if (
        values.ingredientId === ""
        // values.productId === "" &&
        // values.is_deleted === "" &&
        // values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearStockPageData());

        dispatch(
          getStockFilterData({
            ingredientId: values.ingredientId,
            // isActive: values.is_active,
            // isDelete: values.is_deleted,
          })
        );

        return getAllStocksService(requestBody, token, dispatch, "filter");
      }
    }

    // vendor order page
    else if (props.page === "vendors" || props.page === "vendor") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.orderNo !== "" && values.orderNo !== null) {
        requestBody.p_order_no = values.orderNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      if (values.vendor !== "" && values.vendor !== null) {
        requestBody.p_vendor_id = values.vendor;
      }

      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }

      if (values.payment !== "" && values.payment !== null) {
        requestBody.p_payment = values.payment;
      }

      // Check if all fields are empty strings
      if (
        values.orderNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === "" &&
        (values.vendor === "" || !values.vendor) &&
        values.status === "" &&
        values.payment === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearVendorPageData());

          dispatch(
            getVendorFilterData({
              orderNo: values.orderNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
              vendor: values.vendor,
              status: values.status,
              payment: values.payment,
            })
          );

          return getAllVendorService(requestBody, token, dispatch, "filter");
        }
      } else {
        dispatch(clearVendorPageData());

        dispatch(
          getVendorFilterData({
            orderNo: values.orderNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
            vendor: values.vendor,
            status: values.status,
            payment: values.payment,
          })
        );

        return getAllVendorService(requestBody, token, dispatch, "filter");
      }
    }

    // purchase requisition page
    else if (props.page === "purchase-requisition") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (
        values.purchaseRequisitionNo !== "" &&
        values.purchaseRequisitionNo !== null
      ) {
        requestBody.p_purchase_requisition_no = values.purchaseRequisitionNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      if (values.vendor !== "" && values.vendor !== null) {
        requestBody.p_vendor_id = values.vendor;
      }

      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }

      // Check if all fields are empty strings
      if (
        values.purchaseRequisitionNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === "" &&
        (values.vendor === "" || !values.vendor) &&
        values.status === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Requisition End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearPurchaseRequisitionPageData());

          dispatch(
            getPurchaseRequisitionFilterData({
              purchaseRequisitionNo: values.purchaseRequisitionNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
              vendor: values.vendor,
              status: values.status,
            })
          );

          return getAllPurchaseRequisitionService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearPurchaseRequisitionPageData());

        dispatch(
          getPurchaseRequisitionFilterData({
            purchaseRequisitionNo: values.purchaseRequisitionNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
            vendor: values.vendor,
            status: values.status,
          })
        );

        return getAllPurchaseRequisitionService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // purchase order page
    else if (props.page === "purchase-order") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.purchaseOrderNo !== "" && values.purchaseOrderNo !== null) {
        requestBody.p_purchase_order_no = values.purchaseOrderNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      // Check if all fields are empty strings
      if (
        values.purchaseOrderNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearPurchaseOrderPageData());

          dispatch(
            getPurchaseOrderFilterData({
              purchaseOrderNo: values.purchaseOrderNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
            })
          );

          return getAllPurchaseOrderService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearPurchaseOrderPageData());

        dispatch(
          getPurchaseOrderFilterData({
            purchaseOrderNo: values.purchaseOrderNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
          })
        );

        return getAllPurchaseOrderService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // purchase order page
    else if (props.page === "analytics") {
      const requestBody = {
        p_user_id: props.userId,
        p_branch_id: userData.data.branchId,
      };

      if (values.StartDate !== "" && values.StartDate !== null) {
        const startDate = new Date(values.StartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.EndDate !== "" && values.EndDate !== null) {
        const endDate = new Date(values.EndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (values.EndDate === "" && values.StartDate === "") {
        toast.error("Please add filter!");
      } else if (values.StartDate && values.EndDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.StartDate);
        const endDateObj = new Date(values.EndDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error(
            "Analytics End Date cannot be earlier than Analytics Start Date!"
          );
        } else {
          dispatch(
            getCustomerAnalyticsFilterData({
              StartDate: format(startDateObj, "dd-MM-yyyy"),
              EndDate: format(endDateObj, "dd-MM-yyyy"),
            })
          );

          return getCustomerAnalyticsService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(
          getCustomerAnalyticsFilterData({
            StartDate: format(new Date(values.StartDate), "dd-MM-yyyy"),
            EndDate: format(new Date(values.EndDate), "dd-MM-yyyy"),
          })
        );

        return getCustomerAnalyticsService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    } else if (props.page === "customerOrders") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: props.userId,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_start_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }
      if (values.payment !== "" && values.payment !== null) {
        requestBody.p_payment = values.payment;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }

      // Check if all fields are empty strings
      if (
        values.endDate === "" &&
        values.startDate === "" &&
        values.payment === "" &&
        values.status === "" &&
        values.type === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        // Ensure both dates are Date objects
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        // Compare timestamps to check date order
        if (endDateObj.getTime() < startDateObj.getTime()) {
          toast.error(
            "Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearCustomerOrderPageData());

          dispatch(
            getCustomerOrderFilterData({
              startDate: format(startDateObj, "dd-MM-yyyy"),
              endDate: format(endDateObj, "dd-MM-yyyy"),
              status: values.status,
              payment: values.payment,
              orderType: values.type,
            })
          );

          return getCustomersOrderService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearCustomerOrderPageData());
        const startDateObj = new Date(values.startDate);
        const endDateObj = new Date(values.endDate);

        dispatch(
          getCustomerOrderFilterData({
            startDate: format(startDateObj, "dd-MM-yyyy"),
            endDate: format(endDateObj, "dd-MM-yyyy"),
            status: values.status,
            payment: values.payment,
            orderType: values.type,
          })
        );

        return getCustomersOrderService(requestBody, token, dispatch, "filter");
      }
    }
    // GRN page
    else if (props.page === "grn") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.GRNNo !== "" && values.GRNNo !== null) {
        requestBody.p_grn_no = values.GRNNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (
        values.GRNNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Requisition End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearGRNPageData());

          dispatch(
            getGRNFilterData({
              GRNNo: values.GRNNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
            })
          );

          return getAllGoodReceiveNoteService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearGRNPageData());

        dispatch(
          getGRNFilterData({
            GRNNo: values.GRNNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
          })
        );

        return getAllGoodReceiveNoteService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // vendor page
    else if (props.page === "new-vendor-person") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.firstName !== "" && values.firstName !== null) {
        requestBody.p_first_name = values.firstName;
      }
      if (values.isActive !== "" && values.isActive !== null) {
        requestBody.p_isActive = values.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.isDelete !== "" && values.isDelete !== null) {
        requestBody.p_IsDeleted = values.isDelete;
      }
      if (values.lastName !== "" && values.lastName !== null) {
        requestBody.p_last_name = values.lastName;
      }
      if (values.phNum !== "" && values.phNum !== null) {
        requestBody.p_phone_number = values.phNum;
      }

      // Check if all fields are empty strings
      if (
        values.firstName === "" &&
        values.isActive === "" &&
        values.isDelete === "" &&
        values.lastName === "" &&
        values.phNum === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearNewVendorPageData());

        dispatch(
          getNewVendorFilterData({
            firstName: values.firstName,
            lastName: values.lastName,
            phNum: values.phNum,
            isActive: values.isActive,
            isDelete: values.isDelete,
          })
        );

        return getAllNewVendorPersonService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // units
    else if (props.page === "units") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.unit !== "" && values.unit !== null) {
        requestBody.p_unit_id = values.unit;
      }
      if (values.isActive !== "" && values.isActive !== null) {
        requestBody.p_isActive = values.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.isDelete !== "" && values.isDelete !== null) {
        requestBody.p_IsDeleted = values.isDelete;
      }

      // Check if all fields are empty strings
      if (
        values.unit === "" &&
        values.isActive === "" &&
        values.isDelete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearUnitsPageData());

        dispatch(
          getUnitsFilterData({
            unitId: values.unit,
            isActive: values.isActive,
            isDelete: values.isDelete,
          })
        );

        return getAllNewVendorPersonService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // stock history page
    else if (props.page === "StockHistory") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.ingredientId !== "" && values.ingredientId !== null) {
        requestBody.p_ingredient_id = values.ingredientId;
      }
      if (values.orderDate !== "" && values.orderDate !== null) {
        const date = new Date(values.orderDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
        // requestBody.p_created_date = values.orderDate;
      }

      // Check if all fields are empty strings
      if (values.ingredientId === "" && values.orderDate === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(clearStockHistoryPageData());

        dispatch(
          getStockHistoryFilterData({
            ingredientId: values.ingredientId,
            orderDate: values.orderDate,
          })
        );

        return getAllStockHistoryService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }
    //Image manager page
    else if (props.page === "imageManager") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.type !== "" && values.type !== null) {
        requestBody.p_type_id = values.type;
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.type === "" && values.is_delete === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(clearImagePageData());
        dispatch(
          getImageFilterData({ type: values.type, isDelete: values.is_delete })
        );
        getAllImageService(requestBody, token, dispatch, "filter");
      }
    }
    //Brand page
    else if (props.page === "brand" || props.page === "Brand") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category;
      }
      // if (values.subCategory !== "" && values.subCategory !== null) {
      //   requestBody.p_sub_category_id = values.subCategory;
      // }
      // if (values.leafCategory !== "" && values.leafCategory !== null) {
      //   requestBody.p_leaf_level_category_id = values.leafCategory;
      // }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id = values.tags.join(",");
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (
        values.name === "" &&
        values.category === "" &&
        // values.subCategory === "" &&
        // values.leafCategory === "" &&
        (values.tags === "" ||
          values.tags === null ||
          values.tags.length === 0) &&
        values.status === "" &&
        values.is_delete === "" &&
        values.type === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearBrandPageData());
        getAllBrandService(requestBody, token, dispatch, "filter");
        dispatch(
          getBrandFilterData({
            name: values.name,
            category: values.category,
            subCategory: values.subCategory,
            leafCategory: values.leafCategory,
            tags: values.tags,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
      }
    }
    //Product page
    else if (props.page === "product") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.brand !== "" && values.brand !== null) {
        requestBody.p_brand_id = values.brand;
      }
      if (
        values.category !== "" &&
        values.category !== null &&
        values.category.length > 0
      ) {
        requestBody.p_category_id = values.category;
      }
      if (
        values.subCategory !== "" &&
        values.subCategory !== null &&
        values.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id = values.subCategory;
      }
      if (
        values.leafCategory !== "" &&
        values.leafCategory !== null &&
        values.leafCategory > 0
      ) {
        requestBody.p_leaf_level_category_id = values.leafCategory;
      }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id = values.tags.join(",");
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.brand === "" &&
        (values.category === "" ||
          values.category === null ||
          values.category.length === 0) &&
        (values.subCategory === "" ||
          values.subCategory === null ||
          values.subCategory.length === 0) &&
        (values.leafCategory === "" ||
          values.leafCategory === null ||
          values.leafCategory.length === 0) &&
        (values.tags === "" ||
          values.tags === null ||
          values.tags.length === 0) &&
        values.sku === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        // dispatch(clearProductData());
        dispatch(clearProductPageData());
        getAllProductService(requestBody, token, dispatch, "filter");
        dispatch(
          getProductFilterData({
            name: values.name,
            category: values.category,
            subCategory: values.subCategory,
            leafCategory: values.leafCategory,
            tags: values.tags,
            sku: values.sku,
            brand: values.brand,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
      }
    }
    //Product page
    else if (props.page === "product-pricing") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.name === "" && values.sku === "") {
        toast.error("Please add filter!");
      } else {
        // dispatch(clearProductData());
        dispatch(clearProductPricingPageData());
        getAllProductService(requestBody, token, dispatch, "filter");
        dispatch(
          getProductPricingFilterData({
            name: values.name,
            sku: values.sku,
          })
        );
      }
    }
    //Attribute page
    else if (props.page === "attributes") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_isActive = values.status;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearAttributePageData());
        dispatch(
          getAttributeFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllAttributeService(requestBody, token, dispatch, "filter");
      }
    }
    //Warehouse page
    else if (props.page === "warehouse") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearWarehousePageData());
        dispatch(
          getWarehouseFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllWarehouseService(requestBody, token, dispatch, "filter");
      }
    }
    //packager and manufacturer page
    else if (props.page === "packager") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.type === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearPackagerPageData());
        dispatch(
          getPackagerFilterData({
            name: values.name,
            type: values.type,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllPackagerService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "discount") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_discount_type = values.type;
      }
      if (values.start_date !== "" && values.start_date !== null) {
        requestBody.p_start_date = format(
          new Date(values.start_date),
          "yyyy-MM-dd"
        );
      }
      if (values.end_date !== "" && values.end_date !== null) {
        requestBody.p_end_date = format(
          new Date(values.end_date),
          "yyyy-MM-dd"
        );
      }
      if (values.code !== "" && values.code !== null) {
        requestBody.p_discount_code = values.code;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_IsActive = values.status;
      } else {
        requestBody.p_IsActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.type === "" &&
        values.start_date === "" &&
        values.end_date === "" &&
        values.code === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        getAllDiscountService(requestBody, token, dispatch, "filter");
        dispatch(
          getDiscountFilterData({
            name: values.name,
            type: values.type,
            start_date: values.start_date,
            end_date: values.end_date,
            code: values.code,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        dispatch(clearDiscountPageData());
      }
    } else if (props.page === "discountCatalogue") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.start_date !== "" && values.start_date !== null) {
        requestBody.p_start_date = format(
          new Date(values.start_date),
          "yyyy-MM-dd"
        );
      }
      if (values.end_date !== "" && values.end_date !== null) {
        requestBody.p_end_date = format(
          new Date(values.end_date),
          "yyyy-MM-dd"
        );
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_IsActive = values.status;
      } else {
        requestBody.p_IsActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.start_date === "" &&
        values.end_date === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearDiscountCataloguePageData());
        dispatch(
          getDiscountCatalogueFilterData({
            name: values.name,
            start_date: values.start_date,
            end_date: values.end_date,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllDiscountCatalogueService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "inventory") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.code !== "" && values.code !== null) {
        requestBody.p_productCode = values.code;
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.warehouse !== "" && values.warehouse !== null) {
        requestBody.p_warehouse_id = values.warehouse;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      }
      // else {
      //   requestBody.p_is_active = '1';
      // }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.code === "" &&
        values.sku === "" &&
        values.warehouse === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearInventoryPageData());
        dispatch(
          getInventoryFilterData({
            productCode: values.code,
            sku: values.sku,
            warehouse: values.warehouse,
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllInventoryService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "branch") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.pincode !== "" && values.pincode !== null) {
        requestBody.p_pincode = values.pincode;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.is_public_avilable !== "" &&
        values.is_public_avilable !== null
      ) {
        requestBody.p_public_avilable = values.is_public_avilable;
      }
      if (values.is_wp_sms_allow !== "" && values.is_wp_sms_allow !== null) {
        requestBody.p_wp_sms_allow = values.is_wp_sms_allow;
      }
      if (
        values.is_delivery_available !== "" &&
        values.is_delivery_available !== null
      ) {
        requestBody.p_delivery_available = values.is_delivery_available;
      }
      if (values.tax_type !== "" && values.tax_type !== null) {
        requestBody.p_tax_type = values.tax_type;
      }
      if (
        values.name === "" &&
        values.pincode === "" &&
        values.status === "" &&
        values.is_delete === "" &&
        values.is_public_avilable === "" &&
        values.is_wp_sms_allow === "" &&
        values.is_delivery_available === "" &&
        values.tax_type === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearBranchPageData());
        dispatch(
          getBranchFilterData({
            name: values.name,
            pincode: values.pincode,
            isActive: values.status,
            isDelete: values.is_delete,
            isDeliveryAvailable: values.is_delivery_available,
            isPublicAvailable: values.is_public_avilable,
            isWpSmsAllow: values.is_wp_sms_allow,
            taxType: values.tax_type,
          })
        );
        getAllBranchService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "serviceProvider") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        getAllServiceProviderService(requestBody, token, dispatch, "filter");
        dispatch(
          getServiceProviderFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        dispatch(clearServiceProviderPageData());
      }
    } else if (props.page === "service") {
      if (
        values.category === "" &&
        (values.subCategory === "" || values.subCategory.length === 0) &&
        (values.tags === "" || values.tags.length === 0) &&
        (values.branch === "" || values.branch.length === 0) &&
        values.name === "" &&
        values.status === "" &&
        values.is_delete === "" &&
        values.brand === ""
      ) {
        toast.error("Please add filter!");
        return;
      }

      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category
          ? parseInt(values.category)
          : "";
      }
      if (
        values.subCategory !== "" &&
        values.subCategory !== null &&
        values.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id =
          values.subCategory.length > 0 ? values.subCategory.join(",") : "";
      }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id =
          values.tags.length > 0 ? values.tags.join(",") : "";
      }
      if (
        values.branch !== "" &&
        values.branch !== null &&
        values.branch.length > 0
      ) {
        requestBody.p_branch_id =
          values.branch.length > 0 ? values.branch.join(",") : "";
      }
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.brand !== "" && values.brand !== null) {
        requestBody.p_brand_id = values.brand ? parseInt(values.brand) : "";
      }
      dispatch(
        getServiceFilterData({
          name: values.name,
          category: values.category ? parseInt(values.category) : "",
          subCategory:
            values.subCategory.length > 0 ? values.subCategory.join(",") : "",
          tag: values.tags.length > 0 ? values.tags.join(",") : "",
          branch: values.branch.length > 0 ? values.branch.join(",") : "",
          brand: values.brand ? parseInt(values.brand) : "",
          isActive: values.status,
          isDelete: values.is_delete,
        })
      );

      dispatch(clearServicePageData());
      getAllService(requestBody, token, dispatch, "filter");
    } else if (props.page === "order") {
      if (
        (values.orderDate === "" || values.orderDate === null) &&
        (values.status === "" || values.status === null) &&
        (values.orderType === "" || values.orderType === null)
      ) {
        toast.error("Please add filter!");
        return;
      }

      const requestBody = {
        p_skip: 0,
        p_take: 1000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (values.orderDate !== "" && values.orderDate !== null) {
        const date = new Date(values.orderDate);

        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_start_date = dateTimeFormat;
      }
      if (values.orderDate !== "" && values.orderDate !== null) {
        const date = new Date(values.orderDate);

        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_end_date = dateTimeFormat;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }
      if (values.orderType !== "" && values.orderType !== null) {
        requestBody.p_type = values.orderType;
      }
      const date = new Date(values.orderDate);

      // Formatting the date and time parts
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");

      // Combining them into the desired format
      const dateTimeFormat =
        values.orderDate !== "" && values.orderDate !== null
          ? `${year}-${month}-${day}`
          : "";

      dispatch(
        getOrderFilterData({
          orderDate: dateTimeFormat,
          status: values.status,
          orderType: values.orderType,
        })
      );
      dispatch(clearOrderPageData());
      getAllOrderService(requestBody, token, dispatch, "filter");
    } else {
    }
  };

  const handleTypeChange = (option) => {
    if (option && categoryListOptions && categoryListOptions.length > 0) {
      const categoryOptions = categoryListOptions.filter(
        (item) => item.type === option.value
      );
      setCategoryNewOptionsData(categoryOptions);
    }
  };

  /**
   * Setting sub-category and leaf level category data on category option select
   * @param {*} option
   * @param {*} data
   * @param {*} setFieldValue
   */
  // const getFilterOptionData = (option, data, setFieldValue) => {
    // if (data === "category") {
      // if (
      //   subCategoryListData.items &&
      //   subCategoryListData.items[0]?.data &&
      //   subCategoryListData.items[0].data.length > 0
      // ) {
      //   const filteredArr2 = subCategoryListData.items[0].data.filter(
      //     (item) => item.category_id === option.value
      //   );
      //   const subCategoryOptionData = filteredArr2.map((item) => ({
      //     value: item.id,
      //     label: item.name,
      //     is_active: item.is_active,
      //   }));
      //   setSubCategoryListOptions(subCategoryOptionData);
      // }
      // if (setFieldValue) {
      //   if (option.length === 0) {
      //     setFieldValue("brandSubCategory", []);
      //     setLeafCategoryListOptions([]);
      //   }
      //   setFieldValue("brandLeafCategory", []);
      // }
    // } 
    // else if (data === "subCategory") {
    //   if (totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) {
    //     const filterLeafData = totalLeaf.items[0].data.filter(
    //       (item) => item.SubcategoryId === option.value
    //     );
      //   const leafOptions = filterLeafData.map((item) => ({
      //     value: item.id,
      //     label: item.Title,
      //     is_active: item.is_active,
      //   }));
      //   setLeafCategoryListOptions(leafOptions);
      // }
      // if (setFieldValue) {
      //   if (option.length === 0) {
      //     setFieldValue("brandLeafCategory", []);
      //   }
    //   }
    // }
  // };

  const handleMonthChange = (setFieldValue, option, values) => {
    if (option?.value) {
      const selectedMonth = option.value;
      const firstDate = new Date(Number(values.year), selectedMonth - 1, 1);
      const lastDate = new Date(Number(values.year), selectedMonth, 0);
      setFieldValue("startDate", firstDate);
      setFieldValue("endDate", lastDate);
    }
  };

  return (
    <>
      <div className="">
        <div className="table-filter">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div>
                  {props.page === "roles" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name={t("name")}
                            placeholder={t("columns.designation_name")}
                            className="add-roles"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name={t("status")}
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* changes by sakshi */}
                  {props.page === "company" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="company_name"
                            placeholder={t("placeholder_company_name")}
                            className="add-roles"
                            value={values.company_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="pincode"
                            placeholder={t("columns.pincode")}
                            className="add-roles"
                            value={values.pincode}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="company_address"
                            placeholder={t("placeholder_company_address")}
                            className="add-roles"
                            value={values.company_address}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "brand" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("columns.department_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={typeOptions}
                            component={CustomSelect}
                            value={values.type}
                            onchangedata={(option) => handleTypeChange(option)}
                            placeholder={t("placeholder_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryNewOptionsData}
                            component={CustomSelect}
                            value={values.category}
                            // onchangedata={(option) =>
                            //   getFilterOptionData(
                            //     option,
                            //     "category",
                            //     setFieldValue
                            //   )
                            // }
                            placeholder={t("placeholder_category")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            onchangedata={(option) =>
                              getFilterOptionData(
                                option,
                                "subCategory",
                                setFieldValue
                              )
                            }
                            placeholder={t("placeholder_suv_category")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="leafCategory"
                            options={leafCategoryListOptions}
                            component={CustomSelect}
                            value={values.leafCategory}
                            placeholder={t("placeholder_leaf_level_category")}
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            value={values.tags}
                            placeholder={t("placeholder_tags")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "customer" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phone"
                            placeholder={t("placeholder_filter_mobile")}
                            className="add-roles"
                            Regex={/[^0-9\s]/g}
                            maxLength={10}
                            hidelength={"true"}
                            value={values.phone}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "preferences" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="productName"
                            placeholder={t("placeholder_filter_product_name")}
                            className="add-roles"
                            value={values.productName}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "allPreferences" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="productName"
                            placeholder={t("placeholder_filter_product_name")}
                            className="add-roles"
                            value={values.productName}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "salesGraph" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="year"
                            options={yearOptions}
                            component={CustomSelect}
                            value={values.year}
                            placeholder={t("placeholder_filter_year")}
                            isMulti={false}
                            onChange={() => setFieldValue("month", "")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="month"
                            options={monthOption}
                            component={CustomSelect}
                            value={values.month}
                            placeholder={t("placeholder_filter_month")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "salesData" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="year"
                            options={yearOptions}
                            component={CustomSelect}
                            value={values.year}
                            placeholder={t("placeholder_filter_year")}
                            isMulti={false}
                            onChange={() => setFieldValue("month", "")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="month"
                            options={monthOption}
                            component={CustomSelect}
                            value={values.month}
                            placeholder={t("placeholder_filter_month")}
                            isMulti={false}
                            onchangedata={(option) =>
                              handleMonthChange(setFieldValue, option, values)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "eventLog" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="module"
                            options={sidebarOptions}
                            component={CustomSelect}
                            value={values.module}
                            placeholder={t("placeholder_filter_module")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="action"
                            options={actionOption}
                            component={CustomSelect}
                            value={values.action}
                            placeholder={t("placeholder_filter_action")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="user"
                            options={usersOptions}
                            component={CustomSelect}
                            value={values.user}
                            placeholder={t("placeholder_filter_by_user")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "expense" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_expense_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder={t("lable_category_name")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "customerRush" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                    setFieldValue("month", ""); // Clear month field
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "expenseGraph" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder={t("lable_category_name")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "settlementGraph" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "category" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_category_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder={t("lable_category_code")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "branch" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_branch_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="pincode"
                            placeholder={t("pincode")}
                            className="add-roles"
                            Regex={/[^0-9]/g}
                            maxLength={6}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_public_avilable"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_public_avilable}
                            placeholder={t("placeholder_is_public_available")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_wp_sms_allow"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_wp_sms_allow}
                            placeholder={t("placeholder_is_wp_sms_available")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delivery_available"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delivery_available}
                            placeholder={t("placeholder_is_delivery_available")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tax_type"
                            options={taxTypeOption}
                            component={CustomSelect}
                            value={values.tax_type}
                            placeholder={t("lable_tax_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "subCategory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder={t("columns.code")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={typeOptions}
                            component={CustomSelect}
                            value={values.type}
                            onchangedata={(option) => handleTypeChange(option)}
                            placeholder={t("placeholder_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryNewOptionsData}
                            component={CustomSelect}
                            value={values.category}
                            placeholder={t("placeholder_category")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_product_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder={t("placeholder_category")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            placeholder={t("placeholder_suv_category")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="leafCategory"
                            options={leafCategoryListOptions}
                            component={CustomSelect}
                            value={values.leafCategory}
                            placeholder={t("placeholder_leaf_level_category")}
                            isMulti={true}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            value={values.tags}
                            placeholder={t("placeholder_tags")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder={t("lable_SKU")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="brand"
                            options={brandListOptions}
                            component={CustomSelect}
                            value={values.brand}
                            placeholder={t("placeholder_brand")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product-pricing" && (
                    <>
                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_product_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder={t("lable_SKU")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "imageManager" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={imageTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder={t("placeholder_image_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "order" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderDate}
                                  onChange={(date) => {
                                    setFieldValue("orderDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={orderStatusOptions}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_filter_status")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="orderType"
                            options={orderTypeOptions}
                            component={CustomSelect}
                            value={values.orderType}
                            placeholder={t("placeholder_filter_order_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "customerOrders" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="payment"
                            options={paymentTypeOption}
                            component={CustomSelect}
                            value={values.payment}
                            placeholder="Filter By Payment..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={orderStatusOptions}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Filter By Status..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={orderTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder={t("placeholder_filter_order_type")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "leafCategory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            placeholder={t("placeholder_suv_category")}
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {(props.page === "tags" ||
                    props.page === "attributes" ||
                    props.page === "warehouse" ||
                    props.page === "serviceProvider") && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "users" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phone"
                            placeholder={t("columns.mobile_number")}
                            className="add-roles"
                            maxLength={10}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "policy" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_policy_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={categoryOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder={t("placeholder_policy_type")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "packager" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_filter_by_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={packagerTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder={t("placeholder_filter_by_type")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "discount" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_discount_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={discountTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder={t("placeholder_discount_type")}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.start_date}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    // Check if the selected start date is greater than the current end date
                                    if (date > values.end_date) {
                                      // If the start date is greater than the end date, set the end date to null
                                      setFieldValue("end_date", null);
                                    }
                                    // Update the start date value
                                    setFieldValue("start_date", date);
                                  }}
                                  selectsStart
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText={t("placeholder_start_date")}
                                  showIcon
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                              <div className="col-12">
                                <label className="text-center w-100">
                                  {t("lable_to")}
                                </label>
                              </div>
                              <div className="col-12 mb-3 datepicker-width">
                                <DatePicker
                                  selected={values.end_date}
                                  onChange={(date) =>
                                    setFieldValue("end_date", date)
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  selectsEnd
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText={t("placeholder_end_date")}
                                  showIcon
                                  minDate={values.start_date}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder={t("lable_discount_code")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "discountCatalogue" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("placeholder_discount_name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-5 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.start_date}
                                  onChange={(date) => {
                                    // Check if the selected start date is greater than the current end date
                                    if (date > values.end_date) {
                                      // If the start date is greater than the end date, set the end date to null
                                      setFieldValue("end_date", null);
                                    }
                                    // Update the start date value
                                    setFieldValue("start_date", date);
                                  }}
                                  selectsStart
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText={t("placeholder_start_date")}
                                  showIcon
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                              <div className="col-2">
                                <label className="text-center w-100">
                                  {t("lable_to")}{" "}
                                </label>
                              </div>
                              <div className="col-5 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.end_date}
                                  onChange={(date) =>
                                    setFieldValue("end_date", date)
                                  }
                                  selectsEnd
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText={t("placeholder_end_date")}
                                  showIcon
                                  minDate={values.start_date}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "inventory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder={t("lable_product_code")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_product_name")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder={t("columns.sku_code")}
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="warehouse"
                            options={warehouseOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_filter_warehouse")}
                            isMulti={false}
                            value={values.warehouse}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "service" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_filter_tags")}
                            isMulti={true}
                            value={values.tags}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="brand"
                            options={brandListOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_filter_brand")}
                            isMulti={false}
                            value={values.brand}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="branch"
                            options={branchOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_filter_branch")}
                            isMulti={true}
                            value={values.branch}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_filter_by_category")}
                            isMulti={false}
                            value={values.category}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            placeholder={t(
                              "placeholder_filter_by_sub_category"
                            )}
                            isMulti={true}
                            value={values.subCategory}
                            styles={customStyles}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "spots" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_available"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_available}
                            placeholder={t("placeholder_filter_available")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "inventory-item" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder={t("lable_Name")}
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product-inventory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="productId"
                            options={productOptions}
                            component={CustomSelect}
                            value={values.productId}
                            placeholder={t("placeholder_filter_product")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}
                  {(props.page === "stocks" || props.page === "stock") && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="ingredientId"
                            options={inventoryItemOptions}
                            component={CustomSelect}
                            value={values.ingredientId}
                            placeholder={t("placeholder_filter_ingredient")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}
                  {props.page === "vendors" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_order_end_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="vendor"
                            options={vendorPersonListOptions}
                            component={CustomSelect}
                            value={values.vendor}
                            placeholder={t("placeholder_select_vendor")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={vendorOrderStatusOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_filter_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="payment"
                            options={paymentTypeOption}
                            component={CustomSelect}
                            value={values.payment}
                            placeholder={t("placeholder_filter_by_payment")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="orderNo"
                            placeholder={t("placeholder_filter_order_no")}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.orderNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "purchase-requisition" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_order_end_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="vendor"
                            options={vendorPersonListOptions}
                            component={CustomSelect}
                            value={values.vendor}
                            placeholder={t("placeholder_select_vendor")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={purchaseRequisitionStatusOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder={t("placeholder_filter_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="purchaseRequisitionNo"
                            placeholder={t(
                              "placeholder_filter_purchase_requisition_no"
                            )}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.purchaseRequisitionNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "wallet" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_end_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={walletOption}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Filter By Status..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}

                  {props.page === "grn" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_order_end_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="GRNNo"
                            placeholder={t(
                              "placeholder_filter_goods_receive_note_no"
                            )}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.GRNNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "purchase-order" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_order_end_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="purchaseOrderNo"
                            placeholder={t(
                              "placeholder_filter_purchase_order_no"
                            )}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.purchaseOrderNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "analytics" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.StartDate}
                                  onChange={(date) => {
                                    setFieldValue("StartDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="StartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.EndDate}
                                  onChange={(date) => {
                                    setFieldValue("EndDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_order_end_date"
                                  )}
                                  showIcon
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />

                                <ErrorMessage
                                  name="EndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "new-vendor-person" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="firstName"
                            placeholder={t("first_name")}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.firstName}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="lastName"
                            placeholder={t("last_name")}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            value={values.lastName}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phNum"
                            placeholder={t("lable_mobile_no")}
                            className="add-roles"
                            value={values.phNum}
                            Regex={/[^0-9\s]/g}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="categoryType"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder="Category..."
                            isMulti={true}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isActive"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isActive}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isDelete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isDelete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "units" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="unit"
                            name="firstName"
                            placeholder={t("first_name")}
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.unit}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isActive"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isActive}
                            placeholder={t("placeholder_status")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isDelete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isDelete}
                            placeholder={t("placeholder_delete")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "StockHistory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values.orderDate}
                                  onChange={(date) => {
                                    setFieldValue("orderDate", date);
                                  }}
                                  placeholderText={t(
                                    "placeholder_filter_by_date"
                                  )}
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="ingredientId"
                            options={inventoryItemOptions}
                            component={CustomSelect}
                            value={values.ingredientId}
                            placeholder={t("placeholder_filter_ingredient")}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page !== "customer" &&
                    props.page !== "brand" &&
                    props.page !== "category" &&
                    props.page !== "subCategory" &&
                    props.page !== "product" &&
                    props.page !== "imageManager" &&
                    props.page !== "leafCategory" &&
                    props.page !== "tags" &&
                    props.page !== "users" &&
                    props.page !== "policy" &&
                    props.page !== "roles" &&
                    props.page !== "attributes" &&
                    props.page !== "warehouse" &&
                    props.page !== "packager" &&
                    props.page !== "discount" &&
                    props.page !== "discountCatalogue" &&
                    props.page !== "inventory" &&
                    props.page !== "branch" &&
                    props.page !== "serviceProvider" &&
                    props.page !== "service" &&
                    props.page !== "order" &&
                    props.page !== "customer" &&
                    props.page !== "company" &&
                    props.page !== "spots" &&
                    props.page !== "inventory-item" &&
                    props.page !== "product-inventory" &&
                    props.page !== "vendors" &&
                    props.page !== "stocks" &&
                    props.page !== "StockHistory" &&
                    props.page !== "new-vendor-person" &&
                    props.page !== "units" &&
                    props.page !== "purchase-requisition" &&
                    props.page !== "purchase-order" &&
                    props.page !== "grn" &&
                    props.page !== "wallet" &&
                    props.page !== "product-pricing" &&
                    props.page !== "preferences" &&
                    props.page !== "allPreferences" &&
                    props.page !== "analytics" &&
                    props.page !== "customerOrders" &&
                    props.page !== "salesGraph" &&
                    props.page !== "salesData" &&
                    props.page !== "eventLog" &&
                    props.page !== "customerRush" &&
                    props.page !== "expenseGraph" &&
                    props.page !== "expense" &&
                    props.page !== "settlementGraph" && (
                      <>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder={t("lable_Name")}
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="Status"
                              options={categoryOptions}
                              placeholder={t("lable_email")}
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder={t("columns.code")}
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder={t("lable_brand_name")}
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                      </>
                    )}
                  <div className="col-12 mt-3">
                    <div
                      className="btn-submit"
                      // onClick={handleClose}
                    >
                      <Button
                        type="submit"
                        name={t("placeholder_search")}
                        className="btn login-btn"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
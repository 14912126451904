import {
  CLEAR_STOCK_DATA,
  CLEAR_TOTAL_STOCK_DATA,
  GET_STOCK_DATA,
  GET_TOTAL_STOCK_DATA,
} from "../../ReduxKeys";

const stockInitialState = {
  items: [],
};

/**
 * Store stock list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const StockReducer = (state = stockInitialState, action) => {
  switch (action.type) {
    case GET_STOCK_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_STOCK_DATA:
      return stockInitialState;
    default:
      return state;
  }
};

const totalStockInitialState = {
  items: [],
};

/**
 * Store total stock list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalStockReducer = (state = totalStockInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_STOCK_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_STOCK_DATA:
      return totalStockInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CategoryService from "../Category-service/CategoryService.tsx";
import { parseImage } from "../../../services/Regular-services/ImageService";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { modifyString } from "../../../services/Regular-services/StringService";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";

export default function AddCategory() {
  const { addUpdateCategoryService, getCategoryById, getAllCategoryService } =
    CategoryService();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.auth);
  const categoryPage = useSelector((state) => state.categoryPage?.items);
  const tokenData = UtilsData();
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const [errorImages, setErrorImages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const localisation = useSelector((state) => state.localization?.items);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [categoryActionData, setcategoryActionData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { Data } = location.state || {};
  const totalCategory = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);

  const [initialValues, setInitialValues] = useState({
    categoryName: "",
    categoryType: "",
    attributeType: null,
    categoryCode: "",
    description: "",
    parentCategory: "",
    upload: [],
    active: true,
  });

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      categoryName: Yup.string().required(
        t(ValidationMessage.CATEGORY_NAME_REQUIRED)
      ),
      categoryType: Yup.string().required(
        t(ValidationMessage.CATEGORY_TYPE_REQUIRED)
      ),
      // attributeType: Yup.string().required("Attribute type is required"),
      categoryCode: Yup.string().required(
        t(ValidationMessage.CATEGORY_CODE_REQUIRED)
      ),
      // description: Yup.string().required(
      //   t(ValidationMessage.DESCRIPTION_REQUIRED)
      // ),
      // upload: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
    })
  );

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "category"
      );
      setcategoryActionData(actionData);
    }
  }, [sideBarData]);

  /**
   * Fetching category data
   */
  const fetchCategoryList = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllCategoryService(categoryRequestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    fetchCategoryList();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      (totalCategory?.items && !totalCategory?.items?.length) ||
      !totalCategory?.items
    ) {
      fetchCategoryList();
    } else {
      if (totalCategory?.items[0]?.data?.length) {
        const optionData = totalCategory.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        const categoryOptions =
          Data && Data?.data
            ? optionData.filter((item) => item.value !== Data.data?.id)
            : optionData;
        setCategoryListOptions(categoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory, Data]);

  /**
   * Fetching category detail data
   */
  const fetchCategory = async () => {
    try {
      // Call the getRoleById function
      const categoryData = await getCategoryById(
        Data.data.id,
        tokenData,
        dispatch
      );
      const categoryImage = categoryData.data.image_data
        ? parseImage(JSON.stringify(categoryData.data.image_data))
        : null;
      const categoryImageData = categoryImage
        ? categoryImage.map((item) => ({
            imageId: parseInt(item.id),
            url: item.url,
            imageType: parseInt(item.type),
          }))
        : [];
      setShowImage(true);

      setInitialValues({
        categoryName: Data.data.name,
        categoryType: categoryData.data.type,
        categoryCode: Data.data.code,
        description: Data.data.description,
        upload: categoryImageData,
        parentCategory: Data.data?.parentCategoryID || "",
        attributeType: categoryData.data.selection_type,
        active:
          Data.data.isActive === true ||
          Data.data.isActive === "1" ||
          Data.data.isActive === 1
            ? true
            : false,
      });
      return categoryData;
    } catch (error) {
      toast.error(t(t(ValidationMessage.SOMETHING_WENT_WRONG)));
    }
  };

  //Reinitializing data if we get data from category
  useEffect(() => {
    if (Data && Data.data) {
      fetchCategory();
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    const imageId = values.upload.map((item) => ({
      id: item.imageId,
      type_id: item.imageType,
    }));
    const requestBody = {
      ...(Data && Data?.data && { p_id: Data.data.id }),
      p_name: values.categoryName,
      p_description: values.description,
      p_categorycode: values.categoryCode,
      p_isActive:
        values.active === true || values.active === 1 || values.active === "1"
          ? "1"
          : "0",
      p_type: values.categoryType,
      p_IsDeleted: "0",
      p_createdBy:
        Data && Data?.data ? Data.data.createdBy : userData.user.data.userId,
      ...(Data && Data?.data && { p_modifiedBy: userData.user.data.userId }),
      p_imageIds: imageId,
      p_selection_type: values.attributeType,
      p_parent_category_id:
        values?.parentCategory && values.parentCategory !== ""
          ? values.parentCategory
          : 0,
    };
    if (!Data) {
      dispatch(
        addUpdateCategoryService(requestBody, tokenData, "add", navigate)
      );
    } else {
      if (Data?.length && categoryPage > 1) {
        if (
          values.active === "0" ||
          values.active === 0 ||
          values.active === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateCategoryService(
                requestBody,
                tokenData,
                "update",
                navigate,
                categoryPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateCategoryService(
                requestBody,
                tokenData,
                "update",
                navigate,
                categoryPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateCategoryService(
              requestBody,
              tokenData,
              "update",
              navigate,
              categoryPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateCategoryService(requestBody, tokenData, "update", navigate),
          "update"
        );
      }
    }
  };

  const categoryTypeOptions = [
    { value: "1", label: "Blog" },
    { value: "0", label: "Product" },
    { value: "2", label: "Attribute" },
    { value: "3", label: "Service" },
    { value: "4", label: "Inventory" },
    { value: "5", label: "Expense" },
  ];
  const attributeTypeOptions = [
    { value: "0", label: "No Selection" },
    { value: "1", label: "Single Selection" },
    { value: "2", label: "Multi Select" },
  ];

  const setCategoryValidation = (valueData) => {
    const type = valueData;
    if (type === "2") {
      setValidationSchema(
        Yup.object({
          categoryName: Yup.string().required(
            t(ValidationMessage.CATEGORY_NAME_REQUIRED)
          ),
          categoryType: Yup.string().required(
            t(ValidationMessage.CATEGORY_TYPE_REQUIRED)
          ),
          attributeType: Yup.string().required(
            t(ValidationMessage.ATTRIBUTE_TYPE_REQUIRED)
          ),
          categoryCode: Yup.string().required(
            t(ValidationMessage.CATEGORY_CODE_REQUIRED)
          ),
          // description: Yup.string().required(
          //   t(ValidationMessage.DESCRIPTION_REQUIRED)
          // ),
          // upload: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          categoryName: Yup.string().required(
            t(ValidationMessage.CATEGORY_NAME_REQUIRED)
          ),
          categoryType: Yup.string().required(
            t(ValidationMessage.CATEGORY_TYPE_REQUIRED)
          ),
          categoryCode: Yup.string().required(
            t(ValidationMessage.CATEGORY_CODE_REQUIRED)
          ),
          // description: Yup.string().required(
          //   t(ValidationMessage.DESCRIPTION_REQUIRED)
          // ),
          // upload: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
        })
      );
    }
  };

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div> */}
        {/* <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {categoryActionData && categoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          categoryActionData[0].name,
                          localisation
                        )
                      )
                    : t("Category")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/category")}>
                  {categoryActionData && categoryActionData.length > 0
                    ? getRouteNameFromSidebar(
                        categoryActionData[0].name,
                        localisation
                      )
                    : t("Categories")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {categoryActionData && categoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          categoryActionData[0].name,
                          localisation
                        )
                      )
                    : t("Category")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {categoryActionData && categoryActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      categoryActionData[0].name,
                      localisation
                    )
                  )
                : t("Category")}
            </h4>
          </div>
          <div className="category-section add-roles-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("Category")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="categoryName"
                          label="Category Name"
                          placeholder={t("placeholder_enter_category_name")}
                          className="add-roles"
                          maxLength={20}
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.categoryName}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_category_type")}
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="categoryType"
                          options={categoryTypeOptions}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          value={values.categoryType}
                          changeData={(value) => setCategoryValidation(value)}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    {values.categoryType === "2" && (
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_attribute_type")}
                            <span className="required-label"></span>
                          </h4>
                          <Field
                            className="select-menu mb-2"
                            name="attributeType"
                            options={attributeTypeOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_Select")}
                            isMulti={false}
                            value={values.attributeType}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("lable_category_code")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="categoryCode"
                          label="Category Code"
                          placeholder={t("placeholder_enter_category_code")}
                          className="add-roles"
                          maxLength={50}
                          disabled={Data && Data.isEdit === false}
                          value={values.categoryCode}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("parentCategory")}
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="parentCategory"
                          options={categoryListOptions}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          value={values.parentCategory}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {t("lable_category_image")}
                            {/* <span className="required-label"></span> */}
                          </h4>
                        </div>
                        <UploadImage
                          name="upload"
                          onChange={setFieldValue}
                          value={values.upload}
                          disabled={Data && Data.isEdit === false}
                          filterByType={5}
                          setErrorImages={setErrorImages}
                          setShowImage={setShowImage}
                        />

                        <ShowImage
                          value={values.upload}
                          errorImages={errorImages}
                          s
                          showImage={showImage}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_description")}
                          {/* <span className="required-label"></span> */}
                        </h4>
                        <Field
                          name="description"
                          component={CKEditorCpmponent}
                          disabled={Data && Data.isEdit === false}
                          maxLength={255}
                        ></Field>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">{t("status")}</h4>
                        <Field
                          name="active"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/category", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import Filter from "../../components/Filter/Filter";
import { clearCustomerRushData } from "../../redux/Actions/CustomerRushAction/CustomerRushAction";
import { clearCustomerRushFilterData } from "../../redux/Actions/CustomerRushAction/CustomerRushFilterAction";
import Button from "../../components/Button/Button";
import CustomerRushService from "./CustomerRushService/CustomerRushSerivice";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Registering necessary Chart.js components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const CustomerRushReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getCustomerRushService } = CustomerRushService();
  const customerRush = useSelector((state) => state.customerRush);
  const customerRushFilter = useSelector((state) => state.customerRushFilter);
  const [show, setShow] = useState(false);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [customerRushActionData, setCustomerRushData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [timeBool, setTimeBool] = useState(false);
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: t("Customer_Spending_Analytics"),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t("Total Rush"),
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: timeBool ? t("Visit Hour") : t("Visit Date"),
        },
      },
    },
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "customer-rush"
      );
      setCustomerRushData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (customerRush && customerRush.items && customerRush.items.length === 0) {
      fetchCustomerRush();
    } else {
      if (
        customerRush &&
        customerRush.items &&
        customerRush.items[0]?.data &&
        customerRush.items[0].data.length > 0
      ) {
        const data = customerRush.items[0].data;
        // Extracting the dates from the order_day field and checking if they are the same or different
        const orderDays = data.map(
          (item) => new Date(item.order_day).toISOString().split("T")[0]
        );

        // Function to check if all dates are the same
        const allDatesSame = orderDays.every(
          (date, index, arr) => date === arr[0]
        );

        if (allDatesSame) {
          setTimeBool(true);
          // Time intervals for x-axis (Assumed to be in UTC)
          const timeIntervals = data.map((item) => {
            const timeRange = item.time_interval; // e.g., "06:00 - 08:00"

            // Split the time range into start and end times
            const [startTime, endTime] = timeRange.split(" - ");

            // Create a reference date (e.g., today's date) to work with
            const referenceDate = new Date();
            const currentDateString = referenceDate.toISOString().split("T")[0]; // "YYYY-MM-DD"

            // Combine the current date with the start and end times
            const startDate = new Date(`${currentDateString}T${startTime}:00Z`); // Add Z for UTC
            const endDate = new Date(`${currentDateString}T${endTime}:00Z`);

            // Check if start and end dates are valid
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
              return "Invalid Time Interval"; // Return a fallback value for invalid interval
            }

            // Format the start and end dates in the specified time zone (Asia/Calcutta)
            const startLocalTime = new Intl.DateTimeFormat("en-IN", {
              timeZone: "Asia/Calcutta", // Use the target timezone
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(startDate);

            const endLocalTime = new Intl.DateTimeFormat("en-IN", {
              timeZone: "Asia/Calcutta",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(endDate);

            return `${startLocalTime} - ${endLocalTime}`; // Combine the formatted start and end times
          });

          const orderCounts = data.map((item) => item.order_count); // Order count for y-axis

          // Update chart data state for time-based data
          setBarChartData({
            labels: timeIntervals, // Local time intervals on the x-axis
            datasets: [
              {
                label: t("Total_Customer_Rush"),
                data: orderCounts,
                backgroundColor: "rgba(75, 192, 192, 0.2)", // Customize the color
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          });
        } else {
          setTimeBool(false);
          // Group by date if dates are different
          const groupedData = data.reduce((acc, curr) => {
            const date = curr.order_day; // Group by order_day
            if (!acc[date]) {
              // If the date doesn't exist in the accumulator, add it
              acc[date] = {
                order_day: date,
                order_count: 0,
                product_count: 0,
              };
            }
            // Sum the order_count and product_count
            acc[date].order_count += curr.order_count;
            acc[date].product_count += curr.product_count;
            return acc;
          }, {});
          const groupedArray = Object.values(groupedData);
          // Prepare data for the chart
          const labels = groupedArray.map((item) => {
            const date = new Date(item.order_day);
            return `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`;
          }); // Dates for x-axis

          const spendingData = groupedArray.map((item) => item.order_count); // Total spending or any other metric

          // Update chart data state for date-based data
          setBarChartData({
            labels: labels,
            datasets: [
              {
                label: t("Total_Customer_Rush"),
                data: spendingData,
                backgroundColor: "rgba(75, 192, 192, 0.2)", // Customize the color
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [customerRush, customerRushFilter, localisation]);

  const handleCustomerRushClose = () => setShow(false);
  const handleCustomerRushShow = () => setShow(true);

  const fetchCustomerRush = () => {
    const requestBody = {
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_start_date: formatDateToYYYYMMDD(customerRushFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(customerRushFilter.endDate),
    };
    getCustomerRushService(requestBody, tokenData, dispatch);
  };

  const clearFilters = () => {
    dispatch(clearCustomerRushData());
    dispatch(clearCustomerRushFilterData());
  };

  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {customerRushActionData && customerRushActionData.length > 0
                ? getRouteNameFromSidebar(
                    customerRushActionData[0].name,
                    localisation
                  )
                : t("Event Log")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {customerRushActionData && customerRushActionData.length > 0
                ? getRouteNameFromSidebar(
                    customerRushActionData[0].name,
                    localisation
                  )
                : t("Event Log")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups">
          {((customerRushFilter.startDate !== "" &&
            customerRushFilter.startDate !== null &&
            new Date(
              formatDateToYYYYMMDD(customerRushFilter.startDate)
            ).toDateString() !==
              new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toDateString()) ||
            (customerRushFilter.endDate !== "" &&
              customerRushFilter.endDate !== null &&
              new Date(
                formatDateToYYYYMMDD(customerRushFilter.endDate)
              ).toDateString() !== new Date().toDateString())) && (
            <Dropdown className="new-header-dropdown action-btn">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                data-bs-toggle="tooltip"
                title="Action"
              >
                <GrMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu className="event-clear-filter">
                <Dropdown.Item onClick={clearFilters}>
                  {t("clear_filters")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={handleCustomerRushShow}
            data-bs-toggle="tooltip"
            title="Filter"
            className="btn btn-fillter-main crm-filter"
            name={
              <div className="filter">
                <FaFilter className="filter-icon" />
              </div>
            }
          ></Button>
        </div>

        <Offcanvas show={show} onHide={handleCustomerRushClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter
              page="customerRush"
              handleClose={handleCustomerRushClose}
            ></Filter>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {customerRush &&
      customerRush.items &&
      customerRush.items[0]?.data &&
      customerRush.items[0].data.length > 0 ? (
        <div className="chart-container">
          <Bar data={barChartData} options={chartOptions} />
        </div>
      ) : (
        <div className="input-search">
          <div className="no-record">
            <h3 data-bs-toggle="tooltip" title="No records!">
              {t("no_records")}
            </h3>
          </div>
        </div>
      )}
      {/* Bar Chart */}
    </div>
  );
};

export default CustomerRushReport;

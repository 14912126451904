import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  AddUpdateCompanyRequest,
  companyListAPIRequest,
  companyListAPIResponse,
  compnayDetailResponse,
} from "./companyAPIModule";
import { postApiService } from "../../../services/API-services/PostService";
import Swal from "sweetalert2";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import { Dispatch } from "redux";
import { logout } from "../../../redux/Actions/authAction/AuthAction";
import {
  clearCompanyData,
  clearTotalCompanyData,
  getCompanyData,
  getTotalCompanyData,
} from "../../../redux/Actions/companyAction/CompanyAction";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearCompanyPageData,
  getCompanyPageData,
} from "../../../redux/Actions/companyAction/CompanyPageAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function CompanyService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get Company list
   */
  const getCompanyService = (
    companyListRequest: companyListAPIRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<companyListAPIResponse> => {
    return new Promise<companyListAPIResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          companyListRequest.p_skip !== undefined &&
          companyListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${companyListRequest.p_skip}&`;
        }

        if (
          companyListRequest.p_take !== undefined &&
          companyListRequest.p_take !== null
        ) {
          queryParams += `p_take=${companyListRequest.p_take}&`;
        }

        if (
          companyListRequest.p_pincode !== undefined &&
          companyListRequest.p_pincode !== null
        ) {
          queryParams += `p_pincode=${companyListRequest.p_pincode}&`;
        }

        if (
          companyListRequest.p_address !== undefined &&
          companyListRequest.p_address !== null
        ) {
          queryParams += `address=${companyListRequest.p_address}&`;
        }

        if (
          companyListRequest.p_company_name !== undefined &&
          companyListRequest.p_company_name !== null
        ) {
          queryParams += `company_name=${companyListRequest.p_company_name}&`;
        }

        if (
          companyListRequest.p_IsDeleted !== undefined &&
          companyListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${companyListRequest.p_IsDeleted}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.COMPANY +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success" ||
            response.success === true)
        ) {
          if (total && total === true) {
            dispatch(getTotalCompanyData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getCompanyData(response));
          } else {
            dispatch(getCompanyData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get individual company
   */
  const getCompanyById = (
    id: number,
    token: string,
    dispatch: Dispatch
  ): Promise<compnayDetailResponse> => {
    return new Promise<compnayDetailResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.COMPANY + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating company
   */
  const addUpdateCompanyService = (
    addUpdateCompanyRequest: AddUpdateCompanyRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.COMPANY,
            token,
            addUpdateCompanyRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.COMPANY,
            token,
            addUpdateCompanyRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearCompanyData());
          dispatch(clearTotalCompanyData());
          if (type === "add") {
            dispatch(clearCompanyPageData());
            toast.success(
              response?.message
                ? response.message
                : "Company added successfully!"
            );
            navigate("/company");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Company undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getCompanyPageData(pageNumber + 1));
                } else {
                  dispatch(clearCompanyPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Company updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/company");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Company status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            dispatch(logout());
          }
          if (pageNumber) {
            // dispatch(getCompanyPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to delete company
   */
  const deleteCompanyService = (
    id: string,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.COMPANY + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearCompanyData());
          dispatch(clearTotalCompanyData());
          toast.success(
            response?.message
              ? response.message
              : "Company deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getCompanyPageData(pageNumber));
        }
      } catch (error: any) {
        if (errorShow === false) {
          if (!document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getCompanyService,
    getCompanyById,
    addUpdateCompanyService,
    deleteCompanyService,
  };
}
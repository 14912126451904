import {
  orderDateNew,
  sixMonthsBefore,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_CUSTOMER_ORDER_FILTER,
  GET_CUSTOMER_ORDER_FILTER,
} from "../../ReduxKeys";

const customerOrderFilterInitialState = {
  startDate: sixMonthsBefore(),
  endDate: orderDateNew(),
  status: "",
  payment: "",
  orderType: "",
};

/**
 * Store order filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerOrderFilterReducer = (
  state = customerOrderFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDER_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        status: action.payload.status,
        payment: action.payload.payment,
        orderType: action.payload.orderType,
      };
    case CLEAR_CUSTOMER_ORDER_FILTER:
      return customerOrderFilterInitialState;
    default:
      return state;
  }
};
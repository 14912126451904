import { CLEAR_SALES_GRAPH_DATA, GET_SALES_GRAPH_DATA } from "../../ReduxKeys";

const salesGraphDataInitialState = {
  items: [],
};

/**
 * Store sales graph  data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SalesGraphDataReducer = (
  state = salesGraphDataInitialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_GRAPH_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SALES_GRAPH_DATA:
      return salesGraphDataInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useRef } from "react";
import { FcCheckmark } from "react-icons/fc";
import "../../../pages/NewOrderPage/NewOrderPage.scss";
import { AlertMessage } from "../../Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRoundedValue } from "../../../Utils/Utils";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

export default function ProductContainer({ productData, orderData }) {
  const {
    handleLineLevelOrderConfirmationClick,
    // handleLineLevelOrderDeleteClick,
  } = AlertMessage();

  const tooltipRef = useRef(null);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const token = UtilsData();
  const dispatch = useDispatch();

  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  return (
    <div className="main-product">
      <div className="product-new">
        <span className="w-50">{productData?.productName}</span>
        <div className="product-new-details">
          <span
            className="qty-details"
            data-bs-toggle="tooltip"
            title={productData?.quantity || "1"}
          >
            {productData?.quantity || "1"}
          </span>
          <span
            data-bs-toggle="tooltip"
            title={getRoundedValue(
              Number(productData?.quantity) * Number(productData?.price) || 0
            )}
          >
            {orderData?.country_symbol || "₹"}
            {getRoundedValue(
              Number(productData?.quantity) * Number(productData?.price) || 0
            )}
          </span>
          <span
            data-bs-toggle="tooltip"
            title={getRoundedValue(
              Number(productData?.discountProductAmt) || 0
            )}
          >
            {orderData?.country_symbol || "₹"}
            {getRoundedValue(Number(productData?.discountProductAmt) || 0)}
          </span>
          <span
            data-bs-toggle="tooltip"
            title={getRoundedValue(Number(productData?.taxProductAmt) || 0)}
          >
            {orderData?.country_symbol || "₹"}
            {getRoundedValue(Number(productData?.taxProductAmt) || 0)}
          </span>
          <span
            data-bs-toggle="tooltip"
            title={getRoundedValue(Number(productData?.totalTablePrice) || 0)}
          >
            {orderData?.country_symbol || "₹"}
            {getRoundedValue(Number(productData?.totalTablePrice) || 0)}
          </span>
          {productData?.productStatus !== "0" ? (
            <span className="qty-details-icons">
              <IoCheckmarkDoneCircleSharp
                size={21}
                style={{ cursor: "pointer" }}
                data-bs-toggle="tooltip"
                title={"Delivered"}
              />
            </span>
          ) : (
            <span className="qty-details-icons">
              <FcCheckmark
                size={20}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleLineLevelOrderConfirmationClick(
                    productData,
                    token,
                    dispatch,
                    orderPage || 1
                  )
                }
                data-bs-toggle="tooltip"
                title={"Deliver product"}
              />
              {/* {orderData?.productData?.length > 1 ? (
                <MdDeleteForever
                  color="#f00"
                  size={19}
                  style={{ cursor: "pointer", marginLeft: 1 }}
                  onClick={() =>
                    handleLineLevelOrderDeleteClick(
                      productData,
                      token,
                      dispatch,
                      orderData
                    )
                  }
                />
              ) : (
                <></>
              )} */}
            </span>
          )}
        </div>
      </div>
      {productData?.addOns && !["", "null"].includes(productData?.addOns) ? (
        <div className="product-bio-new">
          <span>{productData?.addOns}</span>
        </div>
      ) : (
        <></>
      )}
      {productData?.product_instruction &&
      !["", "null"].includes(productData.product_instruction) ? (
        <div className="product-bio-new">
          <span>
            <span style={{ fontWeight: "bold" }}>Note:</span>{" "}
            {productData.product_instruction}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

import { CLEAR_TAG_PAGE, GET_TAG_PAGE } from "../../ReduxKeys";

/**
 * Set tag page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getTagPageData = (page) => ({
  type: GET_TAG_PAGE,
  payload: page,
});

/**
 * Clear tag page data
 * @returns {*}
 */
export const clearTagPageData = () => ({
  type: CLEAR_TAG_PAGE,
});
import {
  CLEAR_NOTIFICATION_LOADER,
  SET_NOTIFICATION_LOADER,
} from "../../ReduxKeys";

const loaderInitialState = {
  items: false,
};

/**
 * Reducer to set Notification loader during Notification API call
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const NotificationLoaderReducer = (
  state = loaderInitialState,
  action
) => {
  switch (action.type) {
    case SET_NOTIFICATION_LOADER:
      return {
        ...state,
        items: true,
      };
    case CLEAR_NOTIFICATION_LOADER:
      return loaderInitialState;
    default:
      return state;
  }
};
import { parseImage } from "../../../services/Regular-services/ImageService";

export const categoryToggleRequest = ({
  categoryDetail,
  toggleData,
  userId,
  isActive,
  isDelete,
}) => {
  const categoryImage = categoryDetail?.data?.image_data
    ? parseImage(JSON.stringify(categoryDetail.data.image_data))
    : null;

  const categoryImageData = categoryImage
    ? categoryImage.map((item) => ({
        id: parseInt(item.id),
        type_id: parseInt(item.type),
      }))
    : [];

  const requestBody = {
    p_id: toggleData.id,
    p_name: toggleData.name,
    p_description: toggleData?.description || null,
    p_categorycode: toggleData?.code,
    p_isActive: isActive
      ? toggleData.isActive === "1"
        ? "0"
        : "1"
      : toggleData.isActive,
    p_type: toggleData?.type ?
      toggleData.type === "Blog"
        ? "1"
        : toggleData.type === "Attribute"
        ? "2"
        : "0" : null,
    p_IsDeleted: isDelete ? "0" : toggleData.isDeleted,
    p_createdBy: toggleData.createdBy,
    p_modifiedBy: userId,
    p_imageIds: categoryImageData?.length ? categoryImageData : null,
    p_selection_type: categoryDetail?.data?.selection_type,
    p_parent_category_id: categoryDetail?.data?.parentCategoryID || 0
  };
  return requestBody;
};
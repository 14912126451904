import { CLEAR_IMAGE_PAGE, GET_IMAGE_PAGE } from "../../ReduxKeys";

const imagePageInitialState = {
  items: 1,
};

/**
 * Store image page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const imagePageReducer = (state = imagePageInitialState, action) => {
  switch (action.type) {
    case GET_IMAGE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_IMAGE_PAGE:
      return imagePageInitialState;
    default:
      return state;
  }
};
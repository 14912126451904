import { CLEAR_COMPANY_FILTER, GET_COMPANY_FILTER } from "../../ReduxKeys";

const companyFilterInitialState = {
  p_pincode: "",
  p_address: "",
  p_company_name: "",
  p_is_active: "",
  p_IsDeleted: "",
};

/**
 * Store compant filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CompanyFilterReducer = (
  state = companyFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_COMPANY_FILTER:
      return {
        ...state,
        p_pincode: action.payload.p_pincode,
        p_address: action.payload.p_address,
        p_company_name: action.payload.p_company_name,
        p_is_active: action.payload.p_is_active,
        p_IsDeleted: action.payload.p_IsDeleted,
      };
    case CLEAR_COMPANY_FILTER:
      return companyFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { uploadImage } from "../../../redux/Actions/uploadAction/UploadAction";
import { Accordion } from "react-bootstrap";
import "../../Product/AddProduct/AddProduct.scss";
import "../../../App.scss";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import CategoryService from "../../Category/Category-service/CategoryService";
import BrandService from "../../BrandList/BrandService/BrandService";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { addUpdateProductRequest } from "../ProductRequestBody/AddUpdateProductRequest";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { modifyString } from "../../../services/Regular-services/StringService";
import AttributeService from "../../Attributes/AttributeService/AttributeService";
import ProductServices from "../ProductService/ProductService";
import TagServices from "../../Tag/TagService/TagService";

export default function AddProduct() {
  const { getAllBrandService } = BrandService();
  const { getAllAttributeService } = AttributeService();
  const { getAllCategoryService } = CategoryService();
  // const { getAllLeafLevelCategoryService } = LeafLevelCategoryService();
  const { addUpdateProductService, getProductById } = ProductServices();
  const { getAllTagsService } = TagServices();
  // const { getAllSubCategoryService } = SubCategoryServices();
  // const { getAllWarehouseService } = WarehouseServices();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);

  const [initialValues, setInitialValues] = useState({
    productName: "",
    code: "",
    sku: "",
    productImage: [],
    brand: "",
    category: "",
    // subCategory: [],
    // leafCategory: [],
    description: "",
    additionalDescription: "",
    hsn: "",
    price: "",
    purchase_amount: "",
    // size: "",
    // color_name: "",
    // color_code: "",
    // units: "",
    // warehouse: "",
    // country: "",
    // skid: "",
    // ean: "",
    // manufacturer: "",
    // packer: "",
    // importer: "",
    // length_of_outer_package: "",
    // breadth_of_outer_package: "",
    // height_of_outer_package: "",
    // weight: "",
    // Unit_Fill_Pack_Size: "",
    // Unit_Pack_Size: "",
    tags: [],
    attributes: "",
    attributeValues: "",
    attributeImage: [],
    attributeCategory: "",
    status: true,
    veg: 1,
    excludeFilters: 0,
    jain: 0,
    vegan: 0,
    regular: "2",
  });

  const totalAttribute = useSelector((state) => state.totalAttribute);
  const productPage = useSelector((state) => state.productPage?.items);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [productActionData, setProductActionData] = useState([]);

  const tokenData = UtilsData();
  const { handleCancleClick, handlesaveClick } = AlertMessage();

  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  // const [subCategoryOptionValue, setSubCategoryOptionValue] = useState([]);

  const [attributeShowImage, setAttributeShowImage] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      productName: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
      code: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      sku: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      productImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
      brand: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      category: Yup.string().required(
        t(ValidationMessage.SINGLE_CATEGORY_SELECT)
      ),
      // subCategory: Yup.array().min(
      //   1,
      //   t(ValidationMessage.MULTI_SUB_CATEGORY_SELECT)
      // ),
      // description: Yup.string().required(
      //   t(ValidationMessage.DESCRIPTION_REQUIRED)
      // ),
      // additionalDescription: Yup.string().required(
      //   t(ValidationMessage.DESCRIPTION_REQUIRED)
      // ),
      // hsn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      price: Yup.string().required(t(ValidationMessage.PRICE_REQUIRED)),
      // size: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      // warehouse: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      // cgst:
      //   userData.data?.branchTaxType === "GST"
      //     ? Yup.string(t(ValidationMessage.FIELD_REQUIRED))
      //     : Yup.string().notRequired(),
      // sgst:
      //   userData.data?.branchTaxType === "GST"
      //     ? Yup.string(t(ValidationMessage.FIELD_REQUIRED))
      //     : Yup.string().notRequired(),
      // igst:
      //   userData.data?.branchTaxType === "GST"
      //     ? Yup.string(ValidationMessage.FIELD_REQUIRED)
      //     : Yup.string().notRequired(),
      // vat:
      //   userData.data?.branchTaxType === "VAT"
      //     ? Yup.string(t(ValidationMessage.FIELD_REQUIRED))
      //     : Yup.string().notRequired(),
    })
  );

  const [errorImages, setErrorImages] = useState([]);
  const [attributeErrorImages, setAttributeErrorImages] = useState([]);
  const [AttributesOptions, setAttributesOptions] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "product"
      );
      setProductActionData(actionData);
    }
  }, [sideBarData]);

  /**
   * Fetch attribute data
   */
  const fetchAttributeData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 5000000,
    };
    getAllAttributeService(requestBody, tokenData, dispatch, true);
  };
  useEffect(() => {
    if (totalAttribute && totalAttribute.items.length === 0) {
      fetchAttributeData();
    }

    if (
      totalAttribute.items[0]?.data &&
      totalAttribute.items[0].data.length > 0
    ) {
      const transformedArray = totalAttribute.items[0].data.map((item) => ({
        value: item.attributeId,
        label: item.title,
        is_active: item.isActive,
      }));
      setAttributesOptions(transformedArray);
    }
    // eslint-disable-next-line
  }, [totalAttribute]);

  /**
   * Setting category options
   */
  const categoryListData = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [attributeCategoryListOptions, setAttributeCategoryListOptions] =
    useState([]);

  /**
   * Fetching category data
   */
  const fetchCategoryData = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllCategoryService(categoryRequestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    fetchCategoryData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userData.data?.branchTaxType === "GST") {
      setValidationSchema(
        Yup.object({
          productName: Yup.string().required(
            t(ValidationMessage.NAME_REQUIRED)
          ),
          code: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          sku: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          productImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
          brand: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          category: Yup.string().required(
            t(ValidationMessage.SINGLE_CATEGORY_SELECT)
          ),
          hsn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          price: Yup.string().required(t(ValidationMessage.PRICE_REQUIRED)),
          cgst:Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          sgst:Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
        })
      );
    } else if (userData.data?.branchTaxType === "VAT") {
      setValidationSchema(
        Yup.object({
          productName: Yup.string().required(
            t(ValidationMessage.NAME_REQUIRED)
          ),
          code: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          sku: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          productImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
          brand: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          category: Yup.string().required(
            t(ValidationMessage.SINGLE_CATEGORY_SELECT)
          ),
          hsn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          price: Yup.string().required(t(ValidationMessage.PRICE_REQUIRED)),
          vat:Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          productName: Yup.string().required(
            t(ValidationMessage.NAME_REQUIRED)
          ),
          code: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          sku: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          productImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
          brand: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          category: Yup.string().required(
            t(ValidationMessage.SINGLE_CATEGORY_SELECT)
          ),
          price: Yup.string().required(t(ValidationMessage.PRICE_REQUIRED)),
        })
      );
    }
  }, [userData]);

  useEffect(() => {
    if (categoryListData?.items?.length) {
      if (
        categoryListData.items[0]?.data &&
        categoryListData.items[0].data.length > 0
      ) {
        const filterCategoryByType = categoryListData.items[0]?.data.filter(
          (item) => item.type === "0" || item.type === 0
        );
        const categoryOptions = filterCategoryByType.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
        }));
        setCategoryListOptions(categoryOptions);
        // if (categoryOptionValue && categoryOptionValue.length > 0) {
        //   const subCategoryOptions = categoryOptions.filter((item2) =>
        //     categoryOptionValue.some((item1) => item1 === item2.value)
        //   );
        //   getFilterOptionData(subCategoryOptions, "category");
        // }

        const filterAttributeCategoryByType =
          categoryListData.items[0]?.data.filter(
            (item) => item.type === "2" || item.type === 2
          );
        const attributeCategoryOptions = filterAttributeCategoryByType.map(
          (item) => ({
            value: item.categoryId,
            label: item.name,
            is_active: item.is_active,
          })
        );
        setAttributeCategoryListOptions(attributeCategoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [categoryListData, categoryOptionValue]);

  /**
   * Custom styles for React Select
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Setting sub category options
   */
  // const subCategoryListData = useSelector((state) => state.totalSubCategory);
  // const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  // const fetchSubCategoryData = () => {
  //   const subCategoryRequestBody = {
  //     p_skip: 0,
  //     p_take: 5000000,
  //   };
  //   getAllSubCategoryService(subCategoryRequestBody, tokenData, dispatch, true);
  // };

  // useEffect(() => {
  //   if (
  //     subCategoryListData &&
  //     subCategoryListData.items &&
  //     subCategoryListData.items.length === 0
  //   ) {
  //     fetchSubCategoryData();
  //   } else {
  //     if (
  //       subCategoryListData.items &&
  //       subCategoryListData.items[0]?.data &&
  //       subCategoryListData.items[0].data.length > 0
  //     ) {
  //       if (subCategoryOptionValue && subCategoryOptionValue.length > 0) {
  //         const filterSubCategoryByType =
  //           subCategoryListData.items[0].data.filter(
  //             (item) => item.type === "0" || item.type === 0
  //           );
  //         const subCategoryOptions = filterSubCategoryByType.map((item) => ({
  //           value: item.id,
  //           label: item.name,
  //           is_active: item.is_active,
  //         }));

  //         const filteredSubCategoryOptions = subCategoryOptions.filter(
  //           (item2) =>
  //             subCategoryOptionValue.some((item1) => item1 === item2.value)
  //         );
  //         getFilterOptionData(filteredSubCategoryOptions, "subCategory");
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [subCategoryListData, subCategoryOptionValue]);

  /**
   * Setting leaf level category options
   */
  // const totalLeaf = useSelector((state) => state.totalLeaf);
  // const [leafCategoryListOptions, setLeafCategoryListOptions] = useState([]);

  /**
   * Fetching leaf level category data
   */
  // const fetchLeafData = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 5000000,
  //   };
  //   getAllLeafLevelCategoryService(requestBody, tokenData, dispatch, true);
  // };

  // useEffect(() => {
  //   if (totalLeaf && totalLeaf.items && totalLeaf.items.length === 0) {
  //     fetchLeafData();
  //   }
  //   // eslint-disable-next-line
  // }, [totalLeaf]);

  /**
   * Setting tags options
   */
  const totalTag = useSelector((state) => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  /**
   * Fetching tag data
   */
  const fetchTagData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 5000000,
    };
    getAllTagsService(requestBody, tokenData, dispatch, true);
  };
  useEffect(() => {
    if (totalTag && totalTag.items && totalTag.items.length === 0) {
      fetchTagData();
    } else {
      if (totalTag.items[0]?.data && totalTag.items[0].data.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
          is_active: item.isActive,
        }));
        setTagListOptions(tagOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalTag]);

  /**
   * Setting Brand Options
   */
  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandOptions, setBrandOptions] = useState([]);

  /**
   * Fetching brand data
   */
  const fetchBrandData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBrandService(requestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    if (totalBrand && totalBrand.items.length === 0) {
      fetchBrandData();
    } else {
      if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
        const filteredBrandData = totalBrand.items[0].data.filter(
          (item) => item.brandTypeId === "0" || item.brandTypeId === 0
        );
        const brandOptions = filteredBrandData.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandOptions(brandOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalBrand]);

  /**
   * Setting Packager and Monufacturer Options
   */
  // const totalPackager = useSelector((state) => state.totalPackager);
  // const [packagerOptions, setPackagerOptions] = useState([]);
  // const [manufacturerOptions, setManufacturerOptions] = useState([]);
  // const [importerOptions, setImporterOptions] = useState([]);

  /**
   * Fetch packager data
   */
  // const fetchPackagerData = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 10000000
  //   }
  //   getAllPackagerService(requestBody, tokenData, dispatch, true);
  // }

  // useEffect(() => {
  //   if (totalPackager && totalPackager.items.length === 0) {
  //     // fetchPackagerData();
  //   } else {
  //     if (
  //       totalPackager.items[0]?.data &&
  //       totalPackager.items[0].data.length > 0
  //     ) {
  //       const manufacturer = totalPackager.items[0].data.filter(
  //         (item) => item.type === 0 || item.type === "0"
  //       );

  //       const packager = totalPackager.items[0].data.filter(
  //         (item) => item.type === 1 || item.type === "1"
  //       );

  //       const importer = totalPackager.items[0].data.filter(
  //         (item) => item.type === 2 || item.type === "2"
  //       );
  //       if (manufacturer && manufacturer.length > 0) {
  //         const manufacturerData = manufacturer.map((item) => ({
  //           value: item.productPackagerId,
  //           label: item.name,
  //           is_active: item.is_active,
  //         }));
  //         setManufacturerOptions(manufacturerData);
  //       }
  //       if (packager && packager.length > 0) {
  //         const packagerData = packager.map((item) => ({
  //           value: item.productPackagerId,
  //           label: item.name,
  //           is_active: item.is_active,
  //         }));
  //         setPackagerOptions(packagerData);
  //       }
  //       if (importer && importer.length > 0) {
  //         const importerData = importer.map((item) => ({
  //           value: item.productPackagerId,
  //           label: item.name,
  //           is_active: item.is_active,
  //         }));
  //         setImporterOptions(importerData);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [totalPackager]);

  /**
   * Setting warehouse Options
   */
  // const totalWarehouse = useSelector((state) => state.totalWarehouse);
  // const [warehouseOptions, setWarehouseOptions] = useState([]);

  /**
   * Warehouse API Integration
   */
  // const fetchWarehouseData = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 5000000,
  //   };
  //   getAllWarehouseService(requestBody, tokenData, dispatch, true);
  // };

  // useEffect(() => {
  //   if (totalWarehouse && totalWarehouse.items.length === 0) {
  //     fetchWarehouseData();
  //   } else {
  //     if (
  //       totalWarehouse.items[0]?.data &&
  //       totalWarehouse.items[0].data.length > 0
  //     ) {
  //       const warehouse = totalWarehouse.items[0].data.map((item) => ({
  //         value: item.warehouseId,
  //         label: item.name,
  //         is_active: item.is_active,
  //       }));
  //       setWarehouseOptions(warehouse);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [totalWarehouse]);

  const location = useLocation();
  const { Data } = location.state || {};

  /**
   * Fetching product detail data
   */
  const fetchProduct = async () => {
    try {
      // Call the getRoleById function
      const productData = await getProductById(
        Data.data.productId,
        tokenData,
        dispatch
      );

      const productImage = productData.data.image_data
        ? parseImage(JSON.stringify(productData.data.image_data))
        : [];
      const productImageData =
        productImage && productImage.length > 0
          ? productImage.map((item) => ({
              imageId: parseInt(item.id),
              url: item.url,
              imageType: parseInt(item.type),
            }))
          : [];
      setAttributeShowImage(true);
      setShowImage(true);

      const attributeData = productData.data.attribute_data
        ? parseImage(productData.data.attribute_data)
        : [];
      const attributeImageData = productData.data.attribute_image_data
        ? parseImage(JSON.stringify(productData.data.attribute_image_data))
        : [];

      const attributeImageMap = new Map(
        attributeImageData.map((item) => [item.id, item])
      );

      // Create a new array with merged data
      const mergedData = attributeData.map((data) => ({
        id: data.id,
        value: data.value,
        attributeImage: attributeImageMap.get(data.attribute_image) || null,
        category_id: parseInt(data.attribute_category),
      }));

      const updatedData = mergedData.map((item, index) => {
        return {
          id: index + 1,
          attributes: parseInt(item.id),
          attributeValues: item.value,
          attributeImage: item?.attributeImage
            ? [
                {
                  imageId: parseInt(item.attributeImage.id),
                  url: item.attributeImage.url,
                  imageType: parseInt(item.attributeImage.type),
                },
              ]
            : [],
          attributeCategory: item.category_id,
        };
      });
      setFields(updatedData);

      const categoryOption = productData.data.category_ids
        ? productData.data.category_ids.split(",").map(Number)
        : [];
      // const subCategoryOption = productData.data.sub_category_ids
      //   ? productData.data.sub_category_ids.split(",").map(Number)
      //   : [];
      setCategoryOptionValue(categoryOption);
      // setSubCategoryOptionValue(subCategoryOption);

      setInitialValues({
        productName: productData.data.name ? productData.data.name : "",
        code: productData.data.product_code
          ? productData.data.product_code
          : "",
        sku: productData.data.sku ? productData.data.sku.split("-").pop() : "",
        productImage: productImageData,
        brand: productData.data.brand_id ? productData.data.brand_id : "",
        // category: productData.data.category_ids
        //   ? productData.data.category_ids.split(",").map(Number)
        //   : [],
        category: productData.data?.category_id || "",
        // subCategory: productData.data.sub_category_ids
        //   ? productData.data.sub_category_ids.split(",").map(Number)
        //   : [],
        // leafCategory: productData.data.leaf_level_category_ids
        //   ? productData.data.leaf_level_category_ids.split(",").map(Number)
        //   : [],
        description: productData.data.description
          ? productData.data.description
          : "",
        additionalDescription: productData.data.additional_description
          ? productData.data.additional_description
          : "",
        hsn: productData.data.hsn ? productData.data.hsn : "",
        price: productData.data.price ? productData.data.price : "",
        purchase_amount: productData.data.purchase_price
          ? productData.data.purchase_price
          : "",
        // size: productData.data.size ? productData.data.size : "",
        // color_name: productData.data.color_name
        //   ? productData.data.color_name
        //   : "",
        // color_code: productData.data.color_code
        //   ? productData.data.color_code
        //   : "",
        // units: productData.data.unit ? productData.data.unit : "",
        // warehouse: productData.data.warehouse_id
        //   ? productData.data.warehouse_id
        //   : "",
        // country: productData.data.country_of_origin
        //   ? productData.data.country_of_origin
        //   : "",
        // skid: productData.data.SKID ? productData.data.SKID : "",
        // ean: productData.data.EAN_CODES ? productData.data.EAN_CODES : "",
        // manufacturer: productData.data.manufacturer_id
        //   ? productData.data.manufacturer_id
        //   : "",
        // packer: productData.data.packer_id ? productData.data.packer_id : "",
        // importer: productData.data.importer_id
        //   ? productData.data.importer_id
        //   : "",
        // length_of_outer_package: productData.data.Length_of_outer_package
        //   ? productData.data.Length_of_outer_package
        //   : "",
        // breadth_of_outer_package: productData.data.Breadth_of_outer_package
        //   ? productData.data.Breadth_of_outer_package
        //   : "",
        // height_of_outer_package: productData.data.Height_of_outer_package
        //   ? productData.data.Height_of_outer_package
        //   : "",
        // weight: productData.data.Weight_including_packaging
        //   ? productData.data.Weight_including_packaging
        //   : "",
        // Unit_Fill_Pack_Size: productData.data.Unit_Fill_Pack_Size
        //   ? productData.data.Unit_Fill_Pack_Size
        // : "",
        // Unit_Pack_Size: productData.data.Unit_Pack_Size
        //   ? productData.data.Unit_Pack_Size
        //   : "",
        tags: productData.data.tag_ids
          ? productData.data.tag_ids.split(",").map(Number)
          : [],
        attributes: "",
        attributeValues: "",
        attributeImage: [],
        status:
          productData.data.is_active === "1" ||
          productData.data.is_active === 1 ||
          productData.data.is_active === true
            ? true
            : false,
        veg: Data.data.veg_nonveg === "1" || Data.data.veg_nonveg === 1 ? 0 : 1,
        excludeFilters:
          Data.data.filter === "1" || Data.data.filter === 1 ? 1 : 0,
        jain:
          Data.data.jain_or_not === "1" || Data.data.jain_or_not === 1 ? 1 : 0,
        vegan:
          Data.data.vegan_or_not === "1" || Data.data.vegan_or_not === 1
            ? 1
            : 0,
        regular: Data?.data?.jain_regular ? Data.data.jain_regular : "2",
        cgst: productData.data?.cgst ? productData.data?.cgst : "",
        sgst: productData.data?.sgst ? productData.data?.sgst : "",
        igst: productData.data?.igst ? productData.data?.igst : "",
        vat: productData.data?.vat ? productData.data?.vat : "",
      });
      return productData;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  const regularOnionOptions = [
    {
      value: "2",
      label: "Regular",
    },
    {
      value: "0",
      label: "No Onion/Garlic",
    },
  ];

  useEffect(() => {
    if (Data && Data.data) {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Handle navigation
   * @param {*} route
   */
  const handleNavigate = (route) => {
    dispatch(uploadImage([]));
    navigate(route);
  };

  // const CountryoforigingenderOptions = [
  //   { value: "India", label: "India" },
  //   { value: "UK", label: "UK" },
  //   { value: "Iran", label: "Iran" },
  // ];

  /**
   * Append attribute data
   * @param {*} values
   * @param {*} setFieldValue
   */
  const handleAddField = (values, setFieldValue) => {
    if (
      values.attributes === "" ||
      values.attributeValues === "" ||
      values.attributeCategory === ""
    ) {
      toast.warn(t("attribute_select_error"));
    } else {
      let newId;
      if (fields.length === 0) {
        newId = fields.length + 1;
      } else {
        const lastKey = fields[fields.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        attributes: values.attributes,
        attributeValues: values.attributeValues,
        attributeImage: values.attributeImage,
        attributeCategory: values.attributeCategory,
      };
      setFieldValue("attributes", "");
      setFieldValue("attributeValues", "");
      setFieldValue("attributeImage", "");
      setFieldValue("attributeCategory", "");
      setFields([...fields, newField]);
    }
  };

  /**
   * Remove already added attribute data
   * @param {*} idToRemove
   */
  const handleRemoveField = (idToRemove) => {
    const list = [...fields];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFields(filterField);
  };

  /**
   * Updating attributes in reverse order
   */
  const onChangeData = (index, value, category) => {
    if (category && category === "attributeCategory") {
      let listData = [...fields];
      listData[fields.length - index - 1][category] = value;
      setFields(listData);
    } else {
      let listData = [...fields];
      listData[fields.length - index - 1]["attributes"] = value;
      setFields(listData);
    }
  };

  /**
   * Updating attribute values in reverse order
   */
  const changeInputData = (index, value, setFieldValue) => {
    let listData = [...fields];
    setFieldValue(value);
    listData[fields.length - index - 1]["attributeValues"] = value;
    setFields(listData);
  };

  /**
   * Updating attribute images in reverse order
   */
  const changeImageData = (index, value) => {
    let listData = [...fields];
    listData[fields.length - index - 1]["attributeImage"] = value;
    setFields(listData);
  };
  /**
   * Handle form submission
   * @param {*} values
   * @returns {*}
   */
  const onSubmit = (values) => {
    const requestBody = addUpdateProductRequest(values, Data, fields, userData);
    if (!Data) {
      dispatch(
        addUpdateProductService(requestBody, tokenData, "add", navigate)
      );
    } else {
      requestBody.IsDeleted = Data.data.isDeleted;
      if (Data?.length && productPage > 1) {
        if (
          values.active === "0" ||
          values.active === 0 ||
          values.active === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateProductService(
                requestBody,
                tokenData,
                "update",
                navigate,
                productPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateProductService(
                requestBody,
                tokenData,
                "update",
                navigate,
                productPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateProductService(
              requestBody,
              tokenData,
              "update",
              navigate,
              productPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateProductService(requestBody, tokenData, "update", navigate),
          "update"
        );
      }
    }
    dispatch(uploadImage([]));
  };

  /**
   * Setting sub-category and leaf level category options on selecting category value
   * @param {*} option
   * @param {*} data
   * @param {*} setFieldValue
   */
  // const getFilterOptionData = (option, data, setFieldValue) => {
  //   if (data === "category") {
  //     if (
  //       subCategoryListData.items &&
  //       subCategoryListData.items[0]?.data &&
  //       subCategoryListData.items[0].data.length > 0
  //     ) {
  //       const filterSubCategoryByType =
  //         subCategoryListData.items[0].data.filter(
  //           (item) => item.type === "0" || item.type === 0
  //         );
  //       if (filterSubCategoryByType && filterSubCategoryByType.length > 0) {
  //         const filteredArr2 = filterSubCategoryByType.filter((item2) =>
  //           option.some((item1) => item1.value === item2.category_id)
  //         );
  //         const subCategoryOptionData = filteredArr2.map((item) => ({
  //           value: item.id,
  //           label: item.name,
  //           is_active: item.is_active,
  //         }));
  //         setSubCategoryListOptions(subCategoryOptionData);
  //       }
  //     }
  //     if (setFieldValue) {
  //       if (option.length === 0) {
  //         setFieldValue("subCategory", []);
  //         setLeafCategoryListOptions([]);
  //       }
  //       setFieldValue("leafCategory", []);
  //     }
  //   } else if (data === "subCategory") {
  //     if (totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) {
  //       const filterLeafData = totalLeaf.items[0].data.filter((item2) =>
  //         option.some((item1) => item1.value === item2.SubcategoryId)
  //       );
  //       const leafOptions = filterLeafData.map((item) => ({
  //         value: item.id,
  //         label: item.Title,
  //         is_active: item.is_active,
  //       }));
  //       setLeafCategoryListOptions(leafOptions);
  //     }
  //     if (setFieldValue) {
  //       if (option.length === 0) {
  //         setFieldValue("leafCategory", []);
  //       }
  //     }
  //   }
  // };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {productActionData && productActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          productActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("Products"))}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => handleNavigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => handleNavigate("/product")}>
                  {productActionData && productActionData.length > 0
                    ? getRouteNameFromSidebar(
                        productActionData[0].name,
                        localisation
                      )
                    : t("Products")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {productActionData && productActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          productActionData[0].name,
                          localisation
                        )
                      )
                    : modifyString(t("Products"))}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {productActionData && productActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      productActionData[0].name,
                      localisation
                    )
                  )
                : modifyString(t("Products"))}
            </h4>
          </div>
          <div className="category-section add-roles-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        alwaysOpen
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_product_details")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_product_name")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="productName"
                                    label="Product Name"
                                    placeholder={t("lable_product_name")}
                                    className="add-roles"
                                    Regex={/[^a-zA-Z0-9\s]/g}
                                    value={values.productName}
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_product_code")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="code"
                                    label="Product code"
                                    placeholder={t("placeholder_product_code")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                    value={values.code}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_SKU")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="sku"
                                    label="SKU"
                                    placeholder={t("placeholder_SKU")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                    value={values.sku}
                                    Regex={/[^0-9\s]/g}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_product_image")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <UploadImage
                                  name="productImage"
                                  onChange={setFieldValue}
                                  value={values.productImage}
                                  disabled={Data && Data.isEdit === false}
                                  filterByType={8}
                                  setErrorImages={setErrorImages}
                                  setShowImage={setShowImage}
                                ></UploadImage>
                                <ShowImage
                                  value={values.productImage}
                                  errorImages={errorImages}
                                  showImage={showImage}
                                ></ShowImage>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_brand_name")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="brand"
                                    options={brandOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    disabled={Data && Data.isEdit === false}
                                    value={values.brand}
                                    Regex={/[^a-zA-Z\s]/g}
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_category_name")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="category"
                                    options={categoryListOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    disabled={Data && Data.isEdit === false}
                                    value={values.category}
                                    Regex={/[^a-zA-Z\s]/g}
                                    onChangeData={(option) =>
                                      setFieldValue("category", option.value)
                                    }
                                    // onchangedata={(option) =>
                                    //   getFilterOptionData(
                                    //     option,
                                    //     "category",
                                    //     setFieldValue
                                    //   )
                                    // }
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_sub_category_name")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="subCategory"
                                    options={subCategoryListOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={true}
                                    value={values.subCategory}
                                    disabled={Data && Data.isEdit === false}
                                    Regex={/[^a-zA-Z\s]/g}
                                    onchangedata={(option) =>
                                      getFilterOptionData(
                                        option,
                                        "subCategory",
                                        setFieldValue
                                      )
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_leaf_level_category_name")}
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="leafCategory"
                                    options={leafCategoryListOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={true}
                                    value={values.leafCategory}
                                    disabled={Data && Data.isEdit === false}
                                    Regex={/[^a-zA-Z\s]/g}
                                    styles={customStyles}
                                  />
                                </div>
                              </div> */}

                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_is_veg")}{" "}
                                  </h4>
                                </div>
                                <div className="paragraph-editor">
                                  <Field
                                    name="veg"
                                    setFieldValue={setFieldValue}
                                    component={Toggle}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_exculude_filters")}
                                  </h4>
                                </div>
                                <div className="paragraph-editor">
                                  <Field
                                    name="excludeFilters"
                                    setFieldValue={setFieldValue}
                                    component={Toggle}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              {(values.veg === true ||
                                values.veg === 1 ||
                                values.veg === "1") && (
                                <>
                                  <div className="col-md-2">
                                    <div className="paragraph-editor">
                                      <h4 className="paragraph-title">
                                        {t("lable_is_jain")}
                                      </h4>
                                    </div>
                                    <div className="paragraph-editor">
                                      <Field
                                        name="jain"
                                        setFieldValue={setFieldValue}
                                        component={Toggle}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="paragraph-editor">
                                      <h4 className="paragraph-title">
                                        {t("lable_is_vegan")}
                                      </h4>
                                    </div>
                                    <div className="paragraph-editor">
                                      <Field
                                        name="vegan"
                                        setFieldValue={setFieldValue}
                                        component={Toggle}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="paragraph-editor">
                                      <h4 className="paragraph-title">
                                        {t("lable_food_type")}
                                        <span className="required-label"></span>
                                      </h4>
                                      <Field
                                        className="select-menu mb-2"
                                        name="regular"
                                        options={regularOnionOptions}
                                        component={CustomSelect}
                                        placeholder={t("placeholder_Select")}
                                        isMulti={false}
                                        value={values.regular}
                                        changeData={(value) =>
                                          setFieldValue("regular", value)
                                        }
                                        disabled={Data && Data.isEdit === false}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="col-12">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_description")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                  <Field
                                    name="description"
                                    component={CKEditorCpmponent}
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={255}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_additional_description")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                  <Field
                                    name="additionalDescription"
                                    component={CKEditorCpmponent}
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={255}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("Pricing")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              {(userData.data?.branchTaxType === "GST" ||
                                userData.data?.branchTaxType === "VAT") && (
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        {t("lable_HSN")}
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="hsn"
                                      label="HSN"
                                      placeholder={t("placeholder_enter_hsm")}
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.hsn}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_purchase_amount")}
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    Regex={/[^0-9.]/g}
                                    name="purchase_amount"
                                    label="Purchase Amount"
                                    placeholder={t("lable_purchase_amount")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    value={values.purchase_amount}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_purchase_mrp")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    Regex={/[^0-9.]/g}
                                    name="price"
                                    label="Product Price"
                                    placeholder={t("lable_purchase_price")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    value={values.price}
                                  />
                                </div>
                              </div>
                              {userData.data?.branchTaxType === "GST" ? (
                                <>
                                  <div className="col-md-4">
                                    <div className="name-type">
                                      <div className="paragraph-editor ">
                                        <h4 className="paragraph-title">
                                          {t("lable_cgst")}
                                          <span className="required-label"></span>
                                        </h4>
                                      </div>
                                      <Input
                                        type="text"
                                        name="cgst"
                                        label="CGST"
                                        Regex={/[^0-9.]/g}
                                        placeholder={t(
                                          "placeholder_enter_cgst"
                                        )}
                                        className="add-roles"
                                        disabled={Data && Data.isEdit === false}
                                        maxLength={50}
                                        value={values.cgst}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="name-type">
                                      <div className="paragraph-editor ">
                                        <h4 className="paragraph-title">
                                          {t("lable_sgst")}
                                          <span className="required-label"></span>
                                        </h4>
                                      </div>
                                      <Input
                                        type="text"
                                        name="sgst"
                                        label="SGST"
                                        Regex={/[^0-9.]/g}
                                        placeholder={t(
                                          "placeholder_enter_sgst"
                                        )}
                                        className="add-roles"
                                        disabled={Data && Data.isEdit === false}
                                        maxLength={50}
                                        value={values.sgst}
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-md-4">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            IGST
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="igst"
                                          label="IGST"
                                          Regex={/[^0-9.]/g}
                                          placeholder="Enter IGST"
                                          className="add-roles"
                                          disabled={
                                            Data && Data.isEdit === false
                                          }
                                          maxLength={50}
                                          value={values.igst}
                                        />
                                      </div>
                                    </div> */}
                                </>
                              ) : (
                                ""
                              )}
                              {userData.data?.branchTaxType === "VAT" ? (
                                <>
                                  <div className="col-md-4">
                                    <div className="name-type">
                                      <div className="paragraph-editor ">
                                        <h4 className="paragraph-title">
                                          {t("lable_vat")}
                                          <span className="required-label"></span>
                                        </h4>
                                      </div>
                                      <Input
                                        type="text"
                                        name="vat"
                                        label="VAT"
                                        Regex={/[^0-9.]/g}
                                        placeholder={t("placeholder_enter_vat")}
                                        className="add-roles"
                                        disabled={Data && Data.isEdit === false}
                                        maxLength={50}
                                        value={values.vat}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    {/* <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_variants")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_size")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="size"
                                    label="Size"
                                    placeholder={t("placeholder_enter_size")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    Regex={/[^0-9' 'a-zA-Z]/g}
                                    maxLength={20}
                                    value={values.size}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {" "}
                                      {t("lable_color_name")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="color_name"
                                    label=" Colorname"
                                    placeholder={t(
                                      "placeholder_enter_color_name"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-zA-Z/s]/g}
                                    value={values.color_name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_color_code")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="color_code"
                                    label="Color code"
                                    placeholder={t(
                                      "placeholder_enter_color_code"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                    value={values.color_code}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_units")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="units"
                                    label="Color codeUnits"
                                    placeholder={t("placeholder_enter_units")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^0-9]/g}
                                    value={values.units}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    {/* <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_location")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_select_warehouse")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu select-menu-1 mb-2"
                                    name="warehouse"
                                    options={warehouseOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.warehouse}
                                    disabled={Data && Data.isEdit === false}
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_country_of_origin")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="country"
                                    options={CountryoforigingenderOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.country}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    {/* <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_aditional_codes")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_Skid")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="skid"
                                    label="Skid"
                                    placeholder={t("placeholder_enter_skid")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                    value={values.skid}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_ean_code")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="ean"
                                    label="EAN code"
                                    placeholder={t(
                                      "placeholder_enter_ean_code"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={50}
                                    value={values.ean}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    {/* <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_packers_and_movers_details")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_manufacturer")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="manufacturer"
                                    options={manufacturerOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.manufacturer}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_packer")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="packer"
                                    options={packagerOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.packer}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_importer")}
                                    <span className="required-label"></span>
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="importer"
                                    options={importerOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.importer}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    {/* <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_package_size")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_length_of_outer_package")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="length_of_outer_package"
                                    label="Length of outer package"
                                    placeholder={t(
                                      "placeholder_enter_length_of_outer_package"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.length_of_outer_package}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_breadth_of_outer_package")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="breadth_of_outer_package"
                                    label="Breadth of outer package"
                                    placeholder={t(
                                      "placeholder_enter_breadth_of_outer_package"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.breadth_of_outer_package}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_height_of_outer_package")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="height_of_outer_package"
                                    label="Height of outer package"
                                    placeholder={t(
                                      "placeholder_enter_height_of_outer_package"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.height_of_outer_package}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_weight")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="weight"
                                    label="Weight"
                                    placeholder={t("placeholder_enter_weight")}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.weight}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_unit_fill_pack_size")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="Unit_Fill_Pack_Size"
                                    label="Unit Fill Pack Size"
                                    placeholder={t(
                                      "placeholder_enter_unit_fill_pack_size"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.Unit_Fill_Pack_Size}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("lable_unit_pack_size")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="Unit_Pack_Size"
                                    label="Unit Pack Size"
                                    placeholder={t(
                                      "placeholder_enter_unit_pack_size"
                                    )}
                                    className="add-roles"
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={20}
                                    Regex={/[^a-z0-9\s]/g}
                                    value={values.Unit_Pack_Size}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    <div className="col-12">
                      <Accordion
                        defaultActiveKey={["0"]}
                        className="product-accordion"
                      >
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>
                            <IoIosArrowForward />
                            {t("lable_attributes")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("Tags")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                  <Field
                                    className="select-menu mb-2"
                                    name="tags"
                                    options={tagListOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={true}
                                    value={values.tags}
                                    disabled={Data && Data.isEdit === false}
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_attributes")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_values")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_attribute_image")}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_attribute_category")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("actions.add")}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <Field
                                  className="select-menu mb-2"
                                  name="attributes"
                                  options={AttributesOptions}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  value={
                                    values.attributes === "" ||
                                    values.attributes === null
                                      ? false
                                      : values.attributes
                                  }
                                  disabled={Data && Data.isEdit === false}
                                  styles={customStyles}
                                />
                              </div>
                              <div className="col-md-2">
                                <div className="name-type">
                                  <Input
                                    type="text"
                                    name="attributeValues"
                                    label="values"
                                    placeholder={t("placeholder_enter_values")}
                                    className="add-roles"
                                    maxLength={50}
                                    hidelength="true"
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <UploadImage
                                  name="attributeImage"
                                  onChange={setFieldValue}
                                  value={values.attributeImage}
                                  disabled={Data && Data.isEdit === false}
                                  restrictOne={true}
                                  filterByType={12}
                                  setErrorImages={setAttributeErrorImages}
                                />
                                <ShowImage
                                  value={values.attributeImage}
                                  errorImages={attributeErrorImages}
                                />
                              </div>
                              <div className="col-md-3">
                                <Field
                                  className="select-menu mb-2"
                                  name="attributeCategory"
                                  options={attributeCategoryListOptions}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  disabled={Data && Data.isEdit === false}
                                  value={
                                    values.attributeCategory === "" ||
                                    values.attributeCategory === null
                                      ? false
                                      : values.attributeCategory
                                  }
                                  styles={customStyles}
                                />
                              </div>
                              <div className="col-1">
                                <Button
                                  className="btn btn-fillter-main"
                                  type="button"
                                  onClick={() =>
                                    handleAddField(values, setFieldValue)
                                  }
                                  name={
                                    <div className="Plus">
                                      <FaPlus />
                                    </div>
                                  }
                                ></Button>
                              </div>
                            </div>
                            <div>
                              {fields &&
                                fields.length > 0 &&
                                fields
                                  .slice()
                                  .reverse()
                                  .map((field, index) => (
                                    <div className="row" key={index}>
                                      <div className="col-md-3">
                                        <Field
                                          className="select-menu mb-2"
                                          name={`attributes${index}`}
                                          options={AttributesOptions}
                                          changeData={(value) =>
                                            onChangeData(index, value)
                                          }
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={field.attributes}
                                          disabled={
                                            Data && Data.isEdit === false
                                          }
                                          styles={customStyles}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <div className="name-type">
                                          <Input
                                            type="text"
                                            name={`attributeValues${index}`}
                                            label="values"
                                            placeholder={t(
                                              "placeholder_enter_values"
                                            )}
                                            className="add-roles"
                                            value={field.attributeValues}
                                            changeInputData={(event) =>
                                              changeInputData(
                                                index,
                                                event.target.value,
                                                setFieldValue
                                              )
                                            }
                                            disabled={
                                              Data && Data.isEdit === false
                                            }
                                            maxLength={50}
                                            hidelength="true"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <UploadImage
                                          name={`attributeImage${index}`}
                                          onChange={setFieldValue}
                                          changeImageData={(value) =>
                                            changeImageData(index, value)
                                          }
                                          value={
                                            values[`attributeImage${index}`]
                                              ? values[`attributeImage${index}`]
                                              : field.attributeImage
                                          }
                                          disabled={
                                            Data && Data.isEdit === false
                                          }
                                          restrictOne={true}
                                          filterByType={12}
                                          setErrorImages={
                                            setAttributeErrorImages
                                          }
                                          setShowImage={setAttributeShowImage}
                                        />
                                        <ShowImage
                                          value={
                                            values[`attributeImage${index}`]
                                              ? values[`attributeImage${index}`]
                                              : field.attributeImage
                                          }
                                          errorImages={attributeErrorImages}
                                          showImage={attributeShowImage}
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <Field
                                          className="select-menu mb-2"
                                          name={`attributeCategory${index}`}
                                          options={attributeCategoryListOptions}
                                          changeData={(value) =>
                                            onChangeData(
                                              index,
                                              value,
                                              "attributeCategory"
                                            )
                                          }
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={field.attributeCategory}
                                          disabled={
                                            Data && Data.isEdit === false
                                          }
                                          styles={customStyles}
                                        />
                                      </div>
                                      <div className="col-1">
                                        <Button
                                          className="btn btn-fillter-main"
                                          type="button"
                                          onClick={() =>
                                            handleRemoveField(field.id)
                                          }
                                          name={
                                            <div className="Plus">
                                              <FaMinus />
                                            </div>
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">{t("status")}</h4>
                        <Field
                          name="status"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/product", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

import { CLEAR_STOCK_PAGE, GET_STOCK_PAGE } from "../../ReduxKeys";

/**
 * Setting stock page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getStockPageData = (page) => ({
  type: GET_STOCK_PAGE,
  payload: page,
});

/**
 * clearing stock page data
 * @returns {*}
 */
export const clearStockPageData = () => ({
  type: CLEAR_STOCK_PAGE,
});
import { toast } from "react-toastify";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  NotificationByIdResponse,
  getAllNotificationListRequest,
  getAllNotificationListResponse,
} from "./NotificationModal";
import { Dispatch } from "redux";
import {
  clearNotificationData,
  getNotificationData,
} from "../../../redux/Actions/NotificationAction/NotificationAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearNotificationLoader,
  setNotificationLoader,
} from "../../../redux/Actions/NotificationAction/NotificationLoader";
import { useSelector } from "react-redux";
import { getApiService } from "../../../services/API-services/GetService";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function NotificationServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /**
   * Service for getting notification list
   */
  const getNotificationListService = (
    notificationRequest: getAllNotificationListRequest,
    token: string,
    dispatch: Dispatch,
    navigate: any
  ) => {
    return new Promise<getAllNotificationListResponse>(
      async (resolve, reject) => {
        try {
          let queryParams = "";
          // Adding query parameters if they are provided
          if (
            notificationRequest.p_skip !== undefined &&
            notificationRequest.p_skip !== null
          ) {
            queryParams += `p_skip=${notificationRequest.p_skip}&`;
          }
          if (
            notificationRequest.p_take !== undefined &&
            notificationRequest.p_take !== null
          ) {
            queryParams += `p_take=${notificationRequest.p_take}&`;
          }
          const response = await getApiService(
            API_URL.BASE_URL +
              API_URL.NOTIFICATION +
              (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
            token,
            null,
            localisation
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === "200" ||
              response.status === "success")
          ) {
            dispatch(clearNotificationLoader());
            dispatch(getNotificationData(response));
          } else {
            if (!document.querySelector(toastClass)) {
              toast.error(
                response?.message
                  ? response.message
                  : t(ValidationMessage.SOMETHING_WENT_WRONG)
              );
            }
            errorShow = true;
            if (
              response?.message &&
              response.message === UnauthorizeAccessMessage
            ) {
              removePersistStorage(dispatch);
            }
            dispatch(clearNotificationLoader());
          }
          resolve(response);
        } catch (error: any) {
          if (errorShow === false) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          if (error?.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          dispatch(clearNotificationLoader());
        } finally {
          errorShow = false;
        }
      }
    );
  };

  /*
   * Service For getting notification by id
   */
  const getNotificationById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<NotificationByIdResponse> => {
    return new Promise<NotificationByIdResponse>(async (resolve, reject) => {
      try {
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.NOTIFICATION + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearNotificationData());
          dispatch(setNotificationLoader());
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
      }
    });
  };

  /**
   * Service for deleting notification by id
   * @param id
   * @param token
   * @param navigate
   * @returns
   */
  const deleteNotificationById = (
    id: string,
    token: string,
    navigate?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.NOTIFICATION + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearNotificationData());
          dispatch(setNotificationLoader());
          toast.success(
            response?.message
              ? response.message
              : "Notification deleted successfully!"
          );
          if (window.location.pathname === "/import-data") {
            if (navigate) {
              navigate("/home");
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
        dispatch(clearNotificationLoader());
      } finally {
        errorShow = false;
      }
    };
  };
  return {
    getNotificationListService,
    getNotificationById,
    deleteNotificationById,
  };
}

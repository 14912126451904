import {
  CLEAR_TOTAL_WALLET_DATA,
  CLEAR_WALLET_DATA,
  GET_TOTAL_WALLET_DATA,
  GET_WALLET_DATA,
} from "../../ReduxKeys";

const walletInitialState = {
  items: [],
};

/**
 * Store Wallet list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const WalletReducer = (state = walletInitialState, action) => {
  switch (action.type) {
    case GET_WALLET_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_WALLET_DATA:
      return walletInitialState;
    default:
      return state;
  }
};

const totalWalletInitialState = {
  items: [],
};

/**
 * Store total Wallet list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalWalletReducer = (state = totalWalletInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_WALLET_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_WALLET_DATA:
      return totalWalletInitialState;
    default:
      return state;
  }
};
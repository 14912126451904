import { Dispatch } from "redux";
import {
  addUpdateProductInventoryRequest,
  getAllProductInventoryRequest,
  getAllProductInventoryResponse,
} from "./ProductInventoryModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  clearProductInventoryData,
  clearTotalProductInventoryData,
  getProductInventoryData,
  getTotalProductInventoryData,
} from "../../../redux/Actions/ProductInventoryAction/ProductInventoryAction";
import {
  clearProductInventoryPageData,
  getProductInventoryPageData,
} from "../../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function ProductInventoryServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get brand list
   */
  const getAllProductInventoryService = (
    productInventoryRequest: getAllProductInventoryRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllProductInventoryResponse> => {
    return new Promise<getAllProductInventoryResponse>(
      async (resolve, reject) => {
        try {
          dispatch(setLoader());
          let queryParams = "";
          // Adding query parameters if they are provided
          if (
            productInventoryRequest.p_skip !== undefined &&
            productInventoryRequest.p_skip !== null
          ) {
            queryParams += `p_skip=${productInventoryRequest.p_skip}&`;
          }
          if (
            productInventoryRequest.p_take !== undefined &&
            productInventoryRequest.p_take !== null
          ) {
            queryParams += `p_take=${productInventoryRequest.p_take}&`;
          }
          if (
            productInventoryRequest.p_product_id !== undefined &&
            productInventoryRequest.p_product_id !== null
          ) {
            queryParams += `p_product_id=${productInventoryRequest.p_product_id}&`;
          }
          const response = await getApiService(
            API_URL.BASE_URL +
              API_URL.INVENTORYITEM +
              API_URL.PRODUCTINVENTORY +
              (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
            token,
            null,
            localisation
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === "200" ||
              response.status === "success")
          ) {
            if (total && total === true) {
              dispatch(getTotalProductInventoryData(response));
            } else if (total && total === "filter") {
              toast.success(t(ValidationMessage.FILTER_SUCCESS));
              dispatch(getProductInventoryData(response));
            } else {
              dispatch(getProductInventoryData(response));
            }
          } else {
            if (!document.querySelector(toastClass)) {
              toast.error(
                response?.message
                  ? response.message
                  : t(ValidationMessage.SOMETHING_WENT_WRONG)
              );
            }
            errorShow = true;
            if (
              response?.message &&
              response.message === UnauthorizeAccessMessage
            ) {
              removePersistStorage(dispatch);
              // dispatch(logout());
            }
          }
          resolve(response);
        } catch (error: any) {
          if (error?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          if (errorShow === false && !document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        } finally {
          errorShow = false;
          dispatch(clearLoader());
        }
      }
    );
  };

  /*
   * Service for adding and updating Product Inventory
   */
  const addUpdateProductInventoryService = (
    addUpdateProductInventoryRequest: addUpdateProductInventoryRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.INVENTORYITEM + API_URL.PRODUCTINVENTORY,
            token,
            addUpdateProductInventoryRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.INVENTORYITEM + API_URL.PRODUCTINVENTORY,
            token,
            addUpdateProductInventoryRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearProductInventoryData());
          dispatch(clearTotalProductInventoryData());
          if (type === "add") {
            dispatch(clearProductInventoryPageData());
            toast.success(
              response?.message
                ? response.message
                : "Product inventory added successfully!"
            );
            navigate("/product-inventory");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Product inventory undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getProductInventoryPageData(pageNumber + 1));
                } else {
                  dispatch(clearProductInventoryPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Product inventory updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/product-inventory");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Product inventory status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getProductInventoryPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting Product Inventory
   */
  const deleteProductInventoryService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL +
            API_URL.INVENTORYITEM +
            API_URL.PRODUCTINVENTORY +
            "/" +
            deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearProductInventoryData());
          dispatch(clearTotalProductInventoryData());
          toast.success(
            response?.message
              ? response.message
              : "Product Item deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getProductInventoryPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getAllProductInventoryService,
    addUpdateProductInventoryService,
    deleteProductInventoryService,
  };
}

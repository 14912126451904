import { getCurrentYear } from "../../../services/Regular-services/DateService";
import {
  CLEAR_SALES_GRAPH_FILTER,
  GET_SALES_GRAPH_FILTER,
} from "../../ReduxKeys";

const salesGraphInitialState = {
  year: getCurrentYear(),
  month: "",
};

/**
 * Store sales graph filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SalesGraphFilterReducer = (
  state = salesGraphInitialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_GRAPH_FILTER:
      return {
        ...state,
        year: action.payload.year,
        month: action.payload.month,
      };
    case CLEAR_SALES_GRAPH_FILTER:
      return salesGraphInitialState;
    default:
      return state;
  }
};
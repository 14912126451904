// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning {
  background-color: rgb(234, 234, 26);
  color: black;
  position: relative;
  top: 0;
  z-index: 9999;
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/WalletWarning/WalletWarning.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;AACJ","sourcesContent":[".warning{\n    background-color: rgb(234, 234, 26);\n    color: black;\n    position: relative;\n    top: 0;\n    z-index: 9999;\n    width: 100%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

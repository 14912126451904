import { CLEAR_WALLET_PAGE, GET_WALLET_PAGE } from "../../ReduxKeys";

/**
 * Set Wallet page data
 * @param {*} page
 * @returns {*}
 */
export const getWalletPageData = (page) => ({
  type: GET_WALLET_PAGE,
  payload: page,
});

/**
 * Clear Wallet page data
 * @returns {*}
 */
export const clearWalletPageData = () => ({
  type: CLEAR_WALLET_PAGE,
});
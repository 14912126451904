import { removeToken } from "../../../Utils/Utils";
import { LOGIN, LOGIN_FAILURE, LOGOUT, UPDATE_AUTH } from "../../ReduxKeys";

const initialState = {
  isAuthenticated: false,
  // rememberMe: false,
  loginTime: null,
  user: null,
};

/**
 * Store user data oon login and remove user data on logout
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        // rememberMe: action.payload.rememberMe,
        loginTime: action.payload.loginTime,
        user: action.payload.user,
      };

    case UPDATE_AUTH:
      return {
        ...state,
        isAuthenticated: true,
        loginTime: state.loginTime,
        user: action.payload.user,
      };

    case LOGOUT:
      removeToken();
      window.location.reload();
      return initialState;

    case LOGIN_FAILURE:
      removeToken();
      return initialState;

    default:
      return state;
  }
};

export default AuthReducer;
import { Dropdown } from "react-bootstrap";
import { IoNotifications } from "react-icons/io5";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import "./Notification.scss";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { ValidationMessage } from "../../Utils/Utils";
import DateTimeSplitterService from "../../services/Regular-services/DateTimeSplitterService/DateTimeSplitterService";
import { toast } from "react-toastify";
import NotificationServices from "./Notification-service/NotificationService";
import { useTranslation } from "react-i18next";

export default function Notification() {
  const {
    deleteNotificationById,
    getNotificationById,
    getNotificationListService,
  } = NotificationServices();

  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const notification = useSelector((state) => state.notification);
  const sideBarData = useSelector((state) => state.sideBar?.items);
  const notificationLoader = useSelector(
    (state) => state.notificationLoader?.items
  );

  /**
   * Fetching Notification data
   */
  const fetchNotification = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
    };
    getNotificationListService(requestBody, tokenData, dispatch, navigate);
  };

  useEffect(() => {
    if (notification && notification.items && notification.items.length <= 0) {
      fetchNotification();
    }
    // eslint-disable-next-line
  }, [notification]);

  /**
   * Handling Navigation
   */
  const handleNavigate = async (route, data) => {
    try {
      if (data.type === "1") {
        const walletPermission = sideBarData.find(
          (data) => data.menu_id === 54 && data.router_link === "wallet"
        );
        if (walletPermission.allow === "1") {
          const Data = {
            data: data,
          };
          navigate("/wallet", { state: { Data } });
        }
      } else {
        // Call the getNotificationById function
        const notificationDetail = await getNotificationById(
          data.NotificationId,
          tokenData,
          dispatch
        );

        // Execute the returned async function to fetch the data
        if (notificationDetail && notificationDetail.status === "success") {
          const Data = {
            data: data,
          };
          navigate(route, { state: { Data } });
        }
        return notificationDetail;
      }
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  /**
   * Handling delete notification
   * @param {*} notificationData
   * @param {*} event
   */
  const handleDeleteClick = (notificationData, event) => {
    if (window.location.pathname === "/import-data") {
      dispatch(
        deleteNotificationById(
          notificationData.NotificationId,
          tokenData,
          navigate
        )
      );
      // handledeletClick(notificationData, dispatch, 'notification', tokenData, navigate);
    } else {
      event.stopPropagation();
      dispatch(
        deleteNotificationById(
          notificationData.NotificationId,
          tokenData,
          navigate
        )
      );
    }
  };
  return (
    <>
      <Dropdown className="notifications-detail">
        <Dropdown.Toggle variant="success" id="notifications-basic">
          <div className="notifications">
            <IoNotifications />
            {notification?.items &&
            notification.items[0]?.unseen_records &&
            notification.items[0].unseen_records > 0 ? (
              <div className="notifications-number">
                <span>{notification.items[0].unseen_records}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {notification?.items &&
          notification.items.length > 0 &&
          notification.items[0].data.length > 0 ? (
            notification.items[0].data.map((item, index) => (
              <Dropdown.Item key={index}>
                <div className="notification-detail-icon">
                  <div
                    className="name-detail-notifications"
                    onClick={() => handleNavigate("/import-data", item)}
                  >
                    {item.title}
                    <DateTimeSplitterService dateTimeString={item.createdAt} />
                  </div>
                  <div className="notification-icon">
                    <MdDeleteOutline
                      onClick={(event) => handleDeleteClick(item, event)}
                    />
                  </div>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <>
              {notificationLoader && (
                <b className="no-notification">...Loading Notifications</b>
              )}
              {!notificationLoader && (
                <b className="no-notification">No new notifications</b>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

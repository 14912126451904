import {
  orderDateNew,
  sixMonthsBefore,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_CUSTOMER_ANALYTICS_FILTER,
  GET_CUSTOMER_ANALYTICS_FILTER,
} from "../../ReduxKeys";

const customerAnalyticsFilterInitialState = {
  StartDate: sixMonthsBefore(),
  EndDate: orderDateNew(),
  branchId: "",
};

/**
 * Store Customer Analytics filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerAnalyticsFilterReducer = (
  state = customerAnalyticsFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ANALYTICS_FILTER:
      return {
        ...state,
        StartDate: action.payload.StartDate,
        EndDate: action.payload.EndDate,
        branchId: action.payload.branchId,
      };
    case CLEAR_CUSTOMER_ANALYTICS_FILTER:
      return customerAnalyticsFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown, Offcanvas } from "react-bootstrap";
import "../Tag/Tag.scss";
import Toggle from "../../components/Toggle/Toggle";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearTagPageData,
  getTagPageData,
} from "../../redux/Actions/tagAction/TagPageAction";
import { clearTagFilterData } from "../../redux/Actions/tagAction/TagFilterAction";
import { clearTagData } from "../../redux/Actions/tagAction/TagActions";
import { useTranslation } from "react-i18next";
import TagServices from "./TagService/TagService";

//Show keys which is to be displayed in table
const showKey = {
  title: true,
};

const showToggle = {
  status: true,
};

export default function Tag() {
  const { getAllTagsService } = TagServices();
  const { t } = useTranslation();
  const tagData = useSelector((state) => state.tag);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const tagPage = useSelector((state) => state.tagPage?.items);
  const tagFilter = useSelector((state) => state.tagFilter);
  const localisation = useSelector((state) => state.localization?.items);
  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [tagActionData, settagActionData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      settagActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      tagActionData[0]?.view &&
      tagActionData[0].view === "0" &&
      tagActionData[0]?.edit &&
      tagActionData[0].edit === "0" &&
      tagActionData[0]?.add &&
      tagActionData[0].add === "0" &&
      tagActionData[0]?.delete &&
      tagActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.title"),
          selector: (row) => row.title,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.title"),
          selector: (row) => row.title,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: t("columns.actions"),
          selector: (row) => row.action,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [tagActionData, localisation]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (tagActionData[0]?.view && tagActionData[0].view === "1") ||
      !tagActionData
        ? true
        : false,
    showEdit:
      (tagActionData[0]?.edit && tagActionData[0].edit === "1") ||
      !tagActionData
        ? true
        : false,
    showDelete:
      (tagActionData[0]?.delete && tagActionData[0].delete === "1") ||
      !tagActionData
        ? true
        : false,
    showUndo:
      (tagActionData[0]?.edit && tagActionData[0].edit === "1") ||
      !tagActionData
        ? true
        : false,
    navigate:
      (tagActionData[0]?.edit && tagActionData[0].edit === "1") ||
      !tagActionData
        ? "/add-tags"
        : false,
  };

  useEffect(() => {
    setCurrentPage(tagPage);
  }, [tagPage]);

  /**
   * Fetch tag data
   */
  const fetchTag = () => {
    if (tagPage > 1) {
      const requestBody = {
        p_skip: (tagPage - 2) * 10,
        p_limit: 10,
      };
      if (tagFilter.title !== "" && tagFilter.title !== null) {
        requestBody.p_title = tagFilter.title;
      }
      if (tagFilter.isActive !== "" && tagFilter.isActive !== null) {
        requestBody.p_isActive = tagFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (tagFilter.isDelete !== "" && tagFilter.isDelete !== null) {
        requestBody.p_IsDeleted = tagFilter.isDelete;
      }
      getAllTagsService(requestBody, tokenData, dispatch);
      setCurrentPage(tagPage - 1); // Update the current page state
      dispatch(getTagPageData(tagPage - 1));
    } else {
      const requestBody = {
        p_skip: (tagPage - 1) * 10,
        p_limit: 10,
      };
      if (tagFilter.title !== "" && tagFilter.title !== null) {
        requestBody.p_title = tagFilter.title;
      }
      if (tagFilter.isActive !== "" && tagFilter.isActive !== null) {
        requestBody.p_isActive = tagFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (tagFilter.isDelete !== "" && tagFilter.isDelete !== null) {
        requestBody.p_IsDeleted = tagFilter.isDelete;
      }
      getAllTagsService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (tagData && tagData.items && tagData.items.length === 0) {
      fetchTag();
    } else {
      if (
        tagFilter?.title !== "" ||
        tagFilter?.isActive !== "" ||
        tagFilter?.isDelete !== ""
      ) {
        // handleShow();
      }
    }
    // eslint-disable-next-line
  }, [tagData]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (tagFilter.title !== "" && tagFilter.title !== null) {
        requestBody.p_title = tagFilter.title;
      }
      if (tagFilter.isActive !== "" && tagFilter.isActive !== null) {
        requestBody.p_isActive = tagFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (tagFilter.isDelete !== "" && tagFilter.isDelete !== null) {
        requestBody.p_IsDeleted = tagFilter.isDelete;
      }
      getAllTagsService(requestBody, tokenData, dispatch);
      dispatch(getTagPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearTagFilterData());
    dispatch(clearTagPageData());
    dispatch(clearTagData());
    handleClose();
  };

  return (
    <>
      <div>
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {tagActionData && tagActionData.length > 0
                    ? getRouteNameFromSidebar(
                        tagActionData[0].name,
                        localisation
                      )
                    : t("Tags")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {tagActionData && tagActionData.length > 0
                    ? getRouteNameFromSidebar(
                        tagActionData[0].name,
                        localisation
                      )
                    : t("Tags")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(tagFilter?.title !== "" ||
                tagFilter?.isActive !== "" ||
                tagFilter?.isDelete !== "") && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="tags" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              {tagData &&
                tagData.items[0]?.data &&
                tagData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={tagData.items[0].data}
                    fileName={"image"}
                  ></ExcelData>
                )}
              {((tagActionData &&
                tagActionData[0]?.add &&
                tagActionData[0].add === "1") ||
                !tagActionData) && (
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Tag"
                  onClick={() => navigate("/add-tags")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>
          {/* {isToggled && <Filter page="tags"></Filter>} */}
          {tagData && tagData.items[0]?.data && tagData.items[0].data && (
            <>
              <CustomTable
                page="tags"
                showToggle={showToggle}
                checkBool={false}
                showKey={showKey}
                showActions={showActionBool ? showActions : false}
                showTooltip={true}
                data={tagData.items[0].data}
                heading={columns}
                disabled={
                  tagActionData &&
                  tagActionData[0]?.edit &&
                  tagActionData[0].edit === "0"
                }
                pageData={tagPage}
              />

              {tagData.items[0].total_records > tagData.items[0].data.length &&
                tagData.items[0].total_records > 10 && (
                  <CustomPagination
                    totalRecords={tagData.items[0].total_records}
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                  />
                )}
            </>
          )}
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

import {
  CLEAR_ADMIN_PR_DATA,
  CLEAR_TOTAL_ADMIN_PR,
  GET_ADMIN_PR_DATA,
  GET_TOTAL_ADMIN_PR,
} from "../../ReduxKeys";

const adminPRInitialState = {
  items: [],
};

/**
 * Store admin PR user list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const AdminPRUserReducer = (state = adminPRInitialState, action) => {
  switch (action.type) {
    case GET_ADMIN_PR_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_ADMIN_PR_DATA:
      return adminPRInitialState;
    default:
      return state;
  }
};

const totalAdminPRInitialState = {
  items: [],
};

/**
 * Store total Admin PR list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalAdminPRReducer = (
  state = totalAdminPRInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_ADMIN_PR:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_ADMIN_PR:
      return totalAdminPRInitialState;
    default:
      return state;
  }
};
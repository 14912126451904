import {
  CLEAR_CUSTOMER_ORDER,
  CLEAR_TOTAL_CUSTOMER_ORDER,
  GET_CUSTOMER_ORDER,
  GET_TOTAL_CUSTOMER_ORDER,
} from "../../ReduxKeys";

/**
 * Set customer order data into reducer
 * @param {*} CUSTOMERORDER
 * @returns {*}
 */
export const getCustomerOrderData = (CUSTOMERORDER) => ({
  type: GET_CUSTOMER_ORDER,
  payload: CUSTOMERORDER,
});

/**
 * Clear customer ORDER list data
 * @returns {*}
 */
export const clearCustomerOrderData = () => ({
  type: CLEAR_CUSTOMER_ORDER,
});

/**
 * Set total CUSTOMER ORDER list data into reducer (No pagination)
 * @param {*} CUSTOMERORDER
 * @returns {*}
 */
export const getTotalCustomerOrderData = (CUSTOMERORDER) => ({
  type: GET_TOTAL_CUSTOMER_ORDER,
  payload: CUSTOMERORDER,
});

/**
 * Clear total CUSTOMER ORDER list data (No pagination)
 * @returns {*}
 */
export const clearTotalCustomerOrder = () => ({
  type: CLEAR_TOTAL_CUSTOMER_ORDER,
});

import { CLEAR_ROLE_FILTER, GET_ROLE_FILTER } from "../../ReduxKeys";

/**
 * Set role filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getRoleFilterData = ({ name, isActive, isDelete }) => ({
  type: GET_ROLE_FILTER,
  payload: { name, isActive, isDelete },
});

/**
 * Clear role filtered data
 * @returns {*}
 */
export const clearRoleFilterData = () => ({
  type: CLEAR_ROLE_FILTER,
});
export const getApiService = async (Url, token, bodyData, localisation) => {
    try {
      const response = await fetch(Url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : undefined,
          'Content-Language': localisation ? localisation : 'en',
          'accept-language': localisation ? localisation : 'en',
        },
        ...(bodyData && { body: JSON.stringify(bodyData) }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Request failed');
      }
      return data;
    } catch (error) {
      console.error('get api error: ', error);
      throw error; // Rethrow to propagate errors to callers
    }
  };
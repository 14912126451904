import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "../Analytics/Analytics.scss";
import { PiChartLineUpBold } from "react-icons/pi";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Button from "../../components/Button/Button";
import Filter from "../../components/Filter/Filter";
import { useTranslation } from "react-i18next";
import { FaTableCells } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, {
  getRouteNameFromSidebar,
  orderPaymentTableArray,
  orderTypeArray,
} from "../../Utils/Utils";
import { clearSalesGraphFilter } from "../../redux/Actions/AnalyticsActions/SalesGraphFilterAction";
import { clearSalesGraphData } from "../../redux/Actions/AnalyticsActions/SalesGraphAction";
import {
  clearSalesTablePageData,
  getSalesTablePageData,
} from "../../redux/Actions/AnalyticsActions/SalesTablePageAction";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import { format } from "date-fns";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import { clearSalesTableData } from "../../redux/Actions/AnalyticsActions/SalesTableAction";
import { clearSalesTableFilterData } from "../../redux/Actions/AnalyticsActions/SalesTableFilterAction";
import AnalyticsService from "./AnalyticsService/AnalyticsService";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Analytics() {
  const { getSalesChartDataService, getSalesTableDataService } =
    AnalyticsService();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const salesGraph = useSelector((state) => state.salesGraph);
  const salesGraphFilter = useSelector((state) => state.salesGraphFilter);
  const salesTable = useSelector((state) => state.salesTable);
  const salesTablePage = useSelector((state) => state.salesTablePage?.items);
  const salesTableFilter = useSelector((state) => state.salesTableFilter);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [showSalesGraph, setSalesGraph] = useState(false);
  const [showSalesTable, setSalesTable] = useState(false);
  const [salesMode, setSalesMode] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPage] = useState(1);
  // const [groupedTableData, setGroupedTableData] = useState([]);
  const authuserData = useSelector((state) => state.auth?.user);
  const [salesActionData, setSalesData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [totalSalesAmt, setTotalSales] = useState(0);
  const [salesBarChartData, setSalesBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: t("Customer_Spending_Analytics"),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t("Total Spent"),
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
            salesGraphFilter.month === "" ? t("Visit Month") : t("Visit Date"),
        },
      },
    },
  };
  const showKey = {
    orderDate: true,
    orderNumber: true,
    orderSubTotal: true,
    discountAmount: true,
    taxAmount: true,
    orderAmount: true,
    orderType: true,
    deliveryCharge: true,
    serviceCharge: true,
    totalItem: true,
  };

  const [columns, setColumns] = useState([
    {
      name: t("lable_date"),
      selector: (row) => row.orderDate,
    },
    {
      name: t("columns.order_number"),
      selector: (row) => row.orderNumber,
    },
    { name: t("lable_type"), selector: (row) => row.orderType },
    { name: t("quantities"), selector: (row) => row.quantities },
    {
      name: t("columns.subtotal"),
      selector: (row) => row.orderSubTotal,
    },
    {
      name: t("columns.discount"),
      selector: (row) => row.discountAmount,
    },
    {
      name: t("columns.tax"),
      selector: (row) => row.taxAmount,
    },
    {
      name: t("lable_delivery_charges"),
      selector: (row) => row.delivery_charge,
    },
    {
      name: t("placeholder_service_charge"),
      selector: (row) => row.service_charge,
    },
    {
      name: t("columns.order_amount"),
      selector: (row) => row.orderAmount,
    },
  ]);

  const [styleData, setStyleData] = useState({
    orderNumber: {
      type: { minWidth: "250px", width: "250px", maxWidth: "250px" },
    },
    orderSubTotal: { type: { textAlign: "end" } },
    discountAmount: { type: { textAlign: "end" } },
    taxAmount: { type: { textAlign: "end" } },
    orderAmount: { type: { textAlign: "end" } },
    [t("columns.order_number")]: {
      type: { minWidth: "250px", width: "250px", maxWidth: "250px" },
    },
    orderType: { type: { textAlign: "end" } },
    deliveryCharge: { type: { textAlign: "end" } },
    serviceCharge: { type: { textAlign: "end" } },
    totalItem: { type: { textAlign: "end" } },
  });

  useEffect(() => {
    setColumns([
      {
        name: t("lable_date"),
        selector: (row) => row.orderDate,
      },
      {
        name: t("columns.order_number"),
        selector: (row) => row.orderNumber,
      },
      { name: t("lable_type"), selector: (row) => row.orderType },
      { name: t("quantities"), selector: (row) => row.quantities },
      {
        name: t("columns.subtotal"),
        selector: (row) => row.orderSubTotal,
      },
      {
        name: t("columns.discount"),
        selector: (row) => row.discountAmount,
      },
      {
        name: t("columns.tax"),
        selector: (row) => row.taxAmount,
      },
      {
        name: t("lable_delivery_charges"),
        selector: (row) => row.delivery_charge,
      },
      {
        name: t("placeholder_service_charge"),
        selector: (row) => row.service_charge,
      },
      {
        name: t("columns.order_amount"),
        selector: (row) => row.orderAmount,
      },
    ]);
    setStyleData({
      orderNumber: {
        type: { minWidth: "250px", width: "250px", maxWidth: "250px" },
      },
      orderSubTotal: { type: { textAlign: "end" } },
      discountAmount: { type: { textAlign: "end" } },
      taxAmount: { type: { textAlign: "end" } },
      orderAmount: { type: { textAlign: "end" } },
      [t("columns.order_number")]: {
        type: { minWidth: "250px", width: "250px", maxWidth: "250px" },
      },
      deliveryCharge: { type: { textAlign: "end" } },
      serviceCharge: { type: { textAlign: "end" } },
      totalItem: { type: { textAlign: "end" } },
    });
    // eslint-disable-next-line
  }, [localisation]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "analytics"
      );
      setSalesData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (salesMode) {
      if (salesGraph && salesGraph.items && salesGraph.items.length === 0) {
        fetchData();
      } else {
        if (salesGraph && salesGraph.items && salesGraph.items[0]?.data) {
          const data = salesGraph.items[0]?.data;
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          const labels =
            salesGraphFilter.month === ""
              ? data.map((item) => monthNames[item.sale_month - 1]) // Monthly labels
              : data.map((item) => {
                  const date = new Date(item.sale_day);
                  return `${date.getDate()}-${
                    date.getMonth() + 1
                  }-${date.getFullYear()}`; // Daily labels
                });
          const totalSales = data.map((item) =>
            parseFloat(item.total_sales_amount)
          );
          const totalSalesSum = totalSales
            .reduce((acc, curr) => acc + parseFloat(curr), 0)
            .toFixed(2);

          setTotalSales(totalSalesSum);
          setSalesBarChartData({
            labels, // Month names as labels
            datasets: [
              {
                label: t("Total Sales Amount"),
                data: totalSales, // Sales data
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [salesGraph, salesGraphFilter, salesMode, localisation]);

  useEffect(() => {
    if (!salesMode) {
      if (salesTable && salesTable.items && salesTable.items.length === 0) {
        setTableData([]);
        fetchSalesTableData();
      } else {
        if (salesTable.items[0].order.length > 0) {
        }
        const data = salesTable.items[0].order;
        const orderTableData = data
          .map((item) => ({
            id: item.id,
            orderDate: item.order_date
              ? format(item.order_date, "dd/MM/yyyy")
              : "",
            orderNumber: item.order_number,
            orderType: orderTypeArray.find((type) => type.value === item.type)
              .label,
            paymnetType: orderPaymentTableArray.find(
              (payment) => payment.value === item.payment
            ).status,
            totalItem: item?.products
              ? JSON.parse(item.products).reduce(
                  (sum, product) => sum + Number(product.quantity),
                  0
                )
              : 0,
            orderSubTotal: item.products
              ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                JSON.parse(item.products).reduce(
                  (sum, product) =>
                    sum + Number(product.price) * Number(product.quantity),
                  0
                )
              : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                0,
            discountAmount:
              (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              (item.total_discount_amount ? item.total_discount_amount : "0"),
            taxAmount:
              (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              (item?.total_tax_amount ? item.total_tax_amount : "0"),
            deliveryCharge: item?.delivery_charge ? item.delivery_charge : "0",
            serviceCharge: item?.service_charge ? item.service_charge : "0",
            orderAmount:
              (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              (item?.order_amount
                ? (
                    Number(item.order_amount) + Number(item?.service_charge)
                  ).toFixed(2)
                : "0.00"),
            customerName:
              item.first_name !== "Bespeakk" && item.first_name !== "bespeakk"
                ? `${item.first_name} ${item.last_name}`
                : "N/A",
            phoneNumber:
              item.phone_number &&
              item.phone_number !== "" &&
              Number(item.phone_number) !== 8155006366
                ? item.phone_number
                : "N/A",
          }))
          .filter(
            (order, index, self) =>
              index === self.findIndex((o) => o.id === order.id)
          );
        setTableData(orderTableData);
      }
    }

    // eslint-disable-next-line
  }, [salesMode, salesTable, salesTableFilter]);

  // useEffect(() => {
  //   if (tableData && tableData.length > 0) {
  //     const groupedData = Object.values(
  //       tableData.reduce((acc, order) => {
  //         if (!acc[order.id]) {
  //           acc[order.id] = [];
  //         }
  //         acc[order.id].push(order);
  //         return acc;
  //       }, {})
  //     );
  //     setGroupedTableData(groupedData);
  //   }
  //   // eslint-disable-next-line
  // }, [tableData]);

  // functions for graph view
  const fetchData = () => {
    const requestBody = {
      p_year: salesGraphFilter.year,
    };
    if (salesGraphFilter.month !== "" && salesGraphFilter.month !== null) {
      requestBody.p_month = salesGraphFilter.month;
    }
    getSalesChartDataService(requestBody, tokenData, dispatch);
  };
  const handleSalesGraphClose = () => setSalesGraph(false);
  const handleSalesGraphShow = () => setSalesGraph(true);
  const clearSalesGraphFilters = () => {
    dispatch(clearSalesGraphFilter());
    // setIsToggled(false);
    dispatch(clearSalesGraphData());
  };

  // functions for table view
  const fetchSalesTableData = () => {
    if (salesTablePage > 1) {
      const requestBody = {
        p_skip: (salesTablePage - 2) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(salesTableFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(salesTableFilter.endDate),
        p_year: salesTableFilter.year,
      };
      setCurrentPage(salesTablePage - 1);
      dispatch(getSalesTablePageData(salesTablePage - 1));
      getSalesTableDataService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (salesTablePage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_start_date: formatDateToYYYYMMDD(salesTableFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(salesTableFilter.endDate),
        p_year: salesTableFilter.year,
      };
      getSalesTableDataService(requestBody, tokenData, dispatch);
    }
  };

  const handleSalesTableClose = () => setSalesTable(false);
  const handleSalesTableShow = () => setSalesTable(true);

  const onPageChange = (pageNumber) => {
    const requestData = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_year: salesTableFilter.year,
    };
    if (
      salesTableFilter.startDate !== "" &&
      salesTableFilter.startDate !== null
    ) {
      requestData.p_start_date = formatDateToYYYYMMDD(
        salesTableFilter.startDate
      );
    }
    if (salesTableFilter.endDate !== "" && salesTableFilter.endDate !== null) {
      requestData.p_end_date = formatDateToYYYYMMDD(salesTableFilter.endDate);
    }
    if (pageNumber !== currentPageNumber) {
      getSalesTableDataService(requestData, tokenData, dispatch);
    }
    setCurrentPage(pageNumber);
    dispatch(getSalesTablePageData(pageNumber));
  };

  const clearSalesTableFilters = () => {
    dispatch(clearSalesTableFilterData());
    dispatch(clearSalesTableData());
    dispatch(clearSalesTablePageData());
  };

  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {salesActionData && salesActionData.length > 0
                ? getRouteNameFromSidebar(salesActionData[0].name, localisation)
                : t("sales")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {salesActionData && salesActionData.length > 0
                ? getRouteNameFromSidebar(salesActionData[0].name, localisation)
                : t("sales")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups crm-btn-group-analytics">
          {salesMode ? (
            <>
              <div className="box Amount amt-btn btn-fillter-main">
                {t("lable_amount")} : {totalSalesAmt}
              </div>
              <div className="d-flex">
                {((salesGraphFilter.year !== "" &&
                  salesGraphFilter.year !== null &&
                  parseInt(salesGraphFilter.year, 10) !==
                    new Date().getFullYear()) ||
                  (salesGraphFilter.month !== "" &&
                    salesGraphFilter.month !== null)) && (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="crm-clear-filter">
                      <Dropdown.Item onClick={clearSalesGraphFilters}>
                        {t("clear_filters")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <Button
                  onClick={handleSalesGraphShow}
                  data-bs-toggle="tooltip"
                  title="Filter"
                  // onClick={handleButtonClick}
                  className="btn btn-fillter-main crm-filter"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                <Button
                  data-bs-toggle="tooltip"
                  title="Table View"
                  onClick={() => {
                    setSalesMode(!salesMode);
                    clearSalesGraphFilters(); // Call the additional function
                  }}
                  className="btn btn-fillter-main crm-filter"
                  name={
                    <div className="Plus">
                      <FaTableCells />
                    </div>
                  }
                ></Button>
              </div>
            </>
          ) : (
            <>
              {/* <div className="box Amount amt-btn btn-fillter-main">
                {t("lable_amount")} : {totalSalesAmt}
              </div> */}
              <div className="d-flex">
                {((salesTableFilter.year !== "" &&
                  salesTableFilter.year !== null &&
                  parseInt(salesTableFilter.year, 10) !==
                    new Date().getFullYear()) ||
                  (salesTableFilter.month !== "" &&
                    salesTableFilter.month !== null) ||
                  (salesTableFilter.startDate !== "" &&
                    salesTableFilter.startDate !== null &&
                    new Date(
                      formatDateToYYYYMMDD(salesTableFilter.startDate)
                    ).toDateString() !==
                      new Date(
                        new Date().setMonth(new Date().getMonth() - 1)
                      ).toDateString()) ||
                  (salesTableFilter.endDate !== "" &&
                    salesTableFilter.endDate !== null &&
                    new Date(
                      formatDateToYYYYMMDD(salesTableFilter.endDate)
                    ).toDateString() !== new Date().toDateString())) && (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="crm-clear-filter">
                      <Dropdown.Item onClick={clearSalesTableFilters}>
                        {t("clear_filters")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button
                  onClick={handleSalesTableShow}
                  data-bs-toggle="tooltip"
                  title="Filter"
                  // onClick={handleButtonClick}
                  className="btn btn-fillter-main crm-filter"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                <Button
                  data-bs-toggle="tooltip"
                  title="Chart View"
                  onClick={() => {
                    setSalesMode(!salesMode);
                    clearSalesTableFilters();
                  }}
                  className="btn btn-fillter-main crm-filter"
                  name={
                    <div className="Plus">
                      <PiChartLineUpBold />
                    </div>
                  }
                ></Button>
              </div>
            </>
          )}
        </div>

        <Offcanvas
          show={salesMode ? showSalesGraph : showSalesTable}
          onHide={salesMode ? handleSalesGraphClose : handleSalesTableClose}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter Options</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter
              page={salesMode ? "salesGraph" : "salesData"}
              handleClose={
                salesMode ? handleSalesGraphClose : handleSalesTableClose
              }
            ></Filter>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {salesMode ? (
        <>
          {salesGraph &&
          salesGraph.items[0]?.data &&
          salesGraph.items[0].data.length > 0 ? (
            <div className="chart">
              <Bar data={salesBarChartData} options={chartOptions} />
            </div>
          ) : (
            <div className="input-search">
              <div className="no-record">
                <h3 data-bs-toggle="tooltip" title="No records!">
                  {t("no_records")}
                </h3>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {salesTable.items &&
          salesTable.items.length > 0 &&
          tableData.length > 0 ? (
            <>
              <CustomTable
                page="salesData"
                showKey={showKey}
                showActions={false}
                showTooltip={true}
                data={tableData}
                heading={columns}
                checkBool={false}
                styleData={styleData}
              />
              {salesTable.items[0]?.totalRecords > 10 && (
                <CustomPagination
                  totalRecords={salesTable.items[0].totalRecords}
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                  page="salesData"
                />
              )}
            </>
          ) : (
            <div className="input-search">
              <div className="no-record">
                <h3 data-bs-toggle="tooltip" title="No records!">
                  {t("no_records")}
                </h3>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
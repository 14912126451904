import { toast } from "react-toastify";
import { API_URL, ValidationMessage } from "../../Utils/Utils";
import { getApiService } from "../../services/API-services/GetService";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function VerifyOtpServices() {
  const localisation = useSelector((state) => state.localization?.items);
  const { t } = useTranslation();

  const verifyOtp = (email, otp, token, navigate) => {
    return async (dispatch) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.VERIFY_OTP +
            "email=" +
            email +
            "&otp=" +
            otp,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          toast.success(
            response?.message ? response.message : "OTP verified successfully!"
          );
          navigate("/reset-password", { state: { email: email } });
        } else {
          if (response === "unauthenticated") {
            toast.error("Unauthenticated user!");
          } else {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        }
      } catch (error) {
        toast.error(
          error?.message
            ? error.message
            : t(ValidationMessage.SOMETHING_WENT_WRONG)
        );
      } finally {
        dispatch(clearLoader());
      }
    };
  };
  return { verifyOtp };
}

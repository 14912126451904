import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import CustomTable from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import DiscountService from "./DiscountService/DiscountService";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import { format } from "date-fns";
import { clearDiscountData } from "../../redux/Actions/DiscountAction/DiscountAction";
import { clearDiscountFilterData } from "../../redux/Actions/DiscountAction/DiscountFilterAction";
import {
  clearDiscountPageData,
  getDiscountPageData,
} from "../../redux/Actions/DiscountAction/DiscountPageAction";
import { useTranslation } from "react-i18next";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  discount_type: true,
  discount_value: true,
  max_discount_ammount: true,
  min_cart_value: true,
  start_date: true,
  end_date: true,
  discountCode: true,
};

const showToggle = {
  status: true,
  delete: false,
};

export default function Discount() {
  const { t } = useTranslation();
  const {getAllDiscountService} = DiscountService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const localisation = useSelector((state) => state.localization?.items);

  const authuserData = useSelector((state) => state.auth?.user);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [discountActionData, setdiscountActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setdiscountActionData(actionData);
    }
  }, [sideBarData]);

  //Show actions which is to be displayed in table

  const showActions = {
    showView:
      (discountActionData[0]?.view && discountActionData[0].view === "1") ||
      !discountActionData
        ? true
        : false,
    showEdit:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? true
        : false,
    showDelete:
      (discountActionData[0]?.delete && discountActionData[0].delete === "1") ||
      !discountActionData
        ? true
        : false,
    showUndo:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? true
        : false,
    navigate:
      (discountActionData[0]?.edit && discountActionData[0].edit === "1") ||
      !discountActionData
        ? "/edit-discount"
        : false,
  };

  //Headings
  const [columns, setColumns] = useState([]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      discountActionData[0]?.view &&
      discountActionData[0].view === "0" &&
      discountActionData[0]?.edit &&
      discountActionData[0].edit === "0" &&
      discountActionData[0]?.add &&
      discountActionData[0].add === "0" &&
      discountActionData[0]?.delete &&
      discountActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.Name,
        },
        {
          name: t("columns.type"),
          selector: (row) => row.Type,
        },
        {
          name: t("columns.value"),
          selector: (row) => row.value,
        },
        {
          name: t("columns.max_discount"),
          selector: (row) => row.Value,
        },
        {
          name: t("columns.min_cart"),
          selector: (row) => row.cart,
        },
        {
          name: t("columns.start"),
          selector: (row) => row.Date,
        },
        {
          name: t("columns.end"),
          selector: (row) => row.date,
        },
        {
          name: t("columns.code"),
          selector: (row) => row.Code,
        },
        {
          name: t("columns.status"),
          selector: (row) => row.status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.Name,
        },
        {
          name: t("columns.type"),
          selector: (row) => row.Type,
        },
        {
          name: t("columns.value"),
          selector: (row) => row.value,
        },
        {
          name: t("columns.max_discount"),
          selector: (row) => row.Value,
        },
        {
          name: t("columns.min_cart"),
          selector: (row) => row.cart,
        },
        {
          name: t("columns.start"),
          selector: (row) => row.Date,
        },
        {
          name: t("columns.end"),
          selector: (row) => row.date,
        },
        {
          name: t("columns.code"),
          selector: (row) => row.Code,
        },
        {
          name: t("columns.status"),
          selector: (row) => row.status,
        },
        {
          name: t("columns.actions"),
          selector: (row) => row.actions,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [discountActionData, localisation]);

  const discount = useSelector((state) => state.discount);
  const discountPage = useSelector((state) => state.discountPage?.items);
  const discountFilter = useSelector((state) => state.discountFilter);

  const [discountData, setDiscountData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(discountPage);
  }, [discountPage]);

  /**
   * Fetch discount data
   */
  const fetchDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (discountPage > 1) {
      requestBody.p_skip = (discountPage - 2) * 10;
      dispatch(getDiscountPageData(discountPage - 1));
    } else {
      requestBody.p_skip = (discountPage - 1) * 10;
      dispatch(getDiscountPageData(discountPage));
    }

    if (discountFilter.name !== "" && discountFilter.name !== null) {
      requestBody.p_title = discountFilter.name;
    }
    if (discountFilter.type !== "" && discountFilter.type !== null) {
      requestBody.p_discount_type = discountFilter.type;
    }
    if (
      discountFilter.start_date !== "" &&
      discountFilter.start_date !== null
    ) {
      requestBody.p_start_date = format(
        new Date(discountFilter.start_date),
        "yyyy-MM-dd"
      );
    }
    if (discountFilter.end_date !== "" && discountFilter.end_date !== null) {
      requestBody.p_end_date = format(
        new Date(discountFilter.end_date),
        "yyyy-MM-dd"
      );
    }
    if (discountFilter.code !== "" && discountFilter.code !== null) {
      requestBody.p_discount_code = discountFilter.code;
    }

    if (discountFilter.isActive !== "" && discountFilter.isActive !== null) {
      requestBody.p_IsActive = discountFilter.isActive;
    } else {
      requestBody.p_IsActive = "1";
    }

    if (discountFilter.isDelete !== "" && discountFilter.isDelete !== null) {
      requestBody.p_isDeleted = discountFilter.isDelete;
    }
    getAllDiscountService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (discount && discount.items && discount.items.length === 0) {
      fetchDiscount();
    } else {
      if (
        discount.items &&
        discount.items[0].data &&
        discount.items[0].data &&
        discount.items[0].data.length > 0
      ) {
        const transformedData = discount.items[0].data.map((item) => ({
          discountId: item.discountId,
          name: item.name,
          discount_type:
            item.discount_type === 0 || item.discount_type === "0"
              ? authuserData?.data?.symbol
                ? authuserData.data.symbol
                : "₹"
              : "%",
          discount_value: item.discount_ammount,
          max_discount_ammount:
            item.max_discount_ammount && item.max_discount_ammount !== ""
              ? item.max_discount_ammount
              : "—",
          min_cart_value: item.min_cart_value,
          start_date: format(new Date(item.start_date), "dd/MM/yyyy"),
          end_date: format(new Date(item.end_date), "dd/MM/yyyy"),
          discountCode: item.discountCode,
          is_first_order_discount: item.is_first_order_discount,
          discount_type_on: item.discount_type_on,
          is_active: item.is_active,
          IsDeleted: item.IsDeleted,
          created_by: item.created_by,
          modified_by: item.modified_by,
          created_at: item.created_at,
          modified_at: item.modified_at,
          discount_apply_on: item.discount_apply_on,
          spacific_item: item.spacific_item,
        }));
        setDiscountData(transformedData);
      } else {
        setDiscountData([]);
      }
    }
    if (
      (discountFilter.name !== "" && discountFilter.name !== null) ||
      (discountFilter.type !== "" && discountFilter.type !== null) ||
      (discountFilter.start_date !== "" &&
        discountFilter.start_date !== null) ||
      (discountFilter.end_date !== "" && discountFilter.end_date !== null) ||
      (discountFilter.code !== "" && discountFilter.code !== null) ||
      (discountFilter.isActive !== "" && discountFilter.isActive !== null) ||
      (discountFilter.isDelete !== "" && discountFilter.isDelete !== null)
    ) {
      // handleShow();
    }
    // eslint-disable-next-line
  }, [discount]);

  const styleData = {
    discount_value: { type: { textAlign: "end" } },
    max_discount_ammount: { type: { textAlign: "end" } },
    discountCode: {type: {minWidth: "100px", maxWidth: "120px"}},
    min_cart_value: { type: { textAlign: "end" } },
    start_date: { type: { textAlign: "center" } },
    end_date: { type: { textAlign: "center" } },
    Actionstoggle: {
      type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
    },
    [t("columns.actions")]: { type: { minWidth: "100px", width: "100px", maxWidth: "100px" } }
  };

  /**
   * On page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getDiscountPageData(pageNumber));
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (discountFilter.name !== "" && discountFilter.name !== null) {
        requestBody.p_title = discountFilter.name;
      }
      if (discountFilter.type !== "" && discountFilter.type !== null) {
        requestBody.p_discount_type = discountFilter.type;
      }
      if (
        discountFilter.start_date !== "" &&
        discountFilter.start_date !== null
      ) {
        requestBody.p_start_date = format(
          new Date(discountFilter.start_date),
          "yyyy-MM-dd"
        );
      }
      if (discountFilter.end_date !== "" && discountFilter.end_date !== null) {
        requestBody.p_end_date = format(
          new Date(discountFilter.end_date),
          "yyyy-MM-dd"
        );
      }
      if (discountFilter.code !== "" && discountFilter.code !== null) {
        requestBody.p_discount_code = discountFilter.code;
      }

      if (discountFilter.isActive !== "" && discountFilter.isActive !== null) {
        requestBody.p_IsActive = discountFilter.isActive;
      } else {
        requestBody.p_IsActive = "1";
      }

      if (discountFilter.isDelete !== "" && discountFilter.isDelete !== null) {
        requestBody.p_isDeleted = discountFilter.isDelete;
      }
      getAllDiscountService(requestBody, tokenData, dispatch);
    }
  };

  /**
   * clear filtered data
   */
  const clearFilters = () => {
    dispatch(clearDiscountData());
    dispatch(clearDiscountFilterData());
    dispatch(clearDiscountPageData());
    handleClose();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {discountActionData && discountActionData.length > 0
                    ? getRouteNameFromSidebar(discountActionData[0].name, localisation)
                    : t("Discounts")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {discountActionData && discountActionData.length > 0
                    ? getRouteNameFromSidebar(discountActionData[0].name, localisation)
                    : t("Discounts")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {((discountFilter.name !== "" && discountFilter.name !== null) ||
                (discountFilter.type !== "" && discountFilter.type !== null) ||
                (discountFilter.start_date !== "" &&
                  discountFilter.start_date !== null) ||
                (discountFilter.end_date !== "" &&
                  discountFilter.end_date !== null) ||
                (discountFilter.code !== "" && discountFilter.code !== null) ||
                (discountFilter.isActive !== "" &&
                  discountFilter.isActive !== null) ||
                (discountFilter.isDelete !== "" &&
                  discountFilter.isDelete !== null)) && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="discount"></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              {discount &&
                discount.items &&
                discount.items[0]?.data &&
                discount.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={discount.items[0].data}
                    fileName={"discount"}
                  ></ExcelData>
                )}
              {((discountActionData[0]?.add &&
                discountActionData[0].add === "1") ||
                !discountActionData) && (
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Discount"
                  onClick={() => navigate("/add-discount")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>
          {discount &&
            discount.items &&
            discount.items[0]?.data &&
            discount.items[0].data && (
              <>
                <CustomTable
                  page="discount"
                  showDescription={true}
                  showToggle={showToggle}
                  showKey={showKey}
                  showActions={showActionBool ? showActions : false}
                  showTooltip={true}
                  checkBool={false}
                  data={discountData}
                  heading={columns}
                  styleData={styleData}
                  disabled={
                    discountActionData[0]?.edit &&
                    discountActionData[0].edit === "0"
                  }
                  pageData={discountPage}
                />

                {discount.items[0].total_records >
                  discount.items[0].data.length &&
                  discount.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={discount.items[0].total_records}
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
      </div>
    </>
  );
}
import { Dispatch } from "redux";
import { BranchTokenRequest, LoginResponse } from "./BranchTokenModal";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { updateAuthData } from "../../../redux/Actions/authAction/AuthAction";
import { clearAccountDetailData } from "../../../redux/Actions/PaymentDetailAction/AccountDetailAction";
import { clearPaymentDetailData } from "../../../redux/Actions/PaymentDetailAction/PaymentDetailAction";
import { clearStakeHolderDetailData } from "../../../redux/Actions/PaymentDetailAction/StakeHolderDetailAction";
import { clearAccountProductConfigData } from "../../../redux/Actions/PaymentDetailAction/AccountProductConfigAction";
import { clearVerifyAccountData } from "../../../redux/Actions/PaymentDetailAction/VerifyAccountAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function BranchTokenServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  const GetBranchTokenService = (
    requestBody: BranchTokenRequest,
    token: string,
    dispatch: Dispatch
  ) => {
    return new Promise<LoginResponse>(async (resolve, reject) => {
      dispatch(setLoader());
      try {
        const response = await postApiService(
          API_URL.BASE_URL + API_URL.BRANCH_TOKEN,
          token,
          requestBody,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(updateAuthData({ user: response, reload: true }));
          dispatch(clearAccountDetailData());
          dispatch(clearPaymentDetailData());
          dispatch(clearStakeHolderDetailData());
          dispatch(clearAccountProductConfigData());
          dispatch(clearVerifyAccountData());
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };
  return { GetBranchTokenService };
}

import {
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import { CLEAR_WALLET_FILTER, GET_WALLET_FILTER } from "../../ReduxKeys";

const walletFilterInitialState = {
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
  status: "",
  type: "",
};

/**
 * Store WALLET filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const WalletFilterReducer = (
  state = walletFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_WALLET_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        status: action.payload.status,
        type: action.payload.type,
      };
    case CLEAR_WALLET_FILTER:
      return walletFilterInitialState;
    default:
      return state;
  }
};
import { Dispatch } from "redux";
import {
  DiscountBrandResponse,
  DiscountListResponse,
  addUpdateDiscountRequest,
  discountListRequest,
  individualDiscountResponse,
} from "./DiscountModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearDiscountData,
  clearTotalDiscountData,
  getDiscountData,
  getTotalDiscountData,
} from "../../../redux/Actions/DiscountAction/DiscountAction";
import { getDiscountBrandData } from "../../../redux/Actions/DiscountAction/DiscountBrandAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import {
  clearDiscountPageData,
  getDiscountPageData,
} from "../../../redux/Actions/DiscountAction/DiscountPageAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function DiscountService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get discount list
   */
  const getAllDiscountService = (
    discountListRequest: discountListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<DiscountListResponse> => {
    return new Promise<DiscountListResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          discountListRequest.p_skip !== undefined &&
          discountListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${discountListRequest.p_skip}&`;
        }
        if (
          discountListRequest.p_limit !== undefined &&
          discountListRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${discountListRequest.p_limit}&`;
        }
        if (
          discountListRequest.p_title !== undefined &&
          discountListRequest.p_title !== null
        ) {
          queryParams += `p_title=${discountListRequest.p_title}&`;
        }
        if (
          discountListRequest.p_discount_code !== undefined &&
          discountListRequest.p_discount_code !== null
        ) {
          queryParams += `p_discount_code=${discountListRequest.p_discount_code}&`;
        }
        if (
          discountListRequest.p_discount_type !== undefined &&
          discountListRequest.p_discount_type !== null
        ) {
          queryParams += `p_discount_type=${discountListRequest.p_discount_type}&`;
        }
        if (
          discountListRequest.p_start_date !== undefined &&
          discountListRequest.p_start_date !== null
        ) {
          queryParams += `p_start_date=${discountListRequest.p_start_date}&`;
        }
        if (
          discountListRequest.p_end_date !== undefined &&
          discountListRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${discountListRequest.p_end_date}&`;
        }
        if (
          discountListRequest.p_discount_type_on !== undefined &&
          discountListRequest.p_discount_type_on !== null
        ) {
          queryParams += `p_discount_type_on=${discountListRequest.p_discount_type_on}&`;
        }
        if (
          discountListRequest.p_spacific_item !== undefined &&
          discountListRequest.p_spacific_item !== null
        ) {
          queryParams += `p_spacific_item=${discountListRequest.p_spacific_item}&`;
        }
        if (
          discountListRequest.p_discount_apply_on !== undefined &&
          discountListRequest.p_discount_apply_on !== null
        ) {
          queryParams += `p_discount_apply_on=${discountListRequest.p_discount_apply_on}&`;
        }
        if (
          discountListRequest.p_IsActive !== undefined &&
          discountListRequest.p_IsActive !== null
        ) {
          queryParams += `p_IsActive=${discountListRequest.p_IsActive}&`;
        }
        if (
          discountListRequest.p_isDeleted !== undefined &&
          discountListRequest.p_isDeleted !== null
        ) {
          queryParams += `p_isDeleted=${discountListRequest.p_isDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.DISCOUNT +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalDiscountData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getDiscountData(response));
          } else {
            dispatch(getDiscountData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get discount brand list
   */
  const getAllDiscountBrandService = (
    token: string,
    dispatch: Dispatch
  ): Promise<DiscountBrandResponse> => {
    return new Promise<DiscountBrandResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.DISCOUNT_BRAND,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(getDiscountBrandData(response));
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating discount
   */
  const addUpdateDiscountService = (
    addUpdateDiscountRequest: addUpdateDiscountRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.DISCOUNT,
            token,
            addUpdateDiscountRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.DISCOUNT,
            token,
            addUpdateDiscountRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDiscountData());
          dispatch(clearTotalDiscountData());
          if (type === "add") {
            dispatch(clearDiscountPageData());
            toast.success(
              response?.message
                ? response.message
                : "Discount added successfully!"
            );
            navigate("/discount");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Discount undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getDiscountPageData(pageNumber + 1));
                } else {
                  dispatch(clearDiscountPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Discount updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/discount");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Discount status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getDiscountPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual discount
   */
  const getDiscountById = (
    id: string,
    token: string,
    dispatch: Dispatch,
    boolValue?: any
  ): Promise<individualDiscountResponse> => {
    return new Promise<individualDiscountResponse>(async (resolve, reject) => {
      try {
        if (!boolValue) {
          dispatch(setLoader());
        }
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.DISCOUNT + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for deleting discount
   */
  const deleteDiscountService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.DISCOUNT + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDiscountData());
          dispatch(clearTotalDiscountData());
          toast.success(
            response?.message
              ? response.message
              : "Discount deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getDiscountPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllDiscountService,
    getAllDiscountBrandService,
    addUpdateDiscountService,
    getDiscountById,
    deleteDiscountService,
  };
}

export const CalculateCurrentTime = () => {
  const currentDate = new Date();
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");

  const currentTime = hours + ":" + minutes + ":" + seconds;
  return currentTime;
};

export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
};

export const formatUTCDate = (date) => {
  if (date !== null && date !== undefined) {
    const dateObject = new Date(date);
    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const year = dateObject.getUTCFullYear();
    return `${month}-${day}-${year}`;
  } else return null;
};

export const orderDate = () => {
  const date = new Date();

  // Formatting the date and time parts
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Combining them into the desired format
  return `${year}-${month}-${day}`;
}

export const orderDateNew = () => {
  const date = new Date();

  // Formatting the date and time parts
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Combining them into the desired format
  return `${day}-${month}-${year}`;
};
export const sixMonthsBefore = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 6);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};
export const oneMonthsBefore = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};
export const getCurrentYear = () => {
  const date = new Date();
  const year = date.getFullYear();
  return year;
};

export const getCurrentMonth = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  return month;
};

export const formatDateToUTC = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateToYYYYMMDD = (dateString) => {
  const [month, day, year] = dateString.split('-');
  return `${year}-${day}-${month}`;
};
export const formatDateToDDMMYYYY = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export const parseDateFromDDMMYYYY = (dateString) => {
  if (!dateString) return null; // Handle null or undefined
  const [day, month, year] = dateString.split('/'); // Assuming dateString is "dd/MM/yyyy"
  return new Date(year, month - 1, day); // Create a Date object
};

import React, { useEffect, useRef, useState } from "react";
import NewOrderCart from "../../components/NewOrderCart/NewOrderCart";
import Button from "../../components/Button/Button";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { GiSpeaker, GiSpeakerOff, GiWallet } from "react-icons/gi";
import "../NewOrderPage/NewOrderPage.scss";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UtilsData, {
  getRouteNameFromSidebar,
  orderStatusTableArray,
  orderTypeArray,
  ValidationMessage,
} from "../../Utils/Utils";
import {
  clearOrderFilterData,
  getOrderFilterData,
  setClearLocalOrderFilter,
} from "../../redux/Actions/orderAction/OrderFilterAction";
import UserService from "../AdminUsers/User-service/UserService";
import OrderServices from "../Order/OrderService/OrderService";
import { useTranslation } from "react-i18next";
import { orderDate } from "../../services/Regular-services/DateService";
import {
  clearOrderPageData,
  getOrderPageData,
} from "../../redux/Actions/orderAction/OrderPAgeAction";
import { clearOrderData } from "../../redux/Actions/orderAction/OrderAction";
import { setNotificationSound } from "../../redux/Actions/NotificationAction/NotificationSound";
import { toast } from "react-toastify";
import generateInvoiceHtml from "../../components/generateInvoiceHtml/generateInvoiceHtml";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import OrderDetailContainer from "../../components/NewOrderCart/OrderDetailContainer/OrderDetailContainer";
import DatePicker from "react-datepicker";
import Filter from "../../components/Filter/Filter";
import CustomPagination from "../../components/Pagination/Pagination";
import Toggle from "../../components/Toggle/Toggle";
import { AlertMessage } from "../../components/Alertmessage/Alertmessage";
import { OrderRequestBodyOnToggle } from "../Order/OrderRequestBodyOnToggle/OrderRequestBodyOnToggle";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { MdOutlineCancel } from "react-icons/md";
import Input from "../../components/input/Input";
// import { Offcanvas } from "react-bootstrap";

function NewOrderPage() {
  const { addUpdateUserService, getUserById } = UserService();
  const { getAllOrderService, uploadOrderPDFService, placeOrderService } =
    OrderServices();
  const { handleToggleChange } = AlertMessage();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const { t } = useTranslation();
  const localisation = useSelector((state) => state.localization?.items);

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const navigate = useNavigate();
  const authuserData = useSelector((state) => state.auth?.user);
  const userAuth = useSelector((state) => state.auth.user);
  const order = useSelector((state) => state.order);
  const orderFilter = useSelector((state) => state.OrderFilter);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [currentPageNumber, setCurrentPage] = useState(1);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const soundData = useSelector((state) => state.notificationSound?.sound);
  const [beepOption, setBeepOption] = useState(0);
  const [filteredOrderDate, setFilteredOrderDate] = useState(
    orderFilter?.orderDate || format(new Date(), "yyyy-MM-dd")
  );
  const filterDateData = orderDate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [orderActionData, setOrderActionData] = useState([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  const [zomatoSwiggyModal, setZomatoSwiggyModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    zomatoSwiggyPrice: "",
  });

  const [filteredTableData, setFilteredTableData] = useState([]);

  const validationSchema = Yup.object({
    zomatoSwiggyPrice: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  });

  const clearLocalOrderFilter = useSelector(
    (state) => state.clearLocalOrderFilter?.type
  );

  useEffect(() => {
    if (clearLocalOrderFilter) {
      setOrderStatusFilter([]);
      dispatch(setClearLocalOrderFilter(false));
    }
    // eslint-disable-next-line
  }, [clearLocalOrderFilter]);

  useEffect(() => {
    setFilteredOrderDate(
      orderFilter?.orderDate || format(new Date(), "yyyy-MM-dd")
    );
  }, [orderFilter?.orderDate]);

  useEffect(() => {
    if (authuserData && authuserData?.token) {
      setBeepOption(authuserData.data?.beep_option === "1" ? 1 : 0);
    }
  }, [authuserData]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setCurrentPage(orderPage);
  }, [orderPage]);

  function calculateProductTax(product, order) {
    if (Number(order.total_tax_amount) > 0) {
      const price =
        Number(product.price) * Number(product.quantity) -
        Number(product.product_discount_amount);

      const cgstAmount = price * (Number(product.cgst) / 100);
      const sgstAmount = price * (Number(product.sgst) / 100);

      // Total tax amount
      const totalTax = cgstAmount + sgstAmount;
      return totalTax;
    } else {
      return 0;
    }
  }

  const handleClickStatusFilter = (filter) => {
    const filteredStatus =
      orderStatusFilter && orderStatusFilter.length > 0
        ? orderStatusFilter.find((item) => item === filter)
        : false;
    if (filteredStatus) {
      const filterData = orderStatusFilter.filter((item) => item !== filter);
      setOrderStatusFilter(filterData);
      return;
    }
    const newFilteredData = [...orderStatusFilter, filter];
    setOrderStatusFilter(newFilteredData);
    return;
  };

  function totalPrice(product, order) {
    const total =
      Number(product.price) * Number(product.quantity) -
      Number(product.product_discount_amount) +
      calculateProductTax(product, order, "tax");
    return total.toFixed(2);
  }

  useEffect(() => {
    if (orderStatusFilter?.length) {
      if (tableData?.length) {
        const filteredOrders = tableData.filter((order) =>
          orderStatusFilter.includes(order.orderStatus)
        );
        setFilteredTableData(filteredOrders);
      } else {
        setFilteredTableData([]);
      }
    } else {
      setFilteredTableData(tableData?.length ? tableData : []);
    }
  }, [tableData, orderStatusFilter]);

  useEffect(() => {
    const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
    if (
      ((order &&
        order.items &&
        order.items.length < 1 &&
        order.api === false) ||
        !order) &&
      orderFilter &&
      ((orderFilter.orderDate !== null &&
        !["", currentDateFormatted].includes(orderFilter.orderDate)) ||
        (orderFilter.status && orderFilter.status === "") ||
        (orderFilter.orderType && orderFilter.orderType === ""))
    ) {
      setTableData([]);
      fetchOrder();
    } else {
      const orderTableData = order.items
        .map((item) => ({
          ...item,
          id: item.orderId,
          type: item.type,
          tableType: orderTypeArray.find(
            (orderType) => orderType.value === item.type
          )?.label,
          totalItem: item?.productData
            ? JSON.parse(item.productData).reduce(
                (sum, product) => sum + Number(product.quantity),
                0
              )
            : 0,
          orderSubTotal: item.productData
            ? JSON.parse(item.productData).reduce(
                (sum, product) =>
                  sum + Number(product.price) * Number(product.quantity),
                0
              )
            : 0,
          total_delivery_charge: item?.delivery_charge
            ? Number(item.delivery_charge)
            : 0,
          discountAmount: item.total_discount_amount
            ? item.total_discount_amount
            : "0",
          total_tax_amount: item?.total_tax_amount
            ? Number(item.total_tax_amount)
            : 0,
          total_amount_with_tax: item?.order_amount
            ? Number(item.order_amount)
            : 0,
          orderNumber: item.order_number,
          productData: item.productData
            ? JSON.parse(item.productData).map((product) => ({
                ...product,
                productId: product?.productId ? Number(product.productId) : 0,
                productName: product?.productName
                  ? product?.productName.replace(/^'(.*)'$/, "$1")
                  : "",
                addOns:
                  product?.attribute_data &&
                  !["", "null", "NULL"].includes(product.attribute_data)
                    ? getFormattedSize(JSON.parse(product.attribute_data))
                    : "",
                product_size: product.size,
                product_attribute: product?.attribute_data
                  ? JSON.parse(product.attribute_data)
                  : null,
                quantity: product.quantity,
                discountProductAmt: Number(product.product_discount_amount),
                taxProductAmt: calculateProductTax(product, item),
                price: product.price ? Number(product.price) : 0,
                totalTablePrice:
                  product.quantity && product.price
                    ? totalPrice(product, item)
                    : 0,
                food_type_two:
                  product?.food_type_2 &&
                  !["", "null", "NULL"].includes(product.food_type_2)
                    ? product.food_type_2 === "0"
                      ? "No Onion/Garlic"
                      : product.food_type_2 === "1"
                      ? "Jain"
                      : "Regular"
                    : "Regular",
                product_instruction:
                  product?.product_instruction &&
                  !["", "null", "NULL"].includes(product.product_instruction)
                    ? product.product_instruction
                    : null,
                map_table_id: product?.map_table_id ? product.map_table_id : 0,
                orderId: item.orderId,
                productStatus: product?.status ? product.status : "0",
              }))
            : [],
          zomatoSwiggyPrice: item.zomato_swiggy_other_amount
            ? `${item.zomato_swiggy_other_amount}`
            : null,
          orderStatus: item.status,
          user_id: item.user_id,
          firstName:
            item?.first_name &&
            !["Bespeakk", "bespeakk"].includes(item.first_name)
              ? item.first_name
              : "",
          lastName: item.last_name,
          customerName:
            item?.first_name &&
            !["Bespeakk", "bespeakk"].includes(item.first_name)
              ? item.first_name + " " + item.last_name
              : "N/A",
          phoneNumber:
            item?.phone_number && !["", 8155006366].includes(item.phone_number)
              ? item.phone_number
              : "N/A",
          createdAt: item.createdAt ? item.createdAt : new Date(),
          modifiedAt: item.modifiedAt ? item.modifiedAt : new Date(),
          tableName: item.spot_name,
        }))
        .filter(
          (order, index, self) =>
            index === self.findIndex((o) => o.id === order.id)
        );
      setTableData(orderTableData);
    }
    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    if (
      (orderFilter?.status && orderFilter.status?.length) ||
      (orderFilter?.orderType && orderFilter.orderType !== "")
    ) {
      // handleShow();
    }
  }, [orderFilter]);

  /**
   * Fetch order data
   */
  const fetchOrder = ({ filter }) => {
    const dateFilter =
      filter?.orderDate && filter.orderDate !== ""
        ? filter.orderDate
        : orderFilter?.orderDate && orderFilter.orderDate !== ""
        ? orderFilter.orderDate
        : "";

    const statusFilter =
      filter?.status && filter.status?.length
        ? filter.status
        : orderFilter?.status && orderFilter.status?.length
        ? orderFilter.status.join(",")
        : "";

    const orderTypeFilter =
      filter?.orderType && filter.orderType !== ""
        ? filter.orderType
        : orderFilter?.orderType && orderFilter.orderType !== ""
        ? orderFilter.orderType
        : "";

    if (orderPage > 1) {
      const requestBody = {
        p_skip: (orderPage - 2) * 500000,
        p_take: 500000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (dateFilter && dateFilter !== "") {
        requestBody.p_start_date = dateFilter;
      }
      if (dateFilter && dateFilter !== "") {
        requestBody.p_end_date = dateFilter;
      }
      if (statusFilter && statusFilter !== "") {
        requestBody.p_status = statusFilter;
      }
      if (orderTypeFilter && orderTypeFilter !== "") {
        requestBody.p_type = orderTypeFilter;
      }
      setCurrentPage(orderPage - 1); // Update the current page state
      dispatch(getOrderPageData(orderPage - 1));
      getAllOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (orderPage - 1) * 500000,
        p_take: 500000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (dateFilter && dateFilter !== "") {
        requestBody.p_start_date = dateFilter;
      }
      if (dateFilter && dateFilter !== "") {
        requestBody.p_end_date = dateFilter;
      }
      if (statusFilter && statusFilter !== "") {
        requestBody.p_status = statusFilter;
      }
      if (orderTypeFilter && orderTypeFilter !== "") {
        requestBody.p_type = orderTypeFilter;
      }
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    const amt = tableData.reduce((accumulator, current) => {
      // Check if the orderStatus is "3"
      if (current.orderStatus === "3") {
        return accumulator + current.total_amount_with_tax;
      }
      return accumulator; // Return the accumulator unchanged if the condition is not met
    }, 0);
    const totalAmountWithRupeeSymbol = `${
      userAuth?.data?.symbol || "₹"
    }${amt.toFixed(2)}`;
    setTotalAmt(totalAmountWithRupeeSymbol);
    // eslint-disable-next-line
  }, [tableData]);

  const onPageChange = (page) => {
    if (page !== currentPageNumber) {
      const requestBody = {
        p_skip: (page - 1) * 500000,
        p_take: 500000,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_start_date = orderFilter.orderDate;
      }
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_end_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }

      setCurrentPage(page); // Update the current page state
      dispatch(getOrderPageData(page));
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  const clearFilter = () => {
    dispatch(clearOrderPageData());
    dispatch(clearOrderFilterData());
    dispatch(clearOrderData());
    handleClose();
  };

  const toggleSound = () => {
    dispatch(setNotificationSound(!soundData));
  };

  const getFormattedSize = (data) => {
    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
  };

  const fetchUser = async () => {
    try {
      const userData = await getUserById(
        authuserData.data.userId,
        tokenData,
        dispatch
      );
      // setBeepOption(userData.data.beep_option === '1' ? 1 : 0)
      return userData.data;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };
  const handleBeepToggleChange = async (e) => {
    setBeepOption(e.target.checked ? 1 : 0);
    const data = await fetchUser();
    const requestBody = {
      p_user_id: data.userId,
      p_username: data.username,
      p_first_name: data.firstName,
      p_last_name: data.lastName,
      p_email: data.email,
      p_role_permissions_id: data.rolePermissionsId,
      p_is_active: data.isActive === "1" ? "1" : "0",
      p_wp_sms_allow: data.wp_sms_allow === "1" ? "1" : "0",
      p_created_by: data?.createdBy
        ? data?.createdBy
        : authuserData.data.userId,
      p_phone_number: data.phoneNumber,
      p_brand_ids:
        data?.brand_ids && JSON.parse(data.brand_ids).length > 0
          ? JSON.parse(data.brand_ids).map((item) => ({
              id: Number(item?.brand_id),
            }))
          : null,
      p_branch_ids:
        data?.branch_ids && JSON.parse(data.branch_ids).length > 0
          ? JSON.parse(data.branch_ids).map((item) => ({
              id: Number(item?.branch_id),
            }))
          : null,
      p_IsDeleted: data.isDeleted,
      p_modified_by: authuserData.data.userId,
      p_beep_option: e.target.checked === true ? "1" : "0",
    };

    dispatch(
      addUpdateUserService(
        requestBody,
        tokenData,
        "update",
        false,
        false,
        authuserData
      )
    );
  };

  const generatePDFAndUpload = async (orderData) => {
    const { companyName } = authuserData.data;
    const invoiceHtml1 = generateInvoiceHtml(orderData, authuserData, "wp");

    // Create a temporary div to render the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.height = "297mm";
    tempDiv.style.overflow = "hidden";
    tempDiv.innerHTML = invoiceHtml1;
    document.body.appendChild(tempDiv);

    try {
      // Convert HTML to Canvas
      const canvas = await html2canvas(tempDiv, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight); // Use JPEG

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");
      const file = new File(
        [pdfBlob],
        `invoice-${orderData?.orderNumber}.pdf`,
        {
          type: "application/pdf",
        }
      );

      // Prepare upload data
      const uploadData = {
        pdf: file,
        p_order_user: orderData.firstName + " " + orderData.lastName,
        p_order_phone_number: orderData.phoneNumber,
        p_total_amt: orderData.totalOrderWithTax,
        p_company_name: companyName,
      };

      dispatch(uploadOrderPDFService(uploadData, tokenData, navigate));
    } catch (error) {
      console.error("Error generating PDF or uploading:", error);
    } finally {
      // Clean up by removing the temporary div
      document.body.removeChild(tempDiv);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleDateChange = (date) => {
    const filterData = {
      orderDate: format(new Date(date), "yyyy-MM-dd"),
      status: orderFilter?.status,
      orderType: orderFilter?.orderType,
    };
    dispatch(getOrderFilterData(filterData));
    fetchOrder({ filter: filterData });
    setSelectedOrder(null);
  };

  const getOrderDetail = (item) => {
    // const newSelectedOrder = selectedOrder === item ? null : item;
    setSelectedOrder(item);
  };

  useEffect(() => {
    if (selectedOrder) {
      if (filteredTableData?.length) {
        const newSelected = filteredTableData.find(
          (item) => item.orderId === selectedOrder.orderId
        );
        if (newSelected) {
          if (newSelected !== selectedOrder) {
            setSelectedOrder(newSelected);
          }
        } else {
          setSelectedOrder(null);
        }
      }
    } else {
      const newSelected = filteredTableData[0]
      setSelectedOrder(newSelected);
    }
    // eslint-disable-next-line
  }, [filteredTableData]);

  const onChange = (value, item) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: item,
      status: value,
    });

    let orderStatusData = orderStatusTableArray["order"].find(
      (item) => item.value === value
    ).className;

    if (["1", "4"].includes(value)) {
      handleToggleChange(
        dispatch,
        "order",
        orderStatusData,
        tokenData,
        userAuth.data.userId,
        requestBody,
        tableData.length,
        orderPage
      );
    } else {
      dispatch(
        placeOrderService(requestBody, tokenData, "update", false, orderPage)
      );
    }
  };

  const changeOrderPaymentStatus = (value, item) => {
    if (value === "5" || value === "6") {
      setZomatoSwiggyModal(!zomatoSwiggyModal);
      setInitialValues({
        zomatoSwiggyPrice: "",
        payment: value,
        item: item,
      });
    } else {
      const requestBody = OrderRequestBodyOnToggle({
        item: item,
        payment: value,
      });
      dispatch(
        placeOrderService(
          requestBody,
          tokenData,
          "update",
          false,
          orderPage,
          true
        )
      );
    }
  };

  const handleSubmit = (values) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: values.item,
      payment: values.payment,
      zomatoSwiggyPrice: values.zomatoSwiggyPrice,
    });

    dispatch(
      placeOrderService(
        requestBody,
        tokenData,
        "update",
        false,
        orderPage,
        true
      )
    );
  };

  return (
    <div className="body-content">
      <div className="table-body-content new-table-body-content">
        <div className="roles mar-new">
          <div className="order-number-show">
            <div>
              <div className="title">
                <h4>
                  {orderActionData && orderActionData.length > 0
                    ? getRouteNameFromSidebar(
                        orderActionData[0].name,
                        localisation
                      )
                    : t("Orders")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => navigate("/home")}
                  className="breadcrumb"
                >
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="breadcrumb">
                  {orderActionData && orderActionData.length > 0
                    ? getRouteNameFromSidebar(
                        orderActionData[0].name,
                        localisation
                      )
                    : t("Orders")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <div className="btn-groups">
          <div className="main-box-color">
          {order &&
            order?.items &&
            order.items.length > 0 &&
            tableData.length > 0 && (
              <div className="heading-boxes">
                <div className="boxes-container">
                  <div
                    className={`box Pending new-box-3 ${
                      orderStatusFilter &&
                      orderStatusFilter.length > 0 &&
                      orderStatusFilter.filter((item) => item === "0").length >
                        0
                        ? "box-active"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickStatusFilter("0")}
                  >
                    <h6>{t("lable_pending")}</h6>
                    <span>
                      {" "}
                      {
                        tableData.filter((item) => item.orderStatus === "0")
                          .length
                      }
                    </span>
                  </div>
                  <div
                    className={`box Confirmed new-box-3 ${
                      orderStatusFilter &&
                      orderStatusFilter.length > 0 &&
                      orderStatusFilter.filter((item) => item === "1").length >
                        0
                        ? "box-active"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickStatusFilter("1")}
                  >
                    <h6>{t("lable_confirmed")}</h6>
                    <span>
                      {
                        tableData.filter((item) => item.orderStatus === "1")
                          .length
                      }
                    </span>
                  </div>
                  <div
                    className={`box In-progress new-box-3 ${
                      orderStatusFilter &&
                      orderStatusFilter.length > 0 &&
                      orderStatusFilter.filter((item) => item === "2").length >
                        0
                        ? "box-active"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickStatusFilter("2")}
                  >
                    <h6 style={{ textAlign: "left" }}>
                      {t("lable_in_progress")}
                    </h6>
                    <span>
                      {
                        tableData.filter((item) => item.orderStatus === "2")
                          .length
                      }
                    </span>
                  </div>
                  <div
                    className={`box Completed  new-box-3 ${
                      orderStatusFilter &&
                      orderStatusFilter.length > 0 &&
                      orderStatusFilter.filter((item) => item === "3").length >
                        0
                        ? "box-active"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickStatusFilter("3")}
                  >
                    <h6>{t("lable_completed")}</h6>
                    <span>
                      {
                        tableData.filter((item) => item.orderStatus === "3")
                          .length
                      }
                    </span>
                  </div>
                  <div
                    className={`box Cancelled new-box-3 ${
                      orderStatusFilter &&
                      orderStatusFilter.length > 0 &&
                      orderStatusFilter.filter((item) => item === "4").length >
                        0
                        ? "box-active"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickStatusFilter("4")}
                  >
                    <h6>{t("lable_cancelled")}</h6>
                    <span>
                      {
                        tableData.filter((item) => item.orderStatus === "4")
                          .length
                      }
                    </span>
                  </div>
                </div>
              </div>
            )}
          {/* <div className="box Amount">Amount : {totalAmt}</div> */}
        </div>
            {((orderFilter?.orderDate &&
              !["", filterDateData].includes(orderFilter.orderDate)) ||
              (orderFilter?.status && orderFilter.status !== "") ||
              (orderFilter?.orderType && orderFilter.orderType !== "")) && (
              <Dropdown className="new-header-dropdown action-btn">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  data-bs-toggle="tooltip"
                  title="Action"
                >
                  <GrMoreVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={clearFilter}>
                    {t("clear_filters")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div
              className="box Amount amt-btn btn-fillter-main beep-option new-order-btn-2"
              id="dropdown-beep"
              data-bs-toggle="tooltip"
              title={t("orderPage.Beep")}
            >
              <h6>{t("orderPage.Beep")}</h6>
              <Toggle
                className="beep-class-dynamic"
                onChange={handleBeepToggleChange}
                checked={beepOption === 1}
              />
            </div>
            <div
              className="box Amount amt-btn btn-fillter-main new-order-btn-2"
              id="dropdown-beep-1"
              data-bs-toggle="tooltip"
              title={t("today_total_revenue")}
            >
              <GiWallet />
              &nbsp;{totalAmt}
            </div>
            <div
              className="datepicker-container amt-btn btn-fillter-main"
              id="dropdown-beep-2"
              data-bs-toggle="tooltip"
              title={t("filter_by_order_date")}
            >
              <DatePicker
                selected={filteredOrderDate}
                onChange={handleDateChange}
                placeholderText="Filter By Date"
                showIcon
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker"
              />
            </div>
            <Button
              onClick={handleShow}
              data-bs-toggle="tooltip"
              title="Filter"
              className="btn btn-fillter-main all-btn"
              name={
                <div className="filter">
                  <FaFilter className="filter-icon" />
                </div>
              }
            ></Button>

            <Button
              data-bs-toggle="tooltip"
              title="Toggle Sound"
              onClick={toggleSound}
              className="btn btn-fillter-main all-btn"
              name={
                <div className="Plus">
                  {!soundData ? (
                    <GiSpeakerOff style={{ fontSize: 25 }} />
                  ) : (
                    <GiSpeaker style={{ fontSize: 25 }} />
                  )}
                </div>
              }
            ></Button>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Filter page="order" handleClose={handleClose}></Filter>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
       
        {order?.items?.length &&
        filteredTableData &&
        filteredTableData?.length ? (
          <div className="row">
            <div className={selectedOrder ? "col-md-6" : "col-lg-12 col-md-6"}>
              <div className="product-new-section">
                <div className="row">
                  {filteredTableData.map((item, index) => (
                    <div
                      className={
                        selectedOrder
                          ? "col-md-12 col-12 mb-2"
                          : "col-xl-4 col-lg-6 col-12 mb-2"
                      }
                      key={index}
                    >
                      <NewOrderCart
                        orderData={item}
                        generatePDFAndUpload={generatePDFAndUpload}
                        getOrderDetail={getOrderDetail}
                        selectedOrder={selectedOrder}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {selectedOrder ? (
              <OrderDetailContainer
                selectedOrder={selectedOrder}
                onChange={onChange}
                changeOrderPaymentStatus={changeOrderPaymentStatus}
                page="new-order"
              />
            ) : (
              <></>
            )}
            {order.totalRecords > 0 &&
              order.totalRecords > order.items.length &&
              order.totalRecords > 500000 && (
                <CustomPagination
                  totalRecords={order.totalRecords}
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                />
              )}
          </div>
        ) : (
          <div className="input-search">
            <div className="no-record">
              <h3 data-bs-toggle="tooltip" title="No records!">
                {t("no_records")}
              </h3>
            </div>
          </div>
        )}
      </div>

      {zomatoSwiggyModal && (
        <div className="modal-overlay">
          <div className="modal-content-order modal-">
            <div className="modal-header">
              <h4>Sales Details</h4>
              <button
                className="modal-close"
                onClick={() => setZomatoSwiggyModal(!zomatoSwiggyModal)}
              >
                <MdOutlineCancel />
              </button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {({ values }) => (
                  <Form>
                    <div className="add-roles-form row">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                Enter Zomato/Swiggy Price
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Input
                              type="text"
                              name="zomatoSwiggyPrice"
                              placeholder="Enter Price"
                              className="add-roles"
                              maxLength={10}
                              Regex={/[^0-9\s]/g}
                              value={values.zomatoSwiggyPrice}
                              hidelength="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="paragraph-editor">
                          <div className="btn-submit order-submit-btn">
                            <Button
                              type="submit"
                              name="Place Order"
                              className="btn login-btn  place-order-btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewOrderPage;

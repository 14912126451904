import React from "react";
import Form from "react-bootstrap/Form";
import "./Toggle.scss";

export default function Toggle(props) {
  let {
    name,
    field,
    setFieldValue,
    value,
    onChangeData,
    dynamicClass,
    ...rest
  } = props;

  //On change toggle
  const onChange = (event) => {
    const { checked } = event.target;
    if (field && setFieldValue) {
      setFieldValue(field.name, checked);
    }
  };
  return (
    <>
      <Form.Check
        name={field ? field.name : "toggle"}
        type="switch"
        id="custom-switch"
        className="custom-switch"
        checked={field ? field.value : value}
        onChange={onChangeData ? onChangeData : onChange}
        {...rest}
      />
    </>
  );
}
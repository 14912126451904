import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../../components/Toggle/Toggle";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import "./Adduser.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../User-service/UserService.tsx";
import { capitalizeAfterSpace } from "../../../services/Regular-services/NameService.js";
import { toast } from "react-toastify";
import BrandService from "../../BrandList/BrandService/BrandService";
import BranchService from "../../Branches/BranchService/BranchService";
import { modifyString } from "../../../services/Regular-services/StringService";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import RoleServices from "../../Roles/Role-Service/roleService";

export default function Adduser() {
  const { getAllBranchService } = BranchService();
  const { addUpdateUserService, getUserById, getUserTypeList } = UserService();
  const { getAllBrandService } = BrandService();
  const { getRolesService } = RoleServices();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    userRoles: "",
    userType: "",
    brand: [],
    branch: [],
    status: 1,
    smsAllow: 0,
  });

  const { handlesaveClick, handleCancleClick } = AlertMessage();

  const location = useLocation();
  const { Data } = location.state || {};

  const [isDeleted, setIsDeleted] = useState("0");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = UtilsData();
  const adminPageNumber = useSelector((state) => state.adminPage?.items);
  const userData = useSelector((state) => state.auth);
  const [roleListOption, setRoleList] = useState([]);
  const rolesData = useSelector((state) => state.totalRoles);
  const userType = useSelector((state) => state.userType.items);
  const [userTypeOption, setUserTypeOption] = useState([]);
  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandOptions, setBrandOptions] = useState([]);
  const totalBranch = useSelector((state) => state.totalBranch);
  const [branchOptions, setBranchOptions] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);
  const sideBarData = useSelector((state) => state.sideBar?.items);
  const [adminActionData, setAdminActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "adminusers"
      );
      setAdminActionData(actionData);
    }
  }, [sideBarData]);

  /**
   * Fetching role data
   */
  const fetchRole = () => {
    const roleRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getRolesService(roleRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (rolesData && rolesData.items && rolesData.items.length === 0) {
      fetchRole();
    } else {
      if (rolesData.items[0]?.data && rolesData.items[0].data.length > 0) {
        const rolesOptions = rolesData.items[0].data.map((item) => ({
          value: item.id,
          label: item.title,
        }));
        setRoleList(rolesOptions);
      }
    }

    if (userType && userType.length === 0) {
      getUserTypeList(dispatch);
    } else {
      const userOption = userType.map((item) => ({
        value: item.id,
        label: item.user_type,
      }));
      setUserTypeOption(userOption);
    }
    // eslint-disable-next-line
  }, [rolesData]);

  /**
   * Fetching brand data from API
   */
  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
      p_is_active: "1",
    };
    getAllBrandService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBrand && totalBrand.items && totalBrand.items.length === 0) {
      fetchBrand();
    } else {
      if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
        const newBrand = totalBrand.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.brandId,
        }));
        setBrandOptions(newBrand);
      }
    }
    // eslint-disable-next-line
  }, [totalBrand]);

  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
      p_is_active: "1",
    };
    getAllBranchService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBranch && totalBranch.items && totalBranch.items.length === 0) {
      fetchBranch();
    } else {
      if (totalBranch.items[0]?.data && totalBranch.items[0].data.length > 0) {
        const newBranch = totalBranch.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.branchId,
        }));
        setBranchOptions(newBranch);
      }
    }
    // eslint-disable-next-line
  }, [totalBranch]);

  useEffect(() => {
    if (userTypeOption && userTypeOption.length > 0 && !Data) {
      const userValue = userTypeOption.find(
        (item) => item.value === "2" || item.value === 2
      );
      if (userValue) {
        setInitialValues({
          ...initialValues,
          userType: userValue.value,
        });
      }
    }
    // eslint-disable-next-line
  }, [userTypeOption, Data]);

  //Validation schema
  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      firstName: Yup.string().required(
        t(ValidationMessage.FIRST_NAME_REQUIRED)
      ),
      lastName: Yup.string().required(t(ValidationMessage.LAST_NAME_REQUIRED)),
      userRoles: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      userType: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      brand: Yup.array().min(1, t(ValidationMessage.MULTI_BRAND_SELECT)),
      branch: Yup.array().min(1, t(ValidationMessage.MULTI_BRANCH_SELECT)),
      phone: Yup.string()
        .required(t(ValidationMessage.MOBILE_REQUIRED))
        .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
    })
  );
  /**
   * Fetching user detail data
   */
  const fetchUser = async () => {
    try {
      // Call the getRoleById function
      const user = await getUserById(Data.data.userId, token, dispatch);
      // Execute the returned async function to fetch the data
      if (user && user.status === "success") {
        // setAdminUserData(user.data);
        setInitialValues({
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          userName: user.data.username,
          email: user.data.email,
          phone: user.data?.phoneNumber ? user.data.phoneNumber : "",
          userRoles: user.data.rolePermissionsId,
          brand: user.data?.brand_ids
            ? JSON.parse(user.data.brand_ids).map((item) => item.brand_id)
            : [],
          branch: user.data?.branch_ids
            ? JSON.parse(user.data.branch_ids).map((item) => item.branch_id)
            : [],
          status:
            user.data.isActive === true ||
            user.data.isActive === 1 ||
            user.data.isActive === "1"
              ? 1
              : 0,
          smsAllow:
            user.data.wp_sms_allow === true ||
            user.data.wp_sms_allow === "1" ||
            user.data.wp_sms_allow === 1
              ? 1
              : 0,
          // beep changes by sakshi
          beep_option:
            user.data.beep_option === true ||
            user.data.beep_option === "1" ||
            user.data.beep_option === 1
              ? "1"
              : "0",
        });
      }
      // setCreatedBy(user.data.createdBy);
      setIsDeleted(user.data.isDeleted);
      return user;
    } catch (error) {
      toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchUser();
      setValidationSchema(
        Yup.object({
          firstName: Yup.string().required(
            t(ValidationMessage.FIRST_NAME_REQUIRED)
          ),
          lastName: Yup.string().required(
            t(ValidationMessage.LAST_NAME_REQUIRED)
          ),
          userRoles: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          brand: Yup.array().min(1, t(ValidationMessage.MULTI_BRAND_SELECT)),
          branch: Yup.array().min(1, t(ValidationMessage.MULTI_BRANCH_SELECT)),
          phone: Yup.string()
            .required(t(ValidationMessage.MOBILE_REQUIRED))
            .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
        })
      );
    }
    // eslint-disable-next-line
  }, [Data]);

  const navigate = useNavigate();

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = async (values) => {
    let firstNameData = capitalizeAfterSpace(values.firstName);
    let lastNameData = capitalizeAfterSpace(values.lastName);

    const requestBody = {
      p_user_id: Data ? Data.data?.userId : null,
      p_username: values.userName,
      p_first_name: firstNameData,
      p_last_name: lastNameData,
      p_email: values.email,
      p_role_permissions_id: values.userRoles,
      p_is_active:
        values.status === true || values.status === 1 || values.status === "1"
          ? "1"
          : "0",
      p_wp_sms_allow:
        values.smsAllow === true || values.smsAllow === "1" ? "1" : "0",
      p_created_by: userData.user.data.userId,
      p_type_id: values.userType,
      p_phone_number: values.phone,
      p_brand_ids:
        values.brand?.length > 0
          ? values.brand.map((item) => ({ id: Number(item) }))
          : null,
      p_branch_ids:
        values.branch?.length > 0
          ? values.branch.map((item) => ({ id: Number(item) }))
          : null,
      p_IsDeleted: isDeleted,
      p_beep_option: values?.beep_option ? values.beep_option : "0",
      ...(Data && Data.data && { p_modified_by: userData.user.data.userId }),
    };
    // return
    if (!Data) {
      dispatch(addUpdateUserService(requestBody, token, "add", navigate));
    } else {
      let pageToNavigate = adminPageNumber;

      // Adjust pageToNavigate based on Data.length, adminPageNumber, and status
      if (Data.length && adminPageNumber > 1) {
        if (
          values.status === "0" ||
          values.status === false ||
          values.status === 0
        ) {
          pageToNavigate =
            Data.length > 1 ? adminPageNumber : adminPageNumber - 1;
        }
      }
      handlesaveClick(
        dispatch,
        addUpdateUserService(
          requestBody,
          token,
          "update",
          navigate,
          pageToNavigate
        ),
        "update"
      );
    }
  };

  return (
    <>
      <div className="table-body-content">
        <div className="roles">
          <div>
            <div className="title">
              <h4>
                {Data && Data.isEdit === true
                  ? t("actions.edit")
                  : Data && Data.isEdit === false
                  ? t("actions.view")
                  : t("actions.add")}{" "}
                {adminActionData && adminActionData.length > 0
                  ? modifyString(
                      getRouteNameFromSidebar(
                        adminActionData[0].name,
                        localisation
                      )
                    )
                  : t("Staff")}
              </h4>
            </div>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate("/home")}>
                {t("Home")}
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/adminusers")}>
                {adminActionData && adminActionData.length > 0
                  ? getRouteNameFromSidebar(
                      adminActionData[0].name,
                      localisation
                    )
                  : t("Staff")}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {Data && Data.isEdit === true
                  ? t("actions.edit")
                  : Data && Data.isEdit === false
                  ? t("actions.view")
                  : t("actions.add")}{" "}
                {adminActionData && adminActionData.length > 0
                  ? modifyString(
                      getRouteNameFromSidebar(
                        adminActionData[0].name,
                        localisation
                      )
                    )
                  : t("Staff")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="add-roles-title">
          <h4>
            {Data && Data.isEdit === true
              ? t("actions.edit")
              : Data && Data.isEdit === false
              ? t("actions.view")
              : t("actions.add")}{" "}
            {adminActionData && adminActionData.length > 0
              ? modifyString(
                  getRouteNameFromSidebar(adminActionData[0].name, localisation)
                )
              : t("Staff")}
          </h4>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="add-roles-form row">
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        {t("first_name")}
                        <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder={t("placeholder_name")}
                      className="add-roles capital-class"
                      Regex={/[^a-zA-Z\s]/g}
                      maxLength={20}
                      value={values.firstName}
                      disabled={Data && Data.isEdit === false}
                      hidelength={"true"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        {t("last_name")}
                        <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder={t("placeholder_last_name")}
                      className="add-roles capital-class"
                      Regex={/[^a-zA-Z\s]/g}
                      maxLength={20}
                      value={values.lastName}
                      disabled={Data && Data.isEdit === false}
                      hidelength={"true"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        {t("addOrders.mobile_label")}
                        <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="phone"
                      placeholder={t("addOrders.mobile_placeholder")}
                      className="add-roles"
                      maxLength={10}
                      disabled={Data && Data.isEdit === false}
                      value={values.phone}
                      Regex={/[^0-9\s]/g}
                      hidelength={"true"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      {t("lable_designation")}
                      <span className="required-label"></span>
                    </h4>
                    <Field
                      className="basic-single"
                      name="userRoles"
                      options={roleListOption}
                      component={CustomSelect}
                      placeholder={t("placeholder_Select")}
                      isMulti={false}
                      value={values.userRoles}
                      disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      {t("lable_department")}
                      <span className="required-label"></span>
                    </h4>
                    <Field
                      className="basic-single"
                      name="brand"
                      options={brandOptions}
                      component={CustomSelect}
                      placeholder={t("placeholder_Select")}
                      isMulti={true}
                      value={values.brand}
                      disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      {t("Branch")}
                      <span className="required-label"></span>
                    </h4>
                    <Field
                      className="basic-single"
                      name="branch"
                      options={branchOptions}
                      component={CustomSelect}
                      placeholder={t("placeholder_Select")}
                      isMulti={true}
                      value={values.branch}
                      disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                {!Data && (
                  <div className="col-md-6">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">
                        {t("lable_user_type")}
                        <span className="required-label"></span>
                      </h4>
                      <Field
                        className="basic-single"
                        name="userType"
                        options={userTypeOption}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.userType}
                        // disabled={Data && Data.isEdit === false}
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
                {Data && Data.data && <div className="col-6"></div>}
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">{t("whatsApp_message")}</h4>
                  </div>
                  <Field
                    name="smsAllow"
                    setFieldValue={setFieldValue}
                    component={Toggle}
                    disabled={Data && Data.isEdit === false}
                  ></Field>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">{t("status")}</h4>
                  </div>
                  <Field
                    name="status"
                    setFieldValue={setFieldValue}
                    component={Toggle}
                    disabled={Data && Data.isEdit === false}
                  ></Field>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <div className="btn-submit mt-3">
                      {((Data && Data.isEdit === true) || !Data) && (
                        <>
                          <Button
                            type="submit"
                            name={t("button_save")}
                            className="btn login-btn"
                          />
                          <Button
                            type="button"
                            name={t("button_cancel")}
                            className="btn clear-btn login-btn"
                            onClick={() =>
                              handleCancleClick("/adminusers", navigate)
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* <Footer /> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
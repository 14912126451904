import {
  CLEAR_SUB_CATEGORY_DATA,
  CLEAR_TOTAL_SUB_CATEGORY_DATA,
  GET_SUB_CATEGORY_DATA,
  GET_TOTAL_SUB_CATEGORY_DATA,
} from "../../ReduxKeys";

const subCategoryInitialState = {
  items: [],
};

/**
 * Store sub-category list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SubCategoryReducer = (state = subCategoryInitialState, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORY_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SUB_CATEGORY_DATA:
      return subCategoryInitialState;
    default:
      return state;
  }
};

const totalSubCategoriesInitialState = {
  items: [],
};

/**
 * Store total sub-category list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalSubCategoryReducer = (
  state = totalSubCategoriesInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_SUB_CATEGORY_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_SUB_CATEGORY_DATA:
      return totalSubCategoriesInitialState;
    default:
      return state;
  }
};
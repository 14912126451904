import {
  CLEAR_NEW_VENDOR_FILTER,
  GET_NEW_VENDOR_FILTER,
} from "../../ReduxKeys";

/**
 * Setting new vendor filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getNewVendorFilterData = ({
  firstName,
  lastName,
  phNum,
  isActive,
  isDelete,
}) => ({
  type: GET_NEW_VENDOR_FILTER,
  payload: { firstName, lastName, phNum, isActive, isDelete },
});

/**
 * Clearing new vendor filtered data
 * @returns {*}
 */
export const clearNewVendorFilterData = () => ({
  type: CLEAR_NEW_VENDOR_FILTER,
});
import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Formik, Form } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Input from "../../components/input/Input";
import "../../pages/CompanyonBoaring/CompanyonBoaring.scss";

const initialValues = {
  upload1: null,
  Title: "",
  Url: "",
  category: "",
  description: "",
  status: true,
};

function CompanyonBoaring() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < 3) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleAddProduct = (product) => {
    setAvailableProducts((prev) =>
      prev.filter((item) => item.id !== product.id)
    );
    setSelectedProducts((prev) => [...prev, product]);
  };

  const handleRemoveProduct = (product) => {
    setSelectedProducts((prev) =>
      prev.filter((item) => item.id !== product.id)
    );
    setAvailableProducts((prev) => [...prev, product]);
  };
  const [availableProducts, setAvailableProducts] = useState([
    { id: 1, name: "Product 1" },
    { id: 2, name: "Product 2" },
    { id: 3, name: "Product 3" },
    { id: 4, name: "Product 4" },
    { id: 5, name: "Product 5" },
    { id: 6, name: "Product 6" },
    { id: 7, name: "Product 7" },
    { id: 8, name: "Product 8" },
    { id: 9, name: "Product 9" },
    { id: 10, name: "Product 10" },
    { id: 11, name: "Product 11" },
    { id: 12, name: "Product 12" },
    { id: 13, name: "Product 13" },
  ]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">{/* Sidebar Component */}</div>
        <div className="body-content">
          {/* Header Component */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Company Onboarding</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                  <Breadcrumb.Item active>Company Onboarding</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>

            {/* Stepper */}
            <Stepper activeStep={activeStep}>
              <Step label="Company Details" />
              <Step label="Branch Details" />
              <Step label="Department" />
              <Step label="Products List" />
            </Stepper>

            {/* Forms for Each Step */}
            <Formik initialValues={initialValues}>
              {({ values }) => (
                <Form>
                  <div className="add-roles-form">
                    {activeStep === 0 && (
                      <div className="row">
                        <h4 className="mb-4">Company Details</h4>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div className="row">
                        <h4 className="mb-4">Branch Details</h4>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 2 && (
                      <div className="row">
                        <h4 className="mb-4">Department</h4>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="step-content">
                            <Input
                              type="text"
                              name="Title"
                              label="Company Name"
                              placeholder="Enter Company Name"
                              value={values.Title}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 3 && (
                      <div className="row">
                        <h4 className="mb-4">Products List</h4>

                        {/* Available Products Section */}
                        <div className="col-6">
                          <h5 className="mb-3">
                            Available Products ({availableProducts.length})
                          </h5>
                          <div className="product-list">
                            {availableProducts.map((product, index) => (
                              <div key={index} className="product-item">
                                <span>{product.name}</span>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={() => handleAddProduct(product)}
                                >
                                  +
                                </button>
                              </div>
                            ))}
                            {availableProducts.length === 0 && (
                              <p className="text-muted">
                                No products available.
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Selected Products Section */}
                        <div className="col-6">
                          <h5 className="mb-3">
                            Selected Products ({selectedProducts.length})
                          </h5>
                          <div className="product-list">
                            {selectedProducts.map((product, index) => (
                              <div key={index} className="product-item">
                                <span>{product.name}</span>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleRemoveProduct(product)}
                                >
                                  -
                                </button>
                              </div>
                            ))}
                            {selectedProducts.length === 0 && (
                              <p className="text-muted">
                                No products selected.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Navigation Buttons */}
                  <div className="stepper-navigation">
                    <button
                      type="button"
                      className="btn btn-secondary btn-back"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-back"
                      onClick={handleNext}
                      disabled={activeStep === 3}
                    >
                      {activeStep === 3 ? "Finish" : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyonBoaring;

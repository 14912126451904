import {
  CLEAR_INVENTORY_ITEM_FILTER,
  GET_INVENTORY_ITEM_FILTER,
} from "../../ReduxKeys";

const inventoryItemFilterInitialState = {
  title: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store inventory item filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const inventoryItemFilterReducer = (
  state = inventoryItemFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_INVENTORY_ITEM_FILTER:
      return {
        ...state,
        title: action.payload.title,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_INVENTORY_ITEM_FILTER:
      return inventoryItemFilterInitialState;
    default:
      return state;
  }
};
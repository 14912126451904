import {
  CLEAR_SERVICE_DATA,
  CLEAR_TOTAL_SERVICE_DATA,
  GET_SERVICE_DATA,
  GET_TOTAL_SERVICE_DATA,
} from "../../ReduxKeys";

const ServiceInitialState = {
  items: [],
};

/**
 * Store service list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ServiceReducer = (state = ServiceInitialState, action) => {
  switch (action.type) {
    case GET_SERVICE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SERVICE_DATA:
      return ServiceInitialState;
    default:
      return state;
  }
};

const totalServiceInitialState = {
  items: [],
};

/**
 * Store total service list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalServiceReducer = (
  state = totalServiceInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_SERVICE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_SERVICE_DATA:
      return totalServiceInitialState;
    default:
      return state;
  }
};
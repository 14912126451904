import { CLEAR_LOADER, SET_LOADER } from "../ReduxKeys";

const loaderInitialState = {
  items: false,
};

/**
 * Reducer to set main loader during API call
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const LoaderReducer = (state = loaderInitialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        items: true,
      };
    case CLEAR_LOADER:
      return loaderInitialState;
    default:
      return state;
  }
};
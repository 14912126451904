import React, { useEffect, useRef, useState } from "react";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import { clearNewVendorFilterData } from "../../redux/Actions/NewVendorAction/NewVendorFilterAction";
import {
  clearNewVendorPageData,
  getNewVendorPageData,
} from "../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import ExcelData from "../../services/Regular-services/ExcelExport";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import { GrMoreVertical } from "react-icons/gr";
import { clearNewVendorData } from "../../redux/Actions/NewVendorAction/NewVendorAction";
import { useTranslation } from "react-i18next";
import NewVendorPersonService from "./NewVendorPersonService/NewVendorPersonService";

const NewVendorPerson = () => {
  const { getAllNewVendorPersonService } = NewVendorPersonService();
  const { t } = useTranslation();
  const newVendorData = useSelector((state) => state.newVendor);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const newVendorPage = useSelector((state) => state.newVendorPage?.items);
  const newVendorFilter = useSelector((state) => state.newVendorFilter);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [newVendorActionData, setNewVendorActionData] = useState([]);
  const localisation = useSelector((state) => state.localization?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setNewVendorActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    mobile: true,
    shopName: true,
  };

  const showToggle = {
    status: true,
  };

  const showActions = {
    showView:
      (newVendorActionData[0]?.view && newVendorActionData[0].view === "1") ||
      !newVendorActionData
        ? true
        : false,
    showEdit:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? true
        : false,
    showDelete:
      (newVendorActionData[0]?.delete &&
        newVendorActionData[0].delete === "1") ||
      !newVendorActionData
        ? true
        : false,
    showUndo:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? true
        : false,
    navigate:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? "/edit-vendor-person"
        : false,
  };

  const [columns, setColumns] = useState([
    {
      name: t("columns.name"),
      selector: (row) => row.name,
    },
    {
      name: t("columns.mobile_number"),
      selector: (row) => row.mobile,
    },
    {
      name: t("lable_shop_name"),
      selector: (row) => row.shopeName,
    },
    {
      name: t("columns.status"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      newVendorActionData[0]?.view &&
      newVendorActionData[0].view === "0" &&
      newVendorActionData[0]?.edit &&
      newVendorActionData[0].edit === "0" &&
      newVendorActionData[0]?.add &&
      newVendorActionData[0].add === "0" &&
      newVendorActionData[0]?.delete &&
      newVendorActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.name,
        },
        {
          name: t("columns.mobile_number"),
          selector: (row) => row.mobile,
        },
        {
          name: t("lable_shop_name"),
          selector: (row) => row.shopeName,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.name"),
          selector: (row) => row.name,
        },
        {
          name: t("columns.mobile_number"),
          selector: (row) => row.mobile,
        },
        {
          name: t("lable_shop_name"),
          selector: (row) => row.shopeName,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: t("columns.actions"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [newVendorActionData,localisation]);

  /**
   * Fetch new Vendor item data
   */
  const fetchNewVendors = () => {
    const requestBody = {
      p_skip:
        newVendorPage > 1 ? (newVendorPage - 2) * 10 : (newVendorPage - 1) * 10,
      p_take: 10,
    };
    if (
      newVendorFilter.firstName !== "" &&
      newVendorFilter.firstName !== null
    ) {
      requestBody.p_first_name = newVendorFilter.firstName;
    }
    if (newVendorFilter.isActive !== "" && newVendorFilter.isActive !== null) {
      requestBody.p_isActive = newVendorFilter.isActive;
    } else {
      requestBody.p_isActive = "1";
    }
    if (newVendorFilter.isDelete !== "" && newVendorFilter.isDelete !== null) {
      requestBody.p_IsDeleted = newVendorFilter.isDelete;
    }
    if (newVendorFilter.lastName !== "" && newVendorFilter.lastName !== null) {
      requestBody.p_last_name = newVendorFilter.lastName;
    }
    if (newVendorFilter.phNum !== "" && newVendorFilter.phNum !== null) {
      requestBody.p_phone_number = newVendorFilter.phNum;
    }

    // return
    getAllNewVendorPersonService(requestBody, tokenData, dispatch);
    if (newVendorPage > 1) {
      setCurrentPage(newVendorPage - 1); // Update the current page state
      dispatch(getNewVendorPageData(newVendorPage - 1));
    }
  };

  useEffect(() => {
    if (
      newVendorData &&
      newVendorData.items &&
      newVendorData.items.length === 0
    ) {
      fetchNewVendors();
    } else {
      if (
        newVendorFilter?.firstName !== "" ||
        newVendorFilter?.lastName !== "" ||
        newVendorFilter?.phNum !== "" ||
        newVendorFilter?.isActive !== "" ||
        newVendorFilter?.isDelete !== ""
      ) {
        // handleShow();
      }
    }

    if (
      newVendorData.items[0]?.data &&
      newVendorData.items[0].data.length > 0
    ) {
      const transformedNewVendorData = newVendorData.items[0].data.map(
        (item) => {
          const ownerName = item.first_name + " " + item.last_name;
          return {
            id: item.id,
            name: ownerName,
            mobile: item.phone_number,
            shopName: item.shopeName,
            status: item.is_active,
            is_delete: item.isDeleted,
            categoryData: item.categoryData,
            first_name: item.first_name,
            last_name: item.last_name,
            wp_sms_allow: item.wp_sms_allow,
            isDeleted: item.is_deleted,
          };
        }
      );

      setTableData(transformedNewVendorData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [newVendorData, newVendorFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (
        newVendorFilter.firstName !== "" &&
        newVendorFilter.firstName !== null
      ) {
        requestBody.p_first_name = newVendorFilter.firstName;
      }
      if (
        newVendorFilter.isActive !== "" &&
        newVendorFilter.isActive !== null
      ) {
        requestBody.p_isActive = newVendorFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (
        newVendorFilter.isDelete !== "" &&
        newVendorFilter.isDelete !== null
      ) {
        requestBody.p_IsDeleted = newVendorFilter.isDelete;
      }
      if (
        newVendorFilter.lastName !== "" &&
        newVendorFilter.lastName !== null
      ) {
        requestBody.p_last_name = newVendorFilter.lastName;
      }
      if (newVendorFilter.phNum !== "" && newVendorFilter.phNum !== null) {
        requestBody.p_phone_number = newVendorFilter.phNum;
      }
      getAllNewVendorPersonService(requestBody, tokenData, dispatch);
      dispatch(getNewVendorPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearNewVendorFilterData());
    dispatch(clearNewVendorPageData());
    dispatch(clearNewVendorData());
    handleClose();
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        {/* <div className="sidebar-content">
            <Sidebar />
          </div> */}
        {/* <div className="body-content">
            <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {newVendorActionData && newVendorActionData.length > 0
                    ? getRouteNameFromSidebar(
                        newVendorActionData[0].name,
                        localisation
                      )
                    : t("Vendors")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {newVendorActionData && newVendorActionData.length > 0
                    ? getRouteNameFromSidebar(
                        newVendorActionData[0].name,
                        localisation
                      )
                    : t("Vendors")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {newVendorFilter.firstName !== "" ||
              newVendorFilter.lastName !== "" ||
              newVendorFilter.phNum !== "" ||
              newVendorFilter.isActive !== "" ||
              newVendorFilter.isDelete !== "" ? (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter
                    page="new-vendor-person"
                    handleClose={handleClose}
                  ></Filter>
                </Offcanvas.Body>
              </Offcanvas>

              {newVendorData &&
                newVendorData.items[0]?.data &&
                newVendorData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={newVendorData.items[0].data}
                    fileName={"Vendor"}
                  ></ExcelData>
                )}
              <Button
                data-bs-toggle="tooltip"
                title="Add Vendor Person"
                onClick={() => navigate("/add-vendor-person")}
                className="btn btn-fillter-main"
                name={
                  <div className="Plus">
                    <FaPlus />
                  </div>
                }
              ></Button>
            </div>
          </div>
          {/* {isToggled && <Filter page="new-vendor-person"></Filter>} */}

          {newVendorData &&
            newVendorData.items[0]?.data &&
            newVendorData.items[0].data && (
              <>
                <CustomTable
                  page="new-vendor-person"
                  showToggle={showToggle}
                  checkBool={false}
                  showKey={showKey}
                  showActions={showActionBool ? showActions : false}
                  // showActions={ showActions }
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  disabled={
                    newVendorActionData &&
                    newVendorActionData[0]?.edit &&
                    newVendorActionData[0].edit === "0"
                  }
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                  pageData={newVendorPage}
                />

                {/* pagination */}
                {newVendorData.items[0].total_records >
                  newVendorData.items[0].data.length &&
                  newVendorData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={newVendorData.items[0].total_records}
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
        {/* <Footer />
          </div> */}
      </div>
    </>
  );
};

export default NewVendorPerson;

import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ErrorComp from "../Error/ErrorComp";
import './CustomSelect.scss';
import { isEqual } from 'lodash';

export default function CustomSelect  ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  disabled,
  onSelectChange,
  value,
  changeData,
  onchangedata,
  styles,
  innerRef
}) {
  const [selectedValue, setSelectedValue] = useState(null);
  
  /**
   * On change selection
   * @param {*} option 
   */
  const onChange = (option) => {
    form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
    if(onSelectChange){
      onSelectChange(isMulti ? option.map((item) => item) : option);
    }
    if(changeData) {
      changeData(option.value);
    }
    if(onchangedata) {
      onchangedata(option);
    }
  };

  useEffect(() => {
    // Define the new selected value based on the current value and options
    const newSelectedValue = !value && value !== 0
      ? null
      : isMulti
      ? options.filter(option => value.includes(option.value))
      : options.find(option => option.value === value);
  
    // Update selectedValue only if there's a change
    if (!isEqual(selectedValue, newSelectedValue)) {
      setSelectedValue(newSelectedValue);
    }
  
    // Clear the form field if value is null or 0
    if (!value && value !== 0) {
      form.setFieldValue(field.name, '');
    }
    // eslint-disable-next-line
  }, [value, isMulti, options]);
  

  /**
   * Custom style for making inactive option red
   * @param {*} provided 
   * @param {*} state 
   * @returns {*}
   */
  const customOptionStyles = (provided, state) => {
    let color = state.isSelected ? 'white' : '#333'; // Default text color
    let backgroundColor = state.isSelected ? '#000' : '#FFF'; // Default background color
  
    // Check if the option has is_active property and it's '0'
    if (state.data.is_active === '0') {
      color = '#F00'; // Set text color to red
    }
  
    return {
      ...provided,
      backgroundColor: backgroundColor,
      color: color,
      '&:hover': {
        backgroundColor: '#000', // Change background color on hover
        color: '#FFF', // Change text color on hover
      },
    };
  };

  return (
    <>
    <Select
      className={className}
      name={field.name}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      value={selectedValue}
      isDisabled={disabled}
      styles={{
        ...styles,
        option: customOptionStyles
      }}
      ref={innerRef && innerRef}
    />
    <ErrorMessage name={field.name} component={ErrorComp}/>
    </>
  );
};
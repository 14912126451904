import { Dispatch } from "redux";
import {
  addUpdateInventoryItemRequest,
  getAllInventoryItemRequest,
  getAllInventoryItemResponse,
  getInventoryItemByIdResponse,
} from "./InventoryItemModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  clearInventoryItemData,
  clearTotalInventoryItemData,
  getInventoryItemData,
  getTotalInventoryItemData,
} from "../../../redux/Actions/InventoryItemAction/InventoryItemAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearInventoryItemPageData,
  getInventoryItemPageData,
} from "../../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;
export default function IngredientService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get inventory item list
   */
  const getAllInventoryItemService = (
    inventoryItemListRequest: getAllInventoryItemRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllInventoryItemResponse> => {
    return new Promise<getAllInventoryItemResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          inventoryItemListRequest.p_skip !== undefined &&
          inventoryItemListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${inventoryItemListRequest.p_skip}&`;
        }
        if (
          inventoryItemListRequest.p_limit !== undefined &&
          inventoryItemListRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${inventoryItemListRequest.p_limit}&`;
        }
        if (
          inventoryItemListRequest.p_title !== undefined &&
          inventoryItemListRequest.p_title !== null
        ) {
          queryParams += `p_title=${inventoryItemListRequest.p_title}&`;
        }
        if (
          inventoryItemListRequest.p_isActive !== undefined &&
          inventoryItemListRequest.p_isActive !== null
        ) {
          queryParams += `p_isActive=${inventoryItemListRequest.p_isActive}&`;
        }
        if (
          inventoryItemListRequest.p_IsDeleted !== undefined &&
          inventoryItemListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${inventoryItemListRequest.p_IsDeleted}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.INVENTORYITEM +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalInventoryItemData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getInventoryItemData(response));
          } else {
            dispatch(getInventoryItemData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating Inventory Item
   */
  const addUpdateInventoryItemService = (
    addUpdateInventoryItemRequest: addUpdateInventoryItemRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.INVENTORYITEM,
            token,
            addUpdateInventoryItemRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.INVENTORYITEM,
            token,
            addUpdateInventoryItemRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearInventoryItemData());
          dispatch(clearTotalInventoryItemData());
          if (type === "add") {
            dispatch(clearInventoryItemPageData());
            toast.success(
              response?.message
                ? response.message
                : "Inventory Item added successfully!"
            );
            navigate("/inventory-item");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Inventory Item  undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getInventoryItemPageData(pageNumber + 1));
                } else {
                  dispatch(clearInventoryItemPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Inventory item updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/inventory-item");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Inventory Item status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getInventoryItemPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting invertory item
   */
  const deleteInventoryItemService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.INVENTORYITEM + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearInventoryItemData());
          dispatch(clearTotalInventoryItemData());
          toast.success(
            response?.message
              ? response.message
              : "Inventory Item deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          dispatch(getInventoryItemPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual inventory item
   */
  const getInventoryItemById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getInventoryItemByIdResponse> => {
    return new Promise<getInventoryItemByIdResponse>(
      async (resolve, reject) => {
        try {
          dispatch(setLoader());
          const response = await getApiService(
            API_URL.BASE_URL + API_URL.INVENTORYITEM + "/" + id,
            token,
            null,
            localisation
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === "200" ||
              response.status === "success")
          ) {
            resolve(response);
          } else {
            if (!document.querySelector(toastClass)) {
              toast.error(
                response?.message
                  ? response.message
                  : t(ValidationMessage.SOMETHING_WENT_WRONG)
              );
            }
            errorShow = true;
            if (
              response?.message &&
              response.message === UnauthorizeAccessMessage
            ) {
              removePersistStorage(dispatch);
            }
          }
          resolve(response);
        } catch (error: any) {
          if (error?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          if (errorShow === false && !document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        } finally {
          errorShow = false;
          dispatch(clearLoader());
        }
      }
    );
  };
  return {
    getAllInventoryItemService,
    addUpdateInventoryItemService,
    deleteInventoryItemService,
    getInventoryItemById,
  };
}

import { toast } from "react-toastify";
import { API_URL, ValidationMessage } from "../../Utils/Utils";
import { postApiService } from "../../services/API-services/PostService";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";

export const requestOtp = (email, token, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader());
      // Call your login API endpoint
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.REQUEST_OTP + email,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success(
          response?.message ? response.message : "OTP sent successfully!"
        );
        navigate("/otp", { state: { email: email } });
      } else {
        if (response === "unauthenticated") {
          toast.error("Unauthenticated user!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      dispatch(clearLoader());
    }
  };
};

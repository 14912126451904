import {
  CLEAR_INVENTORY_ITEM_DATA,
  CLEAR_TOTAL_INVENTORY_ITEM,
  GET_INVENTORY_ITEM_DATA,
  GET_TOTAL_INVENTORY_ITEM,
} from "../../ReduxKeys";

const inventoryInitialState = {
  items: [],
};

/**
 * Store inventory item list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const InventoryItemReducer = (state = inventoryInitialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_ITEM_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_INVENTORY_ITEM_DATA:
      return inventoryInitialState;
    default:
      return state;
  }
};

const totalInventoryInitialState = {
  items: [],
};

/**
 * Store total inventory item list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalInventoryItemReducer = (
  state = totalInventoryInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_INVENTORY_ITEM:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_INVENTORY_ITEM:
      return totalInventoryInitialState;
    default:
      return state;
  }
};
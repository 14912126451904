import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { clearProductInventoryPageData } from "../../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { modifyString } from "../../../services/Regular-services/StringService";
import IngredientService from "../../InventoryItems/InventoryItemService/InventoryItemService";
import ProductServices from "../../Product/ProductService/ProductService";
import ProductInventoryServices from "../ProductInventoryService/ProductInventoryService";

const AddProductInventory = () => {
  const { getAllInventoryItemService } = IngredientService();
  const { addUpdateProductInventoryService, getAllProductInventoryService } =
    ProductInventoryServices();
  const { getAllProductService } = ProductServices();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    name: "",
    ingredient_name: "",
    ingredient_quantity: "",
    ingredient_unit: "",
    // status: 1,
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    // quantity: Yup.string().required(ValidationMessage.QUANTITY_REQUIRED),
    // unit: Yup.string().required(ValidationMessage.UNIT_REQUIRED)
  });

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};
  // const inventoryPage = useSelector((state) => state.inventoryPage?.items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const [fieldArrays, setFieldArrays] = useState([]);
  const totalProduct = useSelector((state) => state.totalProduct);
  const [productOptions, setProductOptions] = useState([]);
  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const localisation = useSelector((state) => state.localization?.items);
  const totalProductInventory = useSelector(
    (state) => state.totalProductInventory
  );
  const [inventoryItemOptions, setInventoryItemOptions] = useState([]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);
  const [unitOptions, setUnitOptions] = useState([]);
  const { handleCancleClick, handlesaveClick } = AlertMessage();

  useEffect(() => {
    if (
      totalUnitsDropdown &&
      totalUnitsDropdown?.items &&
      totalUnitsDropdown.items.length > 0 &&
      totalUnitsDropdown.items[0]?.data &&
      totalUnitsDropdown.items[0].data.length > 0
    ) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
        id: unit.id,
      }));
      setUnitOptions(unitData);
    } else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown]);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [productInventoryActionData, setProductInventoryActionData] = useState(
    []
  );

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "product-inventory"
      );
      setProductInventoryActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        name: Data.data.productId,
        ingredient_name: "",
        ingredient_quantity: "",
        ingredient_unit: "",
        // status: Data.data.status,
        // is_deleted: Data.data.is_delete,
      });

      const ingredientsData = JSON.parse(Data.data.ingredients);

      const newingredientData = ingredientsData
        ? ingredientsData.map((item, index) => {
            let quantityInOriginalUnit = parseFloat(item.use_value);
            let unit = item.use_unit;

            if (
              unit === "gm" ||
              unit === "ml" ||
              unit === "l" ||
              unit === "mg" ||
              unit === "kg"
            ) {
              if (unit === "gm" && quantityInOriginalUnit > 999) {
                quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
                unit = "kg";
              } else if (unit === "gm" && quantityInOriginalUnit < 1) {
                quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
                unit = "mg";
              } else if (unit === "ml" && quantityInOriginalUnit > 999) {
                quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
                unit = "l";
              }
            }

            return {
              id: index + 1,
              ingredient_name: parseInt(item.ingredient_id),
              ingredient_quantity: quantityInOriginalUnit,
              ingredient_unit: unit,
            };
          })
        : [];

      setFieldArrays(newingredientData);
    }
  }, [Data]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Fetching product data from API
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
    };
    getAllProductService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      (totalProduct &&
        totalProduct?.items &&
        totalProduct.items.length === 0) ||
      !totalProduct?.items
    ) {
      fetchProduct();
    } else if (totalProduct?.items[0]?.data?.length > 0) {
      const newProduct = totalProduct.items[0].data.map((item) => ({
        label: item?.name,
        value: item?.productId,
      }));
      if (Data) {
        setProductOptions(newProduct);
      } else {
        if (totalProductInventory?.items?.length === 0) {
          fetchProductInventory();
        } else {
          if (totalProductInventory?.items[0]?.data?.length > 0) {
            const filteredProductOptions = newProduct.filter(
              (newItem) =>
                !totalProductInventory.items[0].data.some(
                  (inventoryItem) => inventoryItem.productId === newItem.value
                )
            );
            setProductOptions(filteredProductOptions);
          } else {
            setProductOptions(newProduct);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [totalProduct, totalProductInventory]);

  const fetchProductInventory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      // p_is_active: "1",
    };
    getAllProductInventoryService(requestBody, token, dispatch, true);
  };
  /**
   * Fetching product data from API
   */
  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalInventoryItem &&
      totalInventoryItem.items &&
      totalInventoryItem.items.length === 0
    ) {
      fetchInventoryItem();
    } else {
      if (
        totalInventoryItem.items[0]?.data &&
        totalInventoryItem.items[0].data.length > 0
      ) {
        const newInventoryItem = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
            quantity: item?.thresoldValue,
            unit: item?.thresoldUnit,
          })
        );

        setInventoryItemOptions(newInventoryItem);
      }
    }
    // eslint-disable-next-line
  }, [totalInventoryItem]);

  const handleIngredientChange = (selectedOption, setFieldValue) => {
    if (!selectedOption) return;
    let quantityInOriginalUnit = parseFloat(selectedOption.quantity);
    let unit = selectedOption.unit;

    if (
      unit === "gm" ||
      unit === "kg" ||
      unit === "l" ||
      unit === "ml" ||
      unit === "mg"
    ) {
      if (unit === "gm" && quantityInOriginalUnit > 999) {
        quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
        unit = "kg";
      } else if (unit === "gm" && quantityInOriginalUnit < 1) {
        quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
        unit = "mg";
      } else if (unit === "ml" && quantityInOriginalUnit > 999) {
        quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
        unit = "l";
      }
    }

    // Update form values
    setFieldValue("ingredient_name", selectedOption.value);
    setFieldValue("ingredient_quantity", "");
    setFieldValue("ingredient_unit", unit);
  };

  /**
   * Appending ingredient name, ingredient unit, and ingredient quantity
   */
  const handleAddFieldArray = (values, setFieldValue) => {
    if (
      values.ingredient_name === "" ||
      values.ingredient_quantity === "" ||
      values.ingredient_unit === ""
    ) {
      toast.error(
        "Please enter Ingredient Name, Ingredient Quantity and Ingredient Unit!"
      );
      return;
    } else {
      // Check if the new field conflicts with existing ones

      const conflicts =
        fieldArrays && fieldArrays.length > 0
          ? fieldArrays.some(
              (field) => field.ingredient_name === values.ingredient_name
              // && Number( field.ingredient_quantity) === Number(values.ingredient_quantity) &&
              //   field.ingredient_unit === values.ingredient_unit
            )
          : false;
      if (conflicts) {
        toast.error("The ingredient should be unique!");
        setFieldValue("ingredient_name", "");
        setFieldValue("ingredient_unit", "");
        setFieldValue("ingredient_quantity", "");
        return;
      }
      let newId;
      if (fieldArrays.length === 0) {
        newId = fieldArrays.length + 1;
      } else {
        const lastKey = fieldArrays[fieldArrays.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        ingredient_name: values.ingredient_name,
        ingredient_unit: values.ingredient_unit,
        ingredient_quantity: values.ingredient_quantity,
      };
      setFieldValue("ingredient_name", "");
      setFieldValue("ingredient_unit", "");
      setFieldValue("ingredient_quantity", "");
      setFieldArrays([...fieldArrays, newField]);
    }
  };
  /**
   * Removing already appended ingredient_name, start time, end time
   */
  const handleRemoveArrayField = (idToRemove) => {
    const list = [...fieldArrays];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFieldArrays(filterField);
  };

  /**
   * Updating ingredient_names, ingredient_quantity and ingredient_unit in reverse order
   */
  const onChangeData = (index, value, dataType, fieldValue) => {
    let listData = [...fieldArrays];

    if (dataType === "ingredient_quantity") {
      // if (
      //     value !== "" &&
      //     value > listData[fieldArrays.length - index - 1]["ingredient_unit"] &&
      //     listData[fieldArrays.length - index - 1]["ingredient_unit"] > 0 &&
      //     listData[fieldArrays.length - index - 1]["ingredient_quantity"] > 0
      // ) {
      //     if (!document.querySelector(toastClass)) {
      //         toast.error("Start time should not be greater than end time");
      //     }
      //     return;
      // }
      // else {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.ingredient_name === fieldValue.ingredient_name &&
                  field.ingredient_quantity === value &&
                  field.ingredient_unit === fieldValue.ingredient_unit
              )
            : false;

        if (conflicts) {
          toast.error("The ingredient should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
      // }
    } else if (dataType === "ingredient_unit") {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.ingredient_name === fieldValue.ingredient_name &&
                  field.ingredient_quantity ===
                    fieldValue.ingredient_quantity &&
                  field.ingredient_unit === value
              )
            : false;

        if (conflicts) {
          toast.error("The ingredient should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    } else if (dataType === "ingredient_name") {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.ingredient_name === value &&
                  field.ingredient_quantity ===
                    fieldValue.ingredient_quantity &&
                  field.ingredient_unit === fieldValue.ingredient_unit
              )
            : false;

        if (conflicts) {
          toast.error("The ingredient should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    } else {
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    }
  };

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    let ingredientData =
      fieldArrays.length > 0
        ? fieldArrays.map((item) => {
            // // Apply conversion logic
            let { quantityInBaseUnit, unit } = convertToBaseUnit(
              item.ingredient_quantity,
              item.ingredient_unit
            );
            return {
              ingredient_id: JSON.stringify(item.ingredient_name),
              value: quantityInBaseUnit,
              unit: unit,
            };
          })
        : [];

    // Handle case where no ingredients are present
    if (ingredientData.length < 1) {
      if (
        values.ingredient_name !== "" &&
        values.ingredient_quantity !== "" &&
        values.ingredient_unit !== ""
      ) {
        let { quantityInBaseUnit, unit } = convertToBaseUnit(
          values.ingredient_quantity,
          values.ingredient_unit
        );
        ingredientData.push({
          ingredient_id: JSON.stringify(values.ingredient_name),
          value: quantityInBaseUnit,
          unit: unit,
        });
      } else {
        toast.error("Please enter atleast one Ingredient Data");
        return;
      }
    } else {
      if (
        values.ingredient_name !== "" &&
        values.ingredient_quantity !== "" &&
        values.ingredient_unit !== ""
      ) {
        let { quantityInBaseUnit, unit } = convertToBaseUnit(
          values.ingredient_quantity,
          values.ingredient_unit
        );
        const ingredients = ingredientData.some(
          (ingredient) =>
            ingredient.ingredient_id === JSON.stringify(values.ingredient_name)
        );
        if (!ingredients) {
          ingredientData.push({
            ingredient_id: JSON.stringify(values.ingredient_name),
            value: quantityInBaseUnit,
            unit: unit,
          });
        }
      }
    }

    const requestBody = {
      // ...(Data && Data.data && { p_id: values.id }),
      p_product_id: values.name,
      p_ingredient_data: ingredientData,
      // Add other fields as necessary
    };
    dispatch(clearProductInventoryPageData());
    if (!Data) {
      dispatch(
        addUpdateProductInventoryService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdateProductInventoryService(
          requestBody,
          token,
          "update",
          navigate
        ),
        "update"
      );
    }
  };

  return (
    <>
      <div className=" add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {productInventoryActionData &&
                  productInventoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          productInventoryActionData[0].name,
                          localisation
                        )
                      )
                    : t("Recipes")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/product-inventory")}>
                  {productInventoryActionData &&
                  productInventoryActionData.length > 0
                    ? getRouteNameFromSidebar(
                        productInventoryActionData[0].name,
                        localisation
                      )
                    : t("Recipes")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {productInventoryActionData &&
                  productInventoryActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          productInventoryActionData[0].name,
                          localisation
                        )
                      )
                    : t("Recipes")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {productInventoryActionData &&
              productInventoryActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      productInventoryActionData[0].name,
                      localisation
                    )
                  )
                : t("Recipes")}
            </h4>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Name")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="name"
                        options={productOptions}
                        component={CustomSelect}
                        placeholder={t("Placeholder_select_product")}
                        isMulti={false}
                        value={values.name}
                        disabled={Data}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  {/* ingredient data */}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            {t("lable_ingredient_name")}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                      </div>
                      {(Data && Data.isEdit === true) || !Data ? (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_ingredient_quantity")}
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_ingredient_quantity")}
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      )}
                      {(Data && Data.isEdit === true) || !Data ? (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_ingredient_unit")}{" "}
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("lable_ingredient_unit")}{" "}
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              {t("actions.add")}
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-6">
                          <Field
                            className="select-menu mb-2"
                            name="ingredient_name"
                            options={inventoryItemOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_Select")}
                            isMulti={false}
                            value={
                              values.ingredient_name === "" ||
                              values.ingredient_name === null
                                ? null
                                : values.ingredient_name
                            }
                            onchangedata={(selectedOption) =>
                              handleIngredientChange(
                                selectedOption,
                                setFieldValue
                              )
                            } // Update here
                          />
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <Input
                            type="text"
                            name="ingredient_quantity"
                            placeholder={t("Placeholder_enter_quantity")}
                            className="add-roles"
                            maxLength={20}
                            Regex={/[^0-9]/g}
                            value={values.ingredient_quantity || ""}
                            onChange={(e) =>
                              setFieldValue(
                                "ingredient_quantity",
                                e.target.value
                              )
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-md-2">
                          <div className="name-type">
                            <Field
                              className="select-menu mb-2"
                              name="ingredient_unit"
                              options={unitOptions}
                              component={CustomSelect}
                              placeholder={t("placeholder_Select")}
                              isMulti={false}
                              value={
                                values.ingredient_unit === "" ||
                                values.ingredient_unit === null
                                  ? false
                                  : values.ingredient_unit
                              }
                            />
                          </div>
                        </div>
                      )}
                      {((Data && Data.isEdit === true) || !Data) && (
                        <div className="col-1">
                          <Button
                            className="btn btn-fillter-main"
                            type="button"
                            onClick={() =>
                              handleAddFieldArray(values, setFieldValue)
                            }
                            name={
                              <div className="Plus">
                                <FaPlus />
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {fieldArrays &&
                        fieldArrays.length > 0 &&
                        fieldArrays
                          .slice()
                          .reverse()
                          .map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-6">
                                <Field
                                  className="select-menu mb-2"
                                  name={`ingredient_name${index}`}
                                  options={inventoryItemOptions}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  changeData={(value) =>
                                    onChangeData(
                                      index,
                                      value,
                                      "ingredient_name",
                                      field
                                    )
                                  }
                                  value={field.ingredient_name}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                              {(Data && Data.isEdit === true) || !Data ? (
                                <div className="col-md-2">
                                  <Input
                                    type="text"
                                    name={`ingredient_quantity${index}`}
                                    placeholder={t(
                                      "Placeholder_enter_quantity"
                                    )}
                                    className="add-roles"
                                    maxLength={20}
                                    Regex={/[^0-9]/g}
                                    value={field.ingredient_quantity || ""}
                                    changeInputData={(e) =>
                                      onChangeData(
                                        index,
                                        e.target.value,
                                        "ingredient_quantity",
                                        field
                                      )
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                  {/* <Input
                                                                        type="text"
                                                                        name="ingredient_quantity"
                                                                        placeholder="Enter Quantity"
                                                                        className="add-roles"
                                                                        maxLength={20}
                                                                        Regex={/[^0-9]/g}
                                                                        value={values.ingredient_quantity || ''}
                                                                        onChange={(e) => setFieldValue('ingredient_quantity', e.target.value)}
                                                                        disabled={Data && Data.isEdit === false}
                                                                    /> */}
                                </div>
                              ) : (
                                <div className="col-md-3">
                                  <Input
                                    type="text"
                                    name={`ingredient_quantity${index}`}
                                    placeholder={t(
                                      "Placeholder_enter_quantity"
                                    )}
                                    className="add-roles"
                                    maxLength={20}
                                    Regex={/[^0-9]/g}
                                    value={field.ingredient_quantity || ""}
                                    onChange={(e) =>
                                      onChangeData(
                                        index,
                                        e.target.value,
                                        "ingredient_quantity",
                                        field
                                      )
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              )}
                              {(Data && Data.isEdit === true) || !Data ? (
                                <div className="col-md-2">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`ingredient_unit${index}`}
                                      options={unitOptions}
                                      component={CustomSelect}
                                      placeholder={t("placeholder_Select")}
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "ingredient_unit",
                                          field
                                        )
                                      }
                                      value={field.ingredient_unit}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-3">
                                  <div className="name-type">
                                    <Field
                                      className="select-menu mb-2"
                                      name={`ingredient_unit${index}`}
                                      options={unitOptions}
                                      component={CustomSelect}
                                      placeholder={t("placeholder_Select")}
                                      isMulti={false}
                                      changeData={(value) =>
                                        onChangeData(
                                          index,
                                          value,
                                          "ingredient_unit",
                                          field
                                        )
                                      }
                                      value={field.ingredient_unit}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              )}

                              {((Data && Data.isEdit === true) || !Data) && (
                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() =>
                                      handleRemoveArrayField(field.id)
                                    }
                                    name={
                                      <div className="Plus">
                                        <FaMinus />
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick(
                                  "/product-inventory",
                                  navigate
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default AddProductInventory;

import {
  CLEAR_SERVICE_PROVIDER_FILTER,
  GET_SERVICE_PROVIDER_FILTER,
} from "../../ReduxKeys";

const ServiceProviderFilterInitialState = {
  name: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ServiceProviderFilterReducer = (
  state = ServiceProviderFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_SERVICE_PROVIDER_FILTER:
      return {
        ...state,
        name: action.payload.name,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_SERVICE_PROVIDER_FILTER:
      return ServiceProviderFilterInitialState;
    default:
      return state;
  }
};
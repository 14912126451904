import { CLEAR_LOADER, SET_LOADER } from "../ReduxKeys"

/**
 * Action to set loader
 * @returns {*}
 */
export const setLoader = () => ({
    type: SET_LOADER
})

/**
 * Action to remove loader
 * @returns {*}
 */
export const clearLoader = () => ({
    type: CLEAR_LOADER
})
import {
  CLEAR_SERVICE_PROVIDER_DATA,
  CLEAR_TOTAL_SERVICE_PROVIDER_DATA,
  GET_SERVICE_PROVIDER_DATA,
  GET_TOTAL_SERVICE_PROVIDER_DATA,
} from "../../ReduxKeys";

/**
 * Set service provider list data into reducer
 * @param {*} ServiceProvider
 * @returns {*}
 */
export const getServiceProviderData = (ServiceProvider) => ({
  type: GET_SERVICE_PROVIDER_DATA,
  payload: ServiceProvider,
});

/**
 * Clear service provider list data
 * @returns {*}
 */
export const clearServiceProviderData = () => ({
  type: CLEAR_SERVICE_PROVIDER_DATA,
});

/**
 * Set total service provider list data into reducer (No pagination)
 * @param {*} ServiceProvider
 * @returns {*}
 */
export const getTotalServiceProviderData = (ServiceProvider) => ({
  type: GET_TOTAL_SERVICE_PROVIDER_DATA,
  payload: ServiceProvider,
});

/**
 * clear total service provider list data (No pagination)
 * @returns {*}
 */
export const clearTotalServiceProviderData = () => ({
  type: CLEAR_TOTAL_SERVICE_PROVIDER_DATA,
});
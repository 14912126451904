import { CLEAR_DISCOUNT_BRAND, GET_DISCOUNT_BRAND } from "../../ReduxKeys";

/**
 * Set discount brand data into reducer
 * @param {*} data
 * @returns {*}
 */
export const getDiscountBrandData = (data) => ({
  type: GET_DISCOUNT_BRAND,
  payload: data,
});

/**
 * Clear discount brand data
 * @returns {*}
 */
export const clearDiscountBrand = () => ({
  type: CLEAR_DISCOUNT_BRAND,
});
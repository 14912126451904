import { CLEAR_EXPENSE_REPORT, GET_EXPENSE_REPORT } from "../../ReduxKeys";

const expenseGraphInitialState = {
  items: [],
};

/**
 * Store expense graph list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ExpenseGraphReducer = (
  state = expenseGraphInitialState,
  action
) => {
  switch (action.type) {
    case GET_EXPENSE_REPORT:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_EXPENSE_REPORT:
      return expenseGraphInitialState;
    default:
      return state;
  }
};
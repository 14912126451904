import {
  CLEAR_CUSTOMER_ORDER_PAGE,
  GET_CUSTOMER_ORDER_PAGE,
} from "../../ReduxKeys";

/**
 * Set CUSTOMER page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getCustomerOrderPageData = (page) => ({
  type: GET_CUSTOMER_ORDER_PAGE,
  payload: page,
});

/**
 * Clear CUSTOMER page data
 * @returns {*}
 */
export const clearCustomerOrderPageData = () => ({
  type: CLEAR_CUSTOMER_ORDER_PAGE,
});
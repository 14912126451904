import { CLEAR_SALES_TABLE_PAGE, GET_SALES_TABLE_PAGE } from "../../ReduxKeys";

const SalesTablePageInitialState = {
  items: 1,
};

/**
 * Store CUSTOMER ORDERS page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const SalesTablePageReducer = (
  state = SalesTablePageInitialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_TABLE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_SALES_TABLE_PAGE:
      return SalesTablePageInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_PRODUCT_INVENTORY_FILTER,
  GET_PRODUCT_INVENTORY_FILTER,
} from "../../ReduxKeys";

const ProductInventoryFilterInitialState = {
  productId: "",
  // isActive: '',
  // isDelete: '',
};

/**
 * Store branch filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ProductInventoryFilterReducer = (
  state = ProductInventoryFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_PRODUCT_INVENTORY_FILTER:
      return {
        ...state,
        productId: action.payload.productId,
        // isActive: action.payload.isActive,
        // isDelete: action.payload.isDelete,
      };
    case CLEAR_PRODUCT_INVENTORY_FILTER:
      return ProductInventoryFilterInitialState;
    default:
      return state;
  }
};
import { CLEAR_EXPENSE_PAGE, GET_EXPENSE_PAGE } from "../../ReduxKeys";

const expensePageInitialState = {
  items: 1,
};

/**
 * Store expense page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const ExpensePageReducer = (state = expensePageInitialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_EXPENSE_PAGE:
      return expensePageInitialState;
    default:
      return state;
  }
};
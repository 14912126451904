import {
  CLEAR_SALES_TABLE_FILTER,
  GET_SALES_TABLE_FILTER,
} from "../../ReduxKeys";

/**
 * set order sales filter data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getSalesTableFilterData = ({
  startDate,
  endDate,
  year,
  month,
  status,
  orderType,
}) => ({
  type: GET_SALES_TABLE_FILTER,
  payload: { startDate, endDate, year, month, status, orderType },
});

/**
 * clear order filtered data
 * @returns {*}
 */
export const clearSalesTableFilterData = () => ({
  type: CLEAR_SALES_TABLE_FILTER,
});
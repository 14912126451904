import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ValidationMessage } from "../../Utils/Utils";
import Button from "../../components/Button/Button";
// import Checkbox from '../../components/Checkbox/Checkbox';
import Loginbanner from "../../components/Loginbanner/Loginbanner";
import Input from "../../components/input/Input";
import "./Login.scss";
import Logo from "../../assets/images/Bespeakk-logo.png";
import { useTranslation } from "react-i18next";
import NewLoginService from "../../services/API-services/generateToken";

export const validateInput = (str = "") => str.includes("@");

//Initial Values
const initialValues = {
  email: "",
  password: "",
  // rememberMe: false
};

export default function Loginpage() {
  const { fetchToken } = NewLoginService();
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const { t } = useTranslation();
  
  const validationSchema = isOtpRequested
    ? Yup.object({
        phone: Yup.string()
          .required(t(ValidationMessage.MOBILE_REQUIRED))
          .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
        otp: Yup.string()
          .required(t(ValidationMessage.OTP_REQUIRED))
          .matches(/^\d{4}$/, t(ValidationMessage.OTP_4_LENGTH)),
      })
    : Yup.object({
        phone: Yup.string()
          .required(t(ValidationMessage.MOBILE_REQUIRED))
          .matches(/^\d{10}$/, t(ValidationMessage.MOBILE_LENGTH)),
      });

  const dispatch = useDispatch();
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsLinkActive(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [timer]);

  const handleClick = (values) => {
    if (isLinkActive) {
      dispatch(fetchToken(values, "requestLoginOtp", setIsOtpRequested));
      setTimer(120); // Reset the timer to 2 minutes
      setIsLinkActive(false);
    }
  };

  //Handling form submission => Generate Token and Login
  const onSubmit = (values) => {
    if (
      values.phone &&
      values.phone !== "" &&
      values.phone.length >= 10 &&
      !isOtpRequested
    ) {
      // setIsOtpRequested(true);
      dispatch(fetchToken(values, "requestLoginOtp", setIsOtpRequested));
    }
    if (
      values.phone &&
      values.phone !== "" &&
      values.phone.length >= 10 &&
      isOtpRequested
    ) {
      dispatch(fetchToken(values, "login"));
    }
  };

  return (
    <>
      <section className="login-form">
        <div className="row w-100 m-0">
          <div className="col-lg-6 p-0 d-lg-flex d-none">
            <Loginbanner />
          </div>
          <div className="col-lg-6 col-12 p-0">
            {/* Right side login form section end  */}

            <div className="right-side-form">
              <div className="logo d-lg-none d-flex">
                <img src={Logo} alt="" />
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values }) => (
                  <Form className="wrapper label-p-0 bg-layer">
                    <h2>{t("lable_login")}</h2>
                    <Input
                      type="text"
                      name="phone"
                      label={t("addOrders.mobile_label")}
                      placeholder={t("Placeholder_mobile_no")}
                      isRequired={true}
                      maxLength={10}
                      Regex={/[^0-9\s]/g}
                      hidelength={"true"}
                      disabled={isOtpRequested}
                      value={values.phone}
                    />
                    {isOtpRequested && (
                      <Input
                        type="text"
                        name="otp"
                        label={t("lable_OTP")}
                        placeholder={t("placeholder_enter_otp")}
                        isRequired={true}
                        Regex={/[^0-9\s]/g}
                        autoComplete="new-password"
                        maxLength={4}
                        hidelength={"true"}
                        value={values.otp}
                      />
                    )}
                    {/* <section className='remember'>
                      <div className='checkbox-detail'>
                        <Field name='rememberMe' setFieldValue={setFieldValue} label='Remember me' component={Checkbox}></Field>
                      </div>
                      <div className='forget-password'>
                        <Link to='/forgot-password'>Forgot Password?</Link>
                      </div>
                    </section> */}

                    <section className="remember">
                      <div className="checkbox-detail">
                        {/* <Field name='rememberMe' setFieldValue={setFieldValue} label='Remember me' component={Checkbox}></Field> */}
                      </div>
                      {isOtpRequested && (
                        <>
                          <div className="forget-password">
                            {isLinkActive ? (
                              <Link
                                className="resend-otp"
                                onClick={() => handleClick(values)}
                              >
                                {t("lable_resend_otp")}
                              </Link>
                            ) : (
                              <p className="resend-disable-otp">
                                {t("resend_otp_message", { timer })}
                              </p>
                            )}
                            <Link
                              to="/login"
                              className="change-mobile"
                              onClick={() => {
                                setIsOtpRequested(false);
                                setIsLinkActive(false);
                                setTimer(120);
                              }}
                            >
                              {t("change_mobile_number")}
                            </Link>
                          </div>
                        </>
                      )}
                    </section>

                    <Button
                      type="submit"
                      name={
                        isOtpRequested
                          ? t("lable_login")
                          : t("lable_request_OTP")
                      }
                      className="btn login-btn"
                    />
                  </Form>
                )}
              </Formik>
            </div>
            {/* Right side login form section end  */}
          </div>
        </div>
      </section>
    </>
  );
}

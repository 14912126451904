import { CLEAR_EXPENSE_PAGE, GET_EXPENSE_PAGE } from "../../ReduxKeys"

/**
 * Set expense page data
 * @param {*} page 
 * @returns {*}
 */
export const getExpensePageData = (page) => ({
    type: GET_EXPENSE_PAGE,
    payload: page
})

/**
 * Clear expense page data
 * @returns {*}
 */
export const clearExpensePageData = () => ({
    type: CLEAR_EXPENSE_PAGE
})
import { orderTypeArray } from "../../Utils/Utils";

export const OrderArrayMapping = (order) => {
  const item = order?.order;

  const getFormattedSize = (data) => {
    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
  };

  function calculateProductTax(product, order) {
    if (Number(order.total_tax_amount) > 0) {
      const price =
        Number(product.price) * Number(product.quantity) -
        Number(product.product_discount_amount);

      const cgstAmount = price * (Number(product.cgst) / 100);
      const sgstAmount = price * (Number(product.sgst) / 100);

      // Total tax amount
      const totalTax = cgstAmount + sgstAmount;
      return totalTax;
    } else {
      return 0;
    }
  }

  function totalPrice(product, order) {
    const total =
      Number(product.price) * Number(product.quantity) -
      Number(product.product_discount_amount) +
      calculateProductTax(product, order, "tax");
    return total.toFixed(2);
  }

  const transformedData = {
    ...item,
    id: item.orderId,
    type: item.type,
    tableType: orderTypeArray.find((orderType) => orderType.value === item.type)
      ?.label,
    totalItem: item?.productData
      ? JSON.parse(item.productData).reduce(
          (sum, product) => sum + Number(product.quantity),
          0
        )
      : 0,
    orderSubTotal: item.productData
      ? JSON.parse(item.productData).reduce(
          (sum, product) =>
            sum + Number(product.price) * Number(product.quantity),
          0
        )
      : 0,
    total_delivery_charge: item?.delivery_charge
      ? Number(item.delivery_charge)
      : 0,
    discountAmount: item.total_discount_amount
      ? item.total_discount_amount
      : "0",
    total_tax_amount: item?.total_tax_amount
      ? Number(item.total_tax_amount)
      : 0,
    total_amount_with_tax: item?.order_amount ? Number(item.order_amount) : 0,
    orderNumber: item.order_number,
    productData: item.productData
      ? JSON.parse(item.productData).map((product) => ({
          ...product,
          productId: product?.productId ? Number(product.productId) : 0,
          productName: product?.productName
            ? product?.productName.replace(/^'(.*)'$/, "$1")
            : "",
          addOns:
            product?.attribute_data &&
            !["", "null", "NULL"].includes(product.attribute_data)
              ? getFormattedSize(JSON.parse(product.attribute_data))
              : "",
          product_size: product.size,
          product_attribute: product?.attribute_data
            ? JSON.parse(product.attribute_data)
            : null,
          quantity: product.quantity,
          discountProductAmt: Number(product.product_discount_amount),
          taxProductAmt: calculateProductTax(product, item),
          price: product.price ? Number(product.price) : 0,
          totalTablePrice:
            product.quantity && product.price ? totalPrice(product, item) : 0,
          food_type_two:
            product?.food_type_2 &&
            !["", "null", "NULL"].includes(product.food_type_2)
              ? product.food_type_2 === "0"
                ? "No Onion/Garlic"
                : product.food_type_2 === "1"
                ? "Jain"
                : "Regular"
              : "Regular",
          product_instruction:
            product?.product_instruction &&
            !["", "null", "NULL"].includes(product.product_instruction)
              ? product.product_instruction
              : null,
          map_table_id: product?.map_table_id ? product.map_table_id : 0,
          orderId: item.orderId,
          productStatus: product?.status ? product.status : "0",
        }))
      : [],
    zomatoSwiggyPrice: item.zomato_swiggy_other_amount
      ? `${item.zomato_swiggy_other_amount}`
      : null,
    orderStatus: item.status,
    user_id: item.user_id,
    firstName:
      item?.first_name && !["Bespeakk", "bespeakk"].includes(item.first_name)
        ? item.first_name
        : "",
    lastName: item.last_name,
    customerName:
      item?.first_name && !["Bespeakk", "bespeakk"].includes(item.first_name)
        ? item.first_name + " " + item.last_name
        : "N/A",
    phoneNumber:
      item?.phone_number && !["", 8155006366].includes(item.phone_number)
        ? item.phone_number
        : "N/A",
    createdAt: item.createdAt ? new Date(item.createdAt) : new Date(),
    modifiedAt: item.modifiedAt ? new Date(item.modifiedAt) : new Date(),
    tableName: item.spot_name,
  };

  return transformedData;
};
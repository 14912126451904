import React, { useEffect, useRef, useState } from "react";
import "./AddOrder.scss";
import { Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import UtilsData, {
  getRouteNameFromSidebar,
  isProductEligible,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import OrderCart from "../../../components/OrderCart/OrderCart";
import SearchBar from "../../../components/SearchBar/SearchBar";
import DiscountService from "../../Discount/DiscountService/DiscountService";
import { addUpdateOrderRequest } from "./AddUpdateOrderRequest/AddUpdateOrderRequest";
import { MdOutlineCancel } from "react-icons/md";
import { modifyString } from "../../../services/Regular-services/StringService";
import { FaPlus } from "react-icons/fa6";
import CategoryService from "../../Category/Category-service/CategoryService";
import { useTranslation } from "react-i18next";
import { clearTotalProductData } from "../../../redux/Actions/productAction/ProductAction";
import OrderServices from "../OrderService/OrderService";
import ProductServices from "../../Product/ProductService/ProductService";
import SpotServices from "../../Spot/SpotService/SpotService";
import CustomTable from "../../../components/Table/Table";
import DatePicker from "react-datepicker";
import CloseRegister from "../../../assets/images/Close-Register.png";
import ViewRegister from "../../../assets/images/ViewRegister.png";
import unoccupied from "../../../assets/images/unoccupied.png";
import CategoryTree from "./CategoryTree";

export default function AddOrder() {
  const { getAllCategoryService } = CategoryService();
  const { getAllDiscountService, getDiscountById } = DiscountService();
  const {
    getUserDetailService,
    placeOrderService,
    addUpdateSettlementService,
    getSettlementService,
  } = OrderServices();
  const { getAllProductService } = ProductServices();
  // const { getAllSubCategoryService } = SubCategoryServices();
  const { getAllSpotService } = SpotServices();
  const { t } = useTranslation();
  const location = useLocation();
  const { Data } = location.state || {};
  const { tableNo } = location.state || {};

  const navigate = useNavigate();
  const totalProduct = useSelector((state) => state.totalProduct);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const localization = useSelector((state) => state.localization?.items);
  const [addedProducts, setAddedProducts] = useState([]);
  const [totalAmountData, setTotalAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [appliedDiscountCode, setAppliedDiscountCode] = useState(null);
  const [updatedTotalAmt, setUpdatedTotalAmt] = useState(null);
  const [discountAmt, setDiscountAmt] = useState(null);
  const [discountID, setDiscountID] = useState(null);
  const [discountDetail, setDiscountDetail] = useState(null);
  const totalCategory = useSelector((state) => state.totalCategory);
  const [serviceCharge, setServiceCharge] = useState(0);
  const localisation = useSelector((state) => state.localization?.items);
  const settlement = useSelector((state) => state.settlement);
  const [settlementData, setSettlementData] = useState([]);
  const [orderTypeSettlementData, setOrderTypeSettlementData] = useState([]);

  const [columns, setColumns] = useState([
    {
      name: t("columns.payment_type"),
      selector: (row) => row.payment_type,
    },
    {
      name: t("columns.default_amount"),
      selector: (row) => row.default_amount,
    },
    {
      name: t("columns.settlement_amount"),
      selector: (row) => row.settlement_amount,
    },
  ]);
  const [orderTypeColumns, setOrderTypeColumns] = useState([
    {
      name: t("columns.order_type"),
      selector: (row) => row.order_type,
    },
    {
      name: t("columns.default_amount"),
      selector: (row) => row.default_amount,
    },
  ]);
  const showKey = {
    payment_type: true,
    default_amount: true,
    settlement_amount: true,
  };
  const showOrderTypeKey = {
    order_type: true,
    default_amount: true,
  };

  const orderCartRef = useRef();
  const { handlesaveClick } = AlertMessage();

  const openModal = (productData) => {
    if (orderCartRef.current) {
      orderCartRef.current.openModal(productData); // Pass product data
    }
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const [message, setMessage] = useState("");
  const authuserData = useSelector((state) => state.auth?.user);
  const [discountOption, setDiscountOption] = useState([]);

  const [userDetailsModal, setUserDetailModal] = useState(false);
  const [settlementModal, setSettleMentModal] = useState(false);
  const selectRef = useRef(null);

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [orderActionData, setOrderActionData] = useState([]);
  const spotData = useSelector((state) => state.spot);
  const [tableOptions, setTableOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const quantityOptions = [];
  const [productCategory, setProductCategory] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "orders"
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  const totalDiscount = useSelector((state) => state.totalDiscount);
  const dispatch = useDispatch();
  const token = UtilsData();

  const fetchTotalDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 50000,
    };
    getAllDiscountService(requestBody, token, dispatch, true);
    // eslint-disable-next-line
  };

  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 500000,
      p_is_active: "1",
      p_type: "0",
    };
    getAllCategoryService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totalCategory?.items?.length) {
      if (
        totalCategory.items[0]?.data &&
        totalCategory.items[0].data.length > 0
      ) {
        setProductCategory(totalCategory.items[0].data);
      } else {
        setProductCategory([]);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory]);

  useEffect(() => {
    if (
      totalDiscount &&
      totalDiscount.items &&
      totalDiscount.items.length === 0
    ) {
      fetchTotalDiscount();
    } else {
      if (
        totalDiscount.items &&
        totalDiscount.items[0] &&
        totalDiscount.items[0]?.data &&
        totalDiscount.items[0].data.length > 0
      ) {
        const currentDate = new Date();
        const validDiscounts = totalDiscount.items[0].data.filter((item) => {
          const endDate = new Date(item.end_date);
          return endDate > currentDate;
        });

        const optionData = validDiscounts.map((item) => ({
          value: item.discountId,
          label: item.discountCode,
        }));
        setDiscountOption(optionData);
      } else {
        setDiscountOption([]);
      }
    }
    // eslint-disable-next-line
  }, [totalDiscount, Data]);

  for (let i = 1; i <= 100; i++) {
    quantityOptions.push({ value: i.toString(), label: i.toString() });
  }

  const [dineOptions, setDineOptions] = useState([
    { value: "0", label: t("dine_in") },
    { value: "1", label: t("take_away") },
    { value: "2", label: t("delivery") },
    { value: "3", label: t("zomato") },
    { value: "4", label: t("swiggy") },
    { value: "5", label: t("other") },
  ]);

  const [inputData, setInputData] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);

  const [initialValues, setInitialValues] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    tableNo: "",
    product: "",
    size: "",
    price: "",
    quantity: "",
    dineIn: "0",
    status: "1",
    payment: "0",
    zomatoSwiggyPrice: null,
    discount: "",
    productCode: "",
  });

  const [registerInitialValues, setRegisterInitialValues] = useState({
    id: 0,
    option: "",
    note: "",
    date: new Date(),
    delivery_order_type_amount: 0,
    dieing_order_type_amount: 0,
    other_order_type_amount: 0,
    zomato_order_type_amount: 0,
    swiggy_order_type_amount: 0,
    takeaway_order_type_amount: 0,
    total_settlement_amount: 0,
    total_default_amount: 0,
    total_refund_amount: 0,
  });

  const [disableName, setDisableName] = useState(true);

  const paymentOption = [
    { value: "0", label: t("paymentOption.pending") },
    { value: "1", label: t("paymentOption.UPI") },
    { value: "2", label: t("paymentOption.Card") },
    { value: "3", label: t("paymentOption.Cash") },
    { value: "5", label: t("paymentOption.Zomato") },
    { value: "6", label: t("paymentOption.Swiggy") },
    { value: "4", label: t("paymentOption.Other") },
  ];

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
      payment: Yup.string().required("Payment is required!"),
    })
  );

  // changes by sakshi
  const handleSelectedAttributes = (data) => {
    // Function to check if data is grouped
    const isGrouped = (data) => {
      return (
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length > 0
      );
    };

    // Group data if it's not already grouped
    const groupedAttributes = isGrouped(data)
      ? data
      : data.reduce((acc, attr) => {
          const { cat_name } = attr;

          if (!acc[cat_name]) {
            acc[cat_name] = [];
          }

          acc[cat_name].push(attr);
          return acc;
        }, {});

    return groupedAttributes;
  };

  useEffect(() => {
    dispatch(clearTotalProductData());
    // eslint-disable-next-line
  }, []);

  const fetchVeiwOnlyOrderDetail = () => {
    setDineOptions([
      { value: "0", label: t("dine_in") },
      { value: "1", label: t("take_away") },
      { value: "2", label: t("delivery") },
      { value: "3", label: t("zomato") },
      { value: "4", label: t("swiggy") },
      { value: "5", label: t("other") },
    ]);
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      setServiceCharge(Number(Data.data?.service_charge));
      if (Data.data?.type === "2") {
        setDeliveryCharge(Number(Data.data?.delivery_charge));
      }

      const filteredProductData = Data?.data?.productData?.map(
        (addedProduct) => {
          const product = totalProduct.items[0].data.find(
            (product) => product.productId === addedProduct.productId
          );

          // recent hot bug fix related to pricing 21-11-24
          return {
            ...product,
            quantity: addedProduct.quantity,
            food_type_one:
              addedProduct?.food_type_1 &&
              addedProduct?.food_type_1 !== "" &&
              addedProduct?.food_type_1 !== "null" &&
              addedProduct?.food_type_1 !== "NULL"
                ? addedProduct.food_type_1
                : "0",
            food_type_jain_regular:
              addedProduct?.food_type_2 &&
              addedProduct.food_type_2 !== "" &&
              addedProduct.food_type_2 !== "null" &&
              addedProduct.food_type_2 !== "NULL"
                ? addedProduct.food_type_2
                : "2",
            product_instruction:
              addedProduct?.product_instruction &&
              addedProduct.product_instruction !== "" &&
              addedProduct.product_instruction !== "NULL" &&
              addedProduct.product_instruction !== "null"
                ? addedProduct.product_instruction
                : null,
            // changes by sakshi
            selectedAttributes: addedProduct?.product_attribute
              ? handleSelectedAttributes(addedProduct.product_attribute)
              : null,
            price: product.price,
            map_table_id: addedProduct?.map_table_id
              ? addedProduct.map_table_id
              : 0,
            status: addedProduct.productStatus,
            discountedAmount: Number(Data.data?.total_discount_amount),
            discountCode:
              Data.data?.discount_id &&
              Data.data.discount_id !== "" &&
              Data.data.discount_id !== "null"
                ? Data.data?.Discount_Code
                : 0,
            discountId:
              Data.data?.discount_id &&
              Data.data.discount_id !== "" &&
              Data.data.discount_id !== "null"
                ? Data.data.discount_id
                : 0,
          };
        }
      );

      const updatedAddedProduct = filteredProductData.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes)
            .flat()
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value),
            }));

          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : Data.data.type === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      if (
        Data.data?.discount_id &&
        Data.data.discount_id !== "" &&
        Data.data.discount_id !== "null"
      ) {
        setDiscountAmt(Number(Data.data?.total_discount_amount));
        setDiscountID(Data.data.discount_id);
        setAppliedDiscountCode(Data.data?.Discount_Code);
      }
      setAddedProducts(updatedAddedProduct);

      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.customerName &&
          Data.data.customerName !== "" &&
          Data.data.customerName !== "N/A"
            ? Data.data.first_name
            : "",
        lastName: Data.data.last_name,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Number(Data.data.table_no) : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment,
        zomatoSwiggyPrice: Data.data.zomatoSwiggyPrice,
        discount: Data.data.discount_id,
      });
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      if (Data?.isEdit === true) {
        fetchOrderDetail();
      } else {
        fetchVeiwOnlyOrderDetail();
      }
    } else {
      setDineOptions([
        { value: "0", label: t("dine_in") },
        { value: "1", label: t("take_away") },
        { value: "3", label: t("zomato") },
        { value: "4", label: t("swiggy") },
        { value: "5", label: t("other") },
      ]);
    }
    setColumns([
      {
        name: t("columns.payment_type"),
        selector: (row) => row.payment_type,
      },
      {
        name: t("columns.default_amount"),
        selector: (row) => row.default_amount,
      },
      {
        name: t("columns.settlement_amount"),
        selector: (row) => row.settlement_amount,
      },
    ]);
    setOrderTypeColumns([
      {
        name: t("columns.order_type"),
        selector: (row) => row.order_type,
      },
      {
        name: t("columns.default_amount"),
        selector: (row) => row.default_amount,
      },
    ]);
    // eslint-disable-next-line
  }, [Data, totalProduct, localization]);

  useEffect(() => {
    if (initialValues.dineIn === "0") {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          tableNo: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else if (
      initialValues.dineIn === "1" ||
      initialValues.dineIn === "2" ||
      initialValues.dineIn === "3" ||
      initialValues.dineIn === "4" ||
      initialValues.dineIn === "5"
    ) {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    }
    // eslint-disable-next-line
  }, [initialValues]);

  useEffect(() => {
    if (tableNo && !tableNo.isTakeAway && !Data) {
      setInitialValues({
        mobile: "",
        firstName: "",
        lastName: "",
        tableNo: Number(tableNo.table_no),
        product: "",
        size: "",
        price: "",
        quantity: "",
        dineIn: "0",
        status: "1",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "",
      });
    } else if (tableNo && tableNo.isTakeAway && !Data) {
      setInitialValues({
        mobile: "",
        firstName: "",
        lastName: "",
        tableNo: "",
        product: "",
        size: "",
        price: "",
        quantity: "",
        dineIn: tableNo.takeAway,
        status: "1",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "",
      });
    }
    // eslint-disable-next-line
  }, [tableNo, Data]);

  const fetchOrderDetail = async () => {
    if (Data.data.type === "2" || Data.data.type === 2) {
      setDineOptions([
        { value: "0", label: t("dine_in") },
        { value: "1", label: t("take_away") },
        { value: "2", label: t("delivery") },
        { value: "3", label: t("zomato") },
        { value: "4", label: t("swiggy") },
        { value: "5", label: t("other") },
      ]);
    } else {
      setDineOptions([
        { value: "0", label: t("dine_in") },
        { value: "1", label: t("take_away") },
        { value: "3", label: t("zomato") },
        { value: "4", label: t("swiggy") },
        { value: "5", label: t("other") },
      ]);
    }
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      let discountOnEdit = { status: "Fail" };
      let discountApplyBool = false;
      if (
        Data.data?.discount_id &&
        Data.data?.discount_id !== "" &&
        Data.data?.discount_id !== "NULL" &&
        Data.data?.discount_id !== "Null" &&
        Data.data?.discount_id !== "null"
      ) {
        discountOnEdit = await getDiscountById(
          Data.data.discount_id,
          token,
          dispatch,
          true
        );

        if (
          discountOnEdit?.status &&
          (discountOnEdit.status === "success" ||
            discountOnEdit.status === "200" ||
            discountOnEdit.status === 200)
        ) {
          const currentDate = new Date();
          const endDate = new Date(discountOnEdit?.data?.end_date);
          if (endDate > currentDate) {
            discountApplyBool = true;
            setAppliedDiscountCode(Data.data.Discount_Code);
            setDiscountAmt(Data.data.total_discount_amount);

            setDiscountID(Data.data.discount_id);
            setDiscountDetail(discountOnEdit?.data || null);
          } else {
            if (!document.querySelector(toastClass)) {
              toast.warn(
                "Discount code (" + Data.data.Discount_Code + ") is expired!"
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.warn(
              "Discount code (" +
                Data.data.Discount_Code +
                ") is no more applicable!"
            );
          }
        }
      }

      setDeliveryCharge(
        Data.data?.delivery_charge ? Number(Data.data.delivery_charge) : 0
      );
      const filteredProductData = Data?.data?.productData?.map(
        (addedProduct) => {
          const product = totalProduct.items[0].data.find(
            (product) => product.productId === addedProduct.productId
          );

          // recent hot bug fix related to pricing 21-11-24
          return {
            ...product,
            quantity: addedProduct.quantity,
            food_type_one:
              addedProduct?.food_type_1 &&
              addedProduct?.food_type_1 !== "" &&
              addedProduct?.food_type_1 !== "null" &&
              addedProduct?.food_type_1 !== "NULL"
                ? addedProduct.food_type_1
                : "0",
            food_type_jain_regular:
              addedProduct?.food_type_2 &&
              addedProduct.food_type_2 !== "" &&
              addedProduct.food_type_2 !== "null" &&
              addedProduct.food_type_2 !== "NULL"
                ? addedProduct.food_type_2
                : "2",
            product_instruction:
              addedProduct?.product_instruction &&
              addedProduct.product_instruction !== "" &&
              addedProduct.product_instruction !== "NULL" &&
              addedProduct.product_instruction !== "null"
                ? addedProduct.product_instruction
                : null,
            // changes by sakshi
            selectedAttributes: addedProduct?.product_attribute
              ? handleSelectedAttributes(addedProduct.product_attribute)
              : null,
            price: product?.price,
            map_table_id: addedProduct?.map_table_id
              ? addedProduct.map_table_id
              : 0,
            status: addedProduct.productStatus,
          };
        }
      );

      const updatedAddedProduct = filteredProductData.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes) // Get all values (arrays) of the object
            .flat() // Flatten the arrays into one single array
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value), // Convert 'value' to a number
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : Data.data.type === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });
      if (Data.data.discount_id && discountApplyBool) {
        onSelectDiscount(
          Data.data.discount_id,
          false,
          false,
          updatedAddedProduct
        );
      } else {
        setAddedProducts(updatedAddedProduct);
      }

      setMessage(Data.data.instructions);
      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.customerName &&
          Data.data.customerName !== "" &&
          Data.data.customerName !== "N/A"
            ? Data.data.first_name
            : "",
        lastName: Data.data.last_name,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Number(Data.data.table_no) : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment,
        zomatoSwiggyPrice: Data.data.zomatoSwiggyPrice,
        discount: discountApplyBool ? Data.data.discount_id : "",
      });
    } else {
      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.customerName &&
          Data.data.customerName !== "" &&
          Data.data.customerName !== "N/A"
            ? Data.data.first_name
            : "",
        lastName: Data.data.last_name,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Data.data.table_no : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "0",
      });
      setUpdatedTotalAmt(0);
    }
    if (
      Data.data?.phoneNumber &&
      Data.data.phoneNumber !== "" &&
      Data.data.phoneNumber !== "N/A"
    ) {
      setDisableName(false);
    }
  };

  const changeDineInData = (value, setFieldValue) => {
    if (value === "0") {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          tableNo: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
          payment: Yup.string().required("Payment is required!"),
        })
      );
      setFieldValue("tableNo", "");
    }
    if (tableOptions.length === 0 && value === "0") {
      toast.error("All Tables are occupied!");
    }
    setFieldValue("dineIn", value);
    setInitialValues((prevState) => ({
      ...prevState,
      dineIn: value,
    }));
    if (addedProducts && addedProducts.length > 0) {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });
        // Set the updated data
        setProductOptions(updatedData);
      }

      const updatedAddedProduct = addedProducts.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }

          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          const flattenedData = Object.values(item.selectedAttributes)
            .flat()
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value),
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      if (discountID) {
        onSelectDiscount(discountID, setFieldValue, true, updatedAddedProduct);
      } else {
        setAddedProducts(updatedAddedProduct);
        const totalAmount = updatedAddedProduct.reduce(
          (sum, product) =>
            sum + Number(product.newPrice) * Number(product.quantity),
          0
        );
        setTotalAmount(totalAmount);
        setUpdatedTotalAmt(totalAmount - discountAmt);
      }
    } else {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      }
      setUpdatedTotalAmt(0);
    }
  };

  useEffect(() => {
    if (
      (totalProduct && totalProduct.items && totalProduct.items.length <= 0) ||
      !totalProduct
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 5000000,
        p_is_active: "1",
      };
      getAllProductService(requestBody, token, dispatch, true);
    }
    // eslint-disable-next-line
  }, [totalProduct]);

  useEffect(() => {
    if (!discountID) {
      const productData = addedProducts.map((item) => ({
        ...item,
        discountedAmount: 0,
        discountId: 0,
        discountCode: "",
      }));
      setAddedProducts(productData);
    }
    // eslint-disable-next-line
  }, [discountID, Data]);

  useEffect(() => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      if (addedProducts && addedProducts.length > 0) {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });
          // Set the updated data
          setProductOptions(updatedData);
        }

        // const totalAmount = addedProducts.reduce(
        //   (sum, product) =>
        //     sum + Number(product.newPrice) * Number(product.quantity),
        //   0
        // );
        // setTotalAmount(totalAmount);
        // setUpdatedTotalAmt(totalAmount - discountAmt);
      } else {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          setProductOptions(updatedData);
        }
        // setUpdatedTotalAmt(0);
      }
    } else {
      setProductOptions([]);
    }

    // eslint-disable-next-line
  }, [totalProduct, addedProducts, inputData]);

  const onChangeTableNo = (value, setFieldValue, values) => {
    if (value === "") {
      if (initialValues.dineIn === "0") {
        setValidationSchema(
          Yup.object({
            dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
            tableNo: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      } else {
        setValidationSchema(
          Yup.object({
            dineIn: Yup.string().required(t(ValidationMessage.FIELD_REQUIRED)),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      }
    }
  };
  const handlePaymentValidation = (option, setFieldValue, values) => {
    setFieldValue("zomatoSwiggyPrice", "");
  };

  const handlePhoneChange = (event, setFieldValue) => {
    const newValue = event.target.value.replace(/[^0-9\s]/g, "");
    if (newValue) {
      const trimmedValue = newValue.slice(0, 10);
      if (trimmedValue.length === 10) {
        setDisableName(false);
        getUserDetailService(trimmedValue, dispatch).then((res) => {
          if (res && [200, "200", "success"].includes(res.status)) {
            setFieldValue("firstName", res.data.firstName);
            setFieldValue("lastName", res.data.lastName);
          } else {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
          }
        });
      } else {
        setDisableName(true);
        setFieldValue("firstName", "");
        setFieldValue("lastName", "");
      }
    } else {
      setDisableName(true);
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
    }
  };

  useEffect(() => {
    if (addedProducts && addedProducts.length > 0) {
      const total = addedProducts.reduce(
        (sum, product) => sum + product.newPrice * product.quantity,
        0
      );
      const totalTax = addedProducts.reduce((sum, product) => {
        const price = Number(product?.newPrice) || 0;
        const quantity = Number(product?.quantity) || 0;
        const discountedAmount = Number(product?.discountedAmount) || 0;
        const cgst = Number(product?.cgst) || 0;
        const sgst = Number(product?.sgst) || 0;
        const vat = Number(product?.vat) || 0;
        const taxType = authuserData?.data?.branchTaxType;

        const applicableTaxRate =
          taxType === "GST" ? cgst + sgst : taxType === "VAT" ? vat : 0;

        const taxableAmount = price * quantity - discountedAmount;
        const taxForProduct =
          taxableAmount > 0 ? (taxableAmount * applicableTaxRate) / 100 : 0;

        return sum + taxForProduct;
      }, 0);
      setTaxAmount(totalTax.toFixed(2));

      let discount = getDiscountDetail(discountID, discountDetail);

      const deliveryData = deliveryCharge;
      const finalTotal = total + totalTax + deliveryData - discount;
      setTotalAmount(Number(Number(finalTotal).toFixed(2)));
      setUpdatedTotalAmt(Number(Number(finalTotal).toFixed(2)));
      setDeliveryCharge(deliveryCharge);
    } else {
      setTotalAmount(0);
      setUpdatedTotalAmt(0);
      setTaxAmount(0);
      setDeliveryCharge(deliveryCharge);
      setDiscountAmt(0);
      setDiscountID(null);
    }
    // eslint-disable-next-line
  }, [addedProducts]);

  const getDiscountDetail = (discountID, discountDetail) => {
    if (discountID && discountID > 0 && discountDetail) {
      let eligibleTotal = 0;
      let totalCartAmount = 0;

      addedProducts.forEach((product) => {
        totalCartAmount += product.newPrice * product.quantity;

        if (isProductEligible(product, discountDetail)) {
          eligibleTotal += product.newPrice * product.quantity;
        }
      });

      if (discountDetail?.spacific_item === "0") {
        if (totalCartAmount >= discountDetail?.min_cart_value) {
          const discountAmount =
            discountDetail?.discount_type === "1"
              ? (totalCartAmount * Number(discountDetail?.discount_amount)) /
                100
              : Number(discountDetail?.discount_amount);

          let newDiscount =
            discountDetail?.discount_type === "1"
              ? Math.min(
                  discountAmount,
                  Number(discountDetail?.max_discount_ammount)
                )
              : discountAmount;
          setDiscountAmt(newDiscount);
          return newDiscount;
        } else {
          toast.warn(
            `Add more products to apply the discount. Minimum cart value: ${
              authuserData?.data?.symbol || "₹"
            }${discountDetail?.min_cart_value}!`
          );
          setAppliedDiscountCode(null);
          setDiscountAmt(0);
          setDiscountDetail(null);
          setDiscountID(null);
          return 0;
        }
      } else if (eligibleTotal < discountDetail.min_cart_value) {
        toast.warn(
          "Not enough eligible products in cart to apply the discount!"
        );
        setAppliedDiscountCode(null);
        setDiscountAmt(0);
        setDiscountDetail(null);
        setDiscountID(null);
        return 0;
      } else {
        const discountAmount =
          discountDetail?.discount_type === "1"
            ? (eligibleTotal * Number(discountDetail?.discount_amount)) / 100
            : Number(discountDetail?.discount_amount);

        let newDiscount =
          discountDetail?.discount_type === "1"
            ? Math.min(
                discountAmount,
                Number(discountDetail?.max_discount_ammount)
              )
            : discountAmount;

        setDiscountAmt(newDiscount);
        return newDiscount;
      }
    } else {
      return 0;
    }
  };

  /**
   * Set discount
   * @param {*} value
   * @param {*} setFieldValue
   * @returns
   */

  const onSelectDiscount = async (
    value,
    setFieldValue,
    boolValue,
    newCartProducts
  ) => {
    try {
      const viewCartProducts = newCartProducts
        ? newCartProducts
        : addedProducts;
      if (viewCartProducts.length > 0) {
        let newData = {};
        if (discountDetail && discountID && discountID === value) {
          newData = {
            status: "success",
            data: discountDetail,
          };
        }
        const discountData =
          discountDetail && discountID && discountID === value
            ? newData
            : await getDiscountById(value, token, dispatch);
        if (
          discountData?.status &&
          (discountData.status === 200 ||
            discountData.status === "200" ||
            discountData.status === "success" ||
            discountData.status === "Success")
        ) {
          let eligibleTotal = 0;
          let totalCartAmount = 0;
          // let eligibleLength = 0;

          viewCartProducts.forEach((product) => {
            totalCartAmount += product.newPrice * product.quantity;

            if (isProductEligible(product, discountData.data)) {
              eligibleTotal += product.newPrice * product.quantity;
            }
          });

          if (discountData.data?.spacific_item === "0") {
            if (totalCartAmount >= discountData.data?.min_cart_value) {
              const discountAmount =
                discountData.data.discount_type === "1"
                  ? (totalCartAmount *
                      Number(discountData.data.discount_amount)) /
                    100
                  : Number(discountData.data.discount_amount);
              let newDiscount =
                discountData.data.discount_type === "1"
                  ? Math.min(
                      discountAmount,
                      Number(discountData.data.max_discount_ammount)
                    )
                  : discountAmount;

              const branchProductsWithDiscount = viewCartProducts.map(
                (product) => {
                  const productTotal = product.newPrice * product.quantity;
                  const productDiscount =
                    (productTotal / totalCartAmount) * newDiscount;
                  return {
                    ...product,
                    discountedAmount: productDiscount,
                    discountCode:
                      productDiscount > 0 ? discountData.data.code : "",
                    discountId: productDiscount > 0 ? discountData.data.id : 0,
                  };
                }
              );

              setAddedProducts(branchProductsWithDiscount);
              setDiscountAmt(newDiscount);
              if (boolValue) {
                setTotalAmount(totalAmountData - newDiscount);
                setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
                setAppliedDiscountCode(discountData.data?.code);
                setDiscountDetail(discountData.data);
                setDiscountID(discountData.data?.id || null);
                setFieldValue("discount", discountData.data?.id || "");
              }
            } else {
              if (boolValue) {
                toast.warn(
                  `Add more products to apply the discount. Minimum cart value: ${
                    authuserData?.data?.symbol || "₹"
                  }${discountData.data?.min_cart_value}`
                );
              }
              if (setFieldValue) {
                setFieldValue("discount", "");
              }
            }
          } else if (eligibleTotal < discountData.data.min_cart_value) {
            if (boolValue) {
              toast.warn(
                "Not enough eligible products in cart to apply the discount!"
              );
            }
            if (setFieldValue) {
              setFieldValue("discount", "");
            }
          } else {
            const discountAmount =
              discountData.data.discount_type === "1"
                ? (eligibleTotal * Number(discountData.data.discount_amount)) /
                  100
                : Number(discountData.data.discount_amount);

            let newDiscount =
              discountData.data.discount_type === "1"
                ? Math.min(
                    discountAmount,
                    Number(discountData.data.max_discount_ammount)
                  )
                : discountAmount;

            let cartProducts = viewCartProducts.map((product) => {
              let discountedAmount = 0;
              if (
                eligibleTotal >= discountData.data.min_cart_value ||
                totalCartAmount >= discountData.data.min_cart_value
              ) {
                if (isProductEligible(product, discountData.data)) {
                  const productTotal = product.newPrice * product.quantity;
                  discountedAmount =
                    (productTotal / eligibleTotal) * newDiscount;
                }
              }
              return {
                ...product,
                discountedAmount:
                  discountedAmount > 0
                    ? Number(Number(discountedAmount).toFixed(2))
                    : 0,
                discountCode:
                  discountedAmount > 0 ? discountData.data.code : "",
                discountId: discountedAmount > 0 ? discountData.data.id : 0,
              };
            });
            setAddedProducts(cartProducts);
            setDiscountAmt(newDiscount);
            if (boolValue) {
              setTotalAmount(totalAmountData - newDiscount);
              setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
              setAppliedDiscountCode(discountData.data?.code);
              setDiscountDetail(discountData.data);
              setDiscountID(discountData.data?.id || null);
              setFieldValue("discount", discountData.data?.id || "");
            }
          }
        }
      } else {
        if (boolValue) {
          toast.warn("Cart is empty!");
        }
        if (setFieldValue) {
          setFieldValue("discount", "");
        }
        setUpdatedTotalAmt(0);
        setTotalAmount(0);
        setDiscountDetail(null);
        setDiscountID(null);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleRemoveDiscount = (setFieldValue) => {
    setFieldValue("discount", "");
    setTotalAmount(totalAmountData + discountAmt);
    setUpdatedTotalAmt(updatedTotalAmt + discountAmt);
    setDiscountDetail(null);
    setDiscountAmt(0);
    setDiscountID(null);
    const updatedProduct = addedProducts.map((product) => {
      return {
        ...product,
        discountedAmount: 0,
        discountId: 0,
        discountCode: "0",
      };
    });
    setAddedProducts(updatedProduct);
  };

  const handleUserDetailModal = () => {
    setUserDetailModal(!userDetailsModal);
  };

  const fetchSpot = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
      is_active: "1",
    };
    getAllSpotService(requestBody, token, dispatch);
  };
  const order = useSelector((state) => state.order);

  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (spotData.items[0]?.data && spotData.items[0].data.length > 0) {
        const newSpotData = spotData.items[0].data
          .map((spot) => {
            const matchingOrder = order.items.filter((order) => {
              // Check if Data.data.table_no is present, if it is, exclude those orders
              const isOrderExcluded = Data?.data.table_no
                ? Number(order.table_no) === Number(Data.data.table_no)
                : false;

              // Return true only if the order should not be excluded
              return (
                Number(order.table_no) === Number(spot.id) && !isOrderExcluded
              );
            });

            const matchComplete =
              matchingOrder && matchingOrder.length > 0
                ? matchingOrder.filter(
                    (item) => item.status !== "3" && item.status !== "4"
                  )
                : [];

            if (matchComplete.length === 0) {
              return {
                value: spot.id,
                label: spot.name,
              };
            }
            return null;
          })
          .filter((spot) => spot !== null);
        setTableOptions(newSpotData);
        // After setting the tableOptions, check if it's empty and show a toast

        if (newSpotData.length === 0) {
          toast.error("All Tables are occupied!");
        }
      } else {
        setTableOptions([]);
      }
    }
    // eslint-disable-next-line
  }, [spotData]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const handleSubmit = (values) => {
    if (addedProducts.length < 1) {
      toast.error("Please add product!");
      return;
    }

    if (values.mobile && values.mobile !== "") {
      const isValidLength = values.mobile.length === 10;
      const startsWithValidDigit = /^[6789]/.test(values.mobile);
      if (!isValidLength || !startsWithValidDigit) {
        toast.error(
          "Please add a valid 10-digit mobile number starting with 6, 7, 8, or 9!"
        );
        return;
      }
    }

    const requestBody = addUpdateOrderRequest(
      addedProducts,
      values,
      Data,
      deliveryCharge,
      discountAmt,
      discountID,
      appliedDiscountCode,
      message,
      updatedTotalAmt,
      taxAmount
    );
    if (Data && Data.data) {
      handlesaveClick(
        dispatch,
        placeOrderService(
          requestBody,
          token,
          "update",
          navigate,
          orderPage,
          false
        ),
        "update"
      );
    } else {
      dispatch(placeOrderService(requestBody, token, "add", navigate));
    }
  };
  const inputRef = useRef(null);

  const handleAddedProduct = (values, setFieldValue) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (values.productCode !== "") {
      const product = productOptions.find((item) => {
        const itemLastPart = item.skuCode.split("-").pop();
        return itemLastPart === values.productCode;
      });
      if (product) {
        const existingProduct = addedProducts.find(
          (item) => item.productId === product.productId
        );
        if (!product.attribute_data) {
          if (existingProduct) {
            const updatedProducts = addedProducts.map((item) =>
              item.productId === product.productId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
            setAddedProducts(updatedProducts);
            toast.success(t("Product_quantity_updated"));
          } else {
            // If it doesn't exist, add it
            const newProduct = {
              ...product,
              quantity: 1,
              food_type_jain_regular: "2",
              product_instruction: "",
              price: product.price,
              map_table_id:
                addedProducts.length > 0 ? addedProducts.length - 1 : 0,
              status: "0",
            };
            setAddedProducts((prevProducts) => [...prevProducts, newProduct]);
            toast.success("Product added!");
          }
        } else {
          openModal(product);
        }
      } else {
        toast.error(t("no_product_found_code"));
      }
      setFieldValue("productCode", "");
    } else {
      toast.error(t("Enter_SKU_Code"));
    }
  };

  const handleKeyPress = (event, values, setFieldValue) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (values.productCode === "") {
        toast.error(t("Enter_SKU_Code"));
      } else {
        const product = productOptions.find((item) => {
          const itemLastPart = item.skuCode.split("-").pop();
          return itemLastPart === values.productCode;
        });

        if (product) {
          if (product.attribute_data) {
            // Open modal if product has attributes
            openModal(product);
          } else {
            // Handle adding product directly
            handleAddedProduct(values, setFieldValue);
          }
        } else {
          toast.error(t("no_product_found_code"));
        }

        // Clear the input field after processing
        setFieldValue("productCode", "");
      }
    }
  };

  // logic for handling close and view register

  //  converting data for custom table
  useEffect(() => {
    if (settlement && settlement.items && settlement.items[0]?.data) {
      const data = settlement.items[0].data.default_data;
      const settlementNewData = settlement.items[0].data.settelment_data;
      // Transformed data
      const transformedData = [
        {
          payment_type: "UPI",
          default_amount: data.total_sum_of_upi_default_amount
            ? parseFloat(data.total_sum_of_upi_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_upi_settlement_amount
              ? parseFloat(settlementNewData.total_sum_of_upi_settlement_amount)
              : data.total_sum_of_upi_default_amount
              ? parseFloat(data.total_sum_of_upi_default_amount)
              : 0,
        },
        {
          payment_type: "Pending",
          default_amount: data.total_sum_of_pending_default_amount
            ? parseFloat(data.total_sum_of_pending_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_pending_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_pending_settlement_amount
                )
              : data.total_sum_of_pending_default_amount
              ? parseFloat(data.total_sum_of_pending_default_amount)
              : 0,
        },
        {
          payment_type: "Card",
          default_amount: data.total_sum_of_card_default_amount
            ? parseFloat(data.total_sum_of_card_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_card_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_card_settlement_amount
                )
              : data.total_sum_of_card_default_amount
              ? parseFloat(data.total_sum_of_card_default_amount)
              : 0,
        },
        {
          payment_type: "Cash",
          default_amount: data.total_sum_of_cash_default_amount
            ? parseFloat(data.total_sum_of_cash_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_cash_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_cash_settlement_amount
                )
              : data.total_sum_of_cash_default_amount
              ? parseFloat(data.total_sum_of_cash_default_amount)
              : 0,
        },
        {
          payment_type: "Other",
          default_amount: data.total_sum_of_other_default_amount
            ? parseFloat(data.total_sum_of_other_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_other_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_other_settlement_amount
                )
              : data.total_sum_of_other_default_amount
              ? parseFloat(data.total_sum_of_other_default_amount)
              : 0,
        },
        {
          payment_type: "Zomato",
          default_amount: data.total_sum_of_zomato_default_amount
            ? parseFloat(data.total_sum_of_zomato_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_zomato_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_zomato_settlement_amount
                )
              : data.total_sum_of_zomato_default_amount
              ? parseFloat(data.total_sum_of_zomato_default_amount)
              : 0,
        },
        {
          payment_type: "Swiggy",
          default_amount: data.total_sum_of_swiggy_default_amount
            ? parseFloat(data.total_sum_of_swiggy_default_amount)
            : 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_swiggy_settlement_amount
              ? parseFloat(
                  settlementNewData.total_sum_of_swiggy_settlement_amount
                )
              : data.total_sum_of_swiggy_default_amount
              ? parseFloat(data.total_sum_of_swiggy_default_amount)
              : 0,
        },
        {
          payment_type: "Refund",
          default_amount: 0,
          settlement_amount:
            settlementNewData.length !== 0 &&
            settlementNewData.total_sum_of_total_refund_amount
              ? parseFloat(settlementNewData.total_sum_of_total_refund_amount)
              : 0,
        },
      ];

      const transformedOrderData = [
        {
          order_type: "Dine In",
          default_amount: data.total_sum_of_dieing_order_type_default_amount
            ? parseFloat(data.total_sum_of_dieing_order_type_default_amount)
            : 0,
        },
        {
          order_type: "Delivery",
          default_amount: data.total_sum_of_delivery_order_type_default_amount
            ? parseFloat(data.total_sum_of_delivery_order_type_default_amount)
            : 0,
        },
        {
          order_type: "Take Away",
          default_amount: data.total_sum_of_takeaway_order_type_default_amount
            ? parseFloat(data.total_sum_of_takeaway_order_type_default_amount)
            : 0,
        },
        {
          order_type: "Zomato",
          default_amount: data.total_sum_of_zomato_order_type_default_amount
            ? parseFloat(data.total_sum_of_zomato_order_type_default_amount)
            : 0,
        },
        {
          order_type: "Swiggy",
          default_amount: data.total_sum_of_swiggy_order_type_default_amount
            ? parseFloat(data.total_sum_of_swiggy_order_type_default_amount)
            : 0,
        },
        {
          order_type: "Other",
          default_amount: data.total_sum_of_other_order_type_default_amount
            ? parseFloat(data.total_sum_of_other_order_type_default_amount)
            : 0,
        },
      ];

      setOrderTypeSettlementData(transformedOrderData);
      // Set transformed data
      setSettlementData(transformedData);

      // Update the registerInitialValues state with transformed data
      setRegisterInitialValues((prev) => ({
        ...prev,
        // Set values for respective keys
        id:
          settlementNewData.length !== 0 && settlementNewData.id
            ? settlementNewData.id
            : 0,
        note:
          settlementNewData.length !== 0 && settlementNewData.notes
            ? settlementNewData.notes
            : "",
        delivery_order_type_amount:
          data.total_sum_of_delivery_order_type_default_amount
            ? parseFloat(data.total_sum_of_delivery_order_type_default_amount)
            : 0,
        dieing_order_type_amount:
          data.total_sum_of_dieing_order_type_default_amount
            ? parseFloat(data.total_sum_of_dieing_order_type_default_amount)
            : 0,
        other_order_type_amount:
          data.total_sum_of_other_order_type_default_amount
            ? parseFloat(data.total_sum_of_other_order_type_default_amount)
            : 0,
        zomato_order_type_amount:
          data.total_sum_of_zomato_order_type_default_amount
            ? parseFloat(data.total_sum_of_zomato_order_type_default_amount)
            : 0,
        swiggy_order_type_amount:
          data.total_sum_of_swiggy_order_type_default_amount
            ? parseFloat(data.total_sum_of_swiggy_order_type_default_amount)
            : 0,
        takeaway_order_type_amount:
          data.total_sum_of_takeaway_order_type_default_amount
            ? parseFloat(data.total_sum_of_takeaway_order_type_default_amount)
            : 0,
      }));
    }
  }, [settlement]);

  useEffect(() => {
    if (settlementData.length > 0) {
      const settlementNewData = settlement.items[0].data.settelment_data;
      setRegisterInitialValues((prev) => ({
        ...prev,
        // Set values for respective keys
        total_default_amount:
          settlementNewData.length !== 0 &&
          settlementData.total_sum_of_total_sale_amount
            ? parseFloat(settlementData.total_sum_of_total_sale_amount)
            : transformedRegisterData.total_default_amount.toFixed(2),
        total_settlement_amount:
          settlementNewData.length !== 0 &&
          settlementData.total_sum_of_final_amount
            ? parseFloat(settlementData.total_sum_of_final_amount)
            : transformedRegisterData.total_settlement_amount !== "" &&
              transformedRegisterData.total_settlement_amount
            ? parseFloat(
                transformedRegisterData.total_settlement_amount
              ).toFixed(2)
            : 0,
        total_refund_amount:
          settlementNewData.length !== 0 &&
          settlementData.total_sum_of_total_refund_amount
            ? parseFloat(settlementData.total_sum_of_total_refund_amount)
            : transformedRegisterData.total_refund_amount.toFixed(2),
      }));
    }
    // eslint-disable-next-line
  }, [settlementData]);

  // handle modal of register
  const handleRegisterModal = () => {
    setSettleMentModal(false);
    setRegisterInitialValues((prev) => ({
      ...prev,
      option: "",
      // date: new Date(),
    }));
  };

  const formattedDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  // handle api get call on view and close register
  const handleRegister = (value) => {
    setSettleMentModal(!settlementModal);
    if (selectRef.current) {
      selectRef.current.blur(); // Remove focus when modal is triggered
    }

    setRegisterInitialValues((prev) => ({
      ...prev,
      option: value,
    }));

    // get api call

    const requestBody = {
      p_settelment_date: formattedDate(registerInitialValues.date), // Use the formatted date
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    getSettlementService(requestBody, token, dispatch);
  };

  // handle date change
  const handleRegisterDate = (value) => {
    setRegisterInitialValues((prev) => ({
      ...prev,
      date: value,
    }));
  };

  // handleSettlement input update from custom table values
  const handleUpdateItem = (updatedItem) => {
    setSettlementData((prevData) =>
      prevData.map((item) =>
        item.payment_type === updatedItem.payment_type ? updatedItem : item
      )
    );
  };

  // transformed data to final values and getting default total and settelment total
  const transformedRegisterData =
    settlementData.length > 0 &&
    settlementData.reduce(
      (acc, { payment_type, default_amount, settlement_amount }) => {
        const formattedPaymentType = payment_type.replace(/\s+/g, "_");

        // Adding default_amount and settlement_amount to the accumulator
        acc[`default_${formattedPaymentType}_amount`] = default_amount;
        acc[`settlement_${formattedPaymentType}_amount`] = settlement_amount;

        // Summing up default_amount and settlement_amount
        acc.total_default_amount =
          (acc.total_default_amount || 0) + default_amount;

        // Only add settlement_amount if it's not an empty string
        if (settlement_amount !== "" && payment_type !== "Refund") {
          acc.total_settlement_amount =
            (parseFloat(acc.total_settlement_amount) || 0) +
            parseFloat(settlement_amount);
        } else if (settlement_amount !== "" && payment_type === "Refund") {
          acc.total_settlement_amount =
            (parseFloat(acc.total_settlement_amount) || 0) -
            parseFloat(settlement_amount);
        }

        if (payment_type === "Refund") {
          acc.total_refund_amount =
            settlement_amount &&
            settlement_amount !== "" &&
            settlement_amount !== "0"
              ? parseFloat(settlement_amount)
              : 0;
        }
        return acc;
      },
      {}
    );

  const handleSettlementPrint = (data) => {
    const {
      companyName,
      companyAddress,
      firstName,
      lastName,
      companyPhneNumber,
      branchFSSAI_licence_number,
    } = authuserData.data;

    const tableRows = orderTypeSettlementData
      .map(
        (item) => `
        <tr>
          <td>${item.order_type}</td>
          <td>${parseFloat(item.default_amount).toFixed(2)}</td>
        </tr>`
      )
      .join("");

    const paymentRows = settlementData
      .map(
        (item) => `
        <tr>
          <td>${item.payment_type}</td>
          <td>${parseFloat(item.default_amount).toFixed(2)}</td>
          <td>${parseFloat(item.settlement_amount).toFixed(2)}</td>
        </tr>`
      )
      .join("");

    const htmlContent = `
    <html>
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Invoice</title>
      <style>
        body { font-family: Arial, sans-serif; font-size: 10px; margin: 0; padding: 20px; background-color: #f4f4f4; }
        .invoice { width: 80mm; background-color: #ffffff; padding: 10px; margin: 0 auto; }
        .header, .footer { text-align: center; margin-bottom: 10px; }
        .header strong, .footer { font-size: 14px; }
        .header p, .footer p { margin: 2px 0; }
        .header { border-bottom: 2px solid #000; padding-bottom: 10px; }
        .section { margin: 10px 0; padding-bottom: 10px; border-bottom: 2px solid #000; }
        .section p { margin: 2px 0; }
        .table { width: 100%; border-collapse: collapse; margin-top: 20px; }
        .table, .table th, .table td { border-bottom: 2px solid #000; padding: 5px; text-align: left; font-size: 14px }
        .total-section { margin-top: 10px; }
        .total-section .row { display: flex; justify-content: space-between; font-weight: bold; width:100%; flex-direction:row; }
        .thank-you { text-align: center; margin-top: 15px; }
        .thank-you p { margin: 0; }
        .footer{ border-top: 2px solid #000; margin-top: 10px; }
        .summary{ font-size: 12px }
      </style>
    </head>
    <body>
      <div class="invoice">
        <div class="header">
          <p><strong>${companyName}</strong></p>
          <p>${companyAddress}</p>
          <p>Mob - ${companyPhneNumber}</p>
        </div>
        <div class="section">
          <p><strong>Date:</strong> ${data.p_settlement_date}</p>
          <p><strong>Settled By:</strong> ${firstName} ${lastName}</p>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Order Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>

        <table class="table">
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Amount</th>
               <th>Settled Amount</th>
            </tr>
          </thead>
          <tbody>
          ${paymentRows}
          </tbody>
        </table>
        <div class="total-section">
        <div class="row">
            <span class="summary">Total Refund Amount:</span>
            <span class="summary">${
              registerInitialValues.total_refund_amount
            }</span>
          </div>
         <div class="row">
            <span class="summary">Total Amount:</span>
            <span class="summary">${
              registerInitialValues.total_default_amount
            }</span>
          </div>
          <div class="row">
            <span class="summary">Total Settled Amount:</span>
            <span class="summary">${
              registerInitialValues.total_settlement_amount
            }</span>
          </div>
        </div>
        <div class="footer">
          ${
            branchFSSAI_licence_number &&
            branchFSSAI_licence_number !== "N/A" &&
            branchFSSAI_licence_number !== "" &&
            branchFSSAI_licence_number !== null &&
            branchFSSAI_licence_number !== "null"
              ? `<p>FSSAI Lic No. ${branchFSSAI_licence_number}</p>`
              : ""
          }
          <p>Thanks for closing day!</p>
        </div>
      </div>
    </body>
    </html>
  `;
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };

  // function for handle close register's add and update
  const handleRegisterSubmit = (values, type) => {
    const requestBody = {
      p_id: registerInitialValues.id,
      p_settlement_date: formattedDate(registerInitialValues.date),
      p_pending_default_amount: transformedRegisterData.default_Pending_amount,
      p_pending_settlement_amount:
        transformedRegisterData.settlement_Pending_amount,
      p_upi_default_amount: transformedRegisterData.default_UPI_amount,
      p_upi_settlement_amount: transformedRegisterData.settlement_UPI_amount,
      p_card_default_amount: transformedRegisterData.default_Card_amount,
      p_card_settlement_amount: transformedRegisterData.settlement_Card_amount,
      p_cash_default_amount: transformedRegisterData.default_Cash_amount,
      p_cash_settlement_amount: transformedRegisterData.settlement_Cash_amount,
      p_other_default_amount: transformedRegisterData.default_Other_amount,
      p_other_settlement_amount:
        transformedRegisterData.settlement_Other_amount,
      p_zomato_default_amount: transformedRegisterData.default_Zomato_amount,
      p_zomato_settlement_amount:
        transformedRegisterData.settlement_Zomato_amount,
      p_swiggy_default_amount: transformedRegisterData.default_Swiggy_amount,
      p_swiggy_settlement_amount:
        transformedRegisterData.settlement_Swiggy_amount,
      p_dieing_order_type_default_amount:
        registerInitialValues.dieing_order_type_amount,
      p_takeaway_order_type_default_amount:
        registerInitialValues.takeaway_order_type_amount,
      p_delivery_order_type_default_amount:
        registerInitialValues.delivery_order_type_amount,
      p_zomato_order_type_default_amount:
        registerInitialValues.zomato_order_type_amount,
      p_swiggy_order_type_default_amount:
        registerInitialValues.swiggy_order_type_amount,
      p_other_order_type_default_amount:
        registerInitialValues.other_order_type_amount,
      p_total_sale_amount: registerInitialValues.total_default_amount,
      p_total_refund_amount: registerInitialValues.total_refund_amount,
      p_final_amount: registerInitialValues.total_settlement_amount,
      p_notes: values.note ? values.note : "",
    };

    // return
    if (values.option === "0" || type === "print") {
      handleSettlementPrint(requestBody);
    } else {
      if (registerInitialValues.id !== 0 && registerInitialValues.id !== "0") {
        handlesaveClick(
          dispatch,
          addUpdateSettlementService(
            requestBody,
            token,
            "update",
            navigate
            // handleSettlementPrint
          )
        );
      } else {
        dispatch(
          addUpdateSettlementService(requestBody, token, "add", navigate)
        ).then(() => {
          handleSettlementPrint(requestBody);
        });
      }
      // handleSettlementPrint(requestBody);
    }

    setSettleMentModal(false);
    setRegisterInitialValues((prev) => ({
      ...prev,
      option: "",
      // date: new Date(),
    }));
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content table-body-content-new-2">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {orderActionData && orderActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          orderActionData[0].name,
                          localisation
                        )
                      )
                    : t("Order")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/orders")}>
                  {orderActionData && orderActionData.length > 0
                    ? getRouteNameFromSidebar(
                        orderActionData[0].name,
                        localisation
                      )
                    : t("Orders")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {orderActionData && orderActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          orderActionData[0].name,
                          localisation
                        )
                      )
                    : t("Order")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Formik
              initialValues={registerInitialValues}
              enableReinitialize={true}
              onSubmit={handleRegisterSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form style={{ width: "420px" }}>
                  {settlementModal && (
                    <div
                      className="modal-overlay"
                      onClick={(e) => {
                        if (
                          e.target.className &&
                          typeof e.target.className === "string" &&
                          e.target.className.includes("modal-overlay")
                        ) {
                          handleRegisterModal();
                        }
                      }}
                    >
                      <div className="modal-content-order">
                        <div>
                          <h4>
                            {" "}
                            {values.option === "1"
                              ? t("Close_Register")
                              : t("View_Register")}
                          </h4>
                          <button
                            className="modal-close"
                            onClick={() => handleRegisterModal()}
                          >
                            <MdOutlineCancel />
                          </button>
                        </div>
                        {/* <p>
                          No Orders for{" "}
                          {formattedDate(registerInitialValues.date)}
                        </p> */}
                        {settlementData.length > 0 && (
                          <>
                            <CustomTable
                              page="settlementOrderType"
                              checkBool={false}
                              showKey={showOrderTypeKey}
                              showActions={false}
                              showTooltip={true}
                              data={orderTypeSettlementData}
                              heading={orderTypeColumns}
                            />
                            <CustomTable
                              page="settlement"
                              checkBool={false}
                              showKey={showKey}
                              showActions={false}
                              showTooltip={true}
                              data={settlementData}
                              heading={columns}
                              handleUpdateItem={handleUpdateItem}
                              registerType={
                                values.option === "0"
                                  ? true
                                  : registerInitialValues.total_default_amount !==
                                      0 &&
                                    registerInitialValues.total_default_amount !==
                                      "0" &&
                                    registerInitialValues.total_default_amount !==
                                      "0.00" &&
                                    registerInitialValues.total_default_amount !==
                                      0.0
                                  ? false
                                  : true
                              }
                            />
                            <div className="row">
                              <div className="col-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_amount")}
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="total_default_amount"
                                  placeholder={t("lable_designation_title")}
                                  className="add-roles"
                                  Regex={/[^a-zA-Z\s]/g}
                                  value={values.total_default_amount}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_settlement_amount")}
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="total_final_amount"
                                  placeholder={t("lable_designation_title")}
                                  className="add-roles"
                                  Regex={/[^a-zA-Z\s]/g}
                                  value={values.total_settlement_amount}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-md-12">
                                <div className="message-input">
                                  <textarea
                                    value={values.note}
                                    placeholder={t(
                                      "Placeholder_generalized_instruction"
                                    )}
                                    onChange={(event) =>
                                      setFieldValue("note", event.target.value)
                                    }
                                    disabled={
                                      values.option === "0"
                                        ? true
                                        : registerInitialValues.total_default_amount !==
                                            0 &&
                                          registerInitialValues.total_default_amount !==
                                            "0" &&
                                          registerInitialValues.total_default_amount !==
                                            "0.00" &&
                                          registerInitialValues.total_default_amount !==
                                            0.0
                                        ? false
                                        : true
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            {values.option === "1" &&
                            registerInitialValues.total_default_amount !== 0 &&
                            registerInitialValues.total_default_amount !==
                              "0" &&
                            registerInitialValues.total_default_amount !==
                              "0.00" &&
                            registerInitialValues.total_default_amount !==
                              0.0 ? (
                              <div className="d-flex">
                                <Button
                                  type="submit"
                                  name={t("Close_Register")}
                                  className="btn login-btn place-order-btn"
                                  onClick={() =>
                                    handleRegisterSubmit(values, "close")
                                  }
                                />
                                <Button
                                  type="submit"
                                  name={t("Print_Register")}
                                  className="btn login-btn place-order-btn ms-3"
                                  onClick={() =>
                                    handleRegisterSubmit(values, "print")
                                  }
                                />
                              </div>
                            ) : (
                              registerInitialValues.total_default_amount !==
                                0 &&
                              registerInitialValues.total_default_amount !==
                                "0" &&
                              registerInitialValues.total_default_amount !==
                                "0.00" && (
                                <Button
                                  type="submit"
                                  name={t("Print_Register")}
                                  className="btn login-btn place-order-btn"
                                  onClick={() =>
                                    handleRegisterSubmit(values, "print")
                                  }
                                />
                              )
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>

            <div className="btn-groups">
              <div className="datepicker-container  btn-fillter-main btn-width-unset plus-btn-new-2">
                <DatePicker
                  selected={registerInitialValues.date}
                  onChange={handleRegisterDate}
                  placeholderText={t("placeholder_date")}
                  showIcon
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  className="custom-datepicker  custom-datepicker-new"
                />
              </div>
              <Button
                className="btn btn-fillter-main icon-img plus-btn-new-2"
                data-bs-toggle="tooltip"
                title={t("addOrders.View_Register")}
                onClick={() => handleRegister("0")}
                name={<img src={ViewRegister} alt="Close Register" />}
              />
              <Button
                className="btn btn-fillter-main icon-img plus-btn-new-2"
                data-bs-toggle="tooltip"
                title={t("addOrders.Close_Register")}
                onClick={() => handleRegister("1")}
                name={<img src={CloseRegister} alt="Close Register" />}
              />
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row new-bg-revome">
                  {userDetailsModal && (
                    <div
                      className="modal-overlay"
                      onClick={(e) => {
                        if (
                          e.target.className &&
                          typeof e.target.className === "string" &&
                          e.target.className.includes("modal-overlay")
                        ) {
                          setUserDetailModal(false);
                        }
                      }}
                    >
                      <div className="modal-content-order">
                        <div>
                          <h4>{t("addOrders.enter_customer_details")}</h4>
                          <button
                            className="modal-close"
                            onClick={() => handleUserDetailModal()}
                          >
                            <MdOutlineCancel />
                          </button>
                        </div>
                        <div>
                          <div className="row">
                            {Data?.data &&
                            [
                              8155006366,
                              "8155006366",
                              "null",
                              null,
                              undefined,
                            ].includes(Data.data.phone_number) ? (
                              <></>
                            ) : (
                              <>
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        {t("addOrders.customer_mobile")}
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="mobile"
                                      label={t("addOrders.mobile_label")}
                                      placeholder={t(
                                        "addOrders.mobile_placeholder"
                                      )}
                                      className="add-roles"
                                      maxLength={10}
                                      Regex={/[^0-9\s]/g}
                                      value={values.mobile}
                                      hidelength="true"
                                      disabled={Data}
                                      onInput={(event) =>
                                        handlePhoneChange(event, setFieldValue)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        {t("first_name")}
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="firstName"
                                      label={t("lable_Name")}
                                      placeholder={t("placeholder_name")}
                                      className="add-roles"
                                      Regex={/[^a-zA-z\s]/g}
                                      value={values.firstName}
                                      hidelength="true"
                                      disabled={
                                        Data && Data.isEdit === false
                                          ? true
                                          : disableName
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        {t("last_name")}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="lastName"
                                      label={t("lable_Name")}
                                      placeholder={t("placeholder_last_name")}
                                      className="add-roles"
                                      Regex={/[^a-zA-z\s]/g}
                                      value={values.lastName}
                                      hidelength="true"
                                      disabled={
                                        Data && Data.isEdit === false
                                          ? true
                                          : disableName
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    {t("Dine_&_take_away")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="dineIn"
                                  options={dineOptions}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  changeData={(value) => {
                                    changeDineInData(value, setFieldValue);
                                  }}
                                  value={
                                    values.dineIn === "" ||
                                    values.dineIn === null
                                      ? false
                                      : values.dineIn
                                  }
                                  disabled={
                                    (Data && Data.isEdit === false) ||
                                    (Data?.data && Data.data?.type === "2")
                                  }
                                />
                              </div>
                            </div>
                            {values.dineIn === "0" && (
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("table_number")}
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Field
                                    className="select-menu mb-2"
                                    name="tableNo"
                                    options={tableOptions}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={false}
                                    value={values.tableNo}
                                    disabled={Data && Data.isEdit === false}
                                    styles={customStyles}
                                    onchangedata={(value) =>
                                      onChangeTableNo(
                                        value,
                                        setFieldValue,
                                        values
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div className={"col-md-4"}>
                              <div className="name-type">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("Payment")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="payment"
                                  options={paymentOption}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  onchangedata={(value) =>
                                    handlePaymentValidation(
                                      value,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  value={
                                    values.payment === "" ||
                                    values.payment === null
                                      ? false
                                      : values.payment
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            </div>

                            {(values.payment === "5" ||
                              values.payment === "6") && (
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("zomato_swiggy_price")}
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="zomatoSwiggyPrice"
                                    label={t("lable_zomato_swiggy_price")}
                                    placeholder={t("placeholder_price")}
                                    isRequired={true}
                                    Regex={/[^0-9.\s]/g}
                                    // maxLength={7}
                                    hidelength={"true"}
                                    value={values.zomatoSwiggyPrice}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                            )}

                            <div className={"col-md-4"}>
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    {t("Discount")}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="discount"
                                  options={discountOption}
                                  component={CustomSelect}
                                  placeholder={t("placeholder_Select")}
                                  isMulti={false}
                                  changeData={(value) =>
                                    onSelectDiscount(value, setFieldValue, true)
                                  }
                                  value={
                                    values.discount === "" ||
                                    values.discount === null
                                      ? false
                                      : values.discount
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                                {discountID &&
                                ((Data && Data.isEdit === true) || !Data) ? (
                                  <Button
                                    name="Remove discount"
                                    type="button"
                                    onClick={() => {
                                      handleRemoveDiscount(setFieldValue);
                                    }}
                                  ></Button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          {((Data && Data.isEdit === true) || !Data) && (
                            <div className="col-lg-5 col-md-6">
                              <div className="paragraph-editor">
                                <div className="btn-submit order-submit-btn">
                                  <Button
                                    type="submit"
                                    name={t("button_place_order")}
                                    className="btn login-btn  place-order-btn"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                      padding: 0,
                    }}
                  ></div>

                  {/* <br /> */}
                  <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 ps-0">
                      {/* Search Bar Section */}
                      <div className="col-12">
                        <div className="name-type">
                          <div className="search-bar">
                            <SearchBar
                              inputData={inputData}
                              setInputData={setInputData}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <CategoryTree categories={productCategory} />
                      </div>
                      {productOptions?.length ? (
                        <div className="product-order">
                          <div className="row">
                            {productOptions.map((item, index) => (
                              <div
                                className="col-lg-4 col-md-6 col-12 mb-3"
                                key={index}
                              >
                                {/* OrderCart */}
                                <OrderCart
                                  productData={item}
                                  setAddedProducts={setAddedProducts}
                                  showAddButton={true}
                                  addedProducts={addedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  disabled={Data && Data.isEdit === false}
                                  setFieldValue={setFieldValue}
                                  discountID={discountID}
                                  onSelectDiscount={onSelectDiscount}
                                  ref={orderCartRef}
                                  Data={Data}
                                  initialValues={initialValues}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={`col-lg-12 col-md-12 col-sm-12 col-12 ps-0`}
                          >
                            <div className="product-order">
                              <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                  <b>{t("no_product_found")}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-12 position-relative"
                      style={{
                        backgroundColor: "#fff",
                        border: 1,
                        borderRadius: 5,
                      }}
                    >
                      <div className={"col-md-12"}>
                        <div className="name-type mt-2">
                          <Field
                            className="select-menu mb-2"
                            name="dineIn"
                            options={dineOptions}
                            component={CustomSelect}
                            placeholder={t("placeholder_Select")}
                            isMulti={false}
                            changeData={(value) => {
                              changeDineInData(value, setFieldValue);
                            }}
                            value={
                              values.dineIn === "" || values.dineIn === null
                                ? false
                                : values.dineIn
                            }
                            disabled={
                              (Data && Data.isEdit === false) ||
                              (Data?.data && Data.data?.type === "2")
                            }
                          />
                        </div>
                      </div>
                      {values.dineIn === "0" && (
                        <div className="col-md-12">
                          <div className="name-type">
                            <Field
                              className="select-menu mb-2"
                              name="tableNo"
                              options={tableOptions}
                              component={CustomSelect}
                              placeholder={t("select_table_Number")}
                              isMulti={false}
                              value={values.tableNo}
                              disabled={Data && Data.isEdit === false}
                              styles={customStyles}
                              onchangedata={(value) =>
                                onChangeTableNo(value, setFieldValue, values)
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-12 input-product-code">
                        <div className="name-type product-Code">
                          <div style={{ width: "100%" }}>
                            <Input
                              inputRef={inputRef}
                              type="text"
                              name="productCode"
                              label="Product Code"
                              placeholder={t("placeholder_sku_code")}
                              className="add-roles"
                              maxLength={10}
                              Regex={/[^0-9\s]/g}
                              value={values.productCode}
                              hidelength="true"
                              disabled={Data && Data.isEdit === false}
                              onInput={(event) =>
                                setFieldValue("productCode", event.target.value)
                              }
                              onKeyPress={(e) =>
                                handleKeyPress(e, values, setFieldValue)
                              }
                            />
                          </div>

                          <div
                            className="pc-btn-new"
                            onClick={() =>
                              handleAddedProduct(values, setFieldValue)
                            }
                          >
                            <FaPlus
                              className={`add-new-btn ${
                                Data ? "disabled" : ""
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                      {addedProducts && addedProducts.length > 0 ? (
                        <>
                          <div className="new-order-detail">
                            <h6>{t("addOrders.order_details")}</h6>
                            {addedProducts.map((item, index) => (
                              <div key={index}>
                                <OrderCart
                                  productData={item}
                                  showAddButton={false}
                                  showMessageInput={true}
                                  addedProducts={addedProducts}
                                  setAddedProducts={setAddedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  showRadio={true}
                                  disabled={Data && Data.isEdit === false}
                                  setFieldValue={setFieldValue}
                                  discountID={discountID}
                                  onSelectDiscount={onSelectDiscount}
                                  ref={orderCartRef}
                                  Data={Data}
                                  initialValues={initialValues}
                                />
                              </div>
                            ))}
                          </div>
                          {/* Message Input and Total Amount Section */}
                          <div className="pb-5">
                            <div className="message-input">
                              <textarea
                                value={message}
                                placeholder={t("placeholder_instruction")}
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                disabled={Data && Data.isEdit === false}
                              />
                            </div>
                          </div>
                          {/* Total Amount and Buttons */}
                          <div className="row total-amount-btn">
                            <div className="col-lg-7 col-md-6">
                              <div className="name-type">
                                <div className="paragraph-editor">
                                  <h5 className="paragraph-title">
                                    {t("addOrders.total_amount")} :{" "}
                                    {authuserData?.data?.symbol
                                      ? authuserData.data.symbol
                                      : "₹"}
                                    {Data &&
                                    Data.isEdit === false &&
                                    Data.data?.service_charge
                                      ? Number(
                                          Data.data?.total_amount_with_tax
                                        ) + Number(Data.data?.service_charge)
                                      : updatedTotalAmt !== 0
                                      ? updatedTotalAmt
                                      : totalAmountData}
                                  </h5>
                                  {discountID ? (
                                    <span
                                      style={{
                                        fontSize: 12,
                                        display: "block",
                                      }}
                                    >
                                      {t("addOrders.Discount")} :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {discountAmt}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {(Data &&
                                    Data?.isEdit === false &&
                                    Number(Data.data?.total_tax_amount) > 0) ||
                                  (taxAmount && taxAmount > 0) ? (
                                    <span
                                      style={{ fontSize: 12, display: "block" }}
                                    >
                                      {authuserData?.data?.branchTaxType ===
                                      "GST"
                                        ? t("lable_gst")
                                        : authuserData?.data?.branchTaxType ===
                                          "VAT"
                                        ? t("lable_vat")
                                        : t("columns.tax")}{" "}
                                      :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {Data && Data?.isEdit === false
                                        ? Number(Data.data?.total_tax_amount)
                                        : taxAmount}
                                    </span>
                                  ) : (
                                    <></>
                                  )}

                                  {serviceCharge ? (
                                    <span
                                      style={{
                                        fontSize: 12,
                                        display: "block",
                                      }}
                                    >
                                      {t("placeholder_service_charge")} :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {serviceCharge}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* Button Section */}
                            {(Data && Data.isEdit === true) || !Data ? (
                              <div className="col-lg-5 col-md-6">
                                <div className="paragraph-editor">
                                  <div className="btn-submit">
                                    <Button
                                      type="button"
                                      name={t("button_place_order")}
                                      className="btn login-btn place-order-btn width-100"
                                      onClick={() => handleUserDetailModal()}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-lg-5 col-md-6">
                                <div className="paragraph-editor">
                                  <div className="btn-submit">
                                    <Button
                                      type="button"
                                      name="Details"
                                      className="btn login-btn place-order-btn"
                                      onClick={() => handleUserDetailModal()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="no-data-card">
                            <img src={unoccupied} alt="" />
                            <h6 className="no-data-title">
                              {t("no_products_added")}
                            </h6>
                            <p className="no-data-description">
                              {t("no_products_added_massage")}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
import {
  CLEAR_PRODUCT_INVENTORY_DATA,
  CLEAR_TOTAL_PRODUCT_INVENTORY,
  GET_PRODUCT_INVENTORY_DATA,
  GET_TOTAL_PRODUCT_INVENTORY,
} from "../../ReduxKeys";

/**
 * Setting product inventory list data into reducer
 * @param {*} ProductInventory
 * @returns {*}
 */
export const getProductInventoryData = (ProductInventory) => ({
  type: GET_PRODUCT_INVENTORY_DATA,
  payload: ProductInventory,
});

/**
 * clearing product inventory list data
 * @returns {*}
 */
export const clearProductInventoryData = () => ({
  type: CLEAR_PRODUCT_INVENTORY_DATA,
});

/**
 * setting total product inventory data into reducer (No pagination)
 * @param {*} ProductInventory
 * @returns {*}
 */
export const getTotalProductInventoryData = (ProductInventory) => ({
  type: GET_TOTAL_PRODUCT_INVENTORY,
  payload: ProductInventory,
});

/**
 * cleating total product inventory list data
 * @returns {*}
 */
export const clearTotalProductInventoryData = () => ({
  type: CLEAR_TOTAL_PRODUCT_INVENTORY,
});
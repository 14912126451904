import { CLEAR_PREFERENCE_PAGE, GET_PREFERENCE_PAGE } from "../../ReduxKeys";

/**
 * Set Preferences page data
 * @param {*} page
 * @returns {*}
 */
export const getPreferencesPageData = (page) => ({
  type: GET_PREFERENCE_PAGE,
  payload: page,
});

/**
 * Clear Preferences page data
 * @returns {*}
 */
export const clearPreferencesPageData = () => ({
  type: CLEAR_PREFERENCE_PAGE,
});

import {
  CLEAR_INVENTORY_ITEM_PAGE,
  GET_INVENTORY_ITEM_PAGE,
} from "../../ReduxKeys";

const inventoryItemPageInitialState = {
  items: 1,
};

/**
 * Store inventory page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const InventoryItemPageReducer = (
  state = inventoryItemPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_INVENTORY_ITEM_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_INVENTORY_ITEM_PAGE:
      return inventoryItemPageInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_INVENTORY_ITEM_FILTER,
  GET_INVENTORY_ITEM_FILTER,
} from "../../ReduxKeys";

/**
 * Setting inventory item filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getInventoryItemFilterData = ({ title, isActive, isDelete }) => ({
  type: GET_INVENTORY_ITEM_FILTER,
  payload: { title, isActive, isDelete },
});

/**
 * Clearing inventory item filtered data
 * @returns {*}
 */
export const clearInventoryItemFilterData = () => ({
  type: CLEAR_INVENTORY_ITEM_FILTER,
});
import { oneMonthsBefore, orderDateNew } from "../../../services/Regular-services/DateService";
import { CLEAR_EXPENSE_FILTER, GET_EXPENSE_FILTER } from "../../ReduxKeys";

const expenseFilterInitialState = {
  title: "",
  amount: "",
  category: "",
  startDate:oneMonthsBefore(),
  endDate: orderDateNew(),
};

/**
 * Store expense filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const expenseFilterReducer = (
  state = expenseFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_EXPENSE_FILTER:
      return {
        ...state,
        title: action.payload.title,
        amount: action.payload.amount,
        category: action.payload.category,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case CLEAR_EXPENSE_FILTER:
      return expenseFilterInitialState;
    default:
      return state;
  }
};

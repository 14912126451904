import {
  getCurrentYear,
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_SALES_TABLE_FILTER,
  GET_SALES_TABLE_FILTER,
} from "../../ReduxKeys";

const salesTableFilterInitialState = {
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
  year: getCurrentYear(),
  month: "",
  status: "",
  orderType: "",
};

/**
 * Store sales table filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SalesTableFilterReducer = (
  state = salesTableFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_TABLE_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        year: action.payload.year,
        month: action.payload.month,
        status: action.payload.status,
        orderType: action.payload.orderType,
      };
    case CLEAR_SALES_TABLE_FILTER:
      return salesTableFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import {
  clearUnitsPageData,
  getUnitsPageData,
} from "../../redux/Actions/UnitsAction/UnitsPageAction";
import { clearUnitsFilterData } from "../../redux/Actions/UnitsAction/UnitsFilterAction";
import { clearUnitsData } from "../../redux/Actions/UnitsAction/UnitsAction";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import CustomPagination from "../../components/Pagination/Pagination";
import CustomTable from "../../components/Table/Table";
import ExcelData from "../../services/Regular-services/ExcelExport";
import UnitServices from "./UnitsService/UnitsService";

const Units = () => {
  const { getAllUnitsService } = UnitServices();
  const unitsData = useSelector((state) => state.units);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const unitsPage = useSelector((state) => state.unitsPage?.items);
  const unitsFilter = useSelector((state) => state.unitsFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [unitsActionData, setUnitsActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setUnitsActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
  };
  const showToggle = {
    status: true,
  };

  const [columns, setColumns] = useState([
    {
      name: "Unit",
      selector: (row) => row.name,
    },
    {
      name: "Status",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      unitsActionData[0]?.view &&
      unitsActionData[0].view === "0" &&
      unitsActionData[0]?.edit &&
      unitsActionData[0].edit === "0" &&
      unitsActionData[0]?.add &&
      unitsActionData[0].add === "0" &&
      unitsActionData[0]?.delete &&
      unitsActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
    }
  }, [unitsActionData]);

  const showActions = {
    showView:
      (unitsActionData[0]?.view && unitsActionData[0].view === "1") ||
      !unitsActionData
        ? true
        : false,
    showEdit:
      (unitsActionData[0]?.edit && unitsActionData[0].edit === "1") ||
      !unitsActionData
        ? true
        : false,
    showDelete:
      (unitsActionData[0]?.delete && unitsActionData[0].delete === "1") ||
      !unitsActionData
        ? true
        : false,
    showUndo:
      (unitsActionData[0]?.edit && unitsActionData[0].edit === "1") ||
      !unitsActionData
        ? true
        : false,
    navigate:
      (unitsActionData[0]?.edit && unitsActionData[0].edit === "1") ||
      !unitsActionData
        ? "/addunits"
        : false,
  };

  useEffect(() => {
    setCurrentPage(unitsPage);
  }, [unitsPage]);

  /**
   * Fetch units data
   */
  const fetchUnits = () => {
    const requestBody = {
      p_skip: unitsPage > 1 ? (unitsPage - 2) * 10 : (unitsPage - 1) * 10,
      p_limit: 10,
    };
    if (unitsFilter.unitId !== "" && unitsFilter.unitId !== null) {
      requestBody.p_unit_id = unitsFilter.unitId;
    }
    if (unitsFilter.isActive !== "" && unitsFilter.isActive !== null) {
      requestBody.p_isActive = unitsFilter.isActive;
    } else {
      requestBody.p_isActive = "1";
    }
    if (unitsFilter.isDelete !== "" && unitsFilter.isDelete !== null) {
      requestBody.p_IsDeleted = unitsFilter.isDelete;
    }

    getAllUnitsService(requestBody, tokenData, dispatch);
    if (unitsPage > 1) {
      setCurrentPage(unitsPage - 1); // Update the current page state
      dispatch(getUnitsPageData(unitsPage - 1));
    }
  };

  useEffect(() => {
    if (unitsData && unitsData.items && unitsData.items.length === 0) {
      fetchUnits();
    } else {
      if (
        unitsFilter?.unitId !== "" ||
        unitsFilter?.isActive !== "" ||
        unitsFilter?.isDelete !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (unitsData.items[0]?.data && unitsData.items[0].data.length > 0) {
      const transformedUnitsData = unitsData.items[0].data.map((item) => {
        return {
          id: item.id,
          name: item.unit_name,
          status: item.is_active,
          is_delete: item.IsDeleted,
          unit_id: item.unit_id,
        };
      });

      setTableData(transformedUnitsData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [unitsData, unitsFilter]);

  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (unitsFilter.unitId !== "" && unitsFilter.unitId !== null) {
        requestBody.p_unit_id = unitsFilter.unitId;
      }
      if (unitsFilter.isActive !== "" && unitsFilter.isActive !== null) {
        requestBody.p_isActive = unitsFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (unitsFilter.isDelete !== "" && unitsFilter.isDelete !== null) {
        requestBody.p_IsDeleted = unitsFilter.isDelete;
      }

      getAllUnitsService(requestBody, tokenData, dispatch);
      dispatch(getUnitsPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearUnitsFilterData());
    dispatch(clearUnitsPageData());
    dispatch(clearUnitsData());
    setIsToggled(false);
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {unitsActionData && unitsActionData.length > 0
                      ? unitsActionData[0].name
                      : "Units"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {unitsActionData && unitsActionData.length > 0
                      ? unitsActionData[0].name
                      : "Units"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {unitsFilter.title !== "" ||
                unitsFilter.isActive !== "" ||
                unitsFilter.isDelete !== "" ? (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>

                {unitsData &&
                  unitsData.items[0]?.data &&
                  unitsData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={unitsData.items[0].data}
                      fileName={"units"}
                    ></ExcelData>
                  )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Units"
                  onClick={() => navigate("/addunits")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {isToggled && <Filter page="units"></Filter>}

            {unitsData &&
              unitsData.items[0]?.data &&
              unitsData.items[0].data && (
                <>
                  <CustomTable
                    page="units"
                    showToggle={showToggle}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      unitsActionData &&
                      unitsActionData[0]?.edit &&
                      unitsActionData[0].edit === "0"
                    }
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                  />

                  {/* pagination */}
                  {unitsData.items[0].total_records >
                    unitsData.items[0].data.length &&
                    unitsData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={unitsData.items[0].total_records}
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Units;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SettlementGraphService from "./SettlementGraphService/SettlementGraphService";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import { clearSettlementGraphData } from "../../redux/Actions/SettlementGraphAction/SettlementGraphAction";
import { clearSettlementGraphFilterData } from "../../redux/Actions/SettlementGraphAction/SettlementGraphFilterAction";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Button from "../../components/Button/Button";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { FaFilter } from "react-icons/fa";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "../SettlementGraph/SettlementGraph.scss";
import { MdOutlineCancel, MdDateRange } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";

ChartJS.register(ArcElement, Tooltip, Legend);

const SettlementGraph = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getSettlementGraphService } = SettlementGraphService();
  const settlementGraph = useSelector((state) => state.settlementGraph);
  const settlementGraphFilter = useSelector(
    (state) => state.settlementGraphFilter
  );
  const [show, setShow] = useState(false);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [settlementGraphActionData, setSettlementGraphData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [summary, setSummary] = useState({
    date: "",
    title: "",
    amount: "",
  });
  const [pieChartModal, setPieChartModal] = useState(false);

  const dateFormat = (dateInput) => {
    const date = new Date(dateInput);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const handleBarChart = (e, elements, chart) => {
    if (elements.length > 0) {
      const { datasetIndex, index } = elements[0];
      setPieChartModal(true);

      const selectedDataset = chart.data.datasets[datasetIndex].label;
      const selectedData = settlementGraph.items[0].data[index];

      setSummary((prev) => ({
        ...prev,
        date: dateFormat(selectedData.settelment_date),
        title:
          selectedDataset === t("lable_settlement_amount")
            ? t("lable_settlement_amount")
            : t("lable_amount"),
        amount:
          selectedDataset === t("lable_settlement_amount")
            ? parseFloat(selectedData.total_sum_of_final_amount).toFixed(2)
            : parseFloat(selectedData.total_sum_of_total_sale_amount).toFixed(
                2
              ),
      }));

      // Extract relevant amounts for the pie chart based on dataset
      let pieData;
      let pieLabels = [
        t("paymentOption.pending"),
        t("paymentOption.UPI"),
        t("paymentOption.Card"),
        t("paymentOption.Cash"),
        t("paymentOption.Zomato"),
        t("paymentOption.Swiggy"),
        t("paymentOption.Other"),
      ];

      if (selectedDataset === t("lable_settlement_amount")) {
        pieData = [
          parseFloat(selectedData.total_sum_of_pending_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_upi_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_card_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_cash_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_zomato_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_swiggy_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_other_settlement_amount) || 0,
          parseFloat(selectedData.total_sum_of_total_refund_amount) || 0,
        ];
        // Add the "Refund" label to the pie chart
        pieLabels.push(t("paymentOption.Refund"));
      } else if (selectedDataset === t("lable_amount")) {
        pieData = [
          parseFloat(selectedData.total_sum_of_pending_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_upi_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_card_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_cash_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_zomato_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_swiggy_default_amount) || 0,
          parseFloat(selectedData.total_sum_of_other_settlement_amount) || 0,
        ];
      }

      // Update the pie chart data
      setPieChartData({
        labels: pieLabels, // Update labels to include "Refund" if applicable
        datasets: [
          {
            label: t("Settlement Breakdown"),
            data: pieData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)", // Pending
              "rgba(54, 162, 235, 0.2)", // UPI
              "rgba(255, 206, 86, 0.2)", // Card
              "rgba(75, 192, 192, 0.2)", // Cash
              "rgba(153, 102, 255, 0.2)", // Zomato
              "rgba(255, 159, 64, 0.2)", // Swiggy
              "rgba(255, 99, 242, 0.2)", // Other
              "rgba(255, 165, 0, 0.2)", // Refund (New color)
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)", // Pending
              "rgba(54, 162, 235, 1)", // UPI
              "rgba(255, 206, 86, 1)", // Card
              "rgba(75, 192, 192, 1)", // Cash
              "rgba(153, 102, 255, 1)", // Zomato
              "rgba(255, 159, 64, 1)", // Swiggy
              "rgb(255, 99, 242)", // Other
              "rgba(255, 165, 0, 1)", // Refund (New border color)
            ],

            borderWidth: 1,
          },
        ],
      });
    }
  };

  const handlePieChartModal = () => {
    setPieChartModal(!pieChartModal);
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: t("Settlement_Analytics"),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t("lable_amount"),
        },
        ticks: {
          //   stepSize: 500, // Set fixed intervals of 500
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: t("Date"),
        },
      },
    },
    onClick: handleBarChart,
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "settelment-analytics"
      );
      setSettlementGraphData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      settlementGraph &&
      settlementGraph.items &&
      settlementGraph.items.length === 0
    ) {
      fetchSettlementGraph();
    } else {
      if (
        settlementGraph &&
        settlementGraph.items &&
        settlementGraph.items[0]?.data &&
        settlementGraph.items[0].data.length > 0
      ) {
        const data = settlementGraph.items[0].data;

        // Extract labels and data for the bar chart
        const labels = data.map((item) => {
          return dateFormat(item.settelment_date);
        }); // Dates for x-axis

        const totalFinalAmount = data.map(
          (item) => parseFloat(item.total_sum_of_final_amount) || 0
        ); // Y-axis: Final Amount
        const totalSaleAmount = data.map(
          (item) => parseFloat(item.total_sum_of_total_sale_amount) || 0
        ); // Y-axis: Sale Amount

        setBarChartData({
          labels: labels, // Optional: Reverse to show most recent dates last
          datasets: [
            {
              label: t("lable_settlement_amount"),
              data: totalFinalAmount,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
            {
              label: t("lable_amount"),
              data: totalSaleAmount,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
            },
          ],
        });
      }
    }
    // eslint-disable-next-line
  }, [settlementGraph, settlementGraphFilter, localisation]);

  const fetchSettlementGraph = () => {
    const requestBody = {
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_start_date: formatDateToYYYYMMDD(settlementGraphFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(settlementGraphFilter.endDate),
    };

    // return;
    getSettlementGraphService(requestBody, tokenData, dispatch);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearFilters = () => {
    dispatch(clearSettlementGraphData());
    dispatch(clearSettlementGraphFilterData());
  };

  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {settlementGraphActionData && settlementGraphActionData.length > 0
                ? getRouteNameFromSidebar(
                    settlementGraphActionData[0].name,
                    localisation
                  )
                : t("Expense Report")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {settlementGraphActionData && settlementGraphActionData.length > 0
                ? getRouteNameFromSidebar(
                    settlementGraphActionData[0].name,
                    localisation
                  )
                : t("Expense Report")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups">
          {((settlementGraphFilter.startDate !== "" &&
            settlementGraphFilter.startDate !== null &&
            new Date(
              formatDateToYYYYMMDD(settlementGraphFilter.startDate)
            ).toDateString() !==
              new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toDateString()) ||
            (settlementGraphFilter.endDate !== "" &&
              settlementGraphFilter.endDate !== null &&
              new Date(
                formatDateToYYYYMMDD(settlementGraphFilter.endDate)
              ).toDateString() !== new Date().toDateString())) && (
            <Dropdown className="new-header-dropdown action-btn">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                data-bs-toggle="tooltip"
                title="Action"
              >
                <GrMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu className="event-clear-filter">
                <Dropdown.Item onClick={clearFilters}>
                  {t("clear_filters")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={handleShow}
            data-bs-toggle="tooltip"
            title="Filter"
            className="btn btn-fillter-main crm-filter"
            name={
              <div className="filter">
                <FaFilter className="filter-icon" />
              </div>
            }
          ></Button>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter page="settlementGraph" handleClose={handleClose}></Filter>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {settlementGraph &&
      settlementGraph.items &&
      settlementGraph.items[0]?.data &&
      settlementGraph.items[0].data.length > 0 ? (
        <div className="row">
          <div>
            <div className={`chart-container`}>
              <Bar data={barChartData} options={chartOptions} />
            </div>
          </div>

          {pieChartData.labels.length > 0 && pieChartModal && (
            <div
              className="modal-overlay"
              onClick={(e) => {
                if (
                  e.target.className &&
                  typeof e.target.className === "string" &&
                  e.target.className.includes("modal-overlay")
                ) {
                  handlePieChartModal();
                }
              }}
            >
              <div className="modal-content-order">
                <div>
                  <h4>{summary.title}</h4>
                  <div className="modal-content-icons">
                    <span className="settlement-icon-container">
                      <MdDateRange className="settlement-icon" /> {summary.date}
                    </span>
                    <span className="settlement-icon-container">
                      <FaRupeeSign className="settlement-icon" />{" "}
                      {summary.amount}
                    </span>
                  </div>
                  <button
                    className="modal-close"
                    onClick={() => handlePieChartModal()}
                  >
                    <MdOutlineCancel />
                  </button>
                </div>
                <div className={`chart-container pie-chart-settlement mt-2`}>
                  <Pie data={pieChartData} />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="input-search">
          <div className="no-record">
            <h3 data-bs-toggle="tooltip" title="No records!">
              {t("no_records")}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettlementGraph;

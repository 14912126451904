import { CLEAR_PACKAGER_PAGE, GET_PACKAGER_PAGE } from "../../ReduxKeys";

const packagerPageInitialState = {
  items: 1,
};

/**
 * Store packager page data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const packagerPageReducer = (
  state = packagerPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_PACKAGER_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_PACKAGER_PAGE:
      return packagerPageInitialState;
    default:
      return state;
  }
};
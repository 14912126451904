import {
  CLEAR_PURCHASE_ORDER_DATA,
  CLEAR_TOTAL_PURCHASE_ORDER_DATA,
  GET_PURCHASE_ORDER_DATA,
  GET_TOTAL_PURCHASE_ORDER_DATA,
} from "../../ReduxKeys";

const purchaseOrderInitialState = {
  items: [],
};

/**
 * Store Purchase Order list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PurchaseOrderReducer = (
  state = purchaseOrderInitialState,
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_ORDER_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_PURCHASE_ORDER_DATA:
      return purchaseOrderInitialState;
    default:
      return state;
  }
};

const totalPurchaseOrderInitialState = {
  items: [],
};

/**
 * Store total PURCHASE ORDER list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalPurchaseOrderReducer = (
  state = totalPurchaseOrderInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_PURCHASE_ORDER_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_PURCHASE_ORDER_DATA:
      return totalPurchaseOrderInitialState;
    default:
      return state;
  }
};
import { CLEAR_CUSTOMER_ANALYTICS, GET_CUSTOMER_ANALYTICS } from "../../ReduxKeys";

/**
 * Setting customerAnalytics list data into reducer
 * @param {*} customerAnalytics
 * @returns {*}
 */
export const getCustomerAnalyticsData = (customerAnalytics) => ({
    type: GET_CUSTOMER_ANALYTICS,
    payload: customerAnalytics,
  });
  
  /**
   * clearing customerAnalytics list data
   * @returns {*}
   */
  export const clearCustomerAnalyticsData = () => ({
    type: CLEAR_CUSTOMER_ANALYTICS,
  });
  

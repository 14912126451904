import {
  CLEAR_PREFERENCE_FILTER,
  GET_PREFERENCE_FILTER,
} from "../../ReduxKeys";

/**
 * set Preferences filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getPreferencesFilterData = ({ p_user_id, p_product_name }) => ({
  type: GET_PREFERENCE_FILTER,
  payload: {
    p_user_id,
    p_product_name,
  },
});

/**
 * clear Preferences filtered data
 * @returns {*}
 */
export const clearPreferencesFilterData = () => ({
  type: CLEAR_PREFERENCE_FILTER,
});
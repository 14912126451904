import {
  CLEAR_PREFERENCE_DATA,
  CLEAR_TOTAL_PREFERENCE,
  GET_PREFERENCE_DATA,
  GET_TOTAL_PREFERENCE,
} from "../../ReduxKeys";

/**
 * Setting Purchase Requisition list data into reducer
 * @param {*} preferences
 * @returns {*}
 */
export const getPreferencesData = (preferences) => ({
  type: GET_PREFERENCE_DATA,
  payload: preferences,
});

/**
 * clearing Purchase Requisition list data
 * @returns {*}
 */
export const clearPreferencesData = () => ({
  type: CLEAR_PREFERENCE_DATA,
});

/**
 * Setting total Purchase Requisition list data into reducer (No pagnation)
 * @param {*} purchaseRequisition
 * @returns {*}
 */
export const getTotalPreferencesData = (preferences) => ({
  type: GET_TOTAL_PREFERENCE,
  payload: preferences,
});

/**
 * Clearing total Purchase Requisition list data (No pagination)
 * @returns {*}
 */
export const clearTotalPreferencesData = () => ({
  type: CLEAR_TOTAL_PREFERENCE,
});
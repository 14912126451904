import { CLEAR_STOCK_FILTER, GET_STOCK_FILTER } from "../../ReduxKeys";

const StockFilterInitialState = {
  ingredientId: "",
  // isActive: '',
  // isDelete: '',
};

/**
 * Store branch filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const StockFilterReducer = (state = StockFilterInitialState, action) => {
  switch (action.type) {
    case GET_STOCK_FILTER:
      return {
        ...state,
        ingredientId: action.payload.ingredientId,
        // isActive: action.payload.isActive,
        // isDelete: action.payload.isDelete,
      };
    case CLEAR_STOCK_FILTER:
      return StockFilterInitialState;
    default:
      return state;
  }
};
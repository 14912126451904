import { CLEAR_ROLE_PAGE, GET_ROLE_PAGE } from "../../ReduxKeys";

const rolesPageInitialState = {
  items: 1,
};

/**
 * Store role page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const RolePageReducer = (state = rolesPageInitialState, action) => {
  switch (action.type) {
    case GET_ROLE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_ROLE_PAGE:
      return rolesPageInitialState;
    default:
      return state;
  }
};
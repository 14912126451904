import { toast } from "react-toastify";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  getAdminUserListRequest,
  getAdminUserResponse,
} from "../../AdminUsers/User-service/UserModalResponse";
import { Dispatch } from "redux";
import {
  clearCustomerData,
  clearTotalCustomer,
  getCustomerData,
  getTotalCustomerData,
} from "../../../redux/Actions/CustomerAction/CustomerAction";
import {
  addCustomerNotesRequest,
  getAllCustomerNotesRequest,
  getAllCustomerNotesResponse,
  getAllPreferencesRequest,
  getAllPreferencesResponse,
  getCustomerAnalyticsRequest,
  getCustomerOrdersRequest,
  updateCustomerRequest,
} from "./CustomerServiceModal";
import {
  getCustomerOrderData,
  getTotalCustomerOrderData,
} from "../../../redux/Actions/CustomerAction/CustomerOrderAction";
import { formatDateToUTC } from "../../../services/Regular-services/DateService";
import {
  getPreferencesData,
  getTotalPreferencesData,
} from "../../../redux/Actions/Prefrences/PrefrencesAction";
import {
  clearCustomerNoteData,
  clearTotalCustomerNoteData,
  getCustomerNoteData,
  getTotalCustomerNoteData,
} from "../../../redux/Actions/CustomerNoteAction/CustomerNoteAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  clearCustomerNotePageData,
  getCustomerNotePageData,
} from "../../../redux/Actions/CustomerNoteAction/CustomerNotePageAction";
import { getCustomerAnalyticsData } from "../../../redux/Actions/CustomerAnalyticsAction/CustomerAnalyticsAction";
import { putFormDataService } from "../../../services/API-services/PutServiceFormData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllPreferencesData } from "../../../redux/Actions/Prefrences/AllPreferencesAction";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";

let errorShow = false;

export default function CustomerService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /**
   * Get customer list service
   * @param customerListRequest
   * @param token
   * @param dispatch
   * @param total
   * @returns
   */
  const getCustomerListService = (
    customerListRequest: getAdminUserListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ) => {
    return new Promise<getAdminUserResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          customerListRequest.skip !== undefined &&
          customerListRequest.skip !== null
        ) {
          queryParams += `skip=${customerListRequest.skip}&`;
        }
        if (
          customerListRequest.take !== undefined &&
          customerListRequest.take !== null
        ) {
          queryParams += `take=${customerListRequest.take}&`;
        }
        if (
          customerListRequest.searchUsername !== undefined &&
          customerListRequest.searchUsername !== null
        ) {
          queryParams += `searchUsername=${customerListRequest.searchUsername}&`;
        }
        if (
          customerListRequest.search !== undefined &&
          customerListRequest.search !== null
        ) {
          queryParams += `search=${customerListRequest.search}&`;
        }
        if (
          customerListRequest.search_username !== undefined &&
          customerListRequest.search_username !== null
        ) {
          queryParams += `search_username=${customerListRequest.search_username}&`;
        }
        if (
          customerListRequest.search_phone_number !== undefined &&
          customerListRequest.search_phone_number !== null
        ) {
          queryParams += `search_phone_number=${customerListRequest.search_phone_number}&`;
        }
        if (
          customerListRequest.search_first_name !== undefined &&
          customerListRequest.search_first_name !== null
        ) {
          queryParams += `search_first_name=${customerListRequest.search_first_name}&`;
        }
        if (
          customerListRequest.search_last_name !== undefined &&
          customerListRequest.search_last_name !== null
        ) {
          queryParams += `search_last_name=${customerListRequest.search_last_name}&`;
        }
        if (
          customerListRequest.search_email !== undefined &&
          customerListRequest.search_email !== null
        ) {
          queryParams += `search_email=${customerListRequest.search_email}&`;
        }
        if (
          customerListRequest.search_age !== undefined &&
          customerListRequest.search_age !== null
        ) {
          queryParams += `search_age=${customerListRequest.search_age}&`;
        }
        if (
          customerListRequest.search_is_active !== undefined &&
          customerListRequest.search_is_active !== null
        ) {
          queryParams += `search_is_active=${customerListRequest.search_is_active}&`;
        }
        if (
          customerListRequest.p_IsDeleted !== undefined &&
          customerListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${customerListRequest.p_IsDeleted}&`;
        }
        if (
          customerListRequest.search_dob !== undefined &&
          customerListRequest.search_dob !== null
        ) {
          queryParams += `search_dob=${customerListRequest.search_dob}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.CUSTOMER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalCustomerData(response));
          }
          if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getCustomerData(response));
          } else {
            dispatch(getCustomerData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get Packager and Manufacturer list
   */
  const getCustomersOrderService = (
    customerOrdersRequest: getCustomerOrdersRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          customerOrdersRequest.p_skip !== undefined &&
          customerOrdersRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${customerOrdersRequest.p_skip}&`;
        }
        if (
          customerOrdersRequest.p_take !== undefined &&
          customerOrdersRequest.p_take !== null
        ) {
          queryParams += `p_take=${customerOrdersRequest.p_take}&`;
        }
        if (
          customerOrdersRequest.p_order_no !== undefined &&
          customerOrdersRequest.p_order_no !== null
        ) {
          queryParams += `p_order_no=${customerOrdersRequest.p_order_no}&`;
        }
        if (
          customerOrdersRequest.p_created_time !== undefined &&
          customerOrdersRequest.p_created_time !== null
        ) {
          queryParams += `p_created_time=${customerOrdersRequest.p_created_time}&`;
        }
        if (
          customerOrdersRequest.p_status !== undefined &&
          customerOrdersRequest.p_status !== null
        ) {
          queryParams += `p_status=${customerOrdersRequest.p_status}&`;
        }
        if (
          customerOrdersRequest.p_type !== undefined &&
          customerOrdersRequest.p_type !== null
        ) {
          queryParams += `p_type=${customerOrdersRequest.p_type}&`;
        }
        if (
          customerOrdersRequest.p_payment !== undefined &&
          customerOrdersRequest.p_payment !== null
        ) {
          queryParams += `p_payment=${customerOrdersRequest.p_payment}&`;
        }
        if (
          customerOrdersRequest.p_time_zone !== undefined &&
          customerOrdersRequest.p_time_zone !== null
        ) {
          queryParams += `p_time_zone=${customerOrdersRequest.p_time_zone}&`;
        }
        if (
          customerOrdersRequest.p_user_id !== undefined &&
          customerOrdersRequest.p_user_id !== null
        ) {
          queryParams += `p_user_id=${customerOrdersRequest.p_user_id}&`;
        }
        if (
          customerOrdersRequest.p_start_date !== undefined &&
          customerOrdersRequest.p_start_date !== null
        ) {
          queryParams += `p_start_date=${customerOrdersRequest.p_start_date}&`;
        }
        if (
          customerOrdersRequest.p_end_date !== undefined &&
          customerOrdersRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${customerOrdersRequest.p_end_date}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        // dispatch(clearCustomerOrderData())

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalCustomerOrderData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(
              getCustomerOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: null,
              })
            );
          } else {
            if (response.data && response.data.length > 0) {
              const pendingCount = response.data.filter(
                (item: any) => item.status === "0"
              ).length;
              dispatch(
                getCustomerOrderData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: pendingCount,
                })
              );
            } else {
              dispatch(
                getCustomerOrderData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: 0,
                })
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get Preferences list
   */
  const getAllPreferencesService = (
    preferencesListRequest: getAllPreferencesRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllPreferencesResponse> => {
    // Update return type here
    return new Promise<getAllPreferencesResponse>(async (resolve, reject) => {
      // Update type parameter here

      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          preferencesListRequest.p_user_id !== undefined &&
          preferencesListRequest.p_user_id !== null
        ) {
          queryParams += `p_user_id=${preferencesListRequest.p_user_id}&`;
        }
        if (
          preferencesListRequest.p_product_name !== undefined &&
          preferencesListRequest.p_product_name !== null
        ) {
          queryParams += `p_product_name=${preferencesListRequest.p_product_name}&`;
        }
        if (
          preferencesListRequest.p_skip !== undefined &&
          preferencesListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${preferencesListRequest.p_skip}&`;
        }
        if (
          preferencesListRequest.p_take !== undefined &&
          preferencesListRequest.p_take !== null
        ) {
          queryParams += `p_take=${preferencesListRequest.p_take}&`;
        }
        if (
          preferencesListRequest.p_branch_id !== undefined &&
          preferencesListRequest.p_branch_id !== null
        ) {
          queryParams += `p_branch_id=${preferencesListRequest.p_branch_id}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            API_URL.PREFERENCESNOTES +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (
            preferencesListRequest.p_branch_id !== undefined &&
            preferencesListRequest.p_branch_id !== null
          ) {
            if (total && total === true) {
              dispatch(getTotalPreferencesData(response));
            } else if (total && total === "filter") {
              toast.success(t(ValidationMessage.FILTER_SUCCESS));
              dispatch(getPreferencesData(response));
            } else {
              dispatch(getPreferencesData(response));
            }
          } else {
            if (total && total === true) {
              // dispatch(getTotalPreferencesData(response));
            } else if (total && total === "filter") {
              toast.success(t(ValidationMessage.FILTER_SUCCESS));
              dispatch(getAllPreferencesData(response));
            } else {
              dispatch(getAllPreferencesData(response));
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get Preferences list
   */
  const getAllCustomerNotesService = (
    customerNoteListRequest: getAllCustomerNotesRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllCustomerNotesResponse> => {
    // Update return type here
    return new Promise<getAllCustomerNotesResponse>(async (resolve, reject) => {
      // Update type parameter here
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          customerNoteListRequest.p_skip !== undefined &&
          customerNoteListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${customerNoteListRequest.p_skip}&`;
        }
        if (
          customerNoteListRequest.p_take !== undefined &&
          customerNoteListRequest.p_take !== null
        ) {
          queryParams += `p_take=${customerNoteListRequest.p_take}&`;
        }
        if (
          customerNoteListRequest.p_user_id !== undefined &&
          customerNoteListRequest.p_user_id !== null
        ) {
          queryParams += `p_user_id=${customerNoteListRequest.p_user_id}&`;
        }
        if (
          customerNoteListRequest.p_branch_id !== undefined &&
          customerNoteListRequest.p_branch_id !== null
        ) {
          queryParams += `p_branch_id=${customerNoteListRequest.p_branch_id}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.CUSTOMER +
            API_URL.NOTES +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalCustomerNoteData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getCustomerNoteData(response));
          } else {
            dispatch(getCustomerNoteData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating Vendor
   */
  const addCustomerNoteService = (
    addCustomerNotesRequest: addCustomerNotesRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.CUSTOMER + API_URL.NOTES,
            token,
            addCustomerNotesRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.CUSTOMER + API_URL.NOTES,
            token,
            addCustomerNotesRequest,
            localisation
          );
        }
        if (type === "delete") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.CUSTOMER + API_URL.NOTES,
            token,
            addCustomerNotesRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearCustomerNoteData());
          dispatch(clearTotalCustomerNoteData());
          dispatch(clearCustomerNotePageData());
          if (type === "add") {
            dispatch(clearCustomerNotePageData());
            toast.success(response.message);
            if (navigate) {
              navigate("/crm");
            }
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Customer Note undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getCustomerNotePageData(pageNumber + 1));
                } else {
                  dispatch(clearCustomerNotePageData());
                }
                toast.success(response.message);
              } else {
                toast.success(response.message);
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getCustomerNotePageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for Update customer Detail
   */
  const UpdateCustomerDetailService = (
    updateCustomerRequest: updateCustomerRequest,
    token: string,
    type: string
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      updateCustomerRequest.p_profile_pic.forEach((image, index) => {
        formData.append("p_profile_pic", image);
      });

      formData.append("p_first_name", updateCustomerRequest.p_first_name);
      formData.append("p_last_name", updateCustomerRequest.p_last_name);

      if (updateCustomerRequest.p_dob) {
        formData.append("p_dob", updateCustomerRequest.p_dob);
      } else {
        formData.append("p_dob", ""); // Optional, to handle null values
      }

      if (updateCustomerRequest.p_aniversery_date) {
        formData.append(
          "p_aniversery_date",
          updateCustomerRequest.p_aniversery_date
        );
      } else {
        formData.append("p_aniversery_date", "");
      }

      formData.append("p_user_id", updateCustomerRequest.p_user_id);

      if (updateCustomerRequest.p_discount_ids) {
        formData.append(
          "p_discount_ids",
          JSON.stringify(updateCustomerRequest.p_discount_ids)
        );
      } else {
        formData.append("p_discount_ids", JSON.stringify([])); // Optional, to handle null values
      }
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "update") {
          response = await putFormDataService(
            API_URL.BASE_URL + API_URL.CUSTOMER,
            token,
            formData,
            localisation
          );
        }

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearCustomerData());
          dispatch(clearTotalCustomer());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          if (type === "update") {
            // dispatch(clearBranchPageData());
            toast.success(
              response?.message
                ? response.message
                : "Customer Data Update Susscessfully!"
            );
            // navigate("/branch");
            return response;
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get Preferences list
   */
  const getCustomerAnalyticsService = (
    customerAnalyticsRequest: getCustomerAnalyticsRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllCustomerNotesResponse> => {
    // Update return type here
    return new Promise<getAllCustomerNotesResponse>(async (resolve, reject) => {
      // Update type parameter here
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          customerAnalyticsRequest.p_user_id !== undefined &&
          customerAnalyticsRequest.p_user_id !== null
        ) {
          queryParams += `p_user_id=${customerAnalyticsRequest.p_user_id}&`;
        }
        if (
          customerAnalyticsRequest.p_branch_id !== undefined &&
          customerAnalyticsRequest.p_branch_id !== null
        ) {
          queryParams += `p_branch_id=${customerAnalyticsRequest.p_branch_id}&`;
        }
        if (
          customerAnalyticsRequest.p_start_date !== undefined &&
          customerAnalyticsRequest.p_start_date !== null
        ) {
          queryParams += `p_start_date=${customerAnalyticsRequest.p_start_date}&`;
        }
        if (
          customerAnalyticsRequest.p_end_date !== undefined &&
          customerAnalyticsRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${customerAnalyticsRequest.p_end_date}&`;
        }

        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            API_URL.USERANALYTICS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getCustomerAnalyticsData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getCustomerAnalyticsData(response));
          } else {
            dispatch(getCustomerAnalyticsData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

 
  return {
    getCustomerListService,
    getCustomersOrderService,
    getAllPreferencesService,
    getAllCustomerNotesService,
    addCustomerNoteService,
    UpdateCustomerDetailService,
    getCustomerAnalyticsService,
  };
}

import {
  CLEAR_SALES_GRAPH_FILTER,
  GET_SALES_GRAPH_FILTER,
} from "../../ReduxKeys";

/**
 * set customer analytics filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getSalesGraphFilter = ({ year, month }) => ({
  type: GET_SALES_GRAPH_FILTER,
  payload: {
    year,
    month,
  },
});

/**
 * clear customer analytics filtered data
 * @returns {*}
 */
export const clearSalesGraphFilter = () => ({
  type: CLEAR_SALES_GRAPH_FILTER,
});
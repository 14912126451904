import {
  CLEAR_LOCAL_ORDER_FILTER,
  CLEAR_ORDER_FILTER,
  GET_ORDER_FILTER,
} from "../../ReduxKeys";

/**
 * set order filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getOrderFilterData = ({ orderDate, status, orderType }) => ({
  type: GET_ORDER_FILTER,
  payload: { orderDate, status, orderType },
});

/**
 * clear order filtered data
 * @returns {*}
 */
export const clearOrderFilterData = () => ({
  type: CLEAR_ORDER_FILTER,
});

export const setClearLocalOrderFilter = (bool) => ({
  type: CLEAR_LOCAL_ORDER_FILTER,
  payload: bool,
});
import {
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import { CLEAR_EVENT_LOG_FILTER, GET_EVENT_LOG_FILTER } from "../../ReduxKeys";

const eventLogFilterInitialState = {
  module: "",
  Action: "",
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
  user: "",
};

/**
 * Store event log filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const EventLogFilterReducer = (
  state = eventLogFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_EVENT_LOG_FILTER:
      return {
        ...state,
        module: action.payload.module,
        Action: action.payload.Action,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        user: action.payload.user,
      };
    case CLEAR_EVENT_LOG_FILTER:
      return eventLogFilterInitialState;
    default:
      return state;
  }
};
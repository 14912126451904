import { CLEAR_SERVICE_PAGE, GET_SERVICE_PAGE } from "../../ReduxKeys";

/**
 * Set service page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getServicePageData = (page) => ({
  type: GET_SERVICE_PAGE,
  payload: page,
});

/**
 * Clear service page data
 * @returns {*}
 */
export const clearServicePageData = () => ({
  type: CLEAR_SERVICE_PAGE,
});
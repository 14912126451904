import { CLEAR_LEAF_FILTER, GET_LEAF_FILTER } from "../../ReduxKeys";

const leafFilterInitialState = {
  name: "",
  subCategory: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store leaf level category filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const leafFilterReducer = (state = leafFilterInitialState, action) => {
  switch (action.type) {
    case GET_LEAF_FILTER:
      return {
        ...state,
        name: action.payload.name,
        subCategory: action.payload.subCategory,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_LEAF_FILTER:
      return leafFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import { ValidationMessage } from "../../../Utils/Utils";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import Toggle from "../../../components/Toggle/Toggle";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";

export default function Addblog() {
  const navigate = useNavigate();
  const { handleCancleClick } = AlertMessage();

  const validationSchema = Yup.object({
    blogName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    blogCategory: Yup.string().required(ValidationMessage.SINGLE_CATEGORY_SELECT),
    blogSubCategory: Yup.string().required(
      ValidationMessage.SINGLE_SUB_CATEGORY_SELECT
    ),
    blogTag: Yup.string().required(ValidationMessage.TAG_REQUIRED),
    blogAuthorName: Yup.string().required(ValidationMessage.AUTHOR_NAME_REQUIRED),
    PublishDate: Yup.string().required(ValidationMessage.DATE_REQUIRED),
    Time: Yup.string().required(ValidationMessage.TIME_REQUIRED),
  });

  const [bannerImageError, setBannerImageError] = useState([]);
  const [blogImageError, setBlogImageError] = useState([]);

  const [bannerShowImage, setBannerShowImage] = useState(false);
  const [blogShowImage, setBlogShowImage] = useState(false);

  //Initial values
  const [initialValues, setInitialValues] = useState({
    blogName: "",
    blogCategory: "",
    blogSubCategory: "",
    blogTag: "",
    blogAuthorName: "",
    PublishDate: "",
    Time: "",
    Content: "",
    Excerpt: "",
    status: true,
    upload1: [],
    upload2: [],
  });

  //Getting data from blog-list
  const location = useLocation();
  const { Data } = location.state || {};

  //Reinitializing initial values
  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        blogName: Data.data.name,
        blogCategory: Data.data.category,
        blogSubCategory: Data.data.restData.subCategory,
        blogTag: Data.data.tags,
        blogAuthorName: Data.data.author,
        PublishDate: Data.data.date,
        Time: Data.data.restData.time,
        Content: Data.data.restData.content,
        Excerpt: Data.data.restData.excerpt,
        status: Data.data.isActive,
        upload1: Data.data.restData.bannerImage,
        upload2: Data.data.restData.image,
      });
    }
  }, [Data]);

  //Handle form submission
  const handleSubmit = (values) => {
    console.log("value: ", values);
  };

  const brandtypeOptions = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
  ];

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Add Blog</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/blog-list")}>
                    Blog list
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Blog</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>Add Blog</h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Add Blog Banner Image
                          </h4>
                        </div>
                        <UploadImage
                          name="upload1"
                          onChange={setFieldValue}
                          value={values.upload1}
                          disabled={Data && Data.isEdit === false}
                          filterByType={9}
                          setErrorImages={setBannerImageError}
                          setShowImage={setBannerShowImage}
                        />
                        <ShowImage
                          value={values.upload1}
                          errorImages={bannerImageError}
                          showImage={bannerShowImage}
                        ></ShowImage>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Add Image</h4>
                        </div>
                        <UploadImage
                          name="upload2"
                          onChange={setFieldValue}
                          value={values.upload2}
                          disabled={Data && Data.isEdit === false}
                          filterByType={10}
                          setErrorImages={setBlogImageError}
                          setShowImage={setBlogShowImage}
                        />
                        <ShowImage
                          value={values.upload2}
                          errorImages={blogImageError}
                          showImage={blogShowImage}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Title<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="blogName"
                          label="Title"
                          placeholder="Role Title"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.blogName}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Select Categories
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="blogCategory"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Select Sub Categories
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="blogSubCategory"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Tag<span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="blogTag"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Author Name<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="blogAuthorName"
                          label="Author Name"
                          placeholder="AuthorName"
                          className="add-roles"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Publish Date<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="date"
                          name="PublishDate"
                          label="Publish Date"
                          placeholder="Publish Date"
                          className="add-roles"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Time<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="time"
                          name="Time"
                          label="Time"
                          placeholder="Time"
                          className="add-roles"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Content</h4>
                        <Field name="Content" component={CKEditorCpmponent} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Excerpt</h4>
                        <Field name="Excerpt" component={CKEditorCpmponent} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field
                          name="status"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/blog-list", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

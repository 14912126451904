import { CLEAR_EXPENSE_REPORT, GET_EXPENSE_REPORT } from "../../ReduxKeys";

/**
 * Setting expense graph list data into reducer
 * @param {*} expenseGraph
 * @returns {*}
 */
export const getExpenseGraphData = (expenseGraph) => ({
  type: GET_EXPENSE_REPORT,
  payload: expenseGraph,
});

/**
 * clearing event list data
 * @returns {*}
 */
export const clearExpenseGraphData = () => ({
  type: CLEAR_EXPENSE_REPORT,
});
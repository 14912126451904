import { CLEAR_SALES_TABLE_DATA, GET_SALES_TABLE_DATA } from "../../ReduxKeys";

/**
 * Setting branch list data into reducer
 * @param {*} sales
 * @returns {*}
 */
export const getSalesTableData = (sales) => ({
  type: GET_SALES_TABLE_DATA,
  payload: sales,
});

/**
 * clearing branch list data
 * @returns {*}
 */
export const clearSalesTableData = () => ({
  type: CLEAR_SALES_TABLE_DATA,
});
import React, { useState } from "react";
import "./AddOrder.scss";
import ProductServices from "../../Product/ProductService/ProductService";
import UtilsData from "../../../Utils/Utils";
import { useDispatch } from "react-redux";

const CategoryTree = ({ categories }) => {
  const { getAllProductService } = ProductServices();

  const token = UtilsData();
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Handle category selection
  const toggleCategory = (categoryId) => {
    let updatedSelection = [];
    const selectedCategory = categories.find(
      (cat) => cat.categoryId === categoryId
    );

    if (!selectedCategory) return;

    if (selectedCategories.includes(categoryId)) {
      updatedSelection = selectedCategories.filter(
        (id) => id !== categoryId && !isChildCategory(id, categoryId)
      );
      setSelectedCategories(updatedSelection);
    } else {
      updatedSelection = getParentPath(categoryId);
      setSelectedCategories(updatedSelection);
    }
    const requestBody = {
        p_skip: 0,
        p_take: 5000000,
        p_is_active: "1",
      };
      if(updatedSelection?.length) {
        requestBody.p_category_id = updatedSelection[updatedSelection.length - 1];
      }
      getAllProductService(requestBody, token, dispatch, true, true);
  };

  // Helper function to check if a category is a child of another category
  const isChildCategory = (id, parentId) => {
    const category = categories.find((cat) => cat.categoryId === id);
    if (!category) return false;
    return category.parentCategoryID === parentId;
  };

  const getParentPath = (categoryId) => {
    const category = categories.find((cat) => cat.categoryId === categoryId);
    if (category && category.parentCategoryID) {
      return [...getParentPath(category.parentCategoryID), categoryId];
    }
    return [categoryId];
  };

  // Recursive function to render categories
  const renderCategories = (categories, parentId = 0) => {
    const childCategories = categories.filter(
      (category) => category.parentCategoryID === parentId
    );

    if (!childCategories.length) return null;

    return (
      <>
        <div
          className={
            "hide-scrollbar" + (parentId ? " sub-category" : " category")
          }
        >
          <ul
            className={parentId === 0 ? "category-list" : "sub-category-list"}
          >
            {childCategories.map((category) => (
              <React.Fragment key={category.categoryId}>
                <li
                  className={
                    selectedCategories.includes(category.categoryId)
                      ? parentId === 0
                        ? "cat-active"
                        : "sub-active"
                      : ""
                  }
                  onClick={() => toggleCategory(category.categoryId)}
                >
                  <span>{category.name}</span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
        {childCategories.map((category) => (
          <React.Fragment key={category.categoryId}>
            {selectedCategories.includes(category.categoryId) && (
              <>{renderCategories(categories, category.categoryId)}</>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      {categories?.length
        ? renderCategories(categories)
        : "No categories available"}
    </>
  );
};

export default CategoryTree;
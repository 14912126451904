import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Load translations dynamically
import enTranslation from './locales/en/translation.json';
import grTranslation from './locales/gr/translation.json';
import hiTranslation from './locales/hi/translation.json';
import mrTranslation from './locales/mr/translation.json';

i18n
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n instance to React
  .init({
    resources: {
      en: { translation: enTranslation },
      gr: { translation: grTranslation },
      hi: { translation: hiTranslation },
      mr: { translation: mrTranslation },
    },
    fallbackLng: 'en', // Default language
    debug: true,       // Enable console debugging
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

export default i18n;

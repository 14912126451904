import {
  CLEAR_ALL_PREFERENCE_PAGE,
  GET_ALL_PREFERENCE_PAGE,
} from "../../ReduxKeys";

const allPreferencesPageInitialState = {
  items: 1,
};

/**
 * Store All Preferences page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const AllPreferencesPageReducer = (
  state = allPreferencesPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_PREFERENCE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_ALL_PREFERENCE_PAGE:
      return allPreferencesPageInitialState;
    default:
      return state;
  }
};
import { CLEAR_BRANCH_PAGE, GET_BRANCH_PAGE } from "../../ReduxKeys";

/**
 * Setting branch page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getBranchPageData = (page) => ({
  type: GET_BRANCH_PAGE,
  payload: page,
});

/**
 * clearing branch page data
 * @returns {*}
 */
export const clearBranchPageData = () => ({
  type: CLEAR_BRANCH_PAGE,
});
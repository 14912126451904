import {
  CLEAR_ALL_PREFERENCE_DATA,
  GET_ALL_PREFERENCE_DATA,
} from "../../ReduxKeys";

/**
 * Setting all Preferences list data into reducer
 * @param {*} preferences
 * @returns {*}
 */
export const getAllPreferencesData = (allPreferences) => ({
  type: GET_ALL_PREFERENCE_DATA,
  payload: allPreferences,
});

/**
 * clearing all Preferences list data
 * @returns {*}
 */
export const clearAllPreferencesData = () => ({
  type: CLEAR_ALL_PREFERENCE_DATA,
});
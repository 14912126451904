import {
  CLEAR_TOTAL_VENDOR_DATA,
  CLEAR_VENDOR_DATA,
  GET_TOTAL_VENDOR_DATA,
  GET_VENDOR_DATA,
} from "../../ReduxKeys";

const vendorInitialState = {
  items: [],
};

/**
 * Store Vendor list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const VendorReducer = (state = vendorInitialState, action) => {
  switch (action.type) {
    case GET_VENDOR_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_VENDOR_DATA:
      return vendorInitialState;
    default:
      return state;
  }
};

const totalVendorInitialState = {
  items: [],
};

/**
 * Store total Vendor list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalVendorReducer = (state = totalVendorInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_VENDOR_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_VENDOR_DATA:
      return totalVendorInitialState;
    default:
      return state;
  }
};
import { CLEAR_SERVICE_FILTER, GET_SERVICE_FILTER } from "../../ReduxKeys";

const ServiceFilterInitialState = {
  name: "",
  category: "",
  subCategory: "",
  tag: "",
  branch: "",
  brand: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store service filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ServiceFilterReducer = (
  state = ServiceFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_SERVICE_FILTER:
      return {
        ...state,
        name: action.payload.name,
        category: action.payload.category,
        subCategory: action.payload.subCategory,
        tag: action.payload.tag,
        branch: action.payload.branch,
        brand: action.payload.brand,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_SERVICE_FILTER:
      return ServiceFilterInitialState;
    default:
      return state;
  }
};
import { CLEAR_SPOT_FILTER, GET_SPOT_FILTER } from "../../ReduxKeys";

const spotFilterInitialState = {
  p_name: "",
  p_is_available: "",
  p_is_active: "",
  p_is_deleted: "",
};

/**
 * Store spot filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */

export const spotFilterReducer = (state = spotFilterInitialState, action) => {
  switch (action.type) {
    case GET_SPOT_FILTER:
      return {
        ...state,
        p_name: action.payload.name,
        p_is_available: action.payload.p_is_available,
        p_is_active: action.payload.p_is_active,
        p_is_deleted: action.payload.p_is_deleted,
      };
    case CLEAR_SPOT_FILTER:
      return spotFilterInitialState;
    default:
      return state;
  }
};
import { CLEAR_WAREHOUSE_PAGE, GET_WAREHOUSE_PAGE } from "../../ReduxKeys";

/**
 * Set warehouse page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getWarehousePageData = (page) => ({
  type: GET_WAREHOUSE_PAGE,
  payload: page,
});

/**
 * Clear warehouse page data
 * @returns {*}
 */
export const clearWarehousePageData = () => ({
  type: CLEAR_WAREHOUSE_PAGE,
});
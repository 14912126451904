import { CLEAR_INVENTORY_FILTER, GET_INVENTORY_FILTER } from "../../ReduxKeys";

/**
 * Set inventory filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getInventoryFilterData = ({
  productCode,
  sku,
  warehouse,
  name,
  isActive,
  isDelete,
}) => ({
  type: GET_INVENTORY_FILTER,
  payload: { productCode, sku, warehouse, name, isActive, isDelete },
});

/**
 * clear inventory filtered data
 * @returns {*}
 */
export const clearInventoryFilterData = () => ({
  type: CLEAR_INVENTORY_FILTER,
});
import { parseImage } from "../../../services/Regular-services/ImageService";

export const SubCategoryToggleRequest = ({
  subCategoryDetail,
  userId,
  isActive,
  isDelete,
}) => {
  const subCategoryImage = subCategoryDetail.data.image_data
    ? parseImage(JSON.stringify(subCategoryDetail.data.image_data))
    : null;

  const subCategoryImageData = subCategoryImage
    ? subCategoryImage.map((item) => ({
        id: parseInt(item.id),
        type_id: parseInt(item.type),
      }))
    : [];

  const requestBody = {
    p_id: subCategoryDetail.data.id,
    p_categoryid: subCategoryDetail.data.category_id,
    p_name: subCategoryDetail.data.name,
    p_description: subCategoryDetail.data.description,
    p_subcategorycode: subCategoryDetail.data.sub_category_code,
    p_isActive: isActive
      ? subCategoryDetail.data.is_active === "1"
        ? "0"
        : "1"
      : subCategoryDetail.data.is_active,
    p_type: subCategoryDetail.data.type,
    p_deleted: isDelete ? "0" : subCategoryDetail.data.IsDeleted,
    p_createdBy: subCategoryDetail.data.created_by,
    p_modifiedBy: userId,
    p_imageIds: subCategoryImageData,
  };
  return requestBody;
};
import "../Roles/Roles.scss";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../components/Button/Button";
import Toggle from "../../components/Toggle/Toggle";
import CustomTable from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { useEffect, useRef, useState } from "react";
import Filter from "../../components/Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import ExcelData from "../../services/Regular-services/ExcelExport";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination.js";
import {
  clearRolePageData,
  getRolePageData,
} from "../../redux/Actions/rolesAction/RolesPageAction.js";
import { clearRoleData } from "../../redux/Actions/rolesAction/RolesAction";
import { clearRoleFilterData } from "../../redux/Actions/rolesAction/RolesFilterAction";
import { useTranslation } from "react-i18next";
import RoleServices from "./Role-Service/roleService";

//Show keys which is to be displayed in table
const showKey = {
  title: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

export default function Roles() {
  const { getRolesService } = RoleServices();
  const { t } = useTranslation();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [roleActionData, setRoleActionData] = useState([]);
  const localization = useSelector((state) => state.localization?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setRoleActionData(actionData);
    }
  }, [sideBarData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (roleActionData[0]?.view && roleActionData[0].view === "1") ||
      !roleActionData
        ? true
        : false,
    showEdit:
      (roleActionData[0]?.edit && roleActionData[0].edit === "1") ||
      !roleActionData
        ? true
        : false,
    showDelete:
      (roleActionData[0]?.delete && roleActionData[0].delete === "1") ||
      !roleActionData
        ? true
        : false,
    showUndo:
      (roleActionData[0]?.edit && roleActionData[0].edit === "1") ||
      !roleActionData
        ? true
        : false,
    navigate:
      (roleActionData[0]?.edit && roleActionData[0].edit === "1") ||
      !roleActionData
        ? "/addRoles"
        : false,
  };

  const [showActionBool, setShowActionBool] = useState(false);

  const [columns, setColumns] = useState([
    {
      name: t("columns.designation_name"),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: t("columns.description"),
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: t("columns.status"),
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: t("columns.actions"),
      selector: (row) => row.edit,
    },
  ]);

  useEffect(() => {
    if (
      roleActionData[0]?.view &&
      roleActionData[0].view === "0" &&
      roleActionData[0]?.edit &&
      roleActionData[0].edit === "0" &&
      roleActionData[0]?.add &&
      roleActionData[0].add === "0" &&
      roleActionData[0]?.delete &&
      roleActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: t("columns.designation_name"),
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: t("columns.description"),
          selector: (row) => row.description,
          sortable: true,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: t("columns.designation_name"),
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: t("columns.description"),
          selector: (row) => row.description,
          sortable: true,
        },
        {
          name: t("columns.status"),
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: t("columns.actions"),
          selector: (row) => row.edit,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [roleActionData, localization]);

  const roleFilter = useSelector((state) => state.roleFilter);

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const navigate = useNavigate();
  const rolesData = useSelector((state) => state.roles);
  const dispatch = useDispatch();
  const token = UtilsData();
  const rolePage = useSelector((state) => state.rolePage.items);

  const [currentPageNumber, setCurrentPage] = useState(1); // Assuming initial page is 1

  useEffect(() => {
    setCurrentPage(rolePage);
  }, [rolePage]);

  /**
   * Fetch role list
   */
  const fetchRole = () => {
    if (rolePage > 1) {
      const roleRequestBody = {
        p_skip: (rolePage - 2) * 10,
        p_take: 10,
      };
      if (roleFilter.name !== "" && roleFilter.name !== null) {
        roleRequestBody.p_search_title = roleFilter.name;
      }
      if (roleFilter.isActive !== "" && roleFilter.isActive !== null) {
        roleRequestBody.p_is_active = roleFilter.isActive;
      } else {
        roleRequestBody.p_is_active = "1";
      }
      if (roleFilter.isDelete !== "" && roleFilter.isDelete !== null) {
        roleRequestBody.p_IsDeleted = roleFilter.isDelete;
      }
      setCurrentPage(rolePage - 1); // Update the current page state
      dispatch(getRolePageData(rolePage - 1));
      getRolesService(roleRequestBody, token, dispatch);
    } else {
      const roleRequestBody = {
        p_skip: (rolePage - 1) * 10,
        p_take: 10,
      };
      if (roleFilter.name !== "" && roleFilter.name !== null) {
        roleRequestBody.p_search_title = roleFilter.name;
      }
      if (roleFilter.isActive !== "" && roleFilter.isActive !== null) {
        roleRequestBody.p_is_active = roleFilter.isActive;
      } else {
        roleRequestBody.p_is_active = "1";
      }
      if (roleFilter.isDelete !== "" && roleFilter.isDelete !== null) {
        roleRequestBody.p_IsDeleted = roleFilter.isDelete;
      }
      getRolesService(roleRequestBody, token, dispatch);
    }
  };

  useEffect(() => {
    if (rolesData && rolesData.items && rolesData.items.length === 0) {
      fetchRole();
    } else {
      if (
        roleFilter.name !== "" ||
        roleFilter.isActive !== "" ||
        roleFilter.isDelete !== ""
      ) {
        // handleShow();
      }
    }
    // eslint-disable-next-line
  }, [rolesData]);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    const roleRequestBody = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
    };
    if (roleFilter.name !== "" && roleFilter.name !== null) {
      roleRequestBody.p_search_title = roleFilter.name;
    }
    if (roleFilter.isActive !== "" && roleFilter.isActive !== null) {
      roleRequestBody.p_is_active = roleFilter.isActive;
    } else {
      roleRequestBody.p_is_active = "1";
    }
    if (roleFilter.isDelete !== "" && roleFilter.isDelete !== null) {
      roleRequestBody.p_IsDeleted = roleFilter.isDelete;
    }
    if (pageNumber !== currentPageNumber) {
      getRolesService(roleRequestBody, token, dispatch);
    }
    setCurrentPage(pageNumber); // Update the current page state
    dispatch(getRolePageData(pageNumber));
  };

  /**
   * Clear filtered data
   */
  const clearRoleFilters = () => {
    dispatch(clearRoleFilterData());
    dispatch(clearRolePageData());
    dispatch(clearRoleData());
    handleClose();
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {roleActionData && roleActionData.length > 0
                    ? getRouteNameFromSidebar(
                        roleActionData[0].name,
                        localisation
                      )
                    : t("Designation")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {roleActionData && roleActionData.length > 0
                    ? getRouteNameFromSidebar(
                        roleActionData[0].name,
                        localisation
                      )
                    : t("Designation")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {(roleFilter.name !== "" ||
                roleFilter.isActive !== "" ||
                roleFilter.isDelete !== "") && (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearRoleFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="roles" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>

              {rolesData &&
                rolesData.items &&
                rolesData.items[0]?.data &&
                rolesData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={rolesData.items[0].data}
                    fileName={"roles"}
                    keys={["Permissions"]}
                  ></ExcelData>
                )}
              {((roleActionData[0]?.add && roleActionData[0].add === "1") ||
                !roleActionData) && (
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Roles"
                  onClick={() => navigate("/addRoles")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              )}
            </div>
          </div>

          {/* {isToggled && (
              <Filter
                page="roles"
                handleButtonClick={handleButtonClick}
              ></Filter>
            )} */}
          {rolesData && rolesData.items[0]?.data && rolesData.items[0].data && (
            <>
              <CustomTable
                page="roles"
                showDescription={true}
                showToggle={showToggle}
                checkBool={false}
                showKey={showKey}
                showActions={showActionBool ? showActions : false}
                showTooltip={true}
                data={rolesData.items[0].data}
                heading={columns}
                disabled={
                  roleActionData[0]?.edit && roleActionData[0].edit === "0"
                }
                pageData={rolePage}
              />

              {rolesData.items[0].total_records >
                rolesData.items[0].data.length &&
                rolesData.items[0].total_records > 10 && (
                  <CustomPagination
                    totalRecords={rolesData.items[0].total_records}
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                  />
                )}
            </>
          )}
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

import { CLEAR_TAG_PAGE, GET_TAG_PAGE } from "../../ReduxKeys";

const tagPageInitialState = {
  items: 1,
};

/**
 * Store tag page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const TagPageReducer = (state = tagPageInitialState, action) => {
  switch (action.type) {
    case GET_TAG_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_TAG_PAGE:
      return tagPageInitialState;
    default:
      return state;
  }
};
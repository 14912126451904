import { Dispatch } from "redux";
import { SidebarResponse } from "./SidebarModal";
import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
} from "../../../Utils/Utils";
import { getSidebarData } from "../../../redux/Actions/SidebarAction/SidebarAction";
import { useSelector } from "react-redux";
import { getApiService } from "../../../services/API-services/GetService";
import { useTranslation } from "react-i18next";

export default function SidebarServices() {
  /*
   * Service to get sidebar by role id
   */
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  let errorShow = false;
  const getSideBarByRoleId = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<SidebarResponse> => {
    return new Promise<SidebarResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.SIDEBAR + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(getSidebarData(response.data));
          resolve(response);
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };
  return { getSideBarByRoleId };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkox-heading {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.line-level-checkbox {
  align-items: center;
}

.ingredients-title {
  margin-top: 0 !important;
}

.paragraph-titletwo {
  margin-bottom: 0 !important;
}

.vendor-checkbox-input {
  width: 15px;
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendors/AddVendors/AddVendors.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EAEA,QAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAEA;EACE,2BAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;AAEF","sourcesContent":[".checkox-heading {\n  display: flex;\n  flex-direction: row;\n  // margin-right: 2px;\n  gap: 2px;\n  align-items: center;\n}\n\n.line-level-checkbox {\n  align-items: center;\n}\n\n.ingredients-title {\n  margin-top: 0 !important;\n}\n.paragraph-titletwo {\n  margin-bottom: 0 !important;\n}\n.vendor-checkbox-input {\n  width: 15px;\n  height: 15px;\n}\n// .note-msg{\n    \n// }\n// .vendor-item {\n//   border: 1px solid #000;\n//   padding: 10px 0;\n//   margin-bottom: 7px;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

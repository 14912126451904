import { SET_NOTIFICATION_SOUND } from "../../ReduxKeys";

const notificationInitialState = {
  sound: true,
};

/**
 * Notification list reducer
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const notificationSoundReducer = (
  state = notificationInitialState,
  action
) => {
  switch (action.type) {
    case SET_NOTIFICATION_SOUND:
      return {
        ...state,
        sound: action.payload,
      };
    default:
      return state;
  }
};
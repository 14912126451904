import {
  CLEAR_CUSTOMER_DATA,
  CLEAR_TOTAL_CUSTOMER,
  GET_CUSTOMER_DATA,
  GET_TOTAL_CUSTOMER,
} from "../../ReduxKeys";

/**
 * Set cltegory list data into reducer
 * @param {*} CUSTOMER
 * @returns {*}
 */
export const getCustomerData = (CUSTOMER) => ({
  type: GET_CUSTOMER_DATA,
  payload: CUSTOMER,
});

/**
 * Clear CUSTOMER list data
 * @returns {*}
 */
export const clearCustomerData = () => ({
  type: CLEAR_CUSTOMER_DATA,
});

/**
 * Set total CUSTOMER list data into reducer (No pagination)
 * @param {*} CUSTOMER
 * @returns {*}
 */
export const getTotalCustomerData = (CUSTOMER) => ({
  type: GET_TOTAL_CUSTOMER,
  payload: CUSTOMER,
});

/**
 * Clear total CUSTOMER list data (No pagination)
 * @returns {*}
 */
export const clearTotalCustomer = () => ({
  type: CLEAR_TOTAL_CUSTOMER,
});
import { CLEAR_BRANCH_FILTER, GET_BRANCH_FILTER } from "../../ReduxKeys";

/**
 * Setting branch filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getBranchFilterData = ({
  name,
  pincode,
  isActive,
  isDelete,
  isPublicAvailable,
  isWpSmsAllow,
  isDeliveryAvailable,
  taxType,
}) => ({
  type: GET_BRANCH_FILTER,
  payload: {
    name,
    pincode,
    isActive,
    isDelete,
    isPublicAvailable,
    isWpSmsAllow,
    isDeliveryAvailable,
    taxType,
  },
});

/**
 * Clearing branch filtered data
 * @returns {*}
 */
export const clearBranchFilterData = () => ({
  type: CLEAR_BRANCH_FILTER,
});
import {
  CLEAR_TOTAL_UNIT_DATA,
  CLEAR_UNIT_DATA,
  GET_TOTAL_UNIT_DATA,
  GET_UNIT_DATA,
} from "../../ReduxKeys";

const unitsInitialState = {
  items: [],
};

/**
 * Store Units list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const UnitsReducer = (state = unitsInitialState, action) => {
  switch (action.type) {
    case GET_UNIT_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_UNIT_DATA:
      return unitsInitialState;
    default:
      return state;
  }
};

const totalUnitsInitialState = {
  items: [],
};

/**
 * Store total units list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalUnitsReducer = (state = totalUnitsInitialState, action) => {
  switch (action.type) {
    case GET_TOTAL_UNIT_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_UNIT_DATA:
      return totalUnitsInitialState;
    default:
      return state;
  }
};
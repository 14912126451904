import {
  CLEAR_DISCOUNT_CATALOGUE_PAGE,
  GET_DISCOUNT_CATALOGUE_PAGE,
} from "../../ReduxKeys";

/**
 * Set discount catalogue page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getDiscountCataloguePageData = (page) => ({
  type: GET_DISCOUNT_CATALOGUE_PAGE,
  payload: page,
});

/**
 * clear discount catalogue page data
 * @returns {*}
 */
export const clearDiscountCataloguePageData = () => ({
  type: CLEAR_DISCOUNT_CATALOGUE_PAGE,
});
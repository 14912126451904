import {
  CLEAR_DISCOUNT_DATA,
  CLEAR_TOTAL_DISCOUNT_DATA,
  GET_DISCOUNT_DATA,
  GET_TOTAL_DISCOUNT_DATA,
} from "../../ReduxKeys";

/**
 * Set discount list data into reducer
 * @param {*} discount
 * @returns {*}
 */
export const getDiscountData = (discount) => ({
  type: GET_DISCOUNT_DATA,
  payload: discount,
});

/**
 * clear discount list data
 * @returns {*}
 */
export const clearDiscountData = () => ({
  type: CLEAR_DISCOUNT_DATA,
});

/**
 * Set total discount list data into reducer (No pagination)
 * @param {*} discount
 * @returns {*}
 */
export const getTotalDiscountData = (discount) => ({
  type: GET_TOTAL_DISCOUNT_DATA,
  payload: discount,
});

/**
 * Clear total discount list data (No pagination)
 * @returns {*}
 */
export const clearTotalDiscountData = () => ({
  type: CLEAR_TOTAL_DISCOUNT_DATA,
});
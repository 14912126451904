import { CLEAR_SPOT_PAGE, GET_SPOT_PAGE } from "../../ReduxKeys";

/**
 * Set spot page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getSpotPageData = (page) => ({
  type: GET_SPOT_PAGE,
  payload: page,
});

/**
 * Clear spot page data
 * @returns {*}
 */
export const clearSpotPageData = () => ({
  type: CLEAR_SPOT_PAGE,
});

import { CLEAR_IMAGE_FILTER, GET_IMAGE_FILTER } from "../../ReduxKeys";

/**
 * Set image filtered list data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getImageFilterData = ({ type, isDelete }) => ({
  type: GET_IMAGE_FILTER,
  payload: { type, isDelete },
});

/**
 * clear image filtered list data from reducer
 * @returns {*}
 */
export const clearImageFilterData = () => ({
  type: CLEAR_IMAGE_FILTER,
});
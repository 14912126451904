import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExpenseGraphService from "./ExpenseGraphService/ExpenseGraphService";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import { clearExpenseGraphFilterData } from "../../redux/Actions/ExpenseAction/ExpenseGraphFilterAction";
import { clearExpenseGraphData } from "../../redux/Actions/ExpenseAction/ExpenseGraphAction";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { formatDateToYYYYMMDD } from "../../services/Regular-services/DateService";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { FaFilter } from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import Button from "../../components/Button/Button";

const ExpenseGraph = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getExpenseGraphService } = ExpenseGraphService();
  const expenseGraph = useSelector((state) => state.expenseGraph);
  const expenseGraphFilter = useSelector((state) => state.expenseGraphFilter);
  const [show, setShow] = useState(false);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [expenseGraphActionData, setExpenseGraphData] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const localisation = useSelector((state) => state.localization?.items);
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: t("Expense_Analytics")
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t("Total_Expense"),
        },
        ticks: {
        //   stepSize: 500, // Set fixed intervals of 500
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:  t("Date"),
        },
      },
    },
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "expense-analytics"
      );
      setExpenseGraphData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (expenseGraph && expenseGraph.items && expenseGraph.items.length === 0) {
      fetchExpenseGraph();
    } else {
      if (
        expenseGraph &&
        expenseGraph.items &&
        expenseGraph.items[0]?.data &&
        expenseGraph.items[0].data.length > 0
      ) {
        const data = expenseGraph.items[0].data;
        const labels = data.map((item) => {
          const date = new Date(item.expense_date);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        }); // Dates for x-axis

        const spendingData = data.map((item) => item.total_amount);
        setBarChartData({
          labels: labels.reverse(),
          datasets: [
            {
              label: t("Total_Expense"),
              data: spendingData.reverse(),
              backgroundColor: "rgba(75, 192, 192, 0.2)", // Customize the color
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        });
      }
    }
    // eslint-disable-next-line
  }, [expenseGraph, expenseGraphFilter, localisation]);

  const fetchExpenseGraph = () => {
    const requestBody = {
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_start_date: formatDateToYYYYMMDD(expenseGraphFilter.startDate),
      p_end_date: formatDateToYYYYMMDD(expenseGraphFilter.endDate),
    };
    if (
      expenseGraphFilter.category !== "" &&
      expenseGraphFilter.category !== null
    ) {
      requestBody.p_category_id = expenseGraphFilter.category;
    }
    // return;
    getExpenseGraphService(requestBody, tokenData, dispatch);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearFilters = () => {
    dispatch(clearExpenseGraphFilterData());
    dispatch(clearExpenseGraphData());
  };

  return (
    <div className="table-body-content">
      <div className="roles">
        <div>
          <div className="title">
            <h4>
              {expenseGraphActionData && expenseGraphActionData.length > 0
                ? getRouteNameFromSidebar(
                    expenseGraphActionData[0].name,
                    localisation
                  )
                : t("Expense Report")}
            </h4>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/home")}>
              {t("Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {expenseGraphActionData && expenseGraphActionData.length > 0
                ? getRouteNameFromSidebar(
                    expenseGraphActionData[0].name,
                    localisation
                  )
                : t("Expense Report")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-groups">
          {((expenseGraphFilter.startDate !== "" &&
            expenseGraphFilter.startDate !== null &&
            new Date(
              formatDateToYYYYMMDD(expenseGraphFilter.startDate)
            ).toDateString() !==
              new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toDateString()) ||
            (expenseGraphFilter.endDate !== "" &&
              expenseGraphFilter.endDate !== null &&
              new Date(
                formatDateToYYYYMMDD(expenseGraphFilter.endDate)
              ).toDateString() !== new Date().toDateString()) ||
            (expenseGraphFilter.category !== "" &&
              expenseGraphFilter.category !== null)) && (
            <Dropdown className="new-header-dropdown action-btn">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                data-bs-toggle="tooltip"
                title="Action"
              >
                <GrMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu className="event-clear-filter">
                <Dropdown.Item onClick={clearFilters}>
                  {t("clear_filters")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={handleShow}
            data-bs-toggle="tooltip"
            title="Filter"
            className="btn btn-fillter-main crm-filter"
            name={
              <div className="filter">
                <FaFilter className="filter-icon" />
              </div>
            }
          ></Button>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter page="expenseGraph" handleClose={handleClose}></Filter>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {expenseGraph &&
      expenseGraph.items &&
      expenseGraph.items[0]?.data &&
      expenseGraph.items[0].data.length > 0 ? (
        <div className="chart-container">
          <Bar data={barChartData} options={chartOptions} />
        </div>
      ) : (
        <div className="input-search">
          <div className="no-record">
            <h3 data-bs-toggle="tooltip" title="No records!">
              {t("no_records")}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpenseGraph;

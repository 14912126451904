import { CLEAR_PRODUCT_FILTER, GET_PRODUCT_FILTER } from "../../ReduxKeys";

/**
 * Set product filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getProductFilterData = ({
  name,
  category,
  subCategory,
  leafCategory,
  tags,
  sku,
  brand,
  isActive,
  isDelete,
}) => ({
  type: GET_PRODUCT_FILTER,
  payload: {
    name,
    category,
    subCategory,
    leafCategory,
    tags,
    sku,
    brand,
    isActive,
    isDelete,
  },
});

/**
 * Clear product filtered data
 * @returns {*}
 */
export const clearProductFilterData = () => ({
  type: CLEAR_PRODUCT_FILTER,
});
import {
  CLEAR_CUSTOMER_RUSH_DATA,
  GET_CUSTOMER_RUSH_DATA,
} from "../../ReduxKeys";

const customerRushInitialState = {
  items: [],
};

/**
 * Store customer rush list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerRushReducer = (
  state = customerRushInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_RUSH_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_CUSTOMER_RUSH_DATA:
      return customerRushInitialState;
    default:
      return state;
  }
};
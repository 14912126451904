import {
  CLEAR_SUB_CATEGORY_PAGE,
  GET_SUB_CATEGORY_PAGE,
} from "../../ReduxKeys";

/**
 * Set sub-category page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getSubCategoryPageData = (page) => ({
  type: GET_SUB_CATEGORY_PAGE,
  payload: page,
});

/**
 * Clear sub-category page data
 * @returns {*}
 */
export const clearSubCategoryPageData = () => ({
  type: CLEAR_SUB_CATEGORY_PAGE,
});
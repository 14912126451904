import { CLEAR_SALES_TABLE_DATA, GET_SALES_TABLE_DATA } from "../../ReduxKeys";

const salesTableDataInitialState = {
  items: [],
};

/**
 * Store sales table data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SalesTableDataReducer = (
  state = salesTableDataInitialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_TABLE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SALES_TABLE_DATA:
      return salesTableDataInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_VERIFY_ACCOUNT_DATA,
  GET_VERIFY_ACCOUNT_DATA,
} from "../../ReduxKeys";

/**
 * Setting fetched Verify Account after create Account data into reducer
 * @param {*} verifyAccount
 * @returns {*}
 */
export const getVerifyAccountData = (verifyAccount) => ({
  type: GET_VERIFY_ACCOUNT_DATA,
  payload: verifyAccount,
});

/**
 * clearing Verify Account list data
 * @returns {*}
 */
export const clearVerifyAccountData = () => ({
  type: CLEAR_VERIFY_ACCOUNT_DATA,
});
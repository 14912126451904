import { CLEAR_UNIT_PAGE, GET_UNIT_PAGE } from "../../ReduxKeys";

/**
 * Set inventory item page data
 * @param {*} page
 * @returns {*}
 */
export const getUnitsPageData = (page) => ({
  type: GET_UNIT_PAGE,
  payload: page,
});

/**
 * Clear units page data
 * @returns {*}
 */
export const clearUnitsPageData = () => ({
  type: CLEAR_UNIT_PAGE,
});
import { CLEAR_EVENT_LOG_PAGE, GET_EVENT_LOG_PAGE } from "../../ReduxKeys";

/**
 * Set event log table data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getEventLogPageData = (page) => ({
  type: GET_EVENT_LOG_PAGE,
  payload: page,
});

/**
 * Clear event log table data
 * @returns {*}
 */
export const clearEventLogPageData = () => ({
  type: CLEAR_EVENT_LOG_PAGE,
});
import {
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_CUSTOMER_RUSH_FILTER,
  GET_CUSTOMER_RUSH_FILTER,
} from "../../ReduxKeys";

const customerRushFilterInitialState = {
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
};

/**
 * Store event log filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerRushFilterReducer = (
  state = customerRushFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_RUSH_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case CLEAR_CUSTOMER_RUSH_FILTER:
      return customerRushFilterInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_VERIFY_ACCOUNT_DATA,
  GET_VERIFY_ACCOUNT_DATA,
} from "../../ReduxKeys";

const verifyAccountInitialState = {
  items: [],
};

/**
 * Store verifyAccount list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const VerifyAccountReducer = (
  state = verifyAccountInitialState,
  action
) => {
  switch (action.type) {
    case GET_VERIFY_ACCOUNT_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_VERIFY_ACCOUNT_DATA:
      return verifyAccountInitialState;
    default:
      return state;
  }
};
import {
  oneMonthsBefore,
  orderDateNew,
} from "../../../services/Regular-services/DateService";
import {
  CLEAR_EXPENSE_REPORT_FILTER,
  GET_EXPENSE_REPORT_FILTER,
} from "../../ReduxKeys";

const expenseGraphFilterInitialState = {
  startDate: oneMonthsBefore(),
  endDate: orderDateNew(),
  category: "",
};

/**
 * Store expense filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ExpenseGraphFilterReducer = (
  state = expenseGraphFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_EXPENSE_REPORT_FILTER:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        category: action.payload.category,
      };
    case CLEAR_EXPENSE_REPORT_FILTER:
      return expenseGraphFilterInitialState;
    default:
      return state;
  }
};
import { CHECBOX_ACTION } from "../../ReduxKeys";

/**
 * Set checkbox data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getCheckboxData = ({ data }) => ({
  type: CHECBOX_ACTION,
  payload: data,
});
import {
  CLEAR_CUSTOMER_ORDER_PAGE,
  GET_CUSTOMER_ORDER_PAGE,
} from "../../ReduxKeys";

const CustomerOrdersPageInitialState = {
  items: 1,
};

/**
 * Store CUSTOMER ORDERS page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const CustomerOrdersPageReducer = (
  state = CustomerOrdersPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDER_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_CUSTOMER_ORDER_PAGE:
      return CustomerOrdersPageInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useRef, useState } from "react";
import "./TableDashboard.scss";
import Button from "../../components/Button/Button";
import { GiWallet } from "react-icons/gi";
import { BsFillCartCheckFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineCancel, MdOutlineEdit } from "react-icons/md";
import { FiPrinter } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import Unoccupied from "../../assets/images/unoccupied.png";
import courier from "../../assets/images/courier.png";
import OrderServices from "../Order/OrderService/OrderService";
import SpotServices from "../Spot/SpotService/SpotService";
import { useTranslation } from "react-i18next";
import UtilsData, {
  getRouteNameFromSidebar,
  orderStatusTableArray,
  ValidationMessage,
} from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import generateInvoiceHtml from "../../components/generateInvoiceHtml/generateInvoiceHtml";
import { OrderArrayMapping } from "./OrderArrayMapping";
import OrderDetailContainer from "../../components/NewOrderCart/OrderDetailContainer/OrderDetailContainer";
import { OrderRequestBodyOnToggle } from "../Order/OrderRequestBodyOnToggle/OrderRequestBodyOnToggle";
import { AlertMessage } from "../../components/Alertmessage/Alertmessage";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Input from "../../components/input/Input";
import { OrderPrintHtml } from "../../components/Table/OrderPrintHtml";
import CustomTable from "../../components/Table/Table";
import unoccupied from "../../assets/images/unoccupied.png";

function TableDashboard() {
  const { uploadOrderPDFService, placeOrderService } = OrderServices();
  const { handleToggleChange } = AlertMessage();
  const { getAllSpotService } = SpotServices();
  const { t } = useTranslation();
  const tokenData = UtilsData();
  const localisation = useSelector((state) => state.localization?.items);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [orderActionData, setOrderActionData] = useState([]);
  const dispatch = useDispatch();
  const DashboardOrders = useSelector((state) => state.dashboardOrder);
  const spotData = useSelector((state) => state.spot);
  const [spotsOrdersData, setSpotsOrdersData] = useState([]);
  const authuserData = useSelector((state) => state.auth?.user);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [zomatoSwiggyModal, setZomatoSwiggyModal] = useState(false);
  const orderStatusArray = orderStatusTableArray["order"];
  const [initialValues, setInitialValues] = useState({
    zomatoSwiggyPrice: "",
  });

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setOrderActionData(actionData);
    }
    // eslint-disable-next-line
  }, [sideBarData]);

  const validationSchema = Yup.object({
    zomatoSwiggyPrice: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  });

  const [orderSummary, setOrderSummary] = useState({
    totalAmt: 0,
    totalOrder: 0,
  });
  const [selectedOrder, setSelectedOrder] = useState(null);

  const navigate = useNavigate();
  const [earningsModal, setEarningsModal] = useState(false);

  const showKey = {
    itemName: true,
    totalQtySold: true,
    totalAmountEarned: true,
  };
  const styleData = {
    totalQtySold: { type: { textAlign: "end" } },
    totalAmountEarned: { type: { textAlign: "end" } },
  };

  const [columns, setColumns] = useState([
    {
      name: t("columns.product_name"),
      selector: (row) => row.itemName,
    },
    {
      name: t("columns.total_quanity"),
      selector: (row) => row.totalQtySold,
    },
    {
      name: t("columns.total_earnings"),
      selector: (row) => row.totalAmountEarned,
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        name: t("columns.product_name"),
        selector: (row) => row.itemName,
      },
      {
        name: t("columns.total_quanity"),
        selector: (row) => row.totalQtySold,
      },
      {
        name: t("columns.total_earnings"),
        selector: (row) => row.totalAmountEarned,
      },
    ]);
    // eslint-disable-next-line
  }, [localisation]);

  const fetchSpot = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
      is_active: "1",
    };
    getAllSpotService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (
        spotData?.items?.length &&
        spotData.items[0]?.data &&
        spotData.items[0].data.length > 0
      ) {
        const ordersMap = DashboardOrders.items.reduce((acc, order) => {
          if (order.table_no) {
            acc[order.table_no] = acc[order.table_no] || [];
            acc[order.table_no].push(order);
          }
          return acc;
        }, {});
        const newSpotData = spotData.items[0].data.map((spot) => {
          const matchingOrder = ordersMap[spot.id] || [];
          const matchComplete = matchingOrder.filter(
            (item) => item.status !== "3" && item.status !== "4"
          );
          return {
            id: spot.id,
            name: spot.name,
            order: matchComplete.length > 0 ? matchComplete[0] : null,
          };
        });

        if (selectedOrder) {
          if (DashboardOrders.items?.length) {
            const newSelected = newSpotData.find(
              (item) => item.id === Number(selectedOrder.table_no)
            );

            if (newSelected) {
              if (newSelected?.order) {
                const transformedOrder = OrderArrayMapping(newSelected);
                if (selectedOrder !== transformedOrder) {
                  setSelectedOrder(transformedOrder);
                }
              } else {
                setSelectedOrder(null);
              }
            } else {
              setSelectedOrder(null);
            }
          }
        } else {
          if (DashboardOrders.items?.length) {
            const newTableOrders = DashboardOrders.items.filter(
              (item) =>
                item.type === "0" && item.status !== "3" && item.status !== "4"
            );

            const newSelected = newTableOrders?.length ? newSpotData.find(
              (item) => item.id === Number(newTableOrders[0]?.table_no)
            ) : null;
            // const newSelected = newTableOrders[0]
            if (newSelected) {
              if (newSelected?.order) {
                const transformedOrder = OrderArrayMapping(newSelected);
                setSelectedOrder(transformedOrder);
              } else {
                setSelectedOrder(null);
              }
            } else {
              setSelectedOrder(null);
            }
          }
        }

        setSpotsOrdersData(newSpotData);
      } else {
        setSpotsOrdersData([]);
      }
    }
    // eslint-disable-next-line
  }, [spotData, DashboardOrders]);

  useEffect(() => {
    const { totalAmount, count } = DashboardOrders.items.reduce(
      (acc, current) => {
        if (current.status === "3") {
          acc.totalAmount += parseFloat(current.order_amount);
          acc.count += 1;
        }
        return acc;
      },
      { totalAmount: 0, count: 0 }
    );

    const totalAmountWithRupeeSymbol = `₹${totalAmount.toFixed(2)}`;
    setOrderSummary({
      totalAmt: totalAmountWithRupeeSymbol,
      totalOrder: count,
    });
    if (DashboardOrders) {
      calculateTotals();
    }
  }, [DashboardOrders]);

  const handleNavigate = (route, item, isEdit) => {
    if (item) {
      if (item.order) {
        const transformedData = OrderArrayMapping(item);
        const Data = {
          data: transformedData,
          isEdit: isEdit,
        };
        if (!route) {
          // if (selectedOrder) {
          //   if (item.order?.orderId === selectedOrder?.orderId) {
          //     setSelectedOrder(null);
          //     return;
          //   }
          // }
          setSelectedOrder(transformedData);
        } else {
          navigate(route, { state: { Data } });
        }
      } else {
        const tableNo = {
          table_no: item.id,
          isTakeAway: false,
        };
        navigate(route, { state: { tableNo } });
      }
    } else {
      const tableNo = {
        takeAway: "1",
        isTakeAway: true,
      };
      navigate(route, { state: { tableNo } });
    }
  };

  const calculateTotals = (order) => {
    if (!order || !order.productData) return { totalItems: 0, totalServed: 0 };
    const products = JSON.parse(order.productData); // Parse the JSON string
    let totalItems = 0;
    let totalServed = 0;
    let totalOrderAmt = order.order_amount;
    const orderCreationTime = moment(order.createdAt);
    const now = moment();
    const updatedTime = orderCreationTime.add(10, "seconds");
    const totalTimeInSeconds = now.diff(updatedTime, "seconds");
    const totalTimeInHours = Math.floor(totalTimeInSeconds / 3600);
    const remainingMinutes = Math.floor((totalTimeInSeconds % 3600) / 60);
    const remainingSeconds = totalTimeInSeconds % 60;
    const totalTime = `${totalTimeInHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    products.forEach((product) => {
      totalItems += product.quantity;
      if (product.status === "1") {
        totalServed += product.quantity;
      }
    });
    return { totalItems, totalServed, totalOrderAmt, totalTime };
  };

  const printKOT = (item) => {
    const orderData = OrderArrayMapping(item);
    const printWindow = window.open("", "", "height=600,width=800");
    const htmlContent = OrderPrintHtml({ orderData });
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.print();
  };

  const printInvoice = (item) => {
    const orderData = OrderArrayMapping(item);
    const printWindow = window.open("", "", "height=600,width=800");
    const invoiceHtml1 = generateInvoiceHtml(orderData, authuserData);
    printWindow.document.open();
    printWindow.document.write(invoiceHtml1);
    printWindow.document.close();
    printWindow.print();
  };

  const generatePDFAndUpload = async (item) => {
    const { companyName } = authuserData.data;
    const orderData = OrderArrayMapping(item);
    const invoiceHtml1 = generateInvoiceHtml(orderData, authuserData, "wp");

    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.height = "297mm";
    tempDiv.style.overflow = "hidden";
    tempDiv.innerHTML = invoiceHtml1;
    document.body.appendChild(tempDiv);

    try {
      const canvas = await html2canvas(tempDiv, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output("blob");
      const file = new File(
        [pdfBlob],
        `invoice-${orderData?.orderNumber}.pdf`,
        {
          type: "application/pdf",
        }
      );

      const uploadData = {
        pdf: file,
        p_order_user: orderData.firstName + " " + orderData.lastName,
        p_order_phone_number: orderData.phoneNumber,
        p_total_amt: orderData.totalOrderWithTax,
        p_company_name: companyName,
      };

      dispatch(uploadOrderPDFService(uploadData, tokenData, navigate));
    } catch (error) {
      console.error("Error generating PDF or uploading:", error);
    } finally {
      document.body.removeChild(tempDiv);
    }
  };

  const [viewSalesData, setViewSalesData] = useState([]);

  const handleViewEarnings = () => {
    setEarningsModal(!earningsModal);
    if (!earningsModal) {
      const productData = DashboardOrders.items
        .filter((order) => order.status === "3" || order.status === 3)
        .map((order) => JSON.parse(order.productData));
      if (productData.length > 0) {
        const summary = {};

        productData.forEach((group) => {
          group.forEach((item) => {
            const {
              productName,
              quantity,
              price,
              product_discount_amount,
              sgst,
              cgst,
            } = item;
            if (!summary[productName]) {
              summary[productName] = { totalQty: 0, totalAmount: 0 };
            }

            const totalAmtWithDiscount =
              Number(quantity) * Number(price) -
              Number(product_discount_amount);
            const totalTaxOnPrice =
              totalAmtWithDiscount * ((Number(sgst) + Number(cgst)) / 100);
            const finaltotal = (taxAmt, price) => {
              const value = Number(taxAmt) + Number(price);
              return parseFloat(value.toFixed(2)); // Convert the string back to a number
            };

            summary[productName].totalQty += quantity;
            summary[productName].totalAmount += finaltotal(
              totalTaxOnPrice,
              totalAmtWithDiscount
            );
            // summary[productName].totalAmount += quantity * price;
          });
        });

        const result = Object.entries(summary).map(
          ([productName, { totalQty, totalAmount }]) => ({
            itemName: productName,
            totalQtySold: totalQty,
            totalAmountEarned: totalAmount,
          })
        );
        setViewSalesData(result);
      }
    }
  };

  const onChange = (value, item) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: item,
      status: value,
    });

    let orderStatusData = orderStatusTableArray["order"].find(
      (item) => item.value === value
    ).className;

    if (["1", "4"].includes(value)) {
      handleToggleChange(
        dispatch,
        "order",
        orderStatusData,
        tokenData,
        authuserData.data.userId,
        requestBody,
        spotsOrdersData?.length || 1,
        orderPage
      );
    } else {
      dispatch(
        placeOrderService(requestBody, tokenData, "update", false, orderPage)
      );
    }
  };

  const changeOrderPaymentStatus = (value, item) => {
    if (value === "5" || value === "6") {
      setZomatoSwiggyModal(!zomatoSwiggyModal);
      setInitialValues({
        zomatoSwiggyPrice: "",
        payment: value,
        item: item,
      });
    } else {
      const requestBody = OrderRequestBodyOnToggle({
        item: item,
        payment: value,
      });
      dispatch(
        placeOrderService(
          requestBody,
          tokenData,
          "update",
          false,
          orderPage,
          true
        )
      );
    }
  };

  const handleSubmit = (values) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: values.item,
      payment: values.payment,
      zomatoSwiggyPrice: values.zomatoSwiggyPrice,
    });

    dispatch(
      placeOrderService(
        requestBody,
        tokenData,
        "update",
        false,
        orderPage,
        true
      )
    );
  };

  const getOrderStatus = (item) => {
    const status =
      orderStatusArray.find((order) => order.value === item.order?.status) ||
      null;
    return status;
  };

  return (
    <div className="body-content">
      <div className="table-body-content new-table-body-content">
        <div>
          <div className="row">
            <div className={selectedOrder ? "col-7" : "col-7"}>
              <div className="new-table-design">
                <div className="new-table-titale">
                  <h4>
                    {orderActionData && orderActionData.length > 0
                      ? getRouteNameFromSidebar(
                          orderActionData[0].name,
                          localisation
                        )
                      : t("dashboard.Dashboard")}
                  </h4>
                  <div className="new-table-titale-icon">
                    <Button
                      className="btn btn-fillter-main"
                      name={<GiWallet className="filter-icon" />}
                      onClick={() => handleViewEarnings(!earningsModal)}
                      id="order-earning-count"
                      data-bs-toggle="tooltip"
                      title={t("dashboard.todays_total_earnings")}
                    />
                    <Button
                      className="btn btn-fillter-main"
                      name={
                        <>
                          <BsFillCartCheckFill className="filter-icon" />
                          &nbsp;
                          {orderSummary.totalOrder}
                        </>
                      }
                      id="order-completed-count"
                      data-bs-toggle="tooltip"
                      title={t("dashboard.total_completed_orders")}
                    />
                  </div>
                </div>
                <div className="main-table-scroll">
                  <div className="row">
                    <div
                      className={
                        selectedOrder
                          ? "col-6 mb-3"
                          : "col-6 mb-3"
                      }
                    >
                      <div className="chers-details">
                        <div className="chers chers-sitting-table"></div>
                        <div className="chers chers-sitting-table"></div>
                      </div>
                      <div
                        className="sitting-table Unoccupied-sitting-table"
                        onClick={() =>
                          handleNavigate("/add-order", false, true)
                        }
                      >
                        <div className="unoccupied-image">
                          <img src={courier} alt="Logo" />
                          <h5>{t("Take Away")}</h5>
                        </div>
                      </div>
                      <div className="chers-details">
                        <div className="chers chers-sitting-table"></div>
                        <div className="chers chers-sitting-table"></div>
                      </div>
                    </div>
                    {spotsOrdersData?.length > 0 ? (
                      spotsOrdersData.map((item, index) => {
                        const totals =
                          item?.order && calculateTotals(item.order);

                        const newStatus = getOrderStatus(item);
                        return (
                          <div
                            className={
                              selectedOrder
                                ? `${item?.order && OrderArrayMapping(item)?.orderId === selectedOrder?.orderId ? "table-active-bg" : ""} col-6 mb-3`
                                : " col-6 mb-3"
                            }
                            key={index}
                          >
                            
                            {item?.order ? (
                              <>
                                <div
                                  className="chers-details"
                                  onClick={() =>
                                    handleNavigate(null, item, false)
                                  }
                                >
                                  <div
                                    className={`chers ${
                                      newStatus
                                        ? newStatus?.className + "-chair"
                                        : ""
                                    }`}
                                  ></div>
                                  <div
                                    className={`chers ${
                                      newStatus
                                        ? newStatus?.className + "-chair"
                                        : ""
                                    }`}
                                  ></div>
                                </div>
                                <div
                                  className={`sitting-table ${
                                    newStatus
                                      ? newStatus?.className + "-color"
                                      : ""
                                  }`}
                                >
                                  <div className="sitting-table-title">
                                    <span
                                      onClick={() =>
                                        handleNavigate(null, item, false)
                                      }
                                      style={{ marginLeft: 3 }}
                                    >
                                      {t("status")} :{" "}
                                      {newStatus
                                        ? t(newStatus?.label)
                                        : item.order?.status}
                                    </span>
                                    <div className="icons">
                                      <Button
                                        title="Filter"
                                        className="btn all-btn new-pay-icon"
                                        onClick={() =>
                                          handleNavigate(
                                            "/edit-order",
                                            item,
                                            false
                                          )
                                        }
                                        name={
                                          <div className="filter">
                                            <IoEyeOutline className="new-cart-icon" />
                                          </div>
                                        }
                                      ></Button>
                                      <Button
                                        title="Filter"
                                        className="btn all-btn new-pay-icon"
                                        onClick={() => printInvoice(item)}
                                        name={
                                          <div className="filter">
                                            <FiPrinter className="new-cart-icon" />
                                          </div>
                                        }
                                      ></Button>
                                      <Button
                                        title="Filter"
                                        className="btn all-btn new-pay-icon"
                                        onClick={() =>
                                          handleNavigate(
                                            "/edit-order",
                                            item,
                                            true
                                          )
                                        }
                                        name={
                                          <div className="filter">
                                            <MdOutlineEdit className="new-cart-icon" />
                                          </div>
                                        }
                                      ></Button>
                                      <Button
                                        title="Filter"
                                        className="btn all-btn new-pay-icon"
                                        name={"KOT"}
                                        onClick={() => printKOT(item)}
                                      ></Button>
                                      {item.order?.phone_number &&
                                      ![
                                        "8155006366",
                                        8155006366,
                                        "N/A",
                                        "",
                                      ].includes(item.order.phone_number) ? (
                                        <Button
                                          title="Filter"
                                          className="btn all-btn new-pay-icon"
                                          onClick={() =>
                                            generatePDFAndUpload(item)
                                          }
                                          name={
                                            <div className="filter">
                                              <FaWhatsapp className="new-cart-icon" />
                                            </div>
                                          }
                                        ></Button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="sitting-table-details"
                                    onClick={() =>
                                      handleNavigate(null, item, false)
                                    }
                                  >
                                    <span>
                                      <b>{t("table_number")} :</b> {item?.name}
                                    </span>
                                    <span>
                                      <b>
                                        {t("placeholder_filter_order_no")} :
                                      </b>{" "}
                                      {item.order?.order_number}
                                    </span>
                                    <span>
                                      <b>{t("dashboard.total_items")} :</b>{" "}
                                      {totals?.totalItems}
                                    </span>
                                    <span>
                                      <b>{t("dashboard.total_served")} :</b>{" "}
                                      {totals?.totalServed}
                                    </span>
                                    <span>
                                      <b>{t("dashboard.order_amount")} :</b> ₹
                                      {totals?.totalOrderAmt}
                                    </span>
                                    <span>
                                      <b>{t("dashboard.time_from_order")} :</b>{" "}
                                      {totals?.totalTime}
                                    </span>
                                  </div>
                                </div>
                                <div className="chers-details">
                                  <div
                                    className={`chers ${
                                      newStatus
                                        ? newStatus?.className + "-chair"
                                        : ""
                                    }`}
                                  ></div>
                                  <div
                                    className={`chers ${
                                      newStatus
                                        ? newStatus?.className + "-chair"
                                        : ""
                                    }`}
                                  ></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="chers-details">
                                  <div className="chers chers-sitting-table"></div>
                                  <div className="chers chers-sitting-table"></div>
                                </div>
                                <div
                                  className="sitting-table Unoccupied-sitting-table"
                                  onClick={() =>
                                    handleNavigate("/add-order", item, true)
                                  }
                                >
                                  <div className="unoccupied-image">
                                    <img src={Unoccupied} alt="Logo" />
                                    <h5>
                                      {t("table")} - {item.name}
                                    </h5>
                                  </div>
                                </div>
                                <div className="chers-details">
                                  <div className="chers chers-sitting-table"></div>
                                  <div className="chers chers-sitting-table"></div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedOrder ? (
              <OrderDetailContainer
                selectedOrder={selectedOrder}
                onChange={onChange}
                changeOrderPaymentStatus={changeOrderPaymentStatus}
                colClass="col-5"
              />
            ) : (
              <>
                <div className="col-5 p-relative new-bg-12">
                <div className="no-data-card">
                  <img src={unoccupied} alt="" />
                  <h6 className="no-data-title mt-2">{t("No_Selected_Table")}</h6>
                  {/* <p className="no-data-description">
                    {t("no_products_added_massage")}
                  </p> */}
                </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {zomatoSwiggyModal && (
        <div className="modal-overlay">
          <div className="modal-content-order modal-">
            <div className="modal-header">
              <h4>{t("dashboard.salesDetails")}</h4>
              <button
                className="modal-close"
                onClick={() => setZomatoSwiggyModal(!zomatoSwiggyModal)}
              >
                <MdOutlineCancel />
              </button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {({ values }) => (
                  <Form>
                    <div className="add-roles-form row">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                {t("zomato_swiggy_price")}
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Input
                              type="text"
                              name="zomatoSwiggyPrice"
                              placeholder={t("zomato_swiggy_price")}
                              className="add-roles"
                              maxLength={10}
                              Regex={/[^0-9\s]/g}
                              value={values.zomatoSwiggyPrice}
                              hidelength="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="paragraph-editor">
                          <div className="btn-submit order-submit-btn">
                            <Button
                              type="submit"
                              name={t("update_order")}
                              className="btn login-btn place-order-btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      {earningsModal && (
        <div
          className="modal-overlay-order"
          onClick={(e) => {
            if (
              e.target.className &&
              typeof e.target.className === "string" &&
              e.target.className.includes("modal-overlay-order")
            ) {
              setEarningsModal(false);
            }
          }}
        >
          <div className="modal-content-order-1 modal-bg-order">
            <div className="modal-header">
              <h4>{t("dashboard.salesDetails")}</h4>
              <button
                className="modal-close-order"
                onClick={() => handleViewEarnings()}
              >
                <MdOutlineCancel />
              </button>
            </div>
            <div className="modal-body-order">
              <h6>
                {t("dashboard.todays_total_earnings")}: {orderSummary.totalAmt}
              </h6>
              {viewSalesData.length > 0 ? (
                <CustomTable
                  page="dashboard"
                  showToggle={false}
                  checkBool={false}
                  showKey={showKey}
                  showActions={false}
                  showTooltip={false}
                  data={viewSalesData}
                  heading={columns}
                  disabled={true}
                  currentPage={0}
                  styleData={styleData}
                />
              ) : (
                <p>{t("no_sales_data_available")}.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableDashboard;

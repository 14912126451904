import React, { useEffect, useState } from "react";
import "./Product.scss";
import Button from "../../components/Button/Button";
import CustomTable from "../../components/Table/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../components/Toggle/Toggle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import Accordion from "react-bootstrap/Accordion";
import { MdDeleteOutline, MdAdd, MdRemove } from "react-icons/md";
import UtilsData, { getRouteNameFromSidebar } from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import { clearProductFilterData } from "../../redux/Actions/productAction/ProductFilterAction";
import { clearProductData } from "../../redux/Actions/productAction/ProductAction";
import {
  clearProductPageData,
  getProductPageData,
} from "../../redux/Actions/productAction/ProductPageAction";
// import { TfiImport } from "react-icons/tfi";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import { useTranslation } from "react-i18next";
import ProductServices from "./ProductService/ProductService";

const showKey = {
  name: true,
  purchase_price: true,
  product_price: true,
};
//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

//Handle toggle change
const handleToggleChange = (id) => {};

export default function Product() {
  const { getAllProductService, uploadProductExcelService } = ProductServices();
  const { t } = useTranslation();
  //Getting data from reducer
  const productData = useSelector((state) => state.product);
  const navigate = useNavigate();
  const productFilter = useSelector((state) => state.productFilter);
  const productPage = useSelector((state) => state.productPage?.items);
  const authuserData = useSelector((state) => state.auth?.user);
  const localisation = useSelector((state) => state.localization?.items);
  const [currentPage, setCurrentPage] = useState(1);
  const [productList, setProductList] = useState([]);
  const token = UtilsData();
  const dispatch = useDispatch();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [productActionData, setproductActionData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setproductActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    const hasNoPermissions =
      productActionData[0]?.view === "0" &&
      productActionData[0]?.edit === "0" &&
      productActionData[0]?.add === "0" &&
      productActionData[0]?.delete === "0";

    const commonColumns = [
      { name: t("columns.name"), selector: (row) => row.Name },
      {
        name: t("columns.purchase_price"),
        selector: (row) => row.purchase_price,
      },
      { name: t("columns.MRP"), selector: (row) => row.mrp },
      {
        name: t("columns.status"),
        cell: (row) => (
          <Toggle
            checked={row.toggle}
            onChange={() => handleToggleChange(row.id)}
          />
        ),
      },
    ];

    setColumns(
      hasNoPermissions
        ? commonColumns
        : [
            ...commonColumns,
            {
              name: t("columns.actions"),
              selector: (row) => row.actions,
            },
          ]
    );
    setShowActionBool(!hasNoPermissions);
    // eslint-disable-next-line
  }, [productActionData, localisation]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (productActionData[0]?.view && productActionData[0].view === "1") ||
      !productActionData
        ? false
        : false,
    showEdit:
      (productActionData[0]?.edit && productActionData[0].edit === "1") ||
      !productActionData
        ? true
        : false,
    showDelete:
      (productActionData[0]?.delete && productActionData[0].delete === "1") ||
      !productActionData
        ? true
        : false,
    showUndo:
      (productActionData[0]?.edit && productActionData[0].edit === "1") ||
      !productActionData
        ? true
        : false,
    // showReview: true,
    navigate:
      (productActionData[0]?.edit && productActionData[0].edit === "1") ||
      !productActionData
        ? "/add-product"
        : false,
  };

  useEffect(() => {
    setCurrentPage(productPage);
  }, [productPage]);

  const [expandedItem, setExpandedItem] = useState(null);

  const toggleAccordionItem = (eventKey) => {
    setExpandedItem((prevExpandedItem) =>
      prevExpandedItem === eventKey ? null : eventKey
    );
  };

  /**
   * Fetch product data
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10,
    };
    if (productPage > 1) {
      requestBody.p_skip = (productPage - 2) * 10;
      dispatch(getProductPageData(productPage - 1));
    } else {
      requestBody.p_skip = (productPage - 1) * 10;
      dispatch(getProductPageData(productPage));
    }
    if (productFilter.name !== "" && productFilter.name !== null) {
      requestBody.p_name = productFilter.name;
    }
    if (productFilter.brand !== "" && productFilter.brand !== null) {
      requestBody.p_brand_id = productFilter.brand;
    }
    if (
      productFilter.category !== "" &&
      productFilter.category !== null &&
      productFilter.category.length > 0
    ) {
      requestBody.p_category_id = productFilter.category;
    }
    if (
      productFilter.subCategory !== "" &&
      productFilter.subCategory !== null &&
      productFilter.subCategory.length > 0
    ) {
      requestBody.p_sub_category_id = productFilter.subCategory;
    }
    if (
      productFilter.leafCategory !== "" &&
      productFilter.leafCategory !== null &&
      productFilter.leafCategory.length > 0
    ) {
      requestBody.p_leaf_level_category_id = productFilter.leafCategory;
    }
    if (
      productFilter.tags !== "" &&
      productFilter.tags !== null &&
      productFilter.tags.length > 0
    ) {
      requestBody.p_tag_id = productFilter.tags.join(",");
    }
    if (productFilter.isActive !== "" && productFilter.isActive !== null) {
      requestBody.p_is_active = productFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
      requestBody.p_IsDeleted = productFilter.isDelete;
    }
    getAllProductService(requestBody, token, dispatch);
  };

  useEffect(() => {
    if (productData && productData.items && productData.items.length === 0) {
      fetchProduct();
    } else {
      if (
        productFilter.name !== "" ||
        productFilter.brand !== "" ||
        (productFilter.category && productFilter.category.length > 0) ||
        (productFilter.subCategory !== null &&
          productFilter.subCategory.length > 0) ||
        (productFilter.leafCategory && productFilter.leafCategory.length > 0) ||
        (productFilter.tags !== null && productFilter.tags.length > 0) ||
        productFilter.isActive !== "" ||
        productFilter.isDelete !== ""
      ) {
        // handleShow();
      }
    }

    if (productData.items[0]?.data && productData.items[0].data.length > 0) {
      const transformedAdminData = productData.items[0].data.map((item) => ({
        productId: item.productId,
        name: item.name,
        purchase_price: item?.purchase_price
          ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            item.purchase_price
          : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + 0,
        product_price: item?.price
          ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            item.price
          : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + 0,
        brandName: item.brand_name,
        description: item.description,
        productCode: item.productCode,
        sku: item.skuCode,
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        isActive: item.isActive,
        isDeleted: item.isDeleted,
        modifiedBy: item.modifiedBy,
        modifiedAt: item.modifiedAt,
        veg_nonveg: item?.veg_nonveg,
        jain_regular: item?.jain_regular,
        filter: item?.filter,
        vegan_or_not: item?.vegan_or_not,
        jain_or_not: item?.jain_or_not,
      }));
      setProductList(transformedAdminData);
    } else {
      setProductList([]);
    }
    // eslint-disable-next-line
  }, [productData]);

  const styleData = {
    purchase_price: { type: { textAlign: "end" } },
    product_price: { type: { textAlign: "end" } },
  };

  /**
   * On page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (productFilter.name !== "" && productFilter.name !== null) {
        requestBody.p_name = productFilter.name;
      }
      if (productFilter.brand !== "" && productFilter.brand !== null) {
        requestBody.p_brand_id = productFilter.brand;
      }
      if (
        productFilter.category !== "" &&
        productFilter.category !== null &&
        productFilter.category.length > 0
      ) {
        requestBody.p_category_id = productFilter.category;
      }
      if (
        productFilter.subCategory !== "" &&
        productFilter.subCategory !== null &&
        productFilter.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id = productFilter.subCategory;
      }
      if (
        productFilter.leafCategory !== "" &&
        productFilter.leafCategory !== null &&
        productFilter.leafCategory.length > 0
      ) {
        requestBody.p_leaf_level_category_id = productFilter.leafCategory;
      }
      if (
        productFilter.tags !== "" &&
        productFilter.tags !== null &&
        productFilter.tags.length > 0
      ) {
        requestBody.p_tag_id = productFilter.tags.join(",");
      }
      if (productFilter.isActive !== "" && productFilter.isActive !== null) {
        requestBody.p_is_active = productFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
        requestBody.p_IsDeleted = productFilter.isDelete;
      }
      getAllProductService(requestBody, token, dispatch);
    }
    dispatch(getProductPageData(pageNumber));
    setCurrentPage(pageNumber);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Import modal open
   */
  // const handleImportClick = () => {
  //   setIsModalOpen(true);
  // };

  /**
   * Import modal close
   */
  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  /**
   * Handle file upload
   * @param {*} file
   */
  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadProductExcelService(file, token, setIsModalOpen));
  };

  /**
   * Function for clearing filtered data
   */
  const clearFilters = () => {
    dispatch(clearProductFilterData());
    dispatch(clearProductData());
    dispatch(clearProductPageData());
    handleClose();
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {productActionData && productActionData.length > 0
                    ? getRouteNameFromSidebar(
                        productActionData[0].name,
                        localisation
                      )
                    : t("Products")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {productActionData && productActionData.length > 0
                    ? getRouteNameFromSidebar(
                        productActionData[0].name,
                        localisation
                      )
                    : t("Products")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              {productFilter.name !== "" ||
              productFilter.brand !== "" ||
              (productFilter.category && productFilter.category.length > 0) ||
              (productFilter.subCategory &&
                productFilter.subCategory.length > 0) ||
              (productFilter.leafCategory &&
                productFilter.leafCategory.length > 0) ||
              (productFilter.tags && productFilter.tags.length > 0) ||
              productFilter.isActive !== "" ||
              productFilter.isDelete !== "" ? (
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={clearFilters}>
                      {t("clear_filters")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}

              {/* {((productActionData[0]?.add &&
                  productActionData[0].add === "1") ||
                  !productActionData) && (
                  <Dropdown className="new-header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <TfiImport />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleImportClick}>
                        Import
                      </Dropdown.Item>
                      <DownloadExcel
                        excelFile={sampleProductExcelFile}
                        fileName="Product-Import.xlsx"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}

              <FileUploadModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onFileUpload={handleFileUpload}
                isClose={isModalOpen}
              />

              <Button
                onClick={handleShow}
                data-bs-toggle="tooltip"
                title="Filter"
                // onClick={handleButtonClick}
                className="btn btn-fillter-main"
                name={
                  <div className="filter">
                    <FaFilter className="filter-icon" />
                  </div>
                }
              ></Button>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{t("filter_options")} </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Filter page="product" handleClose={handleClose}></Filter>
                </Offcanvas.Body>
              </Offcanvas>
              {productData &&
                productData.items &&
                productData.items[0]?.data &&
                productData.items[0].data.length > 0 && (
                  <ExcelData
                    excelData={productData.items[0].data}
                    fileName={"product"}
                  ></ExcelData>
                )}
              {productActionData &&
                productActionData[0]?.add &&
                productActionData[0].add === "1" && (
                  <Button
                    onClick={() => navigate("/add-product")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
            </div>
          </div>

          {productData &&
          productData.items &&
          productData.items[0]?.data &&
          productData.items[0].data.length > 0 ? (
            <>
              {Object.values(
                productList.reduce((acc, product) => {
                  if (!acc[product.productCode]) {
                    acc[product.productCode] = [];
                  }
                  acc[product.productCode].push(product);
                  return acc;
                }, {})
              ).map((products, index) => (
                <Accordion
                  key={index}
                  activeKey={expandedItem}
                  onSelect={toggleAccordionItem}
                  className="product-accordions"
                >
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header className="p-relative">
                      <div className="product-detail">
                        <div className="product-name">{products[0].name}</div>
                      </div>
                      <div className="delete-icon">
                        <MdDeleteOutline />
                      </div>
                      {expandedItem === index.toString() ? (
                        <MdRemove className="arrow-icon" />
                      ) : (
                        <MdAdd className="arrow-icon" />
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="product-detail-code">
                        <div className="product-code">
                          <span>{t("lable_sku")}</span>
                          {products[0].productCode}
                        </div>
                        <div className="product-toggle">
                          <span>{t("lable_inactive_all")}</span>
                          <Toggle />
                        </div>
                      </div>
                      <CustomTable
                        productLength={
                          productData.items[0].data.length &&
                          productData.items[0].data.length > 1
                            ? productData.items[0].data.length
                            : 1
                        }
                        keyData={index}
                        showImage={true}
                        page="product"
                        showToggle={showToggle}
                        showKey={showKey}
                        showActions={showActionBool ? showActions : false}
                        showTooltip={true}
                        data={products}
                        heading={columns}
                        disabled={
                          productActionData[0]?.edit &&
                          productActionData[0].edit === "0"
                        }
                        checkBool={false}
                        styleData={styleData}
                        pageData={productPage}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </>
          ) : (
            <div className="input-search">
              <div className="no-record">
                <h3 data-bs-toggle="tooltip" title="No records!">
                  {t("no_records")}
                </h3>
              </div>
            </div>
          )}
          {productData &&
            productData.items &&
            productData.items[0]?.data &&
            productData.items[0].data.length > 0 && (
              <>
                {productData.items[0].total_records >
                  productData.items[0].data.length &&
                  productData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={productData.items[0].total_records}
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
        </div>
      </div>
    </>
  );
}

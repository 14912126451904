import { Dispatch } from "redux";
import {
  addUpdateSettlementRequest,
  getAllDashboardOrderRequest,
  getAllOrderRequest,
  getSettlementRequest,
  getSettlementResponse,
  getUserDetailResponse,
  placeOrderRequest,
  placeProductRequest,
  UploadPDFRequest,
} from "./OrderModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  addOrderData,
  clearOrderData,
  clearTotalOrderData,
  getOrderData,
  getTotalOrderData,
} from "../../../redux/Actions/orderAction/OrderAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearOrderPageData,
  getOrderPageData,
} from "../../../redux/Actions/orderAction/OrderPAgeAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { formatDateToUTC } from "../../../services/Regular-services/DateService";
import { format } from "date-fns";
import {
  clearOrderFilterData,
  setClearLocalOrderFilter,
} from "../../../redux/Actions/orderAction/OrderFilterAction";
import {
  clearStockData,
  clearTotalStockData,
} from "../../../redux/Actions/StocksAction/StockAction";
import { clearTotalStockHistoryData } from "../../../redux/Actions/StockHistoryAction/StockHistoryAction";
import { postApiServiceWp } from "../../../services/API-services/PostServiceWp";
import {
  addDashboardOrderData,
  clearDashboardOrderData,
  clearTotalDashboardOrderData,
  getDashboardOrderData,
} from "../../../redux/Actions/orderAction/OrderDashboardAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearCustomerData } from "../../../redux/Actions/CustomerAction/CustomerAction";
import { clearWalletData } from "../../../redux/Actions/WalletAction/WalletAction";
import { clearTotalWalletAmt } from "../../../redux/Actions/WalletAction/WalletTotalAmtAction";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";
import { clearCustomerOrderData } from "../../../redux/Actions/CustomerAction/CustomerOrderAction";
import { clearCustomerFilterData } from "../../../redux/Actions/CustomerAction/CustomerFilterAction";
import { clearCustomerOrderPageData } from "../../../redux/Actions/CustomerAction/CustomerOrderPageAction";
import { clearSalesGraphData } from "../../../redux/Actions/AnalyticsActions/SalesGraphAction";
import { clearSalesGraphFilter } from "../../../redux/Actions/AnalyticsActions/SalesGraphFilterAction";
import { clearSalesTableData } from "../../../redux/Actions/AnalyticsActions/SalesTableAction";
import { clearSalesTableFilterData } from "../../../redux/Actions/AnalyticsActions/SalesTableFilterAction";
import { clearSalesTablePageData } from "../../../redux/Actions/AnalyticsActions/SalesTablePageAction";
import {
  clearSettlementData,
  getSettlementData,
} from "../../../redux/Actions/orderAction/SettlementAction";
import { clearSettlementGraphData } from "../../../redux/Actions/SettlementGraphAction/SettlementGraphAction";
import { clearSettlementGraphFilterData } from "../../../redux/Actions/SettlementGraphAction/SettlementGraphFilterAction";
import { clearCustomerRushData } from "../../../redux/Actions/CustomerRushAction/CustomerRushAction";
import { clearCustomerRushFilterData } from "../../../redux/Actions/CustomerRushAction/CustomerRushFilterAction";

let errorShow = false;

export default function OrderServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get Packager and Manufacturer list
   */
  const getAllOrderService = (
    orderListRequest: getAllOrderRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(orderListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalOrderData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(
              getOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: null,
              })
            );
          } else {
            if (response.data && response.data.length > 0) {
              const pendingCount = response.data.filter(
                (item: any) => item.status === "0"
              ).length;
              dispatch(
                getOrderData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: pendingCount,
                })
              );
            } else {
              dispatch(
                getOrderData({
                  order: response.data,
                  time: formatDateToUTC(new Date()),
                  totalRecords: response.total_records,
                  api: true,
                  dataCount: 0,
                })
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  const getAllTimeStampOrderService = (
    orderListRequest: getAllOrderRequest,
    token: string,
    dispatch: Dispatch
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const queryParams = Object.entries(orderListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (response.data && response.data.length > 0) {
            const pendingCount = response.data.filter(
              (item: any) => item.status === "0"
            ).length;
            dispatch(
              addOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: pendingCount,
              })
            );
          } else {
            dispatch(
              addOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: 0,
              })
            );
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
      }
    });
  };

  /*
   * Service to get individual order
   */
  const getOrderById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.ORDER + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating order
   */
  const placeOrderService = (
    placeOrderRequest: placeOrderRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number,
    payment?: boolean,
    printInvoice?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.PLACE_ORDER,
            token,
            placeOrderRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.PLACE_ORDER,
            token,
            placeOrderRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDashboardOrderData());
          dispatch(clearTotalDashboardOrderData());
          dispatch(clearTotalOrderData());
          dispatch(clearStockData());
          dispatch(clearTotalStockData());
          dispatch(clearTotalStockHistoryData());
          dispatch(clearCustomerData());
          dispatch(clearEventLogData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearCustomerOrderData());
          dispatch(clearCustomerFilterData());
          dispatch(clearCustomerOrderPageData());
          dispatch(clearSalesGraphData());
          dispatch(clearSalesGraphFilter());
          dispatch(clearSalesTableData());
          dispatch(clearSalesTableFilterData());
          dispatch(clearSalesTablePageData());
          dispatch(clearCustomerRushData());
          dispatch(clearCustomerRushFilterData());
          if (type === "add") {
            dispatch(clearOrderData());
            dispatch(clearOrderPageData());
            dispatch(clearOrderFilterData());
            toast.success(
              response?.message ? response.message : "Order added successfully!"
            );
            navigate("/orders");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              dispatch(clearOrderData());
              toast.success(
                response?.message
                  ? response.message
                  : "Order undeleted successfully!"
              );
            } else {
              if (navigate) {
                dispatch(clearOrderData());
                if (pageNumber) {
                  dispatch(getOrderPageData(pageNumber + 1));
                } else {
                  dispatch(clearOrderPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Order updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/orders");

                  if (printInvoice) {
                    printInvoice(placeOrderRequest);
                  }
                });
              } else {
                if (payment) {
                  if (pageNumber) {
                    dispatch(getOrderPageData(pageNumber + 1));
                    dispatch(clearOrderData());
                  } else {
                    dispatch(clearOrderPageData());
                    dispatch(clearOrderData());
                  }
                  toast.success(
                    response?.message
                      ? response.message
                      : "Payment status updated successfully!"
                  );
                } else {
                  if (pageNumber) {
                    dispatch(getOrderPageData(pageNumber + 1));
                    dispatch(clearOrderData());
                  } else {
                    dispatch(clearOrderPageData());
                    dispatch(clearOrderData());
                  }
                  toast.success(
                    response?.message
                      ? response.message
                      : "Order status updated successfully!"
                  );
                }
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getOrderPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  // changes by sakshi
  const LineLevelPlaceOrderService = (
    placeProductRequest: placeProductRequest,
    token: string,
    pageNumber?: number,
    updatedOrder?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await postApiService(
          API_URL.BASE_URL + API_URL.ORDER + "/" + API_URL.PRODUCT,
          token,
          placeProductRequest,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearDashboardOrderData());
          dispatch(clearTotalDashboardOrderData());
          dispatch(clearCustomerData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearCustomerOrderData());
          dispatch(clearCustomerFilterData());
          dispatch(clearCustomerOrderPageData());
          dispatch(clearSalesGraphData());
          dispatch(clearSalesGraphFilter());
          dispatch(clearSalesTableData());
          dispatch(clearSalesTableFilterData());
          dispatch(clearSalesTablePageData());
          dispatch(clearCustomerRushData());
          dispatch(clearCustomerRushFilterData());
          toast.success(
            response?.message
              ? response.message
              : "Product delivered successfully!"
          );

          if (pageNumber) {
            dispatch(getOrderPageData(pageNumber + 1));
          } else {
            dispatch(clearOrderPageData());
          }
          dispatch(clearOrderData());
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  const viewOrdersService = (
    token: string,
    dispatch: Dispatch
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = `?p_order_status=0&p_created_at=${format(
          new Date(),
          "yyyy-MM-dd"
        )}`;
        // Adding query parameters if they are provided
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            API_URL.PRODUCT_COUNT +
            queryParams,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for deleting sub category
   */
  const deleteOrderService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.ORDER + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearOrderData());
          dispatch(clearTotalOrderData());
          dispatch(clearCustomerData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearCustomerOrderData());
          dispatch(clearCustomerFilterData());
          dispatch(clearCustomerOrderPageData());
          dispatch(clearSalesGraphData());
          dispatch(clearSalesGraphFilter());
          dispatch(clearSalesTableData());
          dispatch(clearSalesTableFilterData());
          dispatch(clearSalesTablePageData());
          dispatch(clearCustomerRushData());
          dispatch(clearCustomerRushFilterData());
          toast.success(
            response?.message ? response.message : "Order deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getOrderPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /**
   * Service for get user detail API Integration
   */
  const getUserDetailService = (
    mobile: number,
    dispatch: Dispatch
  ): Promise<getUserDetailResponse> => {
    return new Promise<getUserDetailResponse>(async (resolve, reject) => {
      try {
        // dispatch(setLoader())
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.USER_DETAIL + "?p_mobile_no=" + mobile,
          null,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          errorShow = true;
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        // dispatch(clearLoader());
      }
    });
  };

  /**
   * Service for get user detail API Integration
   */
  const deleteLineLevelProduct = (
    requestBody: any,
    dispatch: Dispatch,
    token: string
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await postApiService(
          API_URL.BASE_URL + API_URL.LINE_LEVEL_DELETE,
          token,
          requestBody,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          toast.success(
            response?.message
              ? response.message
              : "Product removed successfully!"
          );
          dispatch(clearOrderData());
          dispatch(clearCustomerData());
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearCustomerOrderData());
          dispatch(clearCustomerFilterData());
          dispatch(clearCustomerOrderPageData());
          dispatch(clearSalesGraphData());
          dispatch(clearSalesGraphFilter());
          dispatch(clearSalesTableData());
          dispatch(clearSalesTableFilterData());
          dispatch(clearSalesTablePageData());
          dispatch(clearCustomerRushData());
          dispatch(clearCustomerRushFilterData());
          // dispatch(clearDashboardOrderData());
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for file upload for whatsapp
   */
  const uploadOrderPDFService = (
    uploadPDFRequest: UploadPDFRequest,
    token: string,
    navigate?: any
  ) => {
    return async (dispatch: Dispatch) => {
      let errorShow = false;

      try {
        dispatch(setLoader());

        // Prepare FormData
        const formData = new FormData();
        formData.append("pdf", uploadPDFRequest.pdf);
        formData.append("p_order_user", uploadPDFRequest.p_order_user);
        formData.append(
          "p_order_phone_number",
          uploadPDFRequest.p_order_phone_number
        );
        formData.append("p_total_amt", uploadPDFRequest.p_total_amt);
        formData.append("p_company_name", uploadPDFRequest.p_company_name);
        // return
        const response = await postApiServiceWp(
          API_URL.BASE_URL + API_URL.ORDER + API_URL.WHATSAPP,
          token,
          formData,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearWalletData());
          // dispatch(clearTotalWalletData())
          dispatch(clearTotalWalletAmt());
          toast.success(`${response.message}`);

          if (navigate) {
            Swal.fire({
              title: "Success!",
              text: response?.message
                ? response.message
                : "Message sent successfully!",
              icon: "success",
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            // Handle unauthorized access, e.g., logout or remove persist storage
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  const getAllDashboardOrderTimeStampService = (
    orderListRequest: getAllDashboardOrderRequest,
    token: string,
    dispatch: Dispatch,
    orderFilter?: any
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const queryParams = Object.entries(orderListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (response.data && response.data.length > 0) {
            const pendingCount = response.data.filter(
              (item: any) => item.status === "0"
            ).length;
            dispatch(
              addDashboardOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: pendingCount,
              })
            );
            const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
            dispatch(setClearLocalOrderFilter(true));
            if (
              orderFilter?.orderDate !== currentDateFormatted ||
              (orderFilter?.status && orderFilter?.status !== "") ||
              (orderFilter?.orderType && orderFilter?.orderType !== "")
            ) {
              toast.warn("Filter removed to display the newly added order");
              dispatch(clearOrderFilterData());
              dispatch(clearOrderData());
            }
          } else {
            dispatch(
              addDashboardOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: 0,
              })
            );
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
      }
    });
  };

  /*
   * Service to get Packager and Manufacturer list
   */
  const getAllDashboardOrderService = (
    orderListRequest: getAllDashboardOrderRequest,
    token: string,
    dispatch: Dispatch,
    orderFilter?: any
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(orderListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (response.data && response.data.length > 0) {
            const pendingCount = response.data.filter(
              (item: any) => item.status === "0"
            ).length;
            dispatch(
              getDashboardOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: pendingCount,
              })
            );

            const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
            dispatch(setClearLocalOrderFilter(true));
            if (
              orderFilter?.orderDate !== currentDateFormatted ||
              (orderFilter?.status && orderFilter?.status !== "") ||
              (orderFilter?.orderType && orderFilter?.orderType !== "")
            ) {
              toast.warn("Filter removed to display the newly added order");
              dispatch(clearOrderFilterData());
              dispatch(clearOrderData());
            }
          } else {
            dispatch(
              getDashboardOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: 0,
              })
            );
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service to get settlements list
   */
  const getSettlementService = (
    settlementRequest: getSettlementRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getSettlementResponse> => {
    return new Promise<getSettlementResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(settlementRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ORDER +
            API_URL.SETTLEMENTS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );

        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getSettlementData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getSettlementData(response));
          } else {
            dispatch(getSettlementData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating Inventory Item
   */
  const addUpdateSettlementService = (
    addUpdateSettlementRequest: addUpdateSettlementRequest,
    token: string,
    type: string,
    navigate?: any,
    handleRegisterSubmit?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.ORDER + API_URL.SETTLEMENTS,
            token,
            addUpdateSettlementRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.ORDER + API_URL.SETTLEMENTS,
            token,
            addUpdateSettlementRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogFilterData());
          dispatch(clearSettlementData());
          dispatch(clearSettlementGraphData());
          dispatch(clearSettlementGraphFilterData());

          dispatch(clearCustomerOrderData());
          dispatch(clearCustomerFilterData());
          dispatch(clearCustomerOrderPageData());
          dispatch(clearSalesGraphData());
          dispatch(clearSalesGraphFilter());
          dispatch(clearSalesTableData());
          dispatch(clearSalesTableFilterData());
          dispatch(clearSalesTablePageData());
          dispatch(clearCustomerRushData());
          dispatch(clearCustomerRushFilterData());
          if (type === "add") {
            toast.success(
              response?.message
                ? response.message
                : "Expense added successfully!"
            );
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Expense undeleted successfully!"
              );
            } else {
              toast.success(
                response?.message
                  ? response.message
                  : "Expense status updated successfully!"
              );

              // if (handleRegisterSubmit) {
              //   handleRegisterSubmit(addUpdateSettlementRequest);
              // }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllOrderService,
    getAllTimeStampOrderService,
    getOrderById,
    placeOrderService,
    LineLevelPlaceOrderService,
    viewOrdersService,
    deleteOrderService,
    getUserDetailService,
    deleteLineLevelProduct,
    uploadOrderPDFService,
    getAllDashboardOrderTimeStampService,
    getAllDashboardOrderService,
    getSettlementService,
    addUpdateSettlementService,
  };
}

import { orderDate } from "../../../services/Regular-services/DateService";
import { CLEAR_GRN_FILTER, GET_GRN_FILTER } from "../../ReduxKeys";

const GRNFilterInitialState = {
  GRNNo: "",
  orderStartDate: orderDate(),
  orderEndDate: "",
};

/**
 * Store GRN filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const GRNFilterReducer = (state = GRNFilterInitialState, action) => {
  switch (action.type) {
    case GET_GRN_FILTER:
      return {
        ...state,
        GRNNo: action.payload.GRNNo,
        orderStartDate: action.payload.orderStartDate,
        orderEndDate: action.payload.orderEndDate,
      };
    case CLEAR_GRN_FILTER:
      return GRNFilterInitialState;
    default:
      return state;
  }
};
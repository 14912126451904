import {
  CLEAR_CUSTOMER_ORDER_FILTER,
  GET_CUSTOMER_ORDER_FILTER,
} from "../../ReduxKeys";

/**
 * set order filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getCustomerOrderFilterData = ({
  startDate,
  endDate,
  status,
  payment,
  orderType,
}) => ({
  type: GET_CUSTOMER_ORDER_FILTER,
  payload: { startDate, endDate, status, payment, orderType },
});

/**
 * clear order filtered data
 * @returns {*}
 */
export const clearCustomerOrderFilterData = () => ({
  type: CLEAR_CUSTOMER_ORDER_FILTER,
});
import {
  CLEAR_DISCOUNT_CATALOGUE_FILTER,
  GET_DISCOUNT_CATALOGUE_FILTER,
} from "../../ReduxKeys";

/**
 * Set discount catalogue filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getDiscountCatalogueFilterData = ({
  name,
  start_date,
  end_date,
  isActive,
  isDelete,
}) => ({
  type: GET_DISCOUNT_CATALOGUE_FILTER,
  payload: { name, start_date, end_date, isActive, isDelete },
});

/**
 * Clear discount catalogue filtered data
 * @returns {*}
 */
export const clearDiscountCatalogueFilterData = () => ({
  type: CLEAR_DISCOUNT_CATALOGUE_FILTER,
});
import React from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertMessage,
  handleCancleClick,
} from "../../../components/Alertmessage/Alertmessage";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomTable from "../../../components/Table/Table";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import "../../Order/OrderProsessing/OrderProsessing.scss";

const columns = [
  {
    name: "Product Name",
    selector: (row) => row.Name,
  },
  {
    name: "Product code",
    selector: (row) => row.Code,
  },
  {
    name: "SKU",
    selector: (row) => row.Value,
  },
  {
    name: "Qty ",
    selector: (row) => row.quantity,
  },
  {
    name: "warehous ",
    selector: (row) => row.warehous,
  },
  {
    name: "Current Status",
    selector: (row) => row.Status,
  },
];

const data = [
  {
    id: 1,
    name: "Product 1",
    Code: "user",
    Value: "10",
    quantity: "100",
    warehous: "India",
    orderStatus: "Complete",
  },
];

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  Code: true,
  Value: true,
  quantity: true,
  orderStatus: true,
  warehous: true,
};
//Setting initial values
const initialValues = {
  tagTitle: "",
};

//Handling form submission
const handleSubmit = (values) => {
  console.log("value: ", values);
};

export default function OrderProsessing() {
  //Getting data from customer page
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const { handleCancleClick } = AlertMessage();

  const validationSchema = Yup.object({
    tagTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  });

  const OverallOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Allocated", label: "Allocated" },
    { value: "Partially shipped", label: "Partially shipped" },
    { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Returned", label: "Returned" },
  ];
  return (
    <>
      <div className=" add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div> */}
        {/* <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>Order Detail</h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/orders")}>
                  Order
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Order Detail</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>Order Detail</h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="add-roles-form row">
                <div className="col-md-4 mb-2">
                  <div className="customer-detail">
                    <h5>Customer details</h5>
                    <div className="customer-detail-text">
                      <p>
                        <b>User</b>
                      </p>
                      <p>User1@gmail.com</p>
                      <p>1234567890</p>
                      <p>Vasna bhayli road Vadodara, , Vadodara, Gujarat</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <div className="customer-detail">
                    <h5>Shipment details</h5>
                    <div className="customer-detail-text">
                      <p>
                        <b>User</b>
                      </p>
                      <p>User1@gmail.com</p>
                      <p>1234567890</p>
                      <p>Vasna bhayli road Vadodara, , Vadodara, Gujarat</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <div className="customer-detail">
                    <h5>Payment details </h5>
                    <div className="customer-detail-text">
                      <p>
                        <b>User</b>
                      </p>
                      <p>User1@gmail.com</p>
                      <p>1234567890</p>
                      <p>Vasna bhayli road Vadodara, , Vadodara, Gujarat</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <h5 className="list-text">List of products</h5>
                  <CustomTable
                    showKey={showKey}
                    showTooltip={true}
                    data={data}
                    heading={columns}
                  />
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Order Cancelled by{" "}
                        <span className="required-label"></span>
                      </h4>
                      <span className="user-label">User1</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      Order Status
                      <span className="required-label"></span>
                    </h4>
                    <Field
                      className="select-menu mb-2"
                      name="brandCategory"
                      options={OverallOptions}
                      component={CustomSelect}
                      placeholder="Select..."
                      isMulti={false}
                      disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Order Notes</h4>
                    <Field
                      name="Order Notes"
                      component={CKEditorCpmponent}
                      disabled={Data && Data.isEdit === false}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Order Cancelation Note</h4>
                    <Field
                      name="Order Notes"
                      component={CKEditorCpmponent}
                      disabled={Data && Data.isEdit === false}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <div className="btn-submit mt-3">
                      {((Data && Data.isEdit === true) || !Data) && (
                        <>
                          <Button
                            type="submit"
                            name="Save"
                            className="btn login-btn"
                          />
                          <Button
                            type="button"
                            name="Cancel"
                            className="btn clear-btn login-btn"
                            onClick={() =>
                              handleCancleClick("/orders", navigate)
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

import { CLEAR_SETTLEMENT_DATA, GET_SETTLEMENT_DATA } from "../../ReduxKeys";

/**
 * Setting settlement list data into reducer
 * @param {*} settlement
 * @returns {*}
 */
export const getSettlementData = (settlement) => ({
  type: GET_SETTLEMENT_DATA,
  payload: settlement,
});

/**
 * clearing event list data
 * @returns {*}
 */
export const clearSettlementData = () => ({
  type: CLEAR_SETTLEMENT_DATA,
});
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import { Breadcrumb } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import { modifyString } from "../../../services/Regular-services/StringService";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CategoryService from "../../Category/Category-service/CategoryService";
import ErrorComp from "../../../components/Error/ErrorComp";
import { clearExpensePageData } from "../../../redux/Actions/ExpenseAction/ExpensePageAction";
import ExpenseService from "../ExpenseService/ExpenseSerive";

const AddExpense = () => {
  const { t } = useTranslation();
  const { addUpdateExpenseService } = ExpenseService();
  const { handleCancleClick, handlesaveClick } = AlertMessage();
  const { getAllCategoryService } = CategoryService();
  const [initialValues, setInitialValues] = useState({
    title: "",
    date: "",
    msg: "",
    amount: "",
    expenseCategory: "",
  });

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      title: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
      amount: Yup.string().required(t(ValidationMessage.AMOUNT_REQUIRED)),
      date: Yup.string().required(t(ValidationMessage.DATE_REQUIRED)),
      expenseCategory: Yup.string().required(
        t(ValidationMessage.CATEGORY_REQUIRED)
      ),
    })
  );

  const location = useLocation();
  const { Data } = location.state || {};
  const sideBarData = useSelector((state) => state.sideBar.items);
  const totalCategory = useSelector((state) => state.totalCategory);
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const [expenseActionData, setExpenseActionData] = useState([]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000",
    }),
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const localisation = useSelector((state) => state.localization?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "expense"
      );
      setExpenseActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setValidationSchema(
      Yup.object({
        title: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
        amount: Yup.string().required(t(ValidationMessage.AMOUNT_REQUIRED)),
        date: Yup.string().required(t(ValidationMessage.DATE_REQUIRED)),
        expenseCategory: Yup.string().required(
          t(ValidationMessage.CATEGORY_REQUIRED)
        ),
      })
    );
    // eslint-disable-next-line
  }, [localisation]);

  useEffect(() => {
    if (Data && Data.data) {
      const formattedDate = new Date(Data.data.Date);
      setInitialValues({
        id: Data.data.id,
        title: Data.data.name,
        date: formattedDate,
        msg: Data.data.instruction,
        amount: parseFloat(Data.data.amount),
        expenseCategory: Data.data?.category[0],
      });
    }
  }, [Data]);

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totalCategory?.items?.length) {
      if (
        totalCategory.items[0]?.data &&
        totalCategory.items[0].data.length > 0
      ) {
        const filteredData = totalCategory.items[0].data.filter(
          (item) => item.type === "5" || item.type === 5
        );
        const newCategory = filteredData.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
        }));
        setCategoryOptionValue(newCategory);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory]);

  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000000,
    };
    getAllCategoryService(requestBody, token, dispatch, true);
  };

  const handleSubmit = (values) => {
    const amount = Number(values.amount).toFixed(2);
    const date = values.date.toLocaleDateString("en-CA"); // Outputs YYYY-MM-DD
    const category = [{ id: values.expenseCategory }];
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_name: values.title,
      p_amount: parseFloat(amount),
      p_date: date,
      p_category_id: category,
      p_instruction: values.msg,
    };

    dispatch(clearExpensePageData());
    if (Data && Data.data) {
      handlesaveClick(
        dispatch,
        addUpdateExpenseService(requestBody, token, "update", navigate),
        "update"
      );
    } else {
      dispatch(addUpdateExpenseService(requestBody, token, "add", navigate));
    }
  };

  return (
    <>
      <div className=" add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {expenseActionData && expenseActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          expenseActionData[0].name,
                          localisation
                        )
                      )
                    : t("Expense")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/expense")}>
                  {expenseActionData && expenseActionData.length > 0
                    ? getRouteNameFromSidebar(
                        expenseActionData[0].name,
                        localisation
                      )
                    : t("Expense")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {expenseActionData && expenseActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          expenseActionData[0].name,
                          localisation
                        )
                      )
                    : t("Expense")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {expenseActionData && expenseActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      expenseActionData[0].name,
                      localisation
                    )
                  )
                : t("Expense")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Name")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="title"
                        label="Name"
                        placeholder={t("placeholder_enter_name")}
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_amount")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="amount"
                        label="amount"
                        placeholder={t("placeholder_amount")}
                        className="add-roles"
                        Regex={/[^0-9.\s]/g}
                        maxLength="10"
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Date")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <DatePicker
                        selected={values.date}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onChange={(date) => {
                          setFieldValue("date", date);
                        }}
                        disabled={Data && Data.isEdit === false}
                        placeholderText={t("placeholder_date")}
                        showIcon
                      />
                      <ErrorMessage
                        name="date"
                        component={ErrorComp}
                      ></ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Expense_category")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="expenseCategory"
                        options={categoryOptionValue}
                        component={CustomSelect}
                        placeholder={t("Placeholder_select_category")}
                        isMulti={false}
                        value={values.expenseCategory}
                        disabled={Data && Data.isEdit === false}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="message-input">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_expense")}{" "}
                        </h4>
                      </div>
                      <textarea
                        value={values.msg}
                        placeholder={t("Placeholder_generalized_instruction")}
                        onChange={(event) =>
                          setFieldValue("msg", event.target.value)
                        }
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/inventory-item", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddExpense;

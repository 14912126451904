// Convert grams to kilograms
export const GramToKg = (value) => {
  return value / 1000;
};

// Convert kilograms to grams
export const KgToGram = (value) => {
  return value * 1000;
};

// Convert milligrams to grams
export const MgToGram = (value) => {
  return value / 1000;
};

// Convert grams to milligrams
export const GramToMg = (value) => {
  return value * 1000;
};

// Convert milliliters to liters
export const MlToLitre = (value) => {
  return value / 1000;
};

// Convert liters to milliliters
export const LiterToMl = (value) => {
  return value * 1000;
};

export const convertToBaseUnit = (quantity, unitVal) => {
    let quantityInBaseUnit = parseFloat(quantity);
    let unit = unitVal;
    if (
      unit === "gm" ||
      unit === "ml" ||
      unit === "l" ||
      unit === "kg" ||
      unit === "mg"
    ) {
      if (unitVal === "gm" && parseFloat(quantity) > 999) {
        quantityInBaseUnit = GramToKg(parseFloat(quantity));
   
        unit = "kg";
      } else if (unitVal === "ml" && parseFloat(quantity) > 999) {
        quantityInBaseUnit = MlToLitre(parseFloat(quantity));
        // quantityInBaseUnit = parseFloat(quantity);
        unit = "l";
      }
      switch (unitVal) {
        case "gm":
          quantityInBaseUnit = parseFloat(quantity); // Grams as base unit
          unit = "gm";
          break;
        case "mg":
          quantityInBaseUnit = MgToGram(parseFloat(quantity)); // Grams as base unit
          unit = "gm";
          break;
        case "kg":
          quantityInBaseUnit = KgToGram(parseFloat(quantity)); // Convert kilograms to grams
          unit = "gm";
          break;
        case "ml":
          quantityInBaseUnit = parseFloat(quantity); // Milliliters as base unit
          unit = "ml";
          break;
        case "l":
          quantityInBaseUnit = LiterToMl(parseFloat(quantity)); // Convert liters to milliliters
          unit = "ml";
          break;
        default:
          quantityInBaseUnit = parseFloat(quantity); // Default to base unit if no valid unit is selected
          unit = unitVal;
          break;
      }
    }
  return { quantityInBaseUnit, unit };
};

import {
  CLEAR_SUB_CATEGORY_FILTER,
  GET_SUB_CATEGORY_FILTER,
} from "../../ReduxKeys";

const subCategoryFilterInitialState = {
  name: "",
  category: "",
  code: "",
  type: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store sub-category filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const subCategoryFilterReducer = (
  state = subCategoryFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_SUB_CATEGORY_FILTER:
      return {
        ...state,
        name: action.payload.name,
        category: action.payload.category,
        code: action.payload.code,
        type: action.payload.type,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_SUB_CATEGORY_FILTER:
      return subCategoryFilterInitialState;
    default:
      return state;
  }
};
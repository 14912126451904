import LOGO from "../../assets/images/Bespeakk-logo.png";
import { GramToKg, GramToMg, MlToLitre } from "../../services/Regular-services/ConvertUnitService";

export const VendorPrintHTML = ({ user, orderData }) => {
  const { companyLogo, companyName, companyOwnerName, companyPhneNumber } =
    user.data;

  const data = JSON.parse(orderData.ingredients);
  const ingredientData = data.map((item) => {
    let quantityInOriginalUnit = parseFloat(item.value);
    let unit = item.unit;
    if (
      unit === "gm" ||
      unit === "kg" ||
      unit === "l" ||
      unit === "ml" ||
      unit === "mg"
    ) {
      if (unit === "gm" && quantityInOriginalUnit > 999) {
        quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
        unit = "kg";
      } else if (unit === "gm" && quantityInOriginalUnit < 1) {
        quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
        unit = "mg";
      } else if (unit === "ml" && quantityInOriginalUnit > 999) {
        quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
        unit = "l";
      }
    }

    return {
      ingredientName: item?.ingredientName,
      IngredientId: item?.ingredient_id,
      quantity: quantityInOriginalUnit,
      unit: unit,
      instruction: item.ingredient_instruction,
      status: item.status,
      ingredient_price: item.ingredient_price,
    };
  });

  const vendorHtmlContent = `
    <html>
    <head>
      <title>Order Details - Vendor</title>
    </head>
    <body>
      <div style="font-family: Arial, sans-serif; margin: 0; padding: 0; text-align: center; max-width: 80%; margin: 0 auto;">
        <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: flex-start;">
          <img src="${LOGO}" alt="Company Logo" style="width: 100px; height: auto;" />
          <div>
            <img src="${companyLogo}" alt="Company Logo" style="width: 100px; height: auto;" />
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div style="display: flex; justify-content: space-between;">
            <div style="text-align: left;">
              <h5>Order Details</h5>
              <p style="margin: 5px 0; font-size: 12px;">
                Order No. - <strong>${orderData?.orderNum || "N/A"}</strong>
              </p>
              <p style="margin: 5px 0; font-size: 12px;">Name: ${
                orderData?.name || "N/A"
              }</p>
              <p style="margin: 5px 0; font-size: 12px;">Phone Number: ${
                orderData?.phNum || "N/A"
              }</p>
              <p style="margin: 5px 0; font-size: 12px;">
                Order Date: ${
                  orderData?.orderDate
                    ? new Date(orderData?.orderDate).toLocaleString()
                    : "N/A"
                }
              </p>
              <p style="margin: 5px 0; font-size: 12px;">
                <br />
                Payment: ${orderData?.payment === "0" ? "Not Paid" : "Paid"}
                <br />
                Order Status: ${
                  orderData?.orderStatus === "0" ? "Pending" : "Completed"
                }
                <br />
              </p>
              ${
                orderData?.instructions
                  ? `
                <p style="font-size: 12px;">General Instruction : ${orderData.instructions}</p>
              `
                  : ""
              }
            </div>
            <div style="text-align: end;">
              <p style="margin: 0;">Company: ${companyName}</p>
              <p style="margin: 0;">Owner: ${companyOwnerName}</p>
              <p style="margin: 0;">Phone: ${companyPhneNumber}</p>
            </div>
          </div>
    
          <table style="width: 100%; border-collapse: collapse; margin: 10px auto;">
            <thead>
              <tr>
                <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Items</th>
                <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Quantity</th>
                <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Unit</th>
                <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Price</th>
                <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Instructions</th>
              </tr>
            </thead>
            <tbody>
              ${
                ingredientData.length > 0
                  ? ingredientData
                      .map(
                        (ingredient) => `
                  <tr>
                    <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                      ingredient.ingredientName
                    }</td>
                    <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                      ingredient.quantity
                    }</td>
                    <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                      ingredient.unit
                    }</td>
                    <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                      "₹" + ingredient.ingredient_price
                    }</td>
                    <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                      ingredient.instruction.length > 0
                        ? ingredient.instruction
                        : "N/A"
                    }</td>
                  </tr>
                `
                      )
                      .join("")
                  : `
                <tr>
                  <td colspan="4" style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">
                    No ingredients available.
                  </td>
                </tr>`
              }
            </tbody>
          </table>
          <div style="margin-top: 10px; font-size: 12px;">
            <p>Thanks for your business!</p>
          </div>
          <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin: 20px auto;">
            <!-- Your icon or content here -->
          </div>
        </div>
      </div>
    </body>
    </html>
    `;
  return vendorHtmlContent;
};

import {
  CLEAR_ALL_PREFERENCE_PAGE,
  GET_ALL_PREFERENCE_PAGE,
} from "../../ReduxKeys";

/**
 * Set Preferences page data
 * @param {*} page
 * @returns {*}
 */
export const getAllPreferencesPageData = (page) => ({
  type: GET_ALL_PREFERENCE_PAGE,
  payload: page,
});

/**
 * Clear Preferences page data
 * @returns {*}
 */
export const clearAllPreferencesPageData = () => ({
  type: CLEAR_ALL_PREFERENCE_PAGE,
});
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import Input from "../../components/input/Input";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../Utils/Utils";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import {getApiService} from "../../services/API-services/GetService";
import { toast } from "react-toastify";
import {
  accountDocuments,
  businessTypeOption,
  categoryOptionArray,
  countryJson,
  individualIdentificationProof,
  parentAddressProofOptions,
  subCategoryJson,
} from "./PaymentOptions";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";
import { Stepper, Step } from "react-form-stepper";
import "../../pages/PaymentDetails/PaymentDetails.scss";
import FileInput from "../../components/input/FileInput";
import { clearAccountDetailData } from "../../redux/Actions/PaymentDetailAction/AccountDetailAction";
import { IoRadioButtonOnSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import PaymentDetailServices from "./PaymentServices/PaymentDetailService";

function PaymentDetails() {
  const {
    accountProductConfigService,
    createAccountService,
    getAccountDetailService,
    stackHolderService,
    verifyAccountService,
  } = PaymentDetailServices();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const userData = useSelector((state) => state.auth.user);
  const accData = useSelector((state) => state.paymentDetail);
  const accDetail = useSelector((state) => state.accountDetail);
  const stackHolderDetail = useSelector((state) => state.stakeHolderDetail);
  const accountConfigDetail = useSelector(
    (state) => state.accountProductConfig
  );
  const verifyAccount = useSelector((state) => state.verifyAccount);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [paymentDetailActionData, setpaymentDetailActionData] = useState([]);
  const businessTypeOptions = businessTypeOption;
  const CategoryOptions = categoryOptionArray;
  const subCategories = subCategoryJson;
  const countryOptions = countryJson;
  const accountDocumentOptions = accountDocuments;
  const identityProofOptions = individualIdentificationProof;
  const AddressProofOptions = parentAddressProofOptions;
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("food");
  const [invalidIfsc, setInvalidIfsc] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isStackHolderDisable, setIsStackHolderDisable] = useState(false);
  const [isAccUpdateDisable, setIsAccUpdateDisable] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const localisation = useSelector((state) => state.localization?.items);
  const [initialValues, setInitialValues] = useState({
    email: "",
    phone: userData.data.phoneNumber,
    legal_business_name: "",
    business_type: "",
    contact_name: `${userData.data.firstName} ${userData.data.lastName} `,
    p_IFSC_code: "",
    p_account_number: "",
    p_beneficiary_name: "",
    category: "food",
    subcategory: "restaurant",
    street1: userData.data.branchAddress,
    street2: "",
    city: "",
    state: "",
    postal_code: userData.data.branchPincode,
    country: "IN",
    pan: "",
    stakeHolderPan: "",
    gst: "",
    accountId: "",
    name: "",
    notes: "",
    productId: "",
    tnc_accepted: true,
    stakeHolderEmail: "",
    uploadAccImageType: "",
    uploadAccImage: [],
    uploadStakeIdentityType: "personal_pan",
    uploadStakeIdentityImage: [],
    addressProofType: "",
    uploadStakeAddressFrontType: "",
    uploadStakeAddressProofBackImage: [],
    uploadStakeAddressBackType: "",
    uploadStakeAddressProofFrontImage: [],
  });

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const panRegex = /^[A-Z]{3}[CHFABTJGL]{1}[A-Z]{1}\d{4}[A-Z]{1}$/i;
  const stakeHolderPanRegex = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/i;
  const gstRegex = /^[0123][0-9][a-z]{5}[0-9]{4}[a-z][0-9][a-z0-9][a-z0-9]$/gi;
  const [validationSchema, setValidationSchema] = useState({
    0: Yup.object({
      email: Yup.string()
        .required(t(ValidationMessage.EMAIL_REQUIRED))
        .max(64, t(ValidationMessage.MAX_EMAIL_LIMIT))
        .matches(emailRegex, t(ValidationMessage.INVALID_EMAIL)),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^[9876]\d{9}$/, "Invalid phone number"),
      legal_business_name: Yup.string().required("Field is required"),
      category: Yup.string().required("Field is required"),
      subcategory: Yup.string().required("Field is required"),
      business_type: Yup.string().required("Field is required"),
      contact_name: Yup.string().required("Name is required"),
      street1: Yup.string().required("Field is required"),
      city: Yup.string().required("Field is required"),
      state: Yup.string().required("Field is required"),
      postal_code: Yup.string().required("Field is required"),
      country: Yup.string().required("Field is required"),
      pan: Yup.string()
        .required("Field is Required")
        .max(10, "Pan Number must be at most 10 characters!")
        .matches(panRegex, "Invalid Pan Number"),
      gst: Yup.string()
        .max(15, "GST Number must be at most 15 characters!")
        .matches(gstRegex, "Invalid GST Number"),
      uploadAccImageType: Yup.string().required("Field is required"),
      uploadAccImage: Yup.array()
        .test(
          "fileRequired",
          t(ValidationMessage.UPLOAD_IMAGE),
          (files) => files && files.length > 0
        )
        .test(
          "fileType",
          "Unsupported file format. Please upload .jpg, .jpeg, .png, or .webp files.",
          (files) => {
            if (!files || files.length === 0) return true;
            const validFormats = [
              "image/jpeg",
              "image/png",
              "image/webp",
              "image/jpg",
            ];
            return files.every((file) => validFormats.includes(file.type));
          }
        ),
    }),
    1: Yup.object({
      stakeHolderPan: Yup.string()
        .required("Field is Required")
        .max(10, "Pan Number must be at most 10 characters!")
        .matches(stakeHolderPanRegex, "Invalid Pan Number"),
      uploadStakeIdentityType: Yup.string().required("Field is required"),
      uploadStakeIdentityImage: Yup.array()
        .test(
          "fileRequired",
          t(ValidationMessage.UPLOAD_IMAGE), // "Field is required" message
          (files) => files && files.length > 0 // Ensure files exist and are not empty
        )
        .test(
          "fileType",
          "Unsupported file format. Please upload .jpg, .jpeg, .png, or .webp files.",
          (files) => {
            if (!files || files.length === 0) return true; // Skip format validation if no files (let fileRequired handle it)
            const validFormats = [
              "image/jpeg",
              "image/png",
              "image/webp",
              "image/jpg",
            ];
            return files.every((file) => validFormats.includes(file.type)); // Check all files have valid format
          }
        ),
      addressProofType: Yup.string().required("Field is required"),
      uploadStakeAddressProofBackImage: Yup.array()
        .test(
          "fileRequired",
          t(ValidationMessage.UPLOAD_IMAGE), // "Field is required" message
          (files) => files && files.length > 0 // Ensure files exist and are not empty
        )
        .test(
          "fileType",
          "Unsupported file format. Please upload .jpg, .jpeg, .png, or .webp files.",
          (files) => {
            if (!files || files.length === 0) return true; // Skip format validation if no files (let fileRequired handle it)
            const validFormats = [
              "image/jpeg",
              "image/png",
              "image/webp",
              "image/jpg",
            ];
            return files.every((file) => validFormats.includes(file.type)); // Check all files have valid format
          }
        ),
      uploadStakeAddressProofFrontImage: Yup.array()
        .test(
          "fileRequired",
          t(ValidationMessage.UPLOAD_IMAGE), // "Field is required" message
          (files) => files && files.length > 0 // Ensure files exist and are not empty
        )
        .test(
          "fileType",
          "Unsupported file format. Please upload .jpg, .jpeg, .png, or .webp files.",
          (files) => {
            if (!files || files.length === 0) return true; // Skip format validation if no files (let fileRequired handle it)
            const validFormats = [
              "image/jpeg",
              "image/png",
              "image/webp",
              "image/jpg",
            ];
            return files.every((file) => validFormats.includes(file.type)); // Check all files have valid format
          }
        ),
    }),
    2: Yup.object({
      account_number: Yup.string().required(t("Account Number is required")),
      ifsc_code: Yup.string().required(t("IFSC Code is required")),
      beneficiary_name: Yup.string().required(t("Beneficiary Name is required")),
      tnc_accepted: Yup.boolean().oneOf(
        [true],
        t("You must accept terms and conditions")
      ),
    }),
  });
  const [requirements, setRequirement] = useState([]);
  const [staticRequireMsg, setStaticRequireMsg] = useState([]);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setpaymentDetailActionData(actionData);
    }
    // eslint-disable-next-line
  }, [sideBarData]);

  // for getting account details
  useEffect(() => {
    if (accDetail && accDetail.items.length === 0) {
      fetchAccountDetails();
    } else {
      if (accDetail && accDetail.items[0] && accDetail.items[0].data) {
        const data = accDetail.items[0].data;
        const profileDetail = JSON.parse(data.profile);
        const AddressDetail = JSON.parse(profileDetail);
        const addresses = AddressDetail.addresses.registered;
        const LegalDetail = JSON.parse(JSON.parse(data.legal_info));

        if (activeStep === 0) {
          setIsDisable(true);
          setInitialValues({
            email: data.email,
            phone: data.phone,
            legal_business_name: data.legal_business_name,
            business_type: data.business_type,
            contact_name: data.contact_name,
            category: AddressDetail.category,
            subcategory: AddressDetail.subcategory,
            street1: addresses.street1,
            street2: addresses.street2,
            city: addresses.city,
            state: addresses.state,
            postal_code: addresses.postal_code,
            country: addresses.country,
            pan: LegalDetail.pan,
            gst: LegalDetail.gst,
          });
        } else if (activeStep === 1) {
          setInitialValues({
            accountId: data.account_id,
            name: data.contact_name,
            stakeHolderEmail: data?.email,
            street: `${addresses.street1}`,
            city: addresses.city,
            state: addresses.state,
            postal_code: String(addresses.postal_code),
            country: addresses.country,
            stakeHolderPan: data.bussiness_pan ? data.bussiness_pan : "",
            notes: "",
          });
        } else if (
          userData.data.branchRazorpayAccountProductId &&
          userData.data.branchRazorpayStakeholderAccountId &&
          activeStep === 2
        ) {
          setInitialValues({
            accountId: userData.data.branchRazorpayAccountId,
            productId: userData.data.branchRazorpayAccountProductId,
            account_number: data.account_number ? data.account_number : "",
            ifsc_code: data.IFSC_code ? data.IFSC_code : "",
            beneficiary_name: data.beneficiary_name
              ? data.beneficiary_name
              : "",
            tnc_accepted: true,
          });
        } else if (
          stackHolderDetail &&
          stackHolderDetail?.items[0] &&
          stackHolderDetail.items[0]?.data &&
          activeStep === 2
        ) {
          setInitialValues({
            accountId: stackHolderDetail.items[0]?.data.account_id,
            productId: stackHolderDetail.items[0]?.data.account_product_id,
            account_number: data.account_number ? data.account_number : "",
            ifsc_code: data.IFSC_code ? data.IFSC_code : "",
            beneficiary_name: data.beneficiary_name
              ? data.beneficiary_name
              : "",
            tnc_accepted: true,
          });
        }
      }
    }

    if (accDetail && accDetail.items[0] && accDetail.items[0].data) {
      const data = accDetail.items[0].data;
      if (isStackHolderDisable && !data.bussiness_pan) {
        fetchAccountDetails();
      } else if (
        isAccUpdateDisable &&
        !data.IFSC_code &&
        !data.account_number &&
        !data.beneficiary_name
      ) {
        fetchAccountDetails();
      }
    }

    // eslint-disable-next-line
  }, [
    accDetail,
    activeStep,
    accData,
    userData,
    isStackHolderDisable,
    isAccUpdateDisable,
  ]);

  const [timeStamp, setTimeStamp] = useState(0);

  useEffect(() => {
    if (
      accountConfigDetail &&
      accountConfigDetail?.items[0] &&
      accountConfigDetail.items[0]?.message ===
        "Stakeholder account successfully created"
    ) {
      setIsAccUpdateDisable(true);
      if (
        (stackHolderDetail &&
          stackHolderDetail?.items[0] &&
          stackHolderDetail.items[0]?.data) ||
        (userData.data.branchRazorpayAccountProductId &&
          userData.data.branchRazorpayAccountId)
      ) {
        const requestBody = {
          account_id: userData.data.branchRazorpayAccountId
            ? userData.data.branchRazorpayAccountId
            : stackHolderDetail.items[0]?.data.account_id,
          product_id: userData.data.branchRazorpayAccountProductId
            ? userData.data.branchRazorpayAccountProductId
            : stackHolderDetail.items[0]?.data.account_product_id,
        };
        verifyAccountService(requestBody, tokenData, dispatch);
        if (verifyAccount && verifyAccount.items.length === 0) {
          const requestBody = {
            account_id: userData.data.branchRazorpayAccountId,
            product_id: userData.data.branchRazorpayAccountProductId,
          };
          verifyAccountService(requestBody, tokenData, dispatch);
        } else {
          if (
            verifyAccount &&
            verifyAccount?.items[0] &&
            verifyAccount.items[0]?.data
          ) {
            const verificationData = verifyAccount.items[0]?.data;
            const settlements =
              verificationData?.active_configuration?.settlements;
            const areAllSettlementDetailsNull = settlements
              ? Object.values(settlements).every((value) => value === null)
              : false;
            if (areAllSettlementDetailsNull) {
              setIsAccUpdateDisable(false);
            } else if (
              !areAllSettlementDetailsNull &&
              verificationData.activation_status !== "needs_clarification"
            ) {
              setIsAccUpdateDisable(true);
            } else if (
              !areAllSettlementDetailsNull &&
              verificationData.activation_status === "needs_clarification" &&
              verificationData.requirements.length !== 0
            ) {
              const allHaveMaxRetryExceeded = requirements.every(
                (req) =>
                  req.description ===
                  "Max retry exceeded for bank account details."
              );
              const updatedRequirements = verificationData.requirements.map(
                (req) => {
                  return {
                    ...req,
                    field_reference: req.field_reference.split(".")[1], // Get the part after "settlements."
                  };
                }
              );
              setRequirement(updatedRequirements);
              if (allHaveMaxRetryExceeded) {
                setIsAccUpdateDisable(true);
                setTimeStamp(Number(verificationData.requested_at));
              } else {
                setIsAccUpdateDisable(false);
              }
            } else if (
              !areAllSettlementDetailsNull &&
              verificationData.activation_status === "needs_clarification" &&
              verificationData.requirements.length === 0
            ) {
              setStaticRequireMsg("Please Check your Account Details");
            } else {
              setIsAccUpdateDisable(true);
            }
          }
        }
      }
    } else {
      setIsAccUpdateDisable(false);
    }
    if (
      (stackHolderDetail &&
        stackHolderDetail?.items[0] &&
        stackHolderDetail.items[0]?.data) ||
      (userData.data.branchRazorpayAccountId &&
        userData.data.branchRazorpayAccountProductId)
    ) {
      setIsStackHolderDisable(true);
    } else {
      setIsStackHolderDisable(false);
    }
    // eslint-disable-next-line
  }, [stackHolderDetail, userData, accountConfigDetail]);

  useEffect(() => {
    if (
      userData.data.branchRazorpayAccountId &&
      userData.data.branchRazorpayAccountProductId &&
      userData.data.branchRazorpayStakeholderAccountId
    ) {
      if (verifyAccount && verifyAccount.items.length === 0) {
        const requestBody = {
          account_id: userData.data.branchRazorpayAccountId,
          product_id: userData.data.branchRazorpayAccountProductId,
        };
        verifyAccountService(requestBody, tokenData, dispatch);
      } else {
        if (
          verifyAccount &&
          verifyAccount?.items[0] &&
          verifyAccount.items[0]?.data
        ) {
          const verificationData = verifyAccount.items[0]?.data;
          const settlements =
            verificationData?.active_configuration?.settlements;
          const areAllSettlementDetailsNull = settlements
            ? Object.values(settlements).every((value) => value === null)
            : false;
          if (areAllSettlementDetailsNull) {
            setIsAccUpdateDisable(false);
          } else if (
            !areAllSettlementDetailsNull &&
            verificationData.activation_status !== "needs_clarification"
          ) {
            setIsAccUpdateDisable(true);
          } else if (
            !areAllSettlementDetailsNull &&
            verificationData.activation_status === "needs_clarification" &&
            verificationData.requirements.length !== 0
          ) {
            const allHaveMaxRetryExceeded = requirements.every(
              (req) =>
                req.description ===
                "Max retry exceeded for bank account details."
            );
            const updatedRequirements = verificationData.requirements.map(
              (req) => {
                return {
                  ...req,
                  field_reference: req.field_reference.split(".")[1], // Get the part after "settlements."
                };
              }
            );
            setRequirement(updatedRequirements);
            if (allHaveMaxRetryExceeded) {
              setIsAccUpdateDisable(true);
              setTimeStamp(Number(verificationData.requested_at));
            } else if (
              !areAllSettlementDetailsNull &&
              verificationData.activation_status === "needs_clarification" &&
              verificationData.requirements.length === 0
            ) {
              setStaticRequireMsg("Please Check your Account Details");
            } else {
              setIsAccUpdateDisable(false);
            }
          } else {
            setIsAccUpdateDisable(true);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [verifyAccount, userData]);

  const checkTimeDifference = (time) => {
    const timestampDate = new Date(time * 1000);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - timestampDate;

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    if (diffInHours > 24) {
      setIsAccUpdateDisable(false);
    } else {
      setIsAccUpdateDisable(true);
    }
  };

  useEffect(() => {
    if (timeStamp) {
      const interval = setInterval(() => {
        checkTimeDifference(timeStamp);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsAccUpdateDisable(false);
    }
  }, [timeStamp]);

  const fetchAccountDetails = () => {
    if (
      userData.data.branchRazorpayAccountId ||
      (accData && accData?.items[0])
    ) {
      dispatch(clearAccountDetailData);
      setIsDisable(true);
      const data = accData?.items[0];
      const getAccRequestBody = {
        id: userData.data.branchRazorpayAccountId
          ? userData.data.branchRazorpayAccountId
          : data.id,
      };
      getAccountDetailService(getAccRequestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      setSubCategoryOptions(subCategories[selectedCategory] || []);
    } else {
      setSubCategoryOptions([]);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  const handleCategoryChange = (selectedOption, setFieldValue) => {
    setSelectedCategory(selectedOption);
    if (selectedOption === "food") {
      setFieldValue("category", selectedOption);
      setFieldValue("subcategory", "restaurant");
    } else {
      setFieldValue("category", selectedOption);
      setFieldValue("subcategory", "");
    }
  };

  const verifyIfscCodeService = async (code) => {
    const response = await getApiService("https://ifsc.razorpay.com/" + code);

    if (response === "Not Found" || !response?.BRANCH) {
      setInvalidIfsc(true);
      return;
    }
    setInvalidIfsc(false);
  };

  const getAddressFromPincode = async (pincode, setFieldValue) => {
    dispatch(setLoader());
    const response = await getApiService(
      "https://api.postalpincode.in/pincode/" + pincode
    );
    const data = await response;
    if (data && data.length > 0) {
      if (data[0]?.Status === "Success") {
        if (data[0]?.PostOffice && data[0].PostOffice.length > 0) {
          const postOfficeData = data[0].PostOffice[0]?.Block
            ? data[0].PostOffice[0].Block
            : data[0].PostOffice[0].District;
          setFieldValue("city", postOfficeData);
          setFieldValue(
            "state",
            data[0].PostOffice[0]?.State ? data[0].PostOffice[0].State : ""
          );
        } else {
          setFieldValue("city", "");
          setFieldValue("state", "");
        }
      } else {
        setFieldValue("city", "");
        setFieldValue("state", "");
      }
      dispatch(clearLoader());
    } else {
      setFieldValue("city", "");
      setFieldValue("state", "");
      dispatch(clearLoader());
    }
  };

  const handleBusinessChange = (option) => {
    if (
      option.value === "proprietorship" ||
      option.value === "not_yet_registered"
    ) {
      setValidationSchema((prevSchema) => ({
        ...prevSchema,
        0: prevSchema[0].shape({
          ...prevSchema[0].fields,
          pan: Yup.string().notRequired(),
        }),
      }));
    } else {
      setValidationSchema((prevSchema) => ({
        ...prevSchema,
        0: prevSchema[0].shape({
          ...prevSchema[0].fields,
          pan: Yup.string()
            .required("Field is Required")
            .max(10, "Pan Number must be at most 10 characters!")
            .matches(panRegex, "Invalid Pan Number"),
        }),
      }));
    }
  };
  const handleDocumentUpload = (option, setFieldValue) => {
    setFieldValue("uploadAccImageType", option.value);
  };

  const handleAddressProofUpload = (option, setFieldValue) => {
    if (option.value === "voter") {
      setFieldValue("uploadStakeAddressFrontType", "voter_id_front");
      setFieldValue("uploadStakeAddressBackType", "voter_id_back");
      setFieldValue("uploadStakeAddressProofFrontImage", []);
      setFieldValue("uploadStakeAddressProofBackImage", []);
    } else if (option.value === "aadhar") {
      setFieldValue("uploadStakeAddressFrontType", "aadhar_front");
      setFieldValue("uploadStakeAddressBackType", "aadhar_back");
      setFieldValue("uploadStakeAddressProofFrontImage", []);
      setFieldValue("uploadStakeAddressProofBackImage", []);
    } else if (option.value === "passport") {
      setFieldValue("uploadStakeAddressFrontType", "passport_front");
      setFieldValue("uploadStakeAddressBackType", "passport_back");
      setFieldValue("uploadStakeAddressProofFrontImage", []);
      setFieldValue("uploadStakeAddressProofBackImage", []);
    }
  };

  const handleSubmit = (values) => {
    if (invalidIfsc) {
      toast.error("Invalid IFSC Code");
    } else {
      if (activeStep === 0) {
        const requestBody = {
          email: values.email,
          phone: values.phone,
          type: "route",
          business_type: values.business_type,
          reference_id: "REF_" + userData?.data?.branchId,
          legal_business_name: values.legal_business_name,
          contact_name: values.contact_name,
          profileData: {
            category: values.category,
            subcategory: values.subcategory,
            addresses: {
              registered: {
                street1: values.street1,
                street2: values.city,
                city: values.city,
                state: values.state,
                postal_code: values.postal_code,
                country: values.country,
              },
            },
          },
          legal_infoData: {
            // business pan
            pan: values.pan,
            gst: values.gst,
          },
          business_proof_of_identification_document_type:
            values.uploadAccImageType,
          business_proof_of_identification: values.uploadAccImage,
        };
        dispatch(createAccountService(requestBody, tokenData, "add", navigate));
        fetchAccountDetails();
      } else if (activeStep === 1) {
        const requestBody = {
          email: values.stakeHolderEmail,
          name: values.name,
          accountId: values.accountId,
          addressesData: {
            residential: {
              street: values.street,
              city: values.city,
              state: values.state,
              postal_code: values.postal_code,
              country: values.country,
            },
          },
          kycData: {
            pan: values.stakeHolderPan,
          },
          notes: {},
          individual_proof_of_address: [
            values.uploadStakeAddressProofBackImage[0],
            values.uploadStakeAddressProofFrontImage[0],
          ],
          individual_proof_of_address_type: [
            values.uploadStakeAddressBackType,
            values.uploadStakeAddressFrontType,
          ],
          individual_proof_of_identification: values.uploadStakeIdentityImage,
          individual_proof_of_identification_type:
            values.uploadStakeIdentityType,
        };
        dispatch(stackHolderService(requestBody, tokenData, "add", navigate));
      } else if (activeStep === 2) {
        const requestBody = {
          accountId: values.accountId,
          productId: values.productId,
          settlements: {
            account_number: values.account_number,
            ifsc_code: values.ifsc_code,
            beneficiary_name: values.beneficiary_name,
          },
          tnc_accepted: values.tnc_accepted,
        };
        dispatch(
          accountProductConfigService(requestBody, tokenData, "add", navigate)
        );
      }
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {paymentDetailActionData && paymentDetailActionData.length > 0
                    ? getRouteNameFromSidebar(
                        paymentDetailActionData[0].name,
                        localisation
                      )
                    : t("Payment Setting")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {paymentDetailActionData && paymentDetailActionData.length > 0
                    ? getRouteNameFromSidebar(
                        paymentDetailActionData[0].name,
                        localisation
                      )
                    : t("Payment Settings")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div style={{ width: "100%", margin: "auto" }}>
            <Stepper
              activeStep={activeStep}
              styleConfig={{
                activeBgColor: "#4caf50",
                completedBgColor: "#8bc34a",
                inactiveBgColor: "#e0e0e0",
              }}
            >
              <Step label={t("lable_step_1")} />
              <Step label={t("lable_step_2")} />
              <Step label={t("lable_step_3")} />
              {/* <Step label="Step 4" /> */}
            </Stepper>

            <div className="add-roles-title">
              {activeStep === 0 ? (
                <h4>{t("lable_business_information")}</h4>
              ) : activeStep === 1 ? (
                <h4>{t("lable_kyc_details")}</h4>
              ) : (
                <h4>{t("lable_account_details")}</h4>
              )}
            </div>
            <div>
              <div className="category-section add-roles-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema[activeStep]}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      {activeStep === 0 ? (
                        <div className="row">
                          <div className="col-12">
                            <Accordion
                              defaultActiveKey={["0"]}
                              alwaysOpen
                              className="product-accordion"
                            >
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <IoIosArrowForward />
                                  {t("customer_details")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="row">
                                    {/* <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            Name
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="name"
                                          name="contact_name"
                                          label="name"
                                          placeholder="Enter Your Name"
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.contact_name}
                                          disabled={true}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_email")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="email"
                                          name="email"
                                          label="email"
                                          placeholder={t(
                                            "placeholder_enter_your_email"
                                          )}
                                          className="add-roles"
                                          // Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.email}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_business_name")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="legal_business_name"
                                          label="Legal Business Name"
                                          placeholder={t(
                                            "placeholder_enter_business_name"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.legal_business_name}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          {t("lable_business_type")}
                                          <span className="required-label"></span>
                                        </h4>
                                        <Field
                                          className="select-menu mb-2"
                                          name="business_type"
                                          options={businessTypeOptions}
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={values.business_type}
                                          disabled={isDisable}
                                          onchangedata={(option) =>
                                            handleBusinessChange(option)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div className="col-12">
                            <Accordion
                              defaultActiveKey={["0"]}
                              alwaysOpen
                              className="product-accordion"
                            >
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <IoIosArrowForward />
                                  {t("lable_profile_details")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="row">
                                    <div className="col-md-6 mb-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          {t("lable_business_category")}
                                          <span className="required-label"></span>
                                        </h4>
                                        <Field
                                          className="select-menu mb-2"
                                          name="category"
                                          options={CategoryOptions}
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={values.category}
                                          changeData={(option) =>
                                            handleCategoryChange(
                                              option,
                                              setFieldValue
                                            )
                                          }
                                          disabled={isDisable}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          {t("lable_business_sub_category")}
                                          <span className="required-label"></span>
                                        </h4>
                                        <Field
                                          className="select-menu mb-2"
                                          name="subcategory"
                                          options={subCategoryOptions}
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={values.subcategory}
                                          disabled={isDisable}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div className="col-12">
                            <Accordion
                              defaultActiveKey={["0"]}
                              alwaysOpen
                              className="product-accordion"
                            >
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <IoIosArrowForward />
                                  {t("lable_address")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="row">
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_address")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="street1"
                                          label="street1"
                                          placeholder={t(
                                            "placeholder_enter_street"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.street1}
                                          disabled={
                                            values.street1 &&
                                            values.street1 !== ""
                                              ? true
                                              : false
                                          }
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            Street2
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="street2"
                                          label="street2"
                                          placeholder="Enter Your Street2"
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.street2}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_city")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="city"
                                          label=" City"
                                          placeholder={t(
                                            "placeholder_enter_city"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.city}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_state")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="state"
                                          label="State"
                                          placeholder={t(
                                            "placeholder_enter_state"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.state}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_postal_code")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="postal_code"
                                          label="Postal Code"
                                          placeholder={t(
                                            "placeholder_enter_postal_code"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.postal_code}
                                          disabled={true}
                                          changeInputData={(event) =>
                                            getAddressFromPincode(
                                              event.target.value,
                                              setFieldValue
                                            )
                                          }
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          {t("lable_country")}
                                          <span className="required-label"></span>
                                        </h4>
                                        <Field
                                          className="select-menu mb-2"
                                          name="country"
                                          options={countryOptions}
                                          component={CustomSelect}
                                          placeholder={t("placeholder_Select")}
                                          isMulti={false}
                                          value={values.country}
                                          disabled={isDisable}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div className="col-12">
                            <Accordion
                              defaultActiveKey={["0"]}
                              alwaysOpen
                              className="product-accordion"
                            >
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <IoIosArrowForward />
                                  {t("lable_legal_info")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="row">
                                    <div className="col-md-6 mb-4">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_business_pancard_number")}
                                            {!(
                                              values.business_type ===
                                                "proprietorship" ||
                                              values.business_type ===
                                                "not_yet_registered"
                                            ) && (
                                              <span className="required-label"></span>
                                            )}
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="pan"
                                          label="PanCard Number"
                                          placeholder={t(
                                            "placeholder_enter_pancard_number"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.pan}
                                          disabled={isDisable}
                                          maxLength={10}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_gst")}
                                          </h4>
                                        </div>
                                        <Input
                                          type="text"
                                          name="gst"
                                          label="GST"
                                          placeholder={t(
                                            "placeholder_enter_gst"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          //   value={values.productName}
                                          disabled={isDisable}
                                          maxLength={50}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          {!isDisable && (
                            <div className="col-12">
                              <Accordion
                                defaultActiveKey={["0"]}
                                alwaysOpen
                                className="product-accordion"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <IoIosArrowForward />
                                    {t("lable_documents_upload")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <div className="name-type">
                                          <div className="paragraph-editor ">
                                            <h4 className="paragraph-title">
                                              {t("lable_documents_type")}
                                              <span className="required-label"></span>
                                            </h4>
                                          </div>
                                          <Field
                                            className="select-menu mb-2"
                                            name="uploadAccImageType"
                                            options={accountDocumentOptions}
                                            component={CustomSelect}
                                            placeholder={t(
                                              "placeholder_Select"
                                            )}
                                            isMulti={false}
                                            value={values.uploadAccImageType}
                                            disabled={isDisable}
                                            onchangedata={(option) =>
                                              handleDocumentUpload(
                                                option,
                                                setFieldValue
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="paragraph-editor">
                                          <h4 className="paragraph-title">
                                            {t("lable_upload_image")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Field
                                          id="upload"
                                          name="uploadAccImage"
                                          maxCount={1}
                                          component={FileInput}
                                          placeholder={t("no_image_selected")}
                                          accept=".jpg, .jpeg, .png, .webp"
                                          className="upload"
                                          fileValue={values.uploadAccImage}
                                          disabled={isDisable}
                                        />
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          )}

                          {!isDisable && (
                            <div className="col-12">
                              <div className="btn-submit mt-3">
                                <>
                                  <Button
                                    type="submit"
                                    name={t("button_save")}
                                    className="btn login-btn"
                                  />
                                </>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : activeStep === 1 ? (
                        <div className="row">
                          <div className="col-12">
                            <Accordion
                              defaultActiveKey={["0"]}
                              alwaysOpen
                              className="product-accordion"
                            >
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <IoIosArrowForward />
                                  {t("lable_kyc")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="row">
                                    <div className="col-md-6 mb-4">
                                      <div className="name-type">
                                        <div className="paragraph-editor ">
                                          <h4 className="paragraph-title">
                                            {t("lable_owner_pancard_number")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Input
                                          // type="text"
                                          name="stakeHolderPan"
                                          label="PanCard Number"
                                          placeholder={t(
                                            "placeholder_enter_pancard_number"
                                          )}
                                          className="add-roles"
                                          Regex={/[^a-zA-Z0-9\s]/g}
                                          value={values.stakeHolderPan}
                                          disabled={isStackHolderDisable}
                                          maxLength={10}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          {!isStackHolderDisable && (
                            <div className="col-12">
                              <Accordion
                                defaultActiveKey={["0"]}
                                alwaysOpen
                                className="product-accordion"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <IoIosArrowForward />
                                    {t("lable_documents_upload")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <div className="name-type">
                                          <div className="paragraph-editor ">
                                            <h4 className="paragraph-title">
                                              {t("lable_identity_proof")}
                                              <span className="required-label"></span>
                                            </h4>
                                          </div>
                                          <Field
                                            className="select-menu mb-2"
                                            name="uploadStakeIdentityType"
                                            options={identityProofOptions}
                                            component={CustomSelect}
                                            placeholder={t(
                                              "placeholder_Select"
                                            )}
                                            isMulti={false}
                                            value={
                                              values.uploadStakeIdentityType
                                            }
                                            disabled={isStackHolderDisable}
                                            // onchangedata={(option) =>
                                            //   handleDocumentUpload(
                                            //     option,
                                            //     setFieldValue
                                            //   )
                                            // }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="paragraph-editor">
                                          <h4 className="paragraph-title">
                                            {t("lable_upload_image")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Field
                                          id="upload"
                                          name="uploadStakeIdentityImage"
                                          maxCount={1}
                                          component={FileInput}
                                          placeholder={t("no_image_selected")}
                                          accept=".jpg, .jpeg, .png, .webp"
                                          className="upload"
                                          fileValue={
                                            values.uploadStakeIdentityImage
                                          }
                                          disabled={isStackHolderDisable}
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="name-type">
                                          <div className="paragraph-editor ">
                                            <h4 className="paragraph-title">
                                              {t("lable_address_proof")}
                                              <span className="required-label"></span>
                                            </h4>
                                          </div>
                                          <Field
                                            className="select-menu mb-2"
                                            name="addressProofType"
                                            options={AddressProofOptions}
                                            component={CustomSelect}
                                            placeholder={t(
                                              "placeholder_Select"
                                            )}
                                            isMulti={false}
                                            value={values.addressProofType}
                                            disabled={isStackHolderDisable}
                                            onchangedata={(option) =>
                                              handleAddressProofUpload(
                                                option,
                                                setFieldValue
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="paragraph-editor">
                                          <h4 className="paragraph-title">
                                            {t("lable_upload_front_image")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Field
                                          id="upload"
                                          name="uploadStakeAddressProofFrontImage"
                                          maxCount={1}
                                          component={FileInput}
                                          placeholder={t("no_image_selected")}
                                          accept=".jpg, .jpeg, .png, .webp"
                                          className="upload"
                                          fileValue={
                                            values.uploadStakeAddressProofFrontImage
                                          }
                                          disabled={isStackHolderDisable}
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="paragraph-editor">
                                          <h4 className="paragraph-title">
                                            {t("lable_upload_back_image")}
                                            <span className="required-label"></span>
                                          </h4>
                                        </div>
                                        <Field
                                          id="upload"
                                          name="uploadStakeAddressProofBackImage"
                                          maxCount={1}
                                          component={FileInput}
                                          placeholder={t("no_image_selected")}
                                          accept=".jpg, .jpeg, .png, .webp"
                                          className="upload"
                                          fileValue={
                                            values.uploadStakeAddressProofBackImage
                                          }
                                          disabled={isStackHolderDisable}
                                        />
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          )}

                          {!isStackHolderDisable && (
                            <div className="col-12">
                              <div className="btn-submit mt-3">
                                <>
                                  <Button
                                    type="submit"
                                    name={t("button_save")}
                                    className="btn login-btn"
                                  />
                                </>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : activeStep === 2 ? (
                        <div className="row">
                          {requirements.length !== 0 ? (
                            <div className="col-12">
                              <div className="requirement-container">
                                {/* Display all fields in one line */}
                                <p className="field">
                                  <strong>
                                    <IoRadioButtonOnSharp /> {t("lable_fields")}
                                  </strong>{" "}
                                  {requirements
                                    .map((item) => item.field_reference)
                                    .join(", ")}
                                </p>
                                {/* Display the first reason */}
                                <p>
                                  <strong>
                                    <IoRadioButtonOnSharp /> {t("lable_reason")}
                                  </strong>{" "}
                                  {requirements[0].description ===
                                  "Max retry exceeded for bank account details."
                                    ? "Max retry exceeded for bank account details, Please try to update after 24hrs"
                                    : " " + requirements[0].description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="col-12">
                              <div className="requirement-container">
                                <p className="field">
                                  <strong>
                                    <IoRadioButtonOnSharp /> Msg:
                                  </strong>{" "}
                                  {staticRequireMsg}
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="col-md-6 mb-2">
                            <div className="name-type">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  {t("lable_ifsc_code")}
                                  <span className="required-label"></span>
                                </h4>
                              </div>
                              <Input
                                type="name"
                                name="ifsc_code"
                                label="name"
                                placeholder={t("placeholder_enter_ifsc_code")}
                                className="add-roles"
                                Regex={/[^a-zA-Z0-9\s]/g}
                                value={values.ifsc_code}
                                disabled={isAccUpdateDisable}
                                maxLength={50}
                                changeInputData={(event) =>
                                  verifyIfscCodeService(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div className="name-type">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  {t("lable_account_number")}
                                  <span className="required-label"></span>
                                </h4>
                              </div>
                              <Input
                                type="name"
                                name="account_number"
                                label="Account Number"
                                placeholder={t(
                                  "placeholder_enter_account_number"
                                )}
                                className="add-roles"
                                Regex={/[^a-zA-Z0-9\s]/g}
                                disabled={isAccUpdateDisable}
                                // disabled={isDisable}
                                maxLength={18}
                                value={values.account_number}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div className="name-type">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  {t("lable_beneficiary_name")}
                                  <span className="required-label"></span>
                                </h4>
                              </div>
                              <Input
                                type="name"
                                name="beneficiary_name"
                                label="name"
                                placeholder={t("placeholder_enter_your_name")}
                                className="add-roles"
                                Regex={/[^a-zA-Z0-9\s]/g}
                                disabled={isAccUpdateDisable}
                                // disabled={isDisable}
                                maxLength={50}
                                value={values.beneficiary_name}
                              />
                            </div>
                          </div>
                          {!isAccUpdateDisable && (
                            <div className="col-12">
                              <div className="btn-submit mt-3">
                                <>
                                  <Button
                                    type="submit"
                                    name={t("button_save")}
                                    className="btn login-btn"
                                  />
                                </>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="stepper-navigation">
              <button
                onClick={handleBack}
                disabled={activeStep === 0}
                className="btn btn-secondary btn-back"
              >
                {t("lable_back")}
              </button>
              <button
                onClick={handleNext}
                disabled={
                  activeStep === 0 && !isDisable
                    ? true
                    : activeStep === 1 && !isStackHolderDisable
                    ? true
                    : activeStep === 2 && true
                }
                className="btn btn-primary btn-back"
              >
                {t("lable_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;

import "./Button.scss";
import React from "react";

export default function Button(props) {
  const { type, name, page, className, ...rest } = props;
  return (
    <>
      <button
        className={className ? className : "btn"}
        type={props.type}
        {...rest}
      >
        {props.name}
      </button>
    </>
  );
}
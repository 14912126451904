import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Button from "../../../components/Button/Button";
import "./Addimage.scss";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import UtilsData, {
  API_URL,
  ValidationMessage,
  imageTypeOptions,
  imageUrlToFile,
} from "../../../Utils/Utils";
import FileInput from "../../../components/input/FileInput";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import ImageManagerService from "../ImageService/ImageService";

export default function Addimage({ handleToggle, Data, filterByType }) {
  const {
    addImageService,
    updateImageService,
  } = ImageManagerService();
  const { t } = useTranslation();
  const [maxLimit, setMaxLimit] = useState(5);

  const dispatch = useDispatch();

  const imagePage = useSelector((state) => state.imagePage.items);
  const user = useSelector((state) => state.auth.user);
  const tokenData = UtilsData();
  const {
    handleCancleClick,
    handlesaveClick,
  } = AlertMessage();

  //Initial form data value
  const [initialValues, setInitialValues] = useState({
    ImageType: "",
    uploadImage: [],
  });

  const validationSchema = Yup.object({
    ImageType: Yup.number().required(t(ValidationMessage.SINGLE_IMAGE_TYPE)),
    uploadImage: Yup.array().min(1, t(ValidationMessage.UPLOAD_IMAGE)),
  });
  //Setting data grom imageManager
  useEffect(() => {
    if (Data && Data.imageId) {
      imageUrlToFile(API_URL.TOKEN_URL + Data.imagePath)
        .then((file) => {
          setInitialValues({
            ImageType: Data.imageType,
            uploadImage: [file],
          });
        })
        .catch((error) => {
          setInitialValues({
            ImageType: Data.imageType,
            uploadImage: [API_URL.TOKEN_URL + Data.imagePath],
          });
        });
      setMaxLimit(1);
    } else {
      if (filterByType && filterByType !== "filter") {
        setInitialValues({
          ImageType: filterByType,
        });
      }
    }
    // eslint-disable-next-line
  }, [Data]);

  /**
   * Handle Form submission
   */
  const handleSubmit = (values) => {
    if (maxLimit && maxLimit > 1) {
      const requestBody = {
        images: values.uploadImage,
        type_id: values.ImageType,
        created_by: user.data.userId,
      };
      dispatch(addImageService(requestBody, tokenData, handleToggle));
    }

    if (maxLimit === 1) {
      const requestBody = {
        images: values.uploadImage,
        type_id: values.ImageType,
        created_by: Data.createdBy,
        modified_by: user.data.userId,
        image_ids: [
          {
            id: Data.imageId,
          },
        ],
        IsDeleted: "0",
      };
      if (imagePage > 1) {
        handlesaveClick(
          dispatch,
          updateImageService(requestBody, tokenData, handleToggle, imagePage),
          handleToggle,
          "imageManager"
        );
      } else {
        handlesaveClick(
          dispatch,
          updateImageService(requestBody, tokenData, handleToggle),
          handleToggle,
          "imageManager"
        );
      }
    }
  };

  return (
    <>
      <div className=" add-image-section">
        <div className="body-content mar-left">
          <div className="table-body-content add-image-height">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_image_type")}<span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="ImageType"
                          options={imageTypeOptions}
                          component={CustomSelect}
                          placeholder={t("placeholder_Select")}
                          isMulti={false}
                          value={values.ImageType}
                          disabled={
                            filterByType && filterByType !== "filter"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          {t("lable_upload_image")}<span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        id="upload"
                        name="uploadImage"
                        maxCount={maxLimit}
                        component={FileInput}
                        placeholder={t("no_image_selected")}
                        accept=".jpg, .jpeg, .png, .webp"
                        className="upload"
                        fileValue={values.uploadImage}
                      />
                    </div>
                    <div className="col-12">
                      <div className="btn-submit mt-3">
                        <Button
                          type="submit"
                          name={t("button_save")}
                          className="btn login-btn"
                        />
                        <Button
                          type="button"
                          name={t("button_cancel")}
                          className="btn clear-btn login-btn"
                          onClick={() =>
                            handleCancleClick("imageManager", handleToggle)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
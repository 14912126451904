export const AdminUserToggleRequest = ({
  toggleData,
  userDetail,
  userId,
  isActive,
  isDelete,
}) => {
  const adminRequestBody = {
    p_user_id: toggleData.userId,
    p_username: toggleData.username,
    p_first_name: toggleData.firstName,
    p_last_name: toggleData.lastName,
    p_email: toggleData.email,
    p_role_permissions_id: toggleData.rolePermissionsId,
    p_is_active: isActive
      ? toggleData.isActive === "1"
        ? "0"
        : "1"
      : toggleData.isActive,
    p_IsDeleted: isDelete
      ? toggleData.isDeleted === "1"
        ? "0"
        : "1"
      : toggleData.isDeleted,
    p_wp_sms_allow: userDetail.data.wp_sms_allow,
    p_created_by: toggleData.createdBy,
    p_modified_by: userId,
    p_phone_number: userDetail.data.phoneNumber,
    p_brand_ids:
      userDetail.data?.brand_ids &&
      JSON.parse(userDetail.data.brand_ids).length > 0
        ? JSON.parse(userDetail.data.brand_ids).map((item) => ({
            id: Number(item?.brand_id),
          }))
        : null,
    p_branch_ids:
      userDetail.data?.branch_ids &&
      JSON.parse(userDetail.data.branch_ids).length > 0
        ? JSON.parse(userDetail.data.branch_ids).map((item) => ({
            id: Number(item?.branch_id),
          }))
        : null,
    p_beep_option: userDetail.data?.beep_option
      ? userDetail.data?.beep_option
      : "0",
  };
  return adminRequestBody;
};
import {
  CLEAR_ALL_PREFERENCE_FILTER,
  GET_ALL_PREFERENCE_FILTER,
} from "../../ReduxKeys";

/**
 * set all Preferences filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getAllPreferencesFilterData = ({ p_user_id, p_product_name }) => ({
  type: GET_ALL_PREFERENCE_FILTER,
  payload: {
    p_user_id,
    p_product_name,
  },
});

/**
 * clear all Preferences filtered data
 * @returns {*}
 */
export const clearAllPreferencesFilterData = () => ({
  type: CLEAR_ALL_PREFERENCE_FILTER,
});
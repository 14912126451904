import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from "react-bootstrap";
import Toggle from "../../components/Toggle/Toggle";
import ExcelData from "../../services/Regular-services/ExcelExport";

const columns = [
  {
    name: "Tax Name",
    selector: (row) => row.Name,
  },
  {
    name: "Country",
    selector: (row) => row.Country,
  },
  {
    name: "State",
    selector: (row) => row.state,
  },
  {
    name: "Tax Rate",
    selector: (row) => row.Rate,
  },
  {
    name: "Tax Class",
    selector: (row) => row.class,
  },
  {
    name: "Status",
    cell: (row) => (
      <Toggle
        checked={row.toggle}
        onChange={() => handleToggleChange(row.id)}
      />
    ),
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];

const data = [
  {
    id: 1,
    name: "New string",
    Country: "India",
    state: "Gujarat",
    Rate: "500",
    class: "1",
  },
  {
    id: 2,
    name: "New string 1",
    Country: "India",
    state: "Gujarat",
    Rate: 500,
    class: 1,
  },
  {
    id: 3,
    name: "New string 2",
    Country: "India",
    state: "Gujarat",
    Rate: 500,
    class: 1,
  },
];

//Show actions which is to be displayed in table
const showActions = {
  showView: true,
  showEdit: true,
  showDelete: true,
  navigate: "/add-tax",
};

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  Country: true,
  state: true,
  Rate: true,
  class: true,
};
const showToggle = {
  status: true,
  delete: false,
};
const handleToggleChange = (id) => {};
export default function Tax() {
  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };
  return (
    <>
      <div >
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Tax</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Tax</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                <Dropdown className="new-header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Delete</Dropdown.Item>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                    <Dropdown.Item>Undo</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {data && data.length > 0 && (
                  <ExcelData excelData={data} fileName={"image"}></ExcelData>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Tag"
                  onClick={() => navigate("/add-tax")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {isToggled && <Filter page="tags"></Filter>}
            <CustomTable
              showToggle={showToggle}
              checkBool={false}
              showKey={showKey}
              showActions={showActions}
              showTooltip={true}
              data={data}
              heading={columns}
            />
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
}
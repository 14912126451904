import { CLEAR_CUSTOMER_NOTE_PAGE, GET_CUSTOMER_NOTE_PAGE } from "../../ReduxKeys";

/**
 * Set Customer Note page data
 * @param {*} page
 * @returns {*}
 */
export const getCustomerNotePageData = (page) => ({
    type: GET_CUSTOMER_NOTE_PAGE,
    payload: page,
  });
  
  /**
   * Clear Preferences page data
   * @returns {*}
   */
  export const clearCustomerNotePageData = () => ({
    type: CLEAR_CUSTOMER_NOTE_PAGE,
  });
  
import {
  CLEAR_PRODUCT_INVENTORY_FILTER,
  GET_PRODUCT_INVENTORY_FILTER,
} from "../../ReduxKeys";

/**
 * Setting product inventory filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getProductInventoryFilterData = ({ productId }) => ({
  type: GET_PRODUCT_INVENTORY_FILTER,
  payload: { productId },
});

/**
 * Clearing product inventory filtered data
 * @returns {*}
 */
export const clearProductInventoryFilterData = () => ({
  type: CLEAR_PRODUCT_INVENTORY_FILTER,
});
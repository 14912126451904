/**
 * Set spot list data into reducer
 * @param {*} data
 * @returns {*}
 */

import {
  CLEAR_SPOT_DATA,
  CLEAR_TOTAL_SPOT_DATA,
  GET_SPOT_DATA,
  GET_TOTAL_SPOT_DATA,
} from "../../ReduxKeys";

export const getSpotData = (data) => ({
  type: GET_SPOT_DATA,
  payload: data,
});

/**
 * Clear spot list data
 * @returns {*}
 */
export const clearSpotData = () => ({
  type: CLEAR_SPOT_DATA,
});

/**
 * Set total spot list data into reducer (No pagination)
 * @param {*} spot
 * @returns {*}
 */
export const getTotalSpotData = (spot) => ({
  type: GET_TOTAL_SPOT_DATA,
  payload: spot,
});

/**
 * Clear total spot list data (No pagination)
 * @returns {*}
 */
export const clearTotalSpotData = () => ({
  type: CLEAR_TOTAL_SPOT_DATA,
});
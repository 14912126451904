import { CLEAR_ROLE_FILTER, GET_ROLE_FILTER } from "../../ReduxKeys";

const rolesFilterInitialState = {
  name: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store role filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const RoleFilterReducer = (state = rolesFilterInitialState, action) => {
  switch (action.type) {
    case GET_ROLE_FILTER:
      return {
        ...state,
        name: action.payload.name,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case CLEAR_ROLE_FILTER:
      return rolesFilterInitialState;
    default:
      return state;
  }
};
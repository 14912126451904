// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-code td {
  border: unset !important;
}
.qr-code th {
  border: unset !important;
}
.qr-code tr {
  border: unset !important;
  margin-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/QRCode/QRCode.scss"],"names":[],"mappings":"AACI;EACA,wBAAA;AAAJ;AAEA;EACI,wBAAA;AAAJ;AAGA;EACI,wBAAA;EACA,2BAAA;AADJ","sourcesContent":[".qr-code{\n    td{\n    border: unset !important;\n}\nth{\n    border: unset !important;\n   \n}\ntr{\n    border: unset !important;\n    margin-bottom: 0 !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

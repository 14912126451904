import React, { useEffect, useRef, useState } from "react";
import "./Table.scss";
import Table from "react-bootstrap/Table";
import Toggle from "../Toggle/Toggle";
import { FaEye, FaPlus, FaStarHalfAlt } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa6";
import Checkbox from "../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit, MdOutlineCancel, MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import UtilsData, {
  API_URL,
  ValidationMessage,
  imageUrlToFile,
  orderPaymentTableArray,
  orderStatusTableArray,
} from "../../Utils/Utils";
import { GrUndo } from "react-icons/gr";
import { getCheckboxData } from "../../redux/Actions/CheckboxAction/CheckboxAction";
import { toast } from "react-toastify";
import ErrorImage from "./../../assets/images/placeholder.png";
import { updateSequenceService } from "../../services/Sequence-service/SequenceService";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import {
  IoMdPrint,
  IoLogoWhatsapp,
  IoIosCheckmarkCircle,
  IoIosSave,
} from "react-icons/io";
import { OrderRequestBodyOnToggle } from "../../pages/Order/OrderRequestBodyOnToggle/OrderRequestBodyOnToggle";
import { buildVendorRequestBody } from "../../pages/Vendors/buildVendorRequestBody/buildVendorRequestBody";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MdDeleteForever } from "react-icons/md";
import Button from "../Button/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../input/Input";
import { PurchaseRequisitionReqBody } from "../../pages/PurchaseRequisition/PurchaseRequisitionStatusReqbody/PurchaseRequisitionReqBody";
import { clearPurchaseOrderPageData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import generateInvoiceHtml from "../generateInvoiceHtml/generateInvoiceHtml";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../Alertmessage/Alertmessage";
import OrderServices from "../../pages/Order/OrderService/OrderService";
import ProductServices from "../../pages/Product/ProductService/ProductService";
import PurchaseOrderServices from "../../pages/PurchaseOrder/PurchaseOrderService/PurchaseOrderService";
import PurchaseRequisitionServices from "../../pages/PurchaseRequisition/PurchaseRequisitionService/PurchaseRequisitionService";
import VendorServices from "../../pages/Vendors/VendorsService.js/VendorService";
import { VendorPrintHTML } from "./VendorPrintHtml";
import { OrderPrintHtml } from "./OrderPrintHtml";
import { VendorWhatsappHtml } from "./VendorWhatsappHtml";

// import ReactDOMServer from 'react-dom/server';
export default function CustomTable(props) {
  const { placeOrderService } = OrderServices();
  const { updateProductPriceService } = ProductServices();
  const {
    addUpdatePurchaseOrderService,
    getGRNDetailService,
    uploadPDFServicePO,
  } = PurchaseOrderServices();
  const { addUpdateVendorService } = VendorServices();
  const { addUpdatePurchaseRequisitionService } = PurchaseRequisitionServices();
  const {
    handledeletClick,
    handleLineLevelOrderConfirmationClick,
    handleLineLevelOrderDeleteClick,
    handleOrderConfirmationClick,
    handlesaveClick,
    handleToggleChange,
    handleUndoDelete,
  } = AlertMessage();

  const { t } = useTranslation();
  const { productNewData, setProductNewData } = props;

  const [initialValues, setInitialValues] = useState(() => {
    if (props.page === "order") {
      return { zomatoSwiggyPrice: "" };
    } else {
      return {};
    }
  });

  const [validationSchema, setValidationSchema] = useState(() =>
    Yup.object({})
  );

  const loader = useSelector((state) => state.loader?.items);
  const user = useSelector((state) => state.auth?.user);
  const userData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const categoryFilter = useSelector((state) => state.categoryFilter);
  const SubCategoryFilter = useSelector((state) => state.SubCategoryFilter);
  const orderStatusArray = orderStatusTableArray[props.page];
  const [orderPaymentArray, setOrderPaymentArray] = useState([]);
  const tooltipRef = useRef(null);
  const [zomatoSwiggyModal, setZomatoSwiggyModal] = useState(false);
  const [selectAllValue, setSelectAllValue] = useState([]);
  const [disableArray, setDisableArray] = useState([]);
  const [sequenceArray, setSequenceArray] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [paymentSequenceArray, setPaymentSequenceArray] = useState([]);
  const [selectSequenceData, setSelectSequenceData] = useState(false);
  const [selectPaymentSequenceData, setPaymentSelectSequenceData] =
    useState(false);

  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  //Handling Navigation
  const handleNavigate = (route, data, isEdit) => {
    const Data = {
      data: data,
      isEdit: isEdit,
      length: props.page === "product" ? props.productLength : tableData.length,
    };
    if (props.page === "imageManager") {
      props.handleToggle(data, tableData.length);
    } else {
      navigate(route, { state: { Data } });
    }
  };

  let { data, heading, showId, showDescription } = props;
  const [tableData, setTableData] = useState([]);

  //Setting data on initialization
  const [imageErrors, setImageErrors] = useState([]);
  const [notificationImageErrors, setNotificationImageErrors] = useState([]);
  useEffect(() => {
    if (data && data?.items) {
      setTableData(data.items);
    } else {
      setTableData(data);
    }
  }, [data]);

  /**
   * Function for showing static image if image is broken
   * @param {*} index
   */
  const handleImageError = (index) => {
    setImageErrors((prevErrors) => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };

  /**
   * Function for showing static image if image is broken
   * @param {*} index
   */
  const handleNotificationImageError = (index) => {
    setNotificationImageErrors((prevErrors) => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };

  // Handling checkbox for uploading image
  useEffect(() => {
    if (props.page === "uploadImage") {
      if (props.uploadImageValue && props.uploadImageValue.length > 0) {
        if (data.items) {
          const updateIndex = [];
          data.items.filter((item, index) => {
            return props.uploadImageValue.some((product) => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        } else {
          const updateIndex = [];
          data.filter((item, index) => {
            return props.uploadImageValue.some((product) => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        }
      }
    }
  }, [data, props.page, props.uploadImageValue]);

  useEffect(() => {
    if (props.page === "addRoles" || props.page === "add-company") {
      disableCompanyandRolesFields();
    }
    // eslint-disable-next-line
  }, [props.page, data]);

  //Disable other toggle keys if value of isAllow toggle key is false
  const disableCompanyandRolesFields = () => {
    let disableId = [];
    let selectValue = [];
    const processItems = (items) => {
      items.forEach((allow) => {
        if (["0", 0, false].includes(allow.allow)) {
          disableId.push(allow.name);
        } else if (
          ["1", 1, true].includes(allow.allow) &&
          ["1", 1, true].includes(allow.add) &&
          ["1", 1, true].includes(allow.edit) &&
          ["1", 1, true].includes(allow.view) &&
          ["1", 1, true].includes(allow.delete)
        ) {
          selectValue.push(allow.name);
        }
      });
    };

    if (data?.items) {
      processItems(data.items);
    } else if (data) {
      processItems(data);
    }

    setSelectAllValue(selectValue);
    setDisableArray(disableId);
  };

  //Handling Toggle
  const handleToggle = (itemData, itemType, checked) => {
    // const currentDate = new Date();
    const itemId = itemData.id;
    // const formattedDate = formatDate(currentDate);
    const updatedData =
      tableData &&
      tableData.map((item) => {
        if (item.id && item.id === itemId) {
          let updatedItem = item;
          if (
            !["isActive", "toggle", "status", "deleteItem"].includes(itemType)
          ) {
            return {
              ...item,
              [itemType + "Toggle"]: !item[itemType + "Toggle"],
            };
          } else if (itemType === "status") {
            if (
              ["blog", "faq", "Policies", "Policy", "policy"].includes(
                props.page
              )
            ) {
              toast.success(`${props.page} status updated successfully!`);
            } else if (props.page === "customer") {
              return { ...item, [itemType]: !item[itemType] };
            } else {
              handleToggleChange(
                dispatch,
                props.page,
                checked,
                tokenData,
                userData.user.data.userId,
                updatedItem,
                tableData.length,
                props?.pageData || 1
              );
            }
          }
        } else if (item?.name && item.name === itemData) {
          let updatedItem = item;
          if (["addRoles", "add-company"].includes(props.page)) {
            const toggles = [
              "allow",
              "delete",
              "add",
              "view",
              "edit",
              "selectAll",
            ];
            const updateToggle = (value) => {
              toggles.forEach((key) => (item[key] = value ? 1 : 0));
            };
            if (itemType === "selectAll") {
              setSelectAllValue(
                checked
                  ? [...selectAllValue, itemData]
                  : selectAllValue.filter((id) => id !== itemData)
              );
              updateToggle(checked);
              setDisableArray(
                checked
                  ? disableArray.filter((id) => id !== itemData)
                  : [...disableArray, itemData]
              );
            } else {
              item[itemType] = checked ? 1 : 0;
              if (itemType === "allow") updateToggle(checked);
              setSelectAllValue(selectAllValue.filter((id) => id !== itemData));
              if (["selectAll", "allow"].includes(itemType)) {
                setDisableArray(
                  checked
                    ? disableArray.filter((id) => id !== itemData)
                    : [...disableArray, itemData]
                );
              }
            }
            return updatedItem;
          }
        } else if (itemType === "status") {
          handleToggleChange(
            dispatch,
            props.page,
            checked,
            tokenData,
            userData.user.data.userId,
            itemData,
            tableData.length,
            props.pageData
          );
        }
        return item;
      });
    setTableData(updatedData);
  };

  /**
   * Function for undo the deleted data
   */
  const handleUndoClick = (item) => {
    let undoData = item;
    if (props.page === "imageManager" || props.page === "imagemanager") {
      imageUrlToFile(API_URL.TOKEN_URL + item.imagePath)
        .then((file) => {
          undoData.imagePath = [file];
          handleUndoDelete(
            dispatch,
            "imageManager",
            undoData,
            tokenData,
            user.data.userId,
            tableData.length,
            props?.pageData || 1
          );
        })
        .catch((error) => {
          toast.error(t(ValidationMessage.SOMETHING_WENT_WRONG));
        });
    } else {
      handleUndoDelete(
        dispatch,
        props.page,
        undoData,
        tokenData,
        user.data.userId,
        tableData.length,
        props?.pageData || 1
      );
    }
  };

  /**
   * Function for undo the deleted data
   */
  const handleDeleteClick = (item) => {
    handledeletClick(
      item,
      dispatch,
      props.page,
      tokenData,
      tableData.length,
      props.pageData
    );
  };

  const printOrderDetails = (orderData) => {
    if (props.page === "vendors") {
      const printWindow = window.open("", "", "height=600,width=800");
      const vendorHtmlContent = VendorPrintHTML({ user, orderData });
      printWindow.document.open();
      printWindow.document.write(vendorHtmlContent);
      printWindow.document.close();
      printWindow.print();
    } else {
      const printWindow = window.open("", "", "height=600,width=800");
      const htmlContent = OrderPrintHtml({ orderData });
      printWindow.document.open();
      printWindow.document.write(htmlContent);
      printWindow.document.close();
      printWindow.print();
    }
  };

  const printInvoice = (orderData) => {
    const printWindow = window.open("", "", "height=600,width=800");
    const invoiceHtml1 = generateInvoiceHtml(orderData, user);
    printWindow.document.open();
    printWindow.document.write(invoiceHtml1);
    printWindow.document.close();
    printWindow.print();
  };

  const generatePDFAndUpload = async (orderData) => {
    const requestBody = {
      p_pr_id: orderData.pr_id,
      p_po_id: orderData.id,
    };
    const res = await getGRNDetailService(requestBody, tokenData, dispatch);
    const originalIngredients = JSON.parse(orderData.ingredients);
    if (originalIngredients[0].ingredient_id === null) {
      toast.error(t("ingredientNullError"));
    } else {
      const ingredientData = res.data.map((item) => {
        return JSON.parse(item.ingredients_json);
      });
      const totalValues = ingredientData.flat().reduce((acc, ingredient) => {
        const { ingredient_id } = ingredient;
        const receivedValue = parseFloat(ingredient.recived_value) || 0;
        const damageValue = parseFloat(ingredient.damage_value) || 0;
        if (!acc[ingredient_id]) {
          acc[ingredient_id] = {
            ingredient_id,
            totalReceived: 0,
          };
        }
        acc[ingredient_id].totalReceived += receivedValue + damageValue;
        return acc;
      }, {});
      const totalValuesArray = Object.values(totalValues);
      const updatedIngredients = originalIngredients.map(
        (originalIngredient) => {
          const matchedTotal = totalValuesArray.find(
            (item) => item.ingredient_id === originalIngredient.ingredient_id
          );
          if (matchedTotal) {
            const originalValue = parseFloat(originalIngredient.value) || 0;
            const updatedQuantity = originalValue - matchedTotal.totalReceived;
            return {
              ...originalIngredient,
              value: updatedQuantity.toString(),
            };
          }
          return originalIngredient;
        }
      );
      const finalIngredients = updatedIngredients.filter(
        (ingredient) => parseFloat(ingredient.value) !== 0
      );
      if (finalIngredients && finalIngredients.length > 0) {
        const updatedData = {
          ...orderData,
          ingredients: JSON.stringify(finalIngredients),
        };
        const { companyName, companyOwnerName, companyPhneNumber } = user.data;
        const vendorHtmlContent = VendorWhatsappHtml({ user, updatedData });
        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.left = "-9999px";
        tempDiv.style.top = "-9999px";
        tempDiv.style.width = "210mm";
        tempDiv.style.height = "297mm";
        tempDiv.style.overflow = "hidden";
        tempDiv.innerHTML = vendorHtmlContent;
        document.body.appendChild(tempDiv);

        try {
          const canvas = await html2canvas(tempDiv, { scale: 1.5 });
          const imgData = canvas.toDataURL("image/jpeg", 0.7);
          const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });
          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
          const pdfBlob = pdf.output("blob");
          const file = new File(
            [pdfBlob],
            `order-details-${updatedData?.poNum}.pdf`,
            {
              type: "application/pdf",
            }
          );
          const uploadData = {
            pdf: file,
            p_vendor_phone_number: orderData.phNum,
            p_branch_user_phone_number: companyPhneNumber,
            p_branch_user_name: companyOwnerName,
            p_company_name: companyName,
            p_branch_Address: user.data.branchAddress,
          };

          dispatch(uploadPDFServicePO(uploadData, tokenData, navigate));
        } catch (error) {
          console.error("Error generating PDF or uploading:", error);
        } finally {
          document.body.removeChild(tempDiv);
        }
      } else {
        toast.warn(t("goodsReceiveNotesGenerated"));
      }
    }
  };

  const handlePO = (data) => {
    const requestBody = PurchaseRequisitionReqBody(data, "4");
    const ingredientData = requestBody.p_product_data.filter(
      (item) => item.ingredient_status === "1"
    );
    const requestPOBody = {
      p_pr_id: requestBody.p_id,
      p_instruction: requestBody.p_instruction,
      p_product_data: ingredientData,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_status: "4",
    };
    dispatch(
      addUpdatePurchaseRequisitionService(
        requestBody,
        tokenData,
        "update",
        false,
        props?.pageData || 1
      )
    );
    dispatch(clearPurchaseOrderPageData());
    dispatch(
      addUpdatePurchaseOrderService(requestPOBody, tokenData, "add", navigate)
    );
  };

  const handleGRN = async (route, data) => {
    const requestBody = {
      p_pr_id: data.pr_id,
      p_po_id: data.id,
    };
    const res = await getGRNDetailService(requestBody, tokenData, dispatch);
    const originalIngredients = JSON.parse(data.ingredients);
    if (originalIngredients[0].ingredient_id === null) {
      toast.error(t("ingredientNullError"));
    } else {
      const ingredientData = res.data.map((item) => {
        return JSON.parse(item.ingredients_json);
      });

      const totalValues = ingredientData.flat().reduce((acc, ingredient) => {
        const { ingredient_id } = ingredient;
        const receivedValue = parseFloat(ingredient.recived_value) || 0;
        const damageValue = parseFloat(ingredient.damage_value) || 0;
        if (!acc[ingredient_id]) {
          acc[ingredient_id] = {
            ingredient_id,
            totalReceived: 0,
          };
        }
        acc[ingredient_id].totalReceived += receivedValue + damageValue;
        return acc;
      }, {});
      const totalValuesArray = Object.values(totalValues);
      const updatedIngredients = originalIngredients.map(
        (originalIngredient) => {
          const matchedTotal = totalValuesArray.find(
            (item) => item.ingredient_id === originalIngredient.ingredient_id
          );

          if (matchedTotal) {
            const originalValue = parseFloat(originalIngredient.value) || 0;
            const updatedQuantity = originalValue - matchedTotal.totalReceived;

            return {
              ...originalIngredient,
              value: updatedQuantity.toString(),
            };
          }

          return originalIngredient;
        }
      );

      // Filter out ingredients with a value of 0
      const finalIngredients = updatedIngredients.filter(
        (ingredient) => parseFloat(ingredient.value) !== 0
      );
      if (finalIngredients && finalIngredients.length > 0) {
        // Replace ingredients in data
        const updatedData = {
          ...data,
          ingredients: JSON.stringify(finalIngredients),
        };
        navigate(route, { state: { grnData: updatedData } });
      } else {
        toast.warn(t("goodsReceiveNotesGenerated"));
      }
    }
  };

  useEffect(() => {
    if (props.page === "product-pricing") {
      setProductNewData(
        props.data.map((item) => ({
          id: item.id,
          skuCode: item.skuCode || "",
          price: item.price || "0.00",
          takeAwayPrice: item.takeAwayPrice || "0.00",
          zomatoPrice: item.zomatoPrice || "0.00",
          swiggyPrice: item.swiggyPrice || "0.00",
          otherPrice: item.otherPrice || "0.00",
          cgst: item.cgst || "0.00",
          sgst: item.sgst || "0.00",
        }))
      );
    }
    // eslint-disable-next-line
  }, [props.data, props.page]);

  const handlePricingValue = (id, key) => {
    const newData = productNewData.find((data) => data.id === id);
    return newData[key];
  };
  const handleProductPricingUpdate = (data) => {
    const matchedData = productNewData.find((item) => item.id === data.id);
    if (matchedData.skuCode === "") {
      toast.warn(t("skuCodeEmptyError"));
    } else if (
      matchedData.price === "" ||
      matchedData.price === 0 ||
      matchedData.price === "0"
    ) {
      toast.warn(t("priceValidationError"));
    } else {
      const finalProductData = {
        id: matchedData.id,
        sku_code: `SKU-${userData.user.data.companyCode}-${matchedData.skuCode}`,
        sgst: matchedData.sgst !== "" ? Number(matchedData.sgst) : 0,
        cgst: matchedData.cgst !== "" ? Number(matchedData.cgst) : 0,
        dine_in_price: Number(matchedData.price),
        take_away_price:
          matchedData.takeAwayPrice !== "0.00"
            ? Number(matchedData.takeAwayPrice)
            : 0,
        zomato_price:
          matchedData.zomatoPrice !== "0.00"
            ? Number(matchedData.zomatoPrice)
            : 0,
        swiggy_price:
          matchedData.swiggyPrice !== "0.00"
            ? Number(matchedData.swiggyPrice)
            : 0,
        other_price:
          matchedData.otherPrice !== "0.00"
            ? Number(matchedData.otherPrice)
            : 0,
      };
      const requestBody = {
        p_product_data: [finalProductData],
      };
      handlesaveClick(
        dispatch,
        updateProductPriceService(requestBody, tokenData, "update", navigate),
        "update"
      );
    }
  };

  const handleInputChange = (rowIndex, key, value) => {
    const updatedData = [...productNewData];
    updatedData[rowIndex][key] = value;
    setProductNewData(updatedData);
  };

  // handle settlement input change in pos
  const handleSettlementChange = (e, key, currentItem) => {
    const updatedValue = e;
    const updatedItem = {
      ...currentItem,
      [key]: updatedValue ? updatedValue : "",
    };
    props.handleUpdateItem(updatedItem);
  };

  const handleOnInput = (e, limit) => {
    if (e.target.value.length > limit) {
      e.target.value = e.target.value.slice(0, limit);
    }
  };

  const handleKeyDown = (e) => {
    // Allow common navigation and function keys
    const allowedKeys = [
      "Backspace",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (e.ctrlKey) {
      return;
    }
    const isValidKey =
      /^\d$/.test(e.key) || e.key === "." || allowedKeys.includes(e.key);
    if (e.key === "." && e.target.value.includes(".")) {
      e.preventDefault();
    }
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (props.totalRecords && props.totalRecords !== 0) {
      const newArray = Array.from(
        { length: props.totalRecords },
        (_, index) => ({ value: index + 1, label: String(index + 1) })
      );
      setSequenceArray(newArray);
      setPaymentSequenceArray(newArray);
    } else {
      if (tableData && tableData.length > 0) {
        const newArray = Array.from(
          { length: tableData.length },
          (_, index) => ({ value: index + 1, label: String(index + 1) })
        );
        setSequenceArray(newArray);
        setPaymentSequenceArray(newArray);
      }
    }
  }, [tableData, props.totalRecords]);

  //Checkbox change on checking from header
  const handleHeaderCheckboxChange = (event) => {
    const { checked } = event.target;
    setSelectAllCheck(checked);
    const updatedSelectedItems = checked
      ? tableData.map((_, index) => index)
      : [];
    setSelectedItems(updatedSelectedItems);
  };

  //Checkbox cheange on checking from body
  const handleRowCheckboxChange = (index, event) => {
    const { checked } = event.target;
    let updatedSelectedItems = [...selectedItems];
    if (checked) {
      if (props.restrictOneImage && props.restrictOneImage === true) {
        if (updatedSelectedItems.length >= 1) {
          // If maximum limit reached, uncheck the first checked image
          updatedSelectedItems.shift();
          updatedSelectedItems.push(index);
        } else {
          updatedSelectedItems.push(index);
        }
      } else {
        if (props.restrictFive === true && updatedSelectedItems.length >= 5) {
          // If maximum limit reached, uncheck the first checked image
          toast.warn(t("imageLimitError", { imageCount: 5 }));
        } else {
          updatedSelectedItems.push(index);
        }
      }
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(
        (item) => item !== index
      );
    }
    if (props.page === "uploadImage") {
      const filteredTableData =
        tableData &&
        tableData.filter((item, index) => {
          return updatedSelectedItems && updatedSelectedItems.includes(index);
        });
      props.onCheckChange({
        filterData: filteredTableData,
        errorImageList: imageErrors,
      });
    }
    setSelectedItems(updatedSelectedItems);
    setSelectAllCheck(updatedSelectedItems.length === tableData.length);
    dispatch(getCheckboxData({ data: updatedSelectedItems }));
  };

  /**
   * Functions for total amount val in onChange and  changeOrderPaymentStatus
   * @param {*} event
   * @param {*} item
   */

  const onChange = (event, item) => {
    if (["category", "subCategory"].includes(props.page)) {
      const eventType = props.page === "category" ? "category" : "sub_category";
      const requestBody = {
        p_Id: item.id,
        p_NewSequence: parseInt(event.target.value),
        p_Type: eventType,
      };
      const pageNumber = props?.pageData ? props.pageData + 1 : 1;
      dispatch(updateSequenceService(requestBody, tokenData, pageNumber));
    } else if (props.page === "order") {
      const requestBody = OrderRequestBodyOnToggle({
        item: item,
        status: event.target.value,
      });

      let orderStatusData = orderStatusArray.find(
        (item) => item.value === event.target.value
      ).className;

      if (["1", "4"].includes(event.target.value)) {
        handleToggleChange(
          dispatch,
          "order",
          orderStatusData,
          tokenData,
          user.data.userId,
          requestBody,
          tableData.length,
          props?.pageData || 1
        );
      } else {
        dispatch(
          placeOrderService(
            requestBody,
            tokenData,
            "update",
            false,
            props?.pageData || 1
          )
        );
      }
    } else if (props.page === "vendors") {
      const requestBody = buildVendorRequestBody(
        item,
        null,
        event.target.value
      );

      dispatch(
        addUpdateVendorService(
          requestBody,
          tokenData,
          "update",
          false,
          props?.pageData || 1
        )
      );
    } else if (props.page === "purchase-requisition") {
      const requestBody = PurchaseRequisitionReqBody(item, event.target.value);
      dispatch(
        addUpdatePurchaseRequisitionService(
          requestBody,
          tokenData,
          "update",
          false,
          props?.pageData || 1
        )
      );
    }
  };

  const changeOrderPaymentStatus = (event, item) => {
    if (props.page === "order") {
      if (event.target.value === "5" || event.target.value === "6") {
        setZomatoSwiggyModal(!zomatoSwiggyModal);
        setInitialValues({
          zomatoSwiggyPrice: "",
          payment: event.target.value,
          item: item,
        });
      } else {
        const requestBody = OrderRequestBodyOnToggle({
          item: item,
          payment: event.target.value,
        });
        dispatch(
          placeOrderService(
            requestBody,
            tokenData,
            "update",
            false,
            props?.pageData || 1,
            true
          )
        );
      }
    } else if (props.page === "vendors") {
      const requestBody = buildVendorRequestBody(
        item,
        event.target.value,
        null
      );

      dispatch(
        addUpdateVendorService(
          requestBody,
          tokenData,
          "update",
          false,
          props?.pageData || 1,
          true
        )
      );
    }
  };

  useEffect(() => {
    if (props.page === "order") {
      setValidationSchema(() =>
        Yup.object({
          zomatoSwiggyPrice: Yup.string().required(
            ValidationMessage.FIELD_REQUIRED
          ),
        })
      );
    } else {
      setValidationSchema(() => Yup.object({}));
    }
  }, [props.page]);

  const handleSubmit = (values) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: values.item,
      payment: values.payment,
      zomatoSwiggyPrice: values.zomatoSwiggyPrice,
    });

    dispatch(
      placeOrderService(
        requestBody,
        tokenData,
        "update",
        false,
        props?.pageData || 1,
        true
      )
    );
  };

  const deleteLineLevelProduct = (item) => {
    handleLineLevelOrderDeleteClick(
      item,
      tokenData,
      dispatch,
      props.newOrderData
    );
  };

  useEffect(() => {
    if (props.page === "vendors" && orderPaymentTableArray.length > 0) {
      const paymentArray = orderPaymentTableArray.filter(
        (item) => item.value !== "5" && item.value !== "6"
      );
      setOrderPaymentArray(paymentArray);
    } else {
      setOrderPaymentArray(orderPaymentTableArray);
    }
    // eslint-disable-next-line
  }, [props.page, orderPaymentTableArray]);

  return (
    <>
      <div className="py-3">
        {(!tableData || tableData.length <= 0) && loader === false && (
          <>
            <div className="input-search">
              <div className="no-record">
                {props.page === "import" ? (
                  <h3
                    data-bs-toggle="tooltip"
                    title="No records!"
                    align="center"
                  >
                    Imported data might be in wrong format or too long!
                  </h3>
                ) : (
                  <h3 data-bs-toggle="tooltip" title="No records!">
                    {t("no_records")}
                  </h3>
                )}
              </div>
            </div>
          </>
        )}
        {tableData && tableData.length > 0 && (
          <>
            <Table className="pt-3 custom-table" bordered responsive>
              <thead>
                <tr>
                  {props.checkBool &&
                    props.page !== "uploadImage" &&
                    props.page !== "product" && (
                      <th
                        style={{
                          width: "50px",
                          minWidth: "50px",
                          maxWidth: "50px",
                        }}
                      >
                        <Checkbox
                          name="checkHead"
                          className="table-checkbox"
                          checked={selectAllCheck}
                          onChange={handleHeaderCheckboxChange}
                        ></Checkbox>
                      </th>
                    )}

                  {props.checkBool && props.page === "product" && (
                    <th
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="checkHead"
                        className="table-checkbox"
                        checked={selectAllCheck}
                        onChange={handleHeaderCheckboxChange}
                      ></input>
                    </th>
                  )}

                  {props.checkBool && props.page === "uploadImage" && (
                    <th
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                        backgroundColor: "#234047",
                        color: "#fff",
                      }}
                    ></th>
                  )}
                  {heading.map((item, index) => {
                    const headingStyle =
                      props?.styleData && props.styleData[item.name]
                        ? props.styleData[item.name]?.type || {}
                        : {};

                    const commonStyle = {
                      textAlign: "center",
                      backgroundColor: "#234047",
                      color: "#fff",
                    };

                    const actionStyle =
                      item.name === t("columns.actions") ||
                      item.name === "Actions"
                        ? {
                            width: "200px",
                            maxWidth: "200px",
                            minWidth: "200px",
                          }
                        : {};

                    return (
                      <th
                        key={index}
                        style={{
                          ...commonStyle,
                          ...actionStyle,
                          ...headingStyle,
                        }}
                      >
                        {item.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((item, index) => (
                    <tr key={index}>
                      {props.checkBool && props.page !== "product" && (
                        <td
                          key={`checkbox_${index}`}
                          style={{
                            width: "50px",
                            minWidth: "50px",
                            maxWidth: "50px",
                          }}
                        >
                          <Checkbox
                            name={"check" + index}
                            className="table-checkbox"
                            checked={selectedItems.includes(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(index, event)
                            }
                          />
                        </td>
                      )}
                      {props.checkBool && props.page === "product" && (
                        <td key={`product_checkbox_${index}`}>
                          <input
                            type="checkbox"
                            name={"check" + index}
                            className="table-checkbox"
                            checked={selectedItems.includes(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(index, event)
                            }
                          />
                        </td>
                      )}
                      {Object.keys(item).map((key, subIndex) => {
                        if (
                          ["allow", "add", "view", "edit", "delete"].includes(
                            key
                          )
                        ) {
                          return (
                            <td
                              key={subIndex}
                              data-bs-toggle={
                                props.showTooltip ? "tooltip" : undefined
                              }
                              title={
                                props.showTooltip
                                  ? item[key]
                                    ? "active"
                                    : "inactive"
                                  : undefined
                              }
                            >
                              <Toggle
                                disabled={
                                  (props.disabled === true &&
                                    (props.page === "addRoles" ||
                                      props.page === "add-company")) ||
                                  (disableArray &&
                                    disableArray.includes &&
                                    disableArray.includes(item.name) &&
                                    key !== "allow" &&
                                    key !== "selectAll")
                                }
                                value={
                                  item[key] === "1"
                                    ? 1
                                    : item[key] === "0"
                                    ? 0
                                    : item[key]
                                }
                                onChange={(e) =>
                                  handleToggle(item.name, key, e.target.checked)
                                }
                              />
                            </td>
                          );
                        } else if (key === "banner" || key === "imageUrl") {
                          return (
                            <td key={subIndex}>
                              {imageErrors.includes(item.imageId) ? (
                                <img src={ErrorImage} alt="broken_image" />
                              ) : (
                                <img
                                  src={
                                    item.banner ||
                                    URL.createObjectURL(item.imageUrl)
                                  }
                                  alt="banner"
                                  onError={() => handleImageError(item.imageId)}
                                />
                              )}
                            </td>
                          );
                        } else if (
                          (key === "image" || key === "imagePath") &&
                          props.showImage
                        ) {
                          if (
                            item.image &&
                            item.image.length > 0 &&
                            item.image[0].imageUrl
                          ) {
                            return (
                              <td key={subIndex}>
                                {imageErrors.includes(
                                  item.image[index].imageId
                                ) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(
                                      item.image[0].imageUrl
                                    )}
                                    alt="data"
                                    onError={() =>
                                      handleImageError(
                                        item.image[index].imageId
                                      )
                                    }
                                  />
                                )}
                              </td>
                            );
                          } else if (item.imagePath) {
                            return (
                              <td key={subIndex}>
                                {imageErrors.includes(item.imageId) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={API_URL.TOKEN_URL + item.imagePath}
                                    alt="data"
                                    onError={() =>
                                      handleImageError(item.imageId)
                                    }
                                  />
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td key={subIndex}>
                                <p>No image to display</p>
                              </td>
                            );
                          }
                        } else if (
                          key === "Notes" &&
                          (props.page === "preferences" ||
                            props.page === "Allpreferences")
                        ) {
                          const notes = item[key]; // Get the Notes array
                          return (
                            <td>
                              {notes.length > 0 ? (
                                notes.map((note, index) => (
                                  <span key={index}>
                                    - {note}
                                    <br />
                                  </span>
                                ))
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          );
                        } else if (
                          [
                            "description",
                            "question",
                            "answer",
                            "Description",
                          ].includes(key)
                        ) {
                          if (showDescription) {
                            const isError =
                              item?.message && item.message !== "Done"
                                ? true
                                : false;
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                              >
                                {item[key] && typeof item[key] === "string"
                                  ? new DOMParser().parseFromString(
                                      item[key],
                                      "text/html"
                                    ).body.innerText
                                  : item[key]}
                              </td>
                            );
                          } else {
                            return null;
                          }
                        } else if (key === "id" || key === "parent_id") {
                          if (showId) {
                            const isError =
                              item?.message && item.message !== "Done"
                                ? true
                                : false;
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                              >
                                {item[key]}
                              </td>
                            );
                          }
                          return null;
                        } else if (props.showKey[key]) {
                          const displayValue = Array.isArray(item[key])
                            ? item[key].join(", ")
                            : item[key];
                          const isError =
                            item?.message && item.message !== "Done"
                              ? true
                              : false;
                          if (key === "sequence") {
                            const currentSelectedValue =
                              sequenceArray && sequenceArray.length > 0
                                ? sequenceArray.find(
                                    (option) => option.value === item.sequence
                                  )?.value || item.sequence
                                : item.sequence;
                            if (selectSequenceData !== currentSelectedValue) {
                              return (
                                <>
                                  {(props.page === "category" &&
                                    categoryFilter.isDelete === "1") ||
                                  (props.page === "subCategory" &&
                                    SubCategoryFilter.isDelete === "1") ? (
                                    <td
                                      key={item.sequence}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? displayValue
                                          : undefined
                                      }
                                    >
                                      <p>{currentSelectedValue}</p>
                                    </td>
                                  ) : (
                                    <td
                                      onClick={() =>
                                        setSelectSequenceData(
                                          currentSelectedValue
                                        )
                                      }
                                      key={item.sequence}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? displayValue
                                          : undefined
                                      }
                                    >
                                      <p>{currentSelectedValue}</p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <td key={item.sequence}>
                                  <div className="select-dropdown">
                                    <select
                                      onChange={(event) =>
                                        onChange(event, item)
                                      }
                                      value={currentSelectedValue}
                                    >
                                      <option disabled={true}>Select...</option>
                                      {sequenceArray.map(
                                        (item, sequenceIndex) => (
                                          <option
                                            key={sequenceIndex}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }
                          if (props.page === "product-pricing") {
                            if (
                              [
                                "skuCode",
                                "price",
                                "takeAwayPrice",
                                "zomatoPrice",
                                "swiggyPrice",
                                "otherPrice",
                              ].includes(key)
                            ) {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        key,
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) =>
                                      handleOnInput(
                                        e,
                                        key === "skuCode" ? 5 : 10
                                      )
                                    }
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (["cgst", "sgst"].includes(key)) {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        key,
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => {
                                      let value = e.target.value;
                                      const regex =
                                        /^(100(\.\d{0,3})?|(\d{0,2})(\.\d{0,3})?)$/;
                                      const matchedValue = value.match(regex);
                                      if (matchedValue) {
                                        e.target.value = matchedValue[0];
                                      } else {
                                        e.target.value = value.slice(0, -1);
                                      }
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            }
                          }
                          if (
                            props.page === "settlement" &&
                            key === "settlement_amount"
                          ) {
                            return (
                              <td>
                                <input
                                  className="pricing-input"
                                  placeholder="enter"
                                  value={item[key]}
                                  onChange={(e) => {
                                    const formattedValue = e.target.value
                                      .replace(/[^0-9.]/g, "")
                                      .replace(/(\..*?)\..*/g, "$1");
                                    handleSettlementChange(
                                      formattedValue,
                                      key,
                                      item
                                    );
                                  }}
                                  disabled={props.registerType}
                                />
                              </td>
                            );
                          }

                          if (key === "orderStatus") {
                            const selectedValue =
                              sequenceArray && sequenceArray.length > 0
                                ? sequenceArray.find(
                                    (option) => option?.value === index + 1
                                  )?.value
                                : index + 1; //Current Index
                            if (selectSequenceData !== selectedValue) {
                              return (
                                <>
                                  {(props.page === "order" &&
                                    !props.customerOrder &&
                                    !["3", "4"].includes(item.orderStatus)) ||
                                  (props.page === "vendors" &&
                                    !["1", "2"].includes(item.orderStatus)) ||
                                  (props.page === "purchase-requisition" &&
                                    !["0", "1", "2", "3", "4"].includes(
                                      item.orderStatus
                                    )) ? (
                                    <td
                                      onClick={() =>
                                        setSelectSequenceData(index + 1)
                                      }
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        orderStatusArray.find(
                                          (orderItem) =>
                                            orderItem.value === item.orderStatus
                                        )?.status
                                      }
                                      className={
                                        orderStatusArray.find(
                                          (orderItem) =>
                                            orderItem.value === item.orderStatus
                                        )?.className
                                      }
                                    >
                                      <p
                                        className={`${
                                          props.page === "purchase-requisition"
                                            ? "status-new"
                                            : "status-bg"
                                        }`}
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderStatusArray.find(
                                            (orderItem) =>
                                              orderItem.value ===
                                              item.orderStatus
                                          )?.status
                                        }
                                      </p>
                                    </td>
                                  ) : (
                                    <td
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? orderStatusArray.find(
                                              (orderItem) =>
                                                orderItem.value ===
                                                item.orderStatus
                                            )?.status
                                          : undefined
                                      }
                                      className={
                                        orderStatusArray.find(
                                          (orderItem) =>
                                            orderItem.value === item.orderStatus
                                        )?.className
                                      }
                                    >
                                      <p
                                        className={`${
                                          props.page === "purchase-requisition"
                                            ? "status-new"
                                            : "status-bg"
                                        }`}
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderStatusArray.find(
                                            (orderItem) =>
                                              orderItem.value ===
                                              item.orderStatus
                                          )?.status
                                        }
                                      </p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <td key={subIndex}>
                                  <div className="select-dropdown">
                                    <select
                                      value={item.orderStatus}
                                      onChange={(event) =>
                                        onChange(event, item)
                                      }
                                    >
                                      <option disabled={true}>Select...</option>
                                      {orderStatusArray.map(
                                        (item, statusIndex) => (
                                          <option
                                            key={statusIndex}
                                            value={item.value}
                                          >
                                            {item.status}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }

                          if (key === "payment") {
                            const selectedValue =
                              paymentSequenceArray &&
                              paymentSequenceArray.length > 0
                                ? paymentSequenceArray.find(
                                    (option) => option?.value === index + 1
                                  )?.value
                                : index + 1; //Current Index
                            if (selectPaymentSequenceData !== selectedValue) {
                              return (
                                <>
                                  {(props.page === "order" &&
                                    !props.customerOrder &&
                                    item.orderStatus !== "4" &&
                                    (item.orderStatus !== "3" ||
                                      item.payment === "0") &&
                                    item.type !== "2") ||
                                  (props.page === "vendors" &&
                                    item.orderStatus !== "2" &&
                                    (item.orderStatus !== "1" ||
                                      item.payment === "0")) ? (
                                    <td
                                      onClick={() =>
                                        setPaymentSelectSequenceData(index + 1)
                                      }
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? orderPaymentTableArray.find(
                                              (order) =>
                                                order.value === item.payment
                                            ).status
                                          : undefined
                                      }
                                      className={
                                        item.payment === "0"
                                          ? "Cancelled"
                                          : "Completed"
                                      }
                                    >
                                      <p
                                        className="status-bg"
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderPaymentTableArray.find(
                                            (order) =>
                                              order.value === item.payment
                                          ).status
                                        }
                                      </p>
                                    </td>
                                  ) : (
                                    <td
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? orderPaymentTableArray.find(
                                              (order) =>
                                                order.value === item.payment
                                            ).status
                                          : undefined
                                      }
                                      className={
                                        item.payment === "0"
                                          ? "Cancelled"
                                          : "Completed"
                                      }
                                    >
                                      <p
                                        className="status-bg"
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderPaymentTableArray.find(
                                            (orderItem) =>
                                              orderItem.value === item.payment
                                          ).status
                                        }
                                      </p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <td key={subIndex}>
                                  <div className="select-dropdown">
                                    <select
                                      value={item.payment}
                                      onChange={(event) =>
                                        changeOrderPaymentStatus(event, item)
                                      }
                                    >
                                      <option disabled={true}>Select...</option>
                                      {orderPaymentArray &&
                                      orderPaymentArray.length > 0
                                        ? orderPaymentArray.map(
                                            (paymentItem, statusIndex) => {
                                              return (
                                                <option
                                                  key={statusIndex}
                                                  value={paymentItem.value}
                                                >
                                                  {paymentItem.status}
                                                </option>
                                              );
                                            }
                                          )
                                        : orderPaymentTableArray.map(
                                            (item, statusIndex) => (
                                              <option
                                                key={statusIndex}
                                                value={item.value}
                                              >
                                                {item.status}
                                              </option>
                                            )
                                          )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }
                          if (
                            ["image", "banner_Image", "logo_image"].includes(
                              key
                            )
                          ) {
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                                data-bs-toggle={
                                  props.showTooltip && key !== "actions"
                                    ? "tooltip"
                                    : undefined
                                }
                                title={
                                  props.showTooltip && key !== "actions"
                                    ? displayValue
                                    : undefined
                                }
                              >
                                {notificationImageErrors.includes(item[key]) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={
                                      API_URL.TOKEN_URL +
                                      "uploads/images/" +
                                      item[key]
                                    }
                                    alt={item[key]}
                                    onError={() =>
                                      handleNotificationImageError(item[key])
                                    }
                                  />
                                )}
                              </td>
                            );
                          }

                          if (
                            key === "orderType" &&
                            props.page === "StockHistory"
                          ) {
                            if (["0", "null", null].includes(item.orderType)) {
                              return (
                                <td key={subIndex} className="Completed">
                                  {"ADD"}
                                </td>
                              );
                            } else if (item.orderType === "1") {
                              return (
                                <td key={subIndex} className="Cancelled">
                                  {"USED"}
                                </td>
                              );
                            } else if (item.orderType === "2") {
                              return (
                                <td key={subIndex} className="Waste">
                                  {"WASTE"}
                                </td>
                              );
                            }
                          }
                          const childStyle =
                            props?.styleData && props.styleData[key]
                              ? props.styleData[key].type || {}
                              : {};
                          return (
                            <td
                              key={subIndex}
                              data-bs-toggle={
                                props.showTooltip && key !== "actions"
                                  ? "tooltip"
                                  : undefined
                              }
                              title={
                                props.showTooltip && key !== "actions"
                                  ? displayValue
                                  : undefined
                              }
                              className={
                                isError
                                  ? "error-cell"
                                  : item.productStatus !== 0 &&
                                    item.productStatus !== "0"
                                  ? "product-status-color"
                                  : "width-data"
                              }
                              style={childStyle}
                            >
                              {displayValue}
                            </td>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {
                        //Select All Toggle
                        props.showSelectAll && (
                          <td>
                            <Toggle
                              disabled={
                                props.disabled === true &&
                                (props.page === "addRoles" ||
                                  props.page === "add-company")
                              }
                              value={
                                selectAllValue &&
                                selectAllValue.includes &&
                                selectAllValue.includes(item.name)
                                  ? 1
                                  : 0
                              }
                              onChange={(e) =>
                                handleToggle(
                                  item.name,
                                  "selectAll",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        )
                      }
                      {props.showToggle && (
                        <>
                          {props.showToggle.status && (
                            <td key={index}>
                              <Toggle
                                disabled={
                                  props.disabled === true ||
                                  (props.showActions.showUndo &&
                                    (item.is_delete === "1" ||
                                      item.IsDeleted === "1" ||
                                      item.isDelete === "1" ||
                                      item.isDeleted === "1" ||
                                      item.is_deleted === "1"))
                                }
                                value={
                                  ([1, "1"].includes(item.status) ? 1 : 0) ||
                                  ([1, "1"].includes(item.isActive) ? 1 : 0) ||
                                  ([1, "1"].includes(item.active) ? 1 : 0) ||
                                  ([1, "1"].includes(item.is_active) ? 1 : 0) ||
                                  false
                                }
                                onChange={(e) =>
                                  handleToggle(item, "status", e.target.checked)
                                }
                              />
                            </td>
                          )}
                        </>
                      )}
                      {props.showActions && (
                        <td
                          style={
                            props?.styleData?.Actionstoggle
                              ? props.styleData.Actionstoggle.type
                              : {
                                  width: "200px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                }
                          }
                          className="actions"
                        >
                          <div className="action-icon">
                            {props.showActions?.showView &&
                              ((props.showActions?.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                !props.showActions?.showUndo) && (
                                <FaEye
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "view" : undefined}
                                  onClick={() =>
                                    handleNavigate(
                                      props.showActions.navigate,
                                      item,
                                      false
                                    )
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions?.showEdit &&
                              ((props.page === "order" &&
                                !["3", "4"].includes(item.orderStatus) &&
                                item.type !== "2") ||
                                (props.page === "vendors" &&
                                  !["1", "2"].includes(item.orderStatus)) ||
                                (props.page === "purchase-requisition" &&
                                  !["1", "2", "3", "4"].includes(
                                    item.orderStatus
                                  )) ||
                                (props.page !== "order" &&
                                  props.page !== "vendors" &&
                                  props.page !== "purchase-requisition")) &&
                              ((props.showActions?.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                !props.showActions?.showUndo) && (
                                <MdEdit
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "edit" : undefined}
                                  onClick={() =>
                                    handleNavigate(
                                      props.showActions.navigate,
                                      item,
                                      true
                                    )
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions?.showDelete &&
                              ((props.showActions?.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                !props.showActions?.showUndo) && (
                                <MdDelete
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "delete" : undefined
                                  }
                                  className="icon-color"
                                  onClick={() => handleDeleteClick(item)}
                                />
                              )}
                            {props.showActions?.showReview &&
                              ((props.showActions?.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                !props.showActions?.showUndo) && (
                                <FaStarHalfAlt
                                  onClick={() =>
                                    navigate("/product-review-management")
                                  }
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Review" : undefined
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions?.showUndo &&
                              (item.is_delete === "1" ||
                                item.isDeleted === "1" ||
                                item.IsDeleted === "1" ||
                                item.isDelete === "1") && (
                                <GrUndo
                                  onClick={() => handleUndoClick(item)}
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "undo" : undefined}
                                  className="icon-color"
                                />
                              )}
                            {props.showActions?.showPlus && (
                              <FaPlus
                                data-bs-toggle={
                                  props.showTooltip ? "tooltip" : undefined
                                }
                                title={props.showTooltip ? "Plus" : undefined}
                                onClick={() =>
                                  handleNavigate(
                                    props.showActions.navigate,
                                    item,
                                    true
                                  )
                                }
                                className="icon-color"
                              />
                            )}
                            {props.showActions?.showPrint &&
                              props.page !== "spots" && (
                                <IoMdPrint
                                  className="icon-color"
                                  onClick={() => printOrderDetails(item)}
                                />
                              )}

                            {props.showActions?.showInvoice && (
                              <IoMdPrint
                                className="icon-color invoice-print-icon"
                                onClick={() => printInvoice(item)}
                              />
                            )}
                            {props.showActions?.showKOTPrint && (
                              <Button
                                data-bs-toggle="tooltip"
                                title="KOT"
                                onClick={() => printOrderDetails(item)}
                                className="KOT-btn"
                                name={<p className="kot-icon">KOT</p>}
                              ></Button>
                            )}
                            {/* changes of spots */}
                            {props.showActions?.showPrint &&
                              props.page === "spots" && (
                                <FaQrcode
                                  className="icon-color"
                                  onClick={() => props.QRCodeDetail(item)}
                                />
                              )}
                            {props.showActions?.showWP &&
                              props.page === "purchase-order" && (
                                <IoLogoWhatsapp
                                  className="icon-color"
                                  onClick={() => {
                                    generatePDFAndUpload(item);
                                  }}
                                />
                              )}
                            {props.showActions?.showApprove &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "0" ||
                                item.orderStatus === "2") && (
                                <IoIosCheckmarkCircle
                                  className="icon-color"
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Approve" : undefined
                                  }
                                />
                              )}
                            {props.showActions?.showReject &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "0" ||
                                item.orderStatus === "2") && (
                                <MdCancel
                                  className="icon-color"
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Reject" : undefined
                                  }
                                />
                              )}
                            {props.showActions?.showPO &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "1" ||
                                item.orderStatus === "2") && (
                                <Button
                                  data-bs-toggle="tooltip"
                                  title="Purchase Order"
                                  onClick={() => handlePO(item)}
                                  className="KOT-btn"
                                  name={<p className="kot-icon">PO</p>}
                                ></Button>
                              )}
                            {props.showActions?.showGRN &&
                              props.page === "purchase-order" && (
                                <Button
                                  data-bs-toggle="tooltip"
                                  title="Goods Receive Note"
                                  onClick={() =>
                                    handleGRN(
                                      props.showActions.navigateGRN,
                                      item
                                    )
                                  }
                                  className="KOT-btn"
                                  name={<p className="kot-icon">GRN</p>}
                                ></Button>
                              )}
                            {props.showActions?.update &&
                              props.page === "product-pricing" && (
                                <IoIosSave
                                  data-bs-toggle="tooltip"
                                  title="Save"
                                  className="icon-color"
                                  onClick={() => {
                                    handleProductPricingUpdate(item);
                                  }}
                                />
                              )}
                            {item?.orderStatus === "0" && (
                              <>
                                {props.showActions?.showRightIcon && (
                                  <FcCheckmark
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip ? "Accept" : undefined
                                    }
                                    className="icon-color right-icon-color"
                                    onClick={() =>
                                      handleOrderConfirmationClick(
                                        "Confirm",
                                        item,
                                        tokenData,
                                        dispatch,
                                        props?.pageData || 1
                                      )
                                    }
                                  />
                                )}
                                &nbsp;
                                {props.showActions?.showWrongIcon && (
                                  <RxCross2
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip ? "Reject" : undefined
                                    }
                                    className="icon-color wrong-icon-color"
                                    onClick={() =>
                                      handleOrderConfirmationClick(
                                        "Cancel",
                                        item,
                                        tokenData,
                                        dispatch,
                                        props?.pageData || 1
                                      )
                                    }
                                  />
                                )}
                              </>
                            )}
                            {props.showActions?.showRightProductIcon &&
                              item.productStatus !== 1 &&
                              item.productStatus !== "1" && (
                                <>
                                  <FcCheckmark
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip
                                        ? "Deliver Product"
                                        : undefined
                                    }
                                    className="icon-color right-icon-color"
                                    onClick={() =>
                                      handleLineLevelOrderConfirmationClick(
                                        item,
                                        tokenData,
                                        dispatch,
                                        props?.pageData || 1
                                      )
                                    }
                                  />
                                  {tableData &&
                                  tableData.length > 1 &&
                                  props.newOrderData.orderStatus !== "3" &&
                                  props.newOrderData.orderStatus !== "4" ? (
                                    <MdDeleteForever
                                      onClick={() =>
                                        deleteLineLevelProduct(item)
                                      }
                                      data-bs-toggle={
                                        props.showTooltip
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip
                                          ? "Remove Product"
                                          : undefined
                                      }
                                      className="wrong-icon-color"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            {props.showActions?.showRightProductIcon &&
                              (item.productStatus === 1 ||
                                item.productStatus === "1") && (
                                <span>Delivered</span>
                              )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        )}
        {props.page === "order" && zomatoSwiggyModal && (
          <div className="modal-overlay">
            <div className="modal-content-order modal-">
              <div className="modal-header">
                <h4>Sales Details</h4>
                <button
                  className="modal-close"
                  onClick={() => setZomatoSwiggyModal(!zomatoSwiggyModal)}
                >
                  <MdOutlineCancel />
                </button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {({ values }) => (
                    <Form>
                      <div className="add-roles-form row">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="name-type">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  Enter Zomato/Swiggy Price
                                  <span className="required-label"></span>
                                </h4>
                              </div>
                              <Input
                                type="text"
                                name="zomatoSwiggyPrice"
                                placeholder="Enter Price"
                                className="add-roles"
                                maxLength={10}
                                Regex={/[^0-9\s]/g}
                                value={values.zomatoSwiggyPrice}
                                hidelength="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <div className="paragraph-editor">
                            <div className="btn-submit order-submit-btn">
                              <Button
                                type="submit"
                                name="Place Order"
                                className="btn login-btn  place-order-btn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
import {
  CLEAR_ROLE_DATA,
  CLEAR_TOTAL_ROLE_DATA,
  GET_ROLE_DATA,
  GET_TOTAL_ROLE_DATA,
} from "../../ReduxKeys";

/**
 * Set role list data into reducer
 * @param {*} role
 * @returns {*}
 */
export const getRoleData = (role) => ({
  type: GET_ROLE_DATA,
  payload: role,
});

/**
 * Clear role list data
 * @returns {*}
 */
export const clearRoleData = () => ({
  type: CLEAR_ROLE_DATA,
});

/**
 * Set total role list data into reducer (No  pagination)
 * @param {*} role
 * @returns {*}
 */
export const getTotalRoleData = (role) => ({
  type: GET_TOTAL_ROLE_DATA,
  payload: role,
});

/**
 * Clear total role list data (No  pagination)
 * @returns {*}
 */
export const clearTotalRoleData = () => ({
  type: CLEAR_TOTAL_ROLE_DATA,
});
import { CLEAR_SALES_TABLE_PAGE, GET_SALES_TABLE_PAGE } from "../../ReduxKeys";

/**
 * Set sales table data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getSalesTablePageData = (page) => ({
  type: GET_SALES_TABLE_PAGE,
  payload: page,
});

/**
 * Clear Sales table data
 * @returns {*}
 */
export const clearSalesTablePageData = () => ({
  type: CLEAR_SALES_TABLE_PAGE,
});
import { CLEAR_STOCK_FILTER, GET_STOCK_FILTER } from "../../ReduxKeys";

/**
 * Setting stock filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getStockFilterData = ({ ingredientId }) => ({
  type: GET_STOCK_FILTER,
  payload: { ingredientId },
});

/**
 * Clearing Stock filtered data
 * @returns {*}
 */
export const clearStockFilterData = () => ({
  type: CLEAR_STOCK_FILTER,
});
export const TagToggleRequest = ({
  toggleData,
  userId,
  isActive,
  isDelete,
}) => {
  const requestBody = {
    p_id: toggleData.tagId,
    p_title: toggleData.title,
    p_isActive: isActive
      ? toggleData.isActive === "1"
        ? "0"
        : "1"
      : toggleData.isActive,
    p_IsDeleted: isDelete ? "0" : toggleData.isDeleted,
    p_createdBy: toggleData.createdBy,
    p_modifiedBy: userId,
  };
  return requestBody;
};
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getExpenseGraphRequest,
  getExpenseGraphResponse,
} from "./ExpenseGraphModal";
import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  UnauthorizeAccessMessage,
  ValidationMessage,
} from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import { toast } from "react-toastify";
import { getExpenseGraphData } from "../../../redux/Actions/ExpenseAction/ExpenseGraphAction";

let errorShow = false;

export default function ExpenseGraphService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Get branch list service
   */
  const getExpenseGraphService = (
    ExpenseGraphRequest: getExpenseGraphRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getExpenseGraphResponse> => {
    return new Promise<getExpenseGraphResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        if (
            ExpenseGraphRequest.p_time_zone !== undefined &&
            ExpenseGraphRequest.p_time_zone !== null
          ) {
            queryParams += `p_time_zone=${ExpenseGraphRequest.p_time_zone}&`;
          }
        if (
          ExpenseGraphRequest.p_start_date !== undefined &&
          ExpenseGraphRequest.p_start_date !== null
        ) {
          queryParams += `p_start_date=${ExpenseGraphRequest.p_start_date}&`;
        }
        if (
          ExpenseGraphRequest.p_end_date !== undefined &&
          ExpenseGraphRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${ExpenseGraphRequest.p_end_date}&`;
        }
        if (
          ExpenseGraphRequest.p_category_id !== undefined &&
          ExpenseGraphRequest.p_category_id !== null
        ) {
          queryParams += `p_category_id=${ExpenseGraphRequest.p_category_id}&`;
        }
      
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.ANALYTICS +
            API_URL.EXPENSEANALYTICS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getExpenseGraphData(response));
          } else {
            if (response.data && response.data.length > 0) {
              // const pendingCount = response.data.filter(
              //   (item: any) => item.status === "0"
              // ).length;
              dispatch(getExpenseGraphData(response));
            } else {
              dispatch(getExpenseGraphData(response));
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (response?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };
  return { getExpenseGraphService };
}

import { CLEAR_ROLE_PAGE, GET_ROLE_PAGE } from "../../ReduxKeys";

/**
 * Get role page data into reducer
 * @param {*} page
 * @returns {*}
 */
export const getRolePageData = (page) => ({
  type: GET_ROLE_PAGE,
  payload: page,
});

/**
 * Clear role page data
 * @returns {*}
 */
export const clearRolePageData = () => ({
  type: CLEAR_ROLE_PAGE,
});
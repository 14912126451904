import React from 'react'
import './ProductReview.scss'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'
import { Breadcrumb } from 'react-bootstrap'
import Footer from '../../../components/Footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { FaHeart } from "react-icons/fa6";
import { IoMdHeartDislike } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";

export default function ProductReview() {
    const navigate = useNavigate()
    return (
        <>
            <div >
                {/* <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='body-content'>
                    <Header /> */}
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Product Review Management</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => navigate('/product')}>Product</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Product Review Management</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className='product-review-box'>
                            <div className='product-left-detail'>
                                <h3>Lorem</h3>
                                <div className='star-rating'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <CiStar />
                                    <CiStar />
                                </div>
                                <div className='like-dislike'>
                                    <div className='like'><FaHeart />0</div>
                                    <div className='dislike'><IoMdHeartDislike />0</div>
                                </div>
                                <div className='review-name'>Review</div>
                            </div>
                            <div className='product-right-icon'>
                                <Link to="/"><MdDeleteOutline /></Link>
                            </div>
                        </div>
                        <div className='product-review-box'>
                            <div className='product-left-detail'>
                                <h3>Lorem</h3>
                                <div className='star-rating'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <CiStar />
                                    <CiStar />
                                </div>
                                <div className='like-dislike'>
                                    <div className='like'><FaHeart />0</div>
                                    <div className='dislike'><IoMdHeartDislike />0</div>
                                </div>
                                <div className='review-name'>Review</div>
                            </div>
                            <div className='product-right-icon'>
                                <Link to="/"><MdDeleteOutline /></Link>
                            </div>
                        </div>
                        <div className='product-review-box'>
                            <div className='product-left-detail'>
                                <h3>Lorem</h3>
                                <div className='star-rating'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <CiStar />
                                    <CiStar />
                                </div>
                                <div className='like-dislike'>
                                    <div className='like'><FaHeart />0</div>
                                    <div className='dislike'><IoMdHeartDislike />0</div>
                                </div>
                                <div className='review-name'>Review</div>
                            </div>
                            <div className='product-right-icon'>
                                <Link to="/"><MdDeleteOutline /></Link>
                            </div>
                        </div>
                    </div>
                    {/* <Footer />
                </div> */}
            </div>
        </>
    )
}

import {
  CLEAR_CATEGORY_DATA,
  CLEAR_TOTAL_CATEGORY,
  GET_CATEGORY_DATA,
  GET_TOTAL_CATEGORY,
} from "../../ReduxKeys";

/**
 * Set cltegory list data into reducer
 * @param {*} category
 * @returns {*}
 */
export const getCategoryData = (category) => ({
  type: GET_CATEGORY_DATA,
  payload: category,
});

/**
 * Clear category list data
 * @returns {*}
 */
export const clearCategoryData = () => ({
  type: CLEAR_CATEGORY_DATA,
});

/**
 * Set total category list data into reducer (No pagination)
 * @param {*} category
 * @returns {*}
 */
export const getTotalCategoryData = (category) => ({
  type: GET_TOTAL_CATEGORY,
  payload: category,
});

/**
 * Clear total category list data (No pagination)
 * @returns {*}
 */
export const clearTotalCategory = () => ({
  type: CLEAR_TOTAL_CATEGORY,
});
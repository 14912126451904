import React, { useEffect, useState } from "react";
import { API_URL } from "../../Utils/Utils";
import ErrorImage from "./../../assets/images/placeholder.png";

/**
 * Component to display image
 */
export default function ShowImage({
  value,
  showUrlData,
  errorImages,
  showImage,
}) {
  const [errorImageData, setErrorImageData] = useState([]);
  useEffect(() => {
    if (errorImages && errorImages.length > 0) {
      setErrorImageData(errorImages);
    } else {
      setErrorImageData([]);
    }
  }, [errorImages]);

  /**
   * Function for showing static images instead of broken images
   * @param {*} index
   */
  const handleError = (index) => {
    if (showImage === true) {
      setErrorImageData((prevErrors) => {
        const newErrors = [...prevErrors, index];
        return newErrors;
      });
    }
  };

  return (
    <div className="d-flex">
      {value &&
        value.length > 0 &&
        value.map((imageData, index) => (
          <div className="image-main" key={index}>
            <div className="all-image-perview">
              {!showUrlData && (
                <>
                  {imageData.url && (
                    <>
                      <img
                        key={index}
                        src={
                          errorImageData &&
                          errorImageData.length > 0 &&
                          errorImageData.includes(imageData.imageId)
                            ? ErrorImage
                            : API_URL.TOKEN_URL + imageData.url
                        }
                        alt={`Preview ${index}`}
                        className="image-preview"
                        onError={() => handleError(imageData.imageId)}
                      />
                    </>
                  )}
                  {imageData.imagePath && (
                    <>
                      <img
                        key={index}
                        src={
                          errorImageData &&
                          errorImageData.length > 0 &&
                          errorImageData.includes(imageData.imageId)
                            ? ErrorImage
                            : API_URL.TOKEN_URL + imageData.imagePath
                        }
                        alt={`Preview ${index}`}
                        className="image-preview"
                      />
                    </>
                  )}
                </>
              )}
              {showUrlData && showUrlData === true && (
                <>
                  <img
                    key={index}
                    src={
                      errorImageData &&
                      errorImageData.length > 0 &&
                      errorImageData.includes(imageData.imageId)
                        ? ErrorImage
                        : URL.createObjectURL(imageData.imageUrl)
                    }
                    alt={`Preview ${index}`}
                    className="image-preview"
                  />
                </>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

// import React from "react";
// import * as XLSX from "xlsx";
// import Button from "../../components/Button/Button";
// import { SiMicrosoftexcel } from "react-icons/si";

// //Serive to export files to excel
// const ExcelData = ({ excelData, fileName, keys }) => {
//   const exportToExcel = () => {
//     const wb = XLSX.utils.book_new();
//     // Log the formatted data for inspection
//     let ws;
//     if (keys) {
//       ws = XLSX.utils.json_to_sheet(flattenKeys(excelData, keys), {
//         header: 0,
//       });
//     } else {
//       ws = XLSX.utils.json_to_sheet(excelData, { header: 0 });
//     }
//     XLSX.utils.book_append_sheet(wb, ws, "ResponseData");
//     XLSX.writeFile(wb, fileName + ".xlsx");
//   };
//   const flattenKeys = (data, keysToFlatten) => {
//     if (keysToFlatten) {
//       return data.map((item) => {
//         keysToFlatten.forEach((key) => {
//           // Check if the item has the specified key and if it is an array
//           if (Array.isArray(item[key])) {
//             item[key] = item[key]
//               .map((permission) => {
//                 return JSON.stringify(permission);
//               })
//               .join("\t");
//           } else {
//             item[key] = [item[key]]
//               .map((permission) => {
//                 return JSON.stringify(permission);
//               })
//               .join("\t");
//           }
//         });
//         return item;
//       });
//     }
//   };
//   return (
//     <>
//       <Button
//         onClick={(e) => exportToExcel(fileName)}
//         data-bs-toggle="tooltip"
//         title="Export"
//         className="btn btn-fillter-main"
//         name={
//           <div className="filter">
//             <SiMicrosoftexcel className="filter-icon" />
//           </div>
//         }
//       ></Button>
//     </>
//   );
// };

import React from "react";

const ExcelData = () => {
    return <></>
}

export default ExcelData;

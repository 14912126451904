import { CLEAR_SALES_GRAPH_DATA, GET_SALES_GRAPH_DATA } from "../../ReduxKeys";

/**
 * Setting branch list data into reducer
 * @param {*} sales
 * @returns {*}
 */
export const getSalesGraphData = (sales) => ({
  type: GET_SALES_GRAPH_DATA,
  payload: sales,
});

/**
 * clearing branch list data
 * @returns {*}
 */
export const clearSalesGraphData = () => ({
  type: CLEAR_SALES_GRAPH_DATA,
});
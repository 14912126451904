import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import "../../../pages/NewOrderPage/NewOrderPage.scss";
import ProductContainer from "../ProductContainer/ProductContainer";
import {
  orderPaymentTableArray,
  orderStatusTableArray,
} from "../../../Utils/Utils";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function OrderDetailContainer({
  selectedOrder,
  onChange,
  changeOrderPaymentStatus,
  colClass,
  page
}) {

  const {t} = useTranslation();

  const paymentOptions = useMemo(
    () =>
      orderPaymentTableArray.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [t]
  );

  const statusOptions = useMemo(
    () =>
      orderStatusTableArray["order"].map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [t]
  );

  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [orderStatusClass, setOrderStatusClass] = useState("");

  useEffect(() => {
    const orderStatusData = orderStatusTableArray["order"].find(
      (item) => item.value === selectedOrder?.orderStatus
    );
    setOrderStatusClass(orderStatusData ? orderStatusData?.className : "");
    // eslint-disable-next-line
  }, [selectedOrder, orderStatusTableArray]);

  useEffect(() => {
    // Find the matching payment option object
    const paymentOption = paymentOptions.find(
      (option) => option.value === selectedOrder?.payment
    );
    setSelectedPaymentOption(paymentOption || "");

    // Find the matching status option object
    const statusOption = statusOptions.find(
      (option) => option.value === selectedOrder?.status
    );
    setSelectedStatusOption(statusOption || "");
  }, [selectedOrder, paymentOptions, statusOptions]);

  return (
    <div className={colClass ? colClass : "col-md-6"}>
      <div className="order-detail-new">
        <div className={`dine-status ${orderStatusClass}`}>
          <span className="type-color">{t("columns.type")}: {t(selectedOrder?.tableType)}</span>
          <span className="type-color">
            {selectedOrder?.country_symbol || "₹"}
            {Number(selectedOrder?.order_amount).toFixed(2) || "0.00"}
          </span>
        </div>
        <div className="order-more-details">
          <div className="order-user-detail">
            {selectedOrder?.type === "0" ? (
              <span>{t("table_no")} : {selectedOrder?.spot_name}</span>
            ) : (
              <></>
            )}
            <span>{selectedOrder?.order_number}</span>
          </div>
          <div className="order-user-detail">
            <span>{t("columns.name")}: {selectedOrder?.customerName}</span>
            <span>{t("columns.mobile")}: {!["8155006366", 8155006366, "null", null].includes(selectedOrder?.phoneNumber) ? selectedOrder?.phoneNumber : "N/A"}</span>
          </div>
          <div className="order-user-detail">
            <span>{t("lable_Date")}: {format(selectedOrder?.createdAt, "dd/MM/yyyy")}</span>
          </div>

          {selectedOrder?.instructions &&
          !["", "null", "NULL"].includes(selectedOrder.instructions) ? (
            <div className="order-user-detail">
              <span>{t("lable_note")}: {selectedOrder?.instructions}</span>
            </div>
          ) : (
            <></>
          )}

          {selectedOrder?.address_id &&
          !["", "null", "NULL"].includes(selectedOrder.address_id) ? (
            <div className="order-user-detail">
              <span>
              {t("columns.address")}: {selectedOrder?.address_line1},{" "}
                {selectedOrder?.address_line2}, {selectedOrder?.address_city},{" "}
                {selectedOrder?.address_postal_code},{" "}
                {selectedOrder?.country_name}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="order-user-detail mt-2">
            <div className="new-payment-dropdown">
              <Select
                options={paymentOptions}
                value={selectedPaymentOption}
                onChange={(option) =>
                  changeOrderPaymentStatus(option?.value, selectedOrder)
                }
                placeholder="Select Payment Method"
                className="react-select-container"
                classNamePrefix="react-select"
                isDisabled={
                  !(
                    selectedOrder.orderStatus !== "4" &&
                    (selectedOrder.orderStatus !== "3" ||
                      selectedOrder.payment === "0")
                  )
                }
              />
            </div>
            <div className="new-payment-dropdown">
              <Select
                options={statusOptions}
                value={selectedStatusOption}
                onChange={(option) => onChange(option?.value, selectedOrder)}
                placeholder="Select Order Status"
                className="react-select-container"
                classNamePrefix="react-select"
                isDisabled={
                  selectedOrder.orderStatus === "3" ||
                  selectedOrder.orderStatus === "4"
                }
              />
            </div>
          </div>
        </div>
        {selectedOrder?.productData?.length ? (
          <>
            <div className="order-list-new">
              <span>{t("items")}</span>
              <div className="order-list-title-new">
                <span>{t("Qty")}</span>
                <span>{t("columns.price")}</span>
                <span>{t("addOrders.discount")}</span>
                <span>{t("columns.tax")}</span>
                <span>{t("columns.total")}</span>
                <span>{t("Del")}</span>
              </div>
            </div>
            <div className={`scroll-products ${page==="new-order" ? "product-order-999": ""}`}>
              {selectedOrder?.productData.map((product, index) => (
                <ProductContainer
                  key={index}
                  productData={product}
                  orderData={selectedOrder}
                />
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="product-bill-details">
          <div className="product-bill-details-new">
            <span>{t("columns.quantities")}</span>
            <span>{selectedOrder?.totalItem || 1}</span>
          </div>
          <div className="product-bill-details-new">
            <span>{t("columns.subtotal")}</span>
            <span>
              {selectedOrder?.country_symbol || "₹"}
              {Number(selectedOrder?.orderSubTotal).toFixed(2) || "0.00"}
            </span>
          </div>
          {selectedOrder?.discount_id ? (
            <div className="product-bill-details-new">
              <span>{t("addOrders.Discount")}</span>
              <span>
                {selectedOrder?.country_symbol || "₹"}
                {Number(selectedOrder?.total_discount_amount).toFixed(2) ||
                  "0.00"}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="product-bill-details-new">
            <span>{t("columns.tax")}</span>
            <span>
              {selectedOrder?.country_symbol || "₹"}
              {Number(selectedOrder?.total_tax_amount).toFixed(2) || "0.00"}
            </span>
          </div>
          {selectedOrder?.type === "2" ? (
            <div className="product-bill-details-new">
              <span>{t("placeholder_service_charge")}</span>
              <span>
                {selectedOrder?.country_symbol || "₹"}
                {Number(selectedOrder?.service_charge).toFixed(2) || "0.00"}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="product-bill-details-new">
            <span className="product-bill-Total-Payment">{t("columns.total")}</span>
            <span className="product-bill-Total-Payment">
              {selectedOrder?.country_symbol || "₹"}
              {Number(selectedOrder?.order_amount).toFixed(2) || "0.00"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
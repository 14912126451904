import {
  CLEAR_PREFERENCE_DATA,
  CLEAR_TOTAL_PREFERENCE,
  GET_PREFERENCE_DATA,
  GET_TOTAL_PREFERENCE,
} from "../../ReduxKeys";

const preferencesInitialState = {
  items: [],
};

/**
 * Store preferences list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PreferencesReducer = (state = preferencesInitialState, action) => {
  switch (action.type) {
    case GET_PREFERENCE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_PREFERENCE_DATA:
      return preferencesInitialState;
    default:
      return state;
  }
};

const totalPreferencesInitialState = {
  items: [],
};

/**
 * Store total PREFERENCESlist data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalPreferencesReducer = (
  state = totalPreferencesInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_PREFERENCE:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_PREFERENCE:
      return totalPreferencesInitialState;
    default:
      return state;
  }
};
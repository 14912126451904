export const OrderPrintHtml = ({ orderData }) => {
  const orderDate = new Date(orderData?.createdAt).toLocaleDateString();
  const requestHtml = `
    <html>
    <head>
      <title>Order Details</title>
    </head>
    <body style="font-family: Arial, sans-serif; margin: 0 auto; padding: 0; width: 80mm; text-align: center;">
      <h1 style="text-align: center; font-size: 16px; margin-top: 10px;">Order Details</h1>
      <div style="margin: 10px; text-align: left;">
        <p style="margin: 5px 0; font-size: 12px;">Order No. - <strong>${
          orderData?.orderNumber
        }</strong></p>
        <p style="margin: 5px 0; font-size: 12px; border-top: 1px solid black;">
        <br>
          Dine In: ${orderData?.type === "0" ? "Yes" : "No"}<br>
          Table Number: ${
            orderData?.type === "0" ? orderData.spot_name
            : "Parcel"
          }<br>
          Date: ${orderDate ? orderDate : ""}<br>
         
        </p>
        ${
          orderData?.instructions
            ? `<p style="font-size: 12px; margin-bottom:5px">General Instruction</p>
                <p style="font-size: 10px;">${orderData.instructions}</p> `
            : ""
        }

           <tbody>
        ${
          orderData?.productData && orderData.productData.length > 0
            ? orderData.productData
                .map(
                  (product) => `
            <tr>
             <td style=" font-size: 12px;">
${product.quantity} x ${product.productName} - ${product.food_type_two}<br>
${
  product?.product_instruction
    ? `<span class="instructions" style="font-style: italic; font-size: 12px;">Instruction: ${product.product_instruction}</span>`
    : ""
}
${
  product?.attribute_data &&
  product.attribute_data !== "" &&
  product.attribute_data !== "null" &&
  product.attribute_data !== "NULL"
    ? `<div class="attribute-data" style="font-style: italic; font-size: 12px; color: gray;">${JSON.parse(
        product.attribute_data
      )
        .map(
          (attr) => `
      ${attr.cat_name}: ${attr.name} 
    `
        )
        .join("<br>")}</div>`
    : ""
}
</td>

            </tr>
          `
                )
                .join("<br>")
            : "<tr><td>No products available.</td></tr>"
        }
      </tbody>
      </div>
      <div style="margin-top: 10px; text-align: center; font-size: 12px;">
        <p>Thanks for visiting!</p>
      </div>
      <div style="display: flex; padding: 0 10px; justify-content: flex-end;">
        <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin-left: auto;">
          <!-- Your icon or content here -->
        </div>
      </div>
    </body>
    </html>
  `;

  return requestHtml;
}
export const  modifyString = (str) => {
    if (str.endsWith('ies')) {
      // Remove 'ies' and append 'y'
      return str.slice(0, -3) + 'y';
    } else if (str.endsWith('s')) {
      // Remove the last 's'
      return str.slice(0, -1);
    }
    // Return the string unchanged if no match
    return str;
  }
import { CLEAR_GRN_PAGE, GET_GRN_PAGE } from "../../ReduxKeys";

const GRNPageInitialState = {
  items: 1,
};

/**
 * Store Goods receive page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const GRNPageReducer = (state = GRNPageInitialState, action) => {
  switch (action.type) {
    case GET_GRN_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_GRN_PAGE:
      return GRNPageInitialState;
    default:
      return state;
  }
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Stepper styling */
.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper .step {
  flex: 1 1;
  text-align: center;
  padding: 10px 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-bottom: 3px solid #E6E6E6;
  transition: all 0.3s ease;
}
.stepper .step.active {
  border-bottom: 3px solid #FF4F2B;
  font-weight: bold;
  color: #FF4F2B;
}
.stepper .step.completed {
  border-bottom: 3px solid #22bb33;
  color: #22bb33;
}

.requirement-container {
  display: flex;
  flex-direction: column;
  color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentDetails/PaymentDetails.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEE,oBAAA;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,gCAAA;EACA,yBAAA;AADN;AAGM;EACE,gCAAA;EACA,iBAAA;EACA,cCpBO;ADmBf;AAIM;EACE,gCAAA;EACA,cCVG;ADQX;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,cCzBU;ADqBZ","sourcesContent":["@import \"../../variables\";\n  \n  /* Stepper styling */\n  .stepper {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n\n    .step {\n      flex: 1;\n      text-align: center;\n      padding: 10px 5px;\n      font-size: 14px;\n      cursor: pointer;\n      border: none;\n      border-bottom: 3px solid $gray;\n      transition: all 0.3s ease;\n\n      &.active {\n        border-bottom: 3px solid $primary-color;\n        font-weight: bold;\n        color: $primary-color;\n      }\n\n      &.completed {\n        border-bottom: 3px solid $completed;\n        color: $completed;\n      }\n    }\n  }\n\n.requirement-container{\n  display: flex;\n  flex-direction: column;\n  color: $red-color;\n  // .field{\n  //   margin-right: 16px;\n  // }\n}","$main-color:#1D3B42;\n$primary-color:#FF4F2B;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$successColor:#28a745;\n$dangerColor:#dc3545;\n$border-color:#DEE2E6;\n$red-color: #ff0000;\n$star-rating: #e1c100;\n$disabled-bg:#e9ecef;\n$gray:#E6E6E6;\n$dark-gray:\"#808080\";\n$lightLIGHTGRAY:#aaa;\n$orange-color:#008dff;\n$completed:#22bb33;\n$Confirmed:#fcaa11;\n$In-progress:#5bc0de;\n$Pending:#deb887;\n$Cancelled:#bb2124;\n$bespeakk-color:#e25233;\n$White-Smoke:\"#f4f4f4\";\n$Gainsboro-Border:\"#E6E6E6\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

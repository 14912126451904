import { SET_NOTIFICATION_SOUND } from "../../ReduxKeys";

/**
 * Set notification list data into reducer
 * @param {*} Notification
 * @returns {*}
 */
export const setNotificationSound = (Notification) => ({
  type: SET_NOTIFICATION_SOUND,
  payload: Notification,
});
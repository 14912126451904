import {
  CLEAR_EXPENSE_DATA,
  CLEAR_TOTAL_EXPENSE,
  GET_EXPENSE_DATA,
  GET_TOTAL_EXPENSE,
} from "../../ReduxKeys";

const expenseInitialState = {
  items: [],
};

/**
 * Store expense data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const ExpenseReducer = (state = expenseInitialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_EXPENSE_DATA:
      return expenseInitialState;
    default:
      return state;
  }
};

const totalExpenseState = {
  items: [],
};

/**
 * Store total expense list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalExpenseReducer = (state = totalExpenseState, action) => {
  switch (action.type) {
    case GET_TOTAL_EXPENSE:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_TOTAL_EXPENSE:
      return totalExpenseState;
    default:
      return state;
  }
};
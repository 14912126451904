import React, { useEffect, useRef, useState } from "react";
import "./CRM.scss";
import { Accordion, Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerService from "../CustomerService/CustomerService";
import {
  clearCustomerOrderPageData,
  getCustomerOrderPageData,
} from "../../../redux/Actions/CustomerAction/CustomerOrderPageAction";
import UtilsData, {
  API_URL,
  getRouteNameFromSidebar,
  imageUrlToFile,
  orderTypeArray,
} from "../../../Utils/Utils";
import {
  clearCustomerOrderData,
  clearTotalCustomerOrder,
} from "../../../redux/Actions/CustomerAction/CustomerOrderAction";
import { format } from "date-fns";
import {
  FaAngleDown,
  FaAngleUp,
  FaFilter,
  FaPlus,
  FaMinus,
  FaEye,
} from "react-icons/fa";
import { IoLogoWhatsapp, IoIosArrowForward } from "react-icons/io";
import CustomTable from "../../../components/Table/Table";
import { HiMiniInformationCircle } from "react-icons/hi2";
import generateInvoiceHtml from "../../../components/generateInvoiceHtml/generateInvoiceHtml";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomPagination from "../../../components/Pagination/Pagination";
import {
  clearPreferencesPageData,
  getPreferencesPageData,
} from "../../../redux/Actions/Prefrences/PrefrencesPageAction";
import {
  clearPreferencesData,
  clearTotalPreferencesData,
} from "../../../redux/Actions/Prefrences/PrefrencesAction";
import { GrMoreVertical } from "react-icons/gr";
import { clearPreferencesFilterData } from "../../../redux/Actions/Prefrences/PrefrencesFilterAction";
import Button from "../../../components/Button/Button";
import Filter from "../../../components/Filter/Filter";
import {
  clearCustomerNotePageData,
  getCustomerNotePageData,
} from "../../../redux/Actions/CustomerNoteAction/CustomerNotePageAction";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  clearCustomerNoteData,
  clearTotalCustomerNoteData,
} from "../../../redux/Actions/CustomerNoteAction/CustomerNoteAction";
import { useTranslation } from "react-i18next";
import Input from "../../../components/input/Input";
import DatePicker from "react-datepicker";
import ErrorComp from "../../../components/Error/ErrorComp";
import DiscountService from "../../Discount/DiscountService/DiscountService";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import UserService from "../../AdminUsers/User-service/UserService";
import { Bar } from "react-chartjs-2";
import { MdDelete, MdEdit } from "react-icons/md";
import { clearCustomerAnalyticsData } from "../../../redux/Actions/CustomerAnalyticsAction/CustomerAnalyticsAction";
import { clearCustomerAnalyticsFilterData } from "../../../redux/Actions/CustomerAnalyticsAction/CustomerAnalyticsFilterAction";
import FileInput from "../../../components/input/FileInput";
import {
  clearCustomerOrderFilterData,
  getCustomerOrderFilterData,
} from "../../../redux/Actions/CustomerAction/CustomerOrderFilterAction";
import {
  formatDateToDDMMYYYY,
  formatDateToYYYYMMDD,
} from "../../../services/Regular-services/DateService";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import OrderServices from "../../Order/OrderService/OrderService";
import { clearAllPreferencesData } from "../../../redux/Actions/Prefrences/AllPreferencesAction";
import { clearAllPreferencesFilterData } from "../../../redux/Actions/Prefrences/AllPreferencesFilterAction";
import {
  clearAllPreferencesPageData,
  getAllPreferencesPageData,
} from "../../../redux/Actions/Prefrences/AllPreferencesPageAction";

function CRM() {
  const { getUserById } = UserService();
  const { getAllDiscountService } = DiscountService();
  const {
    addCustomerNoteService,
    getAllCustomerNotesService,
    getAllPreferencesService,
    getCustomerAnalyticsService,
    getCustomersOrderService,
    UpdateCustomerDetailService,
  } = CustomerService();
  const { uploadOrderPDFService } = OrderServices();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const localisation = useSelector((state) => state.localization?.items);
  const authuserData = useSelector((state) => state.auth?.user);
  const [activeTab, setActiveTab] = useState(t("Basic Details"));
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [customerActionData, setcustomerActionData] = useState([]);
  const { handledeletClick } = AlertMessage();
  const customerOrders = useSelector((state) => state.customerOrders);
  const customerOrdersFilter = useSelector(
    (state) => state.customerOrdersFilter
  );
  const preferences = useSelector((state) => state.preferences);
  const customerNote = useSelector((state) => state.customerNote);
  const customerAnalytics = useSelector((state) => state.customerAnalytics);
  const customerAnalyticsFilter = useSelector(
    (state) => state.customerAnalyticsFilter
  );
  const customerNotePage = useSelector((state) => state.customerNotePage);
  const [tableData, setTableData] = useState([]);
  const [preferencesData, setPreferencesData] = useState([]);
  const [allPreferencesData, setAllPreferencesData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);
  const customerOrdersPage = useSelector(
    (state) => state.customerOrdersPage?.items
  );
  const preferencesFilter = useSelector((state) => state.preferencesFilter);
  const preferencesPage = useSelector((state) => state.preferencesPage?.items);
  const allPreferences = useSelector((state) => state.allPreferences);
  const allPreferencesPage = useSelector(
    (state) => state.allPreferencesPage?.items
  );
  const allPreferencesFilter = useSelector(
    (state) => state.allPreferencesFilter
  );
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [currentPgNoPreferences, setCurrentPreferencePage] = useState(1);
  const [currentPgNoAllPref, setCurrentAllPrefPage] = useState(1);
  const [currentPgNoCustomerNote, setCurrentCustomerNotePage] = useState(1);
  const [groupedTableData, setGroupedTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [showAll, setAllShow] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [notesAddBool, setNotesAddBoolForm] = useState(false);
  const [notesEditBool, setNotesEditBoolForm] = useState(false);
  const location = useLocation();
  const totalDiscount = useSelector((state) => state.totalDiscount);
  const { Data } = location.state || {};
  const [discountOption, setDiscountOption] = useState([]);
  const [analyticsSummary, setAnalyticsSummary] = useState({
    analyticsAmt: "0",
    analyticsOrders: "0",
    upi: 0,
    cash: 0,
    card: 0,
    zomato: 0,
    swiggy: 0,
    other: 0,
  });
  const [isViewMode, setIsViewMode] = useState(false);
  const [summary, setSummary] = useState({
    totalOrders: 0,
    totalBranchOrders: "0",
    totalOrderAmt: 0,
    name: "",
    phoneNumber: "",
  });
  const [analyticsDates, setAnalyticsDates] = useState({
    analyticsStartDate: null,
    analyticsEndDate: null,
  });
  const [customerOrderDates, setCustomerOrderDates] = useState({
    StartDate: null,
    EndDate: null,
  });

  const [tabs, setTabs] = useState([
    t("Basic Details"),
    t("Order List"),
    t("Notes"),
    t("Preferences"),
    t("All_Preferences"),
    t("Analytics"),
  ]);
  const [orderStatusTabs, setOrderStatusTabs] = useState([
    { value: "0", label: t("lable_pending") },
    { value: "1", label: t("lable_confirmed") },
    { value: "2", label: t("lable_in_progress") },
    { value: "3", label: t("lable_completed") },
    { value: "4", label: t("lable_cancelled") },
  ]);
  const statusClasses = {
    [t("lable_pending")]: "Pending",
    [t("lable_confirmed")]: "Confirmed",
    [t("lable_in_progress")]: "In-progress",
    [t("lable_completed")]: "Completed",
    [t("lable_cancelled")]: "Cancelled",
  };

  const [currentStatus, setCurrentStatus] = useState([]);

  const validationSchema = Yup.object({});

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  //Show keys which is to be displayed in table
  const showKey = {
    id: true,
    orderStatus: true,
    payment: true,
    discountAmount: true,
    taxAmount: true,
    orderAmount: true,
    totalOrderWithTax: true,
    totalItem: true,
  };
  const [columns, setColumns] = useState([
    {
      name: t("columns.quantities"),
      selector: (row) => row.Items,
    },
    {
      name: t("columns.subtotal"),
      selector: (row) => row.Items,
    },
    {
      name: t("columns.discount"),
      selector: (row) => row.Items,
    },
    {
      name: t("columns.tax"),
      selector: (row) => row.Items,
    },

    {
      name: t("columns.total"),
      selector: (row) => row.total,
    },
    {
      name: t("columns.payment"),
      selector: (row) => row.Status,
    },
    {
      name: t("columns.status"),
      selector: (row) => row.Status,
    },
    {
      name: t("columns.actions"),
      selector: (row) => row.action,
    },
  ]);
  const showActions = {
    showView: true,
    showEdit: false,
    showDelete: false,
    showRightIcon: false,
    showWrongIcon: false,
    navigate: "/edit-order",
    showKOTPrint: true,
    showInvoice: true,
  };

  const styleData = {
    phoneNumber: { type: { textAlign: "end" } },
    createdAt: { type: { textAlign: "center" } },
    discountAmount: { type: { textAlign: "end" } },
    taxAmount: { type: { textAlign: "end" } },
    orderAmount: { type: { textAlign: "end" } },
    totalOrderWithTax: { type: { textAlign: "end" } },
    totalItem: { type: { textAlign: "end" } },
    tableNo: { type: { textAlign: "end" } },
  };

  const styleProductData = {
    tablePrice: { type: { textAlign: "end" } },
    discountProductAmt: { type: { textAlign: "end" } },
    quantity: { type: { textAlign: "end" } },
    taxProductAmt: { type: { textAlign: "end" } },
    totalTablePrice: { type: { textAlign: "end" } },
    [t("columns.actions")]: { type: { minWidth: "100px" } },
    Actionstoggle: {
      type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
    },
  };

  const [productHeading, setProductHeading] = useState([
    {
      name: t("columns.product_name"),
      selector: (row) => row.Name,
    },
    {
      name: t("columns.add_ons"),
      selector: (row) => row.Date,
    },
    {
      name: t("columns.price"),
      selector: (row) => row.total,
    },
    {
      name: t("columns.quantity"),
      selector: (row) => row.Name,
    },
    {
      name: t("columns.discount"),
      selector: (row) => row.discountProductAmt,
    },
    {
      name: t("columns.tax"),
      selector: (row) => row.taxProductAmt,
    },

    {
      name: t("columns.total"),
      selector: (row) => row.total,
    },
    {
      name: t("columns.type"),
      selector: (row) => row.total,
    },
    {
      name: t("columns.instruction"),
      selector: (row) => row.instruction,
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        name: t("columns.quantities"),
        selector: (row) => row.Items,
      },
      {
        name: t("columns.subtotal"),
        selector: (row) => row.Items,
      },
      {
        name: t("columns.discount"),
        selector: (row) => row.Items,
      },
      {
        name: t("columns.tax"),
        selector: (row) => row.Items,
      },

      {
        name: t("columns.total"),
        selector: (row) => row.total,
      },
      {
        name: t("columns.payment"),
        selector: (row) => row.Status,
      },
      {
        name: t("columns.status"),
        selector: (row) => row.Status,
      },
      {
        name: t("columns.actions"),
        selector: (row) => row.action,
      },
    ]);

    setProductHeading([
      {
        name: t("columns.product_name"),
        selector: (row) => row.Name,
      },
      {
        name: t("columns.add_ons"),
        selector: (row) => row.Date,
      },
      {
        name: t("columns.price"),
        selector: (row) => row.total,
      },
      {
        name: t("columns.quantity"),
        selector: (row) => row.Name,
      },
      {
        name: t("columns.discount"),
        selector: (row) => row.discountProductAmt,
      },
      {
        name: t("columns.tax"),
        selector: (row) => row.taxProductAmt,
      },

      {
        name: t("columns.total"),
        selector: (row) => row.total,
      },
      {
        name: t("columns.type"),
        selector: (row) => row.total,
      },
      {
        name: t("columns.instruction"),
        selector: (row) => row.instruction,
      },
    ]);

    const updatedTabs = [
      t("Basic Details"),
      t("Order List"),
      t("Notes"),
      t("Preferences"),
      t("All_Preferences"),
      t("Analytics"),
    ];
    setTabs(updatedTabs);

    // Update activeTab to the translated equivalent if it exists
    const currentTabIndex = tabs.findIndex((tab) => tab === activeTab);
    if (currentTabIndex !== -1) {
      setActiveTab(updatedTabs[currentTabIndex]); // Sync activeTab to the current localization
    } else {
      setActiveTab(updatedTabs[0]); // Fallback to the first tab
    }

    const statusTabs = [
      { value: "0", label: t("lable_pending") },
      { value: "1", label: t("lable_confirmed") },
      { value: "2", label: t("lable_in_progress") },
      { value: "3", label: t("lable_completed") },
      { value: "4", label: t("lable_cancelled") },
    ];
    setOrderStatusTabs(statusTabs);
    // eslint-disable-next-line
  }, [localisation]);

  const showProductKeys = {
    productName: true,
    addOns: true,
    tablePrice: true,
    quantity: true,
    discountProductAmt: true,
    taxProductAmt: true,
    food_type_two: true,
    table_product_instruction: true,
    totalTablePrice: true,
  };

  const preferencesHeading = [
    {
      name: t("columns.product_name"),
      selector: (row) => row.productName,
    },
    {
      name: t("columns.total_orders"),
      selector: (row) => row.numberOfOrders,
    },
    {
      name: t("columns.instructions"),
      selector: (row) => row.Notes,
    },
  ];

  const showPreferencesKey = {
    productName: true,
    numberOfOrders: true,
    Notes: true,
  };
  const PreferencesStyleData = {
    productName: {
      type: {
        minWidth: "250px",
        width: "250px",
        maxWidth: "250px",
      },
    },
    [t("columns.product_name")]: {
      type: {
        minWidth: "250px",
        width: "250px",
        maxWidth: "250px",
      },
    },
    numberOfOrders: {
      type: {
        minWidth: "100px",
        width: "100px",
        maxWidth: "100px",
        textAlign: "end",
      },
    },
    [t("columns.total_orders")]: {
      type: { minWidth: "100px", width: "100px", maxWidth: "100px" },
    },
    Notes: {
      type: {
        textAlign: "justify",
      },
    },
  };

  const AllPreferencesHeading = [
    {
      name: t("columns.instructions"),
      selector: (row) => row.Notes,
    },
  ];

  const showAllPreferencesKey = {
    Notes: true,
  };
  const AllPreferencesStyleData = {
    Notes: {
      type: {
        textAlign: "justify",
      },
    },
  };

  const [initialValues, setInitialValues] = useState({
    note: "",
    id: 0,
  });
  const [initialValuesBasicDetails, setInitialValuesBasicDetails] = useState({
    firstName: "",
    lastName: "",
    DOB: "",
    aniverseryDate: "",
    discounts: [],
    profileImg: [],
    img: "",
  });

  const basicDetailValidationSchema = Yup.object({});

  // Example chart data
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: t("Customer_Spending_Analytics"),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t("Total Spent"),
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: t("Visit Date"),
        },
      },
    },
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "customer"
      );
      setcustomerActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    dispatch(clearCustomerOrderData());
    dispatch(clearTotalCustomerOrder());
    dispatch(clearCustomerOrderPageData());
    dispatch(clearCustomerOrderFilterData());
    dispatch(clearPreferencesData());
    dispatch(clearTotalPreferencesData());
    dispatch(clearPreferencesPageData());
    dispatch(clearPreferencesFilterData());
    dispatch(clearCustomerNoteData());
    dispatch(clearCustomerNotePageData());
    dispatch(clearTotalCustomerNoteData());
    dispatch(clearCustomerAnalyticsData());
    dispatch(clearCustomerAnalyticsFilterData());
    dispatch(clearAllPreferencesData());
    dispatch(clearAllPreferencesFilterData());
    dispatch(clearAllPreferencesPageData());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeTab === t("Basic Details")) {
      fetchCustomerById();
    }
    if (activeTab !== t("Notes")) {
      setNotesAddBoolForm(false);
      setIsViewMode(false);
    }
    // eslint-disable-next-line
  }, [activeTab, localisation]);

  useEffect(() => {
    if (Data && Data.data) {
      const data = Data.data;
      setSummary((prevSummary) => ({
        ...prevSummary,
        totalOrders: data.orders,
        totalOrderAmt: Number(data.total_order_amount).toFixed(2),
        name: data.name || "",
        phoneNumber: data.number || "",
        email: data.email || "",
      }));
      if (data.userId && activeTab === t("Order List")) {
        if (
          customerOrders &&
          customerOrders.items &&
          customerOrders.items.length === 0
        ) {
          setTableData([]);
          fetchCustomerOrders(data.userId);
        } else {
          if (
            customerOrders.items[0].order &&
            customerOrders.items[0].order.length > 0
          ) {
            const orders = customerOrders.items[0].order;
            const orderTableData = orders.map((item) => ({
              id: item.orderId,
              type: item.type,
              tableType: orderTypeArray.find(
                (orderType) => orderType.value === item.type
              )?.label,
              totalItem: item?.productData
                ? JSON.parse(item.productData).reduce(
                    (sum, product) => sum + Number(product.quantity),
                    0
                  )
                : 0,

              orderAmount: item.productData
                ? (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") +
                  JSON.parse(item.productData).reduce(
                    (sum, product) =>
                      sum + Number(product.price) * Number(product.quantity),
                    0
                  )
                : (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + "0",

              orderSubTotal: item.productData
                ? JSON.parse(item.productData).reduce(
                    (sum, product) =>
                      sum + Number(product.price) * Number(product.quantity),
                    0
                  )
                : 0,

              deliveryChange:
                (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                (item?.delivery_charge ? item.delivery_charge : "0"),

              total_delivery_charge: item?.delivery_charge
                ? Number(item.delivery_charge)
                : 0,
              discountAmount:
                (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                (item.total_discount_amount ? item.total_discount_amount : "0"),

              taxAmount:
                (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                (item?.total_tax_amount ? item.total_tax_amount : "0"),

              total_tax_amount: item?.total_tax_amount
                ? Number(item.total_tax_amount)
                : 0,

              service_charge: item?.service_charge
                ? Number(item?.service_charge)
                : 0,

              totalOrderWithTax:
                (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                (item?.order_amount
                  ? (
                      Number(item.order_amount) + Number(item?.service_charge)
                    ).toFixed(2)
                  : "0.00"),
              payment: item.payment,
              orderStatus: item.status,

              total_amount_with_tax: item?.order_amount
                ? Number(item.order_amount)
                : 0,

              orderNumber: item.order_number,

              productData: item.productData
                ? JSON.parse(item.productData).map((product, index) => ({
                    productId: product?.productId
                      ? Number(product.productId)
                      : 0,
                    productName: product?.productName
                      ? product?.productName.replace(/^'(.*)'$/, "$1")
                      : "",
                    addOns:
                      product?.attribute_data &&
                      product.attribute_data !== "" &&
                      product.attribute_data !== "null" &&
                      product?.attribute_data !== "NULL"
                        ? getFormattedSize(JSON.parse(product.attribute_data))
                        : "—",
                    product_size: product.size,
                    product_attribute: product?.attribute_data
                      ? JSON.parse(product.attribute_data)
                      : null,

                    tablePrice: product.price
                      ? (authuserData?.data?.symbol
                          ? authuserData.data.symbol
                          : "₹") + Number(product.price)
                      : (authuserData?.data?.symbol
                          ? authuserData.data.symbol
                          : "₹") + 0,
                    quantity: product.quantity,
                    discountProductAmt: Number(product.product_discount_amount),
                    taxProductAmt:
                      (authuserData?.data?.symbol
                        ? authuserData.data.symbol
                        : "₹") + calculateProductTax(product, item),
                    price: product.price ? Number(product.price) : 0,

                    totalTablePrice:
                      product.quantity && product.price
                        ? (authuserData?.data?.symbol
                            ? authuserData.data.symbol
                            : "₹") + totalPrice(product, item)
                        : (authuserData?.data?.symbol
                            ? authuserData.data.symbol
                            : "₹") + 0,

                    totalPrice:
                      product.quantity && product.price
                        ? Number(product.quantity) * Number(product.price)
                        : 0,
                    food_type_two:
                      product?.food_type_2 &&
                      product.food_type_2 !== "" &&
                      product.food_type_2 !== "null" &&
                      product.food_type_2 !== "NULL"
                        ? product.food_type_2 === "0"
                          ? "No Onion/Garlic"
                          : product.food_type_2 === "1"
                          ? "Jain"
                          : "Regular"
                        : "Regular",
                    product_instruction:
                      product?.product_instruction &&
                      product.product_instruction !== "" &&
                      product.product_instruction !== "null" &&
                      product.product_instruction !== "NULL"
                        ? product.product_instruction
                        : null,
                    table_product_instruction:
                      product?.product_instruction &&
                      product.product_instruction !== "" &&
                      product.product_instruction !== "null" &&
                      product.product_instruction !== "NULL"
                        ? product.product_instruction
                        : "—",
                    food_type_2:
                      product?.food_type_2 &&
                      product.food_type_2 !== "" &&
                      product.food_type_2 !== "null"
                        ? product.food_type_2
                        : null,
                    food_type_1:
                      product?.food_type_1 &&
                      product.food_type_1 !== "" &&
                      product.food_type_1 !== "null"
                        ? product.food_type_1
                        : null,
                    attribute_data:
                      product?.attribute_data &&
                      product.attribute_data !== "" &&
                      product.attribute_data !== "null" &&
                      product?.attribute_data !== "NULL"
                        ? product.attribute_data
                        : null,
                    // attribute_data:"",
                    map_table_id: product?.map_table_id
                      ? product.map_table_id
                      : 0,
                    orderId: item.orderId,
                    productStatus: product?.status ? product.status : "0",
                    cgst: product?.cgst,
                    sgst: product.sgst,
                    igst: product.igst,
                    vat: product.vat,
                    product_discount_code: product.product_discount_code
                      ? product.product_discount_code
                      : "",
                    product_discount_id: product.product_discount_id
                      ? product.product_discount_id
                      : "0",
                    product_discount_amount: product.product_discount_amount
                      ? product.product_discount_amount
                      : 0,
                  }))
                : [],
              zomatoSwiggyPrice: item.zomato_swiggy_other_amount
                ? `${item.zomato_swiggy_other_amount}`
                : null,

              user_id: item.user_id,
              firstName:
                item.first_name !== "Bespeakk" && item.first_name !== "bespeakk"
                  ? item.first_name
                  : "",
              lastName: item.last_name,
              instructions: item.instructions,
              brand_id: item.brand_id,
              brand_name: item.brand_name,
              brand_gst_number: item.brand_gst_number,
              address_id: item?.address_id,
              address_line1: item.address_line1,
              address_line2: item.address_line2,
              address_city: item.address_city,
              address_postal_code: item.address_postal_code,
              address_title: item.address_title,
              total_discount_amount: item.total_discount_amount,
              discount_id: item.discount_id,
              Discount_Code: item.Discount_Code,
              delivery_charge: item.delivery_charge,
              companyName: item.companyName,
              companyAddress: item.companyAddress,
              table_no: item.table_no,
              tableNo:
                item.table_no !== "" && item.table_no !== null
                  ? item.table_no
                  : "—",
              customerName:
                item.first_name !== "Bespeakk" && item.first_name !== "bespeakk"
                  ? item.first_name + " " + item.last_name
                  : "N/A",
              phoneNumber:
                item.phone_number &&
                item.phone_number !== "" &&
                Number(item.phone_number) !== 8155006366
                  ? item.phone_number
                  : "N/A",
              createdAt: item.createdAt
                ? format(item.createdAt, "dd-MM-yyyy")
                : "",
              orderCreatedAt: item.createdAt,
              orderModifiedAt: item.modifiedAt,
              modifiedAt: item.modifiedAt
                ? format(item.modifiedAt, "dd-MM-yyyy")
                : "",
              tableName: item.spot_name,
            }));
            const count = customerOrders.items[0].totalRecords;
            setSummary((prevSummary) => ({
              ...prevSummary,
              totalBranchOrders: count,
            }));
            setTableData(orderTableData);
          } else {
            setTableData([]);
          }
        }

        const startDate = formatDateToDDMMYYYY(customerOrdersFilter.startDate);
        const endDate = formatDateToDDMMYYYY(customerOrdersFilter.endDate);
        setCustomerOrderDates((prev) => ({
          ...prev,
          StartDate: startDate,
          EndDate: endDate,
        }));

        if (
          customerOrdersFilter.status !== "" &&
          customerOrdersFilter.status !== null &&
          customerOrdersFilter.status.length !== 0
        ) {
          setCurrentStatus(customerOrdersFilter.status);
        }
      }
    }
    // eslint-disable-next-line
  }, [Data, customerOrders, customerOrdersFilter, activeTab, localisation]);

  useEffect(() => {
    if (
      Data &&
      Data.data &&
      Data.data.userId &&
      activeTab === t("Preferences")
    ) {
      const data = Data.data;
      if (preferences && preferences.items && preferences.items.length === 0) {
        setPreferencesData([]);
        fetchPreferences(data.userId);
      } else {
        if (
          preferences &&
          preferences.items[0] &&
          preferences.items[0]?.data &&
          preferences.items[0].data.length > 0
        ) {
          const data = preferences.items[0].data;
          const rawData = data.map((item) => ({
            productName: item.product_name,
            numberOfOrders: item.number_of_orders,
            Notes: JSON.parse(item.Notes),
          }));

          const transformedData = filterNotes(rawData);
          setPreferencesData(transformedData);
        } else {
          setPreferencesData([]);
        }
      }
    }
    // eslint-disable-next-line
  }, [Data, activeTab, preferences, preferencesFilter, localisation]);

  useEffect(() => {
    if (
      Data &&
      Data.data &&
      Data.data.userId &&
      activeTab === t("All_Preferences")
    ) {
      const data = Data.data;
      if (
        allPreferences &&
        allPreferences.items &&
        allPreferences.items.length === 0
      ) {
        setAllPreferencesData([]);
        fetchAllPreferences(data.userId);
      } else {
        if (
          allPreferences &&
          allPreferences.items[0] &&
          allPreferences.items[0]?.data &&
          allPreferences.items[0].data.length > 0
        ) {
          const data = allPreferences.items[0].data;
          const rawData = data.map((item) => ({
            productName: item.product_name,
            numberOfOrders: item.number_of_orders,
            Notes: JSON.parse(item.Notes),
          }));

          const transformedData = filterNotes(rawData);
          setAllPreferencesData(transformedData);
        } else {
          setAllPreferencesData([]);
        }
      }
    }
    // eslint-disable-next-line
  }, [Data, activeTab, allPreferences, allPreferencesFilter, localisation]);

  useEffect(() => {
    if (Data && Data.data && Data.data.userId && activeTab === t("Notes")) {
      const data = Data.data;
      if (
        customerNote &&
        customerNote.items &&
        customerNote.items.length === 0
      ) {
        setNotesData([]);
        fetchCustomerNotes(data.userId);
      } else {
        if (
          customerNote &&
          customerNote.items[0] &&
          customerNote.items[0]?.data &&
          customerNote.items[0]?.data[0] &&
          customerNote.items[0].data.length > 0
        ) {
          const data = customerNote.items[0].data;
          const transformedNotesData = data.map((item) => {
            // Convert the date format
            const formattedDate = new Date(item.created_at).toLocaleDateString(
              "en-GB",
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }
            );

            return {
              note: item.note,
              date: formattedDate,
              user_id: item.user_id,
              branch_id: item.branch_id,
              id: item.id,
              created_name:
                item.created_first_name + " " + item.created_last_name,
              modified_name:
                item.modified_first_name && item.modified_last_name
                  ? item.created_first_name + " " + item.created_last_name
                  : null,
            };
          });
          setNotesData(transformedNotesData);
        }
      }
    }
    // eslint-disable-next-line
  }, [Data, activeTab, customerNote, localisation]);

  useEffect(() => {
    if (Data && Data.data && Data.data.userId && activeTab === t("Analytics")) {
      const data = Data.data;
      if (
        customerAnalytics &&
        customerAnalytics.items &&
        customerAnalytics.items.length === 0
      ) {
        setNotesData([]);
        fetchCustomerAnalytics(data.userId);
      } else {
        if (
          customerAnalytics &&
          customerAnalytics.items &&
          customerAnalytics.items[0]?.data
        ) {
          const analyticsData = customerAnalytics.items[0].data;
          setAnalyticsSummary({
            analyticsAmt: Number(
              customerAnalytics.items[0].total_spent_amount_in_period
            ).toFixed(2),
            analyticsOrders: Number(
              customerAnalytics.items[0].total_order_in_period
            ),
            upi: customerAnalytics.items[0].total_spent_in_period_upi
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_upi
                ).toFixed(2)
              : 0,
            cash: customerAnalytics.items[0].total_spent_in_period_cash
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_cash
                ).toFixed(2)
              : 0,
            card: customerAnalytics.items[0].total_spent_in_period_card
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_card
                ).toFixed(2)
              : 0,
            zomato: customerAnalytics.items[0].total_spent_in_period_zomato
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_zomato
                ).toFixed(2)
              : 0,
            swiggy: customerAnalytics.items[0].total_spent_in_period_swiggy
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_swiggy
                ).toFixed(2)
              : 0,
            other: customerAnalytics.items[0].total_spent_in_period_other
              ? Number(
                  customerAnalytics.items[0].total_spent_in_period_other
                ).toFixed(2)
              : 0,
          });
          // Transform the data for the bar chart
          const labels = analyticsData.map((item) =>
            new Date(item.visit_date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })
          );
          const data = analyticsData.map((item) =>
            parseFloat(item.total_spent)
          );

          // Update the chart data state
          setBarChartData({
            labels,
            datasets: [
              {
                label: t("Total Spent"),
                data,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          });
        }

        const startDate = formatDateToDDMMYYYY(
          customerAnalyticsFilter.StartDate
        );
        const endDate = formatDateToDDMMYYYY(customerAnalyticsFilter.EndDate);
        setAnalyticsDates((prev) => ({
          ...prev,
          analyticsStartDate: startDate,
          analyticsEndDate: endDate,
        }));
      }
    }
    // eslint-disable-next-line
  }, [
    Data,
    activeTab,
    customerAnalytics,
    customerAnalyticsFilter,
    localisation,
  ]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setExpandedItem(tableData.map((_, index) => index));
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const groupedData = Object.values(
        tableData.reduce((acc, order) => {
          if (!acc[order.id]) {
            acc[order.id] = [];
          }
          acc[order.id].push(order);
          return acc;
        }, {})
      );
      setGroupedTableData(groupedData);
    }
    // eslint-disable-next-line
  }, [tableData]);

  useEffect(() => {
    if (
      Data &&
      totalDiscount &&
      totalDiscount.items &&
      totalDiscount.items.length === 0
    ) {
      fetchTotalDiscount();
    } else {
      if (
        totalDiscount.items &&
        totalDiscount.items[0] &&
        totalDiscount.items[0]?.data &&
        totalDiscount.items[0].data.length > 0
      ) {
        const currentDate = new Date();
        const validDiscounts = totalDiscount.items[0].data.filter((item) => {
          const endDate = new Date(item.end_date);
          return endDate > currentDate;
        });

        const optionData = validDiscounts.map((item) => ({
          value: item.discountId,
          label: item.discountCode,
        }));
        setDiscountOption(optionData);
      } else {
        setDiscountOption([]);
      }
    }
    // eslint-disable-next-line
  }, [totalDiscount, Data, localisation]);

  // fucntions for active step "order list"
  const toggleAccordionItem = (eventKey) => {
    setExpandedItem((prevExpandedItems) =>
      prevExpandedItems.includes(eventKey)
        ? prevExpandedItems.filter((key) => key !== eventKey)
        : [...prevExpandedItems, eventKey]
    );
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Confirmed";
      case "2":
        return "In-progress";
      case "3":
        return "Completed";
      case "4":
        return "Cancelled";
      default:
        return "";
    }
  };

  function calculateProductTax(product, order) {
    if (Number(order.total_tax_amount) > 0) {
      const price =
        Number(product.price) * Number(product.quantity) -
        Number(product.product_discount_amount);

      const cgstAmount = price * (Number(product.cgst) / 100);
      const sgstAmount = price * (Number(product.sgst) / 100);

      // Total tax amount
      const totalTax = cgstAmount + sgstAmount;
      return totalTax;
    } else {
      return 0;
    }
  }
  function totalPrice(product, order) {
    const price =
      Number(product.price) * Number(product.quantity) -
      Number(product.product_discount_amount) +
      calculateProductTax(product, order, "tax");
    return parseFloat(price.toFixed(2));
  }

  const getFormattedSize = (data) => {
    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
  };

  const fetchCustomerOrders = (userId) => {
    if (customerOrdersPage > 1) {
      const requestBody = {
        p_skip: (customerOrdersPage - 2) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: userId,
        p_start_date: formatDateToYYYYMMDD(customerOrdersFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(customerOrdersFilter.endDate),
      };
      if (
        customerOrdersFilter.status !== "" &&
        customerOrdersFilter.status !== null
      ) {
        requestBody.p_status = customerOrdersFilter.status;
      }
      if (
        customerOrdersFilter.payment !== "" &&
        customerOrdersFilter.payment !== null
      ) {
        requestBody.p_payment = customerOrdersFilter.payment;
      }
      if (
        customerOrdersFilter.type !== "" &&
        customerOrdersFilter.type !== null
      ) {
        requestBody.p_type = customerOrdersFilter.type;
      }
      setCurrentPage(customerOrdersPage - 1);
      dispatch(getCustomerOrderPageData(customerOrdersPage - 1));
      getCustomersOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (customerOrdersPage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: userId,
        p_start_date: formatDateToYYYYMMDD(customerOrdersFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(customerOrdersFilter.endDate),
      };
      if (
        customerOrdersFilter.status !== "" &&
        customerOrdersFilter.status !== null
      ) {
        requestBody.p_status = customerOrdersFilter.status;
      }
      if (
        customerOrdersFilter.payment !== "" &&
        customerOrdersFilter.payment !== null
      ) {
        requestBody.p_payment = customerOrdersFilter.payment;
      }
      if (
        customerOrdersFilter.type !== "" &&
        customerOrdersFilter.type !== null
      ) {
        requestBody.p_type = customerOrdersFilter.type;
      }
      getCustomersOrderService(requestBody, tokenData, dispatch);
    }
  };

  const generatePDFAndUpload = async (orderData) => {
    const { companyName } = authuserData.data;
    const invoiceHtml1 = generateInvoiceHtml(orderData, authuserData, "wp");
    // Create a temporary div to render the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.height = "297mm";
    tempDiv.style.overflow = "hidden";
    tempDiv.innerHTML = invoiceHtml1;
    document.body.appendChild(tempDiv);

    try {
      // Convert HTML to Canvas
      const canvas = await html2canvas(tempDiv, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight); // Use JPEG

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");
      const file = new File(
        [pdfBlob],
        `invoice-${orderData?.orderNumber}.pdf`,
        {
          type: "application/pdf",
        }
      );

      // Prepare upload data
      const uploadData = {
        pdf: file,
        p_order_user: orderData.firstName + " " + orderData.lastName,
        p_order_phone_number: orderData.phoneNumber,
        p_total_amt: orderData.totalOrderWithTax,
        p_company_name: companyName,
      };

      dispatch(uploadOrderPDFService(uploadData, tokenData));
    } catch (error) {
      console.error("Error generating PDF or uploading:", error);
    } finally {
      // Clean up by removing the temporary div
      document.body.removeChild(tempDiv);
    }
  };

  const onPageChange = (pageNumber) => {
    const requestData = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_user_id: Data.data.userId,
    };
    if (
      customerOrdersFilter.startDate !== "" &&
      customerOrdersFilter.startDate !== null
    ) {
      requestData.p_start_date = formatDateToYYYYMMDD(
        customerOrdersFilter.startDate
      );
    }
    if (
      customerOrdersFilter.endDate !== "" &&
      customerOrdersFilter.endDate !== null
    ) {
      requestData.p_end_date = formatDateToYYYYMMDD(
        customerOrdersFilter.endDate
      );
    }
    if (
      customerOrdersFilter.status !== "" &&
      customerOrdersFilter.status !== null
    ) {
      requestData.p_status = customerOrdersFilter.status;
    }
    if (
      customerOrdersFilter.payment !== "" &&
      customerOrdersFilter.payment !== null
    ) {
      requestData.p_payment = customerOrdersFilter.payment;
    }
    if (
      customerOrdersFilter.orderType !== "" &&
      customerOrdersFilter.orderType !== null
    ) {
      requestData.p_type = customerOrdersFilter.orderType;
    }
    if (pageNumber !== currentPageNumber) {
      getCustomersOrderService(requestData, tokenData, dispatch);
    }
    setCurrentPage(pageNumber);
    dispatch(getCustomerOrderPageData(pageNumber));
  };

  const clearCustomerOrdersFilters = () => {
    dispatch(clearCustomerOrderFilterData());
    dispatch(clearCustomerOrderData());
    dispatch(clearCustomerOrderPageData());
    setCurrentStatus([]);
  };

  const handleClickStatusFilter = (status) => {
    const isActive = currentStatus.includes(status.value);
    const updatedStatus = isActive
      ? currentStatus.filter((item) => item !== status.value)
      : [...currentStatus, status.value];
    setCurrentStatus(updatedStatus);
    if (customerOrdersPage > 1) {
      const requestBody = {
        p_skip: (customerOrdersPage - 2) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: Data.data.userId,
        p_start_date: formatDateToYYYYMMDD(customerOrdersFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(customerOrdersFilter.endDate),
        p_status: updatedStatus,
      };
      if (
        customerOrdersFilter.payment !== "" &&
        customerOrdersFilter.payment !== null
      ) {
        requestBody.p_payment = customerOrdersFilter.payment;
      }
      if (
        customerOrdersFilter.orderType !== "" &&
        customerOrdersFilter.orderType !== null
      ) {
        requestBody.p_type = customerOrdersFilter.orderType;
      }
      dispatch(
        getCustomerOrderFilterData({
          startDate: customerOrdersFilter.startDate,
          endDate: customerOrdersFilter.endDate,
          status: updatedStatus,
          payment: customerOrdersFilter.payment,
          orderType: customerOrdersFilter.orderType,
        })
      );
      setCurrentPage(customerOrdersPage - 1);
      dispatch(getCustomerOrderPageData(customerOrdersPage - 1));
      getCustomersOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (customerOrdersPage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: Data.data.userId,
        p_start_date: formatDateToYYYYMMDD(customerOrdersFilter.startDate),
        p_end_date: formatDateToYYYYMMDD(customerOrdersFilter.endDate),
        p_status: updatedStatus,
      };
      if (
        customerOrdersFilter.payment !== "" &&
        customerOrdersFilter.payment !== null
      ) {
        requestBody.p_payment = customerOrdersFilter.payment;
      }
      if (
        customerOrdersFilter.orderType !== "" &&
        customerOrdersFilter.orderType !== null
      ) {
        requestBody.p_type = customerOrdersFilter.orderType;
      }
      dispatch(
        getCustomerOrderFilterData({
          startDate: customerOrdersFilter.startDate,
          endDate: customerOrdersFilter.endDate,
          status: updatedStatus,
          payment: customerOrdersFilter.payment,
          orderType: customerOrdersFilter.orderType,
        })
      );
      getCustomersOrderService(requestBody, tokenData, dispatch);
    }
  };
  //  functions for preferences
  const fetchPreferences = (userId) => {
    if (preferencesPage > 1) {
      const requestBody = {
        p_skip: (preferencesPage - 2) * 10,
        p_take: 10,
        p_user_id: userId,
        p_branch_id: authuserData.data.branchId,
      };
      if (
        preferencesFilter.p_product_name !== "" &&
        preferencesFilter.p_product_name !== null
      ) {
        requestBody.p_product_name = preferencesFilter.p_product_name;
      }
      setCurrentPreferencePage(preferencesPage - 1);
      dispatch(getPreferencesPageData(preferencesPage - 1));
      getAllPreferencesService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (preferencesPage - 1) * 10,
        p_take: 10,
        p_user_id: userId,
        p_branch_id: authuserData.data.branchId,
      };
      if (
        preferencesFilter.p_product_name !== "" &&
        preferencesFilter.p_product_name !== null
      ) {
        requestBody.p_product_name = preferencesFilter.p_product_name;
      }
      getAllPreferencesService(requestBody, tokenData, dispatch);
    }
  };

  function filterNotes(preferencesData) {
    return preferencesData.map((item) => ({
      ...item,
      Notes: item.Notes.filter(
        (note) => note !== null && note.trim() !== "" && note !== "null"
      ),
    }));
  }

  const onPagePreferenceChange = (pageNumber) => {
    const requestData = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_user_id: Data.data.userId,
      p_branch_id: authuserData.data.branchId,
    };
    if (
      preferencesFilter.p_product_name !== "" &&
      preferencesFilter.p_product_name !== null
    ) {
      requestData.p_product_name = preferencesFilter.p_product_name;
    }
    if (pageNumber !== currentPgNoPreferences) {
      getAllPreferencesService(requestData, tokenData, dispatch);
    }
    setCurrentPreferencePage(pageNumber);
    dispatch(getPreferencesPageData(pageNumber));
  };
  const clearPreferenceFilters = () => {
    dispatch(clearPreferencesFilterData());
    dispatch(clearPreferencesData());
    dispatch(clearPreferencesPageData());
  };
  const handlePrefClose = () => setShow(false);
  const handlePrefShow = () => setShow(true);

  // functions for all preferences
  const fetchAllPreferences = (userId) => {
    if (allPreferencesPage > 1) {
      const requestBody = {
        p_skip: (allPreferencesPage - 2) * 10,
        p_take: 10,
        p_user_id: userId,
      };
      if (
        allPreferencesFilter.p_product_name !== "" &&
        allPreferencesFilter.p_product_name !== null
      ) {
        requestBody.p_product_name = allPreferencesFilter.p_product_name;
      }
      setCurrentAllPrefPage(allPreferencesPage - 1);
      dispatch(getAllPreferencesPageData(allPreferencesPage - 1));
      getAllPreferencesService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (preferencesPage - 1) * 10,
        p_take: 10,
        p_user_id: userId,
      };
      if (
        allPreferencesFilter.p_product_name !== "" &&
        allPreferencesFilter.p_product_name !== null
      ) {
        requestBody.p_product_name = allPreferencesFilter.p_product_name;
      }
      getAllPreferencesService(requestBody, tokenData, dispatch);
    }
  };

  const onPageAllPrefChange = (pageNumber) => {
    const requestData = {
      p_skip: (pageNumber - 1) * 10,
      p_take: 10,
      p_user_id: Data.data.userId,
    };
    if (
      allPreferencesFilter.p_product_name !== "" &&
      allPreferencesFilter.p_product_name !== null
    ) {
      requestData.p_product_name = allPreferencesFilter.p_product_name;
    }
    if (pageNumber !== currentPgNoAllPref) {
      getAllPreferencesService(requestData, tokenData, dispatch);
    }
    setCurrentAllPrefPage(pageNumber);
    dispatch(getAllPreferencesPageData(pageNumber));
  };
  const clearAllPreferenceFilters = () => {
    dispatch(clearAllPreferencesData());
    dispatch(clearAllPreferencesFilterData());
    dispatch(clearAllPreferencesPageData());
  };

  const handleAllPrefClose = () => setAllShow(false);
  const handleAllPrefShow = () => setAllShow(true);

  const handleAnalyticsClose = () => setShowAnalytics(false);
  const handleAnalyticsShow = () => setShowAnalytics(true);

  const handleOrderListClose = () => setShowOrders(false);
  const handleOrderListShow = () => setShowOrders(true);

  // functions for customer notes
  const fetchCustomerNotes = (userId) => {
    if (customerNotePage > 1) {
      const requestBody = {
        p_skip: (customerNotePage - 2) * 10,
        p_take: 12,
        p_user_id: userId,
        p_branch_id: authuserData.data.branchId,
      };
      setCurrentCustomerNotePage(customerNotePage - 1);
      dispatch(getCustomerNotePageData(customerNotePage - 1));
      getAllCustomerNotesService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (preferencesPage - 1) * 10,
        p_take: 12,
        p_user_id: userId,
        p_branch_id: authuserData.data.branchId,
      };
      getAllCustomerNotesService(requestBody, tokenData, dispatch);
    }
  };

  const handleAddBtn = () => {
    setInitialValues({
      note: "",
      id: 0,
    });
    setNotesAddBoolForm(!notesAddBool);
    setIsViewMode(false);
  };

  const handleNotesSumbit = (values, type) => {
    if (values.note === "") {
      toast.error("Please Enter Note");
    } else {
      setNotesAddBoolForm(false);
      setNotesEditBoolForm(false);
      const requestBody = {
        ...(type === "delete" && { p_id: values.id }),
        ...(setNotesEditBoolForm && { p_id: values.id }),
        p_note: values.note,
        p_user_id: Data.data.userId,
        ...(type === "delete" ? { p_isdeleted: "1" } : { p_isdeleted: "0" }),
      };
      if (notesEditBool) {
        dispatch(addCustomerNoteService(requestBody, tokenData, "update"));
      } else if (type && type === "delete") {
        handledeletClick(
          requestBody,
          dispatch,
          "notes",
          tokenData,
          0,
          customerNotePage
        );
      } else {
        dispatch(addCustomerNoteService(requestBody, tokenData, "add"));
      }
    }
  };

  const onPageCustomerNoteChange = (pageNumber) => {
    setNotesAddBoolForm(false);
    const requestData = {
      p_skip: (pageNumber - 1) * 12,
      p_take: 12,
      p_user_id: Data.data.userId,
      p_branch_id: authuserData.data.branchId,
    };

    if (pageNumber !== currentPgNoCustomerNote) {
      getAllCustomerNotesService(requestData, tokenData, dispatch);
    }
    setCurrentCustomerNotePage(pageNumber);
    dispatch(getCustomerNotePageData(pageNumber));
  };

  const handleEdit = (note) => {
    setNotesAddBoolForm(true);
    setNotesEditBoolForm(true);
    setInitialValues({
      note: note.note,
      id: note.id,
    });
  };
  const handleView = (note) => {
    setNotesAddBoolForm(true);
    setIsViewMode(true); // Enable view mode
    setInitialValues({
      note: note.note,
      id: note.id,
    });
  };
  const handleDelete = (note) => {
    handleNotesSumbit(note, "delete");
  };

  // functions for basic details
  const fetchTotalDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 50000,
    };
    getAllDiscountService(requestBody, tokenData, dispatch, true);
    // eslint-disable-next-line
  };

  const handleBasicDetailSumbit = (values) => {
    const formatDate = (dateString) => {
      return new Date(dateString).toISOString().slice(0, 10); // Extracts "YYYY-MM-DD"
    };
    const requestBody = {
      p_first_name: values.firstName,
      p_last_name: values.lastName,
      p_dob: formatDate(values.DOB),
      p_aniversery_date: formatDate(values.aniverseryDate),
      p_user_id: Data.data.userId,
      p_discount_ids:
        values.discounts?.length > 0
          ? Array.from(
              new Set(values.discounts.map((item) => Number(item)))
            ).map((id) => ({ id }))
          : null,
      p_profile_pic: values.profileImg,
    };
    dispatch(UpdateCustomerDetailService(requestBody, tokenData, "update"));
  };

  const fetchCustomerById = async () => {
    if (Data && Data.data.userId && Data.data.userId !== "") {
      const userResponse = await getUserById(
        Data.data.userId,
        tokenData,
        dispatch
      );
      if (userResponse.status === "success") {
        const userData = userResponse.data;
        if (userData.profilePic) {
          imageUrlToFile(API_URL.TOKEN_URL + userData.profilePic)
            .then((file) => {
              setInitialValuesBasicDetails({
                discounts: userData.discount_ids
                  ? JSON.parse(userData.discount_ids).map((item) => item)
                  : [],
                DOB: userData.dob ? userData.dob : "",
                aniverseryDate: userData.aniversery_date
                  ? userData.aniversery_date
                  : "",
                firstName:
                  userData.firstName && userData.firstName !== ""
                    ? userData.firstName
                    : "",
                lastName:
                  userData.lastName && userData.lastName !== ""
                    ? userData.lastName
                    : "",
                profileImg: [file],
                img: API_URL.TOKEN_URL + userData.profilePic,
              });
            })
            .catch((error) => {
              setInitialValuesBasicDetails({
                discounts: userData.discount_ids
                  ? JSON.parse(userData.discount_ids).map((item) => item)
                  : [],
                DOB: userData.dob ? userData.dob : "",
                aniverseryDate: userData.aniversery_date
                  ? userData.aniversery_date
                  : "",
                firstName:
                  userData.firstName && userData.firstName !== ""
                    ? userData.firstName
                    : "",
                lastName:
                  userData.lastName && userData.lastName !== ""
                    ? userData.lastName
                    : "",
                profileImg: [API_URL.TOKEN_URL + userData.profilePic],
                img: API_URL.TOKEN_URL + userData.profilePic,
              });
            });
        } else {
          setInitialValuesBasicDetails({
            discounts: userData.discount_ids
              ? JSON.parse(userData.discount_ids).map((item) => item)
              : [],
            DOB: userData.dob ? userData.dob : "",
            aniverseryDate: userData.aniversery_date
              ? userData.aniversery_date
              : "",
            firstName:
              userData.firstName && userData.firstName !== ""
                ? userData.firstName
                : "",
            lastName:
              userData.lastName && userData.lastName !== ""
                ? userData.lastName
                : "",
            profileImg: [],
            img: "",
          });
        }
      }
    }
  };
  // functions of analytics
  const fetchCustomerAnalytics = (userId) => {
    const requestBody = {
      p_user_id: userId,
      p_start_date: formatDateToYYYYMMDD(customerAnalyticsFilter.StartDate),
      p_end_date: formatDateToYYYYMMDD(customerAnalyticsFilter.EndDate),
      p_branch_id: authuserData.data.branchId,
    };
    getCustomerAnalyticsService(requestBody, tokenData, dispatch);
  };

  const clearCustomerAnalyticsFilters = () => {
    dispatch(clearCustomerAnalyticsFilterData());
    dispatch(clearCustomerAnalyticsData());
  };

  return (
    <div className="table-body-content">
      <div>
        <div className="title">
          <h4>
            {customerActionData && customerActionData.length > 0
              ? getRouteNameFromSidebar(
                  customerActionData[0].name,
                  localisation
                )
              : t("Customer")}
          </h4>
        </div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/home")}>
            {t("Home")}
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/customer")}>
            {customerActionData && customerActionData.length > 0
              ? getRouteNameFromSidebar(
                  customerActionData[0].name,
                  localisation
                )
              : t("CRM")}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {Data && Data.isEdit === true
              ? summary.name
              : Data && Data.isEdit === false
              ? summary.name
              : t("actions.add")}{" "}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="crm-container">
        <div className="crm-header ">
          <div className="profile-section">
            <p className="profile-name">
              {t("lable_Name")}: {summary.name}
            </p>
            <p className="profile-name">
              {t("lable_phone_number")}: {summary.phoneNumber}
            </p>
            {summary.email && (
              <p className="profile-name">
                {t("lable_email")}: {summary.email}
              </p>
            )}
          </div>
          <div className="stats-container">
            <div className="stat-box">
              <h3>{t("lable_total_orders")}</h3>
              <p>{summary.totalOrders}</p>
            </div>
            <div className="stat-box">
              <h3>{t("addOrders.total_amount")}</h3>
              <p>{summary.totalOrderAmt}</p>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* Tab Content */}
        {Data && Data.data ? (
          <div className="tab-content">
            {activeTab === t("Order List") ? (
              <>
                {customerOrders && (
                  <>
                    <div className="btn-groups crm-btn-group-analytics mb-3">
                      <div className="btn btn-fillter-main crm-filter">
                        {customerOrderDates.StartDate ===
                        customerOrderDates.EndDate ? (
                          <>
                            {t("Orders_of")} {customerOrderDates.StartDate}
                          </>
                        ) : (
                          <>
                            {t("Orders_from")}: {customerOrderDates.StartDate}{" "}
                            to {customerOrderDates.EndDate}
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        {((customerOrdersFilter.startDate !== "" &&
                          customerOrdersFilter.startDate !== null &&
                          new Date(
                            formatDateToYYYYMMDD(customerOrdersFilter.startDate)
                          ).toDateString() !==
                            new Date(
                              new Date().setMonth(new Date().getMonth() - 6)
                            ).toDateString()) ||
                          (customerOrdersFilter.endDate !== "" &&
                            customerOrdersFilter.endDate !== null &&
                            new Date(
                              formatDateToYYYYMMDD(customerOrdersFilter.endDate)
                            ).toDateString() !== new Date().toDateString()) ||
                          (customerOrdersFilter.payment !== "" &&
                            customerOrdersFilter.payment !== null) ||
                          (customerOrdersFilter.orderType !== "" &&
                            customerOrdersFilter.orderType !== null) ||
                          (customerOrdersFilter.status !== "" &&
                            customerOrdersFilter.status !== null &&
                            customerOrdersFilter.status.length !== 0)) && (
                          <Dropdown className="new-header-dropdown action-btn">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              data-bs-toggle="tooltip"
                              title="Action"
                            >
                              <GrMoreVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="crm-clear-filter">
                              <Dropdown.Item
                                onClick={clearCustomerOrdersFilters}
                              >
                                {t("clear_filters")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <Button
                          onClick={handleOrderListShow}
                          data-bs-toggle="tooltip"
                          title="Filter"
                          className="btn btn-fillter-main crm-filter"
                          name={
                            <div className="filter">
                              <FaFilter className="filter-icon" />
                            </div>
                          }
                        ></Button>
                      </div>

                      <Offcanvas
                        show={showOrders}
                        onHide={handleOrderListClose}
                        placement="end"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            {t("filter_options")}
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Filter
                            page="customerOrders"
                            handleClose={handleOrderListClose}
                            userId={Data.data.userId}
                          />
                        </Offcanvas.Body>
                      </Offcanvas>
                    </div>
                    <div className="boxes-container mb-4">
                      {orderStatusTabs.map((status) => (
                        <div
                          className={`box ${statusClasses[status.label]}  ${
                            currentStatus.includes(status.value)
                              ? "box-active"
                              : ""
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickStatusFilter(status)}
                        >
                          <h6>{status.label}</h6>
                        </div>
                      ))}
                    </div>

                    {customerOrders.items &&
                    customerOrders.items.length > 0 &&
                    tableData.length > 0 &&
                    groupedTableData.length > 0 ? (
                      <>
                        {groupedTableData.map((orders, index) => (
                          <Accordion
                            key={index}
                            activeKey={expandedItem}
                            onSelect={toggleAccordionItem}
                            className="product-accordions order-bg-remove"
                          >
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                className={`p-relative ${
                                  orders.length > 0 && orders[0].orderStatus
                                    ? getOrderStatus(orders[0].orderStatus)
                                    : ""
                                }`}
                              >
                                <span className="product-detail white-color">
                                  {orders[0].orderNumber}
                                </span>
                                {!expandedItem.includes(index) ? (
                                  <FaAngleDown className="arrow-icon white-color" />
                                ) : (
                                  <FaAngleUp className="arrow-icon white-color" />
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div>
                                  <span>
                                    {orders[0]?.brand_gst_number && (
                                      <span>
                                        <b>{t("GSTIN")} - </b>
                                        {orders[0].brand_gst_number}
                                      </span>
                                    )}
                                  </span>
                                  <div className="row">
                                    <div className="order-heading-info col-6">
                                      <div className="row">
                                        <div className="col-6">
                                          <span className="table-type">
                                            <b>{t("lable_date")} - </b>{" "}
                                            {orders[0].createdAt}
                                          </span>
                                        </div>

                                        {orders[0].tableName && (
                                          <div className="col-6">
                                            <span className="table-type">
                                              <b>{t("table_no")} - </b>{" "}
                                              {orders[0].tableName}
                                            </span>
                                          </div>
                                        )}

                                        <div className="col-6">
                                          <span className="table-type">
                                            <b>{t("lable_type")} - </b>{" "}
                                            {orders[0].tableType}
                                          </span>
                                        </div>

                                        {(orders[0].payment === "5" ||
                                          orders[0].payment === "6") && (
                                          <div className="col-6">
                                            <span className="table-type">
                                              <b>
                                                {t("zomato_swiggy_price")} -{" "}
                                              </b>
                                              ₹
                                              {orders[0].zomatoSwiggyPrice ||
                                                "0.00"}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                      {orders[0].phoneNumber !== "N/A" &&
                                        orders[0].phoneNumber !==
                                          "8155006366" && (
                                          <span className="table-type">
                                            <IoLogoWhatsapp
                                              className="icon-color"
                                              onClick={() =>
                                                generatePDFAndUpload(orders[0])
                                              }
                                            />
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <CustomTable
                                  page="order"
                                  // customerOrder={true}
                                  showKey={showKey}
                                  showActions={showActions}
                                  showTooltip={true}
                                  data={orders}
                                  heading={columns}
                                  checkBool={false}
                                  styleData={styleData}
                                />
                                {orders[0]?.instructions &&
                                  orders[0].instructions.toLowerCase() !==
                                    "null" && (
                                    <div>
                                      <HiMiniInformationCircle className="i-icon-class" />
                                      &nbsp;{orders[0].instructions}
                                    </div>
                                  )}
                                {orders[0].productData &&
                                  orders[0].productData.length > 0 &&
                                  orders.map((order, productIndex) => (
                                    <div className="col-12" key={productIndex}>
                                      <Accordion
                                        defaultActiveKey={["0"]}
                                        className="product-accordion"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header className="p-relative-1">
                                            <div
                                              className="product-detail-1"
                                              style={{ fontSize: "18px" }}
                                            >
                                              <IoIosArrowForward />
                                              {t("lable_product_details")}
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <CustomTable
                                              page="order"
                                              showKey={showProductKeys}
                                              showActions={false}
                                              showTooltip={true}
                                              data={order.productData}
                                              heading={productHeading}
                                              newOrderData={order}
                                              styleData={styleProductData}
                                            />
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                  ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))}
                        {customerOrders.items[0]?.totalRecords > 10 && (
                          <CustomPagination
                            totalRecords={customerOrders.items[0].totalRecords}
                            currentPage={currentPageNumber}
                            onPageChange={onPageChange}
                          />
                        )}
                      </>
                    ) : (
                      <div className="input-search">
                        <div className="no-record">
                          <h3 data-bs-toggle="tooltip" title="No records!">
                            {t("no_records")}
                          </h3>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : activeTab === t("Basic Details") ? (
              <>
                <Formik
                  initialValues={initialValuesBasicDetails}
                  validationSchema={basicDetailValidationSchema}
                  onSubmit={handleBasicDetailSumbit}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form>
                        <div className="add-roles-form row">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    {t("first_name")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="firstName"
                                  // label="Name"
                                  placeholder={t("placeholder_name")}
                                  className="add-roles"
                                  Regex={/[^a-zA-Z\s]/g}
                                  value={values.firstName}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    {t("last_name")}
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="lastName"
                                  placeholder={t("placeholder_last_name")}
                                  className="add-roles"
                                  Regex={/[^a-zA-Z\s]/g}
                                  value={values.lastName}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  {t("lable_date_of_birth")}
                                </h4>
                              </div>
                              <div className="paragraph-editor">
                                <div className="date-picker">
                                  <div className="row">
                                    <div className="col-12 datepicker-width">
                                      <DatePicker
                                        selected={values.DOB}
                                        onChange={(date) => {
                                          setFieldValue("DOB", date);
                                        }}
                                        placeholderText="Filter By Date"
                                        showIcon
                                        maxDate={new Date()}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                      <ErrorMessage
                                        name="DOB"
                                        component={ErrorComp}
                                      ></ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  {t("lable_aniversery_date")}
                                </h4>
                              </div>
                              <div className="paragraph-editor">
                                <div className="date-picker">
                                  <div className="row">
                                    <div className="col-12 datepicker-width">
                                      <DatePicker
                                        selected={values.aniverseryDate}
                                        onChange={(date) => {
                                          setFieldValue("aniverseryDate", date);
                                        }}
                                        placeholderText={t(
                                          "placeholder_filter_by_date"
                                        )}
                                        showIcon
                                        maxDate={new Date()}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                      <ErrorMessage
                                        name="aniverseryDate"
                                        component={ErrorComp}
                                      ></ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {discountOption.length !== 0 && (
                              <div className="col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      {t("addOrders.Discount")}
                                    </h4>
                                  </div>
                                  <Field
                                    className="basic-single"
                                    name="discounts"
                                    options={discountOption}
                                    component={CustomSelect}
                                    placeholder={t("placeholder_Select")}
                                    isMulti={true}
                                    value={values.discounts}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                            )}

                            {Data && Data.isEdit !== false ? (
                              <div className="col-md-6">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    {t("lable_profile_image")}
                                  </h4>
                                </div>
                                <Field
                                  id="upload"
                                  name="profileImg"
                                  maxCount={1}
                                  component={FileInput}
                                  placeholder={t("no_image_selected")}
                                  accept=".jpg, .jpeg, .png, .webp"
                                  className="upload"
                                  fileValue={values.profileImg}
                                />
                              </div>
                            ) : (
                              <>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      {t("lable_profile_image")}
                                    </h4>
                                  </div>
                                  {typeof values.img === "string" &&
                                  values.img.length !== 0 ? (
                                    <img
                                      className="profile-img"
                                      src={values.img}
                                      alt="Profile Preview"
                                    />
                                  ) : (
                                    <Field
                                      id="upload"
                                      name="profileImg"
                                      maxCount={1}
                                      component={FileInput}
                                      placeholder={t("no_image_selected")}
                                      accept=".jpg, .jpeg, .png, .webp"
                                      className="upload"
                                      fileValue={values.profileImg}
                                      disabled={true}
                                    />
                                  )}
                                </div>
                              </>
                            )}
                            {Data && Data.isEdit !== false && (
                              <div className="col-12">
                                <div className="paragraph-editor">
                                  <div className="btn-submit mt-3">
                                    <button
                                      type="submit"
                                      className="btn login-btn"
                                    >
                                      {t("button_save")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            ) : activeTab === t("Notes") ? (
              <>
                {customerNote &&
                  customerNote.items[0]?.data &&
                  customerNote.items[0].data && (
                    <>
                      <div className="btn-groups crm-btn-group">
                        {Data &&
                          Data.isEdit === true &&
                          (!notesAddBool ? (
                            <Button
                              data-bs-toggle="tooltip"
                              title="Add Customer Note Item"
                              onClick={handleAddBtn}
                              className="btn btn-fillter-main crm-filter"
                              name={
                                <div className="Plus">
                                  <FaPlus />
                                </div>
                              }
                            ></Button>
                          ) : (
                            <Button
                              data-bs-toggle="tooltip"
                              title="Remove Customer Note Item"
                              onClick={handleAddBtn}
                              className="btn btn-fillter-main crm-filter"
                              name={
                                <div className="Plus">
                                  <FaMinus />
                                </div>
                              }
                            ></Button>
                          ))}
                        {Data && Data.isEdit === false && isViewMode && (
                          <Button
                            data-bs-toggle="tooltip"
                            title="Remove Customer Note Item"
                            onClick={handleAddBtn}
                            className="btn btn-fillter-main crm-filter"
                            name={
                              <div className="Plus">
                                <FaMinus />
                              </div>
                            }
                          ></Button>
                        )}
                      </div>
                      {notesAddBool && (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={handleNotesSumbit}
                          enableReinitialize={true}
                          className="crm-notes-form"
                        >
                          {({ values, setFieldValue }) => {
                            return (
                              <Form>
                                <div className="add-roles-form-crm row">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          {isViewMode
                                            ? t("View_Note")
                                            : t("Enter_Note")}
                                        </h4>
                                      </div>
                                      <textarea
                                        name="note"
                                        value={values.note}
                                        placeholder={t("Enter_Note")}
                                        className="crm-textarea"
                                        disabled={isViewMode} // Disable textarea in view mode
                                        onChange={(event) =>
                                          setFieldValue(
                                            "note",
                                            event.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    {!isViewMode && ( // Hide the button in view mode
                                      <div className="col-12 notes-btn">
                                        <div className="btn-submit mt-3">
                                          <button
                                            type="submit"
                                            className="btn login-btn"
                                          >
                                            {t("button_save")}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      )}
                      {notesData.length > 0 ? (
                        <div className="row crm-container">
                          {notesData.map((note, index) => (
                            <div
                              className="col-lg-4 col-sm-6 col-12 mb-3"
                              key={index}
                            >
                              <div className="note">
                                <div className="note-header">
                                  <h5 className="note-date">
                                    {t("lable_note")}
                                  </h5>
                                  <div className="note-actions">
                                    <FaEye
                                      data-bs-toggle={"tooltip"}
                                      title={"view"}
                                      className="icon edit-icon"
                                      onClick={() => handleView(note)}
                                    />
                                    {Data && Data.isEdit === true && (
                                      <>
                                        <MdEdit
                                          data-bs-toggle={"tooltip"}
                                          title={"edit"}
                                          className="icon edit-icon"
                                          onClick={() => handleEdit(note)}
                                        />
                                        <MdDelete
                                          data-bs-toggle={"tooltip"}
                                          title={"delete"}
                                          className="icon delete-icon"
                                          onClick={() => handleDelete(note)}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="note-body">
                                  <div>
                                    <p>{note.note}</p>
                                  </div>
                                  <div>
                                    <p className="info">
                                      {t("lable_created_at")} {note.date}
                                    </p>
                                    <p className="info">
                                      {t("lable_created_by")}{" "}
                                      {note.created_name}
                                    </p>
                                    {note.modified_name && (
                                      <p className="info">
                                        {t("lable_updated_by")}{" "}
                                        {note.modified_name}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="input-search">
                          <div className="no-record">
                            <h3 data-bs-toggle="tooltip" title="No records!">
                              {t("no_records")}
                            </h3>
                          </div>
                        </div>
                      )}

                      {customerNote.items[0]?.data &&
                        customerNote.items[0].data.length > 0 &&
                        customerNote.items[0].total_records > 0 &&
                        customerNote.items[0].total_records >
                          customerNote.items[0].data.length &&
                        customerNote.items[0].total_records > 10 && (
                          <CustomPagination
                            totalRecords={customerNote.items[0].total_records}
                            currentPage={currentPgNoCustomerNote}
                            onPageChange={onPageCustomerNoteChange}
                            page="notes"
                          />
                        )}
                    </>
                  )}
              </>
            ) : activeTab === t("Preferences") ? (
              <>
                {preferences &&
                  preferences.items[0]?.data &&
                  preferences.items[0].data && (
                    <>
                      <div className="btn-groups crm-btn-group">
                        {preferencesFilter.p_product_name !== "" &&
                          preferencesFilter.p_product_name !== null && (
                            <Dropdown className="new-header-dropdown action-btn">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                data-bs-toggle="tooltip"
                                title="Action"
                              >
                                <GrMoreVertical />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="crm-clear-filter">
                                <Dropdown.Item onClick={clearPreferenceFilters}>
                                  {t("clear_filters")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        <Button
                          onClick={handlePrefShow}
                          data-bs-toggle="tooltip"
                          title="Filter"
                          // onClick={handleButtonClick}
                          className="btn btn-fillter-main crm-filter"
                          name={
                            <div className="filter">
                              <FaFilter className="filter-icon" />
                            </div>
                          }
                        ></Button>
                        <Offcanvas
                          show={show}
                          onHide={handlePrefClose}
                          placement="end"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              {t("filter_options")}
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Filter
                              page="preferences"
                              handleClose={handlePrefClose}
                              userId={Data.data.userId}
                            ></Filter>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                      <CustomTable
                        page="preferences"
                        showKey={showPreferencesKey}
                        showActions={false}
                        checkBool={false}
                        showTooltip={true}
                        data={preferencesData}
                        heading={preferencesHeading}
                        styleData={PreferencesStyleData}
                      />
                      {preferences.items[0]?.data &&
                        preferences.items[0].data.length > 0 &&
                        preferences.items[0].total_records > 0 &&
                        preferences.items[0].total_records >
                          preferences.items[0].data.length &&
                        preferences.items[0].total_records > 10 && (
                          <CustomPagination
                            totalRecords={preferences.items[0].total_records}
                            currentPage={currentPgNoPreferences}
                            onPageChange={onPagePreferenceChange}
                          />
                        )}
                    </>
                  )}
              </>
            ) : activeTab === t("All_Preferences") ? (
              <>
                {allPreferences &&
                  allPreferences.items[0]?.data &&
                  allPreferences.items[0].data && (
                    <>
                      <div className="btn-groups crm-btn-group">
                        {allPreferencesFilter.p_product_name !== "" &&
                          allPreferencesFilter.p_product_name !== null && (
                            <Dropdown className="new-header-dropdown action-btn">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                data-bs-toggle="tooltip"
                                title="Action"
                              >
                                <GrMoreVertical />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="crm-clear-filter">
                                <Dropdown.Item
                                  onClick={clearAllPreferenceFilters}
                                >
                                  {t("clear_filters")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        <Button
                          onClick={handleAllPrefShow}
                          data-bs-toggle="tooltip"
                          title="Filter"
                          // onClick={handleButtonClick}
                          className="btn btn-fillter-main crm-filter"
                          name={
                            <div className="filter">
                              <FaFilter className="filter-icon" />
                            </div>
                          }
                        ></Button>
                        <Offcanvas
                          show={showAll}
                          onHide={handleAllPrefClose}
                          placement="end"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              {t("filter_options")}
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Filter
                              page="allPreferences"
                              handleClose={handleAllPrefClose}
                              userId={Data.data.userId}
                            ></Filter>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                      <CustomTable
                        page="Allpreferences"
                        showKey={showAllPreferencesKey}
                        showActions={false}
                        checkBool={false}
                        showTooltip={true}
                        data={allPreferencesData}
                        heading={AllPreferencesHeading}
                        styleData={AllPreferencesStyleData}
                      />
                      {allPreferences.items[0]?.data &&
                        allPreferences.items[0].data.length > 0 &&
                        allPreferences.items[0].total_records > 0 &&
                        allPreferences.items[0].total_records >
                          allPreferences.items[0].data.length &&
                        allPreferences.items[0].total_records > 10 && (
                          <CustomPagination
                            totalRecords={allPreferences.items[0].total_records}
                            currentPage={currentPgNoAllPref}
                            onPageChange={onPageAllPrefChange}
                          />
                        )}
                    </>
                  )}
              </>
            ) : (
              <>
                {customerAnalytics && (
                  <>
                    <div className="btn-groups crm-btn-group-analytics">
                      <div className="d-flex flex-wrap">
                        <div className="btn btn-fillter-main crm-filter mb-2">
                          {analyticsDates.analyticsStartDate ===
                          analyticsDates.analyticsEndDate ? (
                            <>
                              {t("lable_analytics_of")}{" "}
                              {analyticsDates.analyticsStartDate}
                            </>
                          ) : (
                            <>
                              {t("lable_analytics_from")}:{" "}
                              {analyticsDates.analyticsStartDate} to{" "}
                              {analyticsDates.analyticsEndDate}
                            </>
                          )}
                        </div>
                        <div className="btn btn-fillter-main crm-filter mb-2">
                          {t("lable_total_orders")}:{" "}
                          {analyticsSummary.analyticsOrders}
                        </div>
                        <div className="btn btn-fillter-main crm-filter mb-2">
                          {t("lable_amount_spent")}: ₹
                          {analyticsSummary.analyticsAmt}
                        </div>
                        {Number(analyticsSummary.upi) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.UPI")}: ₹{analyticsSummary.upi}
                          </div>
                        )}
                        {Number(analyticsSummary.card) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.Card")}: ₹{analyticsSummary.card}
                          </div>
                        )}
                        {Number(analyticsSummary.cash) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.Cash")}: ₹{analyticsSummary.cash}
                          </div>
                        )}
                        {Number(analyticsSummary.zomato) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.Zomato")}: ₹
                            {analyticsSummary.zomato}
                          </div>
                        )}
                        {Number(analyticsSummary.swiggy) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.Swiggy")}: ₹
                            {analyticsSummary.swiggy}
                          </div>
                        )}
                        {Number(analyticsSummary.other) !== 0 && (
                          <div className="btn btn-fillter-main crm-filter mb-2">
                            {t("paymentOption.Other")}: ₹
                            {analyticsSummary.other}
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        {((customerAnalyticsFilter.StartDate !== "" &&
                          customerAnalyticsFilter.StartDate !== null &&
                          new Date(
                            formatDateToYYYYMMDD(
                              customerAnalyticsFilter.StartDate
                            )
                          ).toDateString() !==
                            new Date(
                              new Date().setMonth(new Date().getMonth() - 6)
                            ).toDateString()) ||
                          (customerAnalyticsFilter.EndDate !== "" &&
                            customerAnalyticsFilter.EndDate !== null &&
                            new Date(
                              formatDateToYYYYMMDD(
                                customerAnalyticsFilter.EndDate
                              )
                            ).toDateString() !==
                              new Date().toDateString())) && (
                          <Dropdown className="new-header-dropdown action-btn">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              data-bs-toggle="tooltip"
                              title="Action"
                            >
                              <GrMoreVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="crm-clear-filter">
                              <Dropdown.Item
                                onClick={clearCustomerAnalyticsFilters}
                              >
                                {t("clear_filters")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <Button
                          onClick={handleAnalyticsShow}
                          data-bs-toggle="tooltip"
                          title="Filter"
                          // onClick={handleButtonClick}
                          className="btn btn-fillter-main crm-filter"
                          name={
                            <div className="filter">
                              <FaFilter className="filter-icon" />
                            </div>
                          }
                        ></Button>
                      </div>
                    </div>

                    <Offcanvas
                      show={showAnalytics}
                      onHide={handleAnalyticsClose}
                      placement="end"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t("filter_options")}</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Filter
                          page="analytics"
                          handleClose={handleAnalyticsClose}
                          userId={Data.data.userId}
                        ></Filter>
                      </Offcanvas.Body>
                    </Offcanvas>
                    {customerAnalytics.items[0]?.data &&
                    customerAnalytics.items[0].data &&
                    customerAnalytics.items[0].data.length > 0 ? (
                      <div className="chart">
                        <Bar data={barChartData} options={chartOptions} />
                      </div>
                    ) : (
                      <div className="input-search">
                        <div className="no-record">
                          <h3 data-bs-toggle="tooltip" title="No records!">
                            {t("no_records")}
                          </h3>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="tab-content"></div>
        )}
      </div>
    </div>
  );
}

export default CRM;

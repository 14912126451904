import { CLEAR_EXPENSE_REPORT_FILTER, GET_EXPENSE_REPORT_FILTER } from "../../ReduxKeys";

/**
 * set expense graph filter data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getExpenseGraphFilterData = ({
  startDate,
  endDate,
  category,
}) => ({
  type: GET_EXPENSE_REPORT_FILTER,
  payload: { startDate, endDate, category },
});

/**
 * clear expense filtered data
 * @returns {*}
 */
export const clearExpenseGraphFilterData = () => ({
  type: CLEAR_EXPENSE_REPORT_FILTER,
});
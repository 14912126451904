import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileIcon from "../../assets/images/profile-icon.jpg";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertMessage } from "../Alertmessage/Alertmessage";
import Notification from "../Notification/Notification";
import { store } from "../../redux/Store";
import { FaShoppingCart } from "react-icons/fa";
import BranchService from "../../pages/Branches/BranchService/BranchService";
import UtilsData from "../../Utils/Utils";
import { Formik, Form, Field } from "formik";
import CustomSelect from "../CustomSelect/CustomSelect";
import BranchTokenServices from "./BranchTokenService/BranchTokenService";
import { orderDate } from "../../services/Regular-services/DateService";
import { clearOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";
import { setLocalization } from "../../redux/Actions/LanguageAction/LocalizationAction";
import OrderServices from "../../pages/Order/OrderService/OrderService";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { getAllBranchService } = BranchService();
  const { getAllOrderService } = OrderServices();
  const { GetBranchTokenService } = BranchTokenServices();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.order);
  const totalBranch = useSelector((state) => state.totalBranch);
  const localisation = useSelector((state) => state.localization?.items);
  const [branchOptions, setBranchOptions] = useState([]);
  const { handleLogoutClick } = AlertMessage();
  const [initialValues, setInitialValues] = useState({
    branch: "",
  });
  const token = UtilsData();
  const user = useSelector((state) => state.auth?.user);
  const walletTotalAmt = useSelector((state) => state.walletTotalAmt?.items);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(localisation);
    setSelectedLanguage(localisation);
    // eslint-disable-next-line
  }, [localisation]);

  useEffect(() => {
    if (user && user?.data) {
      setInitialValues({
        branch: user.data?.branchId ? user.data?.branchId : "",
      });
    }
  }, [user]);

  const fetchOrder = (tokenData) => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_created_date: orderDate(),
    };
    dispatch(clearOrderFilterData());
    getAllOrderService(requestBody, tokenData, dispatch).finally(() =>
      window.location.reload()
    );
  };

  const onLogout = () => {
    handleLogoutClick(dispatch, navigate, store);
  };

  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllBranchService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBranch && totalBranch.items && totalBranch.items.length === 0) {
      fetchBranch();
    } else {
      if (totalBranch.items[0]?.data && totalBranch.items[0].data.length > 0) {
        const newBranch = totalBranch.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.branchId,
        }));
        setBranchOptions(newBranch);
      }
    }
    // eslint-disable-next-line
  }, [totalBranch]);

  const onChangeBranch = async (value) => {
    if (user && user?.data && user.data?.phoneNumber) {
      if (user.data?.branchId && value === user.data.branchId) {
        return;
      }
      const requestBody = {
        p_branch_id: value,
        phone_number: user.data.phoneNumber,
      };
      await GetBranchTokenService(requestBody, token, dispatch).then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === "200" ||
            res.status === "success")
        ) {
          fetchOrder(res.token);
        }
      });
    }
  };

  const [branchWp, setBranchWp] = useState("0");
  useEffect(() => {
    const data = user.data;
    setBranchWp(data.branchWpSmsAllow);
  }, [user]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    dispatch(setLocalization(lng));
  };

  return (
    <>
      <div className="header-dropdown">
        <div className="mt-3">
          <Formik initialValues={initialValues} enableReinitialize={true}>
            {({ values }) => (
              <Form>
                <Field
                  className={`basic-single ${
                    branchWp !== "0" &&
                    walletTotalAmt[0] &&
                    walletTotalAmt[0]?.data &&
                    Number(walletTotalAmt[0]?.data.amount) / 100 <= 2
                      ? "wallet-dropdown"
                      : ""
                  } `}
                  name="branch"
                  options={branchOptions}
                  component={CustomSelect}
                  placeholder="Select branch"
                  value={values.branch}
                  changeData={onChangeBranch}
                />
              </Form>
            )}
          </Formik>
        </div>

        <div className="d-flex align-items-center">
          <div className="shopping-icon">
            <FaShoppingCart onClick={() => navigate("/orders")} />
            {order?.dataCount > 0 && (
              <div className="shopping-count">
                <span>{order.dataCount}</span>
              </div>
            )}
          </div>
          <Notification />
          <div className="Language">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="Language-ui"
              >
                <span>
                  {selectedLanguage && selectedLanguage.includes("en")
                    ? "English"
                    : selectedLanguage && selectedLanguage.includes("hi")
                    ? "Hindi" : selectedLanguage && selectedLanguage.includes("mr")
                    ? "Marathi"
                    : "Gujarati"}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage("en")}>
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("hi")}>
                  Hindi
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("gr")}>
                  Gujarati
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("mr")}>
                  Marathi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="profile">
                <img src={ProfileIcon} alt="" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onLogout}>{t("logout")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

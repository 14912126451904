import {
  CLEAR_PACKAGER_DATA,
  CLEAR_TOTAL_PACKAGER_DATA,
  GET_PACKAGER_DATA,
  GET_TOTAL_PACKAGER_DATA,
} from "../../ReduxKeys";

/**
 * Set packager list data into reducer
 * @param {*} Packager
 * @returns {*}
 */
export const getPackagerData = (Packager) => ({
  type: GET_PACKAGER_DATA,
  payload: Packager,
});

/**
 * clear packager list data
 * @returns {*}
 */
export const clearPackagerData = () => ({
  type: CLEAR_PACKAGER_DATA,
});

/**
 * Set total packager list data into reducer (No pagination)
 * @param {*} Packager
 * @returns {*}
 */
export const getTotalPackagerData = (Packager) => ({
  type: GET_TOTAL_PACKAGER_DATA,
  payload: Packager,
});

/**
 * Clear total packager list data (No pagination)
 * @param {*} Packager
 * @returns {*}
 */
export const clearTotalPackagerData = () => ({
  type: CLEAR_TOTAL_PACKAGER_DATA,
});
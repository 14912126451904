import { CLEAR_SETTLEMENT_DATA, GET_SETTLEMENT_DATA } from "../../ReduxKeys";

const settlementInitialState = {
  items: [],
};

/**
 * Store settlement list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const SettlementReducer = (state = settlementInitialState, action) => {
  switch (action.type) {
    case GET_SETTLEMENT_DATA:
      return {
        ...state,
        items: [action.payload],
      };
    case CLEAR_SETTLEMENT_DATA:
      return settlementInitialState;
    default:
      return state;
  }
};
import { Dispatch } from "redux";
import {
  ServiceProviderDetailResponse,
  ServiceProviderResponse,
  addUpdateServiceProviderRequest,
  getAllServiceProviderRequest,
} from "./ServiceProvicerModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  clearServiceProviderData,
  clearTotalServiceProviderData,
  getServiceProviderData,
  getTotalServiceProviderData,
} from "../../../redux/Actions/ServiceProviderAction/ServiceProviderAction";
import { toast } from "react-toastify";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearServiceProviderPageData,
  getServiceProviderPageData,
} from "../../../redux/Actions/ServiceProviderAction/ServiceProviderPageAction";
import Swal from "sweetalert2";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function ServiceProviderServices() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get service provider list
   */
  const getAllServiceProviderService = (
    subCategoryListRequest: getAllServiceProviderRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<ServiceProviderResponse> => {
    return new Promise<ServiceProviderResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          subCategoryListRequest.p_skip !== undefined &&
          subCategoryListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${subCategoryListRequest.p_skip}&`;
        }
        if (
          subCategoryListRequest.p_limit !== undefined &&
          subCategoryListRequest.p_limit !== null
        ) {
          queryParams += `p_limit=${subCategoryListRequest.p_limit}&`;
        }
        if (
          subCategoryListRequest.p_name !== undefined &&
          subCategoryListRequest.p_name !== null
        ) {
          queryParams += `p_name=${subCategoryListRequest.p_name}&`;
        }
        if (
          subCategoryListRequest.p_is_active !== undefined &&
          subCategoryListRequest.p_is_active !== null
        ) {
          queryParams += `p_is_active=${subCategoryListRequest.p_is_active}&`;
        }
        if (
          subCategoryListRequest.p_IsDeleted !== undefined &&
          subCategoryListRequest.p_IsDeleted !== null
        ) {
          queryParams += `p_IsDeleted=${subCategoryListRequest.p_IsDeleted}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.SERVICE_PROVIDER +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalServiceProviderData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getServiceProviderData(response));
          } else {
            dispatch(getServiceProviderData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for deleting service provider
   */
  const deleteServiceProviderService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.SERVICE_PROVIDER + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearServiceProviderData());
          dispatch(clearTotalServiceProviderData());
          toast.success("Service provider deleted successfully!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getServiceProviderPageData(pageNumber));
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for adding and updating service provider
   */
  const addUpdateServiceProviderService = (
    addUpdateBranchRequest: addUpdateServiceProviderRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.SERVICE_PROVIDER,
            token,
            addUpdateBranchRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.SERVICE_PROVIDER,
            token,
            addUpdateBranchRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearServiceProviderData());
          dispatch(clearTotalServiceProviderData());
          if (type === "add") {
            dispatch(clearServiceProviderPageData());
            toast.success(
              response?.message
                ? response.message
                : "Service Provider added successfully!"
            );
            navigate("/service-provider");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Service Provider undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getServiceProviderPageData(pageNumber + 1));
                } else {
                  dispatch(clearServiceProviderPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Service provider updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/service-provider");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Service provider status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getServiceProviderPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual service provider
   */
  const getServiceProviderById = (
    id: number,
    token: string,
    dispatch: Dispatch
  ): Promise<ServiceProviderDetailResponse> => {
    return new Promise<ServiceProviderDetailResponse>(
      async (resolve, reject) => {
        try {
          dispatch(setLoader());
          const response = await getApiService(
            API_URL.BASE_URL + API_URL.SERVICE_PROVIDER + "/" + id,
            token,
            null,
            localisation
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === "200" ||
              response.status === "success")
          ) {
            resolve(response);
          } else {
            if (!document.querySelector(toastClass)) {
              toast.error(
                response?.message
                  ? response.message
                  : t(ValidationMessage.SOMETHING_WENT_WRONG)
              );
            }
            errorShow = true;
            if (
              response?.message &&
              response.message === UnauthorizeAccessMessage
            ) {
              removePersistStorage(dispatch);
            }
          }
          resolve(response);
        } catch (error: any) {
          if (error?.message === t(UnauthorizeAccessMessage)) {
            removePersistStorage(dispatch);
          }
          if (errorShow === false && !document.querySelector(toastClass)) {
            toast.error(
              error?.message
                ? error.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
        } finally {
          errorShow = false;
          dispatch(clearLoader());
        }
      }
    );
  };

  /*
   * Service for uploading excel
   */
  const uploadServiceProviderExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append("excel", excelRequest);

      try {
        dispatch(setLoader());
        const response = await fetch(
          API_URL.BASE_URL + API_URL.SERVICE_PROVIDER_UPLOAD,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Language": localisation ? localisation : "en",
              "accept-language": localisation ? localisation : "en",
            },
            body: formData,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          if (data?.message && data.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          return data;
        } else {
          const data = await response.json();
          toast.success(
            data?.message
              ? data.message
              : "Service provider imported successfully"
          );
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error: any) {
        if (error?.message === t(UnauthorizeAccessMessage)) {
          removePersistStorage(dispatch);
        }
        if (errorShow === false && !document.querySelector(toastClass)) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  return {
    getAllServiceProviderService,
    deleteServiceProviderService,
    addUpdateServiceProviderService,
    getServiceProviderById,
    uploadServiceProviderExcelService,
  };
}
import { Dispatch } from "redux";
import {
  addUpdateBrandRequest,
  getAllBrandRequest,
  getAllBrandResponse,
  getBrandByIdResponse,
} from "./BrandModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  clearBrandData,
  clearTotalBrandData,
  getBrandData,
  getTotalBrandData,
} from "../../../redux/Actions/brandAction/BrandActions";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearBrandPageData,
  getBrandPageData,
} from "../../../redux/Actions/brandAction/BrandPageAction";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function BrandService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Service to get brand list
   */
  const getAllBrandService = (
    brandListRequest: getAllBrandRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getAllBrandResponse> => {
    return new Promise<getAllBrandResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(brandListRequest)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.BRANDS +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalBrandData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getBrandData(response));
          } else {
            dispatch(getBrandData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating brand
   */
  const addUpdateBrandService = (
    addUpdateBrandRequest: addUpdateBrandRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.BRANDS,
            token,
            addUpdateBrandRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.BRANDS,
            token,
            addUpdateBrandRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearBrandData());
          dispatch(clearTotalBrandData());
          if (type === "add") {
            dispatch(clearBrandPageData());
            toast.success(
              response?.message ? response.message : "Brand added successfully!"
            );
            navigate("/brand-list");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Brand undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getBrandPageData(pageNumber + 1));
                } else {
                  dispatch(clearBrandPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Brand updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/brand-list");
                });
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Brand status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getBrandPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting brand
   */
  const deleteBrandService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.BRANDS + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearBrandData());
          dispatch(clearTotalBrandData());
          toast.success(
            response?.message ? response.message : "Brand deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getBrandPageData(pageNumber));
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service to get individual brand
   */
  const getBrandById = (
    id: string,
    token: string,
    dispatch: Dispatch
  ): Promise<getBrandByIdResponse> => {
    return new Promise<getBrandByIdResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.BRANDS + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for uploading excel
   */
  const uploadBrandExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append("excel", excelRequest);

      try {
        dispatch(setLoader());
        const response = await fetch(API_URL.BASE_URL + API_URL.BRAND_UPLOAD, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Language": localisation ? localisation : "en",
            "accept-language": localisation ? localisation : "en",
          },
          body: formData,
        });
        if (!response.ok) {
          const data = await response.json();
          toast.error(
            data?.message
              ? data.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          if (data?.message && data.message === UnauthorizeAccessMessage) {
            removePersistStorage(dispatch);
          }
          return data;
          // throw new Error('Network response was not ok');
        } else {
          const data = await response.json();
          toast.success(
            data?.message ? data.message : "Brand imported successfully"
          );
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  return {
    getAllBrandService,
    addUpdateBrandService,
    deleteBrandService,
    getBrandById,
    uploadBrandExcelService,
  };
}
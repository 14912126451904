import React from "react";
import "./Subscribers.scss";
import CustomTable from "../../components/Table/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import ExcelData from "../../services/Regular-services/ExcelExport";

const columns = [
  {
    name: "Email",
    selector: (row) => row.Email,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row) => row.Created,
    sortable: true,
  },
  {
    name: "Is subscribe",
    selector: (row) => row.Subscribe,
    sortable: true,
  },
];

const data = [
  {
    Email: "fdsfsdf@sfsf.com",
    Created: "Feb 15, 2024",
    Subscribe: "Active",
  },
  {
    Email: "fdsfsdfdsad@sfsf.com",
    Created: "Feb 14, 2024",
    Subscribe: "Active",
  },
];

//Show keys which is to be displayed in table
const showKey = {
  Email: true,
  Created: true,
  Subscribe: true,
};

export default function Subscribers() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        {/* <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>Subscriber List</h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Subscriber List</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="btn-groups">
              <ExcelData excelData={data} fileName={"subscriber"}></ExcelData>
              {/* <Button data-bs-toggle='tooltip' title='Export' className="btn btn-fillter-main" name={<div className="filter">
                  <SiMicrosoftexcel className="filter-icon" />
                </div>}></Button> */}
            </div>
          </div>
          <div className="news-letter">News Letter Subscribers</div>
          <CustomTable
            showKey={showKey}
            showTooltip={true}
            data={data}
            heading={columns}
          />
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

import { CLEAR_CUSTOMER_FILTER, GET_CUSTOMER_FILTER } from "../../ReduxKeys";

const CustomerFilterInitialState = {
  phone: "",
};

/**
 * Store CUSTOMER filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerFilterReducer = (
  state = CustomerFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_FILTER:
      return {
        ...state,
        phone: action.payload.phone,
      };
    case CLEAR_CUSTOMER_FILTER:
      return CustomerFilterInitialState;
    default:
      return state;
  }
};
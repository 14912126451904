import {
  CLEAR_SUB_CATEGORY_PAGE,
  GET_SUB_CATEGORY_PAGE,
} from "../../ReduxKeys";

const subCategoryPageInitialState = {
  items: 1,
};

/**
 * Store sub-category page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const SubCategoryPageReducer = (
  state = subCategoryPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_SUB_CATEGORY_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_SUB_CATEGORY_PAGE:
      return subCategoryPageInitialState;
    default:
      return state;
  }
};
import {
  CLEAR_COMPANY_DATA,
  CLEAR_TOTAL_COMPANY_DATA,
  GET_COMPANY_DATA,
  GET_TOTAL_COMPANY_DATA,
} from "../../ReduxKeys";

const companyInitialState = {
  items: [],
};

/**
 * Store role list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */

export const CompanyReducer = (state = companyInitialState, action) => {
  switch (action.type) {
    case GET_COMPANY_DATA:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_COMPANY_DATA:
      return companyInitialState;
    default:
      return state;
  }
};

const totalCompanyInitialState = {
  items: [],
};

/**
 * Store total role list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */

export const TotalCompanyReducer = (
  state = totalCompanyInitialState,
  action
) => {
  switch (action.type) {
    case GET_TOTAL_COMPANY_DATA:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_TOTAL_COMPANY_DATA:
      return totalCompanyInitialState;
    default:
      return state;
  }
};
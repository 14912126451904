import { CLEAR_PREFERENCE_PAGE, GET_PREFERENCE_PAGE } from "../../ReduxKeys";

const preferencesPageInitialState = {
  items: 1,
};

/**
 * Store Preferences page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const PreferencesPageReducer = (
  state = preferencesPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_PREFERENCE_PAGE:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_PREFERENCE_PAGE:
      return preferencesPageInitialState;
    default:
      return state;
  }
};
import { CLEAR_CUSTOMER_FILTER, GET_CUSTOMER_FILTER } from "../../ReduxKeys";

/**
 * Set customer filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getCustomerFilterData = ({ phone }) => ({
  type: GET_CUSTOMER_FILTER,
  payload: { phone },
});

/**
 * Clear customer filtered data
 * @returns {*}
 */
export const clearCustomerFilterData = () => ({
  type: CLEAR_CUSTOMER_FILTER,
});

import { CLEAR_EVENT_LOG_DATA, GET_EVENT_LOG_DATA } from "../../ReduxKeys";

/**
 * Setting event list data into reducer
 * @param {*} sales
 * @returns {*}
 */
export const getEventLogData = (sales) => ({
  type: GET_EVENT_LOG_DATA,
  payload: sales,
});

/**
 * clearing event list data
 * @returns {*}
 */
export const clearEventLogData = () => ({
  type: CLEAR_EVENT_LOG_DATA,
});
import {
  CLEAR_INVENTORY_DATA,
  CLEAR_TOTAL_INVENTORY_DATA,
  GET_INVENTORY_DATA,
  GET_TOTAL_INVENTORY_DATA,
} from "../../ReduxKeys";

/**
 * Set inventory list data into reducer
 * @param {*} data
 * @returns {*}
 */
export const getInventoryData = (data) => ({
  type: GET_INVENTORY_DATA,
  payload: data,
});

/**
 * clear inventory list data
 * @returns {*}
 */
export const clearInventoryData = () => ({
  type: CLEAR_INVENTORY_DATA,
});

/**
 * Set total inventory list data into reducer (No pagination)
 * @param {*} data
 * @returns {*}
 */
export const getTotalInventoryData = (data) => ({
  type: GET_TOTAL_INVENTORY_DATA,
  payload: data,
});

/**
 * Clear total innventory list data (No pagination)
 * @returns {*}
 */
export const clearTotalInventoryData = () => ({
  type: CLEAR_TOTAL_INVENTORY_DATA,
});
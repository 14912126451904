import React, { useEffect, useState } from "react";
import UtilsData, {
  getRouteNameFromSidebar,
  ValidationMessage,
} from "../../../Utils/Utils";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Button from "../../../components/Button/Button";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import { clearStockPageData } from "../../../redux/Actions/StocksAction/StockPageAction";
import { modifyString } from "../../../services/Regular-services/StringService";
import { useTranslation } from "react-i18next";
import IngredientService from "../../InventoryItems/InventoryItemService/InventoryItemService";
import AddRemainingStockServices from "./AddRemainingStockService/AddRemainingStockService/AddRemainingStockService";

const AddRemainingStock = () => {
  const { getAllInventoryItemService } = IngredientService();
  const { addUpdateRemainingStockService } = AddRemainingStockServices();
  const { t } = useTranslation();
  const initialValues = {
    name: "",
    quantity: "",
    unit: "",
    stockTypeOption: "",
    instruction: "",
    // status: 1,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t(ValidationMessage.NAME_REQUIRED)),
    quantity: Yup.string().required(t(ValidationMessage.QUANTITY_REQUIRED)),
    unit: Yup.string().required(t(ValidationMessage.UNIT_REQUIRED)),
    stockTypeOption: Yup.string().required(t(ValidationMessage.STOCK_TYPE)),
  });

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};
  const { handleCancleClick, handlesaveClick } = AlertMessage();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const localisation = useSelector((state) => state.localization?.items);
  const token = UtilsData();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [stockActionData, setStockActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "stock"
      );
      setStockActionData(actionData);
    }
  }, [sideBarData]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);

  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    if (
      totalUnitsDropdown &&
      totalUnitsDropdown?.items &&
      totalUnitsDropdown.items.length > 0 &&
      totalUnitsDropdown.items[0]?.data &&
      totalUnitsDropdown.items[0].data.length > 0
    ) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
        id: unit.id,
      }));
      setUnitOptions(unitData);
    } else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown]);

  const stockTypeOption = [
    { value: "0", label: "Add Stock" },
    { value: "1", label: "Use Stock" },
    { value: "2", label: "Waste Stock" },
  ];
  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [inventoryItemOptions, setInventoryItemOptions] = useState([]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalInventoryItem &&
      totalInventoryItem.items &&
      totalInventoryItem.items.length === 0
    ) {
      fetchInventoryItem();
    } else {
      if (
        totalInventoryItem.items[0]?.data &&
        totalInventoryItem.items[0].data.length > 0
      ) {
        const newInventoryItem = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
            quantity: item?.thresoldValue,
            unit: item?.thresoldUnit,
          })
        );
        setInventoryItemOptions(newInventoryItem);
      }
    }
    // eslint-disable-next-line
  }, [totalInventoryItem]);

  const handleIngredientChange = (selectedOption, setFieldValue) => {
    if (!selectedOption) return;
    else if (selectedOption) {
      let quantityInOriginalUnit = parseFloat(selectedOption.quantity);
      let unit = selectedOption.unit;

      if (
        unit === "gm" ||
        unit === "kg" ||
        unit === "l" ||
        unit === "ml" ||
        unit === "mg"
      ) {
        if (unit === "gm" && quantityInOriginalUnit > 999) {
          quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
          unit = "kg";
        } else if (unit === "gm" && quantityInOriginalUnit < 1) {
          quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
          unit = "mg";
        } else if (unit === "ml" && quantityInOriginalUnit > 999) {
          quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
          unit = "l";
        }
      }

      setFieldValue("name", selectedOption.value);
      setFieldValue("unit", unit);
      setFieldValue("quantity", "");
    }
  };

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    let { quantityInBaseUnit, unit } = convertToBaseUnit(
      values.quantity,
      values.unit
    );
    const requestBody = {
      p_ingredient_id: values.name.toString(),
      p_value:
        values.stockTypeOption === "0"
          ? quantityInBaseUnit
          : "-" + quantityInBaseUnit,
      p_unit: unit,
      p_type: values.stockTypeOption,
      p_instruction: values.instruction,
    };
    dispatch(clearStockPageData());
    if (!Data) {
      dispatch(
        addUpdateRemainingStockService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdateRemainingStockService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {stockActionData && stockActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          stockActionData[0].name,
                          localisation
                        )
                      )
                    : t("Live Stock")}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  {t("Home")}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/stock")}>
                  {stockActionData && stockActionData.length > 0
                    ? getRouteNameFromSidebar(
                        stockActionData[0].name,
                        localisation
                      )
                    : t("Live Stock")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? t("actions.edit")
                    : Data && Data.isEdit === false
                    ? t("actions.view")
                    : t("actions.add")}{" "}
                  {stockActionData && stockActionData.length > 0
                    ? modifyString(
                        getRouteNameFromSidebar(
                          stockActionData[0].name,
                          localisation
                        )
                      )
                    : t("Live Stock")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? t("actions.edit")
                : Data && Data.isEdit === false
                ? t("actions.view")
                : t("actions.add")}{" "}
              {stockActionData && stockActionData.length > 0
                ? modifyString(
                    getRouteNameFromSidebar(
                      stockActionData[0].name,
                      localisation
                    )
                  )
                : t("Live Stock")}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-4">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_Name")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="name"
                        options={inventoryItemOptions}
                        component={CustomSelect}
                        placeholder={t("Placeholder_select_Ingredient")}
                        isMulti={false}
                        value={values.name}
                        disabled={Data && Data.isEdit === false}
                        styles={customStyles}
                        onchangedata={(selectedOption) =>
                          handleIngredientChange(selectedOption, setFieldValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_quantity")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="quantity"
                        label="Quantity"
                        placeholder={t("Placeholder_enter_quantity")}
                        className="add-roles"
                        Regex={/[^0-9]/g}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_unit")}{" "}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="unit"
                        options={unitOptions}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.unit}
                        disabled={Data && Data.isEdit === false}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("stock_type")}
                          <span className="required-label"></span>
                        </h4>
                      </div>
                      <Field
                        className="select-menu mb-2"
                        name="stockTypeOption"
                        options={stockTypeOption}
                        component={CustomSelect}
                        placeholder={t("placeholder_Select")}
                        isMulti={false}
                        value={values.unit}
                        disabled={Data && Data.isEdit === false}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="pb-3 col-md-4">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          {t("lable_instructions")}
                        </h4>
                      </div>
                      <div className="message-input">
                        <textarea
                          value={message}
                          placeholder={t("Placeholder_generalized_instruction")}
                          onChange={(event) => setMessage(event.target.value)}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name={t("button_save")}
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name={t("button_cancel")}
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/stock", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default AddRemainingStock;

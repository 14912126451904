import {
  CLEAR_TOTAL_UNIT_DATA,
  CLEAR_UNIT_DATA,
  GET_TOTAL_UNIT_DATA,
  GET_UNIT_DATA,
} from "../../ReduxKeys";

/**
 * Setting units list data into reducer
 * @param {*} Units
 * @returns {*}
 */
export const getUnitsData = (units) => ({
  type: GET_UNIT_DATA,
  payload: units,
});

/**
 * clearing units list data
 * @returns {*}
 */
export const clearUnitsData = () => ({
  type: CLEAR_UNIT_DATA,
});

/**
 * Setting total units list data into reducer (No pagnation)
 * @param {*} units
 * @returns {*}
 */
export const getTotalUnitsData = (units) => ({
  type: GET_TOTAL_UNIT_DATA,
  payload: units,
});

/**
 * Clearing total units list data (No pagination)
 * @returns {*}
 */
export const clearTotalUnitsData = () => ({
  type: CLEAR_TOTAL_UNIT_DATA,
});
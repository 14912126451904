import { Dispatch } from "redux";
import {
  addUpdateBranchRequest,
  branchDetailResponse,
  getBranchListRequest,
  getBranchListResponse,
} from "./BranchModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  UnauthorizeAccessMessage,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import {
  clearBranchData,
  clearTotalBranchData,
  getBranchData,
  getTotalBranchData,
} from "../../../redux/Actions/BranchAction/BranchAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearBranchPageData,
  getBranchPageData,
} from "../../../redux/Actions/BranchAction/BranchPageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearEventLogData } from "../../../redux/Actions/EventLogAction/EventLogAction";
import { clearEventLogPageData } from "../../../redux/Actions/EventLogAction/EventLogPageAction";
import { clearEventLogFilterData } from "../../../redux/Actions/EventLogAction/EventLogFilterAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

let errorShow = false;

export default function BranchService() {
  const localisation = useSelector((state: any) => state.localization?.items);
  const { t } = useTranslation() as any;
  /*
   * Get branch list service
   */
  const getAllBranchService = (
    branchListRequest: getBranchListRequest,
    token: string,
    dispatch: Dispatch,
    total?: any
  ): Promise<getBranchListResponse> => {
    return new Promise<getBranchListResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const queryParams = Object.entries(branchListRequest)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.BRANCH +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalBranchData(response));
          } else if (total && total === "filter") {
            toast.success(t(ValidationMessage.FILTER_SUCCESS));
            dispatch(getBranchData(response));
          } else {
            dispatch(getBranchData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  /*
   * Service for adding and updating branch
   */
  const addUpdateBranchService = (
    addUpdateBranchRequest: addUpdateBranchRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number,
    brachReqBody?: any,
    updateBranch?: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.BRANCH,
            token,
            addUpdateBranchRequest,
            localisation
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.BRANCH,
            token,
            addUpdateBranchRequest,
            localisation
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearBranchData());
          dispatch(clearTotalBranchData());
          if (type === "add") {
            dispatch(clearBranchPageData());
            toast.success(
              response?.message
                ? response.message
                : "Branch added successfully!"
            );
            navigate("/branch");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success(
                response?.message
                  ? response.message
                  : "Branch undeleted successfully!"
              );
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getBranchPageData(pageNumber + 1));
                } else {
                  dispatch(clearBranchPageData());
                }
                Swal.fire({
                  title: t("Saved!"),
                  confirmButtonText: t("OK"),
                  text: response?.message
                    ? response.message
                    : "Branch updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/branch");
                });
                if (updateBranch) {
                  updateBranch(brachReqBody);
                }
              } else {
                toast.success(
                  response?.message
                    ? response.message
                    : "Branch status updated successfully!"
                );
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          if (pageNumber) {
            dispatch(getBranchPageData(pageNumber));
          }
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for deleting branch
   */
  const deleteBranchService = (
    deleteId: number,
    token: string,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        const response = await deleteApiService(
          API_URL.BASE_URL + API_URL.BRANCH + "/" + deleteId,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearEventLogFilterData());
          dispatch(clearEventLogPageData());
          dispatch(clearEventLogData());
          dispatch(clearBranchData());
          dispatch(clearTotalBranchData());
          toast.success(
            response?.message
              ? response.message
              : "Branch deleted successfully!"
          );
        } else {
          toast.error(
            response?.message
              ? response.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
          dispatch(getBranchPageData(pageNumber));
        }
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };

  /*
   * Service for getting a branch
   */
  const getBranchById = (
    id: number,
    token: string,
    dispatch: Dispatch
  ): Promise<branchDetailResponse> => {
    return new Promise<branchDetailResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(
          API_URL.BASE_URL + API_URL.BRANCH + "/" + id,
          token,
          null,
          localisation
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          resolve(response);
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message
                : t(ValidationMessage.SOMETHING_WENT_WRONG)
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === UnauthorizeAccessMessage
          ) {
            removePersistStorage(dispatch);
          }
        }
        resolve(response);
      } catch (error: any) {
        if (errorShow === false) {
          toast.error(
            error?.message
              ? error.message
              : t(ValidationMessage.SOMETHING_WENT_WRONG)
          );
        }
        if (error?.message === UnauthorizeAccessMessage) {
          removePersistStorage(dispatch);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };

  return {
    getAllBranchService,
    addUpdateBranchService,
    deleteBranchService,
    getBranchById,
  };
}
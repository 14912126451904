export const businessTypeOption = [
  { value: "proprietorship", label: "Proprietorship" },
  { value: "llp", label: "LLP" },
  { value: "partnership", label: "Partnership" },
  // { value: "ngo", label: "NGO" },
  // { value: "indivvalueual", label: "Indivvalueual" },
  // { value: "public_limited", label: "Public Limited" },
  // { value: "private_limited", label: "Private Limited" },
  // { value: "trust", label: "Trust" },
  // { value: "educational_institutes", label: "Educational Institutes" },
  // { value: "not_yet_registered", label: "Not Yet Registered" },
  // { value: "other", label: "Other" },
];


export const categoryOptionArray = [
  { value: "financial_services", label: "Financial services" },
  { value: "education", label: "Education" },
  { value: "healthcare", label: "Healthcare" },
  { value: "utilities", label: "Utilities" },
  { value: "government", label: "Government" },
  { value: "logistics", label: "Logistics" },
  { value: "tours_and_travel", label: "Tours and travel" },
  { value: "transport", label: "Transport" },
  { value: "ecommerce", label: "Ecommerce" },
  { value: "food", label: "Food" },
  { value: "it_and_software", label: "It and software" },
  { value: "gaming", label: "Gaming" },
  { value: "media_and_entertainment", label: "Media and entertainment" },
  { value: "services", label: "Services" },
  { value: "housing", label: "Housing" },
  { value: "not_for_profit", label: "Not for profit" },
  { value: "social", label: "Social" },
  { value: "others", label: "Others" },
];

export const subCategoryJson = {
  financial_services: [
    { value: "mutual_fund", label: "Mutual Fund" },
    { value: "lending", label: "Lending" },
    { value: "cryptocurrency", label: "Crypto Currency" },
    { value: "insurance", label: "Insurance" },
    { value: "nbfc", label: "Nbfc" },
    { value: "cooperatives", label: "Cooperatives" },
    { value: "pension_fund", label: "Pension Fund" },
    { value: "forex", label: "Forex" },
    { value: "securities", label: "Securities" },
    { value: "commodities", label: "Commodities" },
    { value: "accounting", label: "Accounting" },
    { value: "financial_advisor", label: "Financial Advisor" },
    { value: "crowdfunding", label: "Crowd Funding" },
    { value: "trading", label: "Trading" },
    { value: "betting", label: "Betting" },
    { value: "get_rich_schemes", label: "Get rich schemes" },
    { value: "moneysend_funding", label: "Moneysend Funding" },
    {
      value: "wire_transfers_and_money_orders",
      label: "Wire transfers and money orders",
    },
    { value: "tax_preparation_services", label: "Tax preparation services" },
    { value: "tax_payments", label: "Tax payments" },
    { value: "digital_goods", label: "Digital Goods" },
    { value: "atms", label: "ATMs" },
  ],
  education: [
    { value: "college", label: "College" },
    { value: "schools", label: "Schools" },
    { value: "university", label: "University" },
    { value: "professional_courses", label: "Professional Courses" },
    { value: "distance_learning", label: "Distance Learning" },
    { value: "day_care", label: "Day Care" },
    { value: "coaching", label: "Coaching" },
    { value: "elearning", label: "Elearning" },
    {
      value: "vocational_and_trade_schools",
      label: "Vocational and Trade Schools",
    },
    { value: "sporting_clubs", label: "Sporting Clubs" },
    {
      value: "dance_halls_studios_and_schools",
      label: "Dance Halls, Studios and Schools",
    },
    { value: "correspondence_schools", label: "Correspondence Schools" },
  ],
  healthcare: [
    { value: "pharmacy", label: "Pharmacy" },
    { value: "clinic", label: "Clinic" },
    { value: "hospital", label: "Hospital" },
    { value: "lab", label: "Lab" },
    { value: "dietician", label: "Dietician" },
    { value: "fitness", label: "Fitness" },
    { value: "health_coaching", label: "Health Coaching" },
    { value: "health_products", label: "Health Products" },
    { value: "drug_stores", label: "Drug Stores" },
    { value: "healthcare_marketplace", label: "Healthcare Marketplace" },
    { value: "osteopaths", label: "Osteopaths" },
    {
      value: "medical_equipment_and_supply_stores",
      label: "Medical Equipment and Supply Stores",
    },
    {
      value: "podiatrists_and_chiropodists",
      label: "Podiatrists and Chiropodists",
    },
    {
      value: "dentists_and_orthodontists",
      label: "Dentists and Orthodontists",
    },
    { value: "hardware_stores", label: "Hardware Stores" },
    { value: "ophthalmologists", label: "Ophthalmologists" },
    { value: "orthopedic_goods_stores", label: "Orthopedic Goods Stores" },
    { value: "testing_laboratories", label: "Testing Laboratories" },
    { value: "doctors", label: "Doctors" },
    {
      value: "health_practitioners_medical_services",
      label: "Health Practitioners and Medical Services",
    },
    { value: "testing_laboratories", label: "Testing Laboratories" },
  ],
  ecommerce: [
    { value: "ecommerce_marketplace", label: "Ecommerce Marketplace" },
    { value: "agriculture", label: "Agriculture" },
    { value: "books", label: "Books" },
    { value: "electronics_and_furniture", label: "Electronics and Furniture" },
    { value: "coupons", label: "Coupons" },
    { value: "rental", label: "Rental" },
    { value: "fashion_and_lifestyle", label: "Fashion and Lifestyle" },
    { value: "gifting", label: "Gifting" },
    { value: "grocery", label: "Grocery" },
    { value: "baby_products", label: "Baby Products" },
    { value: "office_supplies", label: "Office Supplies" },
    { value: "wholesale", label: "Wholesale" },
    { value: "religious_products", label: "Religious Products" },
    { value: "pet_products", label: "Pet Products" },
    { value: "sports_products", label: "Sports Products" },
    { value: "arts_and_collectibles", label: "Arts and Collectibles" },
    { value: "sexual_wellness_products", label: "Sexual Wellness Products" },
    { value: "drop_shipping", label: "Drop Shipping" },
    { value: "crypto_machinery", label: "Crypto Machinery" },
    { value: "tobacco", label: "Tobacco" },
    { value: "weapons_and_ammunitions", label: "Weapons and Ammunitions" },
    { value: "stamps_and_coins_stores", label: "Stamps and Coins Stores" },
    { value: "office_equipment", label: "Office Equipment" },
    {
      value: "automobile_parts_and_equipements",
      label: "Automobile Parts and Equipments",
    },
    { value: "garden_supply_stores", label: "Garden Supply Stores" },
    {
      value: "household_appliance_stores",
      label: "Household Appliance Stores",
    },
    { value: "non_durable_goods", label: "Non Durable Goods" },
    { value: "pawn_shops", label: "Pawn Shops" },
    {
      value: "electrical_parts_and_equipment",
      label: "Electrical Parts and Equipment",
    },
    { value: "wig_and_toupee_shops", label: "Wig and Toupee Shops" },
    {
      value: "gift_novelty_and_souvenir_shops",
      label: "Gift, Novelty, and Souvenir Shops",
    },
    { value: "duty_free_stores", label: "Duty Free Stores" },
    {
      value: "office_and_commercial_furniture",
      label: "Office and Commercial Furniture",
    },
    { value: "dry_goods", label: "Dry Goods" },
    { value: "books_and_publications", label: "Books and Publications" },
    {
      value: "camera_and_photographic_stores",
      label: "Camera and Photographic Stores",
    },
    { value: "record_shops", label: "Record Shops" },
    { value: "meat_supply_stores", label: "Meat Supply Stores" },
    { value: "leather_goods_and_luggage", label: "Leather Goods and Luggage" },
    { value: "snowmobile_dealers", label: "Snowmobile Dealers" },
    {
      value: "men_and_boys_clothing_stores",
      label: "Men and Boys Clothing Stores",
    },
    { value: "paint_supply_stores", label: "Paint Supply Stores" },
    { value: "automotive_parts", label: "Automotive Parts" },
    {
      value: "jewellery_and_watch_stores",
      label: "Jewellery and Watch Stores",
    },
    {
      value: "auto_store_home_supply_stores",
      label: "Auto Store Home Supply Stores",
    },
    { value: "tent_stores", label: "Tent Stores" },
    { value: "shoe_stores_retail", label: "Shoe Stores Retail" },
    {
      value: "petroleum_and_petroleum_products",
      label: "Petroleum and Petroleum Products",
    },
    { value: "department_stores", label: "Department Stores" },
    { value: "automotive_tire_stores", label: "Automotive Tire Stores" },
    { value: "sport_apparel_stores", label: "Sport Apparel Stores" },
    { value: "variety_stores", label: "Variety Stores" },
    {
      value: "chemicals_and_allied_products",
      label: "Chemicals and Allied Products",
    },
    { value: "commercial_equipments", label: "Commercial Equipments" },
    {
      value: "fireplace_parts_and_accessories",
      label: "Fireplace Parts and Accessories",
    },
    { value: "family_clothing_stores", label: "Family Clothing Stores" },
    { value: "fabric_and_sewing_stores", label: "Fabric and Sewing Stores" },
    { value: "home_supply_warehouse", label: "Home Supply Warehouse" },
    { value: "art_supply_stores", label: "Art Supply Stores" },
    {
      value: "camper_recreational_and_utility_trailer_dealers",
      label: "Camper, Recreational, and Utility Trailer Dealers",
    },
    {
      value: "clocks_and_silverware_stores",
      label: "Clocks and Silverware Stores",
    },
    { value: "discount_stores", label: "Discount Stores" },
    {
      value: "school_supplies_and_stationery",
      label: "School Supplies and Stationery",
    },
    { value: "second_hand_stores", label: "Second Hand Stores" },
    {
      value: "watch_and_jewellery_repair_stores",
      label: "Watch and Jewellery Repair Stores",
    },
    { value: "liquor_stores", label: "Liquor Stores" },
    { value: "boat_dealers", label: "Boat Dealers" },
    {
      value: "opticians_optical_goods_and_eyeglasse_stores",
      label: "Opticians, Optical Goods, and Eyeglasses Stores",
    },
    { value: "wholesale_footwear_stores", label: "Wholesale Footwear Stores" },
    { value: "cosmetic_stores", label: "Cosmetic Stores" },
    { value: "home_furnishing_stores", label: "Home Furnishing Stores" },
    { value: "antique_stores", label: "Antique Stores" },
    {
      value: "plumbing_and_heating_equipment",
      label: "Plumbing and Heating Equipment",
    },
    {
      value: "telecommunication_equipment_stores",
      label: "Telecommunication Equipment Stores",
    },
    { value: "women_clothing", label: "Women Clothing" },
    { value: "florists", label: "Florists" },
    { value: "computer_software_stores", label: "Computer Software Stores" },
    { value: "building_matrial_stores", label: "Building Material Stores" },
    {
      value: "candy_nut_confectionery_shops",
      label: "Candy, Nut, and Confectionery Shops",
    },
    {
      value: "glass_and_wallpaper_stores",
      label: "Glass and Wallpaper Stores",
    },
    {
      value: "commercial_photography_and_graphic_design_services",
      label: "Commercial Photography and Graphic Design Services",
    },
    {
      value: "vvalueeo_game_supply_stores",
      label: "Vvalueeo Game Supply Stores",
    },
    { value: "fuel_dealers", label: "Fuel Dealers" },
    {
      value: "drapery_and_window_coverings_stores",
      label: "Drapery and Window Coverings Stores",
    },
    { value: "hearing_avalues_stores", label: "Hearing Avalues Stores" },
    { value: "automotive_paint_shops", label: "Automotive Paint Shops" },
    { value: "durable_goods_stores", label: "Durable Goods Stores" },
    {
      value: "uniforms_and_commercial_clothing_stores",
      label: "Uniforms and Commercial Clothing Stores",
    },
    { value: "fur_shops", label: "Fur Shops" },
    { value: "industrial_supplies", label: "Industrial Supplies" },
    { value: "bicycle_stores", label: "Bicycle Stores" },
    { value: "second_hand_stores", label: "Second Hand Stores" },
    {
      value: "motorcycle_shops_and_dealers",
      label: "Motorcycle Shops and Dealers",
    },
    {
      value: "children_and_infants_wear_stores",
      label: "Children and Infants Wear Stores",
    },
    { value: "women_accessory_stores", label: "Women Accessory Stores" },
    { value: "construction_materials", label: "Construction Materials" },
    {
      value: "books_periodicals_and_newspaper",
      label: "Books, Periodicals, and Newspaper",
    },
    { value: "floor_covering_stores", label: "Floor Covering Stores" },
    {
      value: "crystal_and_glassware_stores",
      label: "Crystal and Glassware Stores",
    },
    {
      value: "accessory_and_apparel_stores",
      label: "Accessory and Apparel Stores",
    },
    {
      value: "hardware_equipment_and_supply_stores",
      label: "Hardware Equipment and Supply Stores",
    },
    {
      value: "computers_peripheral_equipment_software",
      label: "Computers, Peripheral Equipment, and Software",
    },
    {
      value: "automobile_and_truck_dealers",
      label: "Automobile and Truck Dealers",
    },
    {
      value: "aircraft_and_farm_equipment_dealers",
      label: "Aircraft and Farm Equipment Dealers",
    },
    {
      value: "antique_shops_sales_and_repairs",
      label: "Antique Shops, Sales, and Repairs",
    },
    { value: "hearing_avalues_stores", label: "Hearing Avalues Stores" },
    { value: "music_stores", label: "Music Stores" },
    {
      value: "furniture_and_home_furnishing_store",
      label: "Furniture and Home Furnishing Store",
    },
  ],
  services: [
    { value: "repair_and_cleaning", label: "Repair and cleaning" },
    {
      value: "interior_design_and_architect",
      label: "Interior design and architect",
    },
    { value: "movers_and_packers", label: "Movers and packers" },
    { value: "legal", label: "Legal" },
    { value: "event_planning", label: "Event planning" },
    { value: "service_centre", label: "Service centre" },
    { value: "consulting", label: "Consulting" },
    { value: "ad_and_marketing", label: "Ad and marketing" },
    { value: "services_classifieds", label: "Services classifieds" },
    { value: "multi_level_marketing", label: "Multi level marketing" },
    { value: "construction_services", label: "Construction services" },
    { value: "architectural_services", label: "Architectural services" },
    { value: "car_washes", label: "Car washes" },
    { value: "motor_home_rentals", label: "Motor home rentals" },
    {
      value: "stenographic_and_secretarial_support_services",
      label: "Stenographic and secretarial support services",
    },
    { value: "chiropractors", label: "Chiropractors" },
    { value: "automotive_service_shops", label: "Automotive service shops" },
    { value: "shoe_repair_shops", label: "Shoe repair shops" },
    { value: "telecommunication_service", label: "Telecommunication service" },
    { value: "fines", label: "Fines" },
    { value: "security_agencies", label: "Security agencies" },
    { value: "tailors", label: "Tailors" },
    {
      value: "type_setting_and_engraving_services",
      label: "Type setting and engraving services",
    },
    {
      value: "small_appliance_repair_shops",
      label: "Small appliance repair shops",
    },
    { value: "photography_labs", label: "Photography labs" },
    { value: "dry_cleaners", label: "Dry cleaners" },
    { value: "massage_parlors", label: "Massage parlors" },
    { value: "electronic_repair_shops", label: "Electronic repair shops" },
    {
      value: "cleaning_and_sanitation_services",
      label: "Cleaning and sanitation services",
    },
    { value: "nursing_care_facilities", label: "Nursing care facilities" },
    { value: "direct_marketing", label: "Direct marketing" },
    { value: "lottery", label: "Lottery" },
    { value: "veterinary_services", label: "Veterinary services" },
    { value: "affliated_auto_rental", label: "Affiliated auto rental" },
    { value: "alimony_and_child_support", label: "Alimony and child support" },
    { value: "airport_flying_fields", label: "Airport flying fields" },
    { value: "golf_courses", label: "Golf courses" },
    {
      value: "tire_retreading_and_repair_shops",
      label: "Tire retreading and repair shops",
    },
    { value: "television_cable_services", label: "Television cable services" },
    {
      value: "recreational_and_sporting_camps",
      label: "Recreational and sporting camps",
    },
    { value: "barber_and_beauty_shops", label: "Barber and beauty shops" },
    { value: "agricultural_cooperatives", label: "Agricultural cooperatives" },
    { value: "carpentry_contractors", label: "Carpentry contractors" },
    {
      value: "wrecking_and_salvaging_services",
      label: "Wrecking and salvaging services",
    },
    {
      value: "automobile_towing_services",
      label: "Automobile towing services",
    },
    {
      value: "vvalueeo_tape_rental_stores",
      label: "Vvalueeo tape rental stores",
    },
    {
      value: "miscellaneous_repair_shops",
      label: "Miscellaneous repair shops",
    },
    { value: "motor_homes_and_parts", label: "Motor homes and parts" },
    { value: "horse_or_dog_racing", label: "Horse or dog racing" },
    { value: "laundry_services", label: "Laundry services" },
    { value: "electrical_contractors", label: "Electrical contractors" },
    {
      value: "debt_marriage_personal_counseling_service",
      label: "Debt marriage personal counseling service",
    },
    {
      value: "air_conditioning_and_refrigeration_repair_shops",
      label: "Air conditioning and refrigeration repair shops",
    },
    { value: "credit_reporting_agencies", label: "Credit reporting agencies" },
    {
      value: "heating_and_plumbing_contractors",
      label: "Heating and plumbing contractors",
    },
    {
      value: "carpet_and_upholstery_cleaning_services",
      label: "Carpet and upholstery cleaning services",
    },
    { value: "swimming_pools", label: "Swimming pools" },
    {
      value: "roofing_and_metal_work_contractors",
      label: "Roofing and metal work contractors",
    },
    {
      value: "internet_service_provvalueers",
      label: "Internet service provvalueers",
    },
    { value: "recreational_camps", label: "Recreational camps" },
    { value: "masonry_contractors", label: "Masonry contractors" },
    {
      value: "exterminating_and_disinfecting_services",
      label: "Exterminating and disinfecting services",
    },
    { value: "ambulance_services", label: "Ambulance services" },
    {
      value: "funeral_services_and_crematories",
      label: "Funeral services and crematories",
    },
    { value: "metal_service_centres", label: "Metal service centres" },
    {
      value: "copying_and_blueprinting_services",
      label: "Copying and blueprinting services",
    },
    { value: "fuel_dispensers", label: "Fuel dispensers" },
    { value: "welding_repair", label: "Welding repair" },
    { value: "mobile_home_dealers", label: "Mobile home dealers" },
    { value: "concrete_work_contractors", label: "Concrete work contractors" },
    { value: "boat_rentals", label: "Boat rentals" },
    {
      value: "personal_shoppers_and_shopping_clubs",
      label: "Personal shoppers and shopping clubs",
    },
    { value: "door_to_door_sales", label: "Door to door sales" },
    {
      value: "travel_related_direct_marketing",
      label: "Travel related direct marketing",
    },
    { value: "lottery_and_betting", label: "Lottery and betting" },
    {
      value: "bands_orchestras_and_miscellaneous_entertainers",
      label: "Bands orchestras and miscellaneous entertainers",
    },
    {
      value: "furniture_repair_and_refinishing",
      label: "Furniture repair and refinishing",
    },
    { value: "contractors", label: "Contractors" },
    {
      value: "direct_marketing_and_subscription_merchants",
      label: "Direct marketing and subscription merchants",
    },
    {
      value: "typewriter_stores_sales_service_and_rentals",
      label: "Typewriter stores sales service and rentals",
    },
    { value: "recreation_services", label: "Recreation services" },
    {
      value: "direct_marketing_insurance_services",
      label: "Direct marketing insurance services",
    },
    { value: "business_services", label: "Business services" },
    {
      value: "inbound_telemarketing_merchants",
      label: "Inbound telemarketing merchants",
    },
    { value: "public_warehousing", label: "Public warehousing" },
    {
      value: "outbound_telemarketing_merchants",
      label: "Outbound telemarketing merchants",
    },
    { value: "clothing_rental_stores", label: "Clothing rental stores" },
    { value: "transportation_services", label: "Transportation services" },
    { value: "electric_razor_stores", label: "Electric razor stores" },
    { value: "service_stations", label: "Service stations" },
    { value: "photographic_studio", label: "Photographic studio" },
    { value: "professional_services", label: "Professional services" },
  ],
  housing: [
    { value: "developer", label: "Developer" },
    { value: "facility_management", label: "Facility Management" },
    { value: "rwa", label: "RWA" },
    { value: "coworking", label: "Coworking" },
    { value: "realestate_classifieds", label: "Realestate Classifieds" },
    { value: "space_rental", label: "Space Rental" },
  ],
  not_for_profit: [
    { value: "charity", label: "Charity" },
    { value: "educational", label: "Educational" },
    { value: "religious", label: "Religious" },
    { value: "personal", label: "Personal" },
  ],
  social: [
    { value: "matchmaking", label: "Matchmaking" },
    { value: "social_network", label: "Social network" },
    { value: "messaging", label: "Messaging" },
    { value: "professional_network", label: "Professional network" },
    { value: "neighbourhood_network", label: "Neighbourhood network" },
    { value: "political_organizations", label: "Political organizations" },
    {
      value: "automobile_associations_and_clubs",
      label: "Automobile associations and clubs",
    },
    {
      value: "country_and_athletic_clubs",
      label: "Country and athletic clubs",
    },
    {
      value: "associations_and_membership",
      label: "Associations and membership",
    },
  ],
  media_and_entertainment: [
    { value: "vvalueeo_on_demand", label: "Vvalueeo on demand" },
    { value: "music_streaming", label: "Music streaming" },
    { value: "multiplex", label: "Multiplex" },
    { value: "content_and_publishing", label: "Content and publishing" },
    { value: "ticketing", label: "Ticketing" },
    { value: "news", label: "News" },
    { value: "vvalueeo_game_arcades", label: "Vvalueeo game arcades" },
    {
      value: "vvalueeo_tape_production_and_distribution",
      label: "Vvalueeo tape production and distribution",
    },
    { value: "bowling_alleys", label: "Bowling alleys" },
    {
      value: "billiard_and_pool_establishments",
      label: "Billiard and pool establishments",
    },
    {
      value: "amusement_parks_and_circuses",
      label: "Amusement parks and circuses",
    },
    { value: "ticket_agencies", label: "Ticket agencies" },
  ],
  gaming: [
    { value: "game_developer", label: "Game developer" },
    { value: "esports", label: "Esports" },
    { value: "online_casino", label: "Online casino" },
    { value: "fantasy_sports", label: "Fantasy sports" },
    { value: "gaming_marketplace", label: "Gaming marketplace" },
  ],
  it_and_software: [
    { value: "saas", label: "SaaS" },
    { value: "paas", label: "PaaS" },
    { value: "iaas", label: "IaaS" },
    {
      value: "consulting_and_outsourcing",
      label: "Consulting and outsourcing",
    },
    { value: "web_development", label: "Web development" },
    { value: "technical_support", label: "Technical support" },
    { value: "data_processing", label: "Data processing" },
  ],
  food: [
    { value: "online_food_ordering", label: "Online food ordering" },
    { value: "restaurant", label: "Restaurant" },
    { value: "food_court", label: "Food court" },
    { value: "catering", label: "Catering" },
    { value: "alcohol", label: "Alcohol" },
    {
      value: "restaurant_search_and_booking",
      label: "Restaurant search and booking",
    },
    { value: "dairy_products", label: "Dairy products" },
    { value: "bakeries", label: "Bakeries" },
  ],
  utilities: [
    { value: "electricity", label: "Electricity" },
    { value: "gas", label: "Gas" },
    { value: "telecom", label: "Telecom" },
    { value: "water", label: "Water" },
    { value: "cable", label: "Cable" },
    { value: "broadband", label: "Broadband" },
    { value: "dth", label: "DTH" },
    { value: "internet_provvalueer", label: "Internet provvalueer" },
    {
      value: "bill_and_recharge_aggregators",
      label: "Bill and recharge aggregators",
    },
  ],
  government: [
    { value: "central", label: "Central" },
    { value: "state", label: "State" },
    {
      value: "intra_government_purchases",
      label: "Intra government purchases",
    },
    { value: "goverment_postal_services", label: "Government postal services" },
  ],
  logistics: [
    { value: "freight", label: "Freight" },
    { value: "courier", label: "Courier" },
    { value: "warehousing", label: "Warehousing" },
    { value: "distribution", label: "Distribution" },
    { value: "end_to_end_logistics", label: "End to end logistics" },
    { value: "courier_services", label: "Courier services" },
  ],
  tours_and_travel: [
    { value: "aviation", label: "Aviation" },
    { value: "accommodation", label: "Accommodation" },
    { value: "ota", label: "Ota" },
    { value: "travel_agency", label: "Travel agency" },
    {
      value: "tourist_attractions_and_exhibits",
      label: "Tourist attractions and exhibits",
    },
    { value: "timeshares", label: "Timeshares" },
    {
      value: "aquariums_dolphinariums_and_seaquariums",
      label: "Aquariums, dolphinariums and seaquariums",
    },
  ],
  transport: [
    { value: "cab_hailing", label: "Cab hailing" },
    { value: "bus", label: "Bus" },
    { value: "train_and_metro", label: "Train and metro" },
    { value: "automobile_rentals", label: "Automobile rentals" },
    { value: "cruise_lines", label: "Cruise lines" },
    { value: "parking_lots_and_garages", label: "Parking lots and garages" },
    { value: "transportation", label: "Transportation" },
    { value: "brvaluege_and_road_tolls", label: "Brvaluege and road tolls" },
    { value: "freight_transport", label: "Freight transport" },
    {
      value: "truck_and_utility_trailer_rentals",
      label: "Truck and utility trailer rentals",
    },
  ],
};

export const countryJson = [
  { value: "IN", label: "India" },
  { value: "BD", label: "Bangladesh" },
  { value: "PK", label: "pakistan" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "GB", label: "United Kingdom" },
  { value: "AU", label: "Austrailia" },
  { value: "IT", label: "Italy" },
  { value: "ZA", label: "South Africa" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "VN", label: "Vietnam" },
  { value: "SA", label: "Saudi Arabia" },
];

export const accountDocuments = [
  {
    value: "shop_establishment_certificate",
    label: "Shop Establishment Certificate",
  },
  { value: "gst_certificate", label: "Gst Certificate" },
  { value: "msme_certificate", label: "Msme Certificate" },
  // { value: "business_proof_url", label: "Business Proof Url" },
  // { value: "business_pan_url", label: "Business Pan Url" },
];

export const individualIdentificationProof = [
  { value: "personal_pan", label: "personal_pan" },
];

export const parentAddressProofOptions = [
  { value: "voter", label: "Voter Card" },
  { value: "aadhar", label: "Aadhar Card" },
  { value: "passport", label: "Passport" },
];

export const proofOfAddressOptions = [
  { value: "voter_id_back", label: "Voter Card Back" },
  { value: "voter_id_front", label: "Voter Card Front" },
  { value: "aadhar_front", label: "Aadhar Card Front" },
  { value: "aadhar_back", label: "Aadhar Card Back" },
  { value: "passport_front", label: "Passport Front" },
  { value: "passport_back", label: "Passport Back" },
];

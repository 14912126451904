import React from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import { ValidationMessage } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { AlertMessage } from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

//Setting initial values
const initialValues = {
  tagTitle: "",
};

//Handling form submission
const handleSubmit = (values) => {
  console.log("value: ", values);
};

export default function AddTax() {
  const navigate = useNavigate();
  const { handleCancleClick } = AlertMessage();

  //Validation schema
const validationSchema = Yup.object({
  tagTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
});

  const CountryOptions = [
    { value: "India", label: "India" },
    { value: "UK", label: "Uk" },
  ];

  const stateOptions = [
    { value: "Gujarat", label: "Gujarat" },
    { value: "UP", label: "UP" },
  ];

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>Add Tax</h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/tax")}>
                  Tax
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Add Tax</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>Add Tax</h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="add-roles-form row">
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Tax name <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="tagTitle"
                      label="Tax name"
                      placeholder="Enter Tax name"
                      className="add-roles"
                      maxLength={20}
                      Regex={/[^a-zA-Z\s]/g}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Tax rate <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="tagTitle"
                      label="Tax rate"
                      placeholder="Enter Tax rate"
                      className="add-roles"
                      maxLength={20}
                      Regex={/[^0-9]/g}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      Select Country<span className="required-label"></span>
                    </h4>
                    <Field
                      className="select-menu mb-2"
                      name="Country"
                      options={CountryOptions}
                      component={CustomSelect}
                      placeholder="Select..."
                      isMulti={false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">
                      Select State<span className="required-label"></span>
                    </h4>
                    <Field
                      className="select-menu mb-2"
                      name="State"
                      options={stateOptions}
                      component={CustomSelect}
                      placeholder="Select..."
                      isMulti={false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Tax class<span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="tagTitle"
                      label="Tax class"
                      placeholder="Enter Tax class"
                      className="add-roles"
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Compound Tax<span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="tagTitle"
                      label="Compound Tax"
                      placeholder="Enter Compound Tax"
                      className="add-roles"
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">
                        Shipping Tax <span className="required-label"></span>
                      </h4>
                    </div>
                    <Input
                      type="text"
                      name="tagTitle"
                      label="Shipping Tax"
                      placeholder="Enter Shipping Tax"
                      className="add-roles"
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Status</h4>
                    <Field
                      name="active"
                      className="toggle"
                      component={Toggle}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <div className="btn-submit mt-3">
                      <Button
                        type="submit"
                        name="Save"
                        className="btn login-btn"
                      />
                      <Button
                        type="button"
                        name="Cancel"
                        className="btn clear-btn login-btn"
                        onClick={() => handleCancleClick("/tags", navigate)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        {/* <Footer />
        </div> */}
      </div>
    </>
  );
}

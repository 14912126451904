export const GET_ADMIN_FILTER = "GET_ADMIN_FILTER";
export const CLEAR_ADMIN_FILTER = "CLEAR_ADMIN_FILTER";
export const GET_ADMIN_DATA = "GET_ADMIN_DATA";
export const CLEAR_ADMIN_DATA = "CLEAR_ADMIN_DATA";
export const GET_TOTAL_ADMIN_DATA = "GET_TOTAL_ADMIN_DATA";
export const CLEAR_TOTAL_ADMIN_DATA = "CLEAR_TOTAL_ADMIN_DATA";
export const GET_USER_TYPE = "GET_USER_TYPE";
export const GET_ADMIN_PAGE = "GET_ADMIN_PAGE";
export const CLEAR_ADMIN_PAGE = "CLEAR_ADMIN_PAGE";

export const GET_ADMIN_PR_DATA = "GET_ADMIN_PR_DATA";
export const CLEAR_ADMIN_PR_DATA = "CLEAR_ADMIN_PR_DATA";
export const GET_TOTAL_ADMIN_PR = "GET_TOTAL_ADMIN_PR";
export const CLEAR_TOTAL_ADMIN_PR = "CLEAR_TOTAL_ADMIN_PR";

export const GET_SETTLEMENT_REPORT = "GET_SETTLEMENT_REPORT";
export const CLEAR_SETTLEMENT_REPORT = "CLEAR_SETTLEMENT_REPORT";
export const GET_SETTLEMENT_REPORT_FILTER = "GET_SETTLEMENT_REPORT_FILTER";
export const CLEAR_SETTLEMENT_REPORT_FILTER = "CLEAR_SETTLEMENT_REPORT_FILTER";

export const GET_SETTLEMENT_DATA = "GET_SETTLEMENT_DATA";
export const CLEAR_SETTLEMENT_DATA = "CLEAR_SETTLEMENT_DATA";

export const GET_MENU_DATA = "GET_MENU_DATA";
export const CLEAR_MENU_DATA = "CLEAR_MENU_DATA";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const UPDATE_AUTH = "UPDATE_AUTH";

export const GET_BRANCH_DATA = "GET_BRANCH_DATA";
export const CLEAR_BRANCH_DATA = "CLEAR_BRANCH_DATA";
export const GET_BRANCH_FILTER = "GET_BRANCH_FILTER";
export const CLEAR_BRANCH_FILTER = "CLEAR_BRANCH_FILTER";
export const GET_TOTAL_BRANCH = "GET_TOTAL_BRANCH";
export const CLEAR_TOTAL_BRANCH = "CLEAR_TOTAL_BRANCH";
export const GET_BRANCH_PAGE = "GET_BRANCH_PAGE";
export const CLEAR_BRANCH_PAGE = "CLEAR_BRANCH_PAGE";

export const GET_BRAND_DATA = "GET_BRAND_DATA";
export const CLEAR_BRAND_DATA = "CLEAR_BRAND_DATA";
export const GET_TOTAL_BRAND = "GET_TOTAL_BRAND";
export const CLEAR_TOTAL_BRAND = "CLEAR_TOTAL_BRAND";
export const GET_BRAND_FILTER = "GET_BRAND_FILTER";
export const CLEAR_BRAND_FILTER = "CLEAR_BRAND_FILTER";
export const GET_BRAND_PAGE = "GET_BRAND_PAGE";
export const CLEAR_BRAND_PAGE = "CLEAR_BRAND_PAGE";

export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA";
export const CLEAR_CATEGORY_DATA = "CLEAR_CATEGORY_DATA";
export const GET_TOTAL_CATEGORY = "GET_TOTAL_CATEGORY";
export const CLEAR_TOTAL_CATEGORY = "CLEAR_TOTAL_CATEGORY";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const CLEAR_ACTIVE_CATEGORY = "CLEAR_ACTIVE_CATEGORY";
export const GET_CATEGORY_FILTER = "GET_CATEGORY_FILTER";
export const CLEAR_CATEGORY_FILTER = "CLEAR_CATEGORY_FILTER";
export const GET_CATEGORY_PAGE = "GET_CATEGORY_PAGE";
export const CLEAR_CATEGORY_PAGE = "CLEAR_CATEGORY_PAGE";

export const GET_ACCOUNT_DETAIL_DATA = "GET_ACCOUNT_DETAIL_DATA";
export const CLEAR_ACCOUNT_DETAIL_DATA = "CLEAR_ACCOUNT_DETAIL_DATA";

export const GET_ACC_PRODUCT_CONFIG_DATA = "GET_ACC_PRODUCT_CONFIG_DATA";
export const CLEAR_ACC_PRODUCT_CONFIG_DATA = "CLEAR_ACC_PRODUCT_CONFIG_DATA";

export const GET_PAYMENT_DETAIL_DATA = "GET_PAYMENT_DETAIL_DATA";
export const CLEAR_PAYMENT_DETAIL_DATA = "CLEAR_PAYMENT_DETAIL_DATA";

export const GET_STAKEHOLDER_DETAIL_DATA = 'GET_STAKEHOLDER_DETAIL_DATA';
export const CLEAR_STAKEHOLDER_DETAIL_DATA = 'CLEAR_STAKEHOLDER_DETAIL_DATA';

export const GET_VERIFY_ACCOUNT_DATA = 'GET_VERIFY_ACCOUNT_DATA';
export const CLEAR_VERIFY_ACCOUNT_DATA = 'CLEAR_VERIFY_ACCOUNT_DATA';

export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA";
export const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";
export const GET_TOTAL_CUSTOMER = "GET_TOTAL_CUSTOMER";
export const CLEAR_TOTAL_CUSTOMER = "CLEAR_TOTAL_CUSTOMER";
export const SET_CUSTOMER_DETAIL_ID = "SET_CUSTOMER_DETAIL_ID";
export const CLEAR_CUSTOMER_DETAIL_ID = "CLEAR_CUSTOMER_DETAIL_ID";
export const GET_CUSTOMER_FILTER = "GET_CUSTOMER_FILTER";
export const CLEAR_CUSTOMER_FILTER = "CLEAR_CUSTOMER_FILTER";
export const GET_CUSTOMER_PAGE = "GET_CUSTOMER_PAGE";
export const CLEAR_CUSTOMER_PAGE = "CLEAR_CUSTOMER_PAGE";

export const GET_ALL_PREFERENCE_DATA = "GET_ALL_PREFERENCE_DATA";
export const CLEAR_ALL_PREFERENCE_DATA = "CLEAR_ALL_PREFERENCE_DATA";
export const GET_TOTAL_ALL_PREFERENCE = "GET_TOTAL_ALL_PREFERENCE";
export const CLEAR_TOTAL_ALL_PREFERENCE = "CLEAR_TOTAL_ALL_PREFERENCE";
export const SET_ALL_CUSTOMER_PREFERENCE_ID = "SET_ALL_CUSTOMER_PREFERENCE_ID";
export const CLEAR_ALL_CUSTOMER_PREFERENCE_ID =
  "CLEAR_ALL_CUSTOMER_PREFERENCE_ID";
export const GET_ALL_PREFERENCE_FILTER = "GET_ALL_PREFERENCE_FILTER";
export const CLEAR_ALL_PREFERENCE_FILTER = "CLEAR_ALL_PREFERENCE_FILTER";
export const GET_ALL_PREFERENCE_PAGE = "GET_ALL_PREFERENCE_PAGE";
export const CLEAR_ALL_PREFERENCE_PAGE = "CLEAR_ALL_PREFERENCE_PAGE";

export const GET_CUSTOMER_ANALYTICS = "GET_CUSTOMER_ANALYTICS";
export const CLEAR_CUSTOMER_ANALYTICS = "CLEAR_CUSTOMER_ANALYTICS";
export const GET_CUSTOMER_ANALYTICS_ID = "GET_CUSTOMER_ANALYTICS_ID";
export const CLEAR_CUSTOMER_ANALYTICS_ID = "CLEAR_CUSTOMER_ANALYTICS_ID";
export const GET_CUSTOMER_ANALYTICS_FILTER = "GET_CUSTOMER_ANALYTICS_FILTER";
export const CLEAR_CUSTOMER_ANALYTICS_FILTER =
  "CLEAR_CUSTOMER_ANALYTICS_FILTER";

export const GET_CUSTOMER_NOTES = "GET_CUSTOMER_NOTES";
export const CLEAR_CUSTOMER_NOTES = "CLEAR_CUSTOMER_NOTES";
export const GET_TOTAL_CUSTOMER_NOTES = "GET_TOTAL_CUSTOMER_NOTES";
export const CLEAR_TOTAL_CUSTOMER_NOTES = "CLEAR_TOTAL_CUSTOMER_NOTES";
export const SET_CUSTOMER_NOTE_ID = "SET_CUSTOMER_NOTE_ID";
export const CLEAR_CUSTOMER_NOTE_ID = "CLEAR_CUSTOMER_NOTE_ID";
export const GET_CUSTOMER_NOTE_PAGE = "GET_CUSTOMER_NOTE_PAGE";
export const CLEAR_CUSTOMER_NOTE_PAGE = "CLEAR_CUSTOMER_NOTE_PAGE";

export const GET_CUSTOMER_ORDER = "GET_CUSTOMER_ORDER";
export const CLEAR_CUSTOMER_ORDER = "CLEAR_CUSTOMER_ORDER";
export const GET_TOTAL_CUSTOMER_ORDER = "GET_TOTAL_CUSTOMER_ORDER";
export const CLEAR_TOTAL_CUSTOMER_ORDER = "CLEAR_TOTAL_CUSTOMER_ORDER";
export const SET_CUSTOMER_ORDER_ID = "SET_CUSTOMER_ORDER_ID";
export const CLEAR_CUSTOMER_ORDER_ID = "CLEAR_CUSTOMER_ORDER_ID";
export const GET_CUSTOMER_ORDER_FILTER = "GET_CUSTOMER_ORDER_FILTER";
export const CLEAR_CUSTOMER_ORDER_FILTER = "CLEAR_CUSTOMER_ORDER_FILTER";
export const GET_CUSTOMER_ORDER_PAGE = "GET_CUSTOMER_ORDER_PAGE";
export const CLEAR_CUSTOMER_ORDER_PAGE = "CLEAR_CUSTOMER_ORDER_PAGE";
export const ADD_CUSTOMER_ORDER = "ADD_CUSTOMER_ORDER";
export const UPDATE_CUSTOMER_ORDER_STATUS = "UPDATE_CUSTOMER_ORDER_STATUS";

export const GET_PREFERENCE_DATA = "GET_PREFERENCE_DATA";
export const CLEAR_PREFERENCE_DATA = "CLEAR_PREFERENCE_DATA";
export const GET_TOTAL_PREFERENCE = "GET_TOTAL_PREFERENCE";
export const CLEAR_TOTAL_PREFERENCE = "CLEAR_TOTAL_PREFERENCE";
export const SET_CUSTOMER_PREFERENCE_ID = "SET_CUSTOMER_PREFERENCE_ID";
export const CLEAR_CUSTOMER_PREFERENCE_ID = "CLEAR_CUSTOMER_PREFERENCE_ID";
export const GET_PREFERENCE_FILTER = "GET_PREFERENCE_FILTER";
export const CLEAR_PREFERENCE_FILTER = "CLEAR_PREFERENCE_FILTER";
export const GET_PREFERENCE_PAGE = "GET_PREFERENCE_PAGE";
export const CLEAR_PREFERENCE_PAGE = "CLEAR_PREFERENCE_PAGE";

export const GET_CUSTOMER_RUSH_DATA = "GET_CUSTOMER_RUSH_DATA";
export const CLEAR_CUSTOMER_RUSH_DATA = "CLEAR_CUSTOMER_RUSH_DATA";
export const GET_CUSTOMER_RUSH_FILTER = "GET_CUSTOMER_RUSH_FILTER";
export const CLEAR_CUSTOMER_RUSH_FILTER = "CLEAR_CUSTOMER_RUSH_FILTER";

export const GET_DASHBOARD_ORDER_DATA = "GET_DASHBOARD_ORDER_DATA";
export const CLEAR_DASHBOARD_ORDER_DATA = "CLEAR_DASHBOARD_ORDER_DATA";
export const ADD_DASHBOARD_ORDER = "ADD_DASHBOARD_ORDER";
export const UPDATE_DASHBOARD_ORDER_STATUS = "UPDATE_DASHBOARD_ORDER_STATUS";
export const GET_TOTAL_DASHBOARD_ORDER_DATA = "GET_TOTAL_DASHBOARD_ORDER_DATA";
export const CLEAR_TOTAL_DASHBOARD_ORDER_DATA =
  "CLEAR_TOTAL_DASHBOARD_ORDER_DATA";
export const GET_DASHBOARD_ORDER_FILTER = "GET_DASHBOARD_ORDER_FILTER";
export const CLEAR_DASHBOARD_ORDER_FILTER = "CLEAR_DASHBOARD_ORDER_FILTER";

export const CLEAR_LOCAL_ORDER_FILTER = "CLEAR_LOCAL_ORDER_FILTER";

export const SET_DINE_IN_DATA = "SET_DINE_IN_DATA";
export const CLEAR_DINE_IN_DATA = "CLEAR_DINE_IN_DATA";

export const GET_DISCOUNT_DATA = "GET_DISCOUNT_DATA";
export const CLEAR_DISCOUNT_DATA = "CLEAR_DISCOUNT_DATA";
export const GET_TOTAL_DISCOUNT_DATA = "GET_TOTAL_DISCOUNT_DATA";
export const CLEAR_TOTAL_DISCOUNT_DATA = "CLEAR_TOTAL_DISCOUNT_DATA";
export const GET_DISCOUNT_BY_CODE = "GET_DISCOUNT_BY_CODE";
export const CLEAR_DISCOUNT_BY_CODE = "CLEAR_DISCOUNT_BY_CODE";
export const GET_DISCOUNT_BRAND = "GET_DISCOUNT_BRAND";
export const CLEAR_DISCOUNT_BRAND = "CLEAR_DISCOUNT_BRAND";
export const GET_DISCOUNT_FILTER = "GET_DISCOUNT_FILTER";
export const CLEAR_DISCOUNT_FILTER = "CLEAR_DISCOUNT_FILTER";
export const GET_DISCOUNT_PAGE = "GET_DISCOUNT_PAGE";
export const CLEAR_DISCOUNT_PAGE = "CLEAR_DISCOUNT_PAGE";

export const GET_DISCOUNT_CATALOGUE_DATA = "GET_DISCOUNT_CATALOGUE_DATA";
export const CLEAR_DISCOUNT_CATALOGUE_DATA = "CLEAR_DISCOUNT_CATALOGUE_DATA";
export const GET_TOTAL_CATALOGUE_DISCOUNT = "GET_TOTAL_CATALOGUE_DISCOUNT";
export const CLEAR_TOTAL_CATALOGUE_DISCOUNT = "CLEAR_TOTAL_CATALOGUE_DISCOUNT";
export const GET_DISCOUNT_CATALOGUE_FILTER = "GET_DISCOUNT_CATALOGUE_FILTER";
export const CLEAR_DISCOUNT_CATALOGUE_FILTER =
  "CLEAR_DISCOUNT_CATALOGUE_FILTER";
export const GET_DISCOUNT_CATALOGUE_PAGE = "GET_DISCOUNT_CATALOGUE_PAGE";
export const CLEAR_DISCOUNT_CATALOGUE_PAGE = "CLEAR_DISCOUNT_CATALOGUE_PAGE";

export const GET_EVENT_LOG_DATA = "GET_EVENT_LOG_DATA";
export const CLEAR_EVENT_LOG_DATA = "CLEAR_EVENT_LOG_DATA";
export const GET_EVENT_LOG_FILTER = "GET_EVENT_LOG_FILTER";
export const CLEAR_EVENT_LOG_FILTER = "CLEAR_EVENT_LOG_FILTER";
export const GET_EVENT_LOG_PAGE = "GET_EVENT_LOG_PAGE";
export const CLEAR_EVENT_LOG_PAGE = "CLEAR_EVENT_LOG_PAGE";

export const GET_EXPENSE_DATA = "GET_EXPENSE_DATA";
export const CLEAR_EXPENSE_DATA = "CLEAR_EXPENSE_DATA";
export const GET_TOTAL_EXPENSE = "GET_TOTAL_EXPENSE";
export const CLEAR_TOTAL_EXPENSE = "CLEAR_TOTAL_EXPENSE";
export const GET_EXPENSE_FILTER = "GET_EXPENSE_FILTER";
export const CLEAR_EXPENSE_FILTER = "CLEAR_EXPENSE_FILTER";
export const GET_EXPENSE_PAGE = "GET_EXPENSE_PAGE";
export const CLEAR_EXPENSE_PAGE = "CLEAR_EXPENSE_PAGE";

export const GET_EXPENSE_REPORT = "GET_EXPENSE_REPORT";
export const CLEAR_EXPENSE_REPORT = "CLEAR_EXPENSE_REPORT";
export const GET_EXPENSE_REPORT_FILTER = "GET_EXPENSE_REPORT_FILTER";
export const CLEAR_EXPENSE_REPORT_FILTER = "CLEAR_EXPENSE_REPORT_FILTER";

export const GET_GRN_DATA = "GET_GRN_DATA";
export const CLEAR_GRN_DATA = "CLEAR_GRN_DATA";
export const GET_GRN_FILTER = "GET_GRN_FILTER";
export const CLEAR_GRN_FILTER = "CLEAR_GRN_FILTER";
export const GET_TOTAL_GRN = "GET_TOTAL_GRN";
export const CLEAR_TOTAL_GRN = "CLEAR_TOTAL_GRN";
export const GET_GRN_PAGE = "GET_GRN_PAGE";
export const CLEAR_GRN_PAGE = "CLEAR_GRN_PAGE";

export const GET_INVENTORY_ITEM_DATA = "GET_INVENTORY_ITEM_DATA";
export const CLEAR_INVENTORY_ITEM_DATA = "CLEAR_INVENTORY_ITEM_DATA";
export const GET_TOTAL_INVENTORY_ITEM = "GET_TOTAL_INVENTORY_ITEM";
export const CLEAR_TOTAL_INVENTORY_ITEM = "CLEAR_TOTAL_INVENTORY_ITEM";
export const GET_INVENTORY_ITEM_FILTER = "GET_INVENTORY_ITEM_FILTER";
export const CLEAR_INVENTORY_ITEM_FILTER = "CLEAR_INVENTORY_ITEM_FILTER";
export const GET_INVENTORY_ITEM_PAGE = "GET_INVENTORY_ITEM_PAGE";
export const CLEAR_INVENTORY_ITEM_PAGE = "CLEAR_INVENTORY_ITEM_PAGE";

export const SET_LOCALIZATION = "SET_LOCALIZATION";
export const CLEAR_LOCALIZATION = "CLEAR_LOCALIZATION";

export const GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA";
export const CLEAR_NOTIFICATION_DATA = "CLEAR_NOTIFICATION_DATA";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const SET_NOTIFICATION_LOADER = "SET_NOTIFICATION_LOADER";
export const CLEAR_NOTIFICATION_LOADER = "CLEAR_NOTIFICATION_LOADER";
export const SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND";

export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA";
export const CLEAR_PLACE_ORDER_UPDATE = "CLEAR_PLACE_ORDER_UPDATE";
export const ADD_ORDER = "ADD_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const PLACE_ORDER_UPDATE = "PLACE_ORDER_UPDATE";
export const GET_TOTAL_ORDER_DATA = "GET_TOTAL_ORDER_DATA";
export const CLEAR_TOTAL_ORDER_DATA = "CLEAR_TOTAL_ORDER_DATA";
export const GET_ORDER_FILTER = "GET_ORDER_FILTER";
export const CLEAR_ORDER_FILTER = "CLEAR_ORDER_FILTER";
export const GET_ORDER_PAGE = "GET_ORDER_PAGE";
export const CLEAR_ORDER_PAGE = "CLEAR_ORDER_PAGE";

export const GET_PO_USER_DATA = "GET_PO_USER_DATA";
export const CLEAR_PO_USER_DATA = "CLEAR_PO_USER_DATA";
export const GET_TOTAL_PO_USER_DATA = "GET_TOTAL_PO_USER_DATA";
export const CLEAR_TOTAL_PO_USER_DATA = "CLEAR_TOTAL_PO_USER_DATA";

export const GET_PRODUCT_DATA = "GET_PRODUCT_DATA";
export const CLEAR_PRODUCT_DATA = "CLEAR_PRODUCT_DATA";
export const GET_TOTAL_PRODUCT_DATA = "GET_TOTAL_PRODUCT_DATA";
export const CLEAR_TOTAL_PRODUCT_DATA = "CLEAR_TOTAL_PRODUCT_DATA";
export const GET_PRODUCT_FILTER = "GET_PRODUCT_FILTER";
export const CLEAR_PRODUCT_FILTER = "CLEAR_PRODUCT_FILTER";
export const GET_PRODUCT_PAGE = 'GET_PRODUCT_PAGE';
export const CLEAR_PRODUCT_PAGE = 'CLEAR_PRODUCT_PAGE';

export const GET_PRODUCT_INVENTORY_DATA = "GET_PRODUCT_INVENTORY_DATA";
export const CLEAR_PRODUCT_INVENTORY_DATA = "CLEAR_PRODUCT_INVENTORY_DATA";
export const GET_TOTAL_PRODUCT_INVENTORY = "GET_TOTAL_PRODUCT_INVENTORY";
export const CLEAR_TOTAL_PRODUCT_INVENTORY = "CLEAR_TOTAL_PRODUCT_INVENTORY";
export const GET_PRODUCT_INVENTORY_FILTER = "GET_PRODUCT_INVENTORY_FILTER";
export const CLEAR_PRODUCT_INVENTORY_FILTER = "CLEAR_PRODUCT_INVENTORY_FILTER";
export const GET_PRODUCT_INVENTORY_PAGE = "GET_PRODUCT_INVENTORY_PAGE";
export const CLEAR_PRODUCT_INVENTORY_PAGE = "CLEAR_PRODUCT_INVENTORY_PAGE";

export const SET_PRODUCT_PRICING_DATA = "SET_PRODUCT_PRICING_DATA";
export const CLEAR_PRODUCT_PRICING_DATA = "CLEAR_PRODUCT_PRICING_DATA";
export const UPDATE_PRODUCT_PRICING_DATA = "UPDATE_PRODUCT_PRICING_DATA";
export const SET_TOTAL_PRODUCT_PRICING_DATA = "SET_TOTAL_PRODUCT_PRICING_DATA";
export const CLEAR_TOTAL_PRODUCT_PRICING_DATA =
  "CLEAR_TOTAL_PRODUCT_PRICING_DATA";
export const SET_PRODUCT_PRICING_FILTER = "SET_PRODUCT_PRICING_FILTER";
export const CLEAR_PRODUCT_PRICING_FILTER = "CLEAR_PRODUCT_PRICING_FILTER";
export const SET_PRODUCT_PRICING_PAGE = "SET_PRODUCT_PRICING_PAGE";
export const CLEAR_PRODUCT_PRICING_PAGE = "CLEAR_PRODUCT_PRICING_PAGE";

export const GET_PURCHASE_ORDER_DATA = "GET_PURCHASE_ORDER_DATA";
export const CLEAR_PURCHASE_ORDER_DATA = "CLEAR_PURCHASE_ORDER_DATA";
export const GET_TOTAL_PURCHASE_ORDER_DATA = "GET_TOTAL_PURCHASE_ORDER_DATA";
export const CLEAR_TOTAL_PURCHASE_ORDER_DATA =
  "CLEAR_TOTAL_PURCHASE_ORDER_DATA";
export const GET_PURCHASE_ORDER_FILTER = "GET_PURCHASE_ORDER_FILTER";
export const CLEAR_PURCHASE_ORDER_FILTER = "CLEAR_PURCHASE_ORDER_FILTER";
export const GET_PURCHASE_ORDER_PAGE = "GET_PURCHASE_ORDER_PAGE";
export const CLEAR_PURCHASE_ORDER_PAGE = "CLEAR_PURCHASE_ORDER_PAGE";

export const GET_PURCHASE_REQUISITION_DATA = "GET_PURCHASE_REQUISITION_DATA";
export const CLEAR_PURCHASE_REQUISITION_DATA =
  "CLEAR_PURCHASE_REQUISITION_DATA";
export const GET_TOTAL_PURCHASE_REQUISITION_DATA =
  "GET_TOTAL_PURCHASE_REQUISITION_DATA";
export const CLEAR_TOTAL_PURCHASE_REQUISITION_DATA =
  "CLEAR_TOTAL_PURCHASE_REQUISITION_DATA";
export const GET_PURCHASE_REQUISITION_FILTER =
  "GET_PURCHASE_REQUISITION_FILTER";
export const CLEAR_PURCHASE_REQUISITION_FILTER =
  "CLEAR_PURCHASE_REQUISITION_FILTER";
export const GET_PURCHASE_REQUISITION_PAGE = "GET_PURCHASE_REQUISITION_PAGE";
export const CLEAR_PURCHASE_REQUISITION_PAGE =
  "CLEAR_PURCHASE_REQUISITION_PAGE";

export const GET_ROLE_DATA = "GET_ROLE_DATA";
export const CLEAR_ROLE_DATA = "CLEAR_ROLE_DATA";
export const GET_TOTAL_ROLE_DATA = "GET_TOTAL_ROLE_DATA";
export const CLEAR_TOTAL_ROLE_DATA = "CLEAR_TOTAL_ROLE_DATA";
export const GET_ROLE_FILTER = "GET_ROLE_FILTER";
export const CLEAR_ROLE_FILTER = "CLEAR_ROLE_FILTER";
export const GET_ROLE_PAGE = "GET_ROLE_PAGE";
export const CLEAR_ROLE_PAGE = "CLEAR_ROLE_PAGE";

export const GET_SALES_GRAPH_DATA = "GET_SALES_GRAPH_DATA";
export const CLEAR_SALES_GRAPH_DATA = "CLEAR_SALES_GRAPH_DATA";
export const GET_SALES_GRAPH_FILTER = "GET_SALES_GRAPH_FILTER";
export const CLEAR_SALES_GRAPH_FILTER = "CLEAR_SALES_GRAPH_FILTER";
export const GET_SALES_TABLE_DATA = "GET_SALES_TABLE_DATA";
export const CLEAR_SALES_TABLE_DATA = "CLEAR_SALES_TABLE_DATA";
export const GET_SALES_TABLE_PAGE = "GET_SALES_TABLE_PAGE";
export const CLEAR_SALES_TABLE_PAGE = "CLEAR_SALES_TABLE_PAGE";
export const GET_SALES_TABLE_FILTER = "GET_SALES_TABLE_FILTER";
export const CLEAR_SALES_TABLE_FILTER = "CLEAR_SALES_TABLE_FILTER";

export const GET_STOCK_HISTORY_DATA = "GET_STOCK_HISTORY_DATA";
export const CLEAR_STOCK_HISTORY_DATA = "CLEAR_STOCK_HISTORY_DATA";
export const GET_STOCK_HISTORY_FILTER = "GET_STOCK_HISTORY_FILTER";
export const CLEAR_STOCK_HISTORY_FILTER = "CLEAR_STOCK_HISTORY_FILTER";
export const GET_TOTAL_STOCK_HISTORY = "GET_TOTAL_STOCK_HISTORY";
export const CLEAR_TOTAL_STOCK_HISTORY = "CLEAR_TOTAL_STOCK_HISTORY";
export const GET_STOCK_HISTORY_PAGE = "GET_STOCK_HISTORY_PAGE";
export const CLEAR_STOCK_HISTORY_PAGE = "CLEAR_STOCK_HISTORY_PAGE";

export const GET_STOCK_DATA = "GET_STOCK_DATA";
export const CLEAR_STOCK_DATA = "CLEAR_STOCK_DATA";
export const GET_TOTAL_STOCK_DATA = "GET_TOTAL_STOCK_DATA";
export const CLEAR_TOTAL_STOCK_DATA = "CLEAR_TOTAL_STOCK_DATA";
export const GET_STOCK_FILTER = "GET_STOCK_FILTER";
export const CLEAR_STOCK_FILTER = "CLEAR_STOCK_FILTER";
export const GET_STOCK_PAGE = "GET_STOCK_PAGE";
export const CLEAR_STOCK_PAGE = "CLEAR_STOCK_PAGE";

export const GET_SUB_CATEGORY_DATA = "GET_SUB_CATEGORY_DATA";
export const CLEAR_SUB_CATEGORY_DATA = "CLEAR_SUB_CATEGORY_DATA";
export const GET_TOTAL_SUB_CATEGORY_DATA = "GET_TOTAL_SUB_CATEGORY_DATA";
export const CLEAR_TOTAL_SUB_CATEGORY_DATA = "CLEAR_TOTAL_SUB_CATEGORY_DATA";
export const GET_SUB_CATEGORY_FILTER = "GET_SUB_CATEGORY_FILTER";
export const CLEAR_SUB_CATEGORY_FILTER = "CLEAR_SUB_CATEGORY_FILTER";
export const GET_SUB_CATEGORY_PAGE = "GET_SUB_CATEGORY_PAGE";
export const CLEAR_SUB_CATEGORY_PAGE = "CLEAR_SUB_CATEGORY_PAGE";
export const SET_ACTIVE_SUB_CATEGORY = "SET_ACTIVE_SUB_CATEGORY";
export const CLEAR_ACTIVE_SUB_CATEGORY = "CLEAR_ACTIVE_SUB_CATEGORY";

export const GET_SPOT_DATA = "GET_SPOT_DATA";
export const CLEAR_SPOT_DATA = "CLEAR_SPOT_DATA";
export const GET_TOTAL_SPOT_DATA = "GET_TOTAL_SPOT_DATA";
export const CLEAR_TOTAL_SPOT_DATA = "CLEAR_TOTAL_SPOT_DATA";
export const GET_SPOT_FILTER = "GET_SPOT_FILTER";
export const CLEAR_SPOT_FILTER = "CLEAR_SPOT_FILTER";
export const GET_SPOT_PAGE = "GET_SPOT_PAGE";
export const CLEAR_SPOT_PAGE = "CLEAR_SPOT_PAGE";

export const SET_TRANSACTION = "SET_TRANSACTION";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const SET_TOTAL_TRANSACTION = "SET_TOTAL_TRANSACTION";
export const CLEAR_TOTAL_TRANSACTION = "CLEAR_TOTAL_TRANSACTION";
export const GET_TRANSACTION_FILTER = "GET_TRANSACTION_FILTER";
export const CLEAR_TRANSACTION_FILTER = "CLEAR_TRANSACTION_FILTER";
export const GET_TRANSACTION_PAGE = "GET_TRANSACTION_PAGE";
export const CLEAR_TRANSACTION_PAGE = "CLEAR_TRANSACTION_PAGE";

export const GET_UNIT_DATA = "GET_UNIT_DATA";
export const CLEAR_UNIT_DATA = "CLEAR_UNIT_DATA";
export const GET_TOTAL_UNIT_DATA = 'GET_TOTAL_UNIT_DATA';
export const CLEAR_TOTAL_UNIT_DATA = 'CLEAR_TOTAL_UNIT_DATA';
export const GET_UNIT_FILTER = "GET_UNIT_FILTER";
export const CLEAR_UNIT_FILTER = "CLEAR_UNIT_FILTER";
export const GET_UNIT_PAGE = "GET_UNIT_PAGE";
export const CLEAR_UNIT_PAGE = "CLEAR_UNIT_PAGE";

export const GET_UNIT_DROPDOWN_DATA = 'GET_UNIT_DROPDOWN_DATA';
export const CLEAR_UNIT_DROPDOWN_DATA = 'CLEAR_UNIT_DROPDOWN_DATA';
export const GET_TOTAL_UNIT_DROPDOWN_DATA = 'GET_TOTAL_UNIT_DROPDOWN_DATA';
export const CLEAR_TOTAL_UNIT_DROPDOWN_DATA = 'CLEAR_TOTAL_UNIT_DROPDOWN_DATA';

export const GET_VENDOR_DATA = "GET_VENDOR_DATA";
export const CLEAR_VENDOR_DATA = "CLEAR_VENDOR_DATA";
export const GET_TOTAL_VENDOR_DATA = "GET_TOTAL_VENDOR_DATA";
export const CLEAR_TOTAL_VENDOR_DATA = "CLEAR_TOTAL_VENDOR_DATA";
export const GET_VENDOR_FILTER = "GET_VENDOR_FILTER";
export const CLEAR_VENDOR_FILTER = "CLEAR_VENDOR_FILTER";
export const GET_VENDOR_PAGE = "GET_VENDOR_PAGE";
export const CLEAR_VENDOR_PAGE = "CLEAR_VENDOR_PAGE";

export const GET_NEW_VENDOR_DATA = "GET_NEW_VENDOR_DATA";
export const CLEAR_NEW_VENDOR_DATA = "CLEAR_NEW_VENDOR_DATA";
export const GET_TOTAL_NEW_VENDOR_DATA = "GET_TOTAL_NEW_VENDOR_DATA";
export const CLEAR_TOTAL_NEW_VENDOR_DATA = "CLEAR_TOTAL_NEW_VENDOR_DATA";
export const GET_NEW_VENDOR_FILTER = "GET_NEW_VENDOR_FILTER";
export const CLEAR_NEW_VENDOR_FILTER = "CLEAR_NEW_VENDOR_FILTER";
export const GET_NEW_VENDOR_PAGE = "GET_NEW_VENDOR_PAGE";
export const CLEAR_NEW_VENDOR_PAGE = "CLEAR_NEW_VENDOR_PAGE";

export const SET_ORDER_TABLE_DATA = "SET_ORDER_TABLE_DATA";
export const CLEAR_ORDER_TABLE_DATA = "CLEAR_ORDER_TABLE_DATA";

export const GET_WALLET_DATA = "GET_WALLET_DATA";
export const CLEAR_WALLET_DATA = "CLEAR_WALLET_DATA";
export const GET_TOTAL_WALLET_DATA = "GET_TOTAL_WALLET_DATA";
export const CLEAR_TOTAL_WALLET_DATA = "CLEAR_TOTAL_WALLET_DATA";
export const GET_WALLET_FILTER = "GET_WALLET_FILTER";
export const CLEAR_WALLET_FILTER = "CLEAR_WALLET_FILTER";
export const GET_WALLET_PAGE = "GET_WALLET_PAGE";
export const CLEAR_WALLET_PAGE = "CLEAR_WALLET_PAGE";
export const GET_TOTAL_WALLET_AMT = 'GET_TOTAL_WALLET_AMT';
export const CLEAR_TOTAL_WALLET_AMT = 'CLEAR_TOTAL_WALLET_AMT';

export const SET_LOADER = "SET_LOADER";
export const CLEAR_LOADER = "CLEAR_LOADER";

export const GET_ATTRIBUTE_DATA = "GET_ATTRIBUTE_DATA";
export const CLEAR_ATTRIBUTE_DATA = "CLEAR_ATTRIBUTE_DATA";
export const GET_TOTAL_ATTRIBUTE_DATA = "GET_TOTAL_ATTRIBUTE_DATA";
export const CLEAR_TOTAL_ATTRIBUTE_DATA = "CLEAR_TOTAL_ATTRIBUTE_DATA";
export const GET_ATTRIBUTE_FILTER = "GET_ATTRIBUTE_FILTER";
export const CLEAR_ATTRIBUTE_FILTER = "CLEAR_ATTRIBUTE_FILTER";
export const GET_ATTRIBUTE_PAGE = "GET_ATTRIBUTE_PAGE";
export const CLEAR_ATTRIBUTE_PAGE = "CLEAR_ATTRIBUTE_PAGE";

export const GET_IMAGE_DATA = "GET_IMAGE_DATA";
export const CLEAR_IMAGE_DATA = "CLEAR_IMAGE_DATA";
export const TOTAL_IMAGE = "TOTAL_IMAGE";
export const CLEAR_TOTAL_IMAGE = "CLEAR_TOTAL_IMAGE";
export const GET_IMAGE_FILTER = "GET_IMAGE_FILTER";
export const CLEAR_IMAGE_FILTER = "CLEAR_IMAGE_FILTER";
export const GET_IMAGE_PAGE = "GET_IMAGE_PAGE";
export const CLEAR_IMAGE_PAGE = "CLEAR_IMAGE_PAGE";

export const CHECBOX_ACTION = "CHECBOX_ACTION";

export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const CLEAR_COMPANY_DATA = "CLEAR_COMPANY_DATA";
export const GET_TOTAL_COMPANY_DATA = "GET_TOTAL_COMPANY_DATA";
export const CLEAR_TOTAL_COMPANY_DATA = "CLEAR_TOTAL_COMPANY_DATA";
export const GET_COMPANY_FILTER = "GET_COMPANY_FILTER";
export const CLEAR_COMPANY_FILTER = "CLEAR_COMPANY_FILTER";
export const GET_COMPANY_PAGE = "GET_COMPANY_PAGE";
export const CLEAR_COMPANY_PAGE = "CLEAR_COMPANY_PAGE";

export const GET_INDUSTRY_DATA = "GET_INDUSTRY_DATA";
export const CLEAR_INDUSTRY_DATA = "CLEAR_INDUSTRY_DATA";
export const GET_TOTAL_INDUSTRY_DATA = "GET_TOTAL_INDUSTRY_DATA";
export const CLEAR_TOTAL_INDUSTRY_DATA = "CLEAR_TOTAL_INDUSTRY_DATA";
export const GET_INDUSTRY_PAGE = "GET_INDUSTRY_PAGE";
export const CLEAR_INDUSTRY_PAGE = "CLEAR_INDUSTRY_PAGE";

export const GET_INVENTORY_DATA = "GET_INVENTORY_DATA";
export const CLEAR_INVENTORY_DATA = "CLEAR_INVENTORY_DATA";
export const GET_TOTAL_INVENTORY_DATA = "GET_TOTAL_INVENTORY_DATA";
export const CLEAR_TOTAL_INVENTORY_DATA = "CLEAR_TOTAL_INVENTORY_DATA";
export const GET_INVENTORY_FILTER = "GET_INVENTORY_FILTER";
export const CLEAR_INVENTORY_FILTER = "CLEAR_INVENTORY_FILTER";
export const GET_INVENTORY_PAGE = "GET_INVENTORY_PAGE";
export const CLEAR_INVENTORY_PAGE = "CLEAR_INVENTORY_PAGE";

export const GET_LEAF_DATA = "GET_LEAF_DATA";
export const CLEAR_LEAF_DATA = "CLEAR_LEAF_DATA";
export const GET_TOTAL_LEAF_DATA = "GET_TOTAL_LEAF_DATA";
export const CLEAR_TOTAL_LEAF_DATA = "CLEAR_TOTAL_LEAF_DATA";
export const GET_LEAF_FILTER = "GET_LEAF_FILTER";
export const CLEAR_LEAF_FILTER = "CLEAR_LEAF_FILTER";
export const GET_LEAF_PAGE = "GET_LEAF_PAGE";
export const CLEAR_LEAF_PAGE = "CLEAR_LEAF_PAGE";

export const OPEN_SIDEBAR = "OPEN_SIDEBAR";

export const GET_PACKAGER_DATA = "GET_PACKAGER_DATA";
export const CLEAR_PACKAGER_DATA = "CLEAR_PACKAGER_DATA";
export const GET_TOTAL_PACKAGER_DATA = "GET_TOTAL_PACKAGER_DATA";
export const CLEAR_TOTAL_PACKAGER_DATA = "CLEAR_TOTAL_PACKAGER_DATA";
export const GET_PACKAGER_FILTER = "GET_PACKAGER_FILTER";
export const CLEAR_PACKAGER_FILTER = "CLEAR_PACKAGER_FILTER";
export const GET_PACKAGER_PAGE = "GET_PACKAGER_PAGE";
export const CLEAR_PACKAGER_PAGE = "CLEAR_PACKAGER_PAGE";

export const GET_SIDEBAR_DATA = 'GET_SIDEBAR_DATA';
export const CLEAR_SIDEBAR_DATA = 'CLEAR_SIDEBAR_DATA';

export const GET_SERVICE_DATA = 'GET_SERVICE_DATA';
export const CLEAR_SERVICE_DATA = 'CLEAR_SERVICE_DATA';
export const GET_TOTAL_SERVICE_DATA = 'GET_TOTAL_SERVICE_DATA';
export const CLEAR_TOTAL_SERVICE_DATA = 'CLEAR_TOTAL_SERVICE_DATA';
export const GET_SERVICE_FILTER = 'GET_SERVICE_FILTER';
export const CLEAR_SERVICE_FILTER = 'CLEAR_SERVICE_FILTER';
export const GET_SERVICE_PAGE = 'GET_SERVICE_PAGE';
export const CLEAR_SERVICE_PAGE = 'CLEAR_SERVICE_PAGE';

export const GET_SERVICE_PROVIDER_DATA = 'GET_SERVICE_PROVIDER_DATA';
export const CLEAR_SERVICE_PROVIDER_DATA = 'CLEAR_SERVICE_PROVIDER_DATA';
export const GET_TOTAL_SERVICE_PROVIDER_DATA = 'GET_TOTAL_SERVICE_PROVIDER_DATA';
export const CLEAR_TOTAL_SERVICE_PROVIDER_DATA = 'CLEAR_TOTAL_SERVICE_PROVIDER_DATA';
export const GET_SERVICE_PROVIDER_FILTER = 'GET_SERVICE_PROVIDER_FILTER';
export const CLEAR_SERVICE_PROVIDER_FILTER = 'CLEAR_SERVICE_PROVIDER_FILTER';
export const GET_SERVICE_PROVIDER_PAGE = 'GET_SERVICE_PROVIDER_PAGE';
export const CLEAR_SERVICE_PROVIDER_PAGE = 'CLEAR_SERVICE_PROVIDER_PAGE';

export const GET_TAG_DATA = 'GET_TAG_DATA';
export const CLEAR_TAG_DATA = 'CLEAR_TAG_DATA';
export const GET_TOTAL_TAG_DATA = 'GET_TOTAL_TAG_DATA';
export const CLEAR_TOTAL_TAG_DATA = 'CLEAR_TOTAL_TAG_DATA';
export const GET_TAG_FILTER = 'GET_TAG_FILTER';
export const CLEAR_TAG_FILTER = 'CLEAR_TAG_FILTER';
export const GET_TAG_PAGE = 'GET_TAG_PAGE';
export const CLEAR_TAG_PAGE = 'CLEAR_TAG_PAGE';

export const UPLOAD_IMAGE_DATA = 'UPLOAD_IMAGE_DATA';

export const GET_WAREHOUSE_DATA = 'GET_WAREHOUSE_DATA';
export const CLEAR_WAREHOUSE_DATA = 'CLEAR_WAREHOUSE_DATA';
export const GET_TOTAL_WAREHOUSE_DATA = 'GET_TOTAL_WAREHOUSE_DATA';
export const CLEAR_TOTAL_WAREHOUSE_DATA = 'CLEAR_TOTAL_WAREHOUSE_DATA';
export const GET_WAREHOUSE_FILTER = 'GET_WAREHOUSE_FILTER';
export const CLEAR_WAREHOUSE_FILTER = 'CLEAR_WAREHOUSE_FILTER';
export const GET_WAREHOUSE_PAGE = 'GET_WAREHOUSE_PAGE';
export const CLEAR_WAREHOUSE_PAGE = 'CLEAR_WAREHOUSE_PAGE';
import { orderDate } from "../../../services/Regular-services/DateService";
import {
  CLEAR_PURCHASE_ORDER_FILTER,
  GET_PURCHASE_ORDER_FILTER,
} from "../../ReduxKeys";

const purchaseOrderFilterInitialState = {
  purchaseOrderNo: "",
  orderStartDate: orderDate(),
  orderEndDate: "",
};

/**
 * Store PURCHASE ORDER filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const PurchaseOrderFilterReducer = (
  state = purchaseOrderFilterInitialState,
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_ORDER_FILTER:
      return {
        ...state,
        purchaseOrderNo: action.payload.purchaseOrderNo,
        orderStartDate: action.payload.orderStartDate,
        orderEndDate: action.payload.orderEndDate,
      };
    case CLEAR_PURCHASE_ORDER_FILTER:
      return purchaseOrderFilterInitialState;
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import "../NewOrderCart/NewOrderCart.scss";
import Button from "../Button/Button";
import { FaWhatsapp } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { FiPrinter } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { format } from "date-fns";
import generateInvoiceHtml from "../generateInvoiceHtml/generateInvoiceHtml";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderPrintHtml } from "../Table/OrderPrintHtml";
import { orderStatusTableArray } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";

function NewOrderCart({
  orderData,
  generatePDFAndUpload,
  getOrderDetail,
  selectedOrder
}) {
  const user = useSelector((state) => state.auth?.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [orderStatusClass, setOrderStatusClass] = useState("");

  useEffect(() => {
    const orderStatusData = orderStatusTableArray["order"].find(
      (item) => item.value === orderData?.orderStatus
    );
    setOrderStatusClass(orderStatusData ? orderStatusData?.className : "");
    // eslint-disable-next-line
  }, [orderData, orderStatusTableArray]);

  const printInvoice = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    const invoiceHtml1 = generateInvoiceHtml(orderData, user);
    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(invoiceHtml1);
    printWindow.document.close();
    // Trigger the print dialog
    printWindow.print();
  };

  const handleNavigate = (route, data, isEdit) => {
    const Data = {
      data: data,
      isEdit: isEdit,
      length: 1,
    };
    navigate(route, { state: { Data } });
  };

  const printOrderDetails = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    const htmlContent = OrderPrintHtml({ orderData });
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div
      className={`${
        selectedOrder === orderData ? "selected-order " : ""
      }new-order-cart`}
      onClick={() => getOrderDetail(orderData)}
    >
      <div className="order-cart">
        <div className="order-header">
          <div className="order-details">
            <p className="table-info">
              {t("table_no")}{" "}
              {orderData?.type === "0" ? orderData?.spot_name : "N/A"}
            </p>
            <p className="order-id">{orderData?.order_number}</p>
          </div>
          <div className="order-details">
            <p className="customer-name">
              {t("columns.name")}:{" "}
              {orderData?.customerName && orderData.customerName !== ""
                ? orderData.customerName
                : "N/A"}
            </p>
            <p className="order-price">
              {orderData?.country_symbol || "₹"}
              {Number(orderData?.order_amount).toFixed(2) || "0.00"}
            </p>
          </div>
        </div>
        <div className="order-actions">
          <div>
            <div className="icons">
              <Button
                title="Filter"
                className="btn all-btn new-pay-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigate("/edit-order", orderData, false);
                }}
                name={
                  <div className="filter">
                    <IoEyeOutline className="new-cart-icon" />
                  </div>
                }
              ></Button>
              <Button
                title="Filter"
                className="btn all-btn new-pay-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  printInvoice();
                }}
                name={
                  <div className="filter">
                    <FiPrinter className="new-cart-icon" />
                  </div>
                }
              ></Button>
              <Button
                title="Filter"
                className="btn all-btn new-pay-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigate("/edit-order", orderData, true);
                }}
                name={
                  <div className="filter">
                    <MdOutlineEdit className="new-cart-icon" />
                  </div>
                }
              ></Button>
              <Button
                title="Filter"
                className="btn all-btn new-pay-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  printOrderDetails();
                }}
                name={"KOT"}
              ></Button>
              {orderData?.phoneNumber &&
              !["", "N/A", "8155006366", 8155006366].includes(
                orderData.phoneNumber
              ) ? (
                <Button
                  title="Filter"
                  className="btn all-btn new-pay-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    generatePDFAndUpload(orderData);
                  }}
                  name={
                    <div className="filter">
                      <FaWhatsapp className="new-cart-icon" />
                    </div>
                  }
                ></Button>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* <div className="pay-btn">
            <Button
              title="Pay"
              className="btn btn-fillter-main all-btn new-paybtn"
              name={"Pay"}
            ></Button>
            <Button
              title="Pay"
              className="btn btn-fillter-main all-btn new-paybtn new-pay-btn-color"
              name={"Pay Later"}
            ></Button>
          </div> */}
        </div>
      </div>
      <div
        className={`order-footer ${orderStatusClass}`}
        // onClick={() => getOrderDetail(orderData)}
      >
        <p className="dine-in">{t(orderData?.tableType)}</p>
        <p className="order-date">
          {format(new Date(orderData?.createdAt), "dd/MM/yyyy")}
        </p>
      </div>
    </div>
  );
}

export default NewOrderCart;
